import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Accordion,
  Button,
  List,
  Modal,
  Form,
  Input,
  Select,
  TextArea,
  Checkbox,
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type StaffProps =
    { options: any, modals: any, data: any, num: 1 | 2, print: boolean } &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class TemplateInfo extends React.PureComponent<StaffProps> {
    public render() {
      return (
        <Form>
          <Form.Group style={{marginBottom:10,marginTop:12}}>
            <Form.Field
              control={Input}
              label='Class name'
              name='Class name'
              value={this.props.data.className||''}
              onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'className', data.value.validate())}
              width={4}
              className={this.props.print ? 'transparent' : ''}
            />
            <Form.Field
              control={Select}
              label='Funding source'
              placeholder='Funding source'
              options={[{key:0,value:null,text:''}].concat(this.props.options.funding)}
              value={this.props.data.fundingSource}
              onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'fundingSource', data.value)}
              width={4}
              className={this.props.print ? 'transparent' : ''}
            />
            <Form.Field
              control={Input}
              label='Required hours'
              name='Required hours'
              value={this.props.data.requiredHours||''}
              onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'requiredHours', data.value.validate())}
              width={4}
              className={this.props.print ? 'transparent' : ''}
            />
            <Form.Field
              control={Select}
              label='Contract'
              placeholder='Contract'
              options={this.props.options.contracts||[]}
              value={this.props.data.contractID}
              onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'contractID', data.value)}
              width={4}
              className={this.props.print ? 'transparent' : ''}
            />
          </Form.Group>
          <Form.Group style={{marginBottom:10}}>
            <Form.Field
              control={TextArea}
              label='Description'
              name='Description'
              value={this.props.data.description||''}
              onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'description', data.value)}
              width={16}
              className={this.props.print ? 'transparent' : ''}
            />
          </Form.Group>
          <Form.Group style={{marginBottom:10}}>
            <Form.Field
              control={TextArea}
              label='Performance objectives'
              name='Performance objectives'
              value={this.props.data.performanceObjectives||''}
              onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'performanceObjectives', data.value)}
              width={16}
              className={this.props.print ? 'transparent' : ''}
            />
          </Form.Group>
          <Form.Group style={{marginBottom:10}}>
            <Form.Field
              control={TextArea}
              label='Performance indicators'
              name='Performance indicators'
              value={this.props.data.performanceIndicators||''}
              onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'performanceIndicators', data.value)}
              width={16}
              className={this.props.print ? 'transparent' : ''}
            />
          </Form.Group>
          <Form.Group style={{marginBottom:10}}>
            <Form.Field
              control={Input}
              label="Min class size"
              name="minClassSize"
              checked={this.props.data.minClassSize}
              onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'minClassSize', data.value.validate())}
              width={4}
              className={this.props.print ? 'transparent' : ''}
            />
            <Form.Field
              control={Input}
              label="Max class size"
              name="maxClassSize"
              checked={this.props.data.maxClassSize}
              onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'maxClassSize', data.value.validate())}
              width={4}
              className={this.props.print ? 'transparent' : ''}
            />
            <Form.Field
              control={Input}
              label="Pay Rate"
              name="payRate"
              checked={this.props.data.payRate}
              onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'payRate', data.value.validate())}
              width={4}
              className={this.props.print ? 'transparent' : ''}
            />
            <Form.Field
              control={Select}
              label='Per'
              placeholder='Per'
              options={[{key: '', value: null, text: ''}, {key: 'Inmate Hour', value: 'Inmate Hour', text: 'Inmate Hour'}, {key: 'Inmate', value: 'Inmate', text: 'Inmate'}, {key: 'Hour', value: 'Hour', text: 'Hour'}, {key: 'Class', value: 'Class', text: 'Class'}]}
              value={this.props.data.per}
              onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'per', data.value)}
              width={4}
              className={this.props.print ? 'transparent' : ''}
            />
          </Form.Group>
          {this.props.print ? null : <Fragment>
            <Button color='green' onClick={_.debounce(() => this.props.saveTemplate(this.props.num), 10000, true)}>Save</Button>
            {this.props.data.active ? <Button color='black' content='Archive' onClick={_.debounce(() => this.props.archiveTemplate(this.props.num), 10000, true)} /> : null}
            {!this.props.data.active ? <Button color='blue' content='Restore' onClick={_.debounce(() => this.props.restoreTemplate(this.props.num), 10000, true)} /> : null}
          </Fragment>}
          <Modal
            open={this.props.modals.archiveWarning.open}
            onClose={() => this.props.toggleTemplateArchiveWarningModal(this.props.num)}
            size="small"
          >
            <Modal.Header content={`Remove Template`} />
            <Modal.Content>
              <div>This course has active classes under it. Are you sure you wish to <b>archive</b> it anyways?</div>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleTemplateArchiveWarningModal(this.props.num)}>Close</Button>
              <Button
                negative
                content="Remove"
                onClick={_.debounce(() => this.props.archiveTemplate(this.props.num, true), 10000, true)}
              />
            </Modal.Actions>
          </Modal>
        </Form>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { data: ownProps.data, options: ownProps.options, modals: ownProps.modals, num: ownProps.num, print: ownProps.print } },
  PanesStore.actionCreators
)(TemplateInfo as any)