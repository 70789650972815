import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Grid,
  Form,
  Header,
  Select,
  Table,
  TextArea,
  Input,
  Button,
  Modal,
  Popup
} from 'semantic-ui-react'

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2 } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateANDs extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchAND(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      return (
        <Container fluid>
          <div style={{display:'inline-block',marginTop:20}}>
            <Header as='h3'>A&Ds</Header>
          </div>
          <Button positive circular icon='plus' onClick={() => this.props.toggleAND(this.props.num)} style={{marginLeft:10,marginBottom:10}} />
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Admission</Table.HeaderCell>
                        <Table.HeaderCell>Discharge</Table.HeaderCell>
                        <Table.HeaderCell>Facility</Table.HeaderCell>
                        <Table.HeaderCell>Level</Table.HeaderCell>
                        <Table.HeaderCell>Counselor</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.ands ? this.props.inmate.ands.map((and:any) => (
                        <Table.Row key={and.recordID} onClick={() => this.props.editAND(this.props.num, this.props.inmate.info.recordID, and.recordID)}>
                          <Table.Cell>{and.admissionDate}</Table.Cell>
                          <Table.Cell>{and.dischargeDate}</Table.Cell>
                          <Table.Cell>{and.facilityAbbr}</Table.Cell>
                          <Table.Cell>{and.level}</Table.Cell>
                          <Table.Cell>{and.primaryCounselor}</Table.Cell>
                        </Table.Row>
                      )) : null}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <Modal
            open={this.props.inmate.modals.and.open}
            onClose={() => this.props.toggleAND(this.props.num)}
          >
            <Modal.Header content={this.props.inmate.modals.and.data.recordID ? `Save A&D` : `Add A&D`} />
            <Modal.Content>
              <Grid padded>
                <Grid.Column width={16} style={{marginBottom:0}}>
                  <Form>
                    <Form.Group>
                      <Form.Field
                        label="Admission Date"
                        control={DatePicker}
                        selected={this.props.inmate.modals.and.data.admissionDate ? new Date(this.props.inmate.modals.and.data.admissionDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'and', 'admissionDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                      <Form.Field
                        label="Assessment Date"
                        control={DatePicker}
                        selected={this.props.inmate.modals.and.data.assessmentDate ? new Date(this.props.inmate.modals.and.data.assessmentDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'and', 'assessmentDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                      <Form.Field
                        search
                        control={Select}
                        label="Primary Counselor"
                        placeholder="Primary Counselor"
                        name="counselorID"
                        options={this.props.inmate.options.staff}
                        value={this.props.inmate.modals.and.data.counselorID}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'counselorID', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Select}
                        label="Provider"
                        placeholder="Provider"
                        name="provider"
                        options={this.props.inmate.options.providers}
                        value={this.props.inmate.modals.and.data.provider}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'provider', data.value)}
                        width={4}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        label="Primary Program"
                        placeholder="Primary Program"
                        name="primary"
                        options={this.props.inmate.options.primary}
                        value={this.props.inmate.modals.and.data.primary}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'primary', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Input}
                        label="Other"
                        placeholder="Other"
                        name="primaryOther"
                        value={this.props.inmate.modals.and.data.primaryOther||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'primaryOther', data.value.validate())}
                        width={4}
                      />
                      <Form.Field
                        control={Select}
                        label="Supplement Program"
                        placeholder="Supplement Program"
                        name="supplement"
                        options={this.props.inmate.options.secondary}
                        value={this.props.inmate.modals.and.data.supplement}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'supplement', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Input}
                        label="Other"
                        placeholder="Other"
                        name="supplementOther"
                        value={this.props.inmate.modals.and.data.supplementOther||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'supplementOther', data.value.validate())}
                        width={4}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        search
                        control={Select}
                        label="Facility"
                        placeholder="Facility"
                        name="facilityID"
                        options={this.props.inmate.options.facilities}
                        value={this.props.inmate.modals.and.data.facilityID}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'facilityID', data.value)}
                        width={6}
                      />
                      <Form.Field
                        search
                        control={Select}
                        label="Diagnosis"
                        placeholder="Diagnosis"
                        name="diagnosis"
                        options={this.props.inmate.options.diagnoses}
                        value={this.props.inmate.modals.and.data.diagnosis}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'diagnosis', data.value)}
                        width={6}
                      />
                      <Form.Field
                        control={Input}
                        label="RTL Score"
                        placeholder="RTL Score"
                        name="rtl"
                        value={this.props.inmate.modals.and.data.rtl||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'rtl', data.value.validate())}
                        width={4}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        label="Drug of Choice 1"
                        placeholder="Drug of Choice"
                        name="doc1"
                        options={this.props.inmate.options.drugs}
                        value={this.props.inmate.modals.and.data.doc1}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'doc1', data.value)}
                        width={5}
                      />
                      <Form.Field
                        control={Select}
                        label="Drug of Choice 2"
                        placeholder="Drug of Choice"
                        name="doc2"
                        options={this.props.inmate.options.drugs}
                        value={this.props.inmate.modals.and.data.doc2}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'doc2', data.value)}
                        width={6}
                      />
                      <Form.Field
                        control={Select}
                        label="Drug of Choice 3"
                        placeholder="Drug of Choice"
                        name="doc3"
                        options={this.props.inmate.options.drugs}
                        value={this.props.inmate.modals.and.data.doc3}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'doc3', data.value)}
                        width={5}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Input}
                        label="Prior Community Treatments"
                        placeholder="Community Treatments"
                        name="communityTreatments"
                        value={this.props.inmate.modals.and.data.communityTreatments||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'communityTreatments', data.value.validate())}
                        width={4}
                      />
                      <Form.Field
                        control={Input}
                        label="Prior Prison Treatments"
                        placeholder="Prison Treatments"
                        name="prisonTreatments"
                        value={this.props.inmate.modals.and.data.prisonTreatments||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'prisonTreatments', data.value.validate())}
                        width={4}
                      />
                      <Form.Field
                        control={Select}
                        label="Psych History"
                        placeholder="Psych History"
                        name="psychHistory"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.and.data.psychHistory}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'psychHistory', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Select}
                        label="On Psych Meds"
                        placeholder="Psych Meds"
                        name="psychMeds"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.and.data.psychMeds}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'psychMeds', data.value)}
                        width={4}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        label="Treatment Completed"
                        control={DatePicker}
                        selected={this.props.inmate.modals.and.data.treatmentCompleted ? new Date(this.props.inmate.modals.and.data.treatmentCompleted) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'and', 'treatmentCompleted', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                      <Form.Field
                        control={TextArea}
                        label="Admission Notes"
                        placeholder="Admission Notes"
                        name="admissionNotes"
                        value={this.props.inmate.modals.and.data.admissionNotes||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'admissionNotes', data.value)}
                        rows={1}
                        width={12}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        label="Discharge Date"
                        control={DatePicker}
                        selected={this.props.inmate.modals.and.data.dischargeDate ? new Date(this.props.inmate.modals.and.data.dischargeDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'and', 'dischargeDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                      <Form.Field
                        label="Summary Completed"
                        control={DatePicker}
                        selected={this.props.inmate.modals.and.data.summaryCompleted ? new Date(this.props.inmate.modals.and.data.summaryCompleted) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'and', 'summaryCompleted', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                      <Form.Field
                        control={Select}
                        label="Type of Discharge"
                        placeholder="Type of Discharge"
                        name="dischargeType"
                        options={this.props.inmate.options.discharges}
                        value={this.props.inmate.modals.and.data.dischargeType}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'dischargeType', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Input}
                        label="Other"
                        placeholder="Other"
                        name="dischargeOther"
                        value={this.props.inmate.modals.and.data.dischargeOther||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'dischargeOther', data.value.validate())}
                        width={4}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Input}
                        label="Bed Days"
                        placeholder="Bed Days"
                        value={this.props.inmate.modals.and.data.bedDays || ''}
                        width={4}
                      />
                      <Form.Field
                        control={TextArea}
                        label="Discharge Notes"
                        placeholder="Discharge Notes"
                        name="dischargeNotes"
                        value={this.props.inmate.modals.and.data.dischargeNotes||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'and', 'dischargeNotes', data.value)}
                        rows={1}
                        width={12}
                      />
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleAND(this.props.num)}>Close</Button>
              {!this.props.inmate.modals.and.data.facilityID || !this.props.inmate.modals.and.data.admissionDate ? <Popup position='top center' content='Please make sure the A&D has an associated date and facility.' trigger={<Button>{this.props.inmate.modals.and.data.recordID ? 'Save' : 'Add'}</Button>} /> : <Button color='green' onClick={() => this.props.inmate.modals.and.data.recordID ? this.props.saveAND(this.props.num) : this.props.addAND(this.props.num)}>{this.props.inmate.modals.and.data.recordID ? 'Save' : 'Add'}</Button>}
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num } },
  PanesStore.actionCreators
)(InmateANDs as any)