import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Grid,
  Form,
  Header,
  Select,
  Table,
  TextArea,
  Input,
  Button,
  Modal,
  Divider,
  Checkbox
} from 'semantic-ui-react'
const _ = require('underscore')

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2 } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateUAs extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchUA(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      return (
        <Container fluid>
          <div style={{display:'inline-block',marginTop:20}}>
            <Header as='h3'>UAs</Header>
          </div>
          <Button positive circular icon='plus' onClick={() => this.props.toggleUA(this.props.num)} style={{marginLeft:10,marginBottom:10}} />
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Facility</Table.HeaderCell>
                        <Table.HeaderCell>UA Date</Table.HeaderCell>
                        <Table.HeaderCell>Analysis</Table.HeaderCell>
                        <Table.HeaderCell>Confirmed</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.uas ? this.props.inmate.uas.map((ua:any) => (
                        <Table.Row key={ua.recordID} onClick={() => this.props.editUA(this.props.num, this.props.inmate.info.recordID, ua.recordID)}>
                          <Table.Cell>{ua.facilityAbbr}</Table.Cell>
                          <Table.Cell>{ua.uaDate}</Table.Cell>
                          <Table.Cell>{_.filter([{value:ua.negative,text:'Negative'},{value:ua.alcohol,text:'Alcohol'},{value:ua.marijuana,text:'Marijuana'},{value:ua.cocaine,text:'Cocaine'},{value:ua.amphetamines,text:'Amphetamines'},{value:ua.methamphetamines,text:'Methamphetamines'},{value:ua.opioids,text:'Opioids'},{value:ua.benzodiazapines,text:'Benzodiazapines'},{value:ua.hallucinogens,text:'Hallucinogens'},{value:ua.inhalants,text:'Inhalants'},{value:ua.other,text:'Other'}], (ua:any) => ua.value).map((ua:any) => ua.text).join(', ')}</Table.Cell>
                          <Table.Cell><Checkbox checked={ua.confirmed} /></Table.Cell>
                        </Table.Row>
                      )) : null}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <Modal
            open={this.props.inmate.modals.ua.open}
            onClose={() => this.props.toggleUA(this.props.num)}
            size="large"
          >
            <Modal.Header content={this.props.inmate.modals.ua.data.recordID ? 'Save UA' : 'Add UA'} />
            <Modal.Content>
              <Grid padded>
                <Grid.Row>
                  <Grid.Column width={16} style={{marginBottom:0}}>
                    <Form>
                      <Form.Group>
                        <Form.Field
                          control={Checkbox}
                          label="Negative"
                          name="negative"
                          checked={this.props.inmate.modals.ua.data.negative}
                          onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'negative', data.checked)}
                          style={{marginTop:10}}
                        />
                        <Form.Field
                          control={Checkbox}
                          label="Alcohol"
                          name="alcohol"
                          checked={this.props.inmate.modals.ua.data.alcohol}
                          onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'alcohol', data.checked)}
                          style={{marginTop:10}}
                        />
                        <Form.Field
                          control={Checkbox}
                          label="Marijuana"
                          name="marijuana"
                          checked={this.props.inmate.modals.ua.data.marijuana}
                          onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'marijuana', data.checked)}
                          style={{marginTop:10}}
                        />
                        <Form.Field
                          control={Checkbox}
                          label="Cocaine"
                          name="cocaine"
                          checked={this.props.inmate.modals.ua.data.cocaine}
                          onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'cocaine', data.checked)}
                          style={{marginTop:10}}
                        />
                        <Form.Field
                          control={Checkbox}
                          label="Amphetamines"
                          name="amph"
                          checked={this.props.inmate.modals.ua.data.amphetamines}
                          onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'amphetamines', data.checked)}
                          style={{marginTop:10}}
                        />
                        <Form.Field
                          control={Checkbox}
                          label="Meth"
                          name="meth"
                          checked={this.props.inmate.modals.ua.data.methamphetamines}
                          onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'methamphetamines', data.checked)}
                          style={{marginTop:10}}
                        />
                        <Form.Field
                          control={Checkbox}
                          label="Opioids"
                          name="opioids"
                          checked={this.props.inmate.modals.ua.data.opioids}
                          onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'opioids', data.checked)}
                          style={{marginTop:10}}
                        />
                        <Form.Field
                          control={Checkbox}
                          label="Benzodiazapines"
                          name="benz"
                          checked={this.props.inmate.modals.ua.data.benzodiapines}
                          onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'benzodiapines', data.checked)}
                          style={{marginTop:10}}
                        />
                        <Form.Field
                          control={Checkbox}
                          label="Hallucinogens"
                          name="hall"
                          checked={this.props.inmate.modals.ua.data.hallucinogens}
                          onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'hallucinogens', data.checked)}
                          style={{marginTop:10}}
                        />
                        <Form.Field
                          control={Checkbox}
                          label="Inhalants"
                          name="inhalants"
                          checked={this.props.inmate.modals.ua.data.inhalants}
                          onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'inhalants', data.checked)}
                          style={{marginTop:10}}
                        />
                        <Form.Field
                          control={Checkbox}
                          label="Confirmed"
                          name="confirmed"
                          checked={this.props.inmate.modals.ua.data.confirmed}
                          onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'confirmed', data.checked)}
                          style={{marginTop:10}}
                        />
                      </Form.Group>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingTop:0}}>
                  <Grid.Column width={16} style={{marginBottom:0}}>
                    <Form.Field
                      control={Input}
                      placeholder="Other"
                      name="other"
                      value={this.props.inmate.modals.ua.data.other||''}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ua', 'other', data.value.validate())}
                      style={{width:'100%'}}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleUA(this.props.num)}>Close</Button>
              <Button color='green' onClick={() => this.props.inmate.modals.ua.data.recordID ? this.props.saveUA(this.props.num) : this.props.addUA(this.props.num)}>{this.props.inmate.modals.ua.data.recordID ? 'Save' : 'Add'}</Button>
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num } },
  PanesStore.actionCreators
)(InmateUAs as any)