import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import * as DrawerStore from '../../store/reducers/drawer'
import * as PanesStore from '../../store/reducers/panes'
import {
  Table,
  Popup,
  Label
} from 'semantic-ui-react'

type LogProps =
    { log: any, tab: string } &
    typeof AdminStore.actionCreators &
    typeof DrawerStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class LogPDFRow extends React.PureComponent<LogProps> {
    public render() {
      return (
        <Table.Row>
          <Table.Cell>{this.props.log.recordID}</Table.Cell>
          <Table.Cell>{this.props.log.printID}</Table.Cell>
          <Table.Cell>{this.props.log.staffName}</Table.Cell>
          <Table.Cell>{this.props.log.report}</Table.Cell>
          <Table.Cell><div style={{whiteSpace:'normal',wordBreak:'break-all',width:400}}>{this.props.log.data}</div></Table.Cell>
          <Table.Cell>{this.props.log.timestamp}</Table.Cell>
        </Table.Row>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { log: ownProps.log, tab: state.drawer.tab } },
  { ...AdminStore.actionCreators, ...DrawerStore.actionCreators, ...PanesStore.actionCreators }
)(LogPDFRow as any)