import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Table,
  Select,
  Button,
  Modal,
  Form,
  Input,
  Tab,
  Icon,
  Message,
  TextArea
} from 'semantic-ui-react'
const _ = require('underscore')

type CompletionProps =
    { class: PanesStore.Class, num: 1 | 2, print: boolean, admin: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassCompletionsCertificates extends React.PureComponent<CompletionProps> {
    public render() {
        const today = new Date(new Date().toDateString())
        const rosters = this.props.class.rosters == undefined ? [] : _.filter(this.props.class.rosters, (roster:any) => !roster.waitlist)
        return (
            rosters.length > 0 ? <Container fluid>
              {_.any(rosters, (roster:any) => roster.pending) ? <Message color='yellow'>
                <Icon name='warning' />
                Inmates with pending attendance cannot be completed.
              </Message> : null}
              <Table compact celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}></Table.HeaderCell>
                    <Table.HeaderCell width={10}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={1}>SID</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Hours</Table.HeaderCell>
                    <Table.HeaderCell>Certificate</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {rosters.map((roster:any, i:number) => (
                    <Table.Row key={roster.recordID}>
                      <Table.Cell>{i+1}</Table.Cell>
                      <Table.Cell>{`${roster.lastName}, ${roster.firstName}`}</Table.Cell>
                      <Table.Cell>{roster.sid}</Table.Cell>
                      <Table.Cell>{roster.aTime}</Table.Cell>
                      <Table.Cell>{roster.certID > 0 ? <Button negative size='mini' content='Remove Certificate' onClick={_.debounce(() => this.props.removeCertificate(this.props.num, roster.recordID), 10000, true)} style={{width:'100%'}} /> : <Button positive size='mini' content='Add Certificate' onClick={_.debounce(() => this.props.addCertificateModal(this.props.num, roster.recordID), 10000, true)} style={{width:'100%'}} />}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              {this.props.print ? null : <Fragment>
                <Button color='black' content='Back' onClick={() => this.props.completionsBack(this.props.num)} style={{marginBottom:20}} />
                <Button color='blue' content='Next' onClick={() => this.props.completionsNext(this.props.num)} style={{marginBottom:20}} />
                {this.props.admin ? <Button color='green' content='Create Certificate' onClick={() => this.props.toggleNewCertificateModal(this.props.num)} /> : null}
              </Fragment>}
              <Modal
                open={this.props.class.modals.certificate.open}
                onClose={() => this.props.toggleCertificateModal(this.props.num)}
                size="small"
              >
                <Modal.Header content='Add Certificate' />
                <Modal.Content>
                  <Form>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        label='Certificate'
                        placeholder='Certificate'
                        options={this.props.class.options.certificates}
                        value={this.props.class.modals.certificate.data.certificateID}
                        onChange={(e:any, data:any) => this.props.updateCertificateID(this.props.num, data.value as number)}
                        width={16}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Input}
                        label="Number"
                        value={this.props.class.modals.certificate.data.number || ''}
                        onChange={(e:any, data:any) => this.props.updateCertificateData(this.props.num, 'number', data.value.validate())}
                        width={5}
                      />
                      <Form.Field
                        label="Date"
                        control={DatePicker}
                        selected={this.props.class.modals.certificate.data.date ? new Date(this.props.class.modals.certificate.data.date) : null}
                        onChange={(date:any) => this.props.updateCertificateData(this.props.num, 'date', date)}
                        filterDate={(date:any) => date <= today}
                        width={6}
                      />
                      <Form.Field
                        label="Exp. Date"
                        control={DatePicker}
                        selected={this.props.class.modals.certificate.data.expDate ? new Date(this.props.class.modals.certificate.data.expDate) : null}
                        onChange={(date:any) => this.props.updateCertificateData(this.props.num, 'expDate', date)}
                        filterDate={(date:any) => this.props.class.modals.certificate.data.date ? date >= new Date(this.props.class.modals.certificate.data.date) : true}
                        width={5}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Input}
                        label="Notes"
                        value={this.props.class.modals.certificate.data.notes || ''}
                        onChange={(e:any, data:any) => this.props.updateCertificateData(this.props.num, 'notes', data.value.validate())}
                        width={16}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  <Button color='black' onClick={() => this.props.toggleCertificateModal(this.props.num)}>Close</Button>
                  <Button
                    positive
                    content="Add"
                    onClick={_.debounce(() => this.props.saveCertificateModal(this.props.num), 10000, true)}
                  />
                </Modal.Actions>
              </Modal>
              <Modal
                open={this.props.class.modals.newCertificate.open}
                onClose={() => this.props.toggleNewCertificateModal(this.props.num)}
                size="small"
              >
                <Modal.Header content='Create Certificate' />
                <Modal.Content>
                  <Form>
                    <Form.Group>
                      <Form.Field
                        control={Input}
                        label='Certificate name'
                        placeholder='Certificate name'
                        value={this.props.class.modals.newCertificate.data.name || ''}
                        onChange={(e:any, data:any) => this.props.updateNewCertificateData(this.props.num, 'name', data.value.validate())}
                        width={8}
                      />
                      <Form.Field
                        control={Select}
                        label='Institution'
                        placeholder='Institution'
                        options={[{key:0,value:null,text:''}].concat(this.props.class.options.institutions)}
                        value={this.props.class.modals.newCertificate.data.institutionID}
                        onChange={(e:any, data:any) => this.props.updateNewCertificateData(this.props.num, 'institutionID', data.value)}
                        width={8}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Input}
                        label='Prerequisites'
                        placeholder='Prerequisites'
                        value={this.props.class.modals.newCertificate.data.prerequisites||''}
                        onChange={(e:any, data:any) => this.props.updateNewCertificateData(this.props.num, 'prerequisites', data.value.validate())}
                        width={8}
                      />
                      <Form.Field
                        control={Input}
                        label='Satisfies'
                        placeholder='Satisfies'
                        value={this.props.class.modals.newCertificate.data.satisfies||''}
                        onChange={(e:any, data:any) => this.props.updateNewCertificateData(this.props.num, 'satisfies', data.value.validate())}
                        width={8}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={TextArea}
                        label='Details'
                        placeholder='Details'
                        value={this.props.class.modals.newCertificate.data.details||''}
                        onChange={(e:any, data:any) => this.props.updateNewCertificateData(this.props.num, 'details', data.value)}
                        width={16}
                      />
                    </Form.Group>
                  </Form>
                </Modal.Content>
                <Modal.Actions>
                  <Button color='black' onClick={() => this.props.toggleNewCertificateModal(this.props.num)}>Close</Button>
                  <Button
                    positive
                    content="Add"
                    onClick={_.debounce(() => this.props.saveNewCertificateModal(this.props.num), 10000, true)}
                  />
                </Modal.Actions>
              </Modal>
            </Container> : <Message color='yellow'>
              <Icon name='warning' />
              No inmates to complete.
            </Message>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, print: ownProps.print, admin: state.staff.staff ? state.staff.staff.admin : false } },
  PanesStore.actionCreators
)(ClassCompletionsCertificates as any)