import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import InfiniteScroll from 'react-infinite-scroller'
import LogActivityRow from './LogActivityRow'
import {
  Icon,
  Table,
  Dimmer,
  Loader
} from 'semantic-ui-react'

type LogProps =
    { logs: any[], loading: boolean, hasMore: boolean } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class LogActivities extends React.PureComponent<LogProps> {
    private HeaderArrow = (props:any) => {
      /*if (this.props.filters.orderBy == props.orderBy) {
        if (this.props.filters.orderDir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }*/
      return (null)
    }

    public componentDidMount() {
        this.props.fetchLogActivities()
    }

    public render() {
      const HeaderArrow = this.HeaderArrow
      return (
        <Fragment>
          {/*<InmateFilters />*/}
          <div id='activities-table' style={{height:700,overflowY:'scroll'}}>
            <Dimmer inverted active={this.props.loading}><Loader inverted content="Loading activities ..." /></Dimmer>
            <InfiniteScroll
                pageStart={0}
                loadMore={this.props.fetchNextLogActivities}
                hasMore={!this.props.loading && this.props.hasMore}
                initialLoad={false}
                useWindow={false}
                getScrollParent={() => document.getElementById('activities-table') }
            >
              <Table compact celled striped style={{borderTop:0,marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Record ID</Table.HeaderCell>
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Activity<HeaderArrow orderBy='table' /></div></Table.HeaderCell>
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Data<HeaderArrow orderBy='column' /></div></Table.HeaderCell>
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Timestamp<HeaderArrow orderBy='entity' /></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.logs.map((log:any) => (
                    <LogActivityRow log={log} key={log.recordID} />
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </div>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { logs: state.admin.logs.activities.data, loading: state.admin.logs.activities.loading, hasMore: state.admin.logs.activities.hasMore } },
  AdminStore.actionCreators
)(LogActivities as any)