import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Button,
  Input,
  Table,
  Dropdown,
  Select,
  Popup,
  Modal,
  Form,
  TextArea
} from 'semantic-ui-react'
const _ = require('underscore')

type AppProps =
    { options: any, modal: any, data: any, certificates: any[], num: 1 | 2, print: boolean, admin: boolean } &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class TemplateCertificates extends React.PureComponent<AppProps> {
    public render() {
      return (
        <Container fluid>
          <Table celled striped style={{ marginTop: "1em" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Issued By</Table.HeaderCell>
                <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.certificates.map((certificate:any) => (
                <Table.Row key={certificate.recordID}>
                  <Table.Cell>{certificate.name}</Table.Cell>
                  <Table.Cell>{_.any(this.props.options.institutions, (i:any) => i.key == certificate.institutionID) ? _.find(this.props.options.institutions, (i:any) => i.key == certificate.institutionID).text : ''}</Table.Cell>
                  <Table.Cell>{this.props.print ? null : <Button size="mini" color="green" content="Edit" onClick={() => this.props.openEditCertificateModal(this.props.num, certificate)} />}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Modal
            open={this.props.modal.open}
            onClose={() => this.props.toggleEditCertificateModal(this.props.num)}
            size="small"
          >
            <Modal.Header content={`${this.props.modal.data.recordID ? 'Edit' : 'Create'} Certificate`} />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Certificate name'
                    placeholder='Certificate name'
                    value={this.props.modal.data.name || ''}
                    onChange={(e:any, data:any) => this.props.updateEditCertificateData(this.props.num, 'name', data.value.validate())}
                    width={8}
                  />
                  <Form.Field
                    control={Select}
                    label='Institution'
                    placeholder='Institution'
                    options={this.props.options.institutions}
                    value={this.props.modal.data.institutionID}
                    onChange={(e:any, data:any) => this.props.updateEditCertificateData(this.props.num, 'institutionID', data.value)}
                    width={8}
                    search
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Prerequisites'
                    placeholder='Prerequisites'
                    value={this.props.modal.data.prerequisites || ''}
                    onChange={(e:any, data:any) => this.props.updateEditCertificateData(this.props.num, 'prerequisites', data.value.validate())}
                    width={8}
                  />
                  <Form.Field
                    control={Input}
                    label='Satisfies'
                    placeholder='Satisfies'
                    value={this.props.modal.data.satisfies || ''}
                    onChange={(e:any, data:any) => this.props.updateEditCertificateData(this.props.num, 'satisfies', data.value.validate())}
                    width={8}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    label='Details'
                    placeholder='Details'
                    value={this.props.modal.data.details || ''}
                    onChange={(e:any, data:any) => this.props.updateEditCertificateData(this.props.num, 'details', data.value)}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditCertificateModal(this.props.num)}>Close</Button>
              {this.props.modal.data.recordID ? <Button
                negative
                content="Remove"
                onClick={() => this.props.removeEditCertificateModal(this.props.num)}
              /> : null}
              <Button
                positive
                content={`${this.props.modal.data.recordID ? 'Save' : 'Create'}`}
                onClick={() => this.props.modal.data.recordID ? this.props.saveEditCertificateModal(this.props.num) : this.props.saveNewTemplateCertificateModal(this.props.num)}
              />
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
    (state: ApplicationState, ownProps: any) => { return { data: ownProps.data, titles: ownProps.titles, options: ownProps.options, num: ownProps.num, print: ownProps.print, admin: state.staff.staff!.programAdmin } },
    PanesStore.actionCreators
)(TemplateCertificates as any) 