import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import ClassRosterMoveSchedules from './ClassRosterMoveSchedules'
import ClassRosterMoveSessions from './ClassRosterMoveSessions'
import ClassRosterSessionsAdd from './ClassRosterSessionsAdd'
import ClassRosterSessionsRemove from './ClassRosterSessionsRemove'
import ClassRosterDisplaceFrom from './ClassRosterDisplaceFrom'
import ClassRosterDisplaceTo from './ClassRosterDisplaceTo'
import DatePicker from 'react-datepicker'
import { format, getDay, startOfToday } from 'date-fns'
import {
  Container,
  Header,
  Icon,
  Grid,
  Form,
  Checkbox,
  Input,
  Select,
  Search,
  Button,
  Modal,
  Table,
  Divider,
  Popup,
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { class: PanesStore.Class, num: 1 | 2, admin: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassRosterEdit extends React.PureComponent<RosterProps> {
    public componentDidMount() {
        this.props.refreshClass(this.props.num, this.props.class.info.recordID)
    }

    private dayOfWeek = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public render() {
        const klass = this.props.class.info
        const roster = this.props.class.roster
        const schedules = this.props.class.schedules
        const move = this.props.class.modals.move

        var movePanes = [
          { menuItem: 'Schedules', render: () => <Tab.Pane><ClassRosterMoveSchedules class={this.props.class} num={this.props.num} /></Tab.Pane> },
          { menuItem: 'Sessions', render: () => <Tab.Pane><ClassRosterMoveSessions class={this.props.class} num={this.props.num} /></Tab.Pane> }
        ]

        var sessionPanes = [
          { menuItem: 'Add', render: () => <Tab.Pane><ClassRosterSessionsAdd class={this.props.class} num={this.props.num} /></Tab.Pane> },
          { menuItem: 'Remove', render: () => <Tab.Pane><ClassRosterSessionsRemove class={this.props.class} num={this.props.num} /></Tab.Pane> }
        ]

        var displacePanes = [
          { menuItem: 'From', render: () => <Tab.Pane><ClassRosterDisplaceFrom class={this.props.class} num={this.props.num} /></Tab.Pane> },
          { menuItem: 'To', render: () => <Tab.Pane><ClassRosterDisplaceTo class={this.props.class} num={this.props.num} /></Tab.Pane> }
        ]

        return (
          <Container fluid>
            <Grid padded>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header as='h3' style={{marginTop:7}}>
                    <Icon name='user' />
                    {roster.ta ? <Popup content="Inmate is a TA" position="top center" trigger={<Icon name="graduation" color="blue" style={{fontSize:'1em',paddingLeft:2,paddingRight:2}} />} /> : null}
                    {roster.transferred ? <Popup content="Inmate has been transferred to another facility" position="top center" trigger={<Icon name="exclamation" color="red" style={{fontSize:'1em',paddingLeft:2,paddingRight:2}} />} /> : null}
                    {roster.suspended ? <Popup content="Inmate has been suspended" position="top center" trigger={<Icon name="dont" color="red" style={{fontSize:'1em',paddingLeft:2,paddingRight:2}} />} /> : null}
                    {roster.quarantined ? <Popup content="Inmate has been quarantined" position="top center" trigger={<Icon name="plus square" color="red" style={{fontSize:'1em',paddingLeft:2,paddingRight:2}} />} /> : null}
                    {roster.displaced ? <Popup content="Inmate has been displaced" position="top center" trigger={<Icon name="exchange" color="red" style={{fontSize:'1em',paddingLeft:2,paddingRight:2}} />} /> : null}
                    {roster.lowAttendance ? <Popup content="Inmate has low attendance" position="top center" trigger={<Icon name="calendar minus" color="red" style={{fontSize:'1em',paddingLeft:2,paddingRight:2}} />} /> : null}
                    {roster.schedules.length == 0 ? <Popup content="Inmate has no schedules assigned" position="top center" trigger={<Icon name="calendar times" color="red" style={{fontSize:'1em',paddingLeft:2,paddingRight:2}} />} /> : null}
                    <div style={{display:'table-cell',verticalAlign:'middle',paddingLeft:2}}>{this.props.class.roster.lastName}, {this.props.class.roster.firstName}</div>
                  </Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button circular color='blue' icon={this.props.num == 1 ? 'arrow right' : 'arrow left'} onClick={() => { this.props.fetchInmate(this.props.num == 1 ? 2 : 1, roster.inmateID); this.props.updateMode('dual') }} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form>
                    <Form.Group>
                      <Form.Field
                        label='Start date'
                        control={DatePicker}
                        selected={roster.dateStart ? new Date(roster.dateStart) : null}
                        onChange={(date:any) => this.props.editRosterField(this.props.num, 'dateStart', date ? format(date, 'MM/dd/yyyy') : null)}
                      />
                      <Button color='blue' content='Manage sessions' onClick={() => this.props.openSessionsModal(this.props.num)} style={{height:33,marginTop:17}} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                          control={Checkbox}
                          label="TA"
                          name="ta"
                          checked={roster.ta}
                          onChange={() => this.props.editRosterField(this.props.num, 'ta', !roster.ta)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <div style={{marginLeft:6,marginTop:4}}><b>Meeting times</b></div>
                    </Form.Group>
                    <Form.Group style={{flexWrap:'wrap'}}>
                      {schedules.length == 0 ? <div style={{marginLeft:6}}>No class meeting times set</div> : <Fragment>
                        <Table compact celled striped>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell></Table.HeaderCell>
                              <Table.HeaderCell>Day</Table.HeaderCell>
                              <Table.HeaderCell>Start</Table.HeaderCell>
                              <Table.HeaderCell>End</Table.HeaderCell>
                              <Table.HeaderCell>Start Date</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {schedules.map((schedule:any, i:number) => {
                              var existing = _.find(roster.scheduleData, (s:any) => s.recordID == schedule.recordID && s.linkID != null)
                              return (
                                <Fragment key={schedule.recordID}>
                                  <Table.Row>
                                    <Table.Cell><Checkbox checked={_.any(roster.scheduleData, (s:any) => s.recordID == schedule.recordID)} onClick={() => this.props.toggleRosterSchedule(this.props.num, schedule)} /></Table.Cell>
                                    <Table.Cell>{`${this.dayOfWeek(schedule.dayOfWeek)}`}</Table.Cell>
                                    <Table.Cell>{`${schedule.startTime}`}</Table.Cell>
                                    <Table.Cell>{`${schedule.endTime}`}</Table.Cell>
                                    <Table.Cell><DatePicker selected={existing && existing.startDate ? new Date(existing.startDate) : null} onChange={(date:any) => this.props.rosterScheduleChange(this.props.num, schedule.recordID, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)} filterDate={(date:any) => getDay(date) == schedule.dayOfWeek && date >= new Date(roster.dateStart) && date >= new Date(schedule.startDate) && date <= (roster.dateEnd ? new Date(roster.dateEnd) : new Date(klass.dateEnds))} /></Table.Cell>
                                  </Table.Row>
                                  {schedule.grouped.map((schedule:any, i:number) => {
                                    var existingG = _.find(roster.scheduleData, (s:any) => s.recordID == schedule.recordID)
                                    return (
                                      <Table.Row key={schedule.recordID}>
                                        <Table.Cell><Checkbox checked={_.any(roster.scheduleData, (s:any) => s.recordID == schedule.recordID)} onClick={() => this.props.toggleRosterSchedule(this.props.num, schedule)} /></Table.Cell>
                                        <Table.Cell>{`${this.dayOfWeek(schedule.dayOfWeek)}`}</Table.Cell>
                                        <Table.Cell>{`${schedule.startTime}`}</Table.Cell>
                                        <Table.Cell>{`${schedule.endTime}`}</Table.Cell>
                                        <Table.Cell><DatePicker selected={existingG && existingG.startDate ? new Date(existingG.startDate) : null} onChange={(date:any) => this.props.rosterScheduleChange(this.props.num, schedule.recordID, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)} filterDate={(date:any) => getDay(date) == schedule.dayOfWeek && date >= new Date(roster.dateStart) && date >= new Date(schedule.startDate) && date <= (roster.dateEnd ? new Date(roster.dateEnd) : new Date(klass.dateEnds))} /></Table.Cell>
                                      </Table.Row>
                                    )
                                  })}
                                </Fragment>
                              )
                            })}
                          </Table.Body>
                        </Table>
                      </Fragment>}
                    </Form.Group>
                    <Button positive content="Save" onClick={_.debounce(() => this.props.saveRoster(this.props.num), 10000, true)} />
                    {roster.suspended ? <Button negative content="Unsuspend" onClick={() => this.props.unsuspendRoster(this.props.num)} style={{marginLeft:10}} /> : <Button positive content="Suspend" onClick={_.debounce(() => this.props.suspendRoster(this.props.num), 10000, true)} style={{marginLeft:10}} />}
                    {roster.quarantined ? <Button negative content="Unquarantine" onClick={() => this.props.releaseRoster(this.props.num)} /> : <Button positive content="Quarantine" onClick={_.debounce(() => this.props.quarantineRoster(this.props.num), 10000, true)} />}
                  </Form>
                  {roster.suspensions.length == 0 ? null : <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Suspended</Table.HeaderCell>
                        <Table.HeaderCell>Unsuspended</Table.HeaderCell>
                        <Table.HeaderCell>Staff name</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {roster.suspensions.map((suspension:any) => (
                        <Table.Row key={suspension.recordID}>
                          <Table.Cell>{suspension.suspended}</Table.Cell>
                          <Table.Cell>{suspension.unsuspended}</Table.Cell>
                          <Table.Cell>{suspension.staffName}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>}
                  {roster.quarantines.length == 0 ? null : <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Quarantined</Table.HeaderCell>
                        <Table.HeaderCell>Unquarantined</Table.HeaderCell>
                        <Table.HeaderCell>Staff name</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {roster.quarantines.map((quarantine:any) => (
                        <Table.Row key={quarantine.recordID}>
                          <Table.Cell>{quarantine.quarantined}</Table.Cell>
                          <Table.Cell>{quarantine.released}</Table.Cell>
                          <Table.Cell>{quarantine.staffName}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>}
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        className="grade"
                        label="Grade"
                        name="grade"
                        options={this.props.class.options.grades}
                        value={roster.grade}
                        onChange={(e:any, data:any) => this.props.editRosterField(this.props.num, 'grade', data.value)}
                      />
                    </Form.Group>
                    {roster.grade == null ? null : <Form.Group>
                      <Form.Field
                        control={Input}
                        label="Reason"
                        name="reason"
                        value={roster.reason || ''}
                        onChange={(e:any, data:any) => this.props.editRosterField(this.props.num, 'reason', data.value.validate())}
                        width={14}
                      />
                    </Form.Group>}
                    <Button positive content="Move to waitlist" onClick={_.debounce(() => this.props.waitlistRoster(this.props.num), 10000, true)} />
                    <Button positive content="Complete" onClick={_.debounce(() => this.props.completeRoster(this.props.num), 10000, true)} />
                    {!this.props.admin && (this.props.class.roster.aCount > 0 || this.props.class.roster.xCount > 0 || this.props.class.roster.zCount > 0) ? null : <Fragment>
                      <Button negative content="Delete" onClick={_.debounce(() => this.props.deleteRoster(this.props.num), 10000, true)} />
                      <br/>
                      <Checkbox checked={roster.archive} onChange={(e:any, data:any) => this.props.editRosterField(this.props.num, 'archive', data.checked)} style={{display:'inline-block',verticalAlign:'middle',marginLeft:4,marginTop:5}} />
                      <label style={{verticalAlign:'sub',marginLeft:4}}>Delete from reporting</label>
                    </Fragment>}
                  </Form>
                  <Form style={{marginTop:10}}>
                    <Form.Group style={{margin:0}}>
                      <Search
                        placeholder='Search classes'
                        loading={this.props.class.move.loading}
                        onResultSelect={(e:any, data:any) => this.props.selectMoveClass(this.props.num, data.result)}
                        onSearchChange={(e:any, data:any) => this.props.searchMoveClasses(this.props.num, data.value as string)}
                        results={this.props.class.options.classes}
                        value={this.props.class.move.value}
                        onFocus={() => this.props.clearMoveClasses(this.props.num)}
                      />
                      {new Date(this.props.class.data.dateBegins) <= startOfToday() ? <Form.Field
                        control={Select}
                        placeholder="Grade"
                        options={[{ key: 0, value: 'CE', text: 'CE' }, window.location.host.split(".")[0] == "sas" ? { key: 1, value: 'W/R', text: 'W/R' } : { key: 1, value: 'W/S', text: 'W/S' }]}
                        value={this.props.class.move.grade}
                        onChange={(e:any, data:any) => this.props.updateMoveGrade(this.props.num, data.value)}
                        style={{minWidth:80}}
                      /> : null}
                    </Form.Group>
                    <Form.Group style={{marginTop:10,marginBottom:0}}>
                      <Button color='blue' content='Move inmate' onClick={() => this.props.openMoveModal(this.props.num)} style={{marginLeft:7}} />
                      <Button color='blue' content='Temp. displace' onClick={() => this.props.openDisplaceModal(this.props.num)} style={{marginLeft:7}} />
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Modal
              open={this.props.class.modals.displace.open}
              onClose={() => this.props.toggleDisplaceModal(this.props.num)}
              size="tiny"
            >
              <Modal.Header content={`Temporary Displacement`} />
              <Modal.Content>
                <Tab menu={{ secondary: true, pointing: true }} panes={displacePanes} activeIndex={this.props.class.modals.sessions.data.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchDisplaceTab(this.props.num, activeIndex) }} />
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleDisplaceModal(this.props.num)}>Close</Button>
                <Button
                  positive
                  content='Displace'
                  onClick={_.debounce(() => this.props.displaceInmate(this.props.num), 10000, true)}
                />
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.class.modals.sessions.open}
              onClose={() => this.props.toggleSessionsModal(this.props.num)}
              size="tiny"
            >
              <Modal.Header content={`Session Management`} />
              <Modal.Content>
                <Tab menu={{ secondary: true, pointing: true }} panes={sessionPanes} activeIndex={this.props.class.modals.sessions.data.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchSessionsTab(this.props.num, activeIndex) }} />
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleSessionsModal(this.props.num)}>Close</Button>
                <Button
                  content={this.props.class.modals.sessions.data.index == 0 ? 'Add' : 'Remove'}
                  color={this.props.class.modals.sessions.data.index == 0 ? 'green' : 'red'}
                  onClick={_.debounce(() => this.props.class.modals.sessions.data.index == 0 ? this.props.addSessions(this.props.num) : this.props.removeSessions(this.props.num), 10000, true)}
                />
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.class.modals.move.open}
              onClose={() => this.props.toggleMoveModal(this.props.num)}
              size="tiny"
            >
              <Modal.Header content={`Move to ${this.props.class.move.value}`} />
              <Modal.Content>
                <Form>
                  <Form.Group>
                    <Form.Field
                      label="Start Date"
                      control={DatePicker}
                      selected={move.data.startDate && move.data.startDate.length > 0 ? new Date(move.data.startDate) : null}
                      onChange={(date:any) => this.props.updateMoveData(this.props.num, 'startDate', date ? format(date, 'MM/dd/yyyy') : '')}
                    />
                    <Checkbox
                      label='W/L'
                      checked={move.data.waitlist}
                      onChange={(e:any, data:any) => this.props.updateMoveData(this.props.num, 'waitlist', data.checked)}
                      style={{margin:'32px 0 0 10px'}}
                    />
                    <Checkbox
                      label='TA'
                      checked={move.data.ta}
                      onChange={(e:any, data:any) => this.props.updateMoveData(this.props.num, 'ta', data.checked)}
                      style={{margin:'32px 0 0 10px'}}
                    />
                  </Form.Group>
                </Form>
                <Tab menu={{ secondary: true, pointing: true }} panes={movePanes} activeIndex={this.props.class.move.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchMoveInmateTab(this.props.num, activeIndex) }} />
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleMoveModal(this.props.num)}>Close</Button>
                <Button
                  positive
                  content="Move"
                  onClick={_.debounce(() => this.props.moveInmate(this.props.num), 10000, true)}
                />
              </Modal.Actions>
            </Modal>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, admin: state.staff.staff!.admin } },
  PanesStore.actionCreators
)(ClassRosterEdit as any)
