import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Grid,
  Form,
  Header,
  Select,
  Table,
  TextArea,
  Input,
  Button,
  Modal,
  Popup,
  Checkbox
} from 'semantic-ui-react'

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2 } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateScreenings extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchScreenings(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      return (
        <Container fluid>
          <div style={{display:'inline-block',marginTop:20}}>
            <Header as='h3'>Screenings</Header>
          </div>
          <Button positive circular icon='plus' onClick={() => this.props.toggleScreening(this.props.num)} style={{marginLeft:10,marginBottom:10}} />
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Facility</Table.HeaderCell>
                        <Table.HeaderCell>Counselor</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.screenings ? this.props.inmate.screenings.map((screening:any) => (
                        <Table.Row key={screening.recordID} onClick={() => this.props.editScreening(this.props.num, this.props.inmate.info.recordID, screening.recordID)}>
                          <Table.Cell>{screening.date}</Table.Cell>
                          <Table.Cell>{screening.facilityAbbr}</Table.Cell>
                          <Table.Cell>{screening.counselor}</Table.Cell>
                        </Table.Row>
                      )) : null}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <Modal
            open={this.props.inmate.modals.screening.open}
            onClose={() => this.props.toggleScreening(this.props.num)}
          >
            <Modal.Header content={this.props.inmate.modals.screening.data.recordID ? 'Save Screening' : 'Add Screening'} />
            <Modal.Content>
              <Grid padded>
                <Grid.Column width={16} style={{marginBottom:0}}>
                  <Form>
                    <Form.Group>
                      <Form.Field
                        label="Screening Date"
                        placeholderText="Screening Date"
                        control={DatePicker}
                        selected={this.props.inmate.modals.screening.data.screeningDate ? new Date(this.props.inmate.modals.screening.data.screeningDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'screening', 'screeningDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                      <Form.Field
                        label="Date Released"
                        placeholderText="Date Released"
                        control={DatePicker}
                        selected={this.props.inmate.modals.screening.data.dateReleased ? new Date(this.props.inmate.modals.screening.data.dateReleased) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'screening', 'dateReleased', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                      <Form.Field
                        search
                        control={Select}
                        label="Facility"
                        placeholder="Facility"
                        name="facilityID"
                        options={this.props.inmate.options.facilities}
                        value={this.props.inmate.modals.screening.data.facilityID}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'facilityID', data.value)}
                        width={4}
                      />
                      <Form.Field
                        search
                        control={Select}
                        label="Completed By"
                        placeholder="Completed By"
                        name="staffID"
                        options={this.props.inmate.options.staff}
                        value={this.props.inmate.modals.screening.data.staffID}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'staffID', data.value)}
                        width={4}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        label="Recommended Treatment Level"
                        name="recommendedTreatment"
                        options={this.props.inmate.options.recommendations}
                        value={this.props.inmate.modals.screening.data.recommendedTreatment}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'recommendedTreatment', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Input}
                        label="Other"
                        placeholder="Other"
                        name="recTreatmentOther"
                        value={this.props.inmate.modals.screening.data.recTreatmentOther||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'recTreatmentOther', data.value.validate())}
                        width={4}
                      />
                      <Form.Field
                        control={Select}
                        label="Reason for Recommendation"
                        name="reason"
                        options={this.props.inmate.options.reasons}
                        value={this.props.inmate.modals.screening.data.reason}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'reason', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Input}
                        label="Other"
                        placeholder="Other"
                        name="reasonOther"
                        value={this.props.inmate.modals.screening.data.reasonOther||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'reasonOther', data.value.validate())}
                        width={4}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        label="Psych History"
                        placeholder="Psych History"
                        name="psychHistory"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.screening.data.psychHistory}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'psychHistory', data.value)}
                        width={5}
                      />
                      <Form.Field
                        control={Select}
                        label="Medication"
                        placeholder="Medication"
                        name="medication"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.screening.data.medication}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'medication', data.value)}
                        width={6}
                      />
                      <Form.Field
                        control={Select}
                        label="Medical Problems"
                        placeholder="Medical Problems"
                        name="medicalProblems"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.screening.data.medicalProblems}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'medicalProblems', data.value)}
                        width={5}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        label="Disabilities"
                        placeholder="Disabilities"
                        name="disabilities"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.screening.data.disabilities}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'disabilities', data.value)}
                        width={5}
                      />
                      <Form.Field
                        control={Select}
                        label="Sex Offender"
                        placeholder="Sex Offender"
                        name="sexOffender"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.screening.data.sexOffender}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'sexOffender', data.value)}
                        width={6}
                      />
                      <Form.Field
                        control={Select}
                        label="IV Drug User"
                        placeholder="IV Drug User"
                        name="ivDrugUser"
                        options={[{key:'Y',value:true,text:'Yes'},{key:'N',value:false,text:'No'}]}
                        value={this.props.inmate.modals.screening.data.ivDrugUser}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ivDrugUser', data.value)}
                        width={5}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        multiple
                        control={Select}
                        label="Drugs of Choice"
                        placeholder="Drugs of Choice"
                        name="drugsOfChoice"
                        options={this.props.inmate.options.drugs}
                        value={this.props.inmate.modals.screening.data.drugsOfChoice||[]}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'drugsOfChoice', data.value)}
                        width={8}
                      />
                      <Form.Field
                        control={TextArea}
                        label="Notes"
                        placeholder="Notes"
                        name="comments"
                        value={this.props.inmate.modals.screening.data.comments||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'comments', data.value)}
                        rows={1}
                        width={8}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        label="Prior Community Outpatient"
                        width={4}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Yes"
                        name="ptCommOut"
                        checked={this.props.inmate.modals.screening.data.ptCommOut}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptCommOut', data.checked)}
                        width={2}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Completed"
                        name="ptCommOutComp"
                        checked={this.props.inmate.modals.screening.data.ptCommOutComp}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptCommOutComp', data.checked)}
                        width={3}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={DatePicker}
                        placeholderText="Date Entered"
                        selected={this.props.inmate.modals.screening.data.ptCommOutDate ? new Date(this.props.inmate.modals.screening.data.ptCommOutDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptCommOutDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        label="Prior Community Inpatient"
                        width={4}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Yes"
                        name="ptCommIn"
                        checked={this.props.inmate.modals.screening.data.ptCommIn}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptCommIn', data.checked)}
                        width={2}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Completed"
                        name="ptCommInComp"
                        checked={this.props.inmate.modals.screening.data.ptCommInComp}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptCommInComp', data.checked)}
                        width={3}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={DatePicker}
                        placeholderText="Date Entered"
                        selected={this.props.inmate.modals.screening.data.ptCommInDate ? new Date(this.props.inmate.modals.screening.data.ptCommInDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptCommInDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        label="Prior Kashbox"
                        width={4}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Yes"
                        name="ptKashbox"
                        checked={this.props.inmate.modals.screening.data.ptKashbox}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptKashbox', data.checked)}
                        width={2}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Completed"
                        name="ptKashboxComp"
                        checked={this.props.inmate.modals.screening.data.ptKashboxComp}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptKashboxComp', data.checked)}
                        width={3}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={DatePicker}
                        placeholderText="Date Entered"
                        selected={this.props.inmate.modals.screening.data.ptKashboxDate ? new Date(this.props.inmate.modals.screening.data.ptKashboxDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptKashboxDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        label="Prior Level II"
                        width={4}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Yes"
                        name="ptLevelII"
                        checked={this.props.inmate.modals.screening.data.ptLevelII}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptLevelII', data.checked)}
                        width={2}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Completed"
                        name="ptLevelIIComp"
                        checked={this.props.inmate.modals.screening.data.ptLevelIIComp}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptLevelIIComp', data.checked)}
                        width={3}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={DatePicker}
                        placeholderText="Date Entered"
                        selected={this.props.inmate.modals.screening.data.ptLevelIIDate ? new Date(this.props.inmate.modals.screening.data.ptLevelIIDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptLevelIIDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        label="Prior Lifeline"
                        width={4}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Yes"
                        name="ptLifeline"
                        checked={this.props.inmate.modals.screening.data.ptLifeline}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptLifeline', data.checked)}
                        width={2}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Completed"
                        name="ptLifelineComp"
                        checked={this.props.inmate.modals.screening.data.ptLifelineComp}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptLifelineComp', data.checked)}
                        width={3}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={DatePicker}
                        placeholderText="Date Entered"
                        selected={this.props.inmate.modals.screening.data.ptLifelineDate ? new Date(this.props.inmate.modals.screening.data.ptLifelineDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptLifelineDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        label="Prior Crossroads"
                        width={4}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Yes"
                        name="ptCrossroads"
                        checked={this.props.inmate.modals.screening.data.ptCrossroads}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptCrossroads', data.checked)}
                        width={2}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Completed"
                        name="ptCrossroadsComp"
                        checked={this.props.inmate.modals.screening.data.ptCrossroadsComp}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptCrossroadsComp', data.checked)}
                        width={3}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={DatePicker}
                        placeholderText="Date Entered"
                        selected={this.props.inmate.modals.screening.data.ptCrossroadsDate ? new Date(this.props.inmate.modals.screening.data.ptCrossroadsDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptCrossroadsDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        label="Prior Other"
                        width={4}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Yes"
                        name="ptOther"
                        checked={this.props.inmate.modals.screening.data.ptOther}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptOther', data.checked)}
                        width={2}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={Checkbox}
                        label="Completed"
                        name="ptOtherComp"
                        checked={this.props.inmate.modals.screening.data.ptOtherComp}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptOtherComp', data.checked)}
                        width={3}
                        style={{marginTop:10}}
                      />
                      <Form.Field
                        control={DatePicker}
                        placeholderText="Date Entered"
                        selected={this.props.inmate.modals.screening.data.ptOtherDate ? new Date(this.props.inmate.modals.screening.data.ptOtherDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptOtherDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                      <Form.Field
                        control={Input}
                        placeholder="Other"
                        name="ptOtherWhat"
                        value={this.props.inmate.modals.screening.data.ptOtherWhat||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'screening', 'ptOtherWhat', data.value.validate())}
                        width={3}
                      />
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleScreening(this.props.num)}>Close</Button>
              {!this.props.inmate.modals.screening.data.staffID || !this.props.inmate.modals.screening.data.facilityID || !this.props.inmate.modals.screening.data.screeningDate ? <Popup position='top center' content='Please make sure the screening has a date, facility, and staff member associated with it.' trigger={<Button>{this.props.inmate.modals.screening.data.recordID ? 'Save' : 'Add'}</Button>} /> : <Button color='green' onClick={() => this.props.inmate.modals.screening.data.recordID ? this.props.saveScreening(this.props.num) : this.props.addScreening(this.props.num)}>{this.props.inmate.modals.screening.data.recordID ? 'Save' : 'Add'}</Button>}
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num } },
  PanesStore.actionCreators
)(InmateScreenings as any)