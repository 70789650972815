import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import ClassRosterAddSchedules from './ClassRosterAddSchedules'
import ClassRosterAddSessions from './ClassRosterAddSessions'
import { format, getDay } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Search,
  Checkbox,
  Button,
  Select,
  Modal,
  Popup,
  Divider,
  Accordion,
  Table,
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { class: PanesStore.Class, num: 1 | 2, staff: any } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassRosterAdd extends React.PureComponent<RosterProps> {
    public resultRenderer(props:any) {
      if (props.repeat == "true") {
        return (
          <Popup position='right center' content='Inmate has taken class before' trigger={
            <div>
              <div className='content'>
                <div className='title'>{props.title}</div>
                <div className='description'>{props.description}</div>
              </div>
              <div style={{position:'absolute',top:0,bottom:0,left:0,right:0,background:'#fff6f6'}} />
            </div>
          } />
        )
      } else if (props.release == "true") {
        return (
          <Popup position='right center' content='Inmate is being released soon' trigger={
            <div>
              <div className='content'>
                <div className='title'>{props.title}</div>
                <div className='description'>{props.description}</div>
              </div>
              <div style={{position:'absolute',top:0,bottom:0,left:0,right:0,background:'#fff6f6'}} />
            </div>
          } />
        ) 
      } else {
        return (
          <div className='content'>
            <div className='title'>{props.title}</div>
            <div className='description'>{props.description}</div>
          </div>
        )
      }
    }

    public render() {
        const add = this.props.class.modals.add
        const info = this.props.class.info
        const today = new Date(new Date().toDateString())

        if (add.data.displace) {
          var panes = [
            { menuItem: 'Sessions', render: () => <Tab.Pane><ClassRosterAddSessions class={this.props.class} num={this.props.num} /></Tab.Pane> }
          ]
        } else {
          var panes = [
            { menuItem: 'Schedules', render: () => <Tab.Pane><ClassRosterAddSchedules class={this.props.class} num={this.props.num} /></Tab.Pane> },
            { menuItem: 'Sessions', render: () => <Tab.Pane><ClassRosterAddSessions class={this.props.class} num={this.props.num} /></Tab.Pane> }
          ]
        }

        return (
          <Container fluid style={{whiteSpace:'normal'}}>
            <Form>
              <Form.Group style={{marginLeft:0,marginRight:0}}>
                <Search
                  placeholder='Add Inmate'
                  loading={this.props.class.add.loading}
                  onResultSelect={(e:any, data:any) => this.props.selectInmate(this.props.num, data.result)}
                  onSearchChange={(e:any, data:any) => this.props.searchInmates(this.props.num, data.value as string)}
                  results={this.props.class.options.inmates}
                  value={this.props.class.add.value}
                  resultRenderer={this.resultRenderer}
                  onFocus={() => this.props.clearInmates(this.props.num)}
                />
                {!this.props.class.add.repeat ? <Button color='blue' content='Select' onClick={() => this.props.addInmate(this.props.num)} style={{marginLeft:'20px'}} /> : <Popup position='right center' content='Warning: This student has already received a grade for this class.' trigger={<Button color='blue' content='Select' onClick={() => this.props.addInmate(this.props.num)} style={{marginLeft:'20px'}} />} />}
                <Form.Field
                  control={Select}
                  placeholder='Search Waitlist'
                  options={this.props.class.options.waitlist}
                  value={this.props.class.add.waitID > 0 ? this.props.class.add.waitID : null}
                  onChange={(e:any, data:any) => this.props.selectWaitlist(this.props.num, data.value as number)}
                  style={{marginLeft:10}}
                  search
                />
                <Button color='blue' content='Select' onClick={() => this.props.addWaitlist(this.props.num)} style={{marginLeft:'20px'}} />
              </Form.Group>
            </Form>
            {_.map(this.props.class.add.inmates, (inmate:any, i:number) => (
              <Button size='mini' content={inmate.name} onClick={_.debounce(() => this.props.removeInmate(this.props.num, inmate.recordID), 10000, true)} color={inmate.waitlist ? undefined : inmate.ta ? 'blue' : 'orange'} key={i} style={{marginTop:4,marginRight:4}} />
            ))}
            {this.props.class.add.inmates.length > 0 ? <div style={{marginTop:20}}><Button positive content='Add inmates' onClick={_.debounce(() => this.props.toggleAddModal(this.props.num), 10000, true)} /></div> : null}
            <Divider />
            <Modal
              open={this.props.class.modals.add.open}
              onClose={() => this.props.toggleAddModal(this.props.num)}
              size="tiny"
            >
              <Modal.Header content={`Add Inmates`} />
              <Modal.Content style={{paddingTop:10}}>
                <Form style={{marginTop:10}}>
                  <Form.Group>
                    <Form.Field
                      label="Start Date"
                      control={DatePicker}
                      selected={add.data.startDate && add.data.startDate.length > 0 ? new Date(add.data.startDate) : null}
                      onChange={(date:any) => this.props.updateAddInmate(this.props.num, 'startDate', date ? format(date, 'MM/dd/yyyy') : '')}
                      filterDate={(date:any) => date >= new Date(info.dateBegins) && date <= new Date(info.dateEnds)}
                    />
                    <Checkbox
                      label='Displace'
                      checked={add.data.displace}
                      onChange={(e:any, data:any) => this.props.updateAddInmate(this.props.num, 'displace', data.checked)}
                      style={{margin:'32px 0 0 10px'}}
                    />
                    <Checkbox
                      label='W/L'
                      checked={add.data.waitlist}
                      onChange={(e:any, data:any) => this.props.updateAddInmate(this.props.num, 'waitlist', data.checked)}
                      style={{margin:'32px 0 0 10px'}}
                    />
                    <Checkbox
                      label='TA'
                      checked={add.data.ta}
                      onChange={(e:any, data:any) => this.props.updateAddInmate(this.props.num, 'ta', data.checked)}
                      style={{margin:'32px 0 0 10px'}}
                    />
                  </Form.Group>
                </Form>
                <Tab menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={this.props.class.add.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchAddInmatesTab(this.props.num, activeIndex) }} />
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleAddModal(this.props.num)}>Close</Button>
                <Button
                  positive
                  content="Add"
                  onClick={_.debounce(() => this.props.addInmates(this.props.num), 10000, true)}
                />
              </Modal.Actions>
            </Modal>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, staff: state.staff.staff } },
  PanesStore.actionCreators
)(ClassRosterAdd as any)