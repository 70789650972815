import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store'
import * as ReportsStore from '../../store/reducers/reports'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import { Editor } from 'medium-draft'
import mediumDraftExporter from 'medium-draft/lib/exporter';
import {
  Header,
  Form,
  Button,
  Select,
  Divider,
  Popup,
  Search
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { admin: boolean } &
    ReportsStore.ReportsState &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class Attendance extends React.PureComponent<ReportProps> {
    public params() {
      let params = new URLSearchParams()
      for (let [key, value] of Object.entries(this.props.reports.attendance.data)) {
        if (value != undefined && value != null && value != '' && key != 'reportTypes' && key != 'inmates' && key != 'classes' && key != 'instructors') {
            if (typeof value == 'object') {
                for (let ovalue of Object.values(value as any)) {
                  params.append(key, ovalue as string)
                }
            } else {
                params.append(key, (value as any).toString())
            }
        }
      }
      return params.toString()
    }

    public render() {
      return (
        <Fragment>
          <Form>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Report type'
                placeholder='Report type'
                options={this.props.reports.attendance.data.reportTypes}
                value={this.props.reports.attendance.data.reportType}
                onChange={(e:any, data:any) => this.props.dataChange('attendance', 'reportType', data.value)}
                width={4}
              />
            </Form.Group>
          </Form>
          <Header as="h2">Attendance Report &#8212; {_.find(this.props.reports[this.props.report].data.reportTypes, (type:any) => type.value == this.props.reports[this.props.report].data.reportType).text}</Header>
          {this.props.admin ? <Fragment>
            <div style={{border:'thin solid #ddd',padding:1,borderRadius:5,marginBottom:12}}>
              <Editor placeholder="Enter a report description..." editorState={this.props.reports.attendance.description} sideButtons={[]} onChange={(state:any) => this.props.updateDescription(state)} />
            </div>
            <Button positive content='Save' onClick={this.props.saveDescription} />
          </Fragment> : null}
          {this.props.admin ? null : <div style={{whiteSpace:'normal'}} dangerouslySetInnerHTML={{__html: mediumDraftExporter(this.props.reports.attendance.description.getCurrentContent())}} />}
          <Divider />
          <Form>
          <Form.Group>
              <Form.Field
                control={DatePicker}
                label="Beginning date"
                name="startDate"
                placeholder="MM/DD/YYYY"
                selected={this.props.reports.attendance.data.startDate ? new Date(this.props.reports.attendance.data.startDate) : null}
                onChange={(date:any) => this.props.dataChange('attendance', 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                width={4}
              />
              <Form.Field
                control={DatePicker}
                label="Ending date"
                name="endDate"
                placeholder="MM/DD/YYYY"
                selected={this.props.reports.attendance.data.endDate ? new Date(this.props.reports.attendance.data.endDate) : null}
                onChange={(date:any) => this.props.dataChange('attendance', 'endDate', date ? format(date, 'MM/dd/yyyy') : null)}
                width={4}
              />
              <Form.Field
                control={Select}
                label='Display type'
                placeholder='Display type'
                options={[{key:0,value:'html',text:'HTML'},{key:1,value:'pdf',text:'PDF'}]}
                value={this.props.reports.attendance.data.displayType}
                onChange={(e:any, data:any) => this.props.dataChange('attendance', 'displayType', data.value)}
                width={4}
              />
              {/*this.props.reports.attendance.data.displayType == 'pdf' ? <Form.Field
                control={Select}
                label='Orientation'
                placeholder='Orientation'
                options={[{key:0,value:'landscape',text:'Landscape'},{key:1,value:'portrait',text:'Portrait'}]}
                value={this.props.reports.attendance.data.orientation}
                onChange={(e:any, data:any) => this.props.dataChange('attendance', 'orientation', data.value)}
                width={4}
              /> : null*/}
            </Form.Group>
            <Form.Group>
              <Button color="teal" name="LastFY" content="Last FY" size="mini" onClick={this.props.lastFY} style={{marginLeft:7}} />
              <Button color="teal" name="LastCY" content="Last CY" size="mini" onClick={this.props.lastCY} />
              <Button color="teal" name="LastQTR" content="Last QTR" size="mini" onClick={this.props.lastQTR} />
              <Button color="teal" name="LastHalf" content="Last Half" size="mini" onClick={this.props.lastHalf} />
              <Button color="teal" name="ThisFY" content="This FY" size="mini" onClick={this.props.thisFY} />
              <Button color="teal" name="ThisCY" content="This CY" size="mini" onClick={this.props.thisCY} />
              <Button color="teal" name="LastMonth" content="Last Month" size="mini" onClick={this.props.lastMonth} />
            </Form.Group>
            {this.props.reports.attendance.data.reportType == 'class' || this.props.reports.attendance.data.reportType == 'cancellations' ? <Fragment>
              <Form.Group>
                <Form.Field
                  control={Select}
                  label='Facilities'
                  placeholder='Facilities'
                  options={[{key:0,value:0,text:'All facilities'}].concat(this.props.reports.attendance.facilities)}
                  value={this.props.reports.attendance.data.facilities}
                  onChange={(e:any, data:any) => this.props.dataChange('attendance', 'facilities', data.value)}
                  multiple
                  width={8}
                />
                <Form.Field
                  control={Select}
                  label='Programs'
                  placeholder='Programs'
                  options={[{key:0,value:0,text:'All Programs'}].concat(this.props.reports.attendance.programs)}
                  value={this.props.reports.attendance.data.programs}
                  onChange={(e:any, data:any) => this.props.dataChange('attendance', 'programs', data.value)}
                  multiple
                  width={8}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Select}
                  label='Active'
                  placeholder='Active'
                  options={[{key:0,value:'active',text:'Active classes'},{key:1,value:'all',text:'All classes'}]}
                  value={this.props.reports.attendance.data.active}
                  onChange={(e:any, data:any) => this.props.dataChange('attendance', 'active', data.value)}
                  width={4}
                />
                <Form.Field
                  control={Select}
                  label='Attendance Type'
                  placeholder='Attendance Type'
                  options={[{key:0,value:'sessions',text:'Sessions'},{key:1,value:'hours',text:'Hours'}]}
                  value={this.props.reports.attendance.data.attnType}
                  onChange={(e:any, data:any) => this.props.dataChange('attendance', 'attnType', data.value)}
                  width={4}
                />
                {this.props.reports.attendance.data.reportType == 'cancellations' ?
                  <Form.Field
                    control={Select}
                    label='Breakdown By'
                    placeholder='Breakdown By'
                    options={[{key:0,value:'class',text:'Class'},{key:1,value:'session',text:'Session'},{key:2,value:'instructor',text:'Instructor'}]}
                    value={this.props.reports.attendance.data.breakdown}
                    onChange={(e:any, data:any) => this.props.dataChange('attendance', 'breakdown', data.value)}
                    width={4}
                  />
                : null}
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Search}
                  label='Search Instructors'
                  loading={this.props.reports.attendance.data.instructorsLoading}
                  onResultSelect={(e:any, data:any) => { this.props.selectInstructor(data.result) }}
                  onSearchChange={(e:any, data:any) => { this.props.searchInstructors(data.value as string) }}
                  results={this.props.reports.attendance.data.instructors}
                  value={this.props.reports.attendance.data.instructor}
                  onFocus={() => {}}
                  width={8}
                  fluid
                />
                <Form.Field
                  control={Search}
                  label='Search Classes'
                  loading={this.props.reports.attendance.data.classesLoading}
                  onResultSelect={(e:any, data:any) => { this.props.selectRosterClass('attendance', data.result) }}
                  onSearchChange={(e:any, data:any) => { this.props.searchRosterClasses('attendance', data.value as string) }}
                  results={this.props.reports.attendance.data.classes}
                  value={this.props.reports.attendance.data.class}
                  onFocus={() => {}}
                  width={8}
                  fluid
                />
              </Form.Group>
            </Fragment> : null}
            {this.props.reports.attendance.data.reportType == 'inmate' ? <Fragment>
              <Form.Group>
                <Form.Field
                  control={Select}
                  label='Facilities'
                  placeholder='Facilities'
                  options={[{key:0,value:0,text:'All facilities'}].concat(this.props.reports.attendance.facilities)}
                  value={this.props.reports.attendance.data.facilities}
                  onChange={(e:any, data:any) => this.props.dataChange('attendance', 'facilities', data.value)}
                  multiple
                  width={8}
                />
                <Form.Field
                  control={Select}
                  label='Programs'
                  placeholder='Programs'
                  options={[{key:0,value:0,text:'All Programs'}].concat(this.props.reports.attendance.programs)}
                  value={this.props.reports.attendance.data.programs}
                  onChange={(e:any, data:any) => this.props.dataChange('attendance', 'programs', data.value)}
                  multiple
                  width={8}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Select}
                  label='Active'
                  placeholder='Active'
                  options={[{key:0,value:'active',text:'Active students'},{key:1,value:'all',text:'All students'}]}
                  value={this.props.reports.attendance.data.active}
                  onChange={(e:any, data:any) => this.props.dataChange('attendance', 'active', data.value)}
                  width={4}
                />
                <Form.Field
                  control={Select}
                  label='Attendance Type'
                  placeholder='Attendance Type'
                  options={[{key:0,value:'sessions',text:'Sessions'},{key:1,value:'hours',text:'Hours'}]}
                  value={this.props.reports.attendance.data.attnType}
                  onChange={(e:any, data:any) => this.props.dataChange('attendance', 'attnType', data.value)}
                  width={4}
                />
                <Form.Field
                  control={Select}
                  label='Education status'
                  placeholder='Education status'
                  options={[{key:0,value:'',text:''},{key:1,value:'current',text:'Currently enrolled'},{key:2,value:'not',text:'Not currently enrolled'},{key:3,value:'previous',text:'Previously enrolled'},{key:4,value:'never',text:'Never enrolled'},{key:5,value:'recent',text:'Recent arrival'},{key:6,value:'title1',text:'Title 1'},{key:7,value:'abe',text:'ABE student'},{key:8,value:'tabe',text:'Ready for TABE'}]}
                  value={this.props.reports.attendance.data.educationStatus}
                  onChange={(e:any, data:any) => this.props.dataChange('attendance', 'educationStatus', data.value)}
                  width={4}
                />
                <Form.Field
                  control={Select}
                  label='Ethnicity'
                  placeholder='Ethnicity'
                  options={[{key:0,value:'',text:''}].concat(this.props.options.ethnicities)}
                  value={this.props.reports.attendance.data.ethnicity}
                  onChange={(e:any, data:any) => this.props.dataChange('attendance', 'ethnicity', data.value)}
                  search
                  width={4}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Search}
                  label='Search Students'
                  loading={this.props.reports.attendance.data.inmatesLoading}
                  onResultSelect={(e:any, data:any) => { this.props.selectInmate(data.result) }}
                  onSearchChange={(e:any, data:any) => { this.props.searchInmates(data.value as string) }}
                  results={this.props.reports.attendance.data.inmates}
                  value={this.props.reports.attendance.data.inmate}
                  onFocus={() => {}}
                  width={8}
                  fluid
                />
              </Form.Group>
            </Fragment> : null}
            {false ? <Form.Group>
              <Form.Field
                control={Select}
                label='Include administrative deletes'
                options={[{key:0,value:false,text:'No'},{key:1,value:true,text:'Yes'}]}
                value={this.props.reports.attendance.data.adminDeletes}
                onChange={(e:any, data:any) => this.props.dataChange('attendance', 'adminDeletes', data.value)}
                width={4}
              />
            </Form.Group> : null}
            {false ? <Popup
                on='hover'
                position='right center'
                trigger={<Button content='Generate report' />}
              >
                <Popup.Content>Must select an inmate</Popup.Content>
              </Popup> : <Fragment>
                {this.props.reports.attendance.data.displayType == 'html' ? <Button color='blue' content='Generate report' as={Link} to={`/reports/attendance/html?${this.params()}`} target='_window' /> : null}
                {this.props.reports.attendance.data.displayType != 'html' ? <Button color='blue' content='Generate report' onClick={this.props.generateAttendance} /> : null}
              </Fragment>
            }
          </Form>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { ...state.reports, admin: state.staff.staff!.admin } },
  ReportsStore.actionCreators
)(Attendance as any)
