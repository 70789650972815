import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Page,
  View,
  BlobProvider,
  Document,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {
  Dimmer,
  Loader,
  Icon
} from 'semantic-ui-react'
import { format } from "date-fns";
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { pdf: any, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class F8401PDF extends React.PureComponent<ReportProps> {
    public render() {
      return (
        <Fragment>
          {this.props.pdf.generating ? <div className='pdf generating' style={{display:'inline-block',verticalAlign:'top',marginLeft:10}}>
            <Icon.Group>
              <Icon name='circle notch' color='grey' loading style={{fontSize:'2.5em'}} />
              <Icon name='file pdf outline' color='grey' style={{fontSize:'1.4em',marginTop:1,marginLeft:-1}} />
            </Icon.Group>
          </div> : null}
          {this.props.pdf.ready ? <BlobProvider document={this.doc()}>
            {(data:any) => {
              return (
                <a target='_window' href={data.url} style={{marginLeft:10}}>
                  <Icon name='file pdf outline' size='big' />
                </a>
              )
            }}
          </BlobProvider> : null}
        </Fragment>
      )
    }

    public doc() {
        const styles = StyleSheet.create({
          page: {
            flexDirection: "column"
          },
      
          image: {
            marginTop: 30,
            marginBottom: 20,
            width: "108pt",
            alignSelf: "center"
          },
      
          psd: {
            fontSize: 12,
            textAlign: "center",
            flexDirection: "column",
            marginBottom: 20
          },
      
          sub_header: {
            fontSize: 12,
            textAlign: "center",
            flexDirection: "column",
            marginBottom: 20
          },
      
          section: {
            marginLeft: 30,
            marginBottom: 10,
            fontSize: 12,
            textDecoration: "underline",
            flexDirection: "row"
          },
      
          check_row: {
            flexDirection: "row",
            marginBottom: 10
          },
      
          check: {
            marginLeft: 30,
            height: 10,
            width: 10
          },
      
          check_text: {
            marginLeft: 5,
            marginRight: 60,
            marginBottom: 10,
            fontSize: 12
          },
      
          text: {
            marginLeft: 30,
            marginRight: 30,
            marginBottom: 10,
            fontSize: 12
          },
      
          sign: {
            marginLeft: 30,
            marginBottom: 20,
            fontSize: 12,
            flexDirection: "row",
            justifyContent: "space-between"
          },
      
          sign1: {
            flex: 1
          },
          sign2: {
            flex: 2
          },
          sign3: {
            flex: 3
          },
          sign4: {
            flex: 4
          },
          sign5: {
            flex: 5
          },
          sign7: {
            flex: 7
          },
      
          footer: {
            fontSize: 6,
            alignSelf: "center",
            position: "absolute",
            bottom: 30
          },
          footerCenter: {
            position: 'absolute',
            textAlign: 'center',
            bottom: 10,
            fontSize: 8,
            color: 'grey'
          },
          footerLeft: {
            position: 'absolute',
            bottom: 10,
            left: 10,
            fontSize: 8,
            color: 'grey'
          },
          footerRight: {
            position: 'absolute',
            bottom: 10,
            right: 10,
            fontSize: 8,
            color: 'grey'
          }
        });
      
        let data = this.props.pdf.data

        const doc = (
          <Document>
            <Page size="LETTER">
              <Image style={styles.image} src="/assets/HawaiiSeal.png" />
              <View style={styles.psd}>
                <Text>STATE OF HAWAII</Text>
                <Text style={{ fontSize: 12, fontWeight: "bold", textTransform: 'uppercase' }}>
                  {this.props.settings.deptName}
                </Text>
                <Text>CPS-E Branch, Keoniana Building 5th Floor</Text>
                <Text>1177 Alakea St.</Text>
                <Text>Honolulu, HI 96813</Text>
              </View>
              <View style={styles.sub_header}>
                <Text>NOTIFICATION OF INMATE REQUIREMENTS UNDER</Text>
                <Text>HRS 353-64 COMMITTED PERSONS PAROLED</Text>
              </View>
              <Text style={styles.section}>NOTIFICATION</Text>
              <Text style={styles.text}>
                Under Hawaii Revised Statutes Section 353-64 Committed Persons
                Paroled, committed persons in any State correctional institution are
                required to participate in an education program as a precondition
                for parole eligibility. The Department will review your educational
                assessments and history to determine you suitable for participation
                in any authorized education program. Should you be determined
                suitable for participation, you must participate in or successfully
                complete a program in order to be considered eligible for parole.
              </Text>
              <Text style={styles.section}>DETERMINATION</Text>
              <Text style={styles.text}>
                A review of your educational assessment and educational history
                indicates that you:
              </Text>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.metPreconditionsForParole ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  have met preconditions for parole eligibility established by HRS
                  353-64.
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {!this.props.options.metPreconditionsForParole ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  have not met the preconditions for parole eligibility established
                  by HRS 353-64 and are referred to the education program section
                  for placement in an authorized education program.
                </Text>
              </View>
              <Text style={styles.section}>ACKNOWLEDGEMENT</Text>
              <Text style={styles.text}>
                I have read the above notification of preconditions for parole
                eligibility established by HRS 353-64. I fully understand these
                preconditions. If I need an accomodation under the Americans with
                Disabilities Act (ADA), I will submit a request. Furthermore, I have
                been advised of the determination for participation and provided the
                opportunity to discuss this matter with a Social Worker and/or a
                Corrections Education Program Specialist.
              </Text>
              <View style={styles.sign}>
                <View style={styles.sign1}>
                  <Text>INMATE:</Text>
                </View>
                <View style={styles.sign5}>
                  <Text>{data.inmate.name}</Text>
                </View>
                <View style={styles.sign1}>
                  <Text>DATE:</Text>
                </View>
                <View style={styles.sign1}>
                  <Text>{this.props.options.signedOn}</Text>
                </View>
              </View>
              <View style={styles.sign}>
                <Text style={styles.sign1}>Signature:</Text>
                <Text style={styles.sign7}>
                  ____________________________________________________________________
                </Text>
              </View>
              <View style={styles.sign}>
                <View style={styles.sign3}>
                  <Text>INFORMED AND COUNSELED BY:</Text>
                </View>
                <View style={styles.sign3}>
                  <Text>{this.props.options.counseledBy}</Text>
                </View>
                <View style={styles.sign1}>
                  <Text>DATE:</Text>
                </View>
                <View style={styles.sign1}>
                  <Text>{this.props.options.counseledOn}</Text>
                </View>
              </View>
              <View style={styles.sign}>
                <Text style={styles.sign1}>Signature:</Text>
                <Text style={styles.sign7}>
                  ____________________________________________________________________
                </Text>
              </View>

              <Text style={styles.footer} fixed>
                PSD 8401 (Revised 12/19)
              </Text>
              <View fixed style={styles.footerCenter}>
                <Text>{this.props.settings.copyright}</Text>
              </View>
              <View fixed style={styles.footerLeft}>
                <Text>{new Date().today() + " @ " + new Date().timeNow()}</Text>
              </View>
              <View fixed style={styles.footerRight}>
                <Text>{data.staffID} / {data.printID}</Text>
              </View>
            </Page>
          </Document>
        );
    
        return doc
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { pdf: ownProps.pdf, options: ownProps.options, settings: state.staff.settings } },
  ReportsStore.actionCreators
)(F8401PDF as any)
