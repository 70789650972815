import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import { Editor, Block, addNewBlock, ImageSideButton } from 'medium-draft'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Button,
  Input,
  Select,
  Divider
} from 'semantic-ui-react'
const _ = require('underscore')

type OpeningProps =
    { opening: any } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class OpeningMessages extends React.PureComponent<OpeningProps> {
    public componentDidMount() {
        this.props.fetchOpening()
    }

    public render() {
      return (
        <Container fluid style={{overflowY:'auto',height:740,paddingRight:5}}>
          <Form>
            <div style={{marginBottom:4}}><label style={{fontSize:'0.9em'}}><b>Notices</b></label></div>
            <div style={{border:'thin solid #ddd',padding:1,borderRadius:5,marginBottom:12}}>
              <Editor placeholder="" editorState={this.props.opening.notices.content} sideButtons={[]} onChange={(state:any) => this.props.updateNoticesContent(state)} />
            </div>
            <Form.Field
              label="Exp Date"
              control={DatePicker}
              selected={this.props.opening.notices.date ? new Date(this.props.opening.notices.date) : null}
              onChange={(date:any) => this.props.updateNoticesDate(date ? format(date, 'MM/dd/yyyy') : null)}
            />
            <Button positive content="Save" onClick={_.debounce(this.props.saveNotices, 10000, true)} />
            <Button color='blue' content="Clear" onClick={_.debounce(this.props.clearNotices, 10000, true)} />
            <Divider />
            <div style={{marginBottom:4}}><label style={{fontSize:'0.9em'}}><b>Announcements</b></label></div>
            <div style={{border:'thin solid #ddd',padding:1,borderRadius:5,marginBottom:12}}>
              <Editor placeholder="" editorState={this.props.opening.announcements.content} sideButtons={[]} onChange={(state:any) => this.props.updateAnnouncementsContent(state)} />
            </div>
            <Form.Field
              label="Exp Date"
              control={DatePicker}
              selected={this.props.opening.announcements.date ? new Date(this.props.opening.announcements.date) : null}
              onChange={(date:any) => this.props.updateAnnouncementsDate(date ? format(date, 'MM/dd/yyyy') : null)}
            />
            <Button positive content="Save" onClick={_.debounce(this.props.saveAnnouncements, 10000, true)} />
            <Button color='blue' content="Clear" onClick={_.debounce(this.props.clearAnnouncements, 10000, true)} />
          </Form>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { opening: state.admin.opening } },
  AdminStore.actionCreators
)(OpeningMessages as any)