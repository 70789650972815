import React, { Fragment } from 'react'
import {
  Table,
  Container
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class RecidivismBaseline extends React.PureComponent<ReportProps> {
    private totals(object:any) {
      let total = 0
      _.each(object, (value:any,key:any) => {
        if (value && typeof value == 'number') { total += value }
      })
      return total
    }

    private percentage(num:number, total:number) {
      return (total == 0 ? '0' : (num / total > 1) ? '100' : (num / total > 0 && num / total < 0.01) ? '<1' : Math.round(num / total * 100)) + "%"
    }

    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let params = this.props.report.data
        let data = this.props.report.generated

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Recidivism Report</div><div style={{marginTop:4}}>{`${data.firstTransfer} through ${data.lastTransfer}`}</div></div>
            </div>
            <div>
              <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
                <div style={{fontWeight:700,fontSize:16}}>Global</div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2013 and Before</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2013.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2014</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2014.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2015</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2015.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2016</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2016.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2017</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2017.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2018</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2018.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2019</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2019.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2020</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2020.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2021</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2021.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2022</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2022.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2023</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2023.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      {data.baseline.total.facilities.map((facility:any, f:number) => {
                        var studentFacility = _.find(data.baseline.students.total.facilities, (f:any) => f.name == facility.name)
                        var inmateFacility = _.find(data.baseline.inmates.total.facilities, (f:any) => f.name == facility.name)
                        return (<Table.Row key={f} style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.total.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.name}</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.total.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.total}</div></Table.Cell>
                        </Table.Row>)
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, latest age)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 to 29</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 to 39</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 to 49</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 to 59</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 to 69</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 to 79</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 to 89</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, earliest age)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.students.totalUnique.earlyAges) + this.totals(data.baseline.inmates.totalUnique.earlyAges)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.totalFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentencedUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentencedUnique.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentencedUnique.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentencedUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2013 and Before</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2013.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2014</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2014.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2015</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2015.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2016</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2016.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2017</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2017.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2018</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2018.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2019</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2019.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2020</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2020.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2021</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2021.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2022</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2022.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2023</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2023.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      {data.baseline.totalSentenced.facilities.map((facility:any, f:number) => {
                        var studentFacility = _.find(data.baseline.students.totalSentenced.facilities, (f:any) => f.name == facility.name)
                        var inmateFacility = _.find(data.baseline.inmates.totalSentenced.facilities, (f:any) => f.name == facility.name)
                        return (<Table.Row key={f} style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.totalSentenced.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.name}</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.totalSentenced.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.total}</div></Table.Cell>
                        </Table.Row>)
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.totalSentencedFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2013 and Before</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2013.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2014</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2014.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2015</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2015.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2016</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2016.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2017</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2017.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2018</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2018.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2019</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2019.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2020</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2020.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2021</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2021.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2022</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2022.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2023</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2023.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      {data.baseline.totalPretrial.facilities.map((facility:any, f:number) => {
                        var studentFacility = _.find(data.baseline.students.totalPretrial.facilities, (f:any) => f.name == facility.name)
                        var inmateFacility = _.find(data.baseline.inmates.totalPretrial.facilities, (f:any) => f.name == facility.name)
                        return (<Table.Row key={f} style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.totalPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.name}</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.totalPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.total}</div></Table.Cell>
                        </Table.Row>)
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrialUnique.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrialUnique.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrialUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 0 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.students.totalPretrialFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2013 and Before</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2013.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2014</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2014.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2015</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2015.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2016</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2016.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2017</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2017.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2018</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2018.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2019</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2019.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2020</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2020.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2021</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2021.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2022</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2022.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2023</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2023.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, latest age)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, earliest age)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      {data.baseline.recidivism.facilities.map((facility:any, f:number) => {
                        var studentFacility = _.find(data.baseline.students.recidivism.facilities, (f:any) => f.name == facility.name)
                        var inmateFacility = _.find(data.baseline.inmates.recidivism.facilities, (f:any) => f.name == facility.name)
                        return (<Table.Row key={f} style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.recidivism.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.name}</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.recidivism.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.total}</div></Table.Cell>
                        </Table.Row>)
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.recidivismFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2013 and Before</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2013.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2014</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2014.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2015</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2015.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2016</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2016.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2017</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2017.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2018</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2018.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2019</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2019.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2020</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2020.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2021</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2021.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2022</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2022.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2023</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2023.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      {data.baseline.recidivismPretrial.facilities.map((facility:any, f:number) => {
                        var studentFacility = _.find(data.baseline.students.recidivismPretrial.facilities, (f:any) => f.name == facility.name)
                        var inmateFacility = _.find(data.baseline.inmates.recidivismPretrial.facilities, (f:any) => f.name == facility.name)
                        return (<Table.Row key={f} style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.recidivismPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.name}</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.recidivismPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.total}</div></Table.Cell>
                        </Table.Row>)
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, latest age)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, earliest age)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}></div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.recidivismPretrialFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/2}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'2em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return Breakdowns</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Unknown</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Non-sentenced to non-sentenced</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToPre.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToPre.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToPre.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToPre.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Non-sentenced to sentenced</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToSen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToSen.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToSen.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToSen.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sentenced to non-sentenced</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToPre.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToPre.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToPre.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToPre.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sentenced to sentenced</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToSen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToSen.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToSen.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToSen.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/2}%`}}></div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Number of Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Unknown</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.one.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.two.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.three.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.four.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.five.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.six.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.seven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.eight.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.nine.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.ten.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.eleven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.twelve.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.thirteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.fourteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.fifteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.sixteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.seventeen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.eighteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.nineteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.male.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.female.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.genders.unknown.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.twentyPlus.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returns.genders.male)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returns.genders.female)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returns.genders.unknown)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returns)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Number of Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Unknown</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.one.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.two.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.three.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.four.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.five.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.six.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.seven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.eight.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.nine.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.ten.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.eleven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.twelve.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.thirteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.fourteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.fifteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.sixteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.seventeen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.eighteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.nineteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.male.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.female.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.genders.unknown.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.twentyPlus.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsUnique.genders.male)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsUnique.genders.female)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsUnique.genders.unknown)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsUnique)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'2em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Number of Returns from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Unknown</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.one.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.two.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.three.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.four.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.five.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.six.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.seven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.eight.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.nine.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.ten.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.eleven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.twelve.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.thirteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.fourteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.fifteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.sixteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.seventeen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.eighteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.nineteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.male.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.female.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.genders.unknown.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.twentyPlus.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsSent.genders.male)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsSent.genders.female)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsSent.genders.unknown)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsSent)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'2em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Number of Returns from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Unknown</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.one.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.two.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.three.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.four.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.five.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.six.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.seven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.eight.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.nine.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.ten.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.eleven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.twelve.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.thirteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.fourteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.fifteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.sixteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.seventeen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.eighteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.nineteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.male.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.female.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.genders.unknown.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.twentyPlus.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsSentUnique.genders.male)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsSentUnique.genders.female)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsSentUnique.genders.unknown)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsSentUnique)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </Container>
        )
      }
}

export default RecidivismBaseline