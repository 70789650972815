import React, { Fragment } from 'react'
import { format } from 'date-fns'
import {
  Image,
  Container
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class UnexcusedAbsence extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Roboto') || document.fonts.check('bold 1em Roboto'))
              }`}} />
              <body style={{fontSize:'1.2em',lineHeight:'1.2em',fontFamily:'Roboto',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let data = this.props.report.data

        return (
            <Container fluid>
              {data.rosters.map((roster: any) => (
                <div key={roster.recordID} style={{pageBreakAfter:'always'}}>
                  <div style={{flexDirection:'column'}}>
                    <div>
                      <div style={{flexDirection:'row'}}>
                        <Image src="https://sms.psd-hi.com/assets/HawaiiSeal.png" style={{float:'right',width:70}} />
                        <Image src="https://sms.psd-hi.com/assets/RightGraphic.jpg" style={{float:'right',width:70}} />
                        <div style={{flexDirection:'column',textAlign:'center',fontWeight:700}}>
                          <div>State of Hawai‘i</div>
                          <div>{this.props.settings.deptName}</div>
                          <div>Education Services</div>
                          <div>Unexcused Absence Warning</div>
                          <div>{format(new Date(), "MM/dd/yyyy")}</div>
                        </div>
                      </div>
                      <div style={{marginTop:40,flexDirection:'column'}}>
                        <div>
                          <span style={{marginRight:5,fontWeight:700}}>To:</span>
                          <span>{roster.lastName + ", " + roster.firstName}{roster.housing ? " - Housing: " + roster.housing : " - Housing: _____"}</span>
                        </div>
                        <div style={{marginTop:10}}>
                          <span style={{marginRight:5,fontWeight:700}}>From:</span>
                          <span>Education Unit</span>
                        </div>
                        <div style={{marginTop:10}}>
                          <span style={{marginRight:5,fontWeight:700}}>Subject:</span>
                          <span>{roster.className}</span>
                        </div>
                        <div style={{marginTop:20}}>
                          <span>
                            This is your official notice that you currently have <span style={{fontWeight:700}}>{roster.missing.length} unexcused absences.</span> You were absent on the following dates and no excuse form was submitted to the Education Unit.
                          </span>
                        </div>
                        <div style={{marginTop:10}}>
                          <div style={{flexDirection:'row',flexWrap:'wrap'}}>
                            {roster.missing.map((missing:any, m:number) => (
                              <div key={m} style={{display:'inline-block',width:200,marginTop:10}}>{missing.meetingDate} {missing.startTime} - {missing.endTime}</div>
                            ))}
                          </div>
                        </div>
                        <div style={{marginTop:20}}>
                          <span>
                            Your current Student Contract, which you signed, states that more than three unexcused absences will lead to <span style={{fontWeight:700}}>automatic withdrawal from class without credit.</span>
                          </span>
                        </div>
                        <div style={{marginTop:20}}>
                          <span>
                            This action will be reflected in your student record and all appropriate parties will be notified as neccessary (i.e. Warden, Security, OSA, workline supervisor).
                          </span>
                        </div>
                        <div style={{marginTop:20}}>
                          <span>
                            Please make yourself familiar with the "excused from class" procedure which is: if you must make yourself absent for any reason, you should come to class and submit a <span style={{fontWeight:700}}>One Day Excused Absence Form (pink).</span>
                          </span>
                        </div>
                        <div style={{marginTop:20}}>
                          <span>
                            Please sign one copy and return to Education Unit.
                          </span>
                        </div>
                        <div style={{marginTop:20}}>
                          <div>
                            <span style={{fontWeight:700}}>Staff Name:</span> {data.staffName}
                          </div>
                        </div>
                        <div style={{marginTop:20}}>
                          <div style={{fontWeight:700}}>
                            <span>Staff Signature:</span> __________________________________________________
                          </div>
                        </div>
                        <div style={{marginTop:20}}>
                          <div style={{fontWeight:700}}>
                            <span>Inmate Signature:</span> ________________________________________________
                          </div>
                        </div>
                        <div style={{marginTop:20}}>
                          <div style={{fontWeight:700}}>
                            <span>Date:</span> _________________________
                          </div>
                        </div>
                        {(data.comments && data.comments.length > 0) || (roster.suspended || roster.quarantined) ? <div style={{marginTop:40}}>
                          <div style={{fontWeight:700}}>Comments:</div>
                          {data.comments && data.comments.length > 0 ? <div style={{marginTop:4}}>{data.comments}</div> : null}
                          {roster.suspended ? <div style={{marginTop:4}}>{roster.firstName + " " + roster.lastName} is currently suspended.</div> : null}
                          {roster.quarantined ? <div style={{marginTop:4}}>{roster.firstName + " " + roster.lastName} is currently quarantined.</div> : null}
                        </div> : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Container>
          )
    }
}

export default UnexcusedAbsence