import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container,
  Icon
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, connected: boolean, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class UtilizationHTML extends React.PureComponent<ReportProps> {
    public componentDidMount() {
        this.props.socketConnect()
        this.props.fetchSettings()
    }

    public componentDidUpdate(prevProps:any) {
      if (!prevProps.connected && this.props.connected && !this.props.report) {
          this.props.generateUtilization()
      }
    }

    public render() {
      if (!this.props.report) {
        return (
          <Fragment>
            <Dimmer active inverted>
              <Loader content='Generating report...' />
            </Dimmer>
          </Fragment>
        )
      } else if (this.props.report.loading) {
        if (this.props.connected) {
          return (
            <Fragment>
              <Dimmer active inverted>
                <Loader content='Generating report...' />
              </Dimmer>
            </Fragment>
          )
        } else {
          return (
            <Fragment>
              <Dimmer active inverted>
                <Icon name='warning sign' color='red' size='huge' />
                <div className='name' style={{textAlign:'center',color:'black',marginTop:10}}><b>Error</b></div>
              </Dimmer>
            </Fragment>
          )
        }
      } else {
        let params = qs.parse(window.location.search.substr(1, window.location.search.length));
        let data = this.props.report.generated
        var num = 0
        {/*<div style={{display:'flex',justifyContent:'space-between',textAlign:'center',marginTop:20}}>
          <div style={{color:'#ccc',textAlign:'right',marginRight:'auto',paddingBottom:20,paddingLeft:10}}>
            <div style={{marginBottom:4}}><div style={{width:90,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
          </div>
          <div style={{fontWeight:700,minWidth:300}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Funding Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
          <div style={{color:'#ccc',textAlign:'left',marginLeft:'auto',paddingBottom:20,paddingRight:10}}>
            <div style={{marginBottom:4}}><div style={{width:90,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'standard' ? 'Standard' : params.reportType == 'detailed' ? 'Detailed' : params.reportType == 'completions' ? 'Completions' : params.reportType == 'detailed-completions' ? 'Detailed Completions' : params.reportType == 'demographics' ? 'Demographics' : params.reportType == 'participation' ? 'Participation' : params.reportType == 'detailed-participation' ? 'Detailed Participation' : params.reportType == 'weekly-participation' ? 'Weekly Participation' : params.reportType == 'ethnic' ? 'Ethnic Access' : ''}</div></div>
          </div>
        </div>*/}
        return (
            <Container fluid>
              <Table compact celled striped unstackable className='slanted'>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell colSpan={8}>
                      <div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div>
                      <div style={{fontSize:14}}>Classroom Utilization Report</div>
                      <div style={{marginTop:4}}>{`${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div>
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Template</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Currently Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Capacity</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Percent Filled</span></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.facilities.map((facility:any, f:number) =>
                    facility.programs.map((program:any, p:number) =>
                      program.courses.map((course:any, c:number) =>
                        course.templates.map((template:any, t:number) =>
                          template.classes.map((klass:any, k:number) => (
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={`${f}:${p}:${c}:${t}:${k}`}>
                              <Table.Cell warning={p == 0 && c == 0 && t == 0 && k == 0 && facility.highlight}>{p == 0 && c == 0 && t == 0 && k == 0 ? `${facility.abbreviation}` : ''}</Table.Cell>
                              <Table.Cell warning={c == 0 && t == 0 && k == 0 && program.highlight}>{c == 0 && t == 0 && k == 0 ? `${program.name}` : ''}</Table.Cell>
                              <Table.Cell warning={t == 0 && k == 0 && course.highlight}>{t == 0 && k == 0 ? `${course.name}` : ''}</Table.Cell>
                              <Table.Cell warning={k == 0 && template.highlight}>{k == 0 ? `${template.name}` : ''}</Table.Cell>
                              <Table.Cell warning={klass.highlight}>{klass.name}</Table.Cell>
                              <Table.Cell warning={klass.rosterHighlight}>{klass.enrolled}</Table.Cell>
                              <Table.Cell>{klass.capacity}</Table.Cell>
                              <Table.Cell>{`${klass.percent}%`}</Table.Cell>
                            </Table.Row>
                          ))
                        )
                      )
                    )
                  )}
                </Table.Body>
              </Table>
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
      }
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.utilization, connected: state.reports.connected, options: state.reports.options, settings: state.staff.settings } },
  { ...StaffStore.actionCreators, ...ReportsStore.actionCreators }
)(UtilizationHTML as any)
