import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import InmatesTable from '../inmates/InmatesTable'
import {
  Icon,
  Header,
  Button,
  Form,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type DrawerProps =
    { staff: any } &
    DrawerStore.DrawerState &
    InmatesStore.InmatesState &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    WithRouterProps<{}>

class Search extends React.PureComponent<DrawerProps> {
    public componentDidMount() {
        this.props.fetchInmates()
        this.props.fetchHousing()
    }

    public render() {
      const activeFilters = () => {
          return !_.isEqual(_.pick(this.props.filters, (v:any,k:string) => k != 'showFilters'), { facility: (this.props.staff.facilities.includes('PSD') || this.props.staff.facilities.includes('HPA')) ? 'all' : 'my', searchType: 'starts', gender: 'B', active: true, firstName: '', lastName: '', sid: '', dobFrom: null, dobTo: null, education: [], tabeLevel: 'all', gedFilter: 'all', hisetFilter: 'all' })
      }

      return (
        <Fragment>
          <Form style={{position:'absolute',top:10,right:80}}>
            {window.location.host.split(".")[0] == "sms" ? <Form.Group>
              <Button size='small' color={this.props.tab == 'inmates' ? 'blue' : undefined} content='Inmates' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('inmates')} />
              <Button size='small' color={this.props.tab == 'tabe' ? 'blue' : undefined} content='TABE' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('tabe')} />
              <Button size='small' color={this.props.tab == 'ged' ? 'blue' : undefined} content='GED' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('ged')} />
              <Button size='small' color={this.props.tab == 'hiset' ? 'blue' : undefined} content='HiSET' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('hiset')} />
              <Button size='small' color={this.props.tab == 'riasec' ? 'blue' : undefined} content='RIASEC' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('riasec')} />
              <Popup content={`Filter toggle. If the filter icon is blue then the filter is in its default state. If the filter icon is red then there is an entry in one or more fields or one or more selections have changed from the default.`} position="bottom center" trigger={<Button circular icon='filter' color={activeFilters() ? 'red' : 'blue'} onClick={this.props.toggleFilters} style={{fontSize:'1.5em',marginLeft:30}} />} />
            </Form.Group> : null}
            {window.location.host.split(".")[0] == "sas" ? <Form.Group>
              <Button size='small' color={this.props.tab == 'inmates' ? 'blue' : undefined} content='Inmates' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('inmates')} />
              <Button size='small' color={this.props.tab == 'ands' ? 'blue' : undefined} content='A&Ds' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('ands')} />
              <Button size='small' color={this.props.tab == 'uas' ? 'blue' : undefined} content='UAs' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('uas')} />
              <Button circular icon='filter' color={activeFilters() ? 'red' : 'blue'} onClick={this.props.toggleFilters} style={{fontSize:'1.5em',marginLeft:30}} />
            </Form.Group> : null}
          </Form>
          <Button circular icon='arrow left' onClick={() => this.props.toggleDrawer(this.props.tab)} style={{position:'absolute',top:20,right:0}} />
          <Header as='h2' style={{display:'inline-block'}}>
            <Icon name='user' /> Inmates
          </Header>
          <div style={{display:'inline-block',marginLeft:10,verticalAlign:'top',marginTop:32}}>[{this.props.tabs.inmates.count} records]</div>
          <InmatesTable />
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { ...state.drawer, ...state.inmates, staff: state.staff.staff } },
  { ...DrawerStore.actionCreators, ...InmatesStore.actionCreators }
)(Search as any)