import React, { Fragment } from 'react'
import {
  Dimmer,
  Loader,
  Table,
  Container
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class CSPR extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let num1 = 0
        let num2 = 0
        let params = this.props.report.data
        let data = this.props.report.generated
        let options = this.props.options
        var facilities = typeof params.facilities == 'string' ? [params.facilities] : params.facilities
        let width = `${100 / (2 + (params.reports.includes("learning") || params.reports.includes("all") ? 1 : 0) + (params.reports.includes("average") || params.reports.includes("all") ? 1 : 0) + (params.reports.includes("ethnicity") || params.reports.includes("all") ? 1 : 0) + (params.reports.includes("age") || params.reports.includes("all") ? 1 : 0) + (params.reports.includes("academic") || params.reports.includes("all") ? 1 : 0) + (params.reports.includes("vocational") || params.reports.includes("all") ? 1 : 0))}%`

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>CSPR Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
            </div>
            <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
            </div>
            {data.inmates.length > 0 ? <div style={{margin:'0 20px'}}><Table compact celled striped unstackable className='slanted' style={{flexBasis:width,margin:'1em 0'}}>
              <Table.Header>
                <Table.Row style={{textAlign:'center'}}>
                  <Table.HeaderCell colSpan={28}>
                    <span style={{fontSize:14}}>CSPR Report</span>
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell><div><span>Facilities</span></div></Table.HeaderCell>
                  <Table.HeaderCell><div><span>Inmate</span></div></Table.HeaderCell>
                  <Table.HeaderCell><div><span>Date of Birth</span></div></Table.HeaderCell>
                  <Table.HeaderCell><div><span>Age</span></div></Table.HeaderCell>
                  <Table.HeaderCell><div><span>Sex</span></div></Table.HeaderCell>
                  <Table.HeaderCell><div><span>Ethnicity</span></div></Table.HeaderCell>
                  <Table.HeaderCell><div><span>Length of Stay</span></div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.inmates.map((inmate:any, i:number) => {
                  num1 += 1
                  return (<Table.Row style={{backgroundColor: num1 % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>{inmate.facilities.join(', ')}</Table.Cell>
                    <Table.Cell>{inmate.name}</Table.Cell>
                    <Table.Cell>{inmate.dob}</Table.Cell>
                    <Table.Cell>{inmate.age}</Table.Cell>
                    <Table.Cell>{inmate.sex}</Table.Cell>
                    <Table.Cell>{inmate.race}</Table.Cell>
                    <Table.Cell>{inmate.days}</Table.Cell>
                  </Table.Row>)
                })}
              </Table.Body>
            </Table></div> : null}
            {params.reports.includes("academic") || params.reports.includes("all") && data.academic.length > 0 ? <div style={{margin:'0 20px'}}><Table compact celled striped unstackable className='slanted' style={{flexBasis:width,margin:'1em 0'}}>
              <Table.Header>
                <Table.Row style={{textAlign:'center'}}>
                  <Table.HeaderCell colSpan={28}>Academic Performance Report</Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell className='rotate' style={{border:0}} />
                  <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Facilities</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Pre Reading Date</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Pre Reading LF</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Pre Reading SS</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Pre Reading GE</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Post Reading Date</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Post Reading LF</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Post Reading SS</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Post Reading GE</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Pre Math Date</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Pre Math LF</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Pre Math SS</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Pre Math GE</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Post Math Date</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Post Math LF</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Post Math SS</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Post Math GE</span></div></Table.HeaderCell>
                  <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.academic.map((inmate:any, i:number) => {
                  num2 += 1
                  return (<Table.Row style={{backgroundColor: num2 % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>{inmate.facilities.join(', ')}</Table.Cell>
                    <Table.Cell>{inmate.name}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.preReading ? inmate.preReading.date : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.preReading ? inmate.preReading.lf : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.preReading ? inmate.preReading.ss : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.preReading ? inmate.preReading.ge : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.postReading ? inmate.postReading.date : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.postReading ? inmate.postReading.lf : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.postReading ? inmate.postReading.ss : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.postReading ? inmate.postReading.ge : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.preMath ? inmate.preMath.date : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.preMath ? inmate.preMath.lf : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.preMath ? inmate.preMath.ss : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.preMath ? inmate.preMath.ge : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.postMath ? inmate.postMath.date : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.postMath ? inmate.postMath.lf : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.postMath ? inmate.postMath.ss : null}</Table.Cell>
                    <Table.Cell style={{textAlign:'right'}}>{inmate.postMath ? inmate.postMath.ge : null}</Table.Cell>
                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                  </Table.Row>)
                })}
              </Table.Body>
            </Table></div> : null}
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              {params.reports.includes("academic") || params.reports.includes("all") ? <Table compact celled striped unstackable style={{flexBasis:width,margin:'1em 0'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell>Academic Performance</Table.HeaderCell>
                    <Table.HeaderCell>Math</Table.HeaderCell>
                    <Table.HeaderCell>Reading</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Long-term students who tested below grade level upon entry</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.academicMath.below}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.academicReading.below}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Negative grade level change from pre to post test</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.academicMath.negative}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.academicReading.negative}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>No grade level change from pre to post test</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.academicMath.no}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.academicReading.no}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Up to one grade level improvement from pre to post test</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.academicMath.one}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.academicReading.one}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>More than one grade level improvement from pre to post test</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.academicMath.plus}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.academicReading.plus}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table> : null}
              {params.reports.includes("vocational") || params.reports.includes("all") ? <Table compact celled striped unstackable style={{flexBasis:width,margin:'1em 0'}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell><div><span>Vocational Outcomes</span></div></Table.HeaderCell>
                    <Table.HeaderCell><div><span>In</span></div></Table.HeaderCell>
                    <Table.HeaderCell><div><span>Out</span></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Enrolled in GED classes</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.reduce(data.enrolled, (acc:number, inmate:any) => acc + (inmate.in ? 1 : 0), 0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.reduce(data.enrolled, (acc:number, inmate:any) => acc + (inmate.out ? 1 : 0), 0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'rgba(0,0,50,.05)'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Obtained a GED</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.reduce(data.ged, (acc:number, inmate:any) => acc + (inmate.in ? 1 : 0), 0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.reduce(data.ged, (acc:number, inmate:any) => acc + (inmate.out ? 1 : 0), 0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Enrolled in post-secondary education</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.reduce(data.post, (acc:number, inmate:any) => acc + (inmate.in ? 1 : 0), 0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.reduce(data.post, (acc:number, inmate:any) => acc + (inmate.out ? 1 : 0), 0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table> : null}
              {params.reports.includes("average") || params.reports.includes("all") ? <Table compact celled striped unstackable style={{flexBasis:width,margin:'1em 0'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell colSpan={2}>Average Length of Stay</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Reporting Data</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.averages.facilities.length} ({data.averages.facilities.join(', ')})</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'rgba(0,0,50,.05)'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Average Length of Stay</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.averages.average}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Long Term Inmates</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.averages.longTerm}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table> : null}
              {params.reports.includes("age") || params.reports.includes("all") ? <Table compact celled striped unstackable style={{flexBasis:width,margin:'1em 0'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell colSpan={2}>Students Served by Age</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>17</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.age == 17).length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>18</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.age == 18).length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'rgba(0,0,50,.05)'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>19</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.age == 19).length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>20</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.age == 20).length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'rgba(0,0,50,.05)'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>21</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.age == 21).length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{data.inmates.length}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table> : null}
              {params.reports.includes("ethnicity") || params.reports.includes("all") ? <Table compact celled striped unstackable style={{flexBasis:width,margin:'1em 0'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell colSpan={2}>Students Served by Race / Ethnicity</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.sex == "M").length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'rgba(0,0,50,.05)'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.sex == "F").length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>American Indian or Alaskan Native</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.raceCode == "I").length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'rgba(0,0,50,.05)'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Asian</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.raceCode == "A").length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Black or African-American</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.raceCode == "B").length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'rgba(0,0,50,.05)'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Hawaiian or Pacific Islander</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.raceCode == "P").length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Hispanic</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.raceCode == "H").length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'rgba(0,0,50,.05)'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>White</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.raceCode == "W").length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Other or Unknown</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.raceCode == "U").length}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table> : null}
              {params.reports.includes("learning") || params.reports.includes("all") ? <Table compact celled striped unstackable style={{flexBasis:width,margin:'1em 0'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell colSpan={2}>Learning Difficulties</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Children w/ Disabilities</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.learningDisability).length}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'rgba(0,0,50,.05)'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>English Learner</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.filter(data.inmates, (inmate:any) => inmate.lep).length}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table> : null}
            </div>
          </Container>
        )
    }
}

export default CSPR