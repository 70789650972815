import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as StaffStore from '../../store/reducers/staff'
import {
  Dimmer,
  Loader
} from 'semantic-ui-react'
const qs = require('qs')

type ResetProps =
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class AuthGoogle extends React.PureComponent<ResetProps> {
    public componentDidMount() {
        let params = qs.parse(window.location.search.substr(1, window.location.search.length));
        this.props.authGoogle(params.code)
    }

    public render() {
        return (
          <Dimmer active inverted>
            <Loader content='Logging in...' />
          </Dimmer>
        )
    }
}

export default connect(
    (state: ApplicationState) => {},
    StaffStore.actionCreators
)(AuthGoogle as any)