import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import * as DrawerStore from '../../store/reducers/drawer'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Table,
  Modal,
  Form,
  Button,
  Checkbox,
  Input,
  Select,
  Radio,
  Popup,
  Label
} from 'semantic-ui-react'
const _ = require('underscore')

type AccessProps =
    { access: any, tab: string } &
    typeof AdminStore.actionCreators &
    typeof DrawerStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class AccessLevels extends React.PureComponent<AccessProps> {
    public componentDidMount() {
        this.props.fetchAccess()
    }

    public render() {
      return (
        <Container fluid style={{overflow:'auto'}}>
          <Table compact celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Department</Table.HeaderCell>
                <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Title</Table.HeaderCell>
                <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',zIndex:1 }}>Preset</Table.HeaderCell>
                <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.access.list.map((access:any) => (
                <Table.Row key={access.recordID}>
                  <Table.Cell>{access.department}</Table.Cell>
                  <Table.Cell>{access.title}</Table.Cell>
                  <Table.Cell>
                    <Form.Field
                      control={Select}
                      placeholder='Preset'
                      options={[{key:0,value:null,text:''}].concat(this.props.access.options.presets)}
                      value={access.preset}
                      onChange={(e:any, data:any) => this.props.updatePreset(access.recordID, data.value as string)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Button negative content='Remove' onClick={_.debounce(() => this.props.removeStaffTitle(access.recordID, access.departmentID), 10000, true)} />
                  </Table.Cell>
                </Table.Row>
              ))}
              <Table.Row>
                <Table.Cell>
                  <Form.Field
                    control={Select}
                    placeholder='Department'
                    options={[{key:0,value:null,text:''}].concat(this.props.access.options.departments)}
                    value={this.props.access.level.departmentID}
                    onChange={(e:any, data:any) => this.props.updateLevel('departmentID', data.value as number)}
                    style={{width:'100%'}}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    control={Input}
                    placeholder='New Access Level'
                    value={this.props.access.level.title}
                    onChange={(e:any, data:any) => this.props.updateLevel('title', data.value.validateAccess())}
                    style={{width:'100%'}}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    control={Select}
                    placeholder='Preset'
                    options={[{key:0,value:null,text:''}].concat(this.props.access.options.presets)}
                    value={this.props.access.level.preset}
                    onChange={(e:any, data:any) => this.props.updateLevel('preset', data.value as string)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Button positive content='Add' onClick={_.debounce(this.props.createStaffTitle, 10000, true)} style={{width:'100%'}} />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { access: state.admin.access, tab: state.drawer.tab } },
  { ...DrawerStore.actionCreators, ...PanesStore.actionCreators, ...AdminStore.actionCreators }
)(AccessLevels as any)