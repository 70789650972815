import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { renderToString } from 'react-dom/server'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import { pdfshift } from '../../../agent'
import * as ReportsStore from '../../../store/reducers/reports'
import EducationalHistoryReport from '../shift/EducationalHistoryReport'
import Footer from '../shift/ReportFooter'
import {
  Icon
} from 'semantic-ui-react'
const _ = require('underscore')

const css = require("!!css-loader!../../../custom.css");

type ReportProps =
    { report: any, options: any, settings: any, connected: boolean } &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class EducationalHistoryPDF extends React.PureComponent<ReportProps> {
    public componentDidUpdate(prevProps:any) {
      if (!prevProps.report.ready && this.props.report.ready) {
        _.each(this.props.report.generated.inmates, (data:any) => {
          const pdf = renderToString(<EducationalHistoryReport data={data} params={this.props.report.data} options={this.props.options} settings={this.props.settings} />);
          const footer = renderToString(<Footer report={this.props.report} options={this.props.options} settings={this.props.settings} />);

          pdfshift({
              source: pdf,
              footer: {
                source: footer,
                height: 70
              },
              margin: {
                top: 30,
                bottom: 0,
                left: 30,
                right: 30
              },
              css: css.default.toString(),
              format: 'Letter',
              wait_for: 'ready',
              filename: `${data.inmate.lastName.toLowerCase()}-${data.inmate.firstName.toLowerCase()}.pdf`
          }).then((response:any) => {
            this.props.setEdHistoryURL(data.inmate.recordID, response.data.url)
          })
        })
      }
    }

    public render() {
        return (
          <Fragment>
            {!this.props.connected ? <div className='pdf'>
              <Icon.Group>
                <Icon name='warning sign' color='red' size='huge'  />
              </Icon.Group>
              <div className='name' style={{textAlign:'center'}}><b>Error</b></div>
            </div> : null}
            {this.props.connected && (this.props.report.loading || (this.props.report.ready && _.any(this.props.report.generated.inmates, (inmate:any) => inmate.url == null))) ? <div className='pdf generating'>
              <Icon.Group>
                <Icon name='circle notch' color='grey' size='huge' loading  />
                <Icon name='file pdf outline' color='grey' size='big' />
              </Icon.Group>
              <div className='name'><b>Generating PDFs...</b></div>
            </div> : null}
            {this.props.connected && this.props.report.ready && _.all(this.props.report.generated.inmates, (inmate:any) => inmate.url != null) ? <Fragment>
              {_.map(this.props.report.generated.inmates, (data:any, i:number) => (<a target='_window' href={data.url} key={i}>
                <div className='pdf ready'>
                  <Icon name='file pdf outline' size='huge' />
                  <div className='name'><b>{data.inmate.inmateName}</b></div>
                </div>
              </a>))}
            </Fragment> : null}
          </Fragment>
        )
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.edhistory, options: state.reports.options, settings: state.staff.settings, connected: state.reports.connected } },
  ReportsStore.actionCreators
)(EducationalHistoryPDF as any)