import React, { Fragment } from 'react'
import {
  Table,
  Container,
  Image,
  Grid,
  Icon
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { pdf: any, options: any, missing: any }

class Attendance extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <Fragment>
            <html lang="en" style={{height:'auto'}}>
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Roboto') || document.fonts.check('bold 1em Roboto'))
              }`}} />
              <body style={{fontFamily:'Roboto',backgroundColor:'white !important',height:'auto'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let pdf = this.props.pdf
        let missing = this.props.missing
        let options = this.props.options

        let inmates: any[] = []
        let classes: any[] = []
        _.each(missing.transfers.inmates, (data:any) => {
          let inmate = _.find(inmates, (i:any) => i.name == data.missingInmateName)
          if (inmate == null) {
            inmates.push({ name: data.missingInmateName, reasons: ['transfer'] })
          } else if (!inmate.reasons.includes('transfer')) {
            inmate.reasons.push('transfer')
          }
        })
        /*_.each(missing.transfers.classes, (data:any) => {
          let klass = _.find(classes, (c:any) => c.name == data.missingClassName)
          if (klass == null) {
            classes.push({ name: data.missingClassName, reasons: ['transfer'] })
          } else if (!klass.reasons.includes('transfer')) {
            klass.reasons.push('transfer')
          }
        })*/
        _.each(missing.missingSchedules.inmates, (data:any) => {
          let inmate = _.find(inmates, (i:any) => i.name == data.missingInmateName)
          if (inmate == null) {
            inmates.push({ name: data.missingInmateName, reasons: ['missing'] })
          } else if (!inmate.reasons.includes('missing')) {
            inmate.reasons.push('missing')
          }
        })
        /*_.each(missing.missingSchedules.classes, (data:any) => {
          let klass = _.find(classes, (c:any) => c.name == data.missingClassName)
          if (klass == null) {
            classes.push({ name: data.missingClassName, reasons: ['missing'] })
          } else if (!klass.reasons.includes('missing')) {
            klass.reasons.push('missing')
          }
        })*/
        _.each(missing.noSchedules.inmates, (data:any) => {
          let inmate = _.find(inmates, (i:any) => i.name == data.missingInmateName)
          if (inmate == null) {
            inmates.push({ name: data.missingInmateName, reasons: ['no'] })
          } else if (!inmate.reasons.includes('no')) {
            inmate.reasons.push('no')
          }
        })
        /*_.each(missing.noSchedules.classes, (data:any) => {
          let klass = _.find(classes, (c:any) => c.name == data.missingClassName)
          if (klass == null) {
            classes.push({ name: data.missingClassName, reasons: ['no'] })
          } else if (!klass.reasons.includes('no')) {
            klass.reasons.push('no')
          }
        })*/
        _.each(missing.instructors, (data:any) => {
          let klass = _.find(classes, (c:any) => c.name == data.missingClassName)
          if (klass == null) {
            classes.push({ name: data.missingClassName, reasons: ['instructor'] })
          } else if (!klass.reasons.includes('instructor')) {
            klass.reasons.push('instructor')
          }
        })
        _.each(missing.hiatus, (data:any) => {
          let klass = _.find(classes, (c:any) => c.name == data.missingClassName)
          if (klass == null) {
            classes.push({ name: data.missingClassName, reasons: ['hiatus'] })
          } else if (!klass.reasons.includes('hiatus')) {
            klass.reasons.push('hiatus')
          }
        })
        inmates = _.sortBy(inmates, (inmate:any) => inmate.name)
        classes = _.sortBy(classes, (klass:any) => klass.name)
    
        const getDay = (dayOfWeek: number) => {
            switch (dayOfWeek) {
            case 0:
              return 'Sun'
            case 1:
              return 'Mon'
            case 2:
              return 'Tue'
            case 3:
              return 'Wed'
            case 4:
              return 'Thu'
            case 5:
              return 'Fri'
            case 6:
              return 'Sat'
            default:
              return ''
            }
        }

        return (
          <Container fluid>
            {pdf.omissions == 'O' ? null : pdf.data.length == 0 ? <div style={{flexDirection:'column'}}>
              <div style={{fontSize:'1.2em',lineHeight:'1.2em'}}>
                <div style={{flexDirection:'row'}}>
                  <Image src="https://sms.psd-hi.com/assets/HawaiiSeal.png" style={{float:'left',width:70}} />
                  <Image src="https://sms.psd-hi.com/assets/RightGraphic.jpg" style={{float:'right',width:70}} />
                  <div style={{flexDirection:'column',textAlign:'center',fontWeight:700}}>
                    <div>Education Unit</div>
                    <div>Attendance Checklist</div>
                  </div>
                </div>
                <Table compact striped unstackable style={{fontSize:'0.8em',marginTop:80}}>
                  <Table.Header>
                    <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                      <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                </Table>
              </div>
            </div> : _.map(pdf.data, (date:any) => _.map(date.facilities, (facility:any, i:number) => (
              <div key={facility.recordID} style={{pageBreakAfter:'always'}}>
                <div style={{flexDirection:'column'}}>
                  <div style={{fontSize:'1.2em',lineHeight:'1.2em'}}>
                    <div style={{flexDirection:'row'}}>
                      <Image src="https://sms.psd-hi.com/assets/HawaiiSeal.png" style={{float:'left',marginTop:20,marginLeft:30,width:70}} />
                      <Image src="https://sms.psd-hi.com/assets/RightGraphic.jpg" style={{float:'right',marginTop:20,marginRight:30,width:70}} />
                      <div style={{flexDirection:'column',textAlign:'center',fontWeight:700,marginTop:20}}>
                        <div style={{fontSize:'1.2em',fontWeight:700,marginBottom:10}}>{facility.facilityAbbr}</div>
                        <div>Education Unit</div>
                        <div>Attendance Checklist</div>
                        <div style={{marginTop:10}}>{getDay(date.dayOfWeek)}. {date.date}</div>
                      </div>
                    </div>
                    <Table padded striped unstackable style={{fontSize:'0.9em',marginTop:20}}>
                      <Table.Header>
                        <Table.HeaderCell>Class</Table.HeaderCell>
                        <Table.HeaderCell>Location</Table.HeaderCell>
                        {options.includes('instructors') ? <Table.HeaderCell>Instructor</Table.HeaderCell> : null}
                        <Table.HeaderCell>Start Time</Table.HeaderCell>
                        <Table.HeaderCell>End Time</Table.HeaderCell>
                        <Table.HeaderCell>Attendance Recorded</Table.HeaderCell>
                      </Table.Header>
                      <Table.Body>
                        {_.map(facility.classes, (klass:any, c:number) => (
                          <Fragment key={c}>
                            <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell>{klass.name} {klass.sex == "M" ? <Image src="https://sms.psd-hi.com/assets/male.png" style={{display:'inline-block',marginLeft:4,width:16,height:16}} /> : klass.sex == "F" ? <Image src="https://sms.psd-hi.com/assets/female.png" style={{display:'inline-block',marginLeft:4,width:16,height:16}} /> : null}</Table.Cell>
                              <Table.Cell>{klass.location}</Table.Cell>
                              {options.includes('instructors') ? <Table.Cell>{klass.instructorName}</Table.Cell> : null}
                              <Table.Cell>{klass.startTime}</Table.Cell>
                              <Table.Cell>{klass.endTime}</Table.Cell>
                              <Table.Cell style={{borderBottom:'thin solid #333'}} />
                            </Table.Row>
                            {_.map(klass.times, (time:any, t:number) => (
                              <Table.Row key={t} style={{breakInside:'avoid'}}>
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell />
                                <Table.Cell>{time.startTime}</Table.Cell>
                                <Table.Cell>{time.endTime}</Table.Cell>
                                <Table.Cell style={{borderBottom:'thin solid #333'}} />
                              </Table.Row>
                            ))}
                          </Fragment>
                        ))}
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            )))}
            {pdf.omissions != 'N' && (inmates.length > 0 || classes.length > 0) ? <div style={{flexDirection:'column'}}>
              <div style={{fontSize:'1.2em',lineHeight:'1.2em'}}>
                <div style={{flexDirection:'row'}}>
                  <Image src="https://sms.psd-hi.com/assets/HawaiiSeal.png" style={{float:'left',width:70}} />
                  <Image src="https://sms.psd-hi.com/assets/RightGraphic.jpg" style={{float:'right',width:70}} />
                  <div style={{flexDirection:'column',textAlign:'center',fontWeight:700}}>
                    <div>Education Unit</div>
                    <div>Master Schedule</div>
                    <div>Data Omissions By Cause</div>
                    <div style={{fontSize:'0.6em',color:'#aaa'}}>The following data may or may not be printed. Please consult the key for cause.</div>
                    <div style={{marginTop:10}}>{pdf.startDate == pdf.endDate ? pdf.startDate : `${pdf.startDate} - ${pdf.endDate}`}</div>
                  </div>
                </div>
                <div style={{marginTop:20,fontSize:'0.8em'}}>
                  <Grid columns={5} style={{margin:0,padding:0}}>
                    <Grid.Row style={{padding:0,margin:0}}>
                      <Grid.Column><Icon name='user times' /> - Missing Instructor</Grid.Column>
                      <Grid.Column><Icon name='calendar minus outline' /> - Missing Schedules</Grid.Column>
                      <Grid.Column><Icon name='calendar times outline' /> - No Schedules</Grid.Column>
                      <Grid.Column><Icon name='travel' /> - On Hiatus</Grid.Column>
                      <Grid.Column><Icon name='arrows alternate horizontal' /> - Facility Transfer</Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
                {classes.length > 0 ? <div style={{marginTop:10,fontSize:'0.8em'}}>
                  <Grid columns={4} style={{margin:0,padding:0}}>
                    {_.times(Math.ceil(classes.length / 4), (i:number) => {
                      let klass1 = classes[i]
                      let klass2 = classes[i + Math.ceil(classes.length / 4)]
                      let klass3 = classes[i + Math.ceil(classes.length / 4) * 2]
                      let klass4 = classes[i + Math.ceil(classes.length / 4) * 3]
                      return (<Grid.Row key={i} style={{paddingTop:2,paddingBottom:2}}>
                        {klass1 == undefined ? null : <Grid.Column>{klass1.name}{_.map(klass1.reasons, (reason:string) =>  <Icon style={{marginLeft:4}} name={reason == 'missing' ? 'calendar minus outline' : reason == 'no' ? 'calendar times outline' : reason == 'instructor' ? 'arrows alternate horizontal' : 'travel'} />)}</Grid.Column>}
                        {klass2 == undefined ? null : <Grid.Column>{klass2.name}{_.map(klass2.reasons, (reason:string) =>  <Icon style={{marginLeft:4}} name={reason == 'missing' ? 'calendar minus outline' : reason == 'no' ? 'calendar times outline' : reason == 'instructor' ? 'arrows alternate horizontal' : 'travel'} />)}</Grid.Column>}
                        {klass3 == undefined ? null : <Grid.Column>{klass3.name}{_.map(klass3.reasons, (reason:string) =>  <Icon style={{marginLeft:4}} name={reason == 'missing' ? 'calendar minus outline' : reason == 'no' ? 'calendar times outline' : reason == 'instructor' ? 'arrows alternate horizontal' : 'travel'} />)}</Grid.Column>}
                        {klass4 == undefined ? null : <Grid.Column>{klass4.name}{_.map(klass4.reasons, (reason:string) =>  <Icon style={{marginLeft:4}} name={reason == 'missing' ? 'calendar minus outline' : reason == 'no' ? 'calendar times outline' : reason == 'instructor' ? 'arrows alternate horizontal' : 'travel'} />)}</Grid.Column>}
                      </Grid.Row>)
                    })}
                  </Grid>
                </div> : null}
                {inmates.length > 0 ? <div style={{marginTop:10,fontSize:'0.8em'}}>
                  <Grid columns={4} style={{margin:0,padding:0}}>
                    {_.times(Math.ceil(inmates.length / 4), (i:number) => {
                      let inmate1 = inmates[i]
                      let inmate2 = inmates[i + Math.ceil(inmates.length / 4)]
                      let inmate3 = inmates[i + Math.ceil(inmates.length / 4) * 2]
                      let inmate4 = inmates[i + Math.ceil(inmates.length / 4) * 3]
                      return (<Grid.Row key={i} style={{paddingTop:2,paddingBottom:2}}>
                        {inmate1 == undefined ? null : <Grid.Column>{inmate1.name}{_.map(inmate1.reasons, (reason:string) =>  <Icon style={{marginLeft:4}} name={reason == 'missing' ? 'calendar minus outline' : reason == 'no' ? 'calendar times outline' : reason == 'instructor' ? 'arrows alternate horizontal' : 'travel'} />)}</Grid.Column>}
                        {inmate2 == undefined ? null : <Grid.Column>{inmate2.name}{_.map(inmate2.reasons, (reason:string) =>  <Icon style={{marginLeft:4}} name={reason == 'missing' ? 'calendar minus outline' : reason == 'no' ? 'calendar times outline' : reason == 'instructor' ? 'arrows alternate horizontal' : 'travel'} />)}</Grid.Column>}
                        {inmate3 == undefined ? null : <Grid.Column>{inmate3.name}{_.map(inmate3.reasons, (reason:string) =>  <Icon style={{marginLeft:4}} name={reason == 'missing' ? 'calendar minus outline' : reason == 'no' ? 'calendar times outline' : reason == 'instructor' ? 'arrows alternate horizontal' : 'travel'} />)}</Grid.Column>}
                        {inmate4 == undefined ? null : <Grid.Column>{inmate4.name}{_.map(inmate4.reasons, (reason:string) =>  <Icon style={{marginLeft:4}} name={reason == 'missing' ? 'calendar minus outline' : reason == 'no' ? 'calendar times outline' : reason == 'instructor' ? 'arrows alternate horizontal' : 'travel'} />)}</Grid.Column>}
                      </Grid.Row>)
                    })}
                  </Grid>
                </div> : null}
              </div>
            </div> : null}
          </Container>
        )
    }
}

export default Attendance