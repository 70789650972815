import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import ClassBulkSessionsAdd from './ClassBulkSessionsAdd'
import ClassBulkSessionsRemove from './ClassBulkSessionsRemove'
import ClassRosterMoveSchedules from './ClassRosterMoveSchedules'
import ClassRosterMoveSessions from './ClassRosterMoveSessions'
import DatePicker from 'react-datepicker'
import { format, startOfToday } from 'date-fns'
import {
  Container,
  Header,
  Divider,
  Grid,
  Form,
  Checkbox,
  Input,
  Select,
  Button,
  Search,
  Modal,
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { class: PanesStore.Class, num: 1 | 2, admin: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassRosterBulk extends React.PureComponent<RosterProps> {
    public componentDidMount() {
        this.props.refreshClass(this.props.num, this.props.class.info.recordID)
    }

    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public render() {
        const bulk = this.props.class.bulk
        const move = this.props.class.modals.move
        const schedules = this.props.class.schedules
        const rosters = this.props.class.rosters

        var sessionPanes = [
          { menuItem: 'Add', render: () => <Tab.Pane><ClassBulkSessionsAdd class={this.props.class} num={this.props.num} /></Tab.Pane> },
          { menuItem: 'Remove', render: () => <Tab.Pane><ClassBulkSessionsRemove class={this.props.class} num={this.props.num} /></Tab.Pane> }
        ]

        var movePanes = [
          { menuItem: 'Schedules', render: () => <Tab.Pane><ClassRosterMoveSchedules class={this.props.class} num={this.props.num} /></Tab.Pane> },
          { menuItem: 'Sessions', render: () => <Tab.Pane><ClassRosterMoveSessions class={this.props.class} num={this.props.num} /></Tab.Pane> }
        ]

        return (
          <Container fluid>
            <Header as='h3'>Bulk Edit</Header>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Form>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        label='Select rosters'
                        placeholder='Select rosters'
                        options={_.map(rosters, (roster:any) => { return { key: roster.recordID, value: roster.recordID, text: `${roster.lastName}, ${roster.firstName}` } })}
                        value={bulk.rosters || []}
                        onChange={(e:any, data:any) => this.props.editBulkField(this.props.num, 'rosters', data.value as number[])}
                        width={16}
                        multiple
                      />
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form>
                    <Form.Group>
                      <Form.Field
                        label='Start date'
                        control={DatePicker}
                        selected={bulk.dateStart ? new Date(bulk.dateStart) : null}
                        onChange={(date:any) => this.props.editBulkField(this.props.num, 'dateStart', date ? format(date, 'MM/dd/yyyy') : null)}
                      />
                      <Button color='blue' content='Manage sessions' onClick={() => this.props.openBulkSessionsModal(this.props.num)} style={{height:33,marginTop:17}} />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Checkbox}
                        label="TA"
                        name="ta"
                        checked={bulk.ta}
                        onChange={() => this.props.editBulkField(this.props.num, 'ta', !bulk.ta)}
                      />
                    </Form.Group>
                    <Form.Group>
                      <div style={{marginLeft:6,marginTop:4}}><b>Meeting times</b></div>
                    </Form.Group>
                    <Form.Group style={{flexWrap:'wrap'}}>
                      {schedules.length > 0 ? null : <div style={{marginLeft:6}}>No class meeting times set</div>}
                      {schedules.map((schedule, i) => (
                        <div key={i} style={{marginLeft:6,width:150,marginBottom:4}}><Checkbox
                          label={`${this.dayOfWeek3(schedule.dayOfWeek)}. ${schedule.startTime} - ${schedule.endTime}`}
                          checked={_.any(bulk.schedules, (s:any) => s.recordID == schedule.recordID)}
                          onChange={() => this.props.toggleBulkSchedule(this.props.num, schedule)}
                        /></div>
                      ))}
                    </Form.Group>
                    <Button positive content="Save" onClick={_.debounce(() => this.props.saveBulk(this.props.num), 10000, true)} />
                  </Form>
                  <Divider />
                  <Button positive content="Suspend" onClick={_.debounce(() => this.props.suspendBulk(this.props.num), 10000, true)} />
                  <Button negative content="Unsuspend" onClick={_.debounce(() => this.props.unsuspendBulk(this.props.num), 10000, true)} />
                  <br />
                  <Button positive content="Quarantine" onClick={_.debounce(() => this.props.quarantineBulk(this.props.num), 10000, true)} style={{marginTop:5}} />
                  <Button negative content="Unquarantine" onClick={_.debounce(() => this.props.releaseBulk(this.props.num), 10000, true)} style={{marginTop:5}} />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form>
                    <Form.Group>
                    <Form.Field
                        control={Select}
                        className="grade"
                        label="Grade"
                        name="grade"
                        options={this.props.class.options.grades}
                        value={bulk.grade}
                        onChange={(e:any, data:any) => this.props.editBulkField(this.props.num, 'grade', data.value)}
                    />
                    </Form.Group>
                    {bulk.grade == null ? null : <Form.Group>
                    <Form.Field
                        control={Input}
                        label="Reason"
                        name="reason"
                        value={bulk.reason || ''}
                        onChange={(e:any, data:any) => this.props.editBulkField(this.props.num, 'reason', data.value.validate())}
                        width={14}
                    />
                    </Form.Group>}
                    <Button positive content="Waitlist" onClick={_.debounce(() => this.props.waitlistBulk(this.props.num), 10000, true)} />
                    <Button positive content="Complete" onClick={_.debounce(() => this.props.completeBulk(this.props.num), 10000, true)} />
                    {this.props.admin || _.all(_.filter(rosters, (roster:any) => bulk.rosters && bulk.rosters.includes(roster.recordID)), (roster:any) => roster.aCount == 0 && roster.xCount == 0 && roster.zCount == 0) ? <Fragment>
                      <Button negative content="Delete" onClick={_.debounce(() => this.props.deleteBulk(this.props.num), 10000, true)} style={{marginLeft:4}} />
                      <br/>
                      <Checkbox checked={bulk.archive} onChange={(e:any, data:any) => this.props.editBulkField(this.props.num, 'archive', data.checked)} style={{display:'inline-block',verticalAlign:'middle',marginLeft:4,marginTop:5}} />
                      <label style={{verticalAlign:'sub',marginLeft:4}}>Delete from reporting</label>
                    </Fragment> : null}
                  </Form>
                  <Form style={{marginTop:10}}>
                    <Form.Group style={{margin:0}}>
                      <Search
                        placeholder='Search classes'
                        loading={this.props.class.move.loading}
                        onResultSelect={(e:any, data:any) => this.props.selectMoveClass(this.props.num, data.result)}
                        onSearchChange={(e:any, data:any) => this.props.searchBulkMoveClasses(this.props.num, data.value as string)}
                        results={this.props.class.options.classes}
                        value={this.props.class.move.value}
                        onFocus={() => this.props.clearMoveClasses(this.props.num)}
                      />
                      {new Date(this.props.class.data.dateBegins) <= startOfToday() ? <Form.Field
                        control={Select}
                        placeholder="Grade"
                        options={[{ key: 0, value: 'CE', text: 'CE' }, window.location.host.split(".")[0] == "sas" ? { key: 1, value: 'W/R', text: 'W/R' } : { key: 1, value: 'W/S', text: 'W/S' }]}
                        value={this.props.class.move.grade}
                        onChange={(e:any, data:any) => this.props.updateMoveGrade(this.props.num, data.value)}
                        style={{minWidth:80}}
                      /> : null}
                    </Form.Group>
                    <Form.Group style={{marginTop:10,marginBottom:0}}>
                      <Button color='blue' content='Move inmates' onClick={() => this.props.openMoveModal(this.props.num)} style={{marginLeft:7}} />
                      <Button color='blue' content='Temp. displace' onClick={() => this.props.openDisplaceModal(this.props.num)} style={{marginLeft:7}} />
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Modal
              open={this.props.class.modals.bulkSessions.open}
              onClose={() => this.props.toggleBulkSessionsModal(this.props.num)}
              size="tiny"
            >
              <Modal.Header content={`Session Management`} />
              <Modal.Content>
                <Tab menu={{ secondary: true, pointing: true }} panes={sessionPanes} activeIndex={this.props.class.modals.bulkSessions.data.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchBulkSessionsTab(this.props.num, activeIndex) }} />
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleBulkSessionsModal(this.props.num)}>Close</Button>
                <Button
                  content={this.props.class.modals.bulkSessions.data.index == 0 ? 'Add' : 'Remove'}
                  color={this.props.class.modals.bulkSessions.data.index == 0 ? 'green' : 'red'}
                  onClick={_.debounce(() => this.props.class.modals.bulkSessions.data.index == 0 ? this.props.addBulkSessions(this.props.num) : this.props.removeBulkSessions(this.props.num), 10000, true)}
                />
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.class.modals.move.open}
              onClose={() => this.props.toggleMoveModal(this.props.num)}
              size="tiny"
            >
              <Modal.Header content={`Move to ${this.props.class.move.value}`} />
              <Modal.Content>
                <Form>
                  <Form.Group>
                    <Form.Field
                      label="Start Date"
                      control={DatePicker}
                      selected={move.data.startDate && move.data.startDate.length > 0 ? new Date(move.data.startDate) : null}
                      onChange={(date:any) => this.props.updateMoveData(this.props.num, 'startDate', date ? format(date, 'MM/dd/yyyy') : '')}
                    />
                    <Checkbox
                      label='W/L'
                      checked={move.data.waitlist}
                      onChange={(e:any, data:any) => this.props.updateMoveData(this.props.num, 'waitlist', data.checked)}
                      style={{margin:'32px 0 0 10px'}}
                    />
                    <Checkbox
                      label='TA'
                      checked={move.data.ta}
                      onChange={(e:any, data:any) => this.props.updateMoveData(this.props.num, 'ta', data.checked)}
                      style={{margin:'32px 0 0 10px'}}
                    />
                  </Form.Group>
                </Form>
                <Tab menu={{ secondary: true, pointing: true }} panes={movePanes} activeIndex={this.props.class.move.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchMoveInmateTab(this.props.num, activeIndex) }} />
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleMoveModal(this.props.num)}>Close</Button>
                <Button
                  positive
                  content="Move"
                  onClick={_.debounce(() => this.props.moveBulk(this.props.num), 10000, true)}
                />
              </Modal.Actions>
            </Modal>
            <Divider />
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, admin: state.staff.staff!.admin } },
  PanesStore.actionCreators
)(ClassRosterBulk as any)
