import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../withRouter'
import { ApplicationState } from '../store'
import * as SupportStore from '../store/reducers/support'
import * as PanesStore from '../store/reducers/panes'
import mediumDraftExporter from 'medium-draft/lib/exporter';
import {
  Form,
  Select,
  Button,
  Divider,
  Container
} from 'semantic-ui-react'
const _ = require('underscore')

type SupportProps =
    { tab: string } &
    SupportStore.SupportState & 
    typeof SupportStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class Support extends React.PureComponent<SupportProps> {
    public componentDidMount() {
        this.props.fetchCategories()
    }
    
    public render() {
      return (
        <Fragment>
          <Form>
            <Form.Field
              control={Select}
              placeholder='Category'
              options={this.props.options.categories}
              value={this.props.category}
              onChange={(e:any, data:any) => this.props.selectCategory(data.value as number)}
              width={4}
            />
            <Form.Field
              control={Select}
              placeholder='Guide'
              options={this.props.options.guides}
              value={this.props.guide}
              onChange={(e:any, data:any) => this.props.selectGuide(data.value as number)}
              width={4}
            />
          </Form>
          <Divider />
          <Container fluid>
            {this.props.guide > 0 ? <div style={{overflow:'auto',whiteSpace:'normal',height:680}} dangerouslySetInnerHTML={{__html: mediumDraftExporter(this.props.data.getCurrentContent())}} /> : null}
          </Container>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { ...state.support, tab: state.drawer.tab } },
  { ...SupportStore.actionCreators, ...PanesStore.actionCreators }
)(Support as any)