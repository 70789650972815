import React, { Fragment } from 'react'
import {
  Table,
  Container
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class RecidivismBaselineStudents extends React.PureComponent<ReportProps> {
    private totals(object:any) {
      let total = 0
      _.each(object, (value:any,key:any) => {
        if (value && typeof value == 'number') { total += value }
      })
      return total
    }

    private percentage(num:number, total:number) {
      return (total == 0 ? '0' : (num / total > 1) ? '100' : (num / total > 0 && num / total < 0.01) ? '<1' : Math.round(num / total * 100)) + "%"
    }

    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let params = this.props.report.data
        let data = this.props.report.generated
        let facilitiesTotalRec = _.reduce(data.facilities, (acc:number, fac:any) => acc + Math.round(fac.recidivism), 0)

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Recidivism Report</div><div style={{marginTop:4}}>{`${data.firstTransfer} through ${data.lastTransfer}`}</div></div>
            </div>
            <div>
              <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
                <div style={{fontWeight:700,fontSize:16}}>Global</div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2013 and Before</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.years.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.years.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2013.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2014</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.years.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.years.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2014.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2015</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.years.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.years.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2015.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2016</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.years.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.years.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2016.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2017</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.years.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.years.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2017.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2018</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.years.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.years.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2018.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2019</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.years.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.years.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2019.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2020</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.years.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.years.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2020.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2021</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.years.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.years.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2021.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2022</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2022.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2023</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.years.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.years.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.years.year2023.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.total.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.total.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.total.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      {data.baseline.total.facilities.map((facility:any, f:number) => {
                        var studentFacility = _.find(data.baseline.students.total.facilities, (f:any) => f.name == facility.name)
                        var inmateFacility = _.find(data.baseline.inmates.total.facilities, (f:any) => f.name == facility.name)
                        return (<Table.Row key={f} style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.total.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.name}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:(f == data.baseline.total.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{studentFacility.total}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:(f == data.baseline.total.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{inmateFacility.total}</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.total.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.total}</div></Table.Cell>
                        </Table.Row>)
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, latest age)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 to 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 to 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 to 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 to 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 to 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 to 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 to 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, earliest age)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.students.totalUnique.earlyAges)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.inmates.totalUnique.earlyAges)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.students.totalUnique.earlyAges) + this.totals(data.baseline.inmates.totalUnique.earlyAges)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.totalFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.students.totalFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Non-Student Releases<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.inmates.totalFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentencedUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentencedUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentencedUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentencedUnique.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentencedUnique.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentencedUnique.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentencedUnique.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentencedUnique.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentencedUnique.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentencedUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentencedUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentencedUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2013 and Before</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.years.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.years.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2013.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2014</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.years.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.years.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2014.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2015</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.years.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.years.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2015.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2016</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.years.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.years.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2016.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2017</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.years.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.years.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2017.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2018</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.years.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.years.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2018.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2019</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.years.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.years.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2019.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2020</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.years.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.years.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2020.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2021</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.years.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.years.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2021.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2022</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2022.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2023</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.years.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.years.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.years.year2023.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalSentenced.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      {data.baseline.totalSentenced.facilities.map((facility:any, f:number) => {
                        var studentFacility = _.find(data.baseline.students.totalSentenced.facilities, (f:any) => f.name == facility.name)
                        var inmateFacility = _.find(data.baseline.inmates.totalSentenced.facilities, (f:any) => f.name == facility.name)
                        return (<Table.Row key={f} style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.totalSentenced.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.name}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:(f == data.baseline.total.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{studentFacility.total}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:(f == data.baseline.total.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{inmateFacility.total}</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.totalSentenced.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.total}</div></Table.Cell>
                        </Table.Row>)
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.totalSentencedFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.totalSentencedFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.students.totalSentencedFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalSentencedFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Non-Student Releases with sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.inmates.totalSentencedFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalSentencedFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2013 and Before</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.years.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.years.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2013.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2014</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.years.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.years.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2014.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2015</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.years.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.years.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2015.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2016</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.years.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.years.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2016.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2017</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.years.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.years.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2017.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2018</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.years.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.years.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2018.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2019</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.years.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.years.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2019.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2020</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.years.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.years.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2020.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2021</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.years.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.years.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2021.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2022</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2022.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2023</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.years.year2023.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      {data.baseline.totalPretrial.facilities.map((facility:any, f:number) => {
                        var studentFacility = _.find(data.baseline.students.totalPretrial.facilities, (f:any) => f.name == facility.name)
                        var inmateFacility = _.find(data.baseline.inmates.totalPretrial.facilities, (f:any) => f.name == facility.name)
                        return (<Table.Row key={f} style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.totalPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.name}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:(f == data.baseline.totalPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{studentFacility.total}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:(f == data.baseline.totalPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{inmateFacility.total}</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.totalPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.total}</div></Table.Cell>
                        </Table.Row>)
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrial.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrial.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrial.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrialUnique.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrialUnique.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrialUnique.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrialUnique.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrialUnique.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrialUnique.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.totalPretrialUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.totalPretrialUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.totalPretrialUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 0 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.students.totalPretrialFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'90%'}}>
                <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.students.totalPretrialFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.totalPretrialFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Non-Student Releases without sentencing<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.inmates.totalPretrialFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.totalPretrialFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2013 and Before</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.years.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.years.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2013.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2014</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.years.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.years.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2014.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2015</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.years.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.years.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2015.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2016</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.years.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.years.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2016.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2017</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.years.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.years.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2017.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2018</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.years.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.years.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2018.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2019</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.years.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.years.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2019.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2020</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.years.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.years.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2020.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2021</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.years.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.years.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2021.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2022</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2022.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2023</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.years.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.years.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.years.year2023.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, latest age)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, earliest age)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Incarcerated</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.current.incarcerated.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.current.incarcerated.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{(data.baseline.students.recidivismUnique.current.incarcerated + data.baseline.inmates.recidivismUnique.current.incarcerated).toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Released</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismUnique.current.released.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismUnique.current.released.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{(data.baseline.students.recidivismUnique.current.released + data.baseline.inmates.recidivismUnique.current.released).toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivism.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivism.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivism.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      {data.baseline.recidivism.facilities.map((facility:any, f:number) => {
                        var studentFacility = _.find(data.baseline.students.recidivism.facilities, (f:any) => f.name == facility.name)
                        var inmateFacility = _.find(data.baseline.inmates.recidivism.facilities, (f:any) => f.name == facility.name)
                        return (<Table.Row key={f} style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.recidivism.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.name}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:(f == data.baseline.recidivism.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{studentFacility.total}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:(f == data.baseline.recidivism.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{inmateFacility.total}</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.recidivism.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.total}</div></Table.Cell>
                        </Table.Row>)
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.recidivismFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.students.recidivismFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Non-Student Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.inmates.recidivismFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.genders.male.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.genders.female.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2013 and Before</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.years.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.years.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2013.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2014</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.years.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.years.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2014.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2015</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.years.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.years.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2015.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2016</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.years.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.years.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2016.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2017</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.years.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.years.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2017.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2018</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.years.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.years.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2018.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2019</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.years.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.years.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2019.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2020</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.years.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.years.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2020.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2021</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.years.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.years.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2021.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2022</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.years.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2022.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2023</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.years.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.years.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.years.year2023.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      {data.baseline.recidivismPretrial.facilities.map((facility:any, f:number) => {
                        var studentFacility = _.find(data.baseline.students.recidivismPretrial.facilities, (f:any) => f.name == facility.name)
                        var inmateFacility = _.find(data.baseline.inmates.recidivismPretrial.facilities, (f:any) => f.name == facility.name)
                        return (<Table.Row key={f} style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.recidivismPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.name}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:(f == data.baseline.recidivismPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{studentFacility.total}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:(f == data.baseline.recidivismPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{inmateFacility.total}</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(f == data.baseline.recidivismPretrial.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.total}</div></Table.Cell>
                        </Table.Row>)
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, latest age)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.ages.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.ages.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.ages.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.ages.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.ages.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.ages.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.ages.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrial.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrial.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrial.ages.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, earliest age)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell>Students</Table.HeaderCell>
                        <Table.HeaderCell>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.ageUnder22.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 To 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age22To30.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 To 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age30To40.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 To 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age40To50.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 To 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age50To60.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 To 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age60To70.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 To 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age70To80.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 To 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.age80To90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismPretrialUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.recidivismPretrialUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.recidivismPretrialUnique.earlyAges.ageOver90.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}></div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.recidivismPretrialFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.recidivismPretrialFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Returns from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.students.recidivismPretrialFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.students.recidivismPretrialFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:'90%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'1em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none',borderBottom:0}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Non-Student Returns from any sentence<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>2013</Table.HeaderCell>
                        <Table.HeaderCell>2014</Table.HeaderCell>
                        <Table.HeaderCell>2015</Table.HeaderCell>
                        <Table.HeaderCell>2016</Table.HeaderCell>
                        <Table.HeaderCell>2017</Table.HeaderCell>
                        <Table.HeaderCell>2018</Table.HeaderCell>
                        <Table.HeaderCell>2019</Table.HeaderCell>
                        <Table.HeaderCell>2020</Table.HeaderCell>
                        <Table.HeaderCell>2021</Table.HeaderCell>
                        <Table.HeaderCell>2022</Table.HeaderCell>
                        <Table.HeaderCell>2023</Table.HeaderCell>
                        <Table.HeaderCell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.baseline.inmates.recidivismPretrialFacilities.map((facility:any, f:number) => <Table.Row key={f} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2013.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2014.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2015.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2016.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2017.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2018.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2019.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2020.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2021.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2022.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.year2023.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2013), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2014), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2015), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2016), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2017), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2018), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2019), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2020), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2021), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2022), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + Math.round(fac.year2023), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.baseline.inmates.recidivismPretrialFacilities, (acc:number, fac:any) => acc + this.totals(fac), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'2em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Return Breakdowns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Unknown</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Non-sentenced to non-sentenced</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToPre.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToPre.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToPre.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToPre.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Non-sentenced to sentenced</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToSen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToSen.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToSen.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.preToSen.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sentenced to non-sentenced</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToPre.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToPre.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToPre.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToPre.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sentenced to sentenced</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToSen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToSen.genders.male.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToSen.genders.female.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.senToSen.genders.unknown.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'2em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns by Class Hours<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Returned</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Released</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 9 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours1To10.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours1To10.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>10 - 19 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours10To20.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours10To20.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>20 - 29 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours20To30.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours20To30.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours30To40.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours30To40.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours40To50.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours40To50.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours50To60.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours50To60.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours60To70.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours60To70.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours70To80.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours70To80.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours80To90.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours80To90.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>90 - 99 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours90To100.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours90To100.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>100 - 109 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours100To110.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours100To110.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>110 - 119 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours110To120.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours110To120.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>120 - 129 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours120To130.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours120To130.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>130 - 139 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours130To140.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours130To140.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>140 - 149 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours140To150.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours140To150.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>150 - 159 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours150To160.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours150To160.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>160 - 169 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours160To170.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours160To170.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>170 - 179 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours170To180.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours170To180.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>180 - 189 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours180To190.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours180To190.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>190 - 199 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours190To200.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours190To200.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>200 - 209 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours200To210.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours200To210.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>210 - 219 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours210To220.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours210To220.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'2em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Returns by Class Hours<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Returned</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Released</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>220 - 229 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours220To230.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours220To230.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>230 - 239 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours230To240.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours230To240.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>240 - 249 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours240To250.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours240To250.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>250 - 259 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours250To260.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours250To260.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>260 - 269 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours260To270.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours260To270.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>270 - 279 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours270To280.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours270To280.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>280 - 289 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours280To290.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours280To290.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>290 - 299 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours290To300.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours290To300.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>300 - 309 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours300To310.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours300To310.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>310 - 319 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours310To320.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours310To320.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>320 - 329 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours320To330.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours320To330.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>330 - 339 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours330To340.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours330To340.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>340 - 349 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours340To350.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours340To350.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>350 - 359 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours350To360.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours350To360.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>360 - 369 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours360To370.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours360To370.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>370 - 379 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours370To380.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours370To380.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>380 - 389 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours380To390.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours380To390.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>390 - 399 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours390To400.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours390To400.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>400 - 409 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours400To410.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours400To410.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>410 - 419 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours410To420.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours410To420.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>420 - 429 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours420To430.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours420To430.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>430 - 439 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours430To440.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours430To440.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>440 - 449 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours440To450.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours440To450.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>450 - 459 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours450To460.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours450To460.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>460 - 469 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours460To470.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours460To470.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>470 - 479 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours470To480.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours470To480.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>480 - 489 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours480To490.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours480To490.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>490 - 499 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours490To500.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hours490To500.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>500 and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hoursOver500.returned.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.recidivismHours.hoursOver500.released.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'2em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Number of Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Students</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.one.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.two.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.three.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.four.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.five.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.six.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.seven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.eight.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.nine.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.ten.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.eleven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.twelve.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.thirteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.fourteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.fifteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.sixteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.seventeen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.eighteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.nineteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returns.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returns.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returns.twentyPlus.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.students.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.inmates.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returns)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'2em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Number of Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Students</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.one.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.two.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.three.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.four.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.five.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.six.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.seven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.eight.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.nine.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.ten.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.eleven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.twelve.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.thirteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.fourteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.fifteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.sixteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.seventeen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.eighteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.nineteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsUnique.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsUnique.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsUnique.twentyPlus.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.students.returnsUnique)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.inmates.returnsUnique)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsUnique)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'2em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Number of Returns from sentenced<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Students</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.one.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.two.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.three.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.four.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.five.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.six.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.seven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.eight.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.nine.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.ten.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.eleven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.twelve.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.thirteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.fourteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.fifteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.sixteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.seventeen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.eighteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.nineteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSent.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSent.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSent.twentyPlus.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.students.returnsSent)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.inmates.returnsSent)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsSent)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'2em 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Number of Returns from sentenced<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Students</Table.HeaderCell> 
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Non-Students</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.one.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.one.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.two.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.two.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.three.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.three.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.four.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.four.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.five.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.five.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.six.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.six.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.seven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.seven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.eight.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.eight.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.nine.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.nine.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.ten.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.ten.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.eleven.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.eleven.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.twelve.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.twelve.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.thirteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.thirteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.fourteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.fourteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.fifteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.fifteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.sixteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.sixteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.seventeen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.seventeen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.eighteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.eighteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.nineteen.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.nineteen.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.students.returnsSentUnique.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.inmates.returnsSentUnique.twentyPlus.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.baseline.returnsSentUnique.twentyPlus.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.students.returnsSentUnique)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.inmates.returnsSentUnique)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.baseline.returnsSentUnique)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </Container>
        )
      }
}

export default RecidivismBaselineStudents