import { Action, Reducer } from 'redux'
import { AppThunkAction } from '../'
import { createEditorState } from 'medium-draft'
import agent from '../../agent'
const _ = require('underscore')

interface SelectCategoryAction { type: 'SELECT_SUPPORT_CATEGORY', category: number, guides: any[] }
interface SelectGuideAction { type: 'SELECT_SUPPORT_GUIDE', guide: number, data: any }
interface FetchCategoriesAction { type: 'FETCH_SUPPORT_CATEGORIES', categories: any[] }

type KnownAction = SelectCategoryAction | SelectGuideAction | FetchCategoriesAction

export interface SupportState {
    category: number,
    guide: number,
    options: {
        categories: any[],
        guides: any[]
    },
    data: any
}

export const actionCreators = {
    selectCategory: (category: number): AppThunkAction<KnownAction> => async (dispatch) => {
        const { guideData } = await agent.Data.fetchGuides(category)
        dispatch({ type: 'SELECT_SUPPORT_CATEGORY', category: category, guides: guideData } as SelectCategoryAction)
    },
    selectGuide: (guide: number): AppThunkAction<KnownAction> => async (dispatch) => {
        const { guideData } = await agent.Data.fetchGuide(guide)
        dispatch({ type: 'SELECT_SUPPORT_GUIDE', guide: guide, data: createEditorState(JSON.parse(guideData.content)) } as SelectGuideAction)
    },
    fetchCategories: (): AppThunkAction<KnownAction> => async (dispatch) => {
        const { categoryData } = await agent.Data.fetchCategories()
        dispatch({ type: 'FETCH_SUPPORT_CATEGORIES', categories: categoryData } as FetchCategoriesAction)
    },
}

export const reducer: Reducer<SupportState> = (state: SupportState | undefined, incomingAction: Action): SupportState => {
    if (state === undefined) {
        return {
            category: 0,
            guide: 0,
            data: {},
            options: {
                categories: [],
                guides: []
            }
        }
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'FETCH_SUPPORT_CATEGORIES':
            return {
                ...state,
                options: {
                    ...state.options,
                    categories: action.categories
                }
            }
        case 'SELECT_SUPPORT_CATEGORY':
            return {
                ...state,
                category: action.category,
                guide: 0,
                data: {},
                options: {
                    ...state.options,
                    guides: action.guides
                }
            }
        case 'SELECT_SUPPORT_GUIDE':
            return {
                ...state,
                guide: action.guide,
                data: action.data
            }
        default:
            return state
    }
}