import React, { Fragment } from 'react'
import {
  Table,
  Container
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class Population extends React.PureComponent<ReportProps> {
    private totals(object:any) {
      let total = 0
      _.each(object, (value:any,key:any) => {
        if (value && typeof value == 'number') { total += value }
      })
      return total
    }
    
    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let params = this.props.report.data
        let data = this.props.report.generated

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Population Report</div><div style={{marginTop:4}}>{params.endDate != null && params.endDate != params.startDate ? `${params.startDate} through ${params.endDate}` : params.startDate}</div></div>
            </div>
            {data.totals ? <div style={{pageBreakAfter:'always'}}>
              <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
                <div style={{fontWeight:700,fontSize:16}}>Global</div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2014</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2014 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2015</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2015 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2016</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2016 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2017</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2017 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2018</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2018 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2019</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2019 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2020</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2020 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2021</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2021 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2022</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2022 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2023</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2023 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2023 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2023 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(data.totals, (total:any) => total.year == 2023 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}></div>
                <div style={{flexBasis:`${90/3}%`}}></div>
              </div>
            </div> : null}
            {data.totals ? data.facilities.map((facility:any) => <div style={{pageBreakAfter:'always'}}>
              <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
                <div style={{fontWeight:700,fontSize:16}}>{facility.abbreviation}</div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2014</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2014 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2015</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2015 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2016</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2016 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2017</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2017 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2018</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2018 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2019</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2019 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2020</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2020 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2021</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2021 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2022</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>May</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 5).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>June</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 6).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>July</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 7).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>August</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 8).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>September</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 9).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>October</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 10).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>November</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 11).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>December</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2022 && total.month == 12).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>2023</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>January</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2023 && total.month == 1).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>February</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2023 && total.month == 2).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>March</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2023 && total.month == 3).pop}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>April</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.find(facility.months, (total:any) => total.year == 2023 && total.month == 4).pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}></div>
                <div style={{flexBasis:`${90/3}%`}}></div>
              </div>
            </div>) : <div>
              <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
                <div style={{fontWeight:700,fontSize:16}}>Population</div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Facilities</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.facilities.map((facility:any) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.pop}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid #333'}}><div style={{whiteSpace:'nowrap'}}>Hawaii Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid #333'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number,f:any) => acc + f.pop, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid #333',borderTop:'1px solid #333'}}><div style={{whiteSpace:'nowrap'}}>{data.arizona.abbreviation}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid #333',borderTop:'1px solid #333'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.arizona.pop}</div></Table.Cell>
                      </Table.Row>
                      {data.otherFacilities.map((facility:any, i:number) => i == 0 ? <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderTop:'1px solid #333'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderTop:'1px solid #333'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.pop}</div></Table.Cell>
                      </Table.Row> : <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.pop}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid #333'}}><div style={{whiteSpace:'nowrap'}}>Other Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid #333'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.otherFacilities, (acc:number,f:any) => acc + f.pop, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)',borderTop:'1px solid #333'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)',borderTop:'1px solid #333'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number,f:any) => acc + f.pop, 0) + _.reduce(data.otherFacilities, (acc:number,f:any) => acc + f.pop, 0) + data.arizona.pop}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>}
          </Container>
        )
      }
}

export default Population