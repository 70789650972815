import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container,
  Checkbox,
  Icon
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, connected: boolean, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class RosterHTML extends React.PureComponent<ReportProps> {
    public componentDidMount() {
        this.props.fetchFacilities()
        this.props.fetchPositions()
        this.props.fetchSettings()
        this.props.socketConnect()
    }

    public componentDidUpdate(prevProps:any) {
      if (!prevProps.connected && this.props.connected && !this.props.report) {
          this.props.generateRoster()
      }
    }

    public render() {
      if (!this.props.report) {
        return (
          <Fragment>
            <Dimmer active inverted>
              <Loader content='Generating report...' />
            </Dimmer>
          </Fragment>
        )
      } else if (this.props.report.loading) {
        if (this.props.connected) {
          return (
            <Fragment>
              <Dimmer active inverted>
                <Loader content='Generating report...' />
              </Dimmer>
            </Fragment>
          )
        } else {
          return (
            <Fragment>
              <Dimmer active inverted>
                <Icon name='warning sign' color='red' size='huge' />
                <div className='name' style={{textAlign:'center',color:'black',marginTop:10}}><b>Error</b></div>
              </Dimmer>
            </Fragment>
          )
        }
      } else {
        let params = qs.parse(window.location.search.substr(1, window.location.search.length));
        let tabeFilters = params.tabeFilters ? (typeof params.tabeFilters == 'string' ? [params.tabeFilters] : params.tabeFilters) : [];
        let data = this.props.report.generated
        let options = this.props.options
        let num = 0

        switch (params.reportType) {
        case 'name':
            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Roster Report</div>{params.startDate && params.endDate ? <div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div> : null}</div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Sort by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'name' ? 'Name' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'housing' ? 'Housing' : params.reportType == 'class' ? 'Class' : params.reportType == 'tabe' ? 'TABE' : params.reportType == 'staff' ? 'Staff' : ''}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Filter by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.filterBy == 'active' ? 'Active' : params.filterBy == 'recent' ? 'Recent arrivals' : params.filterBy == 'younger' ? 'Younger than XX' : params.filterBy == 'verified' ? 'Verified Diploma/HSE' : params.filterBy == 'unverified' ? 'Unverified Diploma/HSE' : params.filterBy == 'obtained' ? 'Obtained Diploma/HSE' : params.filterBy == 'previous' ? 'Previous GED/HiSET' : params.filterBy == 'contract' ? 'No Signed Ed. Contract' : ''}</div></div>
                  {tabeFilters.length > 0 ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>TABE status:</div><div style={{display:'inline-block',fontWeight:400}}>{_.map(tabeFilters, (filter:any) => filter == 'hours' ? 'Accumulated hours' : filter == 'none' ? 'No TABEs' : filter == 'year' ? 'Over 1 year since last TBAE' : '').join(', ')}</div></div> : null}
                  {params.filterBy == 'contract' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Location:</div><div style={{display:'inline-block',fontWeight:400}}>{params.inClasses == 'false' ? 'All' : params.inClasses == 'true' ? 'In classes' : ''}</div></div> : null}
                  {params.filterBy == 'younger' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Age:</div><div style={{display:'inline-block',fontWeight:400}}>{params.age}</div></div> : null}
                  {params.filterBy == 'younger' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Status:</div><div style={{display:'inline-block',fontWeight:400}}>{params.status == 'all' ? 'All' : params.status == 'enrolled' ? 'Enrolled' : ''}</div></div> : null}
                  {params.filterBy == 'obtained' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Location:</div><div style={{display:'inline-block',fontWeight:400}}>{params.location == 'any' ? 'Any' : params.location == 'facility' ? 'While at Facility' : ''}</div></div> : null}
                  {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Grad Type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.gradType.toString() == 'any' ? 'Any' : _.map(params.gradType, (type:any) => type == 'D' ? 'Diploma' : type == 'G' ? 'GED' : type == 'H' ? 'HiSET' : 'CBCSDP').join(', ')}</div></div> : null}
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{data.facilities.length == 0 ? 'All' : _.map(data.facilities, (f:any) => f.facilityAbbr).join(', ')}</div></div>
                </div>
                <Table compact celled striped unstackable style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1} style={{border:0}}><div><span>#</span></div></Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{border:0}}><div><span>SID</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Student</span></div></Table.HeaderCell>
                      {tabeFilters.length > 0 ? <Table.HeaderCell style={{border:0}}><div><span>ABE Hours</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'recent' ? <Table.HeaderCell style={{border:0}}><div><span>Arrival Date</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'younger' ? <Table.HeaderCell style={{border:0}}><div><span>{params.startDate && params.endDate ? 'Date of Birth' : 'Age'}</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0}}><div><span>Grad Type</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0}}><div><span>Grad Date</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0}}><div><span>Test Type</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0}}><div><span>Date</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0}}><div><span>Math</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0}}><div><span>Science</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0}}><div><span>Social</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0}}><div><span>Literature</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0}}><div><span>Reading</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0}}><div><span>Writing</span></div></Table.HeaderCell> : null}
                      {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0}}><div><span>Last High School</span></div></Table.HeaderCell> : null}
                      {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0}}><div><span>High Grade Completed</span></div></Table.HeaderCell> : null}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.inmates.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                      <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                    </Table.Row> : null}
                    {data.inmates.map((inmate:any, i:number) => {
                      num += 1
                      if (params.filterBy == 'previous') {
                        return (inmate.tests.map((test:any, t:number) => {
                          return (
                            <Table.Row warning={inmate.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={inmate.recordID}>
                              <Table.Cell>{t == 0 ? num : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.facilityAbbr : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.sid : null}</Table.Cell>
                              <Table.Cell>{t == 0 ? inmate.name : null}</Table.Cell>
                              {tabeFilters.length > 0 && t == 0 ? <Table.Cell>{Math.round(inmate.abeMinutes/60*100)/100}</Table.Cell> : null}
                              <Table.Cell>{test.type}</Table.Cell>
                              <Table.Cell>{test.display}</Table.Cell>
                              <Table.Cell>{test.math == 0 ? '' : test.math}</Table.Cell>
                              <Table.Cell>{test.science == 0 ? '' : test.science}</Table.Cell>
                              <Table.Cell>{test.social == 0 ? '' : test.social}</Table.Cell>
                              <Table.Cell>{test.literature == 0 ? '' : test.literature}</Table.Cell>
                              <Table.Cell>{test.reading == 0 ? '' : test.reading}</Table.Cell>
                              <Table.Cell>{test.writing == 0 ? '' : test.writing}</Table.Cell>
                              {params.showSchools == 'true' ? <Table.Cell>{t == 0 ? inmate.lastHS : null}</Table.Cell> : null}
                              {params.showSchools == 'true' ? <Table.Cell>{t == 0 ? inmate.highGradeCompleted : null}</Table.Cell> : null}
                            </Table.Row>
                          )
                        }))
                      } else {
                        return (
                          <Table.Row warning={inmate.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={inmate.recordID}>
                            <Table.Cell>{num}</Table.Cell>
                            <Table.Cell>{inmate.facilityAbbr}</Table.Cell>
                            <Table.Cell>{inmate.sid}</Table.Cell>
                            <Table.Cell>{inmate.name}</Table.Cell>
                            {tabeFilters.length > 0 ? <Table.Cell>{Math.round(inmate.abeMinutes/60*100)/100}</Table.Cell> : null}
                            {params.showSchools == 'true' ? <Table.Cell>{inmate.lastHS}</Table.Cell> : null}
                            {params.showSchools == 'true' ? <Table.Cell>{inmate.highGradeCompleted}</Table.Cell> : null}
                          </Table.Row>
                        )
                      }
                    })}
                  </Table.Body>
                </Table>
                {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                  <Table compact celled striped key={facility.facilityAbbr}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1} style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>#</span></div></Table.HeaderCell>
                        <Table.HeaderCell width={1} style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Facility</span></div></Table.HeaderCell>
                        <Table.HeaderCell width={1} style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>SID</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Student</span></div></Table.HeaderCell>
                        {tabeFilters.length > 0 ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>ABE Hours</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'recent' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Arrival Date</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'younger' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>{params.startDate && params.endDate ? 'Date of Birth' : 'Age'}</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Grad Type</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Grad Date</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Test Type</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Date</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Math</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Science</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Social</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Literature</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Reading</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'previous' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Writing</span></div></Table.HeaderCell> : null}
                        {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Last High School</span></div></Table.HeaderCell> : null}
                        {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>High Grade Completed</span></div></Table.HeaderCell> : null}
                      </Table.Row>
                      <Table.Row positive>
                        <Table.HeaderCell colSpan={4 + (tabeFilters.length > 0 ? 1 : 0) + (params.filterBy == 'recent' ? 1 : 0) + (params.filterBy == 'younger' ? 1 : 0) + (params.filterBy == 'verified' || params.filterBy == 'obtained' ? 2 : 0) + (params.filterBy == 'previous' ? 8 : 0) + (params.showSchools == 'true' ? 2 : 0)} style={{borderTop:'1px solid rgba(34,36,38,.1)',paddingTop:2,paddingBottom:2}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {facility.inmates.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                        <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                      </Table.Row> : null}
                      {facility.inmates.map((inmate:any, i:number) => {
                        if (i == 0) num = 0
                        num += 1
                        if (params.filterBy == 'previous') {
                          return (inmate.tests.map((test:any, t:number) => {
                            return (
                              <Table.Row warning={inmate.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={inmate.recordID}>
                                <Table.Cell>{t == 0 ? num : null}</Table.Cell>
                                <Table.Cell>{t == 0 ? inmate.facilityAbbr : null}</Table.Cell>
                                <Table.Cell>{t == 0 ? inmate.sid : null}</Table.Cell>
                                <Table.Cell>{t == 0 ? inmate.name : null}</Table.Cell>
                                {tabeFilters.length > 0 && t == 0 ? <Table.Cell>{Math.round(inmate.abeMinutes/60*100)/100}</Table.Cell> : null}
                                <Table.Cell>{test.type}</Table.Cell>
                                <Table.Cell>{test.display}</Table.Cell>
                                <Table.Cell>{test.math == 0 ? '' : test.math}</Table.Cell>
                                <Table.Cell>{test.science == 0 ? '' : test.science}</Table.Cell>
                                <Table.Cell>{test.social == 0 ? '' : test.social}</Table.Cell>
                                <Table.Cell>{test.literature == 0 ? '' : test.literature}</Table.Cell>
                                <Table.Cell>{test.reading == 0 ? '' : test.reading}</Table.Cell>
                                <Table.Cell>{test.writing == 0 ? '' : test.writing}</Table.Cell>
                                {params.showSchools == 'true' ? <Table.Cell>{t == 0 ? inmate.lastHS : null}</Table.Cell> : null}
                                {params.showSchools == 'true' ? <Table.Cell>{t == 0 ? inmate.highGradeCompleted : null}</Table.Cell> : null}
                              </Table.Row>
                            )
                          }))
                        } else {
                          return (
                            <Table.Row warning={inmate.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={inmate.recordID}>
                              <Table.Cell>{num}</Table.Cell>
                              <Table.Cell>{inmate.facilityAbbr}</Table.Cell>
                              <Table.Cell>{inmate.sid}</Table.Cell>
                              <Table.Cell>{inmate.name}</Table.Cell>
                              {tabeFilters.length > 0 ? <Table.Cell>{Math.round(inmate.abeMinutes/60*100)/100}</Table.Cell> : null}
                              {params.showSchools == 'true' ? <Table.Cell>{inmate.lastHS}</Table.Cell> : null}
                              {params.showSchools == 'true' ? <Table.Cell>{inmate.highGradeCompleted}</Table.Cell> : null}
                            </Table.Row>
                          )
                        }
                      })}
                    </Table.Body>
                  </Table>
                ))}
                <div className='footer'>
                  <div className='left'>
                      {new Date().today() + " @ " + new Date().timeNow()}
                  </div>
                  <div className='right'>
                      {data.staffID} / {data.printID}
                  </div>
                  <div className='center'>{this.props.report.copyright}</div>
                </div>
              </Container>
            )
        case 'facility':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Roster Report</div>{params.startDate && params.endDate ? <div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div> : null}</div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Sort by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'name' ? 'Name' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'housing' ? 'Housing' : params.reportType == 'class' ? 'Class' : params.reportType == 'tabe' ? 'TABE' : params.reportType == 'staff' ? 'Staff' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Filter by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.filterBy == 'active' ? 'Active' : params.filterBy == 'recent' ? 'Recent arrivals' : params.filterBy == 'younger' ? 'Younger than XX' : params.filterBy == 'verified' ? 'Verified Diploma/HSE' : params.filterBy == 'unverified' ? 'Unverified Diploma/HSE' : params.filterBy == 'obtained' ? 'Obtained Diploma/HSE' : params.filterBy == 'previous' ? 'Previous GED/HiSET' : params.filterBy == 'contract' ? 'No Signed Ed. Contract' : ''}</div></div>
                {tabeFilters.length > 0 ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>TABE status:</div><div style={{display:'inline-block',fontWeight:400}}>{_.map(tabeFilters, (filter:any) => filter == 'hours' ? 'Accumulated hours' : filter == 'none' ? 'No TABEs' : filter == 'year' ? 'Over 1 year since last TBAE' : '').join(', ')}</div></div> : null}
                {params.filterBy == 'contract' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Location:</div><div style={{display:'inline-block',fontWeight:400}}>{params.inClasses == 'false' ? 'All' : params.inClasses == 'true' ? 'In classes' : ''}</div></div> : null}
                {params.filterBy == 'younger' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Age:</div><div style={{display:'inline-block',fontWeight:400}}>{params.age}</div></div> : null}
                {params.filterBy == 'younger' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Status:</div><div style={{display:'inline-block',fontWeight:400}}>{params.status == 'all' ? 'All' : params.status == 'enrolled' ? 'Enrolled' : ''}</div></div> : null}
                {params.filterBy == 'obtained' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Location:</div><div style={{display:'inline-block',fontWeight:400}}>{params.location == 'any' ? 'Any' : params.location == 'facility' ? 'While at Facility' : ''}</div></div> : null}
                {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Grad Type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.gradType.toString() == 'any' ? 'Any' : _.map(params.gradType, (type:any) => type == 'D' ? 'Diploma' : type == 'G' ? 'GED' : type == 'H' ? 'HiSET' : 'CBCSDP').join(', ')}</div></div> : null}
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{data.facilities.length == 0 ? 'All' : _.map(data.facilities, (f:any) => f.facilityAbbr).join(', ')}</div></div>
              </div>
              <Table compact celled striped unstackable style={{marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1} style={{border:0}}><div><span>#</span></div></Table.HeaderCell>
                    <Table.HeaderCell width={1} style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                    {params.showHousing == 'true' ? <Table.HeaderCell width={1} style={{border:0}}><div><span>Housing</span></div></Table.HeaderCell> : null}
                    <Table.HeaderCell width={1} style={{border:0}}><div><span>SID</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Student</span></div></Table.HeaderCell>
                    {tabeFilters.length > 0 ? <Table.HeaderCell style={{border:0}}><div><span>ABE Hours</span></div></Table.HeaderCell> : null}
                    {params.filterBy == 'recent' ? <Table.HeaderCell style={{border:0}}><div><span>Arrival Date</span></div></Table.HeaderCell> : null}
                    {params.filterBy == 'younger' ? <Table.HeaderCell style={{border:0}}><div><span>{params.startDate && params.endDate ? 'Date of Birth' : 'Age'}</span></div></Table.HeaderCell> : null}
                    {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0}}><div><span>Grad Type</span></div></Table.HeaderCell> : null}
                    {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0}}><div><span>Grad Date</span></div></Table.HeaderCell> : null}
                    {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0}}><div><span>Last High School</span></div></Table.HeaderCell> : null}
                    {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0}}><div><span>High Grade Completed</span></div></Table.HeaderCell> : null}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.inmates.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                    <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                  </Table.Row> : null}
                  {_.chain(data.inmates)
                    .groupBy('facilityAbbr')
                    .map((inmates:any, facilityAbbr:any) => (
                      <Fragment key={facilityAbbr}>
                        <Table.Row positive>
                          <Table.HeaderCell colSpan={4 + (params.filterBy == 'recent' || params.filterBy == 'younger' ? 1 : 0) + (params.filterBy == 'verified' || params.filterBy == 'obtained' ? 2 : 0) + (params.showSchools == 'true' ? 2 : 0) + (params.showHousing == 'true' ? 2 : 0)} style={{borderTop:'1px solid rgba(34,36,38,.1)',paddingTop:2,paddingBottom:2}}><b>{facilityAbbr}</b></Table.HeaderCell>
                        </Table.Row>
                        {inmates.map((inmate:any, i:number) => {
                          if (i == 0) num = 0
                          num += 1
                          return (
                            <Table.Row warning={inmate.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={inmate.recordID}>
                              <Table.Cell>{num}</Table.Cell>
                              <Table.Cell>{inmate.facilityAbbr}</Table.Cell>
                              {params.showHousing == 'true' ? <Table.Cell>{inmate.housing}</Table.Cell> : null}
                              <Table.Cell>{inmate.sid}</Table.Cell>
                              <Table.Cell>{inmate.name}</Table.Cell>
                              {tabeFilters.length > 0 ? <Table.Cell>{Math.round(inmate.abeMinutes/60*100)/100}</Table.Cell> : null}
                              {params.filterBy == 'recent' ? <Table.Cell>{inmate.arrivalDate}</Table.Cell> : null}
                              {params.filterBy == 'younger' ? <Table.Cell>{params.startDate && params.endDate ? inmate.dob : inmate.age}</Table.Cell> : null}
                              {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.Cell>{inmate.gradType}</Table.Cell> : null}
                              {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.Cell>{inmate.gradDate}</Table.Cell> : null}
                              {params.showSchools == 'true' ? <Table.Cell>{inmate.lastHS}</Table.Cell> : null}
                              {params.showSchools == 'true' ? <Table.Cell>{inmate.highGradeCompleted}</Table.Cell> : null}
                            </Table.Row>
                          )
                        })}
                      </Fragment>
                    ))
                    .value()}
                  {data.facilities.length < 2 ? null : data.facilities.map((facility:any, f:number) => (
                    <Fragment key={facility.facilityAbbr}>
                      <Table.Row>
                        <Table.HeaderCell width={1} style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>#</span></div></Table.HeaderCell>
                        <Table.HeaderCell width={1} style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Facility</span></div></Table.HeaderCell>
                        {params.showHousing == 'true' ? <Table.HeaderCell width={1} style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Housing</span></div></Table.HeaderCell> : null}
                        <Table.HeaderCell width={1} style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>SID</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Student</span></div></Table.HeaderCell>
                        {tabeFilters.length > 0 ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>ABE Hours</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'recent' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Arrival Date</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'younger' ? <Table.HeaderCell style={{border:0}}><div><span>{params.startDate && params.endDate ? 'Date of Birth' : 'Age'}</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Grad Type</span></div></Table.HeaderCell> : null}
                        {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Grad Date</span></div></Table.HeaderCell> : null}
                        {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>Last High School</span></div></Table.HeaderCell> : null}
                        {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4,borderTop:'2px solid black'}}><div><span>High Grade Completed</span></div></Table.HeaderCell> : null}
                      </Table.Row>
                      <Table.Row positive>
                        <Table.HeaderCell colSpan={params.filterBy != 'active' ? 5 : 4} style={{borderTop:'1px solid rgba(34,36,38,.1)',paddingTop:2,paddingBottom:2}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                      </Table.Row>
                      {facility.inmates.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                        <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                      </Table.Row> : null}
                      {facility.inmates.map((inmate:any, i:number) => {
                        if (i == 0) num = 0
                        num += 1
                        return (
                          <Table.Row warning={facility.highlight || inmate.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={inmate.recordID}>
                            <Table.Cell>{num}</Table.Cell>
                            <Table.Cell>{inmate.facilityAbbr}</Table.Cell>
                            {params.showHousing == 'true' ? <Table.Cell>{inmate.housing}</Table.Cell> : null}
                            <Table.Cell>{inmate.sid}</Table.Cell>
                            <Table.Cell>{inmate.name}</Table.Cell>
                            {tabeFilters.length > 0 ? <Table.Cell>{Math.round(inmate.abeMinutes/60*100)/100}</Table.Cell> : null}
                            {params.filterBy == 'recent' ? <Table.Cell>{inmate.arrivalDate}</Table.Cell> : null}
                            {params.filterBy == 'younger' ? <Table.Cell>{params.startDate && params.endDate ? inmate.dob : inmate.age}</Table.Cell> : null}
                            {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.Cell>{inmate.gradType}</Table.Cell> : null}
                            {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.Cell>{inmate.gradDate}</Table.Cell> : null}
                            {params.showSchools == 'true' ? <Table.Cell>{inmate.lastHS}</Table.Cell> : null}
                            {params.showSchools == 'true' ? <Table.Cell>{inmate.highGradeCompleted}</Table.Cell> : null}
                          </Table.Row>
                        )
                      })}
                    </Fragment>
                  ))}
                </Table.Body>
              </Table>
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        case 'housing':
            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Roster Report</div>{params.startDate && params.endDate ? <div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div> : null}</div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Sort by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'name' ? 'Name' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'housing' ? 'Housing' : params.reportType == 'class' ? 'Class' : params.reportType == 'tabe' ? 'TABE' : params.reportType == 'staff' ? 'Staff' : ''}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Filter by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.filterBy == 'active' ? 'Active' : params.filterBy == 'recent' ? 'Recent arrivals' : params.filterBy == 'younger' ? 'Younger than XX' : params.filterBy == 'verified' ? 'Verified Diploma/HSE' : params.filterBy == 'unverified' ? 'Unverified Diploma/HSE' : params.filterBy == 'obtained' ? 'Obtained Diploma/HSE' : params.filterBy == 'previous' ? 'Previous GED/HiSET' : params.filterBy == 'contract' ? 'No Signed Ed. Contract' : ''}</div></div>
                  {tabeFilters.length > 0 ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>TABE status:</div><div style={{display:'inline-block',fontWeight:400}}>{_.map(tabeFilters, (filter:any) => filter == 'hours' ? 'Accumulated hours' : filter == 'none' ? 'No TABEs' : filter == 'year' ? 'Over 1 year since last TBAE' : '').join(', ')}</div></div> : null}
                  {params.filterBy == 'contract' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Location:</div><div style={{display:'inline-block',fontWeight:400}}>{params.inClasses == 'false' ? 'All' : params.inClasses == 'true' ? 'In classes' : ''}</div></div> : null}
                  {params.filterBy == 'younger' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Age:</div><div style={{display:'inline-block',fontWeight:400}}>{params.age}</div></div> : null}
                  {params.filterBy == 'younger' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Status:</div><div style={{display:'inline-block',fontWeight:400}}>{params.status == 'all' ? 'All' : params.status == 'enrolled' ? 'Enrolled' : ''}</div></div> : null}
                  {params.filterBy == 'obtained' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Location:</div><div style={{display:'inline-block',fontWeight:400}}>{params.location == 'any' ? 'Any' : params.location == 'facility' ? 'While at Facility' : ''}</div></div> : null}
                  {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Grad Type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.gradType.toString() == 'any' ? 'Any' : _.map(params.gradType, (type:any) => type == 'D' ? 'Diploma' : type == 'G' ? 'GED' : type == 'H' ? 'HiSET' : 'CBCSDP').join(', ')}</div></div> : null}
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{data.facilities.length == 0 ? 'All' : _.map(data.facilities, (f:any) => f.facilityAbbr).join(', ')}</div></div>
                </div>
                <Table compact celled striped unstackable style={{borderCollapse:'collapse',marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1} style={{border:0}}><div><span>#</span></div></Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{border:0}}><div><span>H#</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{border:0}}><div><span>Housing</span></div></Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{border:0}}><div><span>SID</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Student</span></div></Table.HeaderCell>
                      {tabeFilters.length > 0 ? <Table.HeaderCell style={{border:0}}><div><span>ABE Hours</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'recent' ? <Table.HeaderCell style={{border:0}}><div><span>Arrival Date</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'younger' ? <Table.HeaderCell style={{border:0}}><div><span>Age</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0}}><div><span>Grad Type</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0}}><div><span>Grad Date</span></div></Table.HeaderCell> : null}
                      {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0}}><div><span>Last High School</span></div></Table.HeaderCell> : null}
                      {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0}}><div><span>High Grade Completed</span></div></Table.HeaderCell> : null}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.housing.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                      <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                    </Table.Row> : null}
                    {data.housing.map((housing:any, h:number) =>
                      housing.inmates.map((inmate:any, i:number) => {
                        num += 1
                        return (
                          <Table.Row warning={housing.highlight || inmate.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)', borderTop: i == 0 && h != 0 ? '2px solid black' : 0}} key={inmate.recordID}>
                            <Table.Cell>{num}</Table.Cell>
                            <Table.Cell>{i+1}</Table.Cell>
                            <Table.Cell>{i == 0 ? housing.facilityAbbr : ''}</Table.Cell>
                            <Table.Cell>{i == 0 ? housing.housing : ''}</Table.Cell>
                            <Table.Cell>{inmate.sid}</Table.Cell>
                            <Table.Cell>{inmate.name}</Table.Cell>
                            {tabeFilters.length > 0 ? <Table.Cell>{Math.round(inmate.abeMinutes/60*100)/100}</Table.Cell> : null}
                            {params.filterBy == 'recent' ? <Table.Cell>{inmate.arrivalDate}</Table.Cell> : null}
                            {params.filterBy == 'younger' ? <Table.Cell>{inmate.age}</Table.Cell> : null}
                            {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.Cell>{inmate.gradType}</Table.Cell> : null}
                            {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.Cell>{inmate.gradDate}</Table.Cell> : null}
                            {params.showSchools == 'true' ? <Table.Cell>{inmate.lastHS}</Table.Cell> : null}
                            {params.showSchools == 'true' ? <Table.Cell>{inmate.highGradeCompleted}</Table.Cell> : null}
                          </Table.Row>
                        )
                      })
                    )}
                  </Table.Body>
                </Table>
                <div className='footer'>
                  <div className='left'>
                      {new Date().today() + " @ " + new Date().timeNow()}
                  </div>
                  <div className='right'>
                      {data.staffID} / {data.printID}
                  </div>
                  <div className='center'>{this.props.report.copyright}</div>
                </div>
              </Container>
            )
        case 'class':
            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Roster Report</div>{params.startDate && params.endDate ? <div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div> : null}</div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Sort by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'name' ? 'Name' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'housing' ? 'Housing' : params.reportType == 'class' ? 'Class' : params.reportType == 'tabe' ? 'TABE' : params.reportType == 'staff' ? 'Staff' : ''}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Filter by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.filterBy == 'active' ? 'Active' : params.filterBy == 'recent' ? 'Recent arrivals' : params.filterBy == 'younger' ? 'Younger than XX' : params.filterBy == 'verified' ? 'Verified Diploma/HSE' : params.filterBy == 'unverified' ? 'Unverified Diploma/HSE' : params.filterBy == 'obtained' ? 'Obtained Diploma/HSE' : params.filterBy == 'previous' ? 'Previous GED/HiSET' : params.filterBy == 'contract' ? 'No Signed Ed. Contract' : ''}</div></div>
                  {tabeFilters.length > 0 ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>TABE status:</div><div style={{display:'inline-block',fontWeight:400}}>{_.map(tabeFilters, (filter:any) => filter == 'hours' ? 'Accumulated hours' : filter == 'none' ? 'No TABEs' : filter == 'year' ? 'Over 1 year since last TBAE' : '').join(', ')}</div></div> : null}
                  {params.filterBy == 'contract' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Location:</div><div style={{display:'inline-block',fontWeight:400}}>{params.inClasses == 'false' ? 'All' : params.inClasses == 'true' ? 'In classes' : ''}</div></div> : null}
                  {params.filterBy == 'younger' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Age:</div><div style={{display:'inline-block',fontWeight:400}}>{params.age}</div></div> : null}
                  {params.filterBy == 'younger' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Status:</div><div style={{display:'inline-block',fontWeight:400}}>{params.status == 'all' ? 'All' : params.status == 'enrolled' ? 'Enrolled' : ''}</div></div> : null}
                  {params.filterBy == 'obtained' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Location:</div><div style={{display:'inline-block',fontWeight:400}}>{params.location == 'any' ? 'Any' : params.location == 'facility' ? 'While at Facility' : ''}</div></div> : null}
                  {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Grad Type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.gradType.toString() == 'any' ? 'Any' : _.map(params.gradType, (type:any) => type == 'D' ? 'Diploma' : type == 'G' ? 'GED' : type == 'H' ? 'HiSET' : 'CBCSDP').join(', ')}</div></div> : null}
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{data.facilities.length == 0 ? 'All' : _.map(data.facilities, (f:any) => f.facilityAbbr).join(', ')}</div></div>
                </div>
                <Table compact celled striped unstackable style={{borderCollapse:'collapse',marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1} style={{border:0}}><div><span>#</span></div></Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                      <Table.HeaderCell width={1} style={{border:0}}><div><span>SID</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Student</span></div></Table.HeaderCell>
                      {tabeFilters.length > 0 ? <Table.HeaderCell style={{border:0}}><div><span>ABE Hours</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'recent' ? <Table.HeaderCell style={{border:0}}><div><span>Arrival Date</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'younger' ? <Table.HeaderCell style={{border:0}}><div><span>Age</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0}}><div><span>Grad Type</span></div></Table.HeaderCell> : null}
                      {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.HeaderCell style={{border:0}}><div><span>Grad Date</span></div></Table.HeaderCell> : null}
                      {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0}}><div><span>Last High School</span></div></Table.HeaderCell> : null}
                      {params.showSchools == 'true' ? <Table.HeaderCell style={{border:0}}><div><span>High Grade Completed</span></div></Table.HeaderCell> : null}
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.classes.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                      <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                    </Table.Row> : null}
                    {data.classes.map((klass:any, k:number) =>
                      klass.inmates.map((inmate:any, i:number) => {
                        num += 1
                        return (
                          <Table.Row warning={klass.highlight || inmate.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)', borderTop: i == 0 && k != 0 ? '2px solid black' : k == 0 ? '1px solid rgba(0,0,50,.05)' : 0}} key={inmate.recordID}>
                            <Table.Cell>{num}</Table.Cell>
                            <Table.Cell>{i == 0 ? klass.facilityAbbr : ''}</Table.Cell>
                            <Table.Cell><div style={{whiteSpace:'nowrap'}}>{i == 0 ? klass.name : ''}</div></Table.Cell>
                            <Table.Cell>{inmate.sid}</Table.Cell>
                            <Table.Cell><div style={{whiteSpace:'nowrap'}}>{inmate.name}</div></Table.Cell>
                            {tabeFilters.length > 0 ? <Table.Cell>{Math.round(inmate.abeMinutes/60*100)/100}</Table.Cell> : null}
                            {params.filterBy == 'recent' ? <Table.Cell>{inmate.arrivalDate}</Table.Cell> : null}
                            {params.filterBy == 'younger' ? <Table.Cell>{inmate.age}</Table.Cell> : null}
                            {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.Cell>{inmate.gradType}</Table.Cell> : null}
                            {params.filterBy == 'verified' || params.filterBy == 'obtained' ? <Table.Cell>{inmate.gradDate}</Table.Cell> : null}
                            {params.showSchools == 'true' ? <Table.Cell>{inmate.lastHS}</Table.Cell> : null}
                            {params.showSchools == 'true' ? <Table.Cell>{inmate.highGradeCompleted}</Table.Cell> : null}
                          </Table.Row>
                        )
                      })
                    )}
                  </Table.Body>
                </Table>
                <div className='footer'>
                  <div className='left'>
                      {new Date().today() + " @ " + new Date().timeNow()}
                  </div>
                  <div className='right'>
                      {data.staffID} / {data.printID}
                  </div>
                  <div className='center'>{this.props.report.copyright}</div>
                </div>
              </Container>
            )
        case 'staff':
            var facilities = typeof params.facilities == 'string' ? [params.facilities] : params.facilities
            var positions = typeof params.positions == 'string' ? [params.positions] : params.positions
            var levels = typeof params.levels == 'string' ? [params.levels] : params.levels

            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Staff Report</div>{params.startDate && params.endDate ? <div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div> : null}</div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Sort by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'name' ? 'Name' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'housing' ? 'Housing' : params.reportType == 'class' ? 'Class' : params.reportType == 'tabe' ? 'TABE' : params.reportType == 'staff' ? 'Staff' : ''}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Filter by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.filterBy == 'active' ? 'Active' : ''}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Positions:</div><div style={{display:'inline-block',fontWeight:400}}>{params.positions == '0' ? 'All' : _.map(positions, (id:string) => _.find(options.positions, (f:any) => f.value == id).text).join(', ')}</div></div>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Access levels:</div><div style={{display:'inline-block',fontWeight:400}}>{params.levels = 'all' ? 'All' : _.map(levels, (level:string) => level).join(', ')}</div></div>
                </div>
                <Table compact celled striped unstackable style={{borderCollapse:'collapse',marginTop:0}}>
                  <Table.Header>
                    {data.staff ? <Table.Row>
                      <Table.HeaderCell width={1} style={{border:0}}><div><span>#</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>ID</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Name</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Facilities</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Positions</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Access Level</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Expiry Date</span></div></Table.HeaderCell>
                      <Table.HeaderCell width={1}style={{border:0}}><div><span>Login Enabled</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Last Login</span></div></Table.HeaderCell>
                    </Table.Row> : null}
                  </Table.Header>
                  <Table.Body>
                    {data.staff ? (data.staff.length === 0 ? <Table.Row>
                        <Table.Cell colSpan={9} style={{textAlign:'center'}}>No data to display</Table.Cell>
                      </Table.Row> : data.staff.map((staff:any, i:number) => {
                      num += 1
                      return (
                        <Table.Row warning={staff.highlight} style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)', borderTop: i == 0 ? '1px solid rgba(0,0,50,.05)' : 0}} key={staff.recordID}>
                          <Table.Cell>{num}</Table.Cell>
                          <Table.Cell>{staff.recordID}</Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap'}}>{staff.name}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap'}}>{_.map(staff.access, (link:any, i:number) => <Fragment key={i}>{link.facilityAbbr}<br/></Fragment>)}</div></Table.Cell>
                          <Table.Cell>{_.map(staff.access, (link:any, i:number) => <Fragment key={i}>{link.title}<br/></Fragment>)}</Table.Cell>
                          <Table.Cell>{_.map(staff.access, (link:any, i:number) => <Fragment key={i}>{link.level}<br/></Fragment>)}</Table.Cell>
                          <Table.Cell>{staff.expDate}</Table.Cell>
                          <Table.Cell><Checkbox checked={staff.login} /></Table.Cell>
                          <Table.Cell>{staff.lastLogin}</Table.Cell>
                        </Table.Row>
                      )
                    })) : null}
                    {data.facilities ? (data.facilities.length == 0 ? <Table.Row>
                        <Table.Cell colSpan={8} style={{textAlign:'center'}}>No data to display</Table.Cell>
                      </Table.Row> : data.facilities.map((facility:any, f:number) => (
                      <Fragment key={facility.facilityAbbr}>
                        <Table.Row style={{backgroundColor:'rgba(0,0,50,.05)',borderTop: f == 0 ? 0 : '1px solid rgba(0,0,50,.1)'}}>
                          <Table.HeaderCell width={1} style={{border:0,paddingTop:4,paddingBottom:4}}><div><span>#</span></div></Table.HeaderCell>
                          <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4}}><div><span>ID</span></div></Table.HeaderCell>
                          <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4}}><div><span>Name</span></div></Table.HeaderCell>
                          <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4}}><div><span>Positions</span></div></Table.HeaderCell>
                          <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4}}><div><span>Access Level</span></div></Table.HeaderCell>
                          <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4}}><div><span>Expiry Date</span></div></Table.HeaderCell>
                          <Table.HeaderCell width={1}style={{border:0,paddingTop:4,paddingBottom:4}}><div><span>Login Enabled</span></div></Table.HeaderCell>
                          <Table.HeaderCell style={{border:0,paddingTop:4,paddingBottom:4}}><div><span>Last Login</span></div></Table.HeaderCell>
                        </Table.Row>
                        <Table.Row positive>
                          <Table.HeaderCell colSpan={8} style={{borderTop:'1px solid rgba(34,36,38,.1)',paddingTop:2,paddingBottom:2}}><b>{facility.facilityAbbr}</b></Table.HeaderCell>
                        </Table.Row>
                        {facility.staff.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                          <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                        </Table.Row> : null}
                        {facility.staff.map((staff:any, i:number) => {
                          return (
                            <Table.Row warning={facility.highlight || staff.highlight} style={{backgroundColor: (i+1) % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)', borderTop: i == 0 ? '1px solid rgba(0,0,50,.05)' : 0}} key={staff.recordID}>
                              <Table.Cell>{i+1}</Table.Cell>
                              <Table.Cell>{staff.recordID}</Table.Cell>
                              <Table.Cell><div style={{whiteSpace:'nowrap'}}>{staff.name}</div></Table.Cell>
                              <Table.Cell>{_.map(staff.access, (link:any, i:number) => <Fragment key={i}>{link.title}<br/></Fragment>)}</Table.Cell>
                              <Table.Cell>{_.map(staff.access, (link:any, i:number) => <Fragment key={i}>{link.level}<br/></Fragment>)}</Table.Cell>
                              <Table.Cell>{staff.expDate}</Table.Cell>
                              <Table.Cell><Checkbox checked={staff.login} /></Table.Cell>
                              <Table.Cell>{staff.lastLogin}</Table.Cell>
                            </Table.Row>
                          )
                        })}
                      </Fragment>
                    ))) : null}
                  </Table.Body>
                </Table>
                <div className='footer'>
                  <div className='left'>
                      {new Date().today() + " @ " + new Date().timeNow()}
                  </div>
                  <div className='right'>
                      {data.staffID} / {data.printID}
                  </div>
                  <div className='center'>{this.props.report.copyright}</div>
                </div>
              </Container>
            )
        default:
            return null
        }
      }
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.roster, connected: state.reports.connected, options: state.reports.options, settings: state.staff.settings } },
  { ...StaffStore.actionCreators, ...ReportsStore.actionCreators }
)(RosterHTML as any)
