import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store'
import * as ReportsStore from '../../store/reducers/reports'
import { Editor } from 'medium-draft'
import mediumDraftExporter from 'medium-draft/lib/exporter';
import {
  Header,
  Form,
  Button,
  Select,
  Divider,
  Search
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { admin: boolean } &
    ReportsStore.ReportsState &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class Utilization extends React.PureComponent<ReportProps> {
    public params() {
      let params = new URLSearchParams()
      for (let [key, value] of Object.entries(this.props.reports.utilization.data)) {
        if (value != undefined && value != null && value != '' && key != 'reportTypes' && key != 'classes') {
            if (key == 'filterClasses') {
              for (let ovalue of Object.values(value)) {
                  params.append(key, ovalue.key)
              }
            } else if (typeof value == 'object') {
                for (let ovalue of Object.values(value as any)) {
                  params.append(key, ovalue as string)
                }
            } else {
                params.append(key, (value as any).toString())
            }
        }
      }
      return params.toString()
    }

    public render() {
      return (
        <Fragment>
          <Header as="h2">Classroom Utilization Report</Header>
          {this.props.admin ? <Fragment>
            <div style={{border:'thin solid #ddd',padding:1,borderRadius:5,marginBottom:12}}>
              <Editor placeholder="Enter a report description..." editorState={this.props.reports.utilization.description} sideButtons={[]} onChange={(state:any) => this.props.updateDescription(state)} />
            </div>
            <Button positive content='Save' onClick={this.props.saveDescription} />
          </Fragment> : null}
          {this.props.admin ? null : <div style={{whiteSpace:'normal'}} dangerouslySetInnerHTML={{__html: mediumDraftExporter(this.props.reports.utilization.description.getCurrentContent())}} />}
          <Divider />
          <Form>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Display type'
                placeholder='Display type'
                options={[{key:0,value:'html',text:'HTML'},{key:1,value:'pdf',text:'PDF'}]}
                value={this.props.reports.utilization.data.displayType}
                onChange={(e:any, data:any) => this.props.dataChange('utilization', 'displayType', data.value)}
                width={4}
              />
              {/*this.props.reports.utilization.data.displayType == 'pdf' ? <Form.Field
                control={Select}
                label='Orientation'
                placeholder='Orientation'
                options={[{key:0,value:'landscape',text:'Landscape'},{key:1,value:'portrait',text:'Portrait'}]}
                value={this.props.reports.utilization.data.orientation}
                onChange={(e:any, data:any) => this.props.dataChange('utilization', 'orientation', data.value)}
                width={4}
              /> : null*/}
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Filter classes'
                options={[{key:0,value:'all',text:'No'},{key:1,value:'include',text:'Include'},{key:2,value:'exclude',text:'Exclude'}]}
                value={this.props.reports.utilization.data.classFilter}
                onChange={(e:any, data:any) => this.props.dataChange('utilization', 'classFilter', data.value)}
                width={4}
              />
              {this.props.reports.utilization.data.classFilter == 'all' ? null : <Form.Field
                control={Search}
                label='Search Classes'
                loading={this.props.reports.utilization.data.classesLoading}
                onResultSelect={(e:any, data:any) => { this.props.selectClass('utilization', data.result) }}
                onSearchChange={(e:any, data:any) => { this.props.searchClasses('utilization', data.value as string) }}
                results={this.props.reports.utilization.data.classes}
                value={this.props.reports.utilization.data.class}
                onFocus={() => {}}
                width={4}
                fluid
              />}
              {this.props.reports.utilization.data.classFilter == 'all' ? null : <Form.Field
                control={Select}
                label='Classes'
                placeholder='Classes'
                options={_.map(this.props.reports.utilization.data.filterClasses, (c:any) => { return {key:c.key,value:`[${c.key}] ${c.value}`,text:`[${c.key}] ${c.value}`} })}
                value={_.map(this.props.reports.utilization.data.filterClasses, (c:any) => `[${c.key}] ${c.value}`)}
                onChange={(e:any, data:any) => this.props.dataChange('utilization', 'filterClasses', data.value)}
                multiple
                width={8}
              />}
            </Form.Group>
            {this.props.reports.utilization.data.displayType == 'html' ? <Button color='blue' content='Generate report' as={Link} to={`/reports/utilization/html?${this.params()}`} target='_window' /> : null}
            {this.props.reports.utilization.data.displayType != 'html' ? <Button color='blue' content='Generate report' onClick={this.props.generateUtilization} /> : null}
          </Form>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { ...state.reports, admin: state.staff.staff!.admin } },
  ReportsStore.actionCreators
)(Utilization as any)