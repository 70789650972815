import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Grid,
  Form,
  Header,
  Select,
  Table,
  TextArea,
  Input,
  Button,
  Modal,
  Popup
} from 'semantic-ui-react'

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2 } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmatePhaseEntries extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchPhaseEntries(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      return (
        <Container fluid>
          <div style={{display:'inline-block',marginTop:20}}>
            <Header as='h3'>Phase Entry Dates</Header>
          </div>
          <Button positive circular icon='plus' onClick={() => this.props.togglePhaseEntries(this.props.num)} style={{marginLeft:10,marginBottom:10}} />
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell width={1}>Phase Number</Table.HeaderCell>
                        <Table.HeaderCell>Entry Date</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.phaseEntries ? this.props.inmate.phaseEntries.map((phase:any) => (
                        <Table.Row key={phase.recordID} onClick={() => this.props.editPhaseEntry(this.props.num, this.props.inmate.info.recordID, phase.recordID)}>
                          <Table.Cell>{phase.number}</Table.Cell>
                          <Table.Cell>{phase.date}</Table.Cell>
                        </Table.Row>
                      )) : null}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <Modal
            open={this.props.inmate.modals.phase.open}
            onClose={() => this.props.togglePhaseEntries(this.props.num)}
            size="tiny"
          >
            <Modal.Header content={this.props.inmate.modals.phase.data.recordID ? 'Edit Phase Entry Date' : 'Add Phase Entry Date'} />
            <Modal.Content>
              <Grid padded>
                <Grid.Column width={16} style={{marginBottom:0}}>
                  <Form>
                    <Form.Group>
                      <Form.Field
                        control={Input}
                        label="Phase Number"
                        placeholder="Phase Number"
                        name="number"
                        value={this.props.inmate.modals.phase.data.number||''}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'phase', 'number', data.value.validate())}
                      />
                      <Form.Field
                        label="Entry Date"
                        control={DatePicker}
                        selected={this.props.inmate.modals.phase.data.date ? new Date(this.props.inmate.modals.phase.data.date) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'phase', 'date', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                      />
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.togglePhaseEntries(this.props.num)}>Close</Button>
              {!this.props.inmate.modals.phase.data.number || !this.props.inmate.modals.phase.data.date ? <Popup position='top center' content='Please make sure the phase entry has a number and date.' trigger={<Button>{this.props.inmate.modals.phase.data.recordID ? 'Save' : 'Add'}</Button>} /> : <Button color='green' onClick={() => this.props.inmate.modals.phase.data.recordID ? this.props.savePhaseEntry(this.props.num) : this.props.addPhaseEntry(this.props.num)}>{this.props.inmate.modals.phase.data.recordID ? 'Save' : 'Add'}</Button>}
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num } },
  PanesStore.actionCreators
)(InmatePhaseEntries as any)