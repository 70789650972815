import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container,
  Icon
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, connected: boolean, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class AttendanceHTML extends React.PureComponent<ReportProps> {
    public componentDidMount() {
        this.props.socketConnect()
        this.props.fetchEthnicities()
        this.props.fetchFacilities()
        this.props.fetchPrograms()
        this.props.fetchSettings()
    }

    public componentDidUpdate(prevProps:any) {
      if (!prevProps.connected && this.props.connected && !this.props.report) {
          this.props.generateAttendance()
      }
    }

    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public totalSessions() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.sessionCount, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.sessionCount, 0)
          }, 0)
        }, 0)
      }
    }

    public totalSessionHours() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.sessionHours, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.sessionHours, 0)
          }, 0)
        }, 0)
      }
    }

    public totalCancelled() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.cancelledCount, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.cancelledCount, 0)
          }, 0)
        }, 0)
      }
    }

    public totalCancelledHours() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.cancelledHours, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.cancelledHours, 0)
          }, 0)
        }, 0)
      }
    }

    public totalSecurity() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.securityCount, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.securityCount, 0)
          }, 0)
        }, 0)
      }
    }

    public totalSecurityHours() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.securityHours, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.securityHours, 0)
          }, 0)
        }, 0)
      }
    }

    public totalFurlough() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.furloughCount, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.furloughCount, 0)
          }, 0)
        }, 0)
      }
    }

    public totalFurloughHours() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.furloughHours, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.furloughHours, 0)
          }, 0)
        }, 0)
      }
    }

    public totalFunction() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.functionCount, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.functionCount, 0)
          }, 0)
        }, 0)
      }
    }

    public totalFunctionHours() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.functionHours, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.functionHours, 0)
          }, 0)
        }, 0)
      }
    }

    public totalTesting() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.testingCount, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.testingCount, 0)
          }, 0)
        }, 0)
      }
    }

    public totalTestingHours() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.testingHours, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.testingHours, 0)
          }, 0)
        }, 0)
      }
    }

    public totalInstructor() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.instructorCount, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.instructorCount, 0)
          }, 0)
        }, 0)
      }
    }

    public totalInstructorHours() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.instructorHours, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.instructorHours, 0)
          }, 0)
        }, 0)
      }
    }

    public totalHoliday() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.holidayCount, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.holidayCount, 0)
          }, 0)
        }, 0)
      }
    }

    public totalHolidayHours() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.holidayHours, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.holidayHours, 0)
          }, 0)
        }, 0)
      }
    }

    public totalQuarantine() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.quarantineCount, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.quarantineCount, 0)
          }, 0)
        }, 0)
      }
    }

    public totalQuarantineHours() {
      if (this.props.report.generated.instructors.length > 0) {
        return _.reduce(this.props.report.generated.instructors, (a1:number, instructor:any) => {
          return a1 + _.reduce(instructor.programs, (a2:number, program:any) => {
            return a2 + _.reduce(program.courses, (a3:number, course:any) => {
              return a3 + _.reduce(course.classes, (a4:number, klass:any) => a4 + klass.quarantineHours, 0)
            }, 0)
          }, 0)
        }, 0)
      } else {
        return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            return a2 + _.reduce(course.classes, (a3:number, klass:any) => a3 + klass.quarantineHours, 0)
          }, 0)
        }, 0)
      }
    }

    public render() {
      if (!this.props.report) {
        return (
          <Fragment>
            <Dimmer active inverted>
              <Loader content='Generating report...' />
            </Dimmer>
          </Fragment>
        )
      } else if (this.props.report.loading) {
        if (this.props.connected) {
          return (
            <Fragment>
              <Dimmer active inverted>
                <Loader content='Generating report...' />
              </Dimmer>
            </Fragment>
          )
        } else {
          return (
            <Fragment>
              <Dimmer active inverted>
                <Icon name='warning sign' color='red' size='huge' />
                <div className='name' style={{textAlign:'center',color:'black',marginTop:10}}><b>Error</b></div>
              </Dimmer>
            </Fragment>
          )
        }
      } else {
        let params = qs.parse(window.location.search.substr(1, window.location.search.length));
        let data = this.props.report.generated
        let options = this.props.options
        let num = 0

        switch (params.reportType) {
        case 'inmate':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Attendance Report</div>{params.startDate != null && params.endDate != null ? <div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div> : null}</div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'inmate' ? 'Student' : params.reportType == 'class' ? 'Class' : params.reportType == 'cancellations' ? 'Cancellations' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Attn. type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.attnType == 'hours' ? 'Hours' : params.attnType == 'sessions' ? 'Sessions' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Active:</div><div style={{display:'inline-block',fontWeight:400}}>{params.active == 'active' ? 'Active students' : params.active == 'all' ? 'All students' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Ed. Status:</div><div style={{display:'inline-block',fontWeight:400}}>{params.educationStatus == 'current' ? 'Currently enrolled' : params.educationStatus == 'not' ? 'Not currently enrolled' : params.educationStatus == 'previous' ? 'Previously enrolled' : params.educationStatus == 'never' ? 'Never enrolled' : params.educationStatus == 'recent' ? 'Recent arrival' : params.educationStatus == 'title1' ? 'Title 1' : params.educationStatus == 'abe' ? 'ABE student' : params.educationStatus == 'tabe' ? 'Ready for TABE' : 'Any'}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == 0 ? 'All' : _.map((typeof params.facilities == 'string' ? [params.facilities] : params.facilities), (id:any) => _.any(options.facilities, (f:any) => f.key == id) ? _.find(options.facilities, (f:any) => f.key == id).text : '').join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : params.programs), (id:any) => _.any(options.programs, (p:any) => p.recordID == id) ? _.find(options.programs, (p:any) => p.recordID == id).name : '').join(', ')}</div></div>
                {params.inmate ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Student:</div><div style={{display:'inline-block',fontWeight:400}}>{params.inmate}</div></div> : null}
              </div>
              <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Student</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Student</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Attended</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Excused</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Unexcused</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Percentage</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.inmates.map((inmate:any, i:number) =>
                    inmate.programs.map((program:any, p:number) =>
                      program.courses.map((course:any, c:number) =>
                        course.classes.map((klass:any, t:number) => {
                          num += 1
                          return (
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                              <Table.Cell>{p == 0 && c == 0 && t== 0 ? inmate.name : ""}</Table.Cell>
                              <Table.Cell>{c== 0 && t == 0 ? program.name : ""}</Table.Cell>
                              <Table.Cell>{t == 0 ? course.name : ""}</Table.Cell>
                              <Table.Cell>[{klass.recordID}] {klass.name}</Table.Cell>
                              <Table.Cell>{inmate.name}</Table.Cell>
                              <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{klass.attended}</b></div></Table.Cell>
                              <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{klass.excused}</b></div></Table.Cell>
                              <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{klass.unexcused}</b></div></Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{klass.percentage}</b></div></Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>
                          )
                        })
                      )
                    )
                  )}
                </Table.Body>
              </Table>
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        case 'class':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Attendance Report</div>{params.startDate != null && params.endDate != null ? <div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div> : null}</div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'inmate' ? 'Student' : params.reportType == 'class' ? 'Class' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Attn. type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.attnType == 'hours' ? 'Hours' : params.attnType == 'sessions' ? 'Sessions' : ''}</div></div>
                {params.instructor ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Instructor:</div><div style={{display:'inline-block',fontWeight:400}}>{params.instructor}</div></div> : null}
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Active:</div><div style={{display:'inline-block',fontWeight:400}}>{params.active == 'active' ? 'Active classes' : params.active == 'all' ? 'All classes' : ''}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == 0 ? 'All' : _.map((typeof params.facilities == 'string' ? [params.facilities] : params.facilities), (id:any) => _.any(options.facilities, (f:any) => f.key == id) ? _.find(options.facilities, (f:any) => f.key == id).text : '').join(', ')}</div></div>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : params.programs), (id:any) => _.any(options.programs, (p:any) => p.recordID == id) ? _.find(options.programs, (p:any) => p.recordID == id).name : '').join(', ')}</div></div>
                {params.class ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Class:</div><div style={{display:'inline-block',fontWeight:400}}>{params.class}</div></div> : null}
              </div>
              <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Attended</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Excused</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Unexcused</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Percentage</span></div></Table.HeaderCell>
                    <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.programs.map((program:any, p:number) =>
                    program.courses.map((course:any, c:number) =>
                      course.classes.map((klass:any, t:number) =>
                        klass.inmates.map((inmate:any, i:number) => {
                          num += 1
                          return (
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={i}>
                              <Table.Cell>{i == 0 && t == 0 && c == 0 ? program.name : ""}</Table.Cell>
                              <Table.Cell>{i == 0 && t == 0 ? course.name : ""}</Table.Cell>
                              <Table.Cell>{i == 0 ? `[${klass.recordID}] ${klass.name}` : ""}</Table.Cell>
                              <Table.Cell>{inmate.name}</Table.Cell>
                              <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{inmate.attended}</b></div></Table.Cell>
                              <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{inmate.excused}</b></div></Table.Cell>
                              <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{inmate.unexcused}</b></div></Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{inmate.percentage}</b></div></Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>
                          )
                        })
                      )
                    )
                  )}
                </Table.Body>
              </Table>
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        case 'cancellations':
          switch (params.breakdown) {
          case 'class':
              return (
                <Container fluid>
                  <div style={{textAlign:'center',marginBottom:15}}>
                    <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Attendance Report</div>{params.startDate != null && params.endDate != null ? <div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div> : null}</div>
                  </div>
                  <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'inmate' ? 'Student' : params.reportType == 'class' ? 'Class' : params.reportType == 'cancellations' ? 'Cancellations' : ''}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Attn. type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.attnType == 'hours' ? 'Hours' : params.attnType == 'sessions' ? 'Sessions' : ''}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Breakdown by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.breakdown == 'class' ? 'Class' : params.breakdown == 'sesion' ? 'Session' : params.breakdown == 'instructor' ? 'Instructor' : ''}</div></div>
                    {params.instructor ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Instructor:</div><div style={{display:'inline-block',fontWeight:400}}>{params.instructor}</div></div> : null}
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Active:</div><div style={{display:'inline-block',fontWeight:400}}>{params.active == 'active' ? 'Active classes' : params.active == 'all' ? 'All classes' : ''}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == 0 ? 'All' : _.map((typeof params.facilities == 'string' ? [params.facilities] : params.facilities), (id:any) => _.any(options.facilities, (f:any) => f.key == id) ? _.find(options.facilities, (f:any) => f.key == id).text : '').join(', ')}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : params.programs), (id:any) => _.any(options.programs, (p:any) => p.recordID == id) ? _.find(options.programs, (p:any) => p.recordID == id).name : '').join(', ')}</div></div>
                    {params.class ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Class:</div><div style={{display:'inline-block',fontWeight:400}}>{params.class}</div></div> : null}
                  </div>
                  <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Sessions</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cancelled</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Security</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Furlough Day</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Education - Function</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Education - Testing</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Instructor</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Holiday</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Quarantine Event</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Percentage</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.programs.map((program:any, p:number) =>
                        program.courses.map((course:any, c:number) =>
                          course.classes.map((klass:any, t:number) => {
                            num += 1
                            return (
                              <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={t}>
                                <Table.Cell>{t == 0 && c == 0 ? program.name : ""}</Table.Cell>
                                <Table.Cell>{t == 0 ? course.name : ""}</Table.Cell>
                                <Table.Cell>{`[${klass.recordID}] ${klass.name}`}</Table.Cell>
                                <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.sessionCount : klass.sessionHours}</b></div></Table.Cell>
                                <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.cancelledCount : klass.cancelledHours}</b></div></Table.Cell>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.securityCount : klass.securityHours}</b></div></Table.Cell>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.furloughCount : klass.furloughHours}</b></div></Table.Cell>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.functionCount : klass.functionHours}</b></div></Table.Cell>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.testingCount : klass.testingHours}</b></div></Table.Cell>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.instructorCount : klass.instructorHours}</b></div></Table.Cell>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.holidayCount : klass.holidayHours}</b></div></Table.Cell>
                                <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.quarantineCount : klass.quarantineHours}</b></div></Table.Cell>
                                <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.sessionPercentage : klass.hoursPercentage}</b></div></Table.Cell>
                                <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                              </Table.Row>
                            )
                          })
                        )
                      )}
                      {(() => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell colSpan={3} style={{textAlign:'right'}}>Totals</Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalSessions()}</b> : <b>{this.totalSessionHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalCancelled()}</b> : <b>{this.totalCancelledHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalSecurity()}</b> : <b>{this.totalSecurityHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalFurlough()}</b> : <b>{this.totalFurloughHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalFunction()}</b> : <b>{this.totalFunctionHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalTesting()}</b> : <b>{this.totalTestingHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalInstructor()}</b> : <b>{this.totalInstructorHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalHoliday()}</b> : <b>{this.totalHolidayHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalQuarantine()}</b> : <b>{this.totalQuarantineHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{((this.totalCancelled() / this.totalSessions()) * 100).toFixed()}%</b> : <b>{((this.totalCancelledHours() / this.totalSessionHours()) * 100).toFixed()}%</b>}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })()}
                    </Table.Body>
                  </Table>
                </Container>
              )
          case 'session':
              return (
                <Container fluid>
                  <div style={{textAlign:'center',marginBottom:15}}>
                    <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Attendance Report</div>{params.startDate != null && params.endDate != null ? <div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div> : null}</div>
                  </div>
                  <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'inmate' ? 'Student' : params.reportType == 'class' ? 'Class' : params.reportType == 'cancellations' ? 'Cancellations' : ''}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Attn. type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.attnType == 'hours' ? 'Hours' : params.attnType == 'sessions' ? 'Sessions' : ''}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Breakdown by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.breakdown == 'class' ? 'Class' : params.breakdown == 'sesion' ? 'Session' : params.breakdown == 'instructor' ? 'Instructor' : ''}</div></div>
                    {params.instructor ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Instructor:</div><div style={{display:'inline-block',fontWeight:400}}>{params.instructor}</div></div> : null}
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Active:</div><div style={{display:'inline-block',fontWeight:400}}>{params.active == 'active' ? 'Active classes' : params.active == 'all' ? 'All classes' : ''}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == 0 ? 'All' : _.map((typeof params.facilities == 'string' ? [params.facilities] : params.facilities), (id:any) => _.any(options.facilities, (f:any) => f.key == id) ? _.find(options.facilities, (f:any) => f.key == id).text : '').join(', ')}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : params.programs), (id:any) => _.any(options.programs, (p:any) => p.recordID == id) ? _.find(options.programs, (p:any) => p.recordID == id).name : '').join(', ')}</div></div>
                    {params.class ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Class:</div><div style={{display:'inline-block',fontWeight:400}}>{params.class}</div></div> : null}
                  </div>
                  <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Session</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Sessions</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cancelled</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Security</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Furlough Day</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Education - Function</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Education - Testing</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Instructor</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Holiday</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Quarantine Event</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Percentage</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.programs.map((program:any, p:number) =>
                        program.courses.map((course:any, c:number) =>
                          course.classes.map((klass:any, t:number) =>
                            [null].concat(klass.sessions).map((session:any, s:number) => {
                              num += 1
                              if (session == null) {
                                return (
                                  <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={t}>
                                    <Table.Cell>{t == 0 && c == 0 ? program.name : ""}</Table.Cell>
                                    <Table.Cell>{t == 0 ? course.name : ""}</Table.Cell>
                                    <Table.Cell>{`[${klass.recordID}] ${klass.name}`}</Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.sessionCount : klass.sessionHours}</b></div></Table.Cell>
                                    <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.cancelledCount : klass.cancelledHours}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.securityCount : klass.securityHours}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.furloughCount : klass.furloughHours}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.functionCount : klass.functionHours}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.testingCount : klass.testingHours}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.instructorCount : klass.instructorHours}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.holidayCount : klass.holidayHours}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.quarantineCount : klass.quarantineHours}</b></div></Table.Cell>
                                    <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.sessionPercentage : klass.hoursPercentage}</b></div></Table.Cell>
                                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                  </Table.Row>
                                )
                              } else {
                                return (
                                  <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={t}>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell></Table.Cell>
                                    <Table.Cell>{`${this.dayOfWeek3(session.day)}. ${session.date} - ${session.time} | ${session.instructorName}`}</Table.Cell>
                                    <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b></b></div></Table.Cell>
                                    <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{session.cancelled ? '1' : '0'}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{session.security ? '1' : '0'}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{session.furlough ? '1' : '0'}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{session.function ? '1' : '0'}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{session.testing ? '1' : '0'}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{session.instructor ? '1' : '0'}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{session.holiday ? '1' : '0'}</b></div></Table.Cell>
                                    <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{session.quarantine ? '1' : '0'}</b></div></Table.Cell>
                                    <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b></b></div></Table.Cell>
                                    <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                  </Table.Row>
                                )
                              }
                            })
                          )
                        )
                      )}
                      {(() => {
                        num += 1
                        return (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                            <Table.Cell colSpan={4} style={{textAlign:'right'}}>Totals</Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalSessions()}</b> : <b>{this.totalSessionHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalCancelled()}</b> : <b>{this.totalCancelledHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalSecurity()}</b> : <b>{this.totalSecurityHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalFurlough()}</b> : <b>{this.totalFurloughHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalFunction()}</b> : <b>{this.totalFunctionHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalTesting()}</b> : <b>{this.totalTestingHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalInstructor()}</b> : <b>{this.totalInstructorHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalHoliday()}</b> : <b>{this.totalHolidayHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalQuarantine()}</b> : <b>{this.totalQuarantineHours()}</b>}</div></Table.Cell>
                            <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{((this.totalCancelled() / this.totalSessions()) * 100).toFixed()}%</b> : <b>{((this.totalCancelledHours() / this.totalSessionHours()) * 100).toFixed()}%</b>}</div></Table.Cell>
                            <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                          </Table.Row>
                        )
                      })()}
                    </Table.Body>
                  </Table>
                </Container>
              )
          case 'instructor':
              return (
                <Container fluid>
                  <div style={{textAlign:'center',marginBottom:15}}>
                    <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Attendance Report</div>{params.startDate != null && params.endDate != null ? <div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div> : null}</div>
                  </div>
                  <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'inmate' ? 'Student' : params.reportType == 'class' ? 'Class' : params.reportType == 'cancellations' ? 'Cancellations' : ''}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Attn. type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.attnType == 'hours' ? 'Hours' : params.attnType == 'sessions' ? 'Sessions' : ''}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Breakdown by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.breakdown == 'class' ? 'Class' : params.breakdown == 'sesion' ? 'Session' : params.breakdown == 'instructor' ? 'Instructor' : ''}</div></div>
                    {params.instructor ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Instructor:</div><div style={{display:'inline-block',fontWeight:400}}>{params.instructor}</div></div> : null}
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Active:</div><div style={{display:'inline-block',fontWeight:400}}>{params.active == 'active' ? 'Active classes' : params.active == 'all' ? 'All classes' : ''}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == 0 ? 'All' : _.map((typeof params.facilities == 'string' ? [params.facilities] : params.facilities), (id:any) => _.any(options.facilities, (f:any) => f.key == id) ? _.find(options.facilities, (f:any) => f.key == id).text : '').join(', ')}</div></div>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Programs:</div><div style={{display:'inline-block',fontWeight:400}}>{params.programs == 0 ? 'All' : _.map((typeof params.programs == 'string' ? [params.programs] : params.programs), (id:any) => _.any(options.programs, (p:any) => p.recordID == id) ? _.find(options.programs, (p:any) => p.recordID == id).name : '').join(', ')}</div></div>
                    {params.class ? <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Class:</div><div style={{display:'inline-block',fontWeight:400}}>{params.class}</div></div> : null}
                  </div>
                  <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Instructor</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Sessions</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Cancelled</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Security</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Furlough Day</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Education - Function</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Education - Testing</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Instructor</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Holiday</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Quarantine Event</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Percentage</span></div></Table.HeaderCell>
                        <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:40}}></span></div></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.instructors.map((instructor:any, i:number) =>
                        instructor.programs.map((program:any, p:number) =>
                          program.courses.map((course:any, c:number) =>
                            course.classes.map((klass:any, t:number) => {
                              num += 1
                              return (
                                <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={t}>
                                  <Table.Cell>{t == 0 && c == 0 && p == 0 ? instructor.name : ""}</Table.Cell>
                                  <Table.Cell>{t == 0 && c == 0 ? program.name : ""}</Table.Cell>
                                  <Table.Cell>{t == 0 ? course.name : ""}</Table.Cell>
                                  <Table.Cell>{`[${klass.recordID}] ${klass.name}`}</Table.Cell>
                                  <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.sessionCount : klass.sessionHours}</b></div></Table.Cell>
                                  <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.cancelledCount : klass.cancelledHours}</b></div></Table.Cell>
                                  <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.securityCount : klass.securityHours}</b></div></Table.Cell>
                                  <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.furloughCount : klass.furloughHours}</b></div></Table.Cell>
                                  <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.functionCount : klass.functionHours}</b></div></Table.Cell>
                                  <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.testingCount : klass.testingHours}</b></div></Table.Cell>
                                  <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.instructorCount : klass.instructorHours}</b></div></Table.Cell>
                                  <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.holidayCount : klass.holidayHours}</b></div></Table.Cell>
                                  <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.quarantineCount : klass.quarantineHours}</b></div></Table.Cell>
                                  <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}><b>{params.attnType == 'sessions' ? klass.sessionPercentage : klass.hoursPercentage}</b></div></Table.Cell>
                                  <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                                </Table.Row>
                              )
                            })
                          )
                        ))}
                        {(() => {
                          num += 1
                          return (
                            <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}}>
                              <Table.Cell colSpan={4} style={{textAlign:'right'}}>Totals</Table.Cell>
                              <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalSessions()}</b> : <b>{this.totalSessionHours()}</b>}</div></Table.Cell>
                              <Table.Cell style={{color:'rgb(33, 133, 208)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalCancelled()}</b> : <b>{this.totalCancelledHours()}</b>}</div></Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalSecurity()}</b> : <b>{this.totalSecurityHours()}</b>}</div></Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalFurlough()}</b> : <b>{this.totalFurloughHours()}</b>}</div></Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalFunction()}</b> : <b>{this.totalFunctionHours()}</b>}</div></Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalTesting()}</b> : <b>{this.totalTestingHours()}</b>}</div></Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalInstructor()}</b> : <b>{this.totalInstructorHours()}</b>}</div></Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalHoliday()}</b> : <b>{this.totalHolidayHours()}</b>}</div></Table.Cell>
                              <Table.Cell style={{textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{this.totalQuarantine()}</b> : <b>{this.totalQuarantineHours()}</b>}</div></Table.Cell>
                              <Table.Cell style={{color:'rgb(219, 40, 40)',textAlign:'right'}}><div style={{whiteSpace:'nowrap'}}>{params.attnType == 'sessions' ? <b>{((this.totalCancelled() / this.totalSessions()) * 100).toFixed()}%</b> : <b>{((this.totalCancelledHours() / this.totalSessionHours()) * 100).toFixed()}%</b>}</div></Table.Cell>
                              <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                            </Table.Row>
                          )
                        })()}
                    </Table.Body>
                  </Table>
                </Container>
              )
          }
          break;
        default:
            return null
        }
      }
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.attendance, connected: state.reports.connected, options: state.reports.options, settings: state.staff.settings } },
  { ...StaffStore.actionCreators, ...ReportsStore.actionCreators }
)(AttendanceHTML as any)
