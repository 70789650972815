import { Action, Reducer } from 'redux'
import { AppThunkAction } from '../'
import agent from '../../agent'
import { FetchStaffFavoritesAction } from './staff'
import { toast } from 'react-toastify'
const store = require('store2')
const _ = require('underscore')

export type Course = any
export type Template = any
export type Class = any

interface ToggleDatesAction { type: 'TOGGLE_CLASS_DATES' }
interface ToggleFiltersAction { type: 'TOGGLE_CLASS_FILTERS' }
interface ToggleWaitlistAction { type: 'TOGGLE_TEMPLATE_WAITLIST' }
interface ResetFiltersAction { type: 'RESET_CLASS_FILTERS' }
interface SetLoadingAction { type: 'SET_CLASSES_LOADING', loading: boolean }
interface SetTemplatesLoadingAction { type: 'SET_TEMPLATES_LOADING', loading: boolean }
interface SetCertificatesLoadingAction { type: 'SET_CERTIFICATES_LOADING', loading: boolean }
interface SetFilterAction { type: 'SET_CLASS_FILTER', name: string, value: FilterValue }
interface FetchWaitlistAction { type: 'FETCH_WAITLIST', recordID: number, inmates: any[] }
interface FetchClassesAction { type: 'FETCH_CLASSES', courses: Course[], offset: number, hasMore: boolean, count: number, facilities: any[], instructors: any[], programs: any[], funding: any[], days: any[], genders: any[] }
interface FetchTemplatesAction { type: 'FETCH_TEMPLATES', courses: Course[], offset: number, hasMore: boolean, count: number, facilities: any[], instructors: any[], programs: any[], funding: any[], days: any[], genders: any[] }
interface FetchCertificatesAction { type: 'FETCH_CERTIFICATES', courses: Course[], offset: number, hasMore: boolean, count: number, facilities: any[], instructors: any[], programs: any[], funding: any[], days: any[], genders: any[] }
interface FetchNextClassesAction { type: 'FETCH_NEXT_CLASSES', courses: Course[], offset: number, hasMore: boolean }
interface FetchNextTemplatesAction { type: 'FETCH_NEXT_TEMPLATES', courses: Course[], offset: number, hasMore: boolean }
interface FetchNextCertificatesAction { type: 'FETCH_NEXT_CERTIFICATES', courses: Course[], offset: number, hasMore: boolean }
interface FetchDataAction { type: 'FETCH_CLASSES_DATA', instructors: any, programs: any, funding: any, archivedInstructors: any, archivedPrograms: any, archivedFunding: any }
interface SelectInmateAction { type: 'SELECT_CLASSES_WAITLIST_INMATE', key: number, value: string }
interface InmatesLoadingAction { type: 'SEARCH_TEMPLATE_WAITLIST_LOADING', value: string }
interface SearchInmatesAction { type: 'SEARCH_TEMPLATE_WAITLIST_INMATES', inmates: any[] }
interface ClearInmatesAction { type: 'CLEAR_WAITLIST_INMATES' }
interface AddWaitlistAction { type: 'ADD_CLASSES_WAITLIST_INMATE' }
interface LoadFiltersAction { type: 'LOAD_CLASS_FILTERS', filters: any, activeFilters: any }
interface FavoriteClassAction { type: 'FAVORITE_CLASS', recordID: number }
interface UnfavoriteClassAction { type: 'UNFAVORITE_CLASS', recordID: number }
interface FavoriteTemplateAction { type: 'FAVORITE_TEMPLATE', recordID: number }
interface UnfavoriteTemplateAction { type: 'UNFAVORITE_TEMPLATE', recordID: number }
interface WaitlistToggleAction { type: 'CLASS_WAITLIST_FACILITY_TOGGLE', toggle: boolean }
interface LoadingPDFAction { type: 'LOADING_CLASSES_PDF', tab: string, loading: boolean }
interface GeneratePDFAction { type: 'GENERATE_CLASSES_PDF', tab: string, data: any }
interface SetURLAction { type: 'SET_CLASSES_URL', tab: string, url: string }

type KnownAction = LoadingPDFAction | GeneratePDFAction | SetURLAction | WaitlistToggleAction | FetchStaffFavoritesAction | FavoriteClassAction | UnfavoriteClassAction | FavoriteTemplateAction | UnfavoriteTemplateAction | LoadFiltersAction | AddWaitlistAction | ClearInmatesAction | SearchInmatesAction | InmatesLoadingAction | SelectInmateAction | ToggleWaitlistAction | FetchCertificatesAction | SetCertificatesLoadingAction | FetchWaitlistAction | FetchTemplatesAction | FetchNextCertificatesAction | FetchNextTemplatesAction | SetTemplatesLoadingAction | ToggleFiltersAction | ToggleDatesAction | FetchClassesAction | FetchNextClassesAction | FetchDataAction | SetLoadingAction | SetFilterAction | ResetFiltersAction
type FilterValue = string | number | boolean | null
export type ClassFilters = { [key:string]: FilterValue }

const defaultFilters:any = {
    classStatus: 'active',
    gender: 'B',
    facility: 'my',
    instructor: null,
    program: null,
    funding: null,
    activeOn: null,
    className: '',
    classID: '',
    days: []
}

type Tabs = {
    classes: {
        loading: boolean,
        hasMore: boolean,
        offset: number,
        count: number,
        data: Course,
        pdf: {
            generating: boolean,
            ready: boolean,
            url: string,
            data: any
        }
    },
    templates: {
        loading: boolean,
        hasMore: boolean,
        offset: number,
        count: number,
        data: Course,
        pdf: {
            generating: boolean,
            ready: boolean,
            url: string,
            data: any
        }
    },
    certificates: {
        loading: boolean,
        hasMore: boolean,
        offset: number,
        count: number,
        data: Course,
        pdf: {
            generating: boolean,
            ready: boolean,
            url: string,
            data: any
        }
    }
};

export interface ClassesState {
    open: boolean,
    dates: boolean,
    filters: ClassFilters,
    activeFilters: string[],
    data: {
        instructors: any,
        programs: any,
        funding: any,
        archivedInstructors: any,
        archivedPrograms: any,
        archivedFunding: any
    },
    tabs: Tabs,
    modals: {
        waitlist: {
            open: boolean,
            loading: boolean,
            all: boolean,
            template: number,
            id: number,
            value: string,
            inmates: any[],
            search: any[]
        }
    },
    options: {
        facilities: any[],
        instructors: any[],
        programs: any[],
        funding: any[],
        days: any[],
        genders: any[]
    }
}

var typingTimeout: any

const buildParams = (filters:ClassFilters, activeFilters: string[], params: URLSearchParams) => {
    for (let [key, value] of Object.entries(filters)) {
        if (value != undefined && value != null && value != '') {
            if (typeof value == 'object') {
                for (let ovalue of Object.values(value)) {
                  params.append(key, ovalue as string)
                }
            } else {
                params.append(key, value.toString())
            }
        }
    }
    _.each(activeFilters, (filter:string) => {
        params.append('active', filter)
    })
    return params
}

export const actionCreators = {
    toggleDates: () => ({ type: 'TOGGLE_CLASS_DATES' } as ToggleDatesAction),
    toggleWaitlist: () => ({ type: 'TOGGLE_TEMPLATE_WAITLIST' } as ToggleWaitlistAction),
    setLoading: (loading: boolean) => ({ type: 'SET_CLASSES_LOADING', loading: loading } as SetLoadingAction),
    setURL: (tab: string, url: string) => ({ type: 'SET_CLASSES_URL', tab: tab, url: url } as SetURLAction),
    selectInmate: (data: any) => ({ type: 'SELECT_CLASSES_WAITLIST_INMATE', key: data.key, value: data.title } as SelectInmateAction),
    clearInmates: () => ({ type: 'CLEAR_WAITLIST_INMATES' } as ClearInmatesAction),
    waitlistFacilityToggle: (toggle: boolean) => ({ type: 'CLASS_WAITLIST_FACILITY_TOGGLE', toggle: toggle } as WaitlistToggleAction),
    toggleFilters: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0
        var state = getState().classes
        var filters = store(`${id}:filters:classes`) || {}
        filters["showFilters"] = !state.open
        store(`${id}:filters:classes`, filters)
        dispatch({ type: 'TOGGLE_CLASS_FILTERS' } as ToggleFiltersAction)
    },
    resetFilters: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'RESET_CLASS_FILTERS' } as ResetFiltersAction)
        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0
        let state = getState().classes
        let tab = getState().drawer.tab
        let params = new URLSearchParams()
        params.append('offset', '0')
        buildParams(state.filters, state.activeFilters, params)
        store(`${id}:filters:classes`, state.filters)
        store(`${id}:activeFilters:classes`, state.activeFilters)
        switch (tab) {
        case 'classes':
            if (document.getElementById('classes-table')) document.getElementById('classes-table')!.scrollTop = 0
            dispatch({ type: 'SET_CLASSES_LOADING', loading: true } as SetLoadingAction)
            var { courses, offset, hasMore, recordCount, facilityOpts, instructorOpts, programOpts, fundingOpts, dayOpts, genderOpts } = await agent.Classes.fetchClasses(params)
            dispatch({ type: 'FETCH_CLASSES', courses: courses, offset: offset, hasMore: hasMore, count: recordCount, facilities: facilityOpts, instructors: instructorOpts, programs: programOpts, funding: fundingOpts, days: dayOpts, genders: genderOpts } as FetchClassesAction)
            break
        case 'templates':
            if (document.getElementById('templates-table')) document.getElementById('templates-table')!.scrollTop = 0
            dispatch({ type: 'SET_TEMPLATES_LOADING', loading: true } as SetTemplatesLoadingAction)
            var { courses, offset, hasMore, recordCount, facilityOpts, instructorOpts, programOpts, fundingOpts, dayOpts, genderOpts } = await agent.Classes.fetchTemplates(params)
            dispatch({ type: 'FETCH_TEMPLATES', courses: courses, offset: offset, hasMore: hasMore, count: recordCount, facilities: facilityOpts, instructors: instructorOpts, programs: programOpts, funding: fundingOpts, days: dayOpts, genders: genderOpts } as FetchTemplatesAction)
            break
        case 'certificates':
            if (document.getElementById('certificates-table')) document.getElementById('certificates-table')!.scrollTop = 0
            dispatch({ type: 'SET_CERTIFICATES_LOADING', loading: true } as SetCertificatesLoadingAction)
            var { courses, offset, hasMore, recordCount, facilityOpts, instructorOpts, programOpts, fundingOpts, dayOpts, genderOpts } = await agent.Classes.fetchCertificates(params)
            dispatch({ type: 'FETCH_CERTIFICATES', courses: courses, offset: offset, hasMore: hasMore, count: recordCount, facilities: facilityOpts, instructors: instructorOpts, programs: programOpts, funding: fundingOpts, days: dayOpts, genders: genderOpts } as FetchCertificatesAction)
            break
        }
    },
    setFilter: (name:string, value:FilterValue): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0
        let tab = getState().drawer.tab
        dispatch({ type: 'SET_CLASS_FILTER', name: name, value: value } as SetFilterAction)
        clearTimeout(typingTimeout)
        switch (tab) {
        case 'classes':
            typingTimeout = setTimeout(async () => {
                dispatch({ type: 'SET_CLASSES_LOADING', loading: true } as SetLoadingAction)
                if (document.getElementById('classes-table')) document.getElementById('classes-table')!.scrollTop = 0
                let state = getState().classes
                let params = new URLSearchParams()
                params.append('offset', '0')
                buildParams(state.filters, state.activeFilters, params)
                store(`${id}:filters:classes`, state.filters)
                store(`${id}:activeFilters:classes`, state.activeFilters)
                const { courses, offset, hasMore, recordCount, facilityOpts, instructorOpts, programOpts, fundingOpts, dayOpts, genderOpts } = await agent.Classes.fetchClasses(params)
                dispatch({ type: 'FETCH_CLASSES', courses: courses, offset: offset, hasMore: hasMore, count: recordCount, facilities: facilityOpts, instructors: instructorOpts, programs: programOpts, funding: fundingOpts, days: dayOpts, genders: genderOpts } as FetchClassesAction)
            }, 1500)
            break
        case 'templates':
            typingTimeout = setTimeout(async () => {
                dispatch({ type: 'SET_TEMPLATES_LOADING', loading: true } as SetTemplatesLoadingAction)
                if (document.getElementById('templates-table')) document.getElementById('templates-table')!.scrollTop = 0
                let state = getState().classes
                let params = new URLSearchParams()
                params.append('offset', '0')
                buildParams(state.filters, state.activeFilters, params)
                store(`${id}:filters:classes`, state.filters)
                store(`${id}:activeFilters:classes`, state.activeFilters)
                const { courses, offset, hasMore, recordCount, facilityOpts, instructorOpts, programOpts, fundingOpts, dayOpts, genderOpts } = await agent.Classes.fetchTemplates(params)
                dispatch({ type: 'FETCH_TEMPLATES', courses: courses, offset: offset, hasMore: hasMore, count: recordCount, facilities: facilityOpts, instructors: instructorOpts, programs: programOpts, funding: fundingOpts, days: dayOpts, genders: genderOpts } as FetchTemplatesAction)
            }, 1500)
            break
        case 'certificates':
            typingTimeout = setTimeout(async () => {
                dispatch({ type: 'SET_CERTIFICATES_LOADING', loading: true } as SetCertificatesLoadingAction)
                if (document.getElementById('certificates-table')) document.getElementById('certificates-table')!.scrollTop = 0
                let state = getState().classes
                let params = new URLSearchParams()
                params.append('offset', '0')
                buildParams(state.filters, state.activeFilters, params)
                store(`${id}:filters:classes`, state.filters)
                store(`${id}:activeFilters:classes`, state.activeFilters)
                const { courses, offset, hasMore, recordCount, facilityOpts, instructorOpts, programOpts, fundingOpts, dayOpts, genderOpts } = await agent.Classes.fetchCertificates(params)
                dispatch({ type: 'FETCH_CERTIFICATES', courses: courses, offset: offset, hasMore: hasMore, count: recordCount, facilities: facilityOpts, instructors: instructorOpts, programs: programOpts, funding: fundingOpts, days: dayOpts, genders: genderOpts } as FetchCertificatesAction)
            }, 1500)
            break
        }
    },
    fetchClasses: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SET_CLASSES_LOADING', loading: true } as SetLoadingAction)
        if (document.getElementById('classes-table')) document.getElementById('classes-table')!.scrollTop = 0
        let state = getState().classes
        let params = new URLSearchParams()
        params.append('offset', '0')
        buildParams(state.filters, state.activeFilters, params)
        const { courses, offset, hasMore, recordCount, facilityOpts, instructorOpts, programOpts, fundingOpts, dayOpts, genderOpts } = await agent.Classes.fetchClasses(params)
        dispatch({ type: 'FETCH_CLASSES', courses: courses, offset: offset, hasMore: hasMore, count: recordCount, facilities: facilityOpts, instructors: instructorOpts, programs: programOpts, funding: fundingOpts, days: dayOpts, genders: genderOpts } as FetchClassesAction)
    },
    fetchTemplates: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SET_TEMPLATES_LOADING', loading: true } as SetTemplatesLoadingAction)
        if (document.getElementById('templates-table')) document.getElementById('templates-table')!.scrollTop = 0
        let state = getState().classes
        let params = new URLSearchParams()
        params.append('offset', '0')
        buildParams(state.filters, state.activeFilters, params)
        const { courses, offset, hasMore, recordCount, facilityOpts, instructorOpts, programOpts, fundingOpts, dayOpts, genderOpts } = await agent.Classes.fetchTemplates(params)
        dispatch({ type: 'FETCH_TEMPLATES', courses: courses, offset: offset, hasMore: hasMore, count: recordCount, facilities: facilityOpts, instructors: instructorOpts, programs: programOpts, funding: fundingOpts, days: dayOpts, genders: genderOpts } as FetchTemplatesAction)
    },
    fetchCertificates: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SET_CERTIFICATES_LOADING', loading: true } as SetCertificatesLoadingAction)
        if (document.getElementById('certificates-table')) document.getElementById('certificates-table')!.scrollTop = 0
        let state = getState().classes
        let params = new URLSearchParams()
        params.append('offset', '0')
        buildParams(state.filters, state.activeFilters, params)
        const { courses, offset, hasMore, recordCount, facilityOpts, instructorOpts, programOpts, fundingOpts, dayOpts, genderOpts } = await agent.Classes.fetchCertificates(params)
        dispatch({ type: 'FETCH_CERTIFICATES', courses: courses, offset: offset, hasMore: hasMore, count: recordCount, facilities: facilityOpts, instructors: instructorOpts, programs: programOpts, funding: fundingOpts, days: dayOpts, genders: genderOpts } as FetchCertificatesAction)
    },
    fetchNextClasses: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().classes
        dispatch({ type: 'SET_CLASSES_LOADING', loading: true } as SetLoadingAction)
        let params = new URLSearchParams()
        params.append('offset', state.tabs.classes.offset.toString())
        buildParams(state.filters, state.activeFilters, params)
        const { courses, offset, hasMore } = await agent.Classes.fetchClasses(params)
        dispatch({ type: 'FETCH_NEXT_CLASSES', courses: courses, offset: offset, hasMore: hasMore } as FetchNextClassesAction)
    },
    fetchNextTemplates: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().classes
        dispatch({ type: 'SET_TEMPLATES_LOADING', loading: true } as SetTemplatesLoadingAction)
        let params = new URLSearchParams()
        params.append('offset', state.tabs.templates.offset.toString())
        buildParams(state.filters, state.activeFilters, params)
        const { courses, offset, hasMore } = await agent.Classes.fetchTemplates(params)
        dispatch({ type: 'FETCH_NEXT_TEMPLATES', courses: courses, offset: offset, hasMore: hasMore } as FetchNextTemplatesAction)
    },
    fetchNextCertificates: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().classes
        dispatch({ type: 'SET_CERTIFICATES_LOADING', loading: true } as SetCertificatesLoadingAction)
        let params = new URLSearchParams()
        params.append('offset', state.tabs.certificates.offset.toString())
        buildParams(state.filters, state.activeFilters, params)
        const { courses, offset, hasMore } = await agent.Classes.fetchCertificates(params)
        dispatch({ type: 'FETCH_NEXT_CERTIFICATES', courses: courses, offset: offset, hasMore: hasMore } as FetchNextCertificatesAction)
    },
    fetchData: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { instructors } = await agent.Data.fetchActiveInstructors()
        const { instructorData } = await agent.Data.fetchAllInstructors()
        const { programs, archivedPrograms } = await agent.Data.fetchPrograms()
        const { funding, archivedFunding } = await agent.Data.fetchFunding()
        dispatch({ type: 'FETCH_CLASSES_DATA', instructors: instructors, programs: programs, funding: funding, archivedInstructors: instructorData, archivedPrograms: archivedPrograms, archivedFunding: archivedFunding } as FetchDataAction)
    },
    templateActionSelect: (recordID: number, data: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().staff
        if (data && data.value) {
            switch (data.value) {
                case 'waitlist':
                    const { inmates } = await agent.Classes.fetchWaitlist(recordID)
                    dispatch({ type: 'FETCH_WAITLIST', recordID: recordID, inmates: inmates } as FetchWaitlistAction)
                    if (state.staff!.facilities.includes('PSD') || state.staff!.facilities.includes('HPA')) {
                        dispatch({ type: 'CLASS_WAITLIST_FACILITY_TOGGLE', toggle: true } as WaitlistToggleAction)
                    }
                    break
            }
        }
    },
    searchInmates: (value: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().classes.modals.waitlist
        dispatch({ type: 'SEARCH_TEMPLATE_WAITLIST_LOADING', value: value } as InmatesLoadingAction)
        clearTimeout(typingTimeout)
        typingTimeout = setTimeout(async () => {
            let params = new URLSearchParams()
            params.append('query', value)
            params.append('all', state.all.toString())
            const { inmates } = await agent.Classes.searchTemplateInmates(state.template, params)
            dispatch({ type: 'SEARCH_TEMPLATE_WAITLIST_INMATES', inmates: inmates } as SearchInmatesAction)
        }, 1500)
    },
    addWaitlist: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().classes.modals.waitlist
        const { error } = await agent.Classes.addWaitlist(state.template, state.id)
        if (error == null) {
            toast.success('Inmate added')
            const { inmates } = await agent.Classes.fetchWaitlist(state.template)
            dispatch({ type: 'FETCH_WAITLIST', recordID: state.template, inmates: inmates } as FetchWaitlistAction)
        } else {
            toast.error(error, { autoClose: false })
        }
        dispatch({ type: 'ADD_CLASSES_WAITLIST_INMATE' } as AddWaitlistAction)
    },
    removeWaitlist: (recordID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().classes.modals.waitlist
        const { error } = await agent.Classes.removeWaitlist(recordID)
        if (error == null) {
            toast.success('Inmate removed')
            const { inmates } = await agent.Classes.fetchWaitlist(state.template)
            dispatch({ type: 'FETCH_WAITLIST', recordID: state.template, inmates: inmates } as FetchWaitlistAction)
        } else {
            toast.error(error, { autoClose: false })
        }
    },
    loadFilters: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0
        let all = getState().staff.staff ? (getState().staff.staff!.facilities.includes('PSD') || getState().staff.staff!.facilities.includes('HPA')) : false
        var filters:any = {
            classStatus: 'active',
            gender: 'B',
            facility: all ? 'all' : 'my',
            instructor: null,
            program: null,
            funding: null,
            activeOn: null,
            className: '',
            classID: '',
            days: []
        }
        var stored = store(`${id}:filters:classes`) || filters
        var storedActive = store(`${id}:activeFilters:classes`) || []
        dispatch({ type: 'LOAD_CLASS_FILTERS', filters: stored, activeFilters: storedActive } as LoadFiltersAction)
    },
    favoriteClassRow: (recordID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { error } = await agent.Classes.favorite(recordID)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Class favorited')
            dispatch({ type: 'FAVORITE_CLASS', recordID: recordID } as FavoriteClassAction)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    },
    unfavoriteClassRow: (recordID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { error } = await agent.Classes.unfavorite(recordID)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Class unfavorited')
            dispatch({ type: 'UNFAVORITE_CLASS', recordID: recordID } as UnfavoriteClassAction)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    },
    favoriteTemplateRow: (recordID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { error } = await agent.Admin.favoriteTemplate(recordID)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Class template favorited')
            dispatch({ type: 'FAVORITE_TEMPLATE', recordID: recordID } as FavoriteTemplateAction)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    },
    unfavoriteTemplateRow: (recordID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { error } = await agent.Admin.unfavoriteTemplate(recordID)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success('Class template unfavorited')
            dispatch({ type: 'UNFAVORITE_TEMPLATE', recordID: recordID } as UnfavoriteTemplateAction)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    },
    generateClassesPDF: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().classes
        let params = new URLSearchParams()
        params.append('offset', '-1')
        buildParams(state.filters, state.activeFilters, params)
        dispatch({ type: 'LOADING_CLASSES_PDF', tab: 'classes', loading: true } as LoadingPDFAction)
        const { courses } = await agent.Classes.fetchClasses(params)
        dispatch({ type: 'GENERATE_CLASSES_PDF', tab: 'classes', data: courses } as GeneratePDFAction)
    }
}

export const reducer: Reducer<ClassesState> = (state: ClassesState | undefined, incomingAction: Action): ClassesState => {
    if (state === undefined) {
        var open = true
        var filters:any = defaultFilters

        return {
            open: open,
            dates: false,
            filters: filters,
            activeFilters: [],
            data: {
                instructors: [],
                programs: [],
                funding: [],
                archivedInstructors: [],
                archivedPrograms: [],
                archivedFunding: []
            },
            tabs: {
                classes: {
                    loading: true,
                    hasMore: true,
                    offset: 0,
                    count: 0,
                    data: [],
                    pdf: {
                        generating: false,
                        ready: false,
                        url: '',
                        data: {}
                    }
                },
                templates: {
                    loading: true,
                    hasMore: true,
                    offset: 0,
                    count: 0,
                    data: [],
                    pdf: {
                        generating: false,
                        ready: false,
                        url: '',
                        data: {}
                    }
                },
                certificates: {
                    loading: true,
                    hasMore: true,
                    offset: 0,
                    count: 0,
                    data: [],
                    pdf: {
                        generating: false,
                        ready: false,
                        url: '',
                        data: {}
                    }
                }
            },
            modals: {
                waitlist: {
                    open: false,
                    all: false,
                    loading: false,
                    template: 0,
                    id: 0,
                    value: '',
                    inmates: [],
                    search: []
                }
            },
            options: {
                facilities: [],
                instructors: [],
                programs: [],
                funding: [],
                days: [],
                genders: []
            }
        }
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'TOGGLE_CLASS_FILTERS':
            return {
                ...state,
                open: !state.open
            }
        case 'TOGGLE_CLASS_DATES':
            return {
                ...state,
                dates: !state.dates
            }
        case 'TOGGLE_TEMPLATE_WAITLIST':
            return {
                ...state,
                modals: {
                    waitlist: {
                        open: !state.modals.waitlist.open,
                        all: false,
                        loading: false,
                        template: 0,
                        id: 0,
                        value: '',
                        inmates: [],
                        search: []
                    }
                }
            }
        case 'SET_CLASSES_LOADING':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    classes: {
                        ...state.tabs.classes,
                        loading: action.loading
                    }
                }
            }
        case 'SET_TEMPLATES_LOADING':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    templates: {
                        ...state.tabs.templates,
                        loading: action.loading
                    }
                }
            }
        case 'SET_CERTIFICATES_LOADING':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    certificates: {
                        ...state.tabs.certificates,
                        loading: action.loading
                    }
                }
            }
        case 'LOADING_CLASSES_PDF':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [action.tab]: {
                        ...state.tabs[action.tab as keyof Tabs],
                        pdf: {
                            ...state.tabs[action.tab as keyof Tabs].pdf,
                            generating: action.loading
                        }
                    }
                }
            }
        case 'GENERATE_CLASSES_PDF':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [action.tab]: {
                        ...state.tabs[action.tab as keyof Tabs],
                        pdf: {
                            generating: false,
                            ready: true,
                            url: '',
                            data: action.data
                        }
                    }
                }
            }
        case 'SET_CLASSES_URL':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    [action.tab]: {
                        ...state.tabs[action.tab as keyof Tabs],
                        pdf: {
                            ...state.tabs[action.tab as keyof Tabs].pdf,
                            url: action.url
                        }
                    }
                }
            }
        case 'RESET_CLASS_FILTERS':
            var filters:any = {
                classStatus: 'active',
                gender: 'B',
                facility: 'my',
                instructor: null,
                program: null,
                funding: null,
                activeOn: null,
                className: '',
                classID: '',
                days: []
            }
            return {
                ...state,
                filters: filters,
                activeFilters: []
            }
        case 'SET_CLASS_FILTER':
            var exists = _.any(state.activeFilters, (filter:string) => filter == action.name)
            var removed = _.filter(state.activeFilters, (filter:string) => filter != action.name)
            var pushed = state.activeFilters.concat(action.name)
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.name]: action.value
                },
                activeFilters: defaultFilters[action.name] == action.value ? removed : (exists ? state.activeFilters : pushed)
            }
        case 'CLEAR_WAITLIST_INMATES':
            return {
                ...state,
                modals: {
                    waitlist: {
                        ...state.modals.waitlist,
                        search: []
                    }
                }
            }
        case 'ADD_CLASSES_WAITLIST_INMATE':
            return {
                ...state,
                modals: {
                    waitlist: {
                        ...state.modals.waitlist,
                        open: true,
                        loading: false,
                        id: 0,
                        value: '',
                        search: []
                    }
                }
            }
        case 'SELECT_CLASSES_WAITLIST_INMATE':
            return {
                ...state,
                modals: {
                    waitlist: {
                        ...state.modals.waitlist,
                        id: action.key,
                        value: action.value
                    }
                }
            }
        case 'SEARCH_TEMPLATE_WAITLIST_LOADING':
            return {
                ...state,
                modals: {
                    waitlist: {
                        ...state.modals.waitlist,
                        loading: true,
                        value: action.value
                    }
                }
            }
        case 'SEARCH_TEMPLATE_WAITLIST_INMATES':
            return {
                ...state,
                modals: {
                    waitlist: {
                        ...state.modals.waitlist,
                        loading: false,
                        search: action.inmates
                    }
                }
            }
        case 'FETCH_WAITLIST':
            return {
                ...state,
                modals: {
                    waitlist: {
                        ...state.modals.waitlist,
                        open: true,
                        loading: false,
                        id: 0,
                        value: '',
                        template: action.recordID,
                        inmates: action.inmates
                    }
                }
            }
        case 'FETCH_CLASSES':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    classes: {
                        loading: false,
                        offset: action.offset,
                        hasMore: action.hasMore,
                        count: action.count,
                        data: action.courses,
                        pdf: {
                            generating: false,
                            ready: false,
                            url: '',
                            data: {}
                        }
                    }
                },
                options: {
                    facilities: action.facilities,
                    instructors: action.instructors,
                    programs: action.programs,
                    days: action.days,
                    genders: action.genders,
                    funding: _.filter(action.funding, (opt:any) => opt.key != null)
                }
            }
        case 'FETCH_TEMPLATES':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    templates: {
                        loading: false,
                        offset: action.offset,
                        hasMore: action.hasMore,
                        count: action.count,
                        data: action.courses,
                        pdf: {
                            generating: false,
                            ready: false,
                            url: '',
                            data: {}
                        }
                    }
                },
                options: {
                    facilities: action.facilities,
                    instructors: action.instructors,
                    programs: action.programs,
                    days: action.days,
                    genders: action.genders,
                    funding: _.filter(action.funding, (opt:any) => opt.key != null)
                }
            }
        case 'FETCH_CERTIFICATES':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    certificates: {
                        loading: false,
                        offset: action.offset,
                        hasMore: action.hasMore,
                        count: action.count,
                        data: action.courses,
                        pdf: {
                            generating: false,
                            ready: false,
                            url: '',
                            data: {}
                        }
                    }
                },
                options: {
                    facilities: action.facilities,
                    instructors: action.instructors,
                    programs: action.programs,
                    days: action.days,
                    genders: action.genders,
                    funding: _.filter(action.funding, (opt:any) => opt.key != null)
                }
            }
        case 'FETCH_NEXT_CLASSES':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    classes: {
                        ...state.tabs.classes,
                        loading: false,
                        offset: action.offset,
                        hasMore: action.hasMore,
                        data: state.tabs.classes.data.concat(action.courses)
                    }
                }
            }
        case 'FETCH_NEXT_TEMPLATES':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    templates: {
                        ...state.tabs.templates,
                        loading: false,
                        offset: action.offset,
                        hasMore: action.hasMore,
                        data: state.tabs.templates.data.concat(action.courses)
                    }
                }
            }
        case 'FETCH_NEXT_CERTIFICATES':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    certificates: {
                        ...state.tabs.certificates,
                        loading: false,
                        offset: action.offset,
                        hasMore: action.hasMore,
                        data: state.tabs.certificates.data.concat(action.courses)
                    }
                }
            }
        case 'FETCH_CLASSES_DATA':
            return {
                ...state,
                data: {
                    instructors: action.instructors,
                    programs: action.programs,
                    funding: _.filter(action.funding, (opt:any) => opt.key != null),
                    archivedInstructors: action.archivedInstructors,
                    archivedPrograms: action.archivedPrograms,
                    archivedFunding: action.archivedFunding
                }
            }
        case 'LOAD_CLASS_FILTERS':
            return {
                ...state,
                open: action.filters['showFilters'] != undefined ? action.filters['showFilters'] : state.open,
                filters: action.filters,
                activeFilters: action.activeFilters
            }
        case 'FAVORITE_CLASS':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    classes: {
                        ...state.tabs.classes,
                        data: _.map(state.tabs.classes.data, (course:any) => _.clone(Object.assign(course, { classes: _.map(course.classes, (klass:any) => klass.recordID == action.recordID ? _.clone(Object.assign(klass, { favorite: true })) : klass) })))
                    }
                }
            }
        case 'UNFAVORITE_CLASS':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    classes: {
                        ...state.tabs.classes,
                        data: _.map(state.tabs.classes.data, (course:any) => _.clone(Object.assign(course, { classes: _.map(course.classes, (klass:any) => klass.recordID == action.recordID ? _.clone(Object.assign(klass, { favorite: false })) : klass) })))
                    }
                }
            }
        case 'FAVORITE_TEMPLATE':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    templates: {
                        ...state.tabs.templates,
                        data: _.map(state.tabs.templates.data, (course:any) => _.clone(Object.assign(course, { templates: _.map(course.templates, (template:any) => template.recordID == action.recordID ? _.clone(Object.assign(template, { favorite: true })) : template) })))
                    }
                }
            }
        case 'UNFAVORITE_TEMPLATE':
            return {
                ...state,
                tabs: {
                    ...state.tabs,
                    templates: {
                        ...state.tabs.templates,
                        data: _.map(state.tabs.templates.data, (course:any) => _.clone(Object.assign(course, { templates: _.map(course.templates, (template:any) => template.recordID == action.recordID ? _.clone(Object.assign(template, { favorite: false })) : template) })))
                    }
                }
            }
        case 'CLASS_WAITLIST_FACILITY_TOGGLE':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    waitlist: {
                        ...state.modals.waitlist,
                        all: action.toggle
                    }
                }
            }
        default:
            return state
    }
}