import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import UATable from '../inmates/UATable'
import {
  Icon,
  Header,
  Button,
  Form,
  Select
} from 'semantic-ui-react'
const _ = require('underscore')

type DrawerProps =
    { staff: any } &
    DrawerStore.DrawerState &
    InmatesStore.InmatesState &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    WithRouterProps<{}>

class UAs extends React.PureComponent<DrawerProps> {
    public componentDidMount() {
        this.props.fetchUAs()
    }

    public render() {
      const activeFilters = () => {
          return !_.isEqual(_.pick(this.props.filters, (v:any,k:string) => k != 'showFilters'), { facility: (this.props.staff.facilities.includes('PSD') || this.props.staff.facilities.includes('HPA')) ? 'all' : 'my', searchType: 'starts', gender: 'B', active: true, firstName: '', lastName: '', sid: '', dobFrom: null, dobTo: null, education: [], tabeLevel: 'all', gedFilter: 'all', hisetFilter: 'all' })
      }

      return (
        <Fragment>
          <Form style={{position:'absolute',top:10,right:80}}>
            <Form.Group>
              <Button size='small' color={this.props.tab == 'inmates' ? 'blue' : undefined} content='Inmates' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('inmates')} />
              <Button size='small' color={this.props.tab == 'ands' ? 'blue' : undefined} content='A&Ds' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('ands')} />
              <Button size='small' color={this.props.tab == 'uas' ? 'blue' : undefined} content='UAs' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('uas')} />
              <Button circular icon='filter' color={activeFilters() ? 'red' : 'blue'} onClick={this.props.toggleFilters} style={{fontSize:'1.5em',marginLeft:30}} />
            </Form.Group>
          </Form>
          <Button circular icon='arrow left' onClick={() => this.props.toggleDrawer(this.props.tab)} style={{position:'absolute',top:20,right:0}} />
          <Header as='h2' style={{display:'inline-block'}}>
            <Icon name='clipboard list' /> UAs
          </Header>
          <div style={{display:'inline-block',marginLeft:10,verticalAlign:'top',marginTop:32}}>[{this.props.tabs.ands.count} records]</div>
          <UATable />
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { ...state.drawer, ...state.inmates, staff: state.staff.staff } },
  { ...DrawerStore.actionCreators, ...InmatesStore.actionCreators }
)(UAs as any)