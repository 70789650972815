import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import TemplateInfo from './TemplateInfo'
import TemplateOptions from './TemplateOptions'
import TemplateTitles from './TemplateTitles'
import TemplateWaitlist from './TemplateWaitlist'
import TemplateCertificates from './TemplateCertificates'
import {
  Container,
  Icon,
  Header,
  Grid,
  Divider,
  Button
} from 'semantic-ui-react'
const _ = require('underscore')

type PaneProps =
    { template: PanesStore.Template, num: 1 | 2, print: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class TemplatePane extends React.PureComponent<PaneProps> {
    public render() {
        return (
          <Container fluid>
            {this.props.print ? null : <div className='close' style={{position:'absolute',top:0,left:0,zIndex:1}}>
              <Button circular color='red' icon='close' size='mini' onClick={() => this.props.togglePane(this.props.num)} />
            </div>}
            <Button color='blue' circular icon='print' onClick={() => this.props.togglePrint(this.props.num)} style={{float:'right',fontSize:'1.2em',marginTop:15}} />
            {this.props.print ? null : this.props.template.favorite ? <Icon className='favorite' name='star' color='blue' onClick={() => this.props.unfavoriteTemplate(this.props.num)} style={{float:'right',fontSize:'2em',marginTop:27,marginRight:18}} /> : <Icon className='favorite' name='star outline' color='blue' onClick={() => this.props.favoriteTemplate(this.props.num)} style={{float:'right',fontSize:'2em',marginTop:27,marginRight:18}} />}
            <Grid style={{marginBottom:10}}>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column>
                  <Header as='h2' style={{display:'inline-block',marginTop:0,width:750,whiteSpace:'normal'}}><div><Icon name='copy' /> {this.props.template.data.className} <span style={{fontSize:'0.8em',color:'#ccc',marginLeft:10,verticalAlign:'middle'}}>{this.props.template.data.recordID}</span></div></Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <TemplateInfo data={this.props.template.info} print={this.props.print} options={this.props.template.options} modals={this.props.template.modals} num={this.props.num} />
            <Divider />
            <Header as='h3' style={{marginTop:10}}>Default Class Options</Header>
            <TemplateOptions data={this.props.template.info} print={this.props.print} options={this.props.template.options} num={this.props.num} />
            <Divider />
            <Header as='h3' style={{marginTop:10}}>Waitlist</Header>
            <TemplateWaitlist data={this.props.template.info} print={this.props.print} waitlist={this.props.template.waitlist} options={this.props.template.options} num={this.props.num} />
            <Divider />
            <Header as='h3' style={{marginTop:10}}>Titles</Header>
            <TemplateTitles data={this.props.template.info} print={this.props.print} titles={this.props.template.titles} options={this.props.template.options} num={this.props.num} />
            <Header as='h3' style={{marginTop:20,display:'inline-block'}}>Certificates</Header>
            <Button positive circular icon='plus' onClick={_.debounce(() => this.props.openNewCertificateModal(this.props.num), 10000, true)} style={{marginLeft:10}} />
            <TemplateCertificates data={this.props.template.info} print={this.props.print} certificates={this.props.template.certificates} options={this.props.template.options} modal={this.props.template.modals.certificate} num={this.props.num} />
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { template: ownProps.template, num: ownProps.num, print: ownProps.options.print } },
  PanesStore.actionCreators
)(TemplatePane as any)