import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import * as PanesStore from '../../store/reducers/panes'
import InmateFilters from './InmateFilters'
import TABERow from './TABERow'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Popup,
  Icon,
  Table,
  Dimmer,
  Loader
} from 'semantic-ui-react'
const _ = require('underscore')

type TableProps =
    { open: boolean, loading: boolean, hasMore: boolean, tab: string, sorts: any, filters: InmatesStore.InmateFilters, inmates: InmatesStore.Inmate[] } &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class TABETable extends React.PureComponent<TableProps> {
    private HeaderArrow = (props:any) => {
      var sort = _.find(this.props.sorts, (sort:any) => sort.column == props.orderBy)
      if (sort != null) {
        if (sort.dir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    public render() {
      const HeaderArrow = this.HeaderArrow
      return (
        <Fragment>
          <InmateFilters />
          <div id='tabe-table' style={{top:this.props.open?200:70}}>
            <Dimmer inverted active={this.props.loading}><Loader inverted content="Loading TABEs ..." /></Dimmer>
            <InfiniteScroll
                pageStart={0}
                loadMore={this.props.fetchNextTABE}
                hasMore={!this.props.loading && this.props.hasMore}
                initialLoad={false}
                useWindow={false}
                getScrollParent={() => document.getElementById('tabe-table') }
            >
              <Table compact celled striped style={{borderTop:0,marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }}><div style={{whiteSpace:'nowrap',height:'1em'}}><i className="icon icon-t" /></div></Table.HeaderCell>
                    <Popup content="Click to sort inmates by name" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('name')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Inmate Name<HeaderArrow orderBy='name' /></div></Table.HeaderCell>} />
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>SID</div></Table.HeaderCell>
                    <Popup content="Click to sort TABEs by facility" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('facility')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Facility<HeaderArrow orderBy='facility' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by date" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('date')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Test Date<HeaderArrow orderBy='date' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReLF" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('relf')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>ReLF<HeaderArrow orderBy='relf' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReSS" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('ress')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>ReSS<HeaderArrow orderBy='ress' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReGE" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('rege')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>ReGE<HeaderArrow orderBy='rege' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReNRS" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('renrs')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>ReNRS<HeaderArrow orderBy='renrs' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReLF" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('tmlf')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>TmLF<HeaderArrow orderBy='tmlf' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReSS" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('tmss')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>TmSS<HeaderArrow orderBy='tmss' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReGE" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('tmge')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>TmGE<HeaderArrow orderBy='tmge' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReNRS" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('tmnrs')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>TmNRS<HeaderArrow orderBy='tmnrs' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReLF" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('lalf')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>LaLF<HeaderArrow orderBy='lalf' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReSS" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('lass')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>LaSS<HeaderArrow orderBy='lass' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReGE" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('lage')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>LaGE<HeaderArrow orderBy='lage' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort TABEs by ReNRS" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('lanrs')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>LaNRS<HeaderArrow orderBy='lanrs' /></div></Table.HeaderCell>} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.inmates.length > 0 && (this.props.inmates[0] as any).tabes && (this.props.inmates[0] as any).tabes.length > 0 ? this.props.inmates.map((inmate:any, i:number) => inmate.tabes.map((test:any, t:number) => (
                    <TABERow inmate={inmate} test={test} key={t} i={i} t={t} />
                  ))) : this.props.inmates.map((tabe:any, t:number) => (
                    <TABERow inmate={tabe} test={tabe} key={t} i={t} t={0} />
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </div>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { open: state.inmates.open, loading: state.inmates.tabs.tabe.loading, hasMore: state.inmates.tabs.tabe.hasMore, filters: state.inmates.filters, sorts: state.inmates.sorts, inmates: state.inmates.tabs.tabe.data, tab: state.drawer.tab } },
  { ...DrawerStore.actionCreators, ...InmatesStore.actionCreators, ...PanesStore.actionCreators }
)(TABETable as any)