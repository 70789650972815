import React, { Fragment } from 'react'

type ReportProps =
    { report: any, options: any, settings: any }

class Footer extends React.PureComponent<ReportProps> {
    public render() {
        return (<div style={{fontSize:'0.7em',fontFamily:'Roboto',color:'grey',display:'flex',justifyContent:'space-between',alignItems:'flex-end',marginBottom:20,marginLeft:30,marginRight:30}}>
            <div style={{verticalAlign:'bottom',marginLeft:10}}>
                <div>{new Date().today() + " @ " + new Date().timeNow()}</div>
            </div>
            <div style={{textAlign:'center'}}>
                <div>{this.props.report.copyright}</div>
            </div>
            <div style={{verticalAlign:'bottom',marginRight:10}}>
                <div>{this.props.report.data.staffID} / {this.props.report.data.printID}</div>
            </div>
        </div>)
    }
}

export default Footer
