import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store'
import * as ReportsStore from '../../store/reducers/reports'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import { Editor } from 'medium-draft'
import mediumDraftExporter from 'medium-draft/lib/exporter';
import {
  Header,
  Form,
  Button,
  Select,
  Input,
  Divider
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { admin: boolean } &
    ReportsStore.ReportsState &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class Graduated extends React.PureComponent<ReportProps> {
    public params() {
      let params = new URLSearchParams()
      for (let [key, value] of Object.entries(this.props.reports.graduated.data)) {
        if (value != undefined && value != null && (typeof value == 'string' ? value != '' : true) && key != 'reportTypes') {
            if (typeof value == 'object') {
                for (let ovalue of Object.values(value as any)) {
                  params.append(key, ovalue as string)
                }
            } else {
                params.append(key, (value as any).toString())
            }
        }
      }
      return params.toString()
    }

    public render() {
      return (
        <Fragment>
          <Form>
            <Form.Field
              control={Select}
              label='Report type'
              placeholder='Report type'
              options={this.props.reports.graduated.data.reportTypes}
              value={this.props.reports.graduated.data.reportType}
              onChange={(e:any, data:any) => this.props.dataChange('graduated', 'reportType', data.value)}
              width={4}
            />
          </Form>
          <Header as="h2">Graduated Report &#8212; {_.find(this.props.reports[this.props.report].data.reportTypes, (type:any) => type.value == this.props.reports[this.props.report].data.reportType).text}</Header>
          {this.props.admin ? <Fragment>
            <div style={{border:'thin solid #ddd',padding:1,borderRadius:5,marginBottom:12}}>
              <Editor placeholder="Enter a report description..." editorState={this.props.reports.graduated.description} sideButtons={[]} onChange={(state:any) => this.props.updateDescription(state)} />
            </div>
            <Button positive content='Save' onClick={this.props.saveDescription} />
          </Fragment> : null}
          {this.props.admin ? null : <div style={{whiteSpace:'normal'}} dangerouslySetInnerHTML={{__html: mediumDraftExporter(this.props.reports.graduated.description.getCurrentContent())}} />}
          <Divider />
          <Form>
            <Form.Group>
              <Form.Field
                control={DatePicker}
                label="Beginning date"
                name="startDate"
                placeholder="MM/DD/YYYY"
                selected={this.props.reports.graduated.data.startDate ? new Date(this.props.reports.graduated.data.startDate) : null}
                onChange={(date:any) => this.props.dataChange('graduated', 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                width={4}
              />
              <Form.Field
                control={DatePicker}
                label="Ending date"
                name="endDate"
                placeholder="MM/DD/YYYY"
                selected={this.props.reports.graduated.data.endDate ? new Date(this.props.reports.graduated.data.endDate) : null}
                onChange={(date:any) => this.props.dataChange('graduated', 'endDate', date ? format(date, 'MM/dd/yyyy') : null)}
                width={4}
              />
              <Form.Field
                control={Select}
                label='Display type'
                placeholder='Display type'
                options={[{key:0,value:'html',text:'HTML'},{key:1,value:'pdf',text:'PDF'}]}
                value={this.props.reports.graduated.data.displayType}
                onChange={(e:any, data:any) => this.props.dataChange('graduated', 'displayType', data.value)}
                width={4}
              />
              {/*this.props.reports.graduated.data.displayType == 'pdf' ? <Form.Field
                control={Select}
                label='Orientation'
                placeholder='Orientation'
                options={[{key:0,value:'landscape',text:'Landscape'},{key:1,value:'portrait',text:'Portrait'}]}
                value={this.props.reports.graduated.data.orientation}
                onChange={(e:any, data:any) => this.props.dataChange('graduated', 'orientation', data.value)}
                width={4}
              /> : null*/}
            </Form.Group>
            <Form.Group>
              <Button color="teal" name="LastFY" content="Last FY" size="mini" onClick={this.props.lastFY} style={{marginLeft:7}} />
              <Button color="teal" name="LastCY" content="Last CY" size="mini" onClick={this.props.lastCY} />
              <Button color="teal" name="LastQTR" content="Last QTR" size="mini" onClick={this.props.lastQTR} />
              <Button color="teal" name="LastHalf" content="Last Half" size="mini" onClick={this.props.lastHalf} />
              <Button color="teal" name="ThisFY" content="This FY" size="mini" onClick={this.props.thisFY} />
              <Button color="teal" name="ThisCY" content="This CY" size="mini" onClick={this.props.thisCY} />
              <Button color="teal" name="LastMonth" content="Last Month" size="mini" onClick={this.props.lastMonth} />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Sort by'
                placeholder='Sort by'
                options={[{key:0,value:'name',text:'Inmate name'},{key:1,value:'date',text:'Grad date'}]}
                value={this.props.reports.graduated.data.sortBy}
                onChange={(e:any, data:any) => this.props.dataChange('graduated', 'sortBy', data.value)}
                width={3}
              />
              <Form.Field
                control={Select}
                label='Test type'
                options={[{key:0,value:'E',text:'HSE'},{key:1,value:'G',text:'GED'},{key:2,value:'H',text:'HiSET'},{key:3,value:'C',text:'CBCSDP'},{key:4,value:'D',text:'Diploma'},{key:5,value:'A',text:'Any'}]}
                value={this.props.reports.graduated.data.gradType}
                onChange={(e:any, data:any) => this.props.dataChange('graduated', 'gradType', data.value)}
                width={3}
              />
              <Form.Field
                control={Select}
                label='Inmates without tests'
                options={[{key:0,value:false,text:'No'},{key:1,value:true,text:'Yes'}]}
                value={this.props.reports.graduated.data.graduated}
                onChange={(e:any, data:any) => this.props.dataChange('graduated', 'graduated', data.value)}
                width={3}
              />
              <Form.Field
                control={Select}
                label='Facilities'
                placeholder='Facilities'
                options={[{key:0,value:0,text:'All facilities'}].concat(this.props.reports.graduated.facilities)}
                value={this.props.reports.graduated.data.facilities}
                onChange={(e:any, data:any) => this.props.dataChange('graduated', 'facilities', data.value)}
                multiple
                width={7}
              />
            </Form.Group>
            {this.props.reports.graduated.data.displayType == 'html' ? <Button color='blue' content='Generate report' as={Link} to={`/reports/graduated/html?${this.params()}`} target='_window' /> : null}
            {this.props.reports.graduated.data.displayType != 'html' ? <Button color='blue' content='Generate report' onClick={this.props.generateGraduated} /> : null}
          </Form>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { ...state.reports, admin: state.staff.staff!.admin } },
  ReportsStore.actionCreators
)(Graduated as any)
