import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Table,
  Popup,
  Label,
  Icon,
  Divider,
  Header
} from 'semantic-ui-react'
const _ = require('underscore')

type CompletionProps =
    { class: PanesStore.Class, num: 1 | 2, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassCompletionsTranscripts extends React.PureComponent<CompletionProps> {
    public componentDidMount() {
      this.props.fetchCompletions(this.props.num, this.props.class.info.recordID)
    }

    public render() {
        const transcripts = this.props.class.transcripts == undefined ? [] : this.props.class.transcripts
        return (transcripts.length > 0 ? <Container fluid>
            <Table compact celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell width={1}>Transcript ID</Table.HeaderCell>
                  <Table.HeaderCell width={6}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={1}>SID</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Start Date</Table.HeaderCell>
                  <Table.HeaderCell width={1}>End Date</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Sessions</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Hours</Table.HeaderCell>
                  <Table.HeaderCell>Grade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {transcripts.map((transcript:any, i:number) => (
                  <Table.Row key={transcript.recordID}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>
                      {this.props.mode == 'dual' ? <Popup
                        hideOnScroll
                        on='click'
                        trigger={<div className='link' style={{whiteSpace:'normal'}}>{transcript.recordID}</div>}
                      >
                        <Popup.Header>Open in pane</Popup.Header>
                        <Popup.Content>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmateAndEditTranscript(1, transcript.inmateID, transcript.recordID) }}>1</Label>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmateAndEditTranscript(2, transcript.inmateID, transcript.recordID) }}>2</Label>
                        </Popup.Content>
                      </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.fetchInmateAndEditTranscript(this.props.mode == 'left' ? 1 : 2, transcript.inmateID, transcript.recordID) }}>{transcript.recordID}</div>}
                    </Table.Cell>
                    <Table.Cell>
                      {this.props.mode == 'dual' ? <Popup
                        hideOnScroll
                        on='click'
                        trigger={<div className='link' style={{whiteSpace:'normal'}}>{`${transcript.lastName}, ${transcript.firstName}`}{transcript.notes.length > 0 ? <Popup on='hover' position='right center' content={transcript.notes[transcript.notes.length-1].content} trigger={<Icon name='talk' color='blue' style={{marginLeft:5}} />} /> : null}{transcript.certName ? <Popup on='hover' position='right center' content={transcript.certName + (transcript.certNotes ? ` | ${transcript.certNotes}` : '')} trigger={<Icon name='certificate' color='blue' style={{marginLeft:5}} />} /> : null}</div>}
                      >
                        <Popup.Header>Open in pane</Popup.Header>
                        <Popup.Content>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, transcript.inmateID) }}>1</Label>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, transcript.inmateID) }}>2</Label>
                        </Popup.Content>
                      </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, transcript.inmateID) }}>{`${transcript.lastName}, ${transcript.firstName}`}{transcript.notes.length > 0 ? <Popup on='hover' position='right center' content={transcript.notes[transcript.notes.length-1].content} trigger={<Icon name='talk' color='blue' style={{marginLeft:5}} />} /> : null}{transcript.certName ? <Popup on='hover' position='right center' content={transcript.certName + (transcript.certNotes ? ` | ${transcript.certNotes}` : '')} trigger={<Icon name='certificate' color='blue' style={{marginLeft:5}} />} /> : null}</div>}
                    </Table.Cell>
                    <Table.Cell>{transcript.sid}</Table.Cell>
                    <Table.Cell>{transcript.dateStart}</Table.Cell>
                    <Table.Cell>{transcript.dateEnd}</Table.Cell>
                    <Table.Cell>{transcript.aCount}</Table.Cell>
                    <Table.Cell>{transcript.aTime}</Table.Cell>
                    <Table.Cell>{transcript.grade}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Fragment>
              <Divider />
              <Header as='h3'>Grade Legend</Header>
              <Table compact celled striped style={{marginBottom:20}}>
                <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Grade</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.filter(this.props.class.options.grades, (g:any) => _.any(this.props.class.transcripts, (transcript:any) => transcript.grade == g.value)).map((g:any, i:number) => (
                    <Table.Row key={i}>
                      <Table.Cell>{g.value}</Table.Cell>
                      <Table.Cell>{g.text}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Fragment>
          </Container> : null
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, mode: state.panes.mode } },
  PanesStore.actionCreators
)(ClassCompletionsTranscripts as any)