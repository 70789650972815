import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format, getDay } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Search,
  Checkbox,
  Button,
  Select,
  Modal,
  Popup,
  Divider,
  Accordion,
  Table,
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { class: PanesStore.Class, num: 1 | 2, staff: any } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassSyncRosters extends React.PureComponent<RosterProps> {
    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public render() {
        const days = [{ key: 0, value: 0, text: 'Sunday' }, { key: 1, value: 1, text: 'Monday' }, { key: 2, value: 2, text: 'Tuesday' }, { key: 3, value: 3, text: 'Wednesday' }, { key: 4, value: 4, text: 'Thursday' }, { key: 5, value: 5, text: 'Friday' }, { key: 6, value: 6, text: 'Saturday' }]
        const sync = this.props.class.modals.sync
        const info = this.props.class.info
        const schedules = this.props.class.modals.sync.data.schedules
        const rosters = this.props.class.modals.sync.data.rosters
        return (
          <Container fluid style={{marginTop:20}}>
            <Form>
              <div style={{display:'inline-block',marginTop:8}}>
                <label><b>Global Start Date:</b></label>
              </div>
              <div style={{display:'inline-block',verticalAlign:'top',marginLeft:10,width:120}}>
                <DatePicker selected={this.props.class.modals.sync.data.globalStart ? new Date(this.props.class.modals.sync.data.globalStart) : null} onChange={(date:any) => this.props.syncRosterStartChanges(this.props.num, date ? format(date, 'MM/dd/yyyy') : null)} filterDate={(date:any) => true} />
              </div>
              <div style={{display:'inline-block',marginTop:8,marginLeft:20}}>
                <label><b>Global Schedule Date:</b></label>
              </div>
              <div style={{display:'inline-block',verticalAlign:'top',marginLeft:10,width:120}}>
                <DatePicker selected={this.props.class.modals.sync.data.globalSchedule ? new Date(this.props.class.modals.sync.data.globalSchedule) : null} onChange={(date:any) => this.props.syncRosterScheduleChanges(this.props.num, date ? format(date, 'MM/dd/yyyy') : null)} filterDate={(date:any) => true} />
              </div>
            </Form>
            <Accordion styled style={{width:'100%',marginTop:20}}>
              {_.filter(rosters, (roster:any) => sync.data.scheduleID ? _.any(roster.scheduleData, (s:any) => s.recordID == sync.data.scheduleID) : true).map((roster:any) => (
                <Fragment key={roster.recordID}>
                  <Accordion.Title active={roster.selected} onClick={() => this.props.selectRoster(this.props.num, roster.recordID)}>{roster.lastName}, {roster.firstName}</Accordion.Title>
                  <Accordion.Content active={roster.selected}>
                    {roster.selected ? <Fragment>
                      <Form>
                        <div style={{display:'inline-block',marginTop:8}}>
                          <label><b>Start Date:</b></label>
                        </div>
                        <div style={{display:'inline-block',verticalAlign:'top',marginLeft:10}}>
                          <DatePicker selected={new Date(roster.dateStart)} onChange={(date:any) => this.props.syncRosterStartChange(this.props.num, roster.recordID, 'dateStart', date ? format(date, 'MM/dd/yyyy') : null)} filterDate={(date:any) => true} />
                        </div>
                      </Form>
                      {schedules.length == 0 ? <div style={{marginLeft:6}}>No class meeting times set</div> : <Fragment>
                        <Table compact celled striped>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>Day</Table.HeaderCell>
                              <Table.HeaderCell>Start</Table.HeaderCell>
                              <Table.HeaderCell>End</Table.HeaderCell>
                              <Table.HeaderCell>Start Date</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {_.filter(schedules, (schedule:any) => _.any(roster.scheduleData, (data:any) => data.recordID == schedule.recordID)).map((schedule:any, i:number) => {
                              var existing = _.find(roster.scheduleData, (s:any) => s.recordID == schedule.recordID)
                              return (
                                <Fragment key={schedule.recordID}>
                                  <Table.Row>
                                    <Table.Cell>{`${this.dayOfWeek3(schedule.dayOfWeek)}`}</Table.Cell>
                                    <Table.Cell>{`${schedule.startTime}`}</Table.Cell>
                                    <Table.Cell>{`${schedule.endTime}`}</Table.Cell>
                                    <Table.Cell>
                                      <Form>
                                        <DatePicker selected={existing.startDate ? new Date(existing.startDate) : null} onChange={(date:any) => this.props.syncRosterScheduleChange(this.props.num, roster.recordID, schedule.recordID, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)} filterDate={(date:any) => getDay(date) == schedule.dayOfWeek && date >= new Date(roster.dateStart) && date >= new Date(schedule.startDate) && date <= (roster.dateEnd ? new Date(roster.dateEnd) : new Date(info.dateEnds))} />
                                      </Form>
                                    </Table.Cell>
                                  </Table.Row>
                                  {schedule.grouped.map((schedule:any, i:number) => {
                                    var existingG = _.find(roster.scheduleData, (s:any) => s.recordID == schedule.recordID)
                                    return (
                                      <Table.Row key={schedule.recordID}>
                                        <Table.Cell><Checkbox checked={_.any(roster.scheduleData, (s:any) => s.recordID == schedule.recordID)} onClick={() => this.props.toggleRosterSchedule(this.props.num, schedule)} /></Table.Cell>
                                        <Table.Cell>{`${this.dayOfWeek3(schedule.dayOfWeek)}`}</Table.Cell>
                                        <Table.Cell>{`${schedule.startTime}`}</Table.Cell>
                                        <Table.Cell>{`${schedule.endTime}`}</Table.Cell>
                                        <Table.Cell>
                                          <Form>
                                            <DatePicker selected={existingG.startDate ? new Date(existingG.startDate) : null} onChange={(date:any) => this.props.syncRosterScheduleChange(this.props.num, roster.recordID, schedule.recordID, 'startDate', date ? format(date, 'MM/dd/yyyy') : null)} filterDate={(date:any) => getDay(date) == schedule.dayOfWeek && date >= new Date(roster.dateStart) && date >= new Date(schedule.startDate) && date <= (roster.dateEnd ? new Date(roster.dateEnd) : new Date(info.dateEnds))} />
                                          </Form>
                                        </Table.Cell>
                                      </Table.Row>
                                    )
                                  })}
                                </Fragment>
                              )
                            })}
                          </Table.Body>
                        </Table>
                      </Fragment>}
                    </Fragment> : null}
                  </Accordion.Content>
                </Fragment>
              ))}
            </Accordion>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, staff: state.staff.staff } },
  PanesStore.actionCreators
)(ClassSyncRosters as any)