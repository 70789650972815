import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import DashboardDrawer from '../Dashboard'
import {
  Icon,
  Header,
  Button
} from 'semantic-ui-react'

type DrawerProps =
    DrawerStore.DrawerState &
    typeof DrawerStore.actionCreators &
    WithRouterProps<{}>

class Dashboard extends React.PureComponent<DrawerProps> {
    public render() {
      return (
        <Fragment>
          <Button circular icon='arrow left' onClick={() => this.props.toggleDrawer(this.props.tab)} style={{position:'absolute',top:20,right:20}} />
          <Header as='h2' style={{display:'inline-block'}}>
            <Icon name='dashboard' /> Dashboard
          </Header>
          <DashboardDrawer />
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => state.drawer,
  DrawerStore.actionCreators
)(Dashboard as any)