import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as StaffStore from '../../store/reducers/staff'
import {
  Icon,
  Header,
  Button,
  Form,
  Select
} from 'semantic-ui-react'

type DrawerProps =
    DrawerStore.DrawerState &
    StaffStore.StaffState &
    typeof DrawerStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class Profile extends React.PureComponent<DrawerProps> {
    public componentDidMount() {
      this.props.fetchThemes()
    }

    public render() {
      return (
        <Fragment>
          <Button circular icon='arrow left' onClick={() => this.props.toggleDrawer(this.props.tab)} style={{position:'absolute',top:20,right:20}} />
          <Header as='h2' style={{display:'inline-block'}}>
            <Icon name='user' /> Profile
          </Header>
          <Form>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Theme'
                placeholder='Default'
                options={[{key:0,value:null,text:'Default'}].concat(this.props.options.themes)}
                value={this.props.profile.themeID}
                onChange={(e:any, data:any) => this.props.updateProfile('themeID', data.value as number)}
                width={4}
              />
            </Form.Group>
            <Button positive content="Save" onClick={this.props.saveProfile} />
          </Form>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => state.staff,
  { ...DrawerStore.actionCreators, ...StaffStore.actionCreators }
)(Profile as any)