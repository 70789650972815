import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Page,
  View,
  BlobProvider,
  Document,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {
  Dimmer,
  Loader,
  Icon
} from 'semantic-ui-react'
import { format } from "date-fns";
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { pdf: any, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class F8400PDF extends React.PureComponent<ReportProps> {
    public render() {
      return (
        <Fragment>
          {this.props.pdf.generating ? <div className='pdf generating' style={{display:'inline-block',verticalAlign:'top',marginLeft:10}}>
            <Icon.Group>
              <Icon name='circle notch' color='grey' loading style={{fontSize:'2.5em'}} />
              <Icon name='file pdf outline' color='grey' style={{fontSize:'1.4em',marginTop:1,marginLeft:-1}} />
            </Icon.Group>
          </div> : null}
          {this.props.pdf.ready ? <BlobProvider document={this.doc()}>
            {(data:any) => {
              return (
                <a target='_window' href={data.url} style={{marginLeft:10}}>
                  <Icon name='file pdf outline' size='big' />
                </a>
              )
            }}
          </BlobProvider> : null}
        </Fragment>
      )
    }

    public doc() {
        const styles = StyleSheet.create({
          page: {
            flexDirection: "column"
          },
      
          image: {
            marginTop: 30,
            marginBottom: 20,
            width: "108pt",
            alignSelf: "center"
          },
      
          psd: {
            fontSize: 12,
            textAlign: "center",
            flexDirection: "column",
            marginBottom: 20
          },
      
          hpa_row: {
            flexDirection: "row"
          },
      
          hpa1: {
            marginLeft: 30,
            marginBottom: 30,
            fontSize: 12,
            flexDirection: "column"
          },
      
          hpa2: {
            marginLeft: 10,
            fontSize: 12,
            flexDirection: "column"
          },
      
          numeral: {
            marginLeft: 30,
            marginTop: 10,
            marginBottom: 10,
            fontSize: 12,
            flexDirection: "row"
          },
      
          check_row: {
            flexDirection: "row",
            marginBottom: 10
          },
      
          check_main: {
            textAlign: "center",
            marginLeft: 30,
            height: 10,
            width: 10
          },
      
          check: {
            marginLeft: 60,
            height: 10,
            width: 10
          },
      
          check_text: {
            marginLeft: 5,
            fontSize: 10,
            marginRight: 60
          },
      
          comments: {
            marginLeft: 30,
            fontSize: 12
          },
      
          footer: {
            fontSize: 6,
            alignSelf: "center",
            position: "absolute",
            bottom: 30
          },
          footerCenter: {
            position: 'absolute',
            textAlign: 'center',
            bottom: 10,
            fontSize: 8,
            color: 'grey'
          },
          footerLeft: {
            position: 'absolute',
            bottom: 10,
            left: 10,
            fontSize: 8,
            color: 'grey'
          },
          footerRight: {
            position: 'absolute',
            bottom: 10,
            right: 10,
            fontSize: 8,
            color: 'grey'
          }
        });

        let data = this.props.pdf.data

        const doc = (
          <Document>
            <Page size="LETTER">
              <Image style={styles.image} src="/assets/HawaiiSeal.png" />
              <View style={styles.psd}>
                <Text>STATE OF HAWAII</Text>
                <Text style={{ fontSize: 12, fontWeight: "bold", textTransform: 'uppercase' }}>
                  {this.props.settings.deptName}
                </Text>
                <Text>CPS-E Branch, Keoniana Building 5th Floor</Text>
                <Text>1177 Alakea St.</Text>
                <Text>Honolulu, HI 96813</Text>
              </View>
              <View style={styles.hpa_row}>
                <View style={styles.hpa1}>
                  <Text>TO:</Text>
                  <Text>THROUGH:</Text>
                  <Text>FROM:</Text>
                  <Text>SUBJECT:</Text>
                </View>
                <View style={styles.hpa2}>
                  <Text>Hawaii Paroling Authority</Text>
                  <Text>Corrections Program Services Division</Text>
                  <Text>Education Services Branch</Text>
                  <Text>PAROLE ELIGIBILITY OF {data.inmate.name}</Text>
                  {data.inmate.ssn ? <Text>SSN: ***-**-{data.inmate.ssn.slice(-4)}</Text> : <Text>No SSN on file</Text>}
                </View>
              </View>
              <View style={styles.numeral}>
                <Text>I.</Text>
                <Text style={{ textDecoration: "underline", marginLeft: 5 }}>
                  {" "}
                  SUITABILITY
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check_main}>
                  {this.props.options.suitable ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>

                <Text style={styles.check_text}>
                  The above named inmate is suitable for participation in:
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.academicProgram ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>Academic Program.</Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.vocationalProgram ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  Career and Technical Educational Training Program.
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.trainingindProgram ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  Training Industries Education Program.
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check_main}>
                  {this.props.options.notSuitable ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  The above named inmate is not suitable for participation in a
                  corrections education program determined by:
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.diploma ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  Possesses diploma/degree from an accredited institution.
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.skills ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  Possesses marketable skills and is deemed suitable for employment.
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.unable ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  Diagnosed by a licensed physician as unable to participate in
                  education programs.
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.f8400Other ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  Other: {this.props.options.f8400OtherWhat}
                </Text>
              </View>
              <View style={styles.numeral}>
                <Text>II.</Text>
                <Text style={{ textDecoration: "underline", marginLeft: 5 }}>
                  {" "}
                  PARTICIPATION
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.refused ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  Refused participation in any or all education programs.
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.waitListed ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  Has completed the education assessment and is on a waitlist for
                  program placement.
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.notEnrolled ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  Has completed the education assessment and is not enrolled in an
                  education program.
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.isEnrolled ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  Has completed the education assessment and is enrolled in an
                  education program.
                </Text>
              </View>
              <View style={styles.check_row}>
                <View style={styles.check}>
                  {this.props.options.completed ? (
                    <Image src="/assets/check-mark-icon.jpg"></Image>
                  ) : (
                    <Text></Text>
                  )}
                </View>
                <Text style={styles.check_text}>
                  Has satisfactorily completed an education program.*
                </Text>
              </View>
              <Text style={{ marginLeft: 30, fontSize: 10 }}>
                *Transcript attached
              </Text>
              <View style={styles.numeral}>
                <Text>III.</Text>
                <Text style={{ textDecoration: "underline", marginLeft: 5 }}>
                  {" "}
                  COMMENTS
                </Text>
              </View>
              <Text style={styles.comments}>{this.props.options.comments}</Text>
              <Text style={styles.footer} fixed>
                PSD 8400 (Revised 12/19)
              </Text>
              <View fixed style={styles.footerCenter}>
                <Text>{this.props.settings.copyright}</Text>
              </View>
              <View fixed style={styles.footerLeft}>
                <Text>{new Date().today() + " @ " + new Date().timeNow()}</Text>
              </View>
              <View fixed style={styles.footerRight}>
                <Text>{data.staffID} / {data.printID}</Text>
              </View>
            </Page>
          </Document>
        );
    
        return doc
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { pdf: ownProps.pdf, options: ownProps.options, settings: state.staff.settings } },
  ReportsStore.actionCreators
)(F8400PDF as any)
