import React, { Fragment } from 'react'
import {
  Table,
  Container
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class Recidivism extends React.PureComponent<ReportProps> {
    private totals(object:any) {
      let total = 0
      _.each(object, (value:any,key:any) => {
        if (value && value.total) { total += value.total }
      })
      return total
    }

    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let params = this.props.report.data
        let data = this.props.report.generated

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Recidivism Report</div><div style={{marginTop:4}}>{`${data.firstTransfer} through ${data.lastTransfer}`}</div></div>
            </div>
            <div>
              <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
                <div style={{fontWeight:700,fontSize:16}}>Students vs. Inmates</div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Students</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.students.student.total == 0 ? '0' : Math.round(data.globals.students.student.recidivism / data.globals.students.student.total * 100) > 100 ? '100' : Math.round(data.globals.students.student.recidivism / data.globals.students.student.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.students.student.recidivism.toFixed(0)} / {data.globals.students.student.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Non-Students</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.students.inmate.total == 0 ? '0' : Math.round(data.globals.students.inmate.recidivism / data.globals.students.inmate.total * 100) > 100 ? '100' : Math.round(data.globals.students.inmate.recidivism / data.globals.students.inmate.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.students.inmate.recidivism.toFixed(0)} / {data.globals.students.inmate.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Students and Number of Returns</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Students</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Non-students</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.one.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.one.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.one.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.one.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.one.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.two.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.two.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.two.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.two.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.two.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.three.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.three.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.three.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.three.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.three.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.four.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.four.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.four.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.four.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.four.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.five.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.five.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.five.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.five.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.five.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.six.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.six.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.six.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.six.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.six.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.seven.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.seven.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.seven.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.seven.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.eight.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.eight.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.eight.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.eight.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.nine.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.nine.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.nine.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.nine.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.ten.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.ten.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.ten.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.ten.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.eleven.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.eleven.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.eleven.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.eleven.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.twelve.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.twelve.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.twelve.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.twelve.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.thirteen.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.thirteen.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.thirteen.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.thirteen.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.fourteen.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.fourteen.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.fourteen.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.fourteen.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.returns) == 0 ? '0' : Math.round(data.students.student.returns.fifteenPlus.total / this.totals(data.students.student.returns) * 100) > 100 ? '100' : Math.round(data.students.student.returns.fifteenPlus.total / this.totals(data.students.student.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.returns) == 0 ? '0' : Math.round(data.students.inmate.returns.fifteenPlus.total / this.totals(data.students.inmate.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.returns.fifteenPlus.total / this.totals(data.students.inmate.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Students and Gender</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Students</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Non-students</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.total == 0 ? '0' : Math.round(data.students.student.genders.male.recidivism / data.students.student.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.recidivism / data.students.student.genders.male.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.recidivism.toFixed(0)} / {data.students.student.genders.male.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.total == 0 ? '0' : Math.round(data.students.inmate.genders.male.recidivism / data.students.inmate.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.recidivism / data.students.inmate.genders.male.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.recidivism.toFixed(0)} / {data.students.inmate.genders.male.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.total == 0 ? '0' : Math.round(data.students.student.genders.female.recidivism / data.students.student.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.recidivism / data.students.student.genders.female.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.recidivism.toFixed(0)} / {data.students.student.genders.female.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.total == 0 ? '0' : Math.round(data.students.inmate.genders.female.recidivism / data.students.inmate.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.recidivism / data.students.inmate.genders.female.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.recidivism.toFixed(0)} / {data.students.inmate.genders.female.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Students and Age</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Students</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Non-students</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.total == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.recidivism / data.students.student.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.recidivism / data.students.student.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.recidivism.toFixed(0)} / {data.students.student.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.total == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.recidivism / data.students.inmate.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.recidivism / data.students.inmate.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.recidivism.toFixed(0)} / {data.students.inmate.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.total == 0 ? '0' : Math.round(data.students.student.ages.age22To30.recidivism / data.students.student.ages.age22To30.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.recidivism / data.students.student.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.recidivism.toFixed(0)} / {data.students.student.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.total == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.recidivism / data.students.inmate.ages.age22To30.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.recidivism / data.students.inmate.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.recidivism.toFixed(0)} / {data.students.inmate.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.total == 0 ? '0' : Math.round(data.students.student.ages.age30To40.recidivism / data.students.student.ages.age30To40.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.recidivism / data.students.student.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.recidivism.toFixed(0)} / {data.students.student.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.total == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.recidivism / data.students.inmate.ages.age30To40.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.recidivism / data.students.inmate.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.recidivism.toFixed(0)} / {data.students.inmate.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.total == 0 ? '0' : Math.round(data.students.student.ages.age40To50.recidivism / data.students.student.ages.age40To50.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.recidivism / data.students.student.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.recidivism.toFixed(0)} / {data.students.student.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.total == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.recidivism / data.students.inmate.ages.age40To50.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.recidivism / data.students.inmate.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.recidivism.toFixed(0)} / {data.students.inmate.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.total == 0 ? '0' : Math.round(data.students.student.ages.age50To60.recidivism / data.students.student.ages.age50To60.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.recidivism / data.students.student.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.recidivism.toFixed(0)} / {data.students.student.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.total == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.recidivism / data.students.inmate.ages.age50To60.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.recidivism / data.students.inmate.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.recidivism.toFixed(0)} / {data.students.inmate.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.total == 0 ? '0' : Math.round(data.students.student.ages.age60To70.recidivism / data.students.student.ages.age60To70.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.recidivism / data.students.student.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.recidivism.toFixed(0)} / {data.students.student.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.total == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.recidivism / data.students.inmate.ages.age60To70.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.recidivism / data.students.inmate.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.recidivism.toFixed(0)} / {data.students.inmate.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.total == 0 ? '0' : Math.round(data.students.student.ages.age70To80.recidivism / data.students.student.ages.age70To80.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.recidivism / data.students.student.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.recidivism.toFixed(0)} / {data.students.student.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.total == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.recidivism / data.students.inmate.ages.age70To80.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.recidivism / data.students.inmate.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.recidivism.toFixed(0)} / {data.students.inmate.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.total == 0 ? '0' : Math.round(data.students.student.ages.age80To90.recidivism / data.students.student.ages.age80To90.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.recidivism / data.students.student.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.recidivism.toFixed(0)} / {data.students.student.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.total == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.recidivism / data.students.inmate.ages.age80To90.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.recidivism / data.students.inmate.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.recidivism.toFixed(0)} / {data.students.inmate.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Students and Length of Stay</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Students</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Non-students</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.stayLengths) == 0 ? '0' : Math.round(data.students.student.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.stayLengths) == 0 ? '0' : Math.round(data.students.student.stayLengths.length3To6M.recidivism / this.totals(data.students.student.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.stayLengths.length3To6M.recidivism / this.totals(data.students.student.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.stayLengths) == 0 ? '0' : Math.round(data.students.student.stayLengths.length6To12M.recidivism / this.totals(data.students.student.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.stayLengths.length6To12M.recidivism / this.totals(data.students.student.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.stayLengths) == 0 ? '0' : Math.round(data.students.student.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.stayLengths) == 0 ? '0' : Math.round(data.students.student.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.stayLengths) == 0 ? '0' : Math.round(data.students.student.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Students and Length Between Returns</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Students</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Non-students</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.outLengths) == 0 ? '0' : Math.round(data.students.student.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.outLengths) == 0 ? '0' : Math.round(data.students.inmate.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.outLengths) == 0 ? '0' : Math.round(data.students.student.outLengths.length3To6M.recidivism / this.totals(data.students.student.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.outLengths.length3To6M.recidivism / this.totals(data.students.student.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.outLengths) == 0 ? '0' : Math.round(data.students.inmate.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.outLengths) == 0 ? '0' : Math.round(data.students.student.outLengths.length6To12M.recidivism / this.totals(data.students.student.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.outLengths.length6To12M.recidivism / this.totals(data.students.student.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.outLengths) == 0 ? '0' : Math.round(data.students.inmate.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.outLengths) == 0 ? '0' : Math.round(data.students.student.outLengths.length1To2Y.recidivism / this.totals(data.students.student.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.outLengths.length1To2Y.recidivism / this.totals(data.students.student.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.outLengths) == 0 ? '0' : Math.round(data.students.inmate.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.outLengths) == 0 ? '0' : Math.round(data.students.student.outLengths.length2To5Y.recidivism / this.totals(data.students.student.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.outLengths.length2To5Y.recidivism / this.totals(data.students.student.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.outLengths) == 0 ? '0' : Math.round(data.students.inmate.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.outLengths) == 0 ? '0' : Math.round(data.students.student.outLengths.length5To10Y.recidivism / this.totals(data.students.student.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.outLengths.length5To10Y.recidivism / this.totals(data.students.student.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.outLengths) == 0 ? '0' : Math.round(data.students.inmate.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                  <div style={{flexBasis:`40%`}}>
                    <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Recidivism by Age and Gender</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.genders.male.total == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.genders.male.recidivism / data.students.student.ages.ageUnder22.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.genders.male.recidivism / data.students.student.ages.ageUnder22.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.genders.male.recidivism.toFixed(0)} / {data.students.student.ages.ageUnder22.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.genders.female.total == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.genders.female.recidivism / data.students.student.ages.ageUnder22.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.genders.female.recidivism / data.students.student.ages.ageUnder22.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.genders.female.recidivism.toFixed(0)} / {data.students.student.ages.ageUnder22.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.genders.male.total == 0 ? '0' : Math.round(data.students.student.ages.age22To30.genders.male.recidivism / data.students.student.ages.age22To30.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.genders.male.recidivism / data.students.student.ages.age22To30.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.genders.male.recidivism.toFixed(0)} / {data.students.student.ages.age22To30.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.genders.female.total == 0 ? '0' : Math.round(data.students.student.ages.age22To30.genders.female.recidivism / data.students.student.ages.age22To30.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.genders.female.recidivism / data.students.student.ages.age22To30.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.genders.female.recidivism.toFixed(0)} / {data.students.student.ages.age22To30.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.genders.male.total == 0 ? '0' : Math.round(data.students.student.ages.age30To40.genders.male.recidivism / data.students.student.ages.age30To40.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.genders.male.recidivism / data.students.student.ages.age30To40.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.genders.male.recidivism.toFixed(0)} / {data.students.student.ages.age30To40.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.genders.female.total == 0 ? '0' : Math.round(data.students.student.ages.age30To40.genders.female.recidivism / data.students.student.ages.age30To40.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.genders.female.recidivism / data.students.student.ages.age30To40.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.genders.female.recidivism.toFixed(0)} / {data.students.student.ages.age30To40.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.genders.male.total == 0 ? '0' : Math.round(data.students.student.ages.age40To50.genders.male.recidivism / data.students.student.ages.age40To50.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.genders.male.recidivism / data.students.student.ages.age40To50.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.genders.male.recidivism.toFixed(0)} / {data.students.student.ages.age40To50.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.genders.female.total == 0 ? '0' : Math.round(data.students.student.ages.age40To50.genders.female.recidivism / data.students.student.ages.age40To50.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.genders.female.recidivism / data.students.student.ages.age40To50.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.genders.female.recidivism.toFixed(0)} / {data.students.student.ages.age40To50.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.genders.male.total == 0 ? '0' : Math.round(data.students.student.ages.age50To60.genders.male.recidivism / data.students.student.ages.age50To60.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.genders.male.recidivism / data.students.student.ages.age50To60.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.genders.male.recidivism.toFixed(0)} / {data.students.student.ages.age50To60.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.genders.female.total == 0 ? '0' : Math.round(data.students.student.ages.age50To60.genders.female.recidivism / data.students.student.ages.age50To60.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.genders.female.recidivism / data.students.student.ages.age50To60.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.genders.female.recidivism.toFixed(0)} / {data.students.student.ages.age50To60.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.genders.male.total == 0 ? '0' : Math.round(data.students.student.ages.age60To70.genders.male.recidivism / data.students.student.ages.age60To70.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.genders.male.recidivism / data.students.student.ages.age60To70.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.genders.male.recidivism.toFixed(0)} / {data.students.student.ages.age60To70.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.genders.female.total == 0 ? '0' : Math.round(data.students.student.ages.age60To70.genders.female.recidivism / data.students.student.ages.age60To70.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.genders.female.recidivism / data.students.student.ages.age60To70.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.genders.female.recidivism.toFixed(0)} / {data.students.student.ages.age60To70.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.genders.male.total == 0 ? '0' : Math.round(data.students.student.ages.age70To80.genders.male.recidivism / data.students.student.ages.age70To80.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.genders.male.recidivism / data.students.student.ages.age70To80.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.genders.male.recidivism.toFixed(0)} / {data.students.student.ages.age70To80.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.genders.female.total == 0 ? '0' : Math.round(data.students.student.ages.age70To80.genders.female.recidivism / data.students.student.ages.age70To80.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.genders.female.recidivism / data.students.student.ages.age70To80.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.genders.female.recidivism.toFixed(0)} / {data.students.student.ages.age70To80.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.genders.male.total == 0 ? '0' : Math.round(data.students.student.ages.age80To90.genders.male.recidivism / data.students.student.ages.age80To90.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.genders.male.recidivism / data.students.student.ages.age80To90.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.genders.male.recidivism.toFixed(0)} / {data.students.student.ages.age80To90.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.genders.female.total == 0 ? '0' : Math.round(data.students.student.ages.age80To90.genders.female.recidivism / data.students.student.ages.age80To90.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.genders.female.recidivism / data.students.student.ages.age80To90.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.genders.female.recidivism.toFixed(0)} / {data.students.student.ages.age80To90.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                  <div style={{flexBasis:`40%`}}>
                    <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Inmate Recidivism by Age and Gender</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.genders.male.total == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.genders.male.recidivism / data.students.inmate.ages.ageUnder22.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.genders.male.recidivism / data.students.inmate.ages.ageUnder22.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.genders.male.recidivism.toFixed(0)} / {data.students.inmate.ages.ageUnder22.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.genders.female.total == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.genders.female.recidivism / data.students.inmate.ages.ageUnder22.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.genders.female.recidivism / data.students.inmate.ages.ageUnder22.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.genders.female.recidivism.toFixed(0)} / {data.students.inmate.ages.ageUnder22.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.genders.male.total == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.genders.male.recidivism / data.students.inmate.ages.age22To30.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.genders.male.recidivism / data.students.inmate.ages.age22To30.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.genders.male.recidivism.toFixed(0)} / {data.students.inmate.ages.age22To30.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.genders.female.total == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.genders.female.recidivism / data.students.inmate.ages.age22To30.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.genders.female.recidivism / data.students.inmate.ages.age22To30.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.genders.female.recidivism.toFixed(0)} / {data.students.inmate.ages.age22To30.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.genders.male.total == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.genders.male.recidivism / data.students.inmate.ages.age30To40.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.genders.male.recidivism / data.students.inmate.ages.age30To40.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.genders.male.recidivism.toFixed(0)} / {data.students.inmate.ages.age30To40.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.genders.female.total == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.genders.female.recidivism / data.students.inmate.ages.age30To40.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.genders.female.recidivism / data.students.inmate.ages.age30To40.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.genders.female.recidivism.toFixed(0)} / {data.students.inmate.ages.age30To40.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.genders.male.total == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.genders.male.recidivism / data.students.inmate.ages.age40To50.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.genders.male.recidivism / data.students.inmate.ages.age40To50.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.genders.male.recidivism.toFixed(0)} / {data.students.inmate.ages.age40To50.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.genders.female.total == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.genders.female.recidivism / data.students.inmate.ages.age40To50.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.genders.female.recidivism / data.students.inmate.ages.age40To50.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.genders.female.recidivism.toFixed(0)} / {data.students.inmate.ages.age40To50.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.genders.male.total == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.genders.male.recidivism / data.students.inmate.ages.age50To60.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.genders.male.recidivism / data.students.inmate.ages.age50To60.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.genders.male.recidivism.toFixed(0)} / {data.students.inmate.ages.age50To60.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.genders.female.total == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.genders.female.recidivism / data.students.inmate.ages.age50To60.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.genders.female.recidivism / data.students.inmate.ages.age50To60.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.genders.female.recidivism.toFixed(0)} / {data.students.inmate.ages.age50To60.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.genders.male.total == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.genders.male.recidivism / data.students.inmate.ages.age60To70.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.genders.male.recidivism / data.students.inmate.ages.age60To70.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.genders.male.recidivism.toFixed(0)} / {data.students.inmate.ages.age60To70.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.genders.female.total == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.genders.female.recidivism / data.students.inmate.ages.age60To70.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.genders.female.recidivism / data.students.inmate.ages.age60To70.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.genders.female.recidivism.toFixed(0)} / {data.students.inmate.ages.age60To70.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.genders.male.total == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.genders.male.recidivism / data.students.inmate.ages.age70To80.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.genders.male.recidivism / data.students.inmate.ages.age70To80.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.genders.male.recidivism.toFixed(0)} / {data.students.inmate.ages.age70To80.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.genders.female.total == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.genders.female.recidivism / data.students.inmate.ages.age70To80.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.genders.female.recidivism / data.students.inmate.ages.age70To80.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.genders.female.recidivism.toFixed(0)} / {data.students.inmate.ages.age70To80.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.genders.male.total == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.genders.male.recidivism / data.students.inmate.ages.age80To90.genders.male.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.genders.male.recidivism / data.students.inmate.ages.age80To90.genders.male.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.genders.male.recidivism.toFixed(0)} / {data.students.inmate.ages.age80To90.genders.male.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.genders.female.total == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.genders.female.recidivism / data.students.inmate.ages.age80To90.genders.female.total * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.genders.female.recidivism / data.students.inmate.ages.age80To90.genders.female.total * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.genders.female.recidivism.toFixed(0)} / {data.students.inmate.ages.age80To90.genders.female.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                  <div style={{flexBasis:`40%`}}>
                    <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Recidivism by Length of Stay and Gender</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.stayLengths.length3To6M.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.stayLengths.length3To6M.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.stayLengths.length3To6M.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.stayLengths.length3To6M.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.stayLengths.length6To12M.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.stayLengths.length6To12M.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.stayLengths.length6To12M.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.stayLengths.length6To12M.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                  <div style={{flexBasis:`40%`}}>
                    <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Inmate Recidivism by Length of Stay and Gender</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.genders.male.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.genders.female.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                  <div style={{flexBasis:'40%'}}>
                    <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Recidivism by Length Between Returns and Gender</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.outLengths.length3To6M.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.outLengths.length3To6M.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.outLengths.length3To6M.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.outLengths.length3To6M.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.outLengths.length6To12M.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.outLengths.length6To12M.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.outLengths.length6To12M.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.outLengths.length6To12M.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.outLengths.length1To2Y.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.outLengths.length1To2Y.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.outLengths.length1To2Y.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.outLengths.length1To2Y.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.outLengths.length2To5Y.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.outLengths.length2To5Y.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.outLengths.length2To5Y.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.outLengths.length2To5Y.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.male.outLengths.length5To10Y.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.outLengths.length5To10Y.recidivism / this.totals(data.students.student.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.student.genders.female.outLengths.length5To10Y.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.outLengths.length5To10Y.recidivism / this.totals(data.students.student.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                  <div style={{flexBasis:'40%'}}>
                    <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Inmate Recidivism by Length Between Returns and Gender</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.male.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.genders.male.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.outLengths) == 0 ? '0' : Math.round(data.students.inmate.genders.female.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.genders.female.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:'40%'}}>
                    <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Recidivism by Number of Returns and Gender</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.one.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.one.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.one.total.toFixed(0)} / {data.students.student.genders.male.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.one.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.one.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.one.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.two.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.two.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.two.total.toFixed(0)} / {data.students.student.genders.male.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.two.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.two.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.two.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.three.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.three.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.three.total.toFixed(0)} / {data.students.student.genders.male.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.three.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.three.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.three.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.four.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.four.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.four.total.toFixed(0)} / {data.students.student.genders.male.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.four.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.four.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.four.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.five.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.five.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.five.total.toFixed(0)} / {data.students.student.genders.male.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.five.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.five.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.five.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.six.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.six.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.six.total.toFixed(0)} / {data.students.student.genders.male.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.six.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.six.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.six.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.seven.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.seven.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.seven.total.toFixed(0)} / {data.students.student.genders.male.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.seven.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.seven.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.eight.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.eight.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.eight.total.toFixed(0)} / {data.students.student.genders.male.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.eight.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.eight.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.nine.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.nine.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.nine.total.toFixed(0)} / {data.students.student.genders.male.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.nine.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.nine.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.ten.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.ten.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.ten.total.toFixed(0)} / {data.students.student.genders.male.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.ten.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.ten.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.eleven.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.eleven.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.eleven.total.toFixed(0)} / {data.students.student.genders.male.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.eleven.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.eleven.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.twelve.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.twelve.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.twelve.total.toFixed(0)} / {data.students.student.genders.male.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.twelve.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.twelve.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.thirteen.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.thirteen.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.thirteen.total.toFixed(0)} / {data.students.student.genders.male.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.thirteen.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.thirteen.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.fourteen.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.fourteen.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.fourteen.total.toFixed(0)} / {data.students.student.genders.male.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.fourteen.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.fourteen.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.male.returns) == 0 ? '0' : Math.round(data.students.student.genders.male.returns.fifteenPlus.total / this.totals(data.students.student.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.male.returns.fifteenPlus.total / this.totals(data.students.student.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.male.returns.fifteenPlus.total.toFixed(0)} / {data.students.student.genders.male.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.genders.female.returns) == 0 ? '0' : Math.round(data.students.student.genders.female.returns.fifteenPlus.total / this.totals(data.students.student.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.student.genders.female.returns.fifteenPlus.total / this.totals(data.students.student.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.genders.female.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                  <div style={{flexBasis:'40%'}}>
                    <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Inmate Recidivism by Number of Returns and Gender</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.one.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.one.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.one.total.toFixed(0)} / {data.students.inmate.genders.male.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.one.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.one.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.one.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.two.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.two.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.two.total.toFixed(0)} / {data.students.inmate.genders.male.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.two.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.two.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.two.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.three.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.three.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.three.total.toFixed(0)} / {data.students.inmate.genders.male.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.three.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.three.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.three.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.four.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.four.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.four.total.toFixed(0)} / {data.students.inmate.genders.male.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.four.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.four.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.four.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.five.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.five.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.five.total.toFixed(0)} / {data.students.inmate.genders.male.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.five.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.five.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.five.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.six.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.six.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.six.total.toFixed(0)} / {data.students.inmate.genders.male.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.six.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.six.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.six.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.seven.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.seven.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.seven.total.toFixed(0)} / {data.students.inmate.genders.male.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.seven.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.seven.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.eight.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.eight.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.eight.total.toFixed(0)} / {data.students.inmate.genders.male.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.eight.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.eight.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.nine.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.nine.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.nine.total.toFixed(0)} / {data.students.inmate.genders.male.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.nine.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.nine.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.ten.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.ten.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.ten.total.toFixed(0)} / {data.students.inmate.genders.male.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.ten.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.ten.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.eleven.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.eleven.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.eleven.total.toFixed(0)} / {data.students.inmate.genders.male.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.eleven.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.eleven.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.twelve.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.twelve.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.twelve.total.toFixed(0)} / {data.students.inmate.genders.male.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.twelve.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.twelve.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.thirteen.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.thirteen.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.thirteen.total.toFixed(0)} / {data.students.inmate.genders.male.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.thirteen.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.thirteen.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.fourteen.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.fourteen.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.fourteen.total.toFixed(0)} / {data.students.inmate.genders.male.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.fourteen.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.fourteen.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.male.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.male.returns.fifteenPlus.total / this.totals(data.students.inmate.genders.male.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.male.returns.fifteenPlus.total / this.totals(data.students.inmate.genders.male.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.male.returns.fifteenPlus.total.toFixed(0)} / {data.students.inmate.genders.male.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.genders.female.returns) == 0 ? '0' : Math.round(data.students.inmate.genders.female.returns.fifteenPlus.total / this.totals(data.students.inmate.genders.female.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.genders.female.returns.fifteenPlus.total / this.totals(data.students.inmate.genders.female.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.genders.female.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                  <div style={{flexBasis:'95%'}}>
                    <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Recidivism by Age and Length of Stay</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.stayLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.stayLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.stayLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.stayLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.stayLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                  <div style={{flexBasis:'95%'}}>
                    <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Inmate Recidivism by Age and Length of Stay</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age22To30.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age30To40.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age40To50.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age50To60.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age60To70.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age70To80.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.stayLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.stayLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.stayLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.stayLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.stayLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.stayLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.stayLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age80To90.stayLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                    <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Recidivism by Age and Length Between Returns</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.outLengths.lengthUnder3M.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.outLengths.length3To6M.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.outLengths.length6To12M.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.outLengths.length1To2Y.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.outLengths.length2To5Y.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.outLengths.length5To10Y.recidivism / this.totals(data.students.student.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                  <div style={{flexBasis:'95%'}}>
                    <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Inmate Recidivism by Age and Length Between Returns</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.ageUnder22.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age22To30.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age30To40.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age40To50.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age50To60.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age60To70.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age70To80.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.outLengths.lengthUnder3M.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.outLengths.length3To6M.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.outLengths.length6To12M.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.outLengths.length1To2Y.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.outLengths.length2To5Y.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.outLengths) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.outLengths.length5To10Y.recidivism / this.totals(data.students.inmate.ages.age80To90.outLengths) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                    <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={30} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Student Recidivism by Age and Number of Returns</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.one.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.one.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.one.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.one.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.one.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.one.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.one.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.one.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.one.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.one.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.one.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.one.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.one.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.one.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.one.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.one.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.one.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.one.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.one.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.one.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.one.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.one.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.one.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.one.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.two.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.two.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.two.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.two.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.two.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.two.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.two.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.two.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.two.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.two.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.two.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.two.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.two.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.two.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.two.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.two.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.two.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.two.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.two.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.two.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.two.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.two.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.two.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.two.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.three.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.three.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.three.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.three.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.three.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.three.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.three.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.three.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.three.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.three.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.three.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.three.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.three.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.three.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.three.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.three.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.three.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.three.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.three.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.three.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.three.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.three.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.three.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.three.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.four.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.four.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.four.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.four.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.four.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.four.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.four.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.four.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.four.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.four.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.four.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.four.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.four.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.four.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.four.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.four.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.four.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.four.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.four.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.four.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.four.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.four.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.four.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.four.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.five.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.five.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.five.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.five.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.five.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.five.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.five.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.five.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.five.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.five.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.five.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.five.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.five.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.five.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.five.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.five.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.five.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.five.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.five.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.five.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.five.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.five.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.five.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.five.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.six.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.six.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.six.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.six.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.six.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.six.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.six.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.six.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.six.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.six.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.six.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.six.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.six.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.six.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.six.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.six.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.six.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.six.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.six.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.six.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.six.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.six.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.six.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.six.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.seven.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.seven.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.seven.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.seven.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.seven.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.seven.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.seven.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.seven.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.seven.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.seven.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.seven.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.seven.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.seven.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.seven.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.seven.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.seven.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.seven.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.seven.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.seven.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.seven.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.seven.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.seven.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.seven.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.eight.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.eight.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.eight.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.eight.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.eight.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.eight.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.eight.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.eight.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.eight.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.eight.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.eight.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.eight.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.eight.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.eight.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.eight.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.eight.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.eight.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.eight.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.eight.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.eight.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.eight.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.eight.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.eight.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.nine.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.nine.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.nine.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.nine.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.nine.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.nine.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.nine.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.nine.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.nine.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.nine.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.nine.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.nine.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.nine.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.nine.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.nine.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.nine.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.nine.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.nine.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.nine.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.nine.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.nine.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.nine.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.nine.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.ten.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.ten.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.ten.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.ten.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.ten.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.ten.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.ten.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.ten.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.ten.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.ten.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.ten.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.ten.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.ten.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.ten.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.ten.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.ten.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.ten.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.ten.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.ten.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.ten.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.ten.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.ten.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.ten.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.eleven.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.eleven.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.eleven.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.eleven.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.eleven.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.eleven.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.eleven.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.eleven.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.eleven.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.eleven.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.eleven.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.eleven.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.eleven.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.eleven.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.eleven.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.eleven.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.eleven.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.eleven.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.eleven.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.eleven.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.eleven.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.eleven.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.eleven.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.twelve.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.twelve.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.twelve.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.twelve.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.twelve.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.twelve.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.twelve.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.twelve.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.twelve.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.twelve.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.twelve.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.twelve.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.twelve.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.twelve.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.twelve.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.twelve.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.twelve.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.twelve.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.twelve.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.twelve.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.twelve.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.twelve.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.twelve.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.thirteen.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.thirteen.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.thirteen.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.thirteen.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.thirteen.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.thirteen.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.thirteen.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.thirteen.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.thirteen.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.thirteen.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.thirteen.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.thirteen.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.thirteen.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.thirteen.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.thirteen.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.thirteen.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.thirteen.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.thirteen.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.thirteen.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.thirteen.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.thirteen.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.thirteen.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.thirteen.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.fourteen.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.fourteen.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.fourteen.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.fourteen.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.fourteen.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.fourteen.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.fourteen.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.fourteen.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.fourteen.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.fourteen.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.fourteen.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.fourteen.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.fourteen.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.fourteen.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.fourteen.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.fourteen.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.fourteen.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.fourteen.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.fourteen.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.fourteen.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.fourteen.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.fourteen.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.fourteen.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.student.ages.ageUnder22.returns.fifteenPlus.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.ageUnder22.returns.fifteenPlus.total / this.totals(data.students.student.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.ageUnder22.returns.fifteenPlus.total.toFixed(0)} / {data.students.student.ages.ageUnder22.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.student.ages.age22To30.returns.fifteenPlus.total / this.totals(data.students.student.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age22To30.returns.fifteenPlus.total / this.totals(data.students.student.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age22To30.returns.fifteenPlus.total.toFixed(0)} / {data.students.student.ages.age22To30.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.student.ages.age30To40.returns.fifteenPlus.total / this.totals(data.students.student.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age30To40.returns.fifteenPlus.total / this.totals(data.students.student.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age30To40.returns.fifteenPlus.total.toFixed(0)} / {data.students.student.ages.age30To40.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.student.ages.age40To50.returns.fifteenPlus.total / this.totals(data.students.student.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age40To50.returns.fifteenPlus.total / this.totals(data.students.student.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age40To50.returns.fifteenPlus.total.toFixed(0)} / {data.students.student.ages.age40To50.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.student.ages.age50To60.returns.fifteenPlus.total / this.totals(data.students.student.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age50To60.returns.fifteenPlus.total / this.totals(data.students.student.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age50To60.returns.fifteenPlus.total.toFixed(0)} / {data.students.student.ages.age50To60.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.student.ages.age60To70.returns.fifteenPlus.total / this.totals(data.students.student.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age60To70.returns.fifteenPlus.total / this.totals(data.students.student.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age60To70.returns.fifteenPlus.total.toFixed(0)} / {data.students.student.ages.age60To70.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.student.ages.age70To80.returns.fifteenPlus.total / this.totals(data.students.student.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age70To80.returns.fifteenPlus.total / this.totals(data.students.student.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age70To80.returns.fifteenPlus.total.toFixed(0)} / {data.students.student.ages.age70To80.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.student.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.student.ages.age80To90.returns.fifteenPlus.total / this.totals(data.students.student.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.student.ages.age80To90.returns.fifteenPlus.total / this.totals(data.students.student.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.student.ages.age80To90.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                  <div style={{flexBasis:'95%'}}>
                    <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                      <Table.Header>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={30} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Inmate Recidivism by Age and Number of Returns</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row style={{textAlign:'center'}}>
                          <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                          <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.one.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.one.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.one.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.one.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.one.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.one.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.one.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.one.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.one.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.one.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.one.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.one.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.one.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.one.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.one.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.one.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.one.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.one.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.one.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.one.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.one.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.one.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.one.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.one.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.one.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.two.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.two.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.two.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.two.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.two.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.two.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.two.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.two.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.two.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.two.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.two.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.two.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.two.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.two.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.two.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.two.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.two.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.two.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.two.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.two.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.two.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.two.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.two.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.two.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.two.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.three.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.three.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.three.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.three.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.three.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.three.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.three.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.three.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.three.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.three.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.three.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.three.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.three.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.three.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.three.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.three.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.three.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.three.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.three.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.three.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.three.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.three.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.three.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.three.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.three.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.four.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.four.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.four.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.four.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.four.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.four.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.four.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.four.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.four.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.four.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.four.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.four.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.four.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.four.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.four.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.four.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.four.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.four.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.four.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.four.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.four.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.four.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.four.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.four.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.four.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.five.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.five.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.five.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.five.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.five.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.five.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.five.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.five.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.five.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.five.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.five.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.five.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.five.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.five.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.five.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.five.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.five.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.five.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.five.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.five.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.five.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.five.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.five.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.five.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.five.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.six.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.six.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.six.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.six.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.six.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.six.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.six.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.six.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.six.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.six.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.six.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.six.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.six.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.six.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.six.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.six.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.six.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.six.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.six.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.six.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.six.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.six.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.six.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.six.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.six.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.seven.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.seven.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.seven.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.seven.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.seven.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.seven.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.seven.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.seven.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.seven.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.seven.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.seven.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.seven.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.seven.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.seven.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.seven.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.seven.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.seven.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.seven.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.seven.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.seven.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.seven.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.seven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.seven.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.seven.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.eight.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.eight.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.eight.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.eight.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.eight.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.eight.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.eight.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.eight.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.eight.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.eight.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.eight.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.eight.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.eight.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.eight.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.eight.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.eight.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.eight.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.eight.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.eight.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.eight.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.eight.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.eight.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.eight.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.eight.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.nine.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.nine.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.nine.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.nine.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.nine.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.nine.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.nine.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.nine.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.nine.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.nine.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.nine.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.nine.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.nine.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.nine.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.nine.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.nine.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.nine.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.nine.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.nine.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.nine.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.nine.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.nine.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.nine.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.nine.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.ten.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.ten.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.ten.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.ten.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.ten.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.ten.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.ten.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.ten.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.ten.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.ten.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.ten.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.ten.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.ten.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.ten.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.ten.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.ten.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.ten.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.ten.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.ten.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.ten.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.ten.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.ten.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.ten.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.ten.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.eleven.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.eleven.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.eleven.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.eleven.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.eleven.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.eleven.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.eleven.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.eleven.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.eleven.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.eleven.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.eleven.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.eleven.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.eleven.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.eleven.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.eleven.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.eleven.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.eleven.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.eleven.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.eleven.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.eleven.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.eleven.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.eleven.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.eleven.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.twelve.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.twelve.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.twelve.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.twelve.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.twelve.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.twelve.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.twelve.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.twelve.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.twelve.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.twelve.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.twelve.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.twelve.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.twelve.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.twelve.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.twelve.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.twelve.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.twelve.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.twelve.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.twelve.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.twelve.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.twelve.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.twelve.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.twelve.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.thirteen.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.thirteen.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.thirteen.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.thirteen.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.thirteen.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.thirteen.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.thirteen.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.thirteen.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.thirteen.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.thirteen.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.thirteen.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.thirteen.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.thirteen.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.thirteen.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.thirteen.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.thirteen.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.thirteen.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.thirteen.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.thirteen.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.thirteen.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.thirteen.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.thirteen.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.thirteen.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.fourteen.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.fourteen.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.fourteen.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.fourteen.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.fourteen.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.fourteen.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.fourteen.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.fourteen.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.fourteen.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.fourteen.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.fourteen.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.fourteen.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.fourteen.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.fourteen.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.fourteen.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.fourteen.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.fourteen.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.fourteen.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.fourteen.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.fourteen.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.fourteen.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.fourteen.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.fourteen.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                        <Table.Row style={{backgroundColor: 'white'}}>
                          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.ageUnder22.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.ageUnder22.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.ageUnder22.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.ageUnder22.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.ageUnder22.returns.fifteenPlus.total.toFixed(0)} / {data.students.inmate.ages.ageUnder22.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age22To30.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age22To30.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age22To30.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age22To30.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age22To30.returns.fifteenPlus.total.toFixed(0)} / {data.students.inmate.ages.age22To30.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age30To40.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age30To40.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age30To40.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age30To40.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age30To40.returns.fifteenPlus.total.toFixed(0)} / {data.students.inmate.ages.age30To40.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age40To50.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age40To50.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age40To50.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age40To50.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age40To50.returns.fifteenPlus.total.toFixed(0)} / {data.students.inmate.ages.age40To50.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age50To60.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age50To60.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age50To60.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age50To60.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age50To60.returns.fifteenPlus.total.toFixed(0)} / {data.students.inmate.ages.age50To60.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age60To70.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age60To70.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age60To70.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age60To70.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age60To70.returns.fifteenPlus.total.toFixed(0)} / {data.students.inmate.ages.age60To70.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age70To80.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age70To80.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age70To80.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age70To80.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age70To80.returns.fifteenPlus.total.toFixed(0)} / {data.students.inmate.ages.age70To80.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.students.inmate.ages.age80To90.returns) == 0 ? '0' : Math.round(data.students.inmate.ages.age80To90.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100) > 100 ? '100' : Math.round(data.students.inmate.ages.age80To90.returns.fifteenPlus.total / this.totals(data.students.inmate.ages.age80To90.returns) * 100)}%</div></Table.Cell>
                          <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.students.inmate.ages.age80To90.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        )
      }
}

export default Recidivism