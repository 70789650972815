import React, { Fragment } from 'react'
import {
  Table,
  Container
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class Recidivism extends React.PureComponent<ReportProps> {
    private totals(object:any) {
      let total = 0
      _.each(object, (value:any,key:any) => {
        if (value && value.total) { total += value.total }
      })
      return total
    }

    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let params = this.props.report.data
        let data = this.props.report.generated

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Recidivism Report</div><div style={{marginTop:4}}>{`${data.firstTransfer} through ${data.lastTransfer}`}</div></div>
            </div>
            {_.map(data.facilities, (facility:any) => <div key={facility.abbreviation} style={{pageBreakAfter:'always'}}>
              <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
                <div style={{fontWeight:700,fontSize:16}}>Student Breakdowns - {facility.abbreviation}</div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`95%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={10} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Class Hours and Program Particpation</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Academics</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Vocational</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Self Development</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Social Studies</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Miscellaneous</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 5 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours1To5.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours1To5.recidivism / facility.extraStudents.academics.students.hours1To5.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours1To5.recidivism / facility.extraStudents.academics.students.hours1To5.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours1To5.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours1To5.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours1To5.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours1To5.recidivism / facility.extraStudents.vocational.students.hours1To5.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours1To5.recidivism / facility.extraStudents.vocational.students.hours1To5.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours1To5.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours1To5.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours1To5.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours1To5.recidivism / facility.extraStudents.selfDevelopment.students.hours1To5.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours1To5.recidivism / facility.extraStudents.selfDevelopment.students.hours1To5.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours1To5.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours1To5.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours1To5.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours1To5.recidivism / facility.extraStudents.socialStudies.students.hours1To5.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours1To5.recidivism / facility.extraStudents.socialStudies.students.hours1To5.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours1To5.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours1To5.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours1To5.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours1To5.recidivism / facility.extraStudents.misc.students.hours1To5.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours1To5.recidivism / facility.extraStudents.misc.students.hours1To5.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours1To5.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours1To5.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours5To10.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours5To10.recidivism / facility.extraStudents.academics.students.hours5To10.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours5To10.recidivism / facility.extraStudents.academics.students.hours5To10.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours5To10.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours5To10.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours5To10.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours5To10.recidivism / facility.extraStudents.vocational.students.hours5To10.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours5To10.recidivism / facility.extraStudents.vocational.students.hours5To10.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours5To10.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours5To10.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours5To10.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours5To10.recidivism / facility.extraStudents.selfDevelopment.students.hours5To10.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours5To10.recidivism / facility.extraStudents.selfDevelopment.students.hours5To10.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours5To10.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours5To10.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours5To10.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours5To10.recidivism / facility.extraStudents.socialStudies.students.hours5To10.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours5To10.recidivism / facility.extraStudents.socialStudies.students.hours5To10.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours5To10.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours5To10.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours5To10.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours5To10.recidivism / facility.extraStudents.misc.students.hours5To10.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours5To10.recidivism / facility.extraStudents.misc.students.hours5To10.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours5To10.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours5To10.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>10 - 25 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours10To25.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours10To25.recidivism / facility.extraStudents.academics.students.hours10To25.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours10To25.recidivism / facility.extraStudents.academics.students.hours10To25.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours10To25.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours10To25.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours10To25.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours10To25.recidivism / facility.extraStudents.vocational.students.hours10To25.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours10To25.recidivism / facility.extraStudents.vocational.students.hours10To25.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours10To25.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours10To25.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours10To25.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours10To25.recidivism / facility.extraStudents.selfDevelopment.students.hours10To25.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours10To25.recidivism / facility.extraStudents.selfDevelopment.students.hours10To25.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours10To25.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours10To25.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours10To25.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours10To25.recidivism / facility.extraStudents.socialStudies.students.hours10To25.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours10To25.recidivism / facility.extraStudents.socialStudies.students.hours10To25.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours10To25.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours10To25.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours10To25.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours10To25.recidivism / facility.extraStudents.misc.students.hours10To25.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours10To25.recidivism / facility.extraStudents.misc.students.hours10To25.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours10To25.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours10To25.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>25 - 50 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours25To50.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours25To50.recidivism / facility.extraStudents.academics.students.hours25To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours25To50.recidivism / facility.extraStudents.academics.students.hours25To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours25To50.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours25To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours25To50.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours25To50.recidivism / facility.extraStudents.vocational.students.hours25To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours25To50.recidivism / facility.extraStudents.vocational.students.hours25To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours25To50.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours25To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours25To50.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours25To50.recidivism / facility.extraStudents.selfDevelopment.students.hours25To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours25To50.recidivism / facility.extraStudents.selfDevelopment.students.hours25To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours25To50.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours25To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours25To50.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours25To50.recidivism / facility.extraStudents.socialStudies.students.hours25To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours25To50.recidivism / facility.extraStudents.socialStudies.students.hours25To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours25To50.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours25To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours25To50.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours25To50.recidivism / facility.extraStudents.misc.students.hours25To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours25To50.recidivism / facility.extraStudents.misc.students.hours25To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours25To50.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours25To50.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 100 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours50To100.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours50To100.recidivism / facility.extraStudents.academics.students.hours50To100.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours50To100.recidivism / facility.extraStudents.academics.students.hours50To100.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours50To100.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours50To100.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours50To100.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours50To100.recidivism / facility.extraStudents.vocational.students.hours50To100.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours50To100.recidivism / facility.extraStudents.vocational.students.hours50To100.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours50To100.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours50To100.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours50To100.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours50To100.recidivism / facility.extraStudents.selfDevelopment.students.hours50To100.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours50To100.recidivism / facility.extraStudents.selfDevelopment.students.hours50To100.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours50To100.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours50To100.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours50To100.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours50To100.recidivism / facility.extraStudents.socialStudies.students.hours50To100.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours50To100.recidivism / facility.extraStudents.socialStudies.students.hours50To100.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours50To100.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours50To100.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours50To100.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours50To100.recidivism / facility.extraStudents.misc.students.hours50To100.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours50To100.recidivism / facility.extraStudents.misc.students.hours50To100.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours50To100.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours50To100.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>100 - 250 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours100To250.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours100To250.recidivism / facility.extraStudents.academics.students.hours100To250.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours100To250.recidivism / facility.extraStudents.academics.students.hours100To250.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours100To250.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours100To250.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours100To250.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours100To250.recidivism / facility.extraStudents.vocational.students.hours100To250.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours100To250.recidivism / facility.extraStudents.vocational.students.hours100To250.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours100To250.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours100To250.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours100To250.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours100To250.recidivism / facility.extraStudents.selfDevelopment.students.hours100To250.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours100To250.recidivism / facility.extraStudents.selfDevelopment.students.hours100To250.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours100To250.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours100To250.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours100To250.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours100To250.recidivism / facility.extraStudents.socialStudies.students.hours100To250.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours100To250.recidivism / facility.extraStudents.socialStudies.students.hours100To250.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours100To250.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours100To250.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours100To250.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours100To250.recidivism / facility.extraStudents.misc.students.hours100To250.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours100To250.recidivism / facility.extraStudents.misc.students.hours100To250.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours100To250.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours100To250.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>250 - 500 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours250To500.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours250To500.recidivism / facility.extraStudents.academics.students.hours250To500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours250To500.recidivism / facility.extraStudents.academics.students.hours250To500.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours250To500.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours250To500.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours250To500.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours250To500.recidivism / facility.extraStudents.vocational.students.hours250To500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours250To500.recidivism / facility.extraStudents.vocational.students.hours250To500.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours250To500.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours250To500.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours250To500.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours250To500.recidivism / facility.extraStudents.selfDevelopment.students.hours250To500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours250To500.recidivism / facility.extraStudents.selfDevelopment.students.hours250To500.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours250To500.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours250To500.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours250To500.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours250To500.recidivism / facility.extraStudents.socialStudies.students.hours250To500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours250To500.recidivism / facility.extraStudents.socialStudies.students.hours250To500.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours250To500.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours250To500.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours250To500.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours250To500.recidivism / facility.extraStudents.misc.students.hours250To500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours250To500.recidivism / facility.extraStudents.misc.students.hours250To500.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours250To500.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours250To500.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Over 500 Hours</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hoursOver500.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hoursOver500.recidivism / facility.extraStudents.academics.students.hoursOver500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hoursOver500.recidivism / facility.extraStudents.academics.students.hoursOver500.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hoursOver500.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hoursOver500.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hoursOver500.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hoursOver500.recidivism / facility.extraStudents.vocational.students.hoursOver500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hoursOver500.recidivism / facility.extraStudents.vocational.students.hoursOver500.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hoursOver500.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hoursOver500.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hoursOver500.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hoursOver500.recidivism / facility.extraStudents.selfDevelopment.students.hoursOver500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hoursOver500.recidivism / facility.extraStudents.selfDevelopment.students.hoursOver500.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hoursOver500.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hoursOver500.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hoursOver500.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hoursOver500.recidivism / facility.extraStudents.socialStudies.students.hoursOver500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hoursOver500.recidivism / facility.extraStudents.socialStudies.students.hoursOver500.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hoursOver500.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hoursOver500.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hoursOver500.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hoursOver500.recidivism / facility.extraStudents.misc.students.hoursOver500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hoursOver500.recidivism / facility.extraStudents.misc.students.hoursOver500.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hoursOver500.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hoursOver500.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={16} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Education Attained and Age</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>None</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.ageUnder22.recidivism / facility.extraStudents.levelNone.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.ageUnder22.recidivism / facility.extraStudents.levelNone.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age22To30.recidivism / facility.extraStudents.levelNone.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age22To30.recidivism / facility.extraStudents.levelNone.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age30To40.recidivism / facility.extraStudents.levelNone.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age30To40.recidivism / facility.extraStudents.levelNone.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age40To50.recidivism / facility.extraStudents.levelNone.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age40To50.recidivism / facility.extraStudents.levelNone.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age50To60.recidivism / facility.extraStudents.levelNone.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age50To60.recidivism / facility.extraStudents.levelNone.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age60To70.recidivism / facility.extraStudents.levelNone.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age60To70.recidivism / facility.extraStudents.levelNone.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age70To80.recidivism / facility.extraStudents.levelNone.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age70To80.recidivism / facility.extraStudents.levelNone.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age80To90.recidivism / facility.extraStudents.levelNone.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age80To90.recidivism / facility.extraStudents.levelNone.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Primary</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.ageUnder22.recidivism / facility.extraStudents.levelPrimary.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.ageUnder22.recidivism / facility.extraStudents.levelPrimary.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age22To30.recidivism / facility.extraStudents.levelPrimary.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age22To30.recidivism / facility.extraStudents.levelPrimary.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age30To40.recidivism / facility.extraStudents.levelPrimary.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age30To40.recidivism / facility.extraStudents.levelPrimary.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age40To50.recidivism / facility.extraStudents.levelPrimary.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age40To50.recidivism / facility.extraStudents.levelPrimary.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age50To60.recidivism / facility.extraStudents.levelPrimary.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age50To60.recidivism / facility.extraStudents.levelPrimary.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age60To70.recidivism / facility.extraStudents.levelPrimary.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age60To70.recidivism / facility.extraStudents.levelPrimary.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age70To80.recidivism / facility.extraStudents.levelPrimary.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age70To80.recidivism / facility.extraStudents.levelPrimary.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age80To90.recidivism / facility.extraStudents.levelPrimary.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age80To90.recidivism / facility.extraStudents.levelPrimary.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Secondary</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.ageUnder22.recidivism / facility.extraStudents.levelSecondary.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.ageUnder22.recidivism / facility.extraStudents.levelSecondary.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age22To30.recidivism / facility.extraStudents.levelSecondary.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age22To30.recidivism / facility.extraStudents.levelSecondary.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age30To40.recidivism / facility.extraStudents.levelSecondary.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age30To40.recidivism / facility.extraStudents.levelSecondary.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age40To50.recidivism / facility.extraStudents.levelSecondary.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age40To50.recidivism / facility.extraStudents.levelSecondary.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age50To60.recidivism / facility.extraStudents.levelSecondary.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age50To60.recidivism / facility.extraStudents.levelSecondary.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age60To70.recidivism / facility.extraStudents.levelSecondary.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age60To70.recidivism / facility.extraStudents.levelSecondary.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age70To80.recidivism / facility.extraStudents.levelSecondary.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age70To80.recidivism / facility.extraStudents.levelSecondary.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age80To90.recidivism / facility.extraStudents.levelSecondary.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age80To90.recidivism / facility.extraStudents.levelSecondary.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Post Secondary</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.ageUnder22.recidivism / facility.extraStudents.levelPost.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.ageUnder22.recidivism / facility.extraStudents.levelPost.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age22To30.recidivism / facility.extraStudents.levelPost.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age22To30.recidivism / facility.extraStudents.levelPost.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age30To40.recidivism / facility.extraStudents.levelPost.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age30To40.recidivism / facility.extraStudents.levelPost.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age40To50.recidivism / facility.extraStudents.levelPost.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age40To50.recidivism / facility.extraStudents.levelPost.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age50To60.recidivism / facility.extraStudents.levelPost.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age50To60.recidivism / facility.extraStudents.levelPost.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age60To70.recidivism / facility.extraStudents.levelPost.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age60To70.recidivism / facility.extraStudents.levelPost.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age70To80.recidivism / facility.extraStudents.levelPost.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age70To80.recidivism / facility.extraStudents.levelPost.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age80To90.recidivism / facility.extraStudents.levelPost.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age80To90.recidivism / facility.extraStudents.levelPost.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={16} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Program Participation and Age</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Academics</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.ageUnder22.recidivism / facility.extraStudents.academics.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.ageUnder22.recidivism / facility.extraStudents.academics.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age22To30.recidivism / facility.extraStudents.academics.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age22To30.recidivism / facility.extraStudents.academics.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age30To40.recidivism / facility.extraStudents.academics.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age30To40.recidivism / facility.extraStudents.academics.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age40To50.recidivism / facility.extraStudents.academics.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age40To50.recidivism / facility.extraStudents.academics.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age50To60.recidivism / facility.extraStudents.academics.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age50To60.recidivism / facility.extraStudents.academics.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age60To70.recidivism / facility.extraStudents.academics.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age60To70.recidivism / facility.extraStudents.academics.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age70To80.recidivism / facility.extraStudents.academics.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age70To80.recidivism / facility.extraStudents.academics.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age80To90.recidivism / facility.extraStudents.academics.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age80To90.recidivism / facility.extraStudents.academics.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Vocational</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.ageUnder22.recidivism / facility.extraStudents.vocational.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.ageUnder22.recidivism / facility.extraStudents.vocational.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age22To30.recidivism / facility.extraStudents.vocational.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age22To30.recidivism / facility.extraStudents.vocational.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age30To40.recidivism / facility.extraStudents.vocational.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age30To40.recidivism / facility.extraStudents.vocational.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age40To50.recidivism / facility.extraStudents.vocational.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age40To50.recidivism / facility.extraStudents.vocational.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age50To60.recidivism / facility.extraStudents.vocational.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age50To60.recidivism / facility.extraStudents.vocational.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age60To70.recidivism / facility.extraStudents.vocational.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age60To70.recidivism / facility.extraStudents.vocational.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age70To80.recidivism / facility.extraStudents.vocational.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age70To80.recidivism / facility.extraStudents.vocational.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age80To90.recidivism / facility.extraStudents.vocational.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age80To90.recidivism / facility.extraStudents.vocational.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Self Development</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.ageUnder22.recidivism / facility.extraStudents.selfDevelopment.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.ageUnder22.recidivism / facility.extraStudents.selfDevelopment.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age22To30.recidivism / facility.extraStudents.selfDevelopment.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age22To30.recidivism / facility.extraStudents.selfDevelopment.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age30To40.recidivism / facility.extraStudents.selfDevelopment.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age30To40.recidivism / facility.extraStudents.selfDevelopment.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age40To50.recidivism / facility.extraStudents.selfDevelopment.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age40To50.recidivism / facility.extraStudents.selfDevelopment.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age50To60.recidivism / facility.extraStudents.selfDevelopment.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age50To60.recidivism / facility.extraStudents.selfDevelopment.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age60To70.recidivism / facility.extraStudents.selfDevelopment.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age60To70.recidivism / facility.extraStudents.selfDevelopment.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age70To80.recidivism / facility.extraStudents.selfDevelopment.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age70To80.recidivism / facility.extraStudents.selfDevelopment.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age80To90.recidivism / facility.extraStudents.selfDevelopment.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age80To90.recidivism / facility.extraStudents.selfDevelopment.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Social Studies</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.ageUnder22.recidivism / facility.extraStudents.socialStudies.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.ageUnder22.recidivism / facility.extraStudents.socialStudies.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age22To30.recidivism / facility.extraStudents.socialStudies.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age22To30.recidivism / facility.extraStudents.socialStudies.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age30To40.recidivism / facility.extraStudents.socialStudies.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age30To40.recidivism / facility.extraStudents.socialStudies.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age40To50.recidivism / facility.extraStudents.socialStudies.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age40To50.recidivism / facility.extraStudents.socialStudies.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age50To60.recidivism / facility.extraStudents.socialStudies.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age50To60.recidivism / facility.extraStudents.socialStudies.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age60To70.recidivism / facility.extraStudents.socialStudies.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age60To70.recidivism / facility.extraStudents.socialStudies.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age70To80.recidivism / facility.extraStudents.socialStudies.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age70To80.recidivism / facility.extraStudents.socialStudies.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age80To90.recidivism / facility.extraStudents.socialStudies.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age80To90.recidivism / facility.extraStudents.socialStudies.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Miscellaneous</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.ageUnder22.recidivism / facility.extraStudents.misc.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.ageUnder22.recidivism / facility.extraStudents.misc.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age22To30.recidivism / facility.extraStudents.misc.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age22To30.recidivism / facility.extraStudents.misc.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age30To40.recidivism / facility.extraStudents.misc.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age30To40.recidivism / facility.extraStudents.misc.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age40To50.recidivism / facility.extraStudents.misc.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age40To50.recidivism / facility.extraStudents.misc.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age50To60.recidivism / facility.extraStudents.misc.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age50To60.recidivism / facility.extraStudents.misc.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age60To70.recidivism / facility.extraStudents.misc.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age60To70.recidivism / facility.extraStudents.misc.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age70To80.recidivism / facility.extraStudents.misc.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age70To80.recidivism / facility.extraStudents.misc.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age80To90.recidivism / facility.extraStudents.misc.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age80To90.recidivism / facility.extraStudents.misc.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`95%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={16} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Class Hours and Age</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 5 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.ageUnder22.recidivism / facility.extraStudents.hours1To5.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.ageUnder22.recidivism / facility.extraStudents.hours1To5.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age22To30.recidivism / facility.extraStudents.hours1To5.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age22To30.recidivism / facility.extraStudents.hours1To5.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age30To40.recidivism / facility.extraStudents.hours1To5.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age30To40.recidivism / facility.extraStudents.hours1To5.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age40To50.recidivism / facility.extraStudents.hours1To5.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age40To50.recidivism / facility.extraStudents.hours1To5.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age50To60.recidivism / facility.extraStudents.hours1To5.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age50To60.recidivism / facility.extraStudents.hours1To5.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age60To70.recidivism / facility.extraStudents.hours1To5.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age60To70.recidivism / facility.extraStudents.hours1To5.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age70To80.recidivism / facility.extraStudents.hours1To5.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age70To80.recidivism / facility.extraStudents.hours1To5.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age80To90.recidivism / facility.extraStudents.hours1To5.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age80To90.recidivism / facility.extraStudents.hours1To5.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.ageUnder22.recidivism / facility.extraStudents.hours5To10.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.ageUnder22.recidivism / facility.extraStudents.hours5To10.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age22To30.recidivism / facility.extraStudents.hours5To10.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age22To30.recidivism / facility.extraStudents.hours5To10.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age30To40.recidivism / facility.extraStudents.hours5To10.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age30To40.recidivism / facility.extraStudents.hours5To10.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age40To50.recidivism / facility.extraStudents.hours5To10.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age40To50.recidivism / facility.extraStudents.hours5To10.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age50To60.recidivism / facility.extraStudents.hours5To10.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age50To60.recidivism / facility.extraStudents.hours5To10.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age60To70.recidivism / facility.extraStudents.hours5To10.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age60To70.recidivism / facility.extraStudents.hours5To10.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age70To80.recidivism / facility.extraStudents.hours5To10.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age70To80.recidivism / facility.extraStudents.hours5To10.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age80To90.recidivism / facility.extraStudents.hours5To10.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age80To90.recidivism / facility.extraStudents.hours5To10.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>10 - 25 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.ageUnder22.recidivism / facility.extraStudents.hours10To25.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.ageUnder22.recidivism / facility.extraStudents.hours10To25.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age22To30.recidivism / facility.extraStudents.hours10To25.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age22To30.recidivism / facility.extraStudents.hours10To25.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age30To40.recidivism / facility.extraStudents.hours10To25.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age30To40.recidivism / facility.extraStudents.hours10To25.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age40To50.recidivism / facility.extraStudents.hours10To25.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age40To50.recidivism / facility.extraStudents.hours10To25.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age50To60.recidivism / facility.extraStudents.hours10To25.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age50To60.recidivism / facility.extraStudents.hours10To25.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age60To70.recidivism / facility.extraStudents.hours10To25.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age60To70.recidivism / facility.extraStudents.hours10To25.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age70To80.recidivism / facility.extraStudents.hours10To25.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age70To80.recidivism / facility.extraStudents.hours10To25.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age80To90.recidivism / facility.extraStudents.hours10To25.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age80To90.recidivism / facility.extraStudents.hours10To25.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>25 - 50 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.ageUnder22.recidivism / facility.extraStudents.hours25To50.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.ageUnder22.recidivism / facility.extraStudents.hours25To50.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age22To30.recidivism / facility.extraStudents.hours25To50.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age22To30.recidivism / facility.extraStudents.hours25To50.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age30To40.recidivism / facility.extraStudents.hours25To50.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age30To40.recidivism / facility.extraStudents.hours25To50.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age40To50.recidivism / facility.extraStudents.hours25To50.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age40To50.recidivism / facility.extraStudents.hours25To50.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age50To60.recidivism / facility.extraStudents.hours25To50.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age50To60.recidivism / facility.extraStudents.hours25To50.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age60To70.recidivism / facility.extraStudents.hours25To50.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age60To70.recidivism / facility.extraStudents.hours25To50.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age70To80.recidivism / facility.extraStudents.hours25To50.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age70To80.recidivism / facility.extraStudents.hours25To50.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age80To90.recidivism / facility.extraStudents.hours25To50.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age80To90.recidivism / facility.extraStudents.hours25To50.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 100 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.ageUnder22.recidivism / facility.extraStudents.hours50To100.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.ageUnder22.recidivism / facility.extraStudents.hours50To100.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age22To30.recidivism / facility.extraStudents.hours50To100.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age22To30.recidivism / facility.extraStudents.hours50To100.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age30To40.recidivism / facility.extraStudents.hours50To100.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age30To40.recidivism / facility.extraStudents.hours50To100.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age40To50.recidivism / facility.extraStudents.hours50To100.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age40To50.recidivism / facility.extraStudents.hours50To100.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age50To60.recidivism / facility.extraStudents.hours50To100.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age50To60.recidivism / facility.extraStudents.hours50To100.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age60To70.recidivism / facility.extraStudents.hours50To100.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age60To70.recidivism / facility.extraStudents.hours50To100.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age70To80.recidivism / facility.extraStudents.hours50To100.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age70To80.recidivism / facility.extraStudents.hours50To100.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age80To90.recidivism / facility.extraStudents.hours50To100.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age80To90.recidivism / facility.extraStudents.hours50To100.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>100 - 250 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.ageUnder22.recidivism / facility.extraStudents.hours100To250.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.ageUnder22.recidivism / facility.extraStudents.hours100To250.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age22To30.recidivism / facility.extraStudents.hours100To250.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age22To30.recidivism / facility.extraStudents.hours100To250.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age30To40.recidivism / facility.extraStudents.hours100To250.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age30To40.recidivism / facility.extraStudents.hours100To250.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age40To50.recidivism / facility.extraStudents.hours100To250.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age40To50.recidivism / facility.extraStudents.hours100To250.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age50To60.recidivism / facility.extraStudents.hours100To250.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age50To60.recidivism / facility.extraStudents.hours100To250.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age60To70.recidivism / facility.extraStudents.hours100To250.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age60To70.recidivism / facility.extraStudents.hours100To250.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age70To80.recidivism / facility.extraStudents.hours100To250.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age70To80.recidivism / facility.extraStudents.hours100To250.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age80To90.recidivism / facility.extraStudents.hours100To250.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age80To90.recidivism / facility.extraStudents.hours100To250.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>250 - 500 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.ageUnder22.recidivism / facility.extraStudents.hours250To500.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.ageUnder22.recidivism / facility.extraStudents.hours250To500.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age22To30.recidivism / facility.extraStudents.hours250To500.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age22To30.recidivism / facility.extraStudents.hours250To500.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age30To40.recidivism / facility.extraStudents.hours250To500.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age30To40.recidivism / facility.extraStudents.hours250To500.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age40To50.recidivism / facility.extraStudents.hours250To500.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age40To50.recidivism / facility.extraStudents.hours250To500.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age50To60.recidivism / facility.extraStudents.hours250To500.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age50To60.recidivism / facility.extraStudents.hours250To500.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age60To70.recidivism / facility.extraStudents.hours250To500.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age60To70.recidivism / facility.extraStudents.hours250To500.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age70To80.recidivism / facility.extraStudents.hours250To500.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age70To80.recidivism / facility.extraStudents.hours250To500.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age80To90.recidivism / facility.extraStudents.hours250To500.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age80To90.recidivism / facility.extraStudents.hours250To500.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Over 500 Hours</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.ageUnder22.recidivism / facility.extraStudents.hoursOver500.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.ageUnder22.recidivism / facility.extraStudents.hoursOver500.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age22To30.recidivism / facility.extraStudents.hoursOver500.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age22To30.recidivism / facility.extraStudents.hoursOver500.ages.age22To30.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age30To40.recidivism / facility.extraStudents.hoursOver500.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age30To40.recidivism / facility.extraStudents.hoursOver500.ages.age30To40.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age40To50.recidivism / facility.extraStudents.hoursOver500.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age40To50.recidivism / facility.extraStudents.hoursOver500.ages.age40To50.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age50To60.recidivism / facility.extraStudents.hoursOver500.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age50To60.recidivism / facility.extraStudents.hoursOver500.ages.age50To60.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age60To70.recidivism / facility.extraStudents.hoursOver500.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age60To70.recidivism / facility.extraStudents.hoursOver500.ages.age60To70.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age70To80.recidivism / facility.extraStudents.hoursOver500.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age70To80.recidivism / facility.extraStudents.hoursOver500.ages.age70To80.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age80To90.recidivism / facility.extraStudents.hoursOver500.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age80To90.recidivism / facility.extraStudents.hoursOver500.ages.age80To90.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Education Attained and Length of Stay</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>None</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelNone.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Primary</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelPrimary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Secondary</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelSecondary.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Post Secondary</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelPost.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Program Participation and Length of Stay</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Academics</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.academics.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Vocational</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.vocational.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Self Development</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Social Studies</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.socialStudies.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Miscellaneous</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`95%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Class Hours and Length of Stay</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 5 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours1To5.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours5To10.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>10 - 25 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours10To25.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>25 - 50 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours25To50.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 100 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours50To100.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>100 - 250 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours100To250.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>250 - 500 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours250To500.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Over 500 Hours</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length3To6M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length6To12M.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.stayLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.misc.stayLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Education Attained and Length Between Returns</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>None</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelNone.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Primary</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelPrimary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Secondary</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelSecondary.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Post Secondary</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.levelPost.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Program Participation and Length Between Returns</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Academics</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.academics.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Vocational</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.vocational.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Self Development</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.selfDevelopment.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Social Studies</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.socialStudies.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Miscellaneous</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`95%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Class Hours and Length Between Returns</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 5 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours1To5.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours5To10.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>10 - 25 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours10To25.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>25 - 50 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours25To50.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 100 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours50To100.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>100 - 250 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours100To250.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>250 - 500 Hours</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.hours250To500.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Over 500 Hours</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length3To6M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length6To12M.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length1To2Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length2To5Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.outLengths) == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length5To10Y.recidivism / this.totals(facility.extraStudents.misc.outLengths) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={30} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Education Attained and Number of Returns</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>None</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Primary</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Secondary</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Post Secondary</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.one.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.one.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.one.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.one.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.one.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.one.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.one.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.one.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.one.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.two.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.two.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.two.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.two.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.two.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.two.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.two.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.two.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.two.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.three.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.three.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.three.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.three.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.three.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.three.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.three.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.three.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.three.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.four.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.four.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.four.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.four.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.four.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.four.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.four.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.four.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.four.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.five.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.five.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.five.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.five.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.five.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.five.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.five.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.five.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.five.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.six.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.six.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.six.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.six.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.six.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.six.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.six.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.six.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.six.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.seven.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.seven.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.seven.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.seven.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.seven.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.seven.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.seven.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.seven.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.eight.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.eight.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.eight.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.eight.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.eight.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.eight.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.eight.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.eight.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.nine.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.nine.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.nine.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.nine.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.nine.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.nine.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.nine.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.nine.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.ten.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.ten.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.ten.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.ten.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.ten.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.ten.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.ten.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.ten.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.eleven.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.eleven.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.eleven.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.eleven.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.eleven.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.eleven.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.eleven.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.eleven.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.twelve.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.twelve.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.twelve.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.twelve.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.twelve.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.twelve.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.twelve.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.twelve.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.thirteen.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.thirteen.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.thirteen.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.thirteen.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.thirteen.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.thirteen.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.thirteen.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.thirteen.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.fourteen.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.fourteen.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.fourteen.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.fourteen.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.fourteen.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.fourteen.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.fourteen.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.fourteen.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelNone.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.fifteenPlus.total / this.totals(facility.extraStudents.levelNone.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.fifteenPlus.total / this.totals(facility.extraStudents.levelNone.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPrimary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.fifteenPlus.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.fifteenPlus.total / this.totals(facility.extraStudents.levelPrimary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelSecondary.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.fifteenPlus.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.fifteenPlus.total / this.totals(facility.extraStudents.levelSecondary.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.levelPost.returns) == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.fifteenPlus.total / this.totals(facility.extraStudents.levelPost.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.fifteenPlus.total / this.totals(facility.extraStudents.levelPost.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'95%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={30} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Program Participation and Number of Returns</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Academics</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Vocational</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Self Development</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Social Studies</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Miscellaneous</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.one.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.one.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.one.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.one.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.one.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.one.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.one.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.one.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.one.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.one.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.one.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.two.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.two.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.two.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.two.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.two.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.two.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.two.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.two.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.two.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.two.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.two.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.three.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.three.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.three.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.three.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.three.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.three.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.three.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.three.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.three.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.three.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.three.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.four.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.four.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.four.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.four.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.four.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.four.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.four.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.four.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.four.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.four.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.four.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.five.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.five.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.five.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.five.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.five.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.five.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.five.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.five.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.five.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.five.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.five.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.six.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.six.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.six.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.six.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.six.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.six.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.six.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.six.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.six.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.six.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.six.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.seven.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.seven.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.seven.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.seven.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.seven.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.seven.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.seven.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.seven.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.seven.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.seven.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.eight.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.eight.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.eight.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.eight.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.eight.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.eight.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.eight.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.eight.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.eight.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.eight.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.nine.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.nine.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.nine.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.nine.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.nine.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.nine.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.nine.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.nine.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.nine.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.nine.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.ten.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.ten.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.ten.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.ten.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.ten.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.ten.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.ten.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.ten.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.ten.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.ten.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.eleven.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.eleven.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.eleven.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.eleven.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.eleven.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.eleven.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.eleven.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.eleven.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.eleven.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.eleven.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.twelve.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.twelve.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.twelve.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.twelve.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.twelve.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.twelve.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.twelve.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.twelve.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.twelve.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.twelve.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.thirteen.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.thirteen.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.thirteen.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.thirteen.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.thirteen.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.thirteen.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.thirteen.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.thirteen.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.thirteen.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.thirteen.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.fourteen.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.fourteen.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.fourteen.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.fourteen.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.fourteen.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.fourteen.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.fourteen.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.fourteen.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.fourteen.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.fourteen.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.academics.returns) == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.fifteenPlus.total / this.totals(facility.extraStudents.academics.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.fifteenPlus.total / this.totals(facility.extraStudents.academics.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.vocational.returns) == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.fifteenPlus.total / this.totals(facility.extraStudents.vocational.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.fifteenPlus.total / this.totals(facility.extraStudents.vocational.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.selfDevelopment.returns) == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.fifteenPlus.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.fifteenPlus.total / this.totals(facility.extraStudents.selfDevelopment.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.socialStudies.returns) == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.fifteenPlus.total / this.totals(facility.extraStudents.socialStudies.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.fifteenPlus.total / this.totals(facility.extraStudents.socialStudies.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.misc.returns) == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.fifteenPlus.total / this.totals(facility.extraStudents.misc.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.fifteenPlus.total / this.totals(facility.extraStudents.misc.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:'95%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={30} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Class Hours and Number of Returns</Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 5 Hours</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Hours</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>10 - 25 Hours</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>25 - 50 Hours</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 100 Hours</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>100 - 250 Hours</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>250 - 500 Hours</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Over 500 Hours</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.one.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.one.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.one.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.one.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.one.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.one.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.one.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.one.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.one.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.one.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.one.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.one.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.one.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.one.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.one.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.one.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.one.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.two.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.two.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.two.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.two.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.two.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.two.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.two.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.two.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.two.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.two.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.two.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.two.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.two.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.two.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.two.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.two.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.two.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.three.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.three.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.three.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.three.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.three.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.three.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.three.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.three.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.three.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.three.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.three.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.three.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.three.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.three.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.three.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.three.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.three.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.four.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.four.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.four.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.four.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.four.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.four.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.four.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.four.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.four.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.four.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.four.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.four.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.four.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.four.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.four.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.four.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.four.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.five.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.five.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.five.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.five.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.five.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.five.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.five.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.five.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.five.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.five.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.five.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.five.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.five.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.five.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.five.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.five.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.five.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.six.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.six.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.six.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.six.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.six.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.six.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.six.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.six.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.six.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.six.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.six.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.six.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.six.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.six.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.six.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.six.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.six.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.seven.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.seven.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.seven.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.seven.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.seven.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.seven.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.seven.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.seven.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.seven.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.seven.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.seven.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.seven.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.seven.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.seven.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.seven.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.seven.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.eight.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.eight.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.eight.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.eight.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.eight.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.eight.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.eight.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.eight.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.eight.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.eight.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.eight.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.eight.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.eight.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.eight.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.eight.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.eight.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.nine.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.nine.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.nine.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.nine.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.nine.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.nine.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.nine.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.nine.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.nine.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.nine.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.nine.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.nine.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.nine.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.nine.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.nine.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.nine.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.ten.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.ten.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.ten.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.ten.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.ten.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.ten.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.ten.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.ten.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.ten.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.ten.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.ten.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.ten.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.ten.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.ten.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.ten.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.ten.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.eleven.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.eleven.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.eleven.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.eleven.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.eleven.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.eleven.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.eleven.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.eleven.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.eleven.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.eleven.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.eleven.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.eleven.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.eleven.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.eleven.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.eleven.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.eleven.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.twelve.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.twelve.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.twelve.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.twelve.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.twelve.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.twelve.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.twelve.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.twelve.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.twelve.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.twelve.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.twelve.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.twelve.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.twelve.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.twelve.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.twelve.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.twelve.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.thirteen.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.thirteen.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.thirteen.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.thirteen.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.thirteen.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.thirteen.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.thirteen.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.thirteen.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.thirteen.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.thirteen.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.thirteen.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.thirteen.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.thirteen.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.thirteen.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.thirteen.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.thirteen.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.fourteen.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.fourteen.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.fourteen.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.fourteen.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.fourteen.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.fourteen.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.fourteen.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.fourteen.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.fourteen.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.fourteen.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.fourteen.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.fourteen.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.fourteen.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.fourteen.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.fourteen.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.fourteen.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours1To5.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.one.total / this.totals(facility.extraStudents.hours1To5.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.one.total / this.totals(facility.extraStudents.hours1To5.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours5To10.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.one.total / this.totals(facility.extraStudents.hours5To10.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.one.total / this.totals(facility.extraStudents.hours5To10.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours10To25.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.one.total / this.totals(facility.extraStudents.hours10To25.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.one.total / this.totals(facility.extraStudents.hours10To25.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours25To50.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.one.total / this.totals(facility.extraStudents.hours25To50.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.one.total / this.totals(facility.extraStudents.hours25To50.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours50To100.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.one.total / this.totals(facility.extraStudents.hours50To100.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.one.total / this.totals(facility.extraStudents.hours50To100.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours100To250.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.one.total / this.totals(facility.extraStudents.hours100To250.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.one.total / this.totals(facility.extraStudents.hours100To250.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hours250To500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.one.total / this.totals(facility.extraStudents.hours250To500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.one.total / this.totals(facility.extraStudents.hours250To500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.extraStudents.hoursOver500.returns) == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.one.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.one.total / this.totals(facility.extraStudents.hoursOver500.returns) * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.one.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>)}
          </Container>
        )
      }
}

export default Recidivism