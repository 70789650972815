import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import * as PanesStore from '../../store/reducers/panes'
import {
  Table,
  Popup,
  Label,
  Checkbox
} from 'semantic-ui-react'
const _ = require('underscore')

type InmateProps =
    { inmate: InmatesStore.Inmate, ua: any, tab: string, mode: string, i:number, r:number } &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class UARow extends React.PureComponent<InmateProps> {
    public render() {
      return (
        <Table.Row key={this.props.ua.recordID}>
          <Table.Cell>
            {this.props.r > 0 ? null : this.props.mode == 'dual' ? <Popup
              hideOnScroll
              on='click'
              trigger={<div className='link' style={{whiteSpace:'normal'}}>{this.props.inmate.lastName}, {this.props.inmate.firstName}{this.props.inmate.mi ? ` ${this.props.inmate.mi.charAt(0)}` : null}</div>}
            >
              <Popup.Header>Open in pane</Popup.Header>
              <Popup.Content>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(1, this.props.inmate.recordID) }}>1</Label>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(2, this.props.inmate.recordID) }}>2</Label>
              </Popup.Content>
            </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, this.props.inmate.recordID) }}>{this.props.inmate.lastName}, {this.props.inmate.firstName}{this.props.inmate.mi ? ` ${this.props.inmate.mi.charAt(0)}` : null}</div>}
          </Table.Cell>
          <Table.Cell>{this.props.r == 0 ? this.props.inmate.sid : null}</Table.Cell>
          <Table.Cell>{this.props.ua.facilityAbbr}</Table.Cell>
          <Table.Cell>{this.props.ua.uaDate}</Table.Cell>
          <Table.Cell>{_.filter([{value:this.props.ua.negative,text:'Negative'},{value:this.props.ua.alcohol,text:'Alcohol'},{value:this.props.ua.marijuana,text:'Marijuana'},{value:this.props.ua.cocaine,text:'Cocaine'},{value:this.props.ua.amphetamines,text:'Amphetamines'},{value:this.props.ua.methamphetamines,text:'Methamphetamines'},{value:this.props.ua.opioids,text:'Opioids'},{value:this.props.ua.benzodiazapines,text:'Benzodiazapines'},{value:this.props.ua.hallucinogens,text:'Hallucinogens'},{value:this.props.ua.inhalants,text:'Inhalants'},{value:this.props.ua.other,text:'Other'}], (ua:any) => ua.value).map((ua:any) => ua.text).join(', ')}</Table.Cell>
          <Table.Cell><Checkbox checked={this.props.ua.confirmed} /></Table.Cell>
        </Table.Row>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, ua: ownProps.ua, tab: state.drawer.tab, mode: state.panes.mode, i: ownProps.i, r: ownProps.r } },
  { ...DrawerStore.actionCreators, ...PanesStore.actionCreators }
)(UARow as any)