import React, { Fragment } from 'react'
import {
  Table,
  Container
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class Recidivism extends React.PureComponent<ReportProps> {
    private totals(object:any) {
      let total = 0
      _.each(object, (value:any,key:any) => {
        if (value && value.total) { total += value.total }
      })
      return total
    }

    private totalsRec(object:any) {
      let total = 0
      _.each(object, (value:any,key:any) => {
        if (value && value.recidivism) { total += value.recidivism }
      })
      return total
    }

    private percentage(num:number, total:number) {
      return (total == 0 ? '0' : (num / total > 1) ? '100' : (num / total > 0 && num / total < 0.01) ? '<1' : Math.round(num / total * 100)) + "%"
    }

    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let params = this.props.report.data
        let data = this.props.report.generated
        let facilitiesTotalRec = _.reduce(data.facilities, (acc:number, fac:any) => acc + Math.round(fac.recidivism), 0)

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Recidivism Report</div><div style={{marginTop:4}}>{`${data.firstTransfer} through ${data.lastTransfer}`}</div></div>
            </div>
            <div>
              <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
                <div style={{fontWeight:700,fontSize:16}}>Global</div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable style={{margin:'0 0 2em 0',height:'0%'}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell>Number of Returns</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2}>Recidivism<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Zero</div></Table.Cell>
                        <Table.Cell colSpan={2}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.zero.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.one.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.one.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.two.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.two.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.three.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.three.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.four.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.four.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.five.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.five.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.six.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.six.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.seven.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.eight.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.nine.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.ten.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.eleven.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.twelve.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.thirteen.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.fourteen.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.fifteen.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.sixteen.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.seventeen.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.eighteen.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.nineteen.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.returns.twentyPlus.total, this.totals(data.globals.returns))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell colSpan={2}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.globals.returns)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.total == 0 ? '0' : Math.round(data.globals.recidivism / data.globals.total * 100) > 100 ? '100' : Math.round(data.globals.recidivism / data.globals.total * 100)}%</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.recidivism.toFixed(0)} / {data.globals.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable style={{margin:'0 0 2em 0',height:'0%'}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell>Gender</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2}>Recidivism<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.genders.male.recidivism, this.totalsRec(data.globals.genders))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genders.male.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.genders.female.recidivism, this.totalsRec(data.globals.genders))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genders.female.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Unknown</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.genders.unknown.recidivism, this.totalsRec(data.globals.genders))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genders.unknown.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell colSpan={2}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.globals.genders)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable style={{margin:'0 0 2em 0',height:'0%'}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell>Age<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(at return)</span></Table.HeaderCell>
                        <Table.HeaderCell colSpan={2}>Recidivism<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.ages.ageUnder22.recidivism, this.totalsRec(data.globals.ages))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.ageUnder22.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.ages.age22To30.recidivism, this.totalsRec(data.globals.ages))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age22To30.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.ages.age30To40.recidivism, this.totalsRec(data.globals.ages))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age30To40.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.ages.age40To50.recidivism, this.totalsRec(data.globals.ages))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age40To50.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.ages.age50To60.recidivism, this.totalsRec(data.globals.ages))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age50To60.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.ages.age60To70.recidivism, this.totalsRec(data.globals.ages))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age60To70.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.ages.age70To80.recidivism, this.totalsRec(data.globals.ages))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age70To80.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.ages.age80To90.recidivism, this.totalsRec(data.globals.ages))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age80To90.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell colSpan={2}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.globals.ages)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`${90/3}%`}}>
                  <Table compact celled striped unstackable style={{margin:'0 0 2em 0',height:'0%'}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell>Length of Stay</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2}>Recidivism<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.stayLengths.lengthUnder3M.recidivism, this.totalsRec(data.globals.stayLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.stayLengths.length3To6M.recidivism, this.totalsRec(data.globals.stayLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.stayLengths.length6To12M.recidivism, this.totalsRec(data.globals.stayLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.stayLengths.length1To2Y.recidivism, this.totalsRec(data.globals.stayLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.stayLengths.length2To5Y.recidivism, this.totalsRec(data.globals.stayLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.stayLengths.length5To10Y.recidivism, this.totalsRec(data.globals.stayLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell colSpan={2}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.globals.stayLengths)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable style={{margin:'0 0 2em 0',height:'0%'}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell>Length Between Returns</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2}>Recidivism<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.outLengths.lengthUnder3M.recidivism, this.totalsRec(data.globals.outLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.outLengths.length3To6M.recidivism, this.totalsRec(data.globals.outLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.outLengths.length6To12M.recidivism, this.totalsRec(data.globals.outLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.outLengths.length1To2Y.recidivism, this.totalsRec(data.globals.outLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.outLengths.length2To5Y.recidivism, this.totalsRec(data.globals.outLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.percentage(data.globals.outLengths.length5To10Y.recidivism, this.totalsRec(data.globals.outLengths))}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell colSpan={2}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.globals.outLengths)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`40%`,fontSize:'0.9em'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Length of Stay and Gender<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.stayLengths.lengthUnder3M.recidivism, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.stayLengths.length3To6M.recidivism, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.stayLengths.length6To12M.recidivism, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.stayLengths.length1To2Y.recidivism, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.stayLengths.length2To5Y.recidivism, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.stayLengths.length5To10Y.recidivism, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.genders.male.stayLengths)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.genders.female.stayLengths)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + this.totalsRec(group.stayLengths), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Length Between Returns and Gender<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.outLengths.lengthUnder3M.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.outLengths.length3To6M.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.outLengths.length6To12M.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.outLengths.length1To2Y.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.outLengths.length2To5Y.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.outLengths.length5To10Y.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.genders.male.outLengths)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.genders.female.outLengths)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + this.totals(group.outLengths), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Age and Gender<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events, at return)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.genders.male.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.genders.female.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.ageUnder22.genders)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.genders.male.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.genders.female.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age22To30.genders)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.genders.male.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.genders.female.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age30To40.genders)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.genders.male.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.genders.female.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age40To50.genders)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.genders.male.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.genders.female.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age50To60.genders)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.genders.male.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.genders.female.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age60To70.genders)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.genders.male.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.genders.female.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age70To80.genders)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.genders.male.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.genders.female.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age80To90.genders)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.genders.male.recidivism, 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.genders.female.recidivism, 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + this.totalsRec(group.genders), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:`40%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Number of Returns and Gender<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Zero</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.zero.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.zero.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.zero.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.one.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.two.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.three.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.four.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.five.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.six.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.seven.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.eight.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.nine.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.ten.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.eleven.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.twelve.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.thirteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.fourteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.fifteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.sixteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.seventeen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.eighteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.nineteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + group.returns.twentyPlus.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.genders.male.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.genders.female.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.genders.male,data.genders.female], (acc:number, group:any) => acc + this.totals(group.returns), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:`95%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={7} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Age and Length of Stay<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events, at return)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.ageUnder22.stayLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age22To30.stayLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age30To40.stayLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age40To50.stayLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age50To60.stayLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age60To70.stayLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age70To80.stayLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(data.ages.age80To90.stayLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.stayLengths.lengthUnder3M.recidivism, 0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.stayLengths.length3To6M.recidivism, 0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.stayLengths.length6To12M.recidivism, 0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.stayLengths.length1To2Y.recidivism, 0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.stayLengths.length2To5Y.recidivism, 0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.stayLengths.length5To10Y.recidivism, 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + this.totalsRec(group.stayLengths), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={7} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Age and Length Between Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events, at return)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.ageUnder22.outLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age22To30.outLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age30To40.outLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age40To50.outLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age50To60.outLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age60To70.outLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age70To80.outLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age80To90.outLengths)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.outLengths.lengthUnder3M.total, 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.outLengths.length3To6M.total, 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.outLengths.length6To12M.total, 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.outLengths.length1To2Y.total, 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.outLengths.length2To5Y.total, 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.outLengths.length5To10Y.total, 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + this.totals(group.outLengths), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'95%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={15} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Age and Number of Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(individuals, at return)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Zero</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.zero.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.zero.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.zero.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.zero.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.zero.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.zero.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.zero.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.zero.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.zero.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.one.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.one.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.two.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.two.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.three.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.three.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.four.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.four.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.five.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.five.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.six.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.six.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.seven.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.eight.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.nine.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.ten.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.eleven.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.twelve.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.thirteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.fourteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.fifteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.sixteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.seventeen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.eighteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.nineteen.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + group.returns.twentyPlus.total, 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.ageUnder22.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age22To30.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age30To40.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age40To50.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age50To60.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age60To70.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age70To80.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(data.ages.age80To90.returns)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce([data.ages.ageUnder22,data.ages.age22To30,data.ages.age30To40,data.ages.age40To50,data.ages.age50To60,data.ages.age60To70,data.ages.age70To80,data.ages.age80To90], (acc:number, group:any) => acc + this.totals(group.returns), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:'40%'}}>
                  <Table compact celled striped unstackable style={{margin:'0 0 2em 0',height:'0%'}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell>Facility</Table.HeaderCell>
                        <Table.HeaderCell colSpan={2}>Recidivism<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {_.map(_.filter(data.facilities, (f:any) => f.total > 0), (facility:any) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facilitiesTotalRec == 0 ? '0' : Math.round(facility.recidivism / facilitiesTotalRec * 100) > 100 ? '100' : Math.round(facility.recidivism / facilitiesTotalRec * 100)}%</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.recidivism.toFixed(0)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                        <Table.Cell colSpan={2}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facilitiesTotalRec}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
                <div style={{flexBasis:'40%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={3} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Facility and Gender<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {_.map(_.filter(data.facilities, (facility:any) => this.totalsRec(facility.genders).toFixed(0) != '0'), (facility:any, i:number) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genders.male.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genders.female.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(facility.genders)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.genders.male.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.genders.female.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + this.totalsRec(facility.genders), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'95%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={9} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Facility and Age<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events, at return)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {_.map(_.filter(data.facilities, (facility:any) => this.totalsRec(facility.ages).toFixed(0) != '0'), (facility:any, i:number) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.ageUnder22.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age22To30.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age30To40.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age40To50.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age50To60.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age60To70.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age70To80.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age80To90.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(facility.ages)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.ages.ageUnder22.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.ages.age22To30.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.ages.age30To40.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.ages.age40To50.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.ages.age50To60.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.ages.age60To70.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.ages.age70To80.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.ages.age80To90.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + this.totalsRec(facility.ages), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around',pageBreakAfter:'always'}}>
                <div style={{flexBasis:'95%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={7} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Facility and Length of Stay<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {_.map(_.filter(data.facilities, (facility:any) => this.totalsRec(facility.stayLengths).toFixed(0) != '0'), (facility:any, i:number) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.lengthUnder3M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length3To6M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length6To12M.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length1To2Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length2To5Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length5To10Y.recidivism.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totalsRec(facility.stayLengths)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.stayLengths.lengthUnder3M.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.stayLengths.length3To6M.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.stayLengths.length6To12M.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.stayLengths.length1To2Y.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.stayLengths.length2To5Y.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.stayLengths.length5To10Y.recidivism), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + this.totalsRec(facility.stayLengths), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={7} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Facility and Length Between Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {_.map(_.filter(data.facilities, (facility:any) => this.totalsRec(facility.outLengths).toFixed(0) != '0'), (facility:any, i:number) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.outLengths)}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.outLengths.lengthUnder3M.total), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.outLengths.length3To6M.total), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.outLengths.length6To12M.total), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.outLengths.length1To2Y.total), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.outLengths.length2To5Y.total), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.outLengths.length5To10Y.total), 0)}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + this.totals(facility.outLengths), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:'95%'}}>
                  <Table compact celled striped unstackable definition style={{margin:'0',height:'0%',border:0,fontSize:'0.8em'}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell colSpan={16} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Facility and Number of Returns<br/><span style={{fontWeight:400,fontSize:'0.8em'}}>(events)</span></Table.HeaderCell>
                      </Table.Row>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Table.HeaderCell key={facility.abbreviation} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation}</Table.HeaderCell>)}
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Totals</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Zero</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.zero.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.zero.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.one.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.one.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.two.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.two.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.three.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.three.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.four.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.four.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.five.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.five.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.six.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.six.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.seven.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.seven.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.eight.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.eight.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.nine.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.nine.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.ten.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.ten.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.eleven.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.twelve.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.thirteen.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.fourteen.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.fifteen.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.fifteen.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Sixteen</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.sixteen.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.sixteen.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seventeen</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.seventeen.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.seventeen.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eighteen</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.eighteen.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.eighteen.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nineteen</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.nineteen.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.nineteen.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twenty and Over</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.twentyPlus.total.toFixed(0)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + Math.round(facility.returns.twentyPlus.total), 0)}</div></Table.Cell>
                      </Table.Row>
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        {_.map(_.filter(data.facilities, (facility:any) => this.totals(facility.returns).toFixed(0) != '0'), (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                          <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{this.totals(facility.returns)}</div></Table.Cell>
                        </Fragment>)}
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{_.reduce(data.facilities, (acc:number, facility:any) => acc + this.totals(facility.returns), 0)}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </Container>
        )
      }
}

export default Recidivism