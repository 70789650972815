import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Container,
  Checkbox,
  Button,
  Input,
  Select,
  Grid,
  Table,
  Header
} from 'semantic-ui-react'
const _ = require('underscore')

type MenuProps =
    { menu: any } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class MenuItems extends React.PureComponent<MenuProps> {
    public componentDidMount() {
        this.props.fetchMenuItems()
    }

    public render() {
      const getMenuItems = () => {
          return _.filter(this.props.menu.items, (item:any) => { return item.category == this.props.menu.category && item.itemOrder > 0 })
      }

      const getMenuTitles = () => {
          return _.filter(this.props.menu.items, (item:any) => { return item.itemOrder == 0 })
      }

      const getMenuOptions = () => {
          return _.map(getMenuTitles(), (data:any) => { return { key: data.category, value: data.category, text: data.item }})
      }

      const onDragEnd = (result:any) => {
          var {draggableId, source, destination} = result
          var newItems = getMenuItems()
          var item = _.find(newItems, (data:any) => { return data.recordID == draggableId })
          newItems = _.filter(newItems, (data:any) => { return data.recordID != draggableId })
          newItems.splice(destination.index, 0, item)
          this.props.reorderMenuItems(newItems)
      }

      return (
        <Container fluid style={{overflowY:'auto',height:740,paddingRight:5}}>
          <Grid>
            <Grid.Column width={16}>
              <Header size='large'>Menu Items</Header>
              <Select
                options={getMenuOptions()}
                onChange={(e:any, data:any) => this.props.changeMenuCategory(data.value as string)}
                value={this.props.menu.category}
              />
              <Button negative content="Remove Category" onClick={_.debounce(this.props.removeMenuCategory, 10000, true)} style={{marginLeft:10}} />
              <Input name="code" placeholder="New Category Code" value={this.props.menu.new.category.code} onChange={(e:any, data:any) => this.props.changeNewMenuCategory('code', data.value.validate())} style={{marginLeft:20}} maxLength={3} />
              <Input name="name" placeholder="New Category Name" value={this.props.menu.new.category.name} onChange={(e:any, data:any) => this.props.changeNewMenuCategory('name', data.value.validate())} style={{marginLeft:10}} />
              <Select name="department" placeholder="New Category Program Type" options={this.props.menu.options.departments} value={this.props.menu.new.category.department} onChange={(e:any, data:any) => this.props.changeNewMenuCategory('department', data.value as number)} style={{marginLeft:10}} />
              <Button positive content="Add Category" onClick={_.debounce(this.props.addMenuCategory, 10000, true)} style={{marginLeft:10}} />
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'0'}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Table compact celled striped style={{ marginTop: "1em" }}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={1}></Table.HeaderCell>
                            <Table.HeaderCell width={1}>Item Code</Table.HeaderCell>
                            <Table.HeaderCell>Item</Table.HeaderCell>
                            <Table.HeaderCell width={1}>Active</Table.HeaderCell>
                            <Table.HeaderCell width={2} colSpan={2}>Actions</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {getMenuItems().map((data:any, i:number) => (
                            <Fragment key={data.recordID}>
                              <Draggable draggableId={data.recordID.toString()} index={i}>
                                {(provided) => (
                                  <tr ref={provided.innerRef} {...provided.draggableProps}>
                                    <Table.Cell {...provided.dragHandleProps} style={{textAlign:'center'}}><i className="icon bars" /></Table.Cell>
                                    <Table.Cell><Input readOnly value={data.itemCode} recordid={data.recordID} name="itemCode" onChange={(e:any, d:any) => this.props.updateItemField(data.recordID, 'itemCode', d.value.validate())} /></Table.Cell>
                                    <Table.Cell><Input value={data.item} recordid={data.recordID} name="item" onChange={(e:any, d:any) => this.props.updateItemField(data.recordID, 'item', d.value.validate())} style={{width:'100%'}} /></Table.Cell>
                                    <Table.Cell><Checkbox checked={data.active} recordid={data.recordID} name="active" onChange={(e:any, d:any) => this.props.updateItemField(data.recordID, 'active', d.checked as boolean)} /></Table.Cell>
                                    <Table.Cell style={{textAlign:'center'}}>
                                      <Button positive content="Save" size="mini" onClick={_.debounce(() => this.props.saveMenuItem(data.recordID), 10000, true)} />
                                    </Table.Cell>
                                    <Table.Cell style={{textAlign:'center'}}>
                                      <Button negative content="Remove" size="mini" onClick={_.debounce(() => this.props.removeMenuItem(data.recordID), 10000, true)} />
                                    </Table.Cell>
                                  </tr>
                                )}
                              </Draggable>
                            </Fragment>
                          ))}
                          {this.props.menu.category == '' ? null :
                            <Table.Row>
                              <Table.Cell></Table.Cell>
                              <Table.Cell><Input value={this.props.menu.new.item.code} name="code" placeholder="New Item Code" onChange={(e:any, data:any) => this.props.changeNewMenuItem('code', data.value.validate())} /></Table.Cell>
                              <Table.Cell><Input value={this.props.menu.new.item.name} name="name" placeholder="New Item Name" onChange={(e:any, data:any) => this.props.changeNewMenuItem('name', data.value.validate())} style={{width:'100%'}} /></Table.Cell>
                              <Table.Cell><Checkbox checked={this.props.menu.new.item.active} onChange={(e:any, data:any) => this.props.changeNewMenuItem('active', data.checked as boolean)} /></Table.Cell>
                              <Table.Cell style={{textAlign:'center'}}><Button positive content="Save" size="mini" onClick={_.debounce(this.props.addMenuItem, 10000, true)} /></Table.Cell>
                              <Table.Cell />
                            </Table.Row>
                          }
                        </Table.Body>
                      </Table>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid.Column>
          </Grid>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { menu: state.admin.menu } },
  AdminStore.actionCreators
)(MenuItems as any)