import React, { Fragment } from 'react'
import { format } from 'date-fns'
import {
  Image,
  Container,
  Table,
  Divider
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { data: any, params: any, options: any, settings: any }

class EducationalHistoryReport extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <html lang="en" style={{height:'auto'}}>
            <head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Roboto') || document.fonts.check('bold 1em Roboto'))
              }`}} />
            </head>
            <body style={{fontSize:'1.2em',lineHeight:'1.2em',fontFamily:'Roboto',backgroundColor:'white !important',height:'auto'}}>
              {this.report()}
            </body>
          </html>
        )
    }

    public report() {
        let data = this.props.data
        let params = this.props.params

        return (
            <Container fluid>
              <div style={{marginLeft:30,marginRight:30}}>
                <div>
                  <div style={{flexDirection:'row'}}>
                    <Image src="https://sms.psd-hi.com/assets/HawaiiSeal.png" style={{float:'left',width:70}} />
                    <Image src="https://sms.psd-hi.com/assets/RightGraphic.jpg" style={{float:'right',width:70}} />
                    <div style={{textAlign:'center',fontWeight:700}}>
                      <div>State of Hawai‘i</div>
                      <div>{this.props.settings.deptName}</div>
                      <div>Education Services</div>
                      <div>{format(new Date(), "MM/dd/yyyy")}</div>
                      <div style={{marginTop:15,fontSize:'1.2em'}}>{data.inmate.inmateName}</div>
                    </div>
                  </div>
                  {params.info.includes('intake') || params.info.includes('all') ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Intake Information</div>
                    <Table compact celled striped definition unstackable>
                      <Table.Body>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={4} style={{fontWeight:400}}>DoB</Table.Cell>
                          <Table.Cell>{data.inmate.dob}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={4} style={{fontWeight:400}}>SSN</Table.Cell>
                          <Table.Cell>***-**-{data.inmate.ssn ? data.inmate.ssn.slice(-4) : '****'}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={4} style={{fontWeight:400}}>SID</Table.Cell>
                          <Table.Cell>{data.inmate.sid}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={4} style={{fontWeight:400}}>Sex</Table.Cell>
                          <Table.Cell>{data.inmate.sex}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={4} style={{fontWeight:400}}>Race</Table.Cell>
                          <Table.Cell>{data.inmate.race}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={4} style={{fontWeight:400}}>Ethnicity</Table.Cell>
                          <Table.Cell>{data.inmate.ethnicityOther ? data.inmate.ethnicityOther : data.inmate.ethnicity}</Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {(params.info.includes('incarceration') || params.info.includes('all')) && (data.incarceration.length > 0 || (data.incarceration.length == 0 && params.empty)) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Facility Entry Dates</div>
                    <Table compact celled striped unstackable>
                      <Table.Header>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.HeaderCell width={4}>Facility</Table.HeaderCell>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {data.incarceration.length == 0 && params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell colSpan={2}>No Data Available</Table.Cell>
                        </Table.Row> : _.map(data.incarceration, (transfer:any, i:number) => (<Table.Row key={i} style={{breakInside:'avoid'}}>
                          <Table.Cell>{transfer.facilityAbbr}</Table.Cell>
                          <Table.Cell>{transfer.transferDate}</Table.Cell>
                        </Table.Row>))}
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {params.info.includes('education') || params.info.includes('all') ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Educational History</div>
                    <Table compact celled striped definition unstackable>
                      <Table.Body>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>High School Diploma/HSE Verified</Table.Cell>
                          <Table.Cell>{data.inmate.diplomaGEDverified === "Y" ? "Yes" : data.inmate.diplomaGEDverified === "N" ?  "No" : "Unknown"}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>High School Info Verified</Table.Cell>
                          <Table.Cell>{data.inmate.hSinfoVerified === "Y" ?  "Yes" : data.inmate.hSinfoVerified === "N" ? "No" : "Unknown"}</Table.Cell>
                        </Table.Row>
                        {data.inmate.highGradeCompleted && data.inmate.highGradeCompleted.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Highest Grade Completed</Table.Cell>
                          <Table.Cell>{data.inmate.highGradeCompleted ? data.inmate.highGradeCompleted : ""}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.lastHS && data.inmate.lastHS.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Last High School Attended</Table.Cell>
                          <Table.Cell>{data.inmate.lastHS ? data.inmate.lastHS : ""}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.lastHSlocation && data.inmate.lastHSlocation.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Location</Table.Cell>
                          <Table.Cell>{data.inmate.lastHSlocation ? data.inmate.lastHSlocation : ""}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.graduationDate && data.inmate.graduationDate.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>High School Grad Date or HSE Date</Table.Cell>
                          <Table.Cell>{data.inmate.graduationDate ? data.inmate.graduationDate : ""}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.gradType && data.inmate.gradType.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Graduation Type</Table.Cell>
                          <Table.Cell>{data.inmate.gradType == 'G' ? "GED" : data.inmate.gradType == 'D' ? "Diploma" : data.inmate.gradType == 'H' ? "HSE" : data.inmate.gradType == 'C' ? "CBCSDP" : ""}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.diplomaNumber && data.inmate.diplomaNumber.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Diploma / HSE Number</Table.Cell>
                          <Table.Cell>{data.inmate.diplomaNumber ? data.inmate.diplomaNumber : ""}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.gedFrom && data.inmate.gedFrom.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Diploma / HSE From</Table.Cell>
                          <Table.Cell>{data.inmate.gedFrom ? data.inmate.gedFrom : ""}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.gedFacility && data.inmate.gedFacility.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>HSE Completed While At</Table.Cell>
                          <Table.Cell>{data.inmate.gedFacility ? data.inmate.gedFacility : ""}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.edRemarks && data.inmate.edRemarks.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Remarks</Table.Cell>
                          <Table.Cell>{data.inmate.edRemarks ? data.inmate.edRemarks : ""}</Table.Cell>
                        </Table.Row> : null}
                      </Table.Body>
                    </Table>
                    {data.inmate.colleges.length > 0 || (data.inmate.colleges.length == 0 && params.empty) ? <Table compact celled striped unstackable style={{marginTop:10}}>
                      <Table.Header>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.HeaderCell>College</Table.HeaderCell>
                          <Table.HeaderCell>Location</Table.HeaderCell>
                          <Table.HeaderCell>Major</Table.HeaderCell>
                          <Table.HeaderCell>Degree</Table.HeaderCell>
                          <Table.HeaderCell>Grad Year</Table.HeaderCell>
                          <Table.HeaderCell>Verified</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {data.inmate.colleges.length == 0 && params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell colSpan={6}>No Data Available</Table.Cell>
                        </Table.Row> : _.map(data.inmate.colleges, (college:any, i:number) => (<Table.Row key={i} style={{breakInside:'avoid'}}>
                          <Table.Cell>{college.college}</Table.Cell>
                          <Table.Cell>{college.location}</Table.Cell>
                          <Table.Cell>{college.major}</Table.Cell>
                          <Table.Cell>{college.degree}</Table.Cell>
                          <Table.Cell>{college.gradYear}</Table.Cell>
                          <Table.Cell>{college.verified ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>))}
                      </Table.Body>
                    </Table> : null}
                  </div> : null}
                  {(params.info.includes('employment') || params.info.includes('all')) && (data.inmate.jobs.length > 0 || (data.inmate.jobs.length == 0 && params.empty)) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Employment History</div>
                    <Table compact celled striped unstackable>
                      <Table.Header>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.HeaderCell>Title</Table.HeaderCell>
                          <Table.HeaderCell>Company</Table.HeaderCell>
                          <Table.HeaderCell>Location</Table.HeaderCell>
                          <Table.HeaderCell>Status</Table.HeaderCell>
                          <Table.HeaderCell>Dates</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {data.inmate.jobs.length == 0 && params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell colSpan={5}>No Data Available</Table.Cell>
                        </Table.Row> : _.map(data.inmate.jobs, (job:any, i:number) => (<Table.Row key={i} style={{breakInside:'avoid'}}>
                          <Table.Cell>{job.title}</Table.Cell>
                          <Table.Cell>{job.company}</Table.Cell>
                          <Table.Cell>{job.location}</Table.Cell>
                          <Table.Cell>{job.status}</Table.Cell>
                          <Table.Cell>{job.startDate ? job.startDate : ''} - {job.current ? 'Present' : job.endDate ? job.endDate : ''}</Table.Cell>
                        </Table.Row>))}
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {((data.inmate.licenses && data.inmate.licenses.length > 0) || (data.inmate.commLicenseClass && data.inmate.commLicenseType && data.inmate.commLicenseClass.length > 0 && data.inmate.commLicenseType.length > 0) || (data.inmate.commLicenseNumber && data.inmate.commLicenseType && data.inmate.commLicenseNumber.length > 0 && data.inmate.commLicenseType.length > 0) || (data.inmate.commLicenseCompany && data.inmate.commLicenseType && data.inmate.commLicenseCompany.length > 0 && data.inmate.commLicenseType.length > 0) || (data.inmate.endorsements && data.inmate.commLicenseType && data.inmate.endorsements.length > 0 && data.inmate.commLicenseType.length > 0) || (data.inmate.restrictions && data.inmate.commLicenseType && data.inmate.restrictions.length > 0 && data.inmate.commLicenseType.length > 0) || params.empty) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>License Information</div>
                    <Table compact celled striped definition unstackable>
                      <Table.Body>
                        {data.inmate.licenses && data.inmate.licenses.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Prof. & Vocational Licenses</Table.Cell>
                          <Table.Cell>{data.inmate.licenses && data.inmate.licenses.length > 0 ? data.inmate.licenses.join(', ') : "None"}</Table.Cell>
                        </Table.Row> : null}
                        {(data.inmate.licenses && data.inmate.licenses.length > 0 || params.empty) && (data.inmate.commLicenseType && data.inmate.commLicenseType.length > 0 || params.empty) ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Commercial License Type</Table.Cell>
                          <Table.Cell>{data.inmate.commLicenseType == "CDL" || data.inmate.commLicenseType == "CLP" ? data.inmate.commLicenseType : "None"}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.commLicenseClass && data.inmate.commLicenseType && data.inmate.commLicenseClass.length > 0 && data.inmate.commLicenseType.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>License Class</Table.Cell>
                          <Table.Cell>{data.inmate.commLicenseClass == "A" || data.inmate.commLicenseClass == "B" || data.inmate.commLicenseClass == "C" ? `Class ${data.inmate.commLicenseClass}` : "None"}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.commLicenseNumber && data.inmate.commLicenseType && data.inmate.commLicenseNumber.length > 0 && data.inmate.commLicenseType.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>License Number</Table.Cell>
                          <Table.Cell>{data.inmate.commLicenseNumber ? data.inmate.commLicenseNumber : "None"}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.commLicenseCompany && data.inmate.commLicenseType && data.inmate.commLicenseCompany.length > 0 && data.inmate.commLicenseType.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>License Company</Table.Cell>
                          <Table.Cell>{data.inmate.commLicenseCompany ? data.inmate.commLicenseCompany : "None"}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.endorsements && data.inmate.commLicenseType && data.inmate.endorsements.length > 0 && data.inmate.commLicenseType.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>License Endorsements</Table.Cell>
                          <Table.Cell>{data.inmate.endorsements && data.inmate.endorsements.length > 0 ? data.inmate.endorsements.join(', ') : "None"}</Table.Cell>
                        </Table.Row> : null}
                        {data.inmate.restrictions && data.inmate.commLicenseType && data.inmate.restrictions.length > 0 && data.inmate.commLicenseType.length > 0 || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>License Restrictions</Table.Cell>
                          <Table.Cell>{data.inmate.restrictions && data.inmate.restrictions.length > 0 ? data.inmate.restrictions.join(', ') : "None"}</Table.Cell>
                        </Table.Row> : null}
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {(params.info.includes('community') || params.info.includes('all')) && (data.inmate.alimony == "T" || data.inmate.libraryCard || data.inmate.registeredToVote || data.inmate.currentDriversLic || data.inmate.currentDriversLicExp || data.inmate.licRevoked || data.inmate.noFreeTime || data.inmate.surf || data.inmate.fishHunt || data.inmate.hobby || data.inmate.watchTV || data.inmate.sportsWorkOut || data.inmate.read || data.inmate.computers || data.inmate.otherFTAwhat) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Community Involvement</div>
                    <Table compact celled striped unstackable>
                      <Table.Body>
                        <Table.Row style={{breakInside:'avoid'}}>
                          {data.inmate.alimony == "T" ? <Table.Cell>Alimony</Table.Cell> : null}
                          {data.inmate.libraryCard ? <Table.Cell>Library card</Table.Cell> : null}
                          {data.inmate.registeredToVote ? <Table.Cell>Registered to vote</Table.Cell> : null}
                          {data.inmate.currentDriversLic ? <Table.Cell>Current driver's license</Table.Cell> : null}
                          {data.inmate.currentDriversLicExp ? <Table.Cell>License expires: {data.inmate.currentDriversLicExp}</Table.Cell> : null}
                          {data.inmate.licRevoked ? <Table.Cell>License revoked</Table.Cell> : null}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Free Time Activities</div>
                    <Table compact celled striped unstackable>
                      <Table.Body>
                        <Table.Row style={{breakInside:'avoid'}}>
                          {data.inmate.noFreeTime ? <Table.Cell>No free time</Table.Cell> : null}
                          {data.inmate.surf ? <Table.Cell>Surf</Table.Cell> : null}
                          {data.inmate.fishHunt ? <Table.Cell>Fish/Hunt</Table.Cell> : null}
                          {data.inmate.hobby ? <Table.Cell>Hobby</Table.Cell> : null}
                          {data.inmate.watchTV ? <Table.Cell>Watch TV</Table.Cell> : null}
                          {data.inmate.sportsWorkOut ? <Table.Cell>Sports/Workout</Table.Cell> : null}
                          {data.inmate.read ? <Table.Cell>Read</Table.Cell> : null}
                          {data.inmate.computers ? <Table.Cell>Computers</Table.Cell> : null}
                          {data.inmate.otherFTAwhat ? <Table.Cell>Other: {data.inmate.otherFTAwhat}</Table.Cell> : null}
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {(params.info.includes('health') || params.info.includes('all')) && (data.inmate.improvements.length > 0 || (data.inmate.improvements.length == 0 && params.empty)) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Self Improvement</div>
                    <Table compact celled striped unstackable>
                      <Table.Header>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.HeaderCell>Type</Table.HeaderCell>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {data.inmate.improvements.length == 0 && params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell colSpan={2}>No Data Available</Table.Cell>
                        </Table.Row> : _.map(data.inmate.improvements, (improvement:any, i:number) => (<Table.Row key={i} style={{breakInside:'avoid'}}>
                          <Table.Cell>{improvement.type}</Table.Cell>
                          <Table.Cell>{improvement.date}</Table.Cell>
                        </Table.Row>))}
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {(params.info.includes('health') || params.info.includes('all')) && (data.inmate.checkups.length > 0 || (data.inmate.checkups.length == 0 && params.empty)) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Checkups</div>
                    <Table compact celled striped unstackable>
                      <Table.Header>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.HeaderCell>Type</Table.HeaderCell>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {data.inmate.checkups.length == 0 && params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell colSpan={2}>No Data Available</Table.Cell>
                        </Table.Row> : _.map(data.inmate.checkups, (checkup:any, i:number) => (<Table.Row key={i} style={{breakInside:'avoid'}}>
                          <Table.Cell>{checkup.type}</Table.Cell>
                          <Table.Cell>{checkup.date}</Table.Cell>
                        </Table.Row>))}
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {params.info.includes('health') || params.info.includes('all') ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Health History</div>
                    <Table compact celled striped definition unstackable>
                      <Table.Body>
                        {data.inmate.hPWhat || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Health problems</Table.Cell>
                          <Table.Cell>{data.inmate.hPWhat ? data.inmate.hPWhat : ""}</Table.Cell>
                        </Table.Row> : null}
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Hearing impaired</Table.Cell>
                          <Table.Cell>{data.inmate.hearingImpaired ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Visually impaired</Table.Cell>
                          <Table.Cell>{data.inmate.visuallyImpaired ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Wheelchair</Table.Cell>
                          <Table.Cell>{data.inmate.wheelchair ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>ADHD / ADD</Table.Cell>
                          <Table.Cell>{data.inmate.add ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Seizure disorder</Table.Cell>
                          <Table.Cell>{data.inmate.seizure ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Diabetic</Table.Cell>
                          <Table.Cell>{data.inmate.diabetic ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Frequent restroom</Table.Cell>
                          <Table.Cell>{data.inmate.frequentRestroom ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Physical limitation</Table.Cell>
                          <Table.Cell>{data.inmate.physicalLimitation ? "Yes" : "No"}</Table.Cell>
                        </Table.Row>
                        {data.inmate.disabilityExplanation || params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell width={8} style={{fontWeight:400}}>Disability explanation</Table.Cell>
                          <Table.Cell>{data.inmate.disabilityExplanation ? data.inmate.disabilityExplanation : ""}</Table.Cell>
                        </Table.Row> : null}
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {(params.info.includes('recommendations') || params.info.includes('all')) && (data.inmate.joinFurlough || data.inmate.joinHealth || data.inmate.joinHSE || data.inmate.verifyDiplomaHSE || data.inmate.joinWorkline || data.inmate.joinPrep || data.inmate.joinCTE || data.inmate.joinAANA || data.inmate.joinStressManagement || data.inmate.joinCogSkills || data.inmate.joinSocialSkills || data.inmate.joinParenting || data.inmate.joinDomesticViolence || data.inmate.joinSOTP || data.inmate.joinSubstanceAbuse || data.inmate.joinSAL2 || data.inmate.joinSAL3 || data.inmate.joinOther) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Recommended Programs</div>
                    <Table compact celled striped unstackable>
                      <Table.Body>
                        {data.inmate.joinHSE || data.inmate.verifyDiplomaHSE || data.inmate.joinWorkline || data.inmate.joinPrep || data.inmate.joinCTE || data.inmate.joinFurlough ? <Table.Row style={{breakInside:'avoid'}}>
                          {data.inmate.joinHSE ? <Table.Cell>Obtain HSE</Table.Cell> : null}
                          {data.inmate.verifyDiplomaHSE ? <Table.Cell>Verify Diploma or HSE</Table.Cell> : null}
                          {data.inmate.joinWorkline ? <Table.Cell>Available workline</Table.Cell> : null}
                          {data.inmate.joinPrep ? <Table.Cell>Pre-employment prep</Table.Cell> : null}
                          {data.inmate.joinCTE ? <Table.Cell>Career & Technical Education</Table.Cell> : null}
                          {data.inmate.joinFurlough ? <Table.Cell>Work Furlough</Table.Cell> : null}
                        </Table.Row> : null}
                        {data.inmate.joinAANA || data.inmate.joinStressManagement || data.inmate.joinCogSkills || data.inmate.joinSocialSkills || data.inmate.joinParenting || data.inmate.joinDomesticViolence || data.inmate.joinSOTP ? <Table.Row style={{breakInside:'avoid'}}>
                          {data.inmate.joinAANA ? <Table.Cell>AA / NA</Table.Cell> : null}
                          {data.inmate.joinStressManagement ? <Table.Cell>Stress Management</Table.Cell> : null}
                          {data.inmate.joinCogSkills ? <Table.Cell>Cognitive Skills</Table.Cell> : null}
                          {data.inmate.joinSocialSkills ? <Table.Cell>Social Skills</Table.Cell> : null}
                          {data.inmate.joinParenting ? <Table.Cell>Parenting</Table.Cell> : null}
                          {data.inmate.joinDomesticViolence ? <Table.Cell>Domestic Violence</Table.Cell> : null}
                          {data.inmate.joinSOTP ? <Table.Cell>SOTP</Table.Cell> : null}
                        </Table.Row> : null}
                        {data.inmate.joinSubstanceAbuse || data.inmate.joinSAL2 || data.inmate.joinSAL3 || data.inmate.joinHealth || data.inmate.joinOther ? <Table.Row style={{breakInside:'avoid'}}>
                          {data.inmate.joinSubstanceAbuse ? <Table.Cell>Substance Abuse</Table.Cell> : null}
                          {data.inmate.joinSAL2 ? <Table.Cell>Substance Abuse Level II</Table.Cell> : null}
                          {data.inmate.joinSAL3 ? <Table.Cell>Substance Abuse Level III (T.C)</Table.Cell> : null}
                          {data.inmate.joinHealth ? <Table.Cell>Mental Health</Table.Cell> : null}
                          {data.inmate.joinOther ? <Table.Cell>Other: {data.inmate.otherProgramWhat}</Table.Cell> : null}
                        </Table.Row> : null}
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {(params.info.includes('classes') || params.info.includes('all')) && (data.classes.length > 0 || (data.classes.length == 0 && params.empty)) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Active Classes</div>
                    <Table compact celled striped unstackable>
                      <Table.Header>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.HeaderCell>Class Name</Table.HeaderCell>
                          <Table.HeaderCell>Instructor</Table.HeaderCell>
                          <Table.HeaderCell>Start Date</Table.HeaderCell>
                          <Table.HeaderCell>Hours to Date</Table.HeaderCell>
                          <Table.HeaderCell>Hours Required</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {data.classes.length == 0 && params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell colSpan={5}>No Data Available</Table.Cell>
                        </Table.Row> : _.map(data.classes, (klass:any, i:number) => (<Table.Row key={i} style={{breakInside:'avoid'}}>
                          <Table.Cell>{klass.ta ? <Fragment><Image src="https://sms.psd-hi.com/assets/cap.png" style={{display:'inline-block',verticalAlign:'top',height:16}} /> </Fragment> : null}{klass.className}</Table.Cell>
                          <Table.Cell>{klass.instructorName}</Table.Cell>
                          <Table.Cell>{klass.dateBegins}</Table.Cell>
                          <Table.Cell>{klass.attendanceTime}</Table.Cell>
                          <Table.Cell>{klass.requiredHours}</Table.Cell>
                        </Table.Row>))}
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {(params.info.includes('transcript') || params.info.includes('all')) && (data.transcripts.length > 0 || (data.transcripts.length == 0 && params.empty)) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Transcript</div>
                    <Table compact celled striped unstackable>
                      <Table.Header>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.HeaderCell>Facility</Table.HeaderCell>
                          <Table.HeaderCell>Class Name</Table.HeaderCell>
                          <Table.HeaderCell>Instructor</Table.HeaderCell>
                          <Table.HeaderCell>Ended</Table.HeaderCell>
                          <Table.HeaderCell>Comp</Table.HeaderCell>
                          {params.transcript.includes('grades') || params.info.includes('all') ? <Table.HeaderCell>Grade</Table.HeaderCell> : null}
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {data.transcripts.length == 0 && params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell colSpan={params.transcript.includes('grades') || params.info.includes('all') ? 6 : 5}>No Data Available</Table.Cell>
                        </Table.Row> : _.map(data.transcripts, (transcript:any, i:number) => (<Table.Row key={i} style={{breakInside:'avoid'}}>
                          <Table.Cell>{transcript.facilityAbbr}</Table.Cell>
                          <Table.Cell>{transcript.className}</Table.Cell>
                          <Table.Cell>{transcript.instructorName}</Table.Cell>
                          <Table.Cell>{transcript.dateEnds}</Table.Cell>
                          <Table.Cell>{transcript.cert ? "ccc" : transcript.completed ? "***" : ""}</Table.Cell>
                          {params.transcript.includes('grades') || params.info.includes('all') ? <Table.Cell>{transcript.grade}</Table.Cell> : null}
                        </Table.Row>))}
                      </Table.Body>
                    </Table>
                    {params.transcript.includes('grades') || params.info.includes('all') ? <Fragment>
                      <div style={{fontSize:'1em',fontWeight:700,marginTop:15}}>Grade Legend</div>
                      <Table compact celled striped definition unstackable style={{fontSize:'0.9em'}}>
                        <Table.Body>
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "A") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>A</Table.Cell>
                            <Table.Cell>Well above average</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "B") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>B</Table.Cell>
                            <Table.Cell>Above average</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "C") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>C</Table.Cell>
                            <Table.Cell>Average</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "D") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>D</Table.Cell>
                            <Table.Cell>Below average</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "P") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>P</Table.Cell>
                            <Table.Cell>Pass</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "I") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>I</Table.Cell>
                            <Table.Cell>Incomplete</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "P/N") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>P/N</Table.Cell>
                            <Table.Cell>Pass to next level</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "F/N") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>F/N</Table.Cell>
                            <Table.Cell>Fail</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "W/P") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>W/P</Table.Cell>
                            <Table.Cell>Withdraw, receiving passing grade at the time</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "W/F") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>W/F</Table.Cell>
                            <Table.Cell>Withdraw, receiving failing grade at the time</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "W/R") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>W/R</Table.Cell>
                            <Table.Cell>Transfer to a Substance Abuse program</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "W/T") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>W/T</Table.Cell>
                            <Table.Cell>Released (probation or time served)</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "W/X") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>W/X</Table.Cell>
                            <Table.Cell>Transfer to another facility</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "W/D") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>W/D</Table.Cell>
                            <Table.Cell>Withdraw, disciplinary problems</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "W/Z") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>W/Z</Table.Cell>
                            <Table.Cell>Withdraw, no show</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "W/A") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>W/A</Table.Cell>
                            <Table.Cell>Released on parole</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "W/S") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>W/S</Table.Cell>
                            <Table.Cell>Schedule change</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "W/N") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>W/N</Table.Cell>
                            <Table.Cell>No specific reason</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "Rec") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>Rec</Table.Cell>
                            <Table.Cell>Reclassified</Table.Cell>
                          </Table.Row> : null}
                          {_.any(data.transcripts.filter((transcript:any) => params.transcript.includes('completed') && !params.info.includes('all') ? transcript.completed : true), (transcript:any) => transcript.grade == "Comp") ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>Comp</Table.Cell>
                            <Table.Cell>Completed with no grade assigned</Table.Cell>
                          </Table.Row> : null}
                        </Table.Body>
                      </Table>
                    </Fragment> : null}
                    <div style={{fontSize:'1em',fontWeight:700,marginTop:15}}>Comp Legend</div>
                      <Table compact celled striped definition unstackable style={{fontSize:'0.9em'}}>
                        <Table.Body>
                          <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>***</Table.Cell>
                            <Table.Cell>Class completed</Table.Cell>
                          </Table.Row>
                          <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={4} style={{fontWeight:400}}>ccc</Table.Cell>
                            <Table.Cell>Certificate awarded</Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                  </div> : null}
                  {(params.info.includes('transcript') || params.info.includes('all')) && (data.certs.length > 0 || (data.certs.length == 0 && params.empty)) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>Certificates Awarded</div>
                    {data.certs.length == 0 && this.props.options.empty ? <Table compact celled striped unstackable>
                      <Table.Body>
                        <Table.Cell>No Data Available</Table.Cell>
                      </Table.Body>
                      </Table> : _.map(data.certs, (cert:any, i:number) => <Table compact celled striped definition unstackable>
                        <Table.Body>
                          <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={8} style={{fontWeight:400}}>Name</Table.Cell>
                            <Table.Cell>{cert.name}</Table.Cell>
                          </Table.Row>
                          {cert.institution && cert.institution.length > 0 ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={8} style={{fontWeight:400}}>Institution</Table.Cell>
                            <Table.Cell>{cert.institution}</Table.Cell>
                          </Table.Row> : null}
                          {cert.number && cert.number.length > 0 ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={8} style={{fontWeight:400}}>Number</Table.Cell>
                            <Table.Cell>{cert.number}</Table.Cell>
                          </Table.Row> : null}
                          {cert.date && cert.date.length > 0 ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={8} style={{fontWeight:400}}>Date</Table.Cell>
                            <Table.Cell>{cert.date}</Table.Cell>
                          </Table.Row> : null}
                          {cert.expDate && cert.expDate.length > 0 ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={8} style={{fontWeight:400}}>Exp. Date</Table.Cell>
                            <Table.Cell>{cert.expDate}</Table.Cell>
                          </Table.Row> : null}
                          {cert.notes && cert.notes.length > 0 ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={8} style={{fontWeight:400}}>Notes</Table.Cell>
                            <Table.Cell>{cert.notes}</Table.Cell>
                          </Table.Row> : null}
                          {cert.details && cert.details.length > 0 ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={8} style={{fontWeight:400}}>Details</Table.Cell>
                            <Table.Cell>{cert.details}</Table.Cell>
                          </Table.Row> : null}
                          {cert.prerequisites && cert.prerequisites.length > 0 ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={8} style={{fontWeight:400}}>Prerequisites</Table.Cell>
                            <Table.Cell>{cert.prerequisites}</Table.Cell>
                          </Table.Row> : null}
                          {cert.satisfies && cert.satisfies.length > 0 ? <Table.Row style={{breakInside:'avoid'}}>
                            <Table.Cell width={8} style={{fontWeight:400}}>Satisfies</Table.Cell>
                            <Table.Cell>{cert.satisfies}</Table.Cell>
                          </Table.Row> : null}
                        </Table.Body>
                      </Table>
                    )}
                  </div> : null}
                  {(params.info.includes('ged') || params.info.includes('all')) && (data.geds.length > 0 || (data.geds.length == 0 && params.empty)) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>GED Testing</div>
                    <Table compact celled striped unstackable>
                      <Table.Header>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.HeaderCell>Facility</Table.HeaderCell>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                          <Table.HeaderCell>Social Studies</Table.HeaderCell>
                          <Table.HeaderCell>Science</Table.HeaderCell>
                          <Table.HeaderCell>Literature</Table.HeaderCell>
                          <Table.HeaderCell>Math</Table.HeaderCell>
                          <Table.HeaderCell>Pass</Table.HeaderCell>
                          <Table.HeaderCell>Official</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {data.geds.length == 0 && params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell colSpan={8}>No Data Available</Table.Cell>
                        </Table.Row> : _.map(data.geds, (ged:any, i:number) => (<Table.Row key={i} style={{breakInside:'avoid'}}>
                          <Table.Cell>{ged.facilityName}</Table.Cell>
                          <Table.Cell>{ged.testDate}</Table.Cell>
                          <Table.Cell>{ged.socialStudies}</Table.Cell>
                          <Table.Cell>{ged.science}</Table.Cell>
                          <Table.Cell>{ged.literature}</Table.Cell>
                          <Table.Cell>{ged.math}</Table.Cell>
                          <Table.Cell>{ged.pass ? <Image style={{width:8,height:8}} src="/assets/check-mark-icon.jpg" /> : ""}</Table.Cell>
                          <Table.Cell>{!ged.practice ? <Image style={{width:8,height:8}} src="/assets/check-mark-icon.jpg" /> : ""}</Table.Cell>
                        </Table.Row>))}
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {(params.info.includes('tabe') || params.info.includes('all')) && (data.tabes.length > 0 || (data.tabes.length == 0 && params.empty)) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>TABE Testing</div>
                    {data.tabes.length == 0 && params.empty ? <Table compact celled striped unstackable>
                        <Table.Body>
                          <Table.Cell>No Data Available</Table.Cell>
                        </Table.Body>
                      </Table> : _.map(data.tabes, (tabe:any, i:number) => {
                      return (<Fragment key={i}>
                        <Table compact celled striped definition unstackable>
                          <Table.Body>
                            <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell width={8} style={{fontWeight:400}}>Test Date</Table.Cell>
                              <Table.Cell>{tabe.testDate}</Table.Cell>
                            </Table.Row>
                            <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell width={8} style={{fontWeight:400}}>Test</Table.Cell>
                              <Table.Cell>{tabe.test}</Table.Cell>
                            </Table.Row>
                            <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell width={8} style={{fontWeight:400}}>Facility</Table.Cell>
                              <Table.Cell>{tabe.facilityAbbr}</Table.Cell>
                            </Table.Row>
                            <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell width={8} style={{fontWeight:400}}>Intake Assessment</Table.Cell>
                              <Table.Cell>{tabe.intakeAssessment ? "Yes" : "No"}</Table.Cell>
                            </Table.Row>
                            <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell width={8} style={{fontWeight:400}}>Official</Table.Cell>
                              <Table.Cell>{tabe.preTest ? "No" : "Yes"}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                        <Table compact celled striped unstackable>
                          <Table.Header>
                            <Table.Row style={{breakInside:'avoid'}}>
                              <Table.HeaderCell>Subtest</Table.HeaderCell>
                              {tabe.reLF || tabe.mcLF || tabe.amLF || tabe.laLF || tabe.voLF || tabe.lmLF || tabe.spLF || tabe.tmLF || tabe.tbLF ? <Table.HeaderCell>LF</Table.HeaderCell> : null}
                              {tabe.reNC || tabe.mcNC || tabe.amNC || tabe.laNC || tabe.voNC || tabe.lmNC || tabe.spNC || tabe.tmNC || tabe.tbNC ? <Table.HeaderCell>NC</Table.HeaderCell> : null}
                              {tabe.reNA || tabe.mcNA || tabe.amNA || tabe.laNA || tabe.voNA || tabe.lmNA || tabe.spNA || tabe.tmNA || tabe.tbNA ? <Table.HeaderCell>NA</Table.HeaderCell> : null}
                              {tabe.reSS || tabe.mcSS || tabe.amSS || tabe.laSS || tabe.voSS || tabe.lmSS || tabe.spSS || tabe.tmSS || tabe.tbSS ? <Table.HeaderCell>SS</Table.HeaderCell> : null}
                              {tabe.reGE || tabe.mcGE || tabe.amGE || tabe.laGE || tabe.voGE || tabe.lmGE || tabe.spGE || tabe.tmGE || tabe.tbGE ? <Table.HeaderCell>GE</Table.HeaderCell> : null}
                              {tabe.reNP || tabe.mcNP || tabe.amNP || tabe.laNP || tabe.voNP || tabe.lmNP || tabe.spNP || tabe.tmNP || tabe.tbNP ? <Table.HeaderCell>NP</Table.HeaderCell> : null}
                              {tabe.reNRS || tabe.mcNRS || tabe.amNRS || tabe.laNRS || tabe.voNRS || tabe.lmNRS || tabe.spNRS || tabe.tmNRS || tabe.tbNRS ? <Table.HeaderCell>NRS</Table.HeaderCell> : null}
                              {tabe.reNS || tabe.mcNS || tabe.amNS || tabe.laNS || tabe.voNS || tabe.lmNS || tabe.spNS || tabe.tmNS || tabe.tbNS ? <Table.HeaderCell>NS</Table.HeaderCell> : null}
                              {tabe.reOM || tabe.mcOM || tabe.amOM || tabe.laOM || tabe.voOM || tabe.lmOM || tabe.spOM || tabe.tmOM || tabe.tbOM ? <Table.HeaderCell>OM</Table.HeaderCell> : null}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {tabe.reLF ? <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell>Reading</Table.Cell>
                              {tabe.reLF || tabe.mcLF || tabe.amLF || tabe.laLF || tabe.voLF || tabe.lmLF || tabe.spLF || tabe.tmLF || tabe.tbLF ? <Table.Cell>{tabe.reLF}</Table.Cell> : null}
                              {tabe.reNC || tabe.mcNC || tabe.amNC || tabe.laNC || tabe.voNC || tabe.lmNC || tabe.spNC || tabe.tmNC || tabe.tbNC ? <Table.Cell>{tabe.reNC}</Table.Cell> : null}
                              {tabe.reNA || tabe.mcNA || tabe.amNA || tabe.laNA || tabe.voNA || tabe.lmNA || tabe.spNA || tabe.tmNA || tabe.tbNA ? <Table.Cell>{tabe.reNA}</Table.Cell> : null}
                              {tabe.reSS || tabe.mcSS || tabe.amSS || tabe.laSS || tabe.voSS || tabe.lmSS || tabe.spSS || tabe.tmSS || tabe.tbSS ? <Table.Cell>{tabe.reSS}</Table.Cell> : null}
                              {tabe.reGE || tabe.mcGE || tabe.amGE || tabe.laGE || tabe.voGE || tabe.lmGE || tabe.spGE || tabe.tmGE || tabe.tbGE ? <Table.Cell>{tabe.reGE}</Table.Cell> : null}
                              {tabe.reNP || tabe.mcNP || tabe.amNP || tabe.laNP || tabe.voNP || tabe.lmNP || tabe.spNP || tabe.tmNP || tabe.tbNP ? <Table.Cell>{tabe.reNP}</Table.Cell> : null}
                              {tabe.reNRS || tabe.mcNRS || tabe.amNRS || tabe.laNRS || tabe.voNRS || tabe.lmNRS || tabe.spNRS || tabe.tmNRS || tabe.tbNRS ? <Table.Cell>{tabe.reNRS}</Table.Cell> : null}
                              {tabe.reNS || tabe.mcNS || tabe.amNS || tabe.laNS || tabe.voNS || tabe.lmNS || tabe.spNS || tabe.tmNS || tabe.tbNS ? <Table.Cell>{tabe.reNS}</Table.Cell> : null}
                              {tabe.reOM || tabe.mcOM || tabe.amOM || tabe.laOM || tabe.voOM || tabe.lmOM || tabe.spOM || tabe.tmOM || tabe.tbOM ? <Table.Cell>{tabe.reOM}</Table.Cell> : null}
                            </Table.Row> : null}
                            {tabe.mcLF ? <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell>Math Computation</Table.Cell>
                              {tabe.reLF || tabe.mcLF || tabe.amLF || tabe.laLF || tabe.voLF || tabe.lmLF || tabe.spLF || tabe.tmLF || tabe.tbLF ? <Table.Cell>{tabe.mcLF}</Table.Cell> : null}
                              {tabe.reNC || tabe.mcNC || tabe.amNC || tabe.laNC || tabe.voNC || tabe.lmNC || tabe.spNC || tabe.tmNC || tabe.tbNC ? <Table.Cell>{tabe.mcNC}</Table.Cell> : null}
                              {tabe.reNA || tabe.mcNA || tabe.amNA || tabe.laNA || tabe.voNA || tabe.lmNA || tabe.spNA || tabe.tmNA || tabe.tbNA ? <Table.Cell>{tabe.mcNA}</Table.Cell> : null}
                              {tabe.reSS || tabe.mcSS || tabe.amSS || tabe.laSS || tabe.voSS || tabe.lmSS || tabe.spSS || tabe.tmSS || tabe.tbSS ? <Table.Cell>{tabe.mcSS}</Table.Cell> : null}
                              {tabe.reGE || tabe.mcGE || tabe.amGE || tabe.laGE || tabe.voGE || tabe.lmGE || tabe.spGE || tabe.tmGE || tabe.tbGE ? <Table.Cell>{tabe.mcGE}</Table.Cell> : null}
                              {tabe.reNP || tabe.mcNP || tabe.amNP || tabe.laNP || tabe.voNP || tabe.lmNP || tabe.spNP || tabe.tmNP || tabe.tbNP ? <Table.Cell>{tabe.mcNP}</Table.Cell> : null}
                              {tabe.reNRS || tabe.mcNRS || tabe.amNRS || tabe.laNRS || tabe.voNRS || tabe.lmNRS || tabe.spNRS || tabe.tmNRS || tabe.tbNRS ? <Table.Cell>{tabe.mcNRS}</Table.Cell> : null}
                              {tabe.reNS || tabe.mcNS || tabe.amNS || tabe.laNS || tabe.voNS || tabe.lmNS || tabe.spNS || tabe.tmNS || tabe.tbNS ? <Table.Cell>{tabe.mcNS}</Table.Cell> : null}
                              {tabe.reOM || tabe.mcOM || tabe.amOM || tabe.laOM || tabe.voOM || tabe.lmOM || tabe.spOM || tabe.tmOM || tabe.tbOM ? <Table.Cell>{tabe.mcOM}</Table.Cell> : null}
                            </Table.Row> : null}
                            {tabe.amLF ? <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell>Applied Math</Table.Cell>
                              {tabe.reLF || tabe.mcLF || tabe.amLF || tabe.laLF || tabe.voLF || tabe.lmLF || tabe.spLF || tabe.tmLF || tabe.tbLF ? <Table.Cell>{tabe.amLF}</Table.Cell> : null}
                              {tabe.reNC || tabe.mcNC || tabe.amNC || tabe.laNC || tabe.voNC || tabe.lmNC || tabe.spNC || tabe.tmNC || tabe.tbNC ? <Table.Cell>{tabe.amNC}</Table.Cell> : null}
                              {tabe.reNA || tabe.mcNA || tabe.amNA || tabe.laNA || tabe.voNA || tabe.lmNA || tabe.spNA || tabe.tmNA || tabe.tbNA ? <Table.Cell>{tabe.amNA}</Table.Cell> : null}
                              {tabe.reSS || tabe.mcSS || tabe.amSS || tabe.laSS || tabe.voSS || tabe.lmSS || tabe.spSS || tabe.tmSS || tabe.tbSS ? <Table.Cell>{tabe.amSS}</Table.Cell> : null}
                              {tabe.reGE || tabe.mcGE || tabe.amGE || tabe.laGE || tabe.voGE || tabe.lmGE || tabe.spGE || tabe.tmGE || tabe.tbGE ? <Table.Cell>{tabe.amGE}</Table.Cell> : null}
                              {tabe.reNP || tabe.mcNP || tabe.amNP || tabe.laNP || tabe.voNP || tabe.lmNP || tabe.spNP || tabe.tmNP || tabe.tbNP ? <Table.Cell>{tabe.amNP}</Table.Cell> : null}
                              {tabe.reNRS || tabe.mcNRS || tabe.amNRS || tabe.laNRS || tabe.voNRS || tabe.lmNRS || tabe.spNRS || tabe.tmNRS || tabe.tbNRS ? <Table.Cell>{tabe.amNRS}</Table.Cell> : null}
                              {tabe.reNS || tabe.mcNS || tabe.amNS || tabe.laNS || tabe.voNS || tabe.lmNS || tabe.spNS || tabe.tmNS || tabe.tbNS ? <Table.Cell>{tabe.amNS}</Table.Cell> : null}
                              {tabe.reOM || tabe.mcOM || tabe.amOM || tabe.laOM || tabe.voOM || tabe.lmOM || tabe.spOM || tabe.tmOM || tabe.tbOM ? <Table.Cell>{tabe.amOM}</Table.Cell> : null}
                            </Table.Row> : null}
                            {tabe.tmLF ? <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell>Total Math</Table.Cell>
                              {tabe.reLF || tabe.mcLF || tabe.amLF || tabe.laLF || tabe.voLF || tabe.lmLF || tabe.spLF || tabe.tmLF || tabe.tbLF ? <Table.Cell>{tabe.tmLF}</Table.Cell> : null}
                              {tabe.reNC || tabe.mcNC || tabe.amNC || tabe.laNC || tabe.voNC || tabe.lmNC || tabe.spNC || tabe.tmNC || tabe.tbNC ? <Table.Cell>{tabe.tmNC}</Table.Cell> : null}
                              {tabe.reNA || tabe.mcNA || tabe.amNA || tabe.laNA || tabe.voNA || tabe.lmNA || tabe.spNA || tabe.tmNA || tabe.tbNA ? <Table.Cell>{tabe.tmNA}</Table.Cell> : null}
                              {tabe.reSS || tabe.mcSS || tabe.amSS || tabe.laSS || tabe.voSS || tabe.lmSS || tabe.spSS || tabe.tmSS || tabe.tbSS ? <Table.Cell>{tabe.tmSS}</Table.Cell> : null}
                              {tabe.reGE || tabe.mcGE || tabe.amGE || tabe.laGE || tabe.voGE || tabe.lmGE || tabe.spGE || tabe.tmGE || tabe.tbGE ? <Table.Cell>{tabe.tmGE}</Table.Cell> : null}
                              {tabe.reNP || tabe.mcNP || tabe.amNP || tabe.laNP || tabe.voNP || tabe.lmNP || tabe.spNP || tabe.tmNP || tabe.tbNP ? <Table.Cell>{tabe.tmNP}</Table.Cell> : null}
                              {tabe.reNRS || tabe.mcNRS || tabe.amNRS || tabe.laNRS || tabe.voNRS || tabe.lmNRS || tabe.spNRS || tabe.tmNRS || tabe.tbNRS ? <Table.Cell>{tabe.tmNRS}</Table.Cell> : null}
                              {tabe.reNS || tabe.mcNS || tabe.amNS || tabe.laNS || tabe.voNS || tabe.lmNS || tabe.spNS || tabe.tmNS || tabe.tbNS ? <Table.Cell>{tabe.tmNS}</Table.Cell> : null}
                              {tabe.reOM || tabe.mcOM || tabe.amOM || tabe.laOM || tabe.voOM || tabe.lmOM || tabe.spOM || tabe.tmOM || tabe.tbOM ? <Table.Cell>{tabe.tmOM}</Table.Cell> : null}
                            </Table.Row> : null}
                            {tabe.laLF ? <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell>Language</Table.Cell>
                              {tabe.reLF || tabe.mcLF || tabe.amLF || tabe.laLF || tabe.voLF || tabe.lmLF || tabe.spLF || tabe.tmLF || tabe.tbLF ? <Table.Cell>{tabe.laLF}</Table.Cell> : null}
                              {tabe.reNC || tabe.mcNC || tabe.amNC || tabe.laNC || tabe.voNC || tabe.lmNC || tabe.spNC || tabe.tmNC || tabe.tbNC ? <Table.Cell>{tabe.laNC}</Table.Cell> : null}
                              {tabe.reNA || tabe.mcNA || tabe.amNA || tabe.laNA || tabe.voNA || tabe.lmNA || tabe.spNA || tabe.tmNA || tabe.tbNA ? <Table.Cell>{tabe.laNA}</Table.Cell> : null}
                              {tabe.reSS || tabe.mcSS || tabe.amSS || tabe.laSS || tabe.voSS || tabe.lmSS || tabe.spSS || tabe.tmSS || tabe.tbSS ? <Table.Cell>{tabe.laSS}</Table.Cell> : null}
                              {tabe.reGE || tabe.mcGE || tabe.amGE || tabe.laGE || tabe.voGE || tabe.lmGE || tabe.spGE || tabe.tmGE || tabe.tbGE ? <Table.Cell>{tabe.laGE}</Table.Cell> : null}
                              {tabe.reNP || tabe.mcNP || tabe.amNP || tabe.laNP || tabe.voNP || tabe.lmNP || tabe.spNP || tabe.tmNP || tabe.tbNP ? <Table.Cell>{tabe.laNP}</Table.Cell> : null}
                              {tabe.reNRS || tabe.mcNRS || tabe.amNRS || tabe.laNRS || tabe.voNRS || tabe.lmNRS || tabe.spNRS || tabe.tmNRS || tabe.tbNRS ? <Table.Cell>{tabe.laNRS}</Table.Cell> : null}
                              {tabe.reNS || tabe.mcNS || tabe.amNS || tabe.laNS || tabe.voNS || tabe.lmNS || tabe.spNS || tabe.tmNS || tabe.tbNS ? <Table.Cell>{tabe.laNS}</Table.Cell> : null}
                              {tabe.reOM || tabe.mcOM || tabe.amOM || tabe.laOM || tabe.voOM || tabe.lmOM || tabe.spOM || tabe.tmOM || tabe.tbOM ? <Table.Cell>{tabe.laOM}</Table.Cell> : null}
                            </Table.Row> : null}
                            {tabe.lmLF ? <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell>Language Mechanics</Table.Cell>
                              {tabe.reLF || tabe.mcLF || tabe.amLF || tabe.laLF || tabe.voLF || tabe.lmLF || tabe.spLF || tabe.tmLF || tabe.tbLF ? <Table.Cell>{tabe.lmLF}</Table.Cell> : null}
                              {tabe.reNC || tabe.mcNC || tabe.amNC || tabe.laNC || tabe.voNC || tabe.lmNC || tabe.spNC || tabe.tmNC || tabe.tbNC ? <Table.Cell>{tabe.lmNC}</Table.Cell> : null}
                              {tabe.reNA || tabe.mcNA || tabe.amNA || tabe.laNA || tabe.voNA || tabe.lmNA || tabe.spNA || tabe.tmNA || tabe.tbNA ? <Table.Cell>{tabe.lmNA}</Table.Cell> : null}
                              {tabe.reSS || tabe.mcSS || tabe.amSS || tabe.laSS || tabe.voSS || tabe.lmSS || tabe.spSS || tabe.tmSS || tabe.tbSS ? <Table.Cell>{tabe.lmSS}</Table.Cell> : null}
                              {tabe.reGE || tabe.mcGE || tabe.amGE || tabe.laGE || tabe.voGE || tabe.lmGE || tabe.spGE || tabe.tmGE || tabe.tbGE ? <Table.Cell>{tabe.lmGE}</Table.Cell> : null}
                              {tabe.reNP || tabe.mcNP || tabe.amNP || tabe.laNP || tabe.voNP || tabe.lmNP || tabe.spNP || tabe.tmNP || tabe.tbNP ? <Table.Cell>{tabe.lmNP}</Table.Cell> : null}
                              {tabe.reNRS || tabe.mcNRS || tabe.amNRS || tabe.laNRS || tabe.voNRS || tabe.lmNRS || tabe.spNRS || tabe.tmNRS || tabe.tbNRS ? <Table.Cell>{tabe.lmNRS}</Table.Cell> : null}
                              {tabe.reNS || tabe.mcNS || tabe.amNS || tabe.laNS || tabe.voNS || tabe.lmNS || tabe.spNS || tabe.tmNS || tabe.tbNS ? <Table.Cell>{tabe.lmNS}</Table.Cell> : null}
                              {tabe.reOM || tabe.mcOM || tabe.amOM || tabe.laOM || tabe.voOM || tabe.lmOM || tabe.spOM || tabe.tmOM || tabe.tbOM ? <Table.Cell>{tabe.lmOM}</Table.Cell> : null}
                            </Table.Row> : null}
                            {tabe.voLF ? <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell>Vocabulary</Table.Cell>
                              {tabe.reLF || tabe.mcLF || tabe.amLF || tabe.laLF || tabe.voLF || tabe.lmLF || tabe.spLF || tabe.tmLF || tabe.tbLF ? <Table.Cell>{tabe.voLF}</Table.Cell> : null}
                              {tabe.reNC || tabe.mcNC || tabe.amNC || tabe.laNC || tabe.voNC || tabe.lmNC || tabe.spNC || tabe.tmNC || tabe.tbNC ? <Table.Cell>{tabe.voNC}</Table.Cell> : null}
                              {tabe.reNA || tabe.mcNA || tabe.amNA || tabe.laNA || tabe.voNA || tabe.lmNA || tabe.spNA || tabe.tmNA || tabe.tbNA ? <Table.Cell>{tabe.voNA}</Table.Cell> : null}
                              {tabe.reSS || tabe.mcSS || tabe.amSS || tabe.laSS || tabe.voSS || tabe.lmSS || tabe.spSS || tabe.tmSS || tabe.tbSS ? <Table.Cell>{tabe.voSS}</Table.Cell> : null}
                              {tabe.reGE || tabe.mcGE || tabe.amGE || tabe.laGE || tabe.voGE || tabe.lmGE || tabe.spGE || tabe.tmGE || tabe.tbGE ? <Table.Cell>{tabe.voGE}</Table.Cell> : null}
                              {tabe.reNP || tabe.mcNP || tabe.amNP || tabe.laNP || tabe.voNP || tabe.lmNP || tabe.spNP || tabe.tmNP || tabe.tbNP ? <Table.Cell>{tabe.voNP}</Table.Cell> : null}
                              {tabe.reNRS || tabe.mcNRS || tabe.amNRS || tabe.laNRS || tabe.voNRS || tabe.lmNRS || tabe.spNRS || tabe.tmNRS || tabe.tbNRS ? <Table.Cell>{tabe.voNRS}</Table.Cell> : null}
                              {tabe.reNS || tabe.mcNS || tabe.amNS || tabe.laNS || tabe.voNS || tabe.lmNS || tabe.spNS || tabe.tmNS || tabe.tbNS ? <Table.Cell>{tabe.voNS}</Table.Cell> : null}
                              {tabe.reOM || tabe.mcOM || tabe.amOM || tabe.laOM || tabe.voOM || tabe.lmOM || tabe.spOM || tabe.tmOM || tabe.tbOM ? <Table.Cell>{tabe.voOM}</Table.Cell> : null}
                            </Table.Row> : null}
                            {tabe.spLF ? <Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell>Spelling</Table.Cell>
                              {tabe.reLF || tabe.mcLF || tabe.amLF || tabe.laLF || tabe.voLF || tabe.lmLF || tabe.spLF || tabe.tmLF || tabe.tbLF ? <Table.Cell>{tabe.spLF}</Table.Cell> : null}
                              {tabe.reNC || tabe.mcNC || tabe.amNC || tabe.laNC || tabe.voNC || tabe.lmNC || tabe.spNC || tabe.tmNC || tabe.tbNC ? <Table.Cell>{tabe.spNC}</Table.Cell> : null}
                              {tabe.reNA || tabe.mcNA || tabe.amNA || tabe.laNA || tabe.voNA || tabe.lmNA || tabe.spNA || tabe.tmNA || tabe.tbNA ? <Table.Cell>{tabe.spNA}</Table.Cell> : null}
                              {tabe.reSS || tabe.mcSS || tabe.amSS || tabe.laSS || tabe.voSS || tabe.lmSS || tabe.spSS || tabe.tmSS || tabe.tbSS ? <Table.Cell>{tabe.spSS}</Table.Cell> : null}
                              {tabe.reGE || tabe.mcGE || tabe.amGE || tabe.laGE || tabe.voGE || tabe.lmGE || tabe.spGE || tabe.tmGE || tabe.tbGE ? <Table.Cell>{tabe.spGE}</Table.Cell> : null}
                              {tabe.reNP || tabe.mcNP || tabe.amNP || tabe.laNP || tabe.voNP || tabe.lmNP || tabe.spNP || tabe.tmNP || tabe.tbNP ? <Table.Cell>{tabe.spNP}</Table.Cell> : null}
                              {tabe.reNRS || tabe.mcNRS || tabe.amNRS || tabe.laNRS || tabe.voNRS || tabe.lmNRS || tabe.spNRS || tabe.tmNRS || tabe.tbNRS ? <Table.Cell>{tabe.spNRS}</Table.Cell> : null}
                              {tabe.reNS || tabe.mcNS || tabe.amNS || tabe.laNS || tabe.voNS || tabe.lmNS || tabe.spNS || tabe.tmNS || tabe.tbNS ? <Table.Cell>{tabe.spNS}</Table.Cell> : null}
                              {tabe.reOM || tabe.mcOM || tabe.amOM || tabe.laOM || tabe.voOM || tabe.lmOM || tabe.spOM || tabe.tmOM || tabe.tbOM ? <Table.Cell>{tabe.spOM}</Table.Cell> : null}
                            </Table.Row> : null}
                          </Table.Body>
                        </Table>
                        {tabe.domains.length > 0 ? <Table compact celled striped unstackable>
                          <Table.Header>
                            <Table.Row style={{breakInside:'avoid'}}>
                              <Table.HeaderCell>Subtest</Table.HeaderCell>
                              <Table.HeaderCell>Domain</Table.HeaderCell>
                              <Table.HeaderCell>Obtained Pts</Table.HeaderCell>
                              <Table.HeaderCell>Total Points</Table.HeaderCell>
                              <Table.HeaderCell>No. of Items</Table.HeaderCell>
                              <Table.HeaderCell>Perf. Category</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {tabe.domains.map((domain:any) => (<Table.Row style={{breakInside:'avoid'}}>
                              <Table.Cell>{domain.subtest}</Table.Cell>
                              <Table.Cell>{domain.domainName}</Table.Cell>
                              <Table.Cell>{domain.obtainedPoints}</Table.Cell>
                              <Table.Cell>{domain.totalPoints}</Table.Cell>
                              <Table.Cell>{domain.itemNumber}</Table.Cell>
                              <Table.Cell>{domain.perfCategory}</Table.Cell>
                            </Table.Row>))}
                          </Table.Body>
                        </Table> : null}
                      </Fragment>)
                    })}
                  </div> : null}
                  {(params.info.includes('hiset') || params.info.includes('all')) && (data.hisets.length > 0 || (data.hisets.length == 0 && params.empty)) ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{fontSize:'1.25em',fontWeight:700,marginTop:15}}>HiSET Testing</div>
                    <Table compact celled striped unstackable>
                      <Table.Header>
                        <Table.Row style={{breakInside:'avoid'}}>
                          <Table.HeaderCell>Facility</Table.HeaderCell>
                          <Table.HeaderCell>ETS_ID</Table.HeaderCell>
                          <Table.HeaderCell>Test Date</Table.HeaderCell>
                          <Table.HeaderCell>Math</Table.HeaderCell>
                          <Table.HeaderCell>Science</Table.HeaderCell>
                          <Table.HeaderCell>Social Studies</Table.HeaderCell>
                          <Table.HeaderCell>Reading</Table.HeaderCell>
                          <Table.HeaderCell>Writing</Table.HeaderCell>
                          <Table.HeaderCell>Essay</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {data.hisets.length == 0 && params.empty ? <Table.Row style={{breakInside:'avoid'}}>
                          <Table.Cell colSpan={9}>No Data Available</Table.Cell>
                        </Table.Row> : _.map(data.hisets, (hiset:any, i:number) => (<Table.Row key={i} style={{breakInside:'avoid'}}>
                          <Table.Cell>{hiset.facilityAbbr}</Table.Cell>
                          <Table.Cell>{hiset.ets_ID}</Table.Cell>
                          <Table.Cell>{hiset.testDate}</Table.Cell>
                          <Table.Cell>{hiset.math}</Table.Cell>
                          <Table.Cell>{hiset.science}</Table.Cell>
                          <Table.Cell>{hiset.socialStudies}</Table.Cell>
                          <Table.Cell>{hiset.reading}</Table.Cell>
                          <Table.Cell>{hiset.writing}</Table.Cell>
                          <Table.Cell>{hiset.essay}</Table.Cell>
                        </Table.Row>))}
                      </Table.Body>
                    </Table>
                  </div> : null}
                  {params.signature ? <div style={{marginTop:25}}>
                    <Divider />
                    <div style={{borderBottom:'thin solid black',paddingBottom:40,marginTop:10,marginBottom:10,display:'inline-block'}}>This is an accurate representation of the inmate's educational efforts.</div>
                    <div>{params.signatoryName}</div>
                    <div>{params.signatoryTitle}</div>
                    <div>{params.signatoryFacility}</div>
                    <div>{params.signatoryPhone}</div>
                  </div> : null}
                </div>
              </div>
            </Container>
          )
    }
}

export default EducationalHistoryReport