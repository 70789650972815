import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format, getDay } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Search,
  Checkbox,
  Button,
  Select,
  Modal,
  Popup,
  Divider,
  Accordion,
  Table,
  Tab,
  Input
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { class: PanesStore.Class, num: 1 | 2, staff: any } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassSyncCapacities extends React.PureComponent<RosterProps> {
    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public render() {
        const days = [{ key: 0, value: 0, text: 'Sunday' }, { key: 1, value: 1, text: 'Monday' }, { key: 2, value: 2, text: 'Tuesday' }, { key: 3, value: 3, text: 'Wednesday' }, { key: 4, value: 4, text: 'Thursday' }, { key: 5, value: 5, text: 'Friday' }, { key: 6, value: 6, text: 'Saturday' }]
        const info = this.props.class.info
        const schedules = this.props.class.modals.capSync.data.schedules
        return (
          <Container fluid style={{marginTop:20}}>
            <Form>
              <div style={{display:'inline-block',marginTop:8}}>
                <label><b>Class Capacity:</b></label>
              </div>
              <div style={{display:'inline-block',verticalAlign:'top',marginLeft:10,width:120}}>
                <Input value={this.props.class.modals.capSync.data.capacity} onChange={(e:any, data:any) => this.props.syncCapData(this.props.num, 'capacity', data.value)} />
              </div>
            </Form>
            <Table compact celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Day</Table.HeaderCell>
                  <Table.HeaderCell>Start</Table.HeaderCell>
                  <Table.HeaderCell>End</Table.HeaderCell>
                  <Table.HeaderCell>Roster Count</Table.HeaderCell>
                  <Table.HeaderCell>Session Capacity</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {schedules.map((schedule:any, s:number) => (
                  <Fragment key={schedule.recordID}>
                    <Table.Row>
                      <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>{_.find(days, (day:any) => day.value == schedule.dayOfWeek).text}</Table.Cell>
                      <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>{schedule.startTime}</Table.Cell>
                      <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>{schedule.endTime}</Table.Cell>
                      <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>{schedule.rosterCount}</Table.Cell>
                      <Table.Cell warning={schedule.grouped.length > 0} style={{borderTop: schedule.grouped.length > 0 && (s == 0 || schedules[s-1].grouped == 0) ? '2px solid rgba(34, 36, 38, 0.1)' : '1px solid rgba(34, 36, 38, 0.1)'}}>
                        <Form>
                          <Form.Field
                            control={Input}
                            name="capacity"
                            value={schedule.capacity}
                            onChange={(e:any, data:any) => this.props.syncCapChange(this.props.num, schedule.recordID, 'capacity', data.value)}
                          />
                        </Form>
                      </Table.Cell>
                    </Table.Row>
                  </Fragment>
                ))}
              </Table.Body>
            </Table>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, staff: state.staff.staff } },
  PanesStore.actionCreators
)(ClassSyncCapacities as any)