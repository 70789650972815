import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Grid,
  Form,
  Select,
  Button,
  TextArea,
  Divider,
  Header,
  Table,
  Modal,
  Input,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean, hide: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateHealth extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchMedical(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      const today = new Date(new Date().toDateString())
      return (
        <Container fluid>
          <div style={{display:'inline-block',marginTop:10,marginBottom:10}}>
            <Header as='h3'>Health related learning problems</Header>
          </div>
          <Form>
            <Grid>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column width={16}>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label='Health problems'
                      placeholder='Health problems'
                      name='healthProblems'
                      options={[{ key: "N", text: "No", value: "N" }, { key: "Y", text: "Yes", value: "Y" }]}
                      value={this.props.inmate.medical.healthProblems}
                      onChange={(e:any, data:any) => this.props.editMedicalField(this.props.num, 'healthProblems', data.value as string)}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                    />
                    <Form.Field
                      control={Select}
                      label='Declined to disclose'
                      placeholder='Declined to disclose'
                      name='declinedToDisclose'
                      options={[{ key: "N", text: "No", value: false }, { key: "Y", text: "Yes", value: true }]}
                      value={this.props.inmate.medical.declinedToDisclose}
                      onChange={(e:any, data:any) => this.props.editMedicalField(this.props.num, 'declinedToDisclose', data.value as boolean)}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Comment</Table.HeaderCell>
                        <Table.HeaderCell>Staff</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.medical.healthNotes ? this.props.inmate.medical.healthNotes.map((note:any, i:number) => (
                        <Table.Row key={i} onClick={() => this.props.openEditHealthNote(this.props.num, note)}>
                          <Table.Cell><div style={{whiteSpace:'normal'}}>{note.content}</div></Table.Cell>
                          <Table.Cell>{note.staffName}</Table.Cell>
                          <Table.Cell>{note.date}</Table.Cell>
                        </Table.Row>
                      )) : null}
                      <Table.Row>
                        <Table.Cell colSpan={2}><Input style={{width:'100%'}} value={this.props.inmate.medical.newHealthNote||''} onChange={(e:any, data:any) => this.props.editMedicalField(this.props.num, 'newHealthNote', data.value.validate())} /></Table.Cell>
                        <Table.Cell style={{textAlign:'center'}}><Button positive size='mini' content='Add' style={{width:'100%'}} onClick={_.debounce(() => this.props.addHealthNote(this.props.num), 10000, true)} /></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
              {this.props.print ? null : <Grid.Row style={{paddingTop:0}}>
                <Grid.Column>
                  <Button
                    positive
                    content='Save'
                    onClick={_.debounce(() => this.props.saveHealth(this.props.num), 10000, true)}
                  />
                </Grid.Column>
              </Grid.Row>}
            </Grid>
          </Form>
          <Divider />
          <div style={{display:'inline-block',marginTop:10,marginBottom:10}}>
            <Header as='h3'>Disability related learning problems</Header>
          </div>
          <Form>
            <Grid>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column width={16}>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label='Disabilities'
                      placeholder='Disabilities'
                      options={[{ key: 0, text: "Hearing impaired", value: "hearingImpaired" }, { key: 1, text: "Visually impaired", value: "visuallyImpaired" }, { key: 2, text: "Wheelchair", value: "wheelchair" }, { key: 3, text: "ADHD", value: "adhd" }, { key: 4, text: "Seizure", value: "seizure" }, { key: 5, text: "Diabetic", value: "diabetic" }, { key: 6, text: "Frequent restroom", value: "frequentRestroom" }, { key: 7, text: "Physical limitation", value: "physicalLimitation" }]}
                      value={this.props.inmate.medical.disabilities || []}
                      onChange={(e:any, data:any) => this.props.editMedicalField(this.props.num, 'disabilities', data.value as string)}
                      width={16}
                      className={this.props.print ? 'transparent' : ''}
                      multiple
                    />
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Comment</Table.HeaderCell>
                        <Table.HeaderCell>Staff</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.medical.disabilityNotes ? this.props.inmate.medical.disabilityNotes.map((note:any, i:number) => (
                        <Table.Row key={i} onClick={() => this.props.openEditDisabilityNote(this.props.num, note)}>
                          <Table.Cell><div style={{whiteSpace:'normal'}}>{note.content}</div></Table.Cell>
                          <Table.Cell>{note.staffName}</Table.Cell>
                          <Table.Cell>{note.date}</Table.Cell>
                        </Table.Row>
                      )) : null}
                      <Table.Row>
                        <Table.Cell colSpan={2}><Input style={{width:'100%'}} value={this.props.inmate.medical.newDisabilityNote||''} onChange={(e:any, data:any) => this.props.editMedicalField(this.props.num, 'newDisabilityNote', data.value.validate())} /></Table.Cell>
                        <Table.Cell style={{textAlign:'center'}}><Button positive size='mini' content='Add' style={{width:'100%'}} onClick={_.debounce(() => this.props.addDisabilityNote(this.props.num), 10000, true)} /></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
              {this.props.print ? null : <Grid.Row style={{paddingTop:0}}>
                <Grid.Column>
                  <Button
                    positive
                    content='Save'
                    onClick={_.debounce(() => this.props.saveHealth(this.props.num), 10000, true)}
                  />
                </Grid.Column>
              </Grid.Row>}
            </Grid>
          </Form>
          {!this.props.print || (this.props.hide ? this.props.inmate.medical.checkups.length > 0 : true) ? <Fragment>
            <Divider />
            <div style={{display:'inline-block',marginTop:10}}>
              <Header as='h3'>Checkups</Header>
            </div>
            {this.props.print ? null : <Popup content={`Add Medical, Dental, Visions, or Hearing Checkups.`} position="top center" trigger={<Button positive circular icon='plus' onClick={() => this.props.toggleCheckup(this.props.num)} style={{marginLeft:10,marginBottom:10}} />} />}
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Table compact celled striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Type</Table.HeaderCell>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.props.inmate.medical.checkups ? this.props.inmate.medical.checkups.map((checkup:any) => (
                          <Table.Row key={checkup.recordID}>
                            <Table.Cell>{checkup.type}</Table.Cell>
                            <Table.Cell>{checkup.date}</Table.Cell>
                          </Table.Row>
                        )) : null}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Fragment> : null}
          <Modal
            open={this.props.inmate.modals.checkup.open}
            onClose={() => this.props.toggleCheckup(this.props.num)}
            size="tiny"
          >
            <Modal.Header content='Add Checkup' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label="Type"
                    placeholder='Type'
                    options={[{ key: 0, value: "Medical", text: "Medical" },{ key: 1, value: "Dental", text: "Dental" },{ key: 2, value: "Vision", text: "Vision" },{ key: 3, value: "Hearing", text: "Hearing" }]}
                    value={this.props.inmate.modals.checkup.data.type}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'checkup', 'type', data.value as string)}
                    width={10}
                    fluid
                  />
                  <Form.Field
                    label="Date"
                    control={DatePicker}
                    selected={this.props.inmate.modals.checkup.data.date ? new Date(this.props.inmate.modals.checkup.data.date) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'checkup', 'date', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={6}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleCheckup(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.addCheckup(this.props.num), 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editHealthNote.open}
            onClose={() => this.props.toggleEditHealthNote(this.props.num)}
            size='tiny'
          >
            <Modal.Header content='Edit Comment' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    name='content'
                    value={this.props.inmate.modals.editHealthNote.data.content || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editHealthNote', 'content', data.value)}
                    rows={3}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditHealthNote(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeHealthNote(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.editHealthNote(this.props.num), 10000, true)}>Edit</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editDisabilityNote.open}
            onClose={() => this.props.toggleEditDisabilityNote(this.props.num)}
            size='tiny'
          >
            <Modal.Header content='Edit Comment' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    name='content'
                    value={this.props.inmate.modals.editDisabilityNote.data.content || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editDisabilityNote', 'content', data.value)}
                    rows={3}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditDisabilityNote(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeDisabilityNote(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.editDisabilityNote(this.props.num), 10000, true)}>Edit</Button>
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print, hide: ownProps.hide } },
  PanesStore.actionCreators
)(InmateHealth as any)