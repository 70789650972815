import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import * as DrawerStore from '../../store/reducers/drawer'
import * as PanesStore from '../../store/reducers/panes'
import { format, compareAsc, differenceInDays, differenceInWeeks, differenceInMonths, startOfMonth, addHours, subHours, subDays, addDays, subWeeks, addWeeks, subMonths, addMonths } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Table,
  Checkbox,
  Popup,
  Label,
  Form,
  Input,
  Select,
  Button
} from 'semantic-ui-react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line, Bar, getDatasetAtEvent } from 'react-chartjs-2'
const _ = require('underscore')

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const dailyOpts = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Last Day',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Hours'
      }
    }
  }
}

const weeklyOpts = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Last Week',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Hours'
      }
    }
  }
}

const monthlyOpts = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Last Month',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Hours'
      }
    }
  }
}

const yearlyOpts = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Last Year',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Hours'
      }
    }
  }
}

const customOpts = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Custom Range',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Hours'
      }
    }
  }
}

type StaffProps =
    { staff: any, options: any, filters: any, tab: string, mode: string } &
    typeof AdminStore.actionCreators &
    typeof DrawerStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class StaffActivity extends React.PureComponent<StaffProps> {
    public componentDidMount() {
        //this.props.fetchStaffList()
    }

    private HeaderArrow = (props:any) => {
      if (this.props.filters.orderBy == props.orderBy) {
        if (this.props.filters.orderDir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    public render() {
      const HeaderArrow = this.HeaderArrow
      const dayOfWeek3 = (day:number) => {
        switch(day) {
          case 0: {
            return 'Sun'
          }
          case 1: {
            return 'Mon'
          }
          case 2: {
            return 'Tue'
          }
          case 3: {
            return 'Wed'
          }
          case 4: {
            return 'Thu'
          }
          case 5: {
            return 'Fri'
          }
          case 6: {
            return 'Sat'
          }
          default: {
            return ''
          }
        }
      }

      const getStaff = () => {
          var staff = this.props.staff.list
          if (this.props.filters.lastName.length > 0) { staff = _.filter(staff, (s:any) => s.lastName.toLowerCase().includes(this.props.filters.lastName.toLowerCase())) }
          if (this.props.filters.firstName.length > 0) { staff = _.filter(staff, (s:any) => s.firstName.toLowerCase().includes(this.props.filters.firstName.toLowerCase())) }
          if (this.props.filters.nickname.length > 0) { staff = _.filter(staff, (s:any) => s.nickname != null && s.nickname.toLowerCase().includes(this.props.filters.nickname.toLowerCase())) }
          if (this.props.filters.facility.length > 0) { staff = _.filter(staff, (s:any) => s.facilities.includes(this.props.filters.facility)) }
          if (this.props.filters.department) {
            staff = _.map(staff, (s:any) => Object.assign(_.clone(s), { titleOpts: _.filter(s.titleOpts, (title:any) => title.departmentID == this.props.filters.department) }))
            staff = _.filter(staff, (s:any) => s.titleOpts.length > 0)
          }
          if (this.props.filters.position) { staff = _.filter(staff, (s:any) => _.any(s.titleOpts, (title:any) => title.titleID == this.props.filters.position)) }
          staff = _.filter(staff, (s:any) => s.deleted == !this.props.filters.active)
          if (this.props.filters.department && this.props.filters.departmentPreset) {
            var presets = _.filter(this.props.staff.presets, (preset:any) => preset.departmentID == this.props.filters.department && preset.name == this.props.filters.departmentPreset)
            staff = _.filter(staff, (s:any) => {
              return _.all(presets, (preset:any) => {
                switch (preset.departmentPermission) {
                case "del":
                  return _.any(s.departmentPermissions, (p:any) => p.departmentID == this.props.filters.department && p.subsystem == preset.subsystem && p.module == preset.module && ['del'].includes(p.permission))
                case "add":
                  return _.any(s.departmentPermissions, (p:any) => p.departmentID == this.props.filters.department && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add'].includes(p.permission))
                case "edit":
                  return _.any(s.departmentPermissions, (p:any) => p.departmentID == this.props.filters.department && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add','edit'].includes(p.permission))
                case "view":
                  return _.any(s.departmentPermissions, (p:any) => p.departmentID == this.props.filters.department && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add','edit','view'].includes(p.permission))
                case "none":
                  return true
                default:
                  return false
                }
              })
            })
          }
          if (this.props.filters.facility.length > 0 && this.props.filters.department && this.props.filters.facilityPreset) {
            var presets = _.filter(this.props.staff.presets, (preset:any) => preset.departmentID == this.props.filters.department && preset.name == this.props.filters.facilityPreset)
            staff = _.filter(staff, (s:any) => {
              return _.all(presets, (preset:any) => {
                switch (preset.facilityPermission) {
                case "del":
                  return _.any(s.facilityPermissions, (p:any) => p.facilityAbbr == this.props.filters.facility && p.subsystem == preset.subsystem && p.module == preset.module && ['del'].includes(p.permission))
                case "add":
                  return _.any(s.facilityPermissions, (p:any) => p.facilityAbbr == this.props.filters.facility && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add'].includes(p.permission))
                case "edit":
                  return _.any(s.facilityPermissions, (p:any) => p.facilityAbbr == this.props.filters.facility && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add','edit'].includes(p.permission))
                case "view":
                  return _.any(s.facilityPermissions, (p:any) => p.facilityAbbr == this.props.filters.facility && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add','edit','view'].includes(p.permission))
                case "none":
                  return true
                default:
                  return false
                }
              })
            })
          }
          switch (this.props.filters.orderBy) {
          case 'id':
            staff = _.sortBy(staff, (s:any) => s.recordID)
            break;
          case 'name':
            staff = _.sortBy(staff, (s:any) => s.name)
            break;
          case 'lastSession':
            staff = _.sortBy(staff, (s:any) => s.lastSessionUnix)
            break;
          case 'daily':
            staff = _.sortBy(staff, (s:any) => s.dailySeconds)
            break;
          case 'weekly':
            staff = _.sortBy(staff, (s:any) => s.weeklySeconds)
            break;
          case 'monthly':
            staff = _.sortBy(staff, (s:any) => s.monthlySeconds)
            break;
          }
          if (this.props.filters.orderDir == 'desc') staff = staff.reverse()
          staff = _.sortBy(staff, (s:any) => s.checked != true)
          return staff
      }

      const hourlySessions = (staff:any, date:any) => {
        var range = _.filter(staff.sessions, (session:any) => new Date(session.startTime).getHours() - date.getHours() == 0 && compareAsc(new Date(new Date(session.startTime).toDateString()), new Date(date.toDateString())) == 0)
        return Math.round((_.reduce(range, (acc:number, session:any) => acc + session.seconds/60/60, 0) + Number.EPSILON) * 10) / 10
      }

      const dailySessions = (staff:any, date:any) => {
        var range = _.filter(staff.sessions, (session:any) => compareAsc(new Date(new Date(session.startTime).toDateString()), new Date(date.toDateString())) == 0)
        return Math.round((_.reduce(range, (acc:number, session:any) => acc + session.seconds/60/60, 0) + Number.EPSILON) * 10) / 10
      }

      const weeklySessions = (staff:any, date:any) => {
        var range = _.filter(staff.sessions, (session:any) => new Date(new Date(session.startTime).toDateString()) >= new Date(date.toDateString()) && new Date(new Date(session.startTime).toDateString()) < new Date(addWeeks(date,1).toDateString()))
        return Math.round((_.reduce(range, (acc:number, session:any) => acc + session.seconds/60/60, 0) + Number.EPSILON) * 10) / 10
      }

      const monthlySessions = (staff:any, date:any) => {
        var range = _.filter(staff.sessions, (session:any) => new Date(new Date(session.startTime).toDateString()) >= new Date(date.toDateString()) && new Date(new Date(session.startTime).toDateString()) < new Date(addMonths(date,1).toDateString()))
        return Math.round((_.reduce(range, (acc:number, session:any) => acc + session.seconds/60/60, 0) + Number.EPSILON) * 10) / 10
      }

      let dailyDates = [subHours(new Date(), 24),subHours(new Date(), 23),subHours(new Date(), 22),subHours(new Date(), 21),subHours(new Date(), 20),subHours(new Date(), 19),subHours(new Date(), 18),subHours(new Date(), 17),subHours(new Date(), 16),subHours(new Date(), 15),subHours(new Date(), 14),subHours(new Date(), 13),subHours(new Date(), 12),subHours(new Date(), 11),subHours(new Date(), 10),subHours(new Date(), 9),subHours(new Date(), 8),subHours(new Date(), 7),subHours(new Date(), 6),subHours(new Date(), 5),subHours(new Date(), 4),subHours(new Date(), 3),subHours(new Date(), 2),subHours(new Date(), 1)]
      let drop = true
      while (drop && dailyDates.length > 0) {
        if (_.all(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => hourlySessions(staff, dailyDates[0]) == 0)) {
          dailyDates.shift()
        } else {
          drop = false
        }
      }
      drop = true
      while (drop && dailyDates.length > 0) {
        if (_.all(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => hourlySessions(staff, dailyDates[dailyDates.length-1]) == 0)) {
          dailyDates.pop()
        } else {
          drop = false
        }
      }
      const dailyData = {
        labels: _.map(dailyDates, (date:any) => date.getHours()),
        datasets: _.map(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => {
          return {
            label: `${staff.name} (${Math.round(_.reduce(dailyDates, (acc:number, date:number) => acc + hourlySessions(staff, date), 0) * 10) / 10})`,
            data: _.map(dailyDates, (date:any) => hourlySessions(staff, date)),
            backgroundColor: staff.color,
            borderColor: staff.border
          }
        })
      }

      const weeklyData = {
        labels: [`${dayOfWeek3(subDays(new Date(), 6).getDay())} (${format(subDays(new Date(), 6), 'MM/dd')})`, `${dayOfWeek3(subDays(new Date(), 5).getDay())} (${format(subDays(new Date(), 5), 'MM/dd')})`, `${dayOfWeek3(subDays(new Date(), 4).getDay())} (${format(subDays(new Date(), 4), 'MM/dd')})`, `${dayOfWeek3(subDays(new Date(), 3).getDay())} (${format(subDays(new Date(), 3), 'MM/dd')})`, `${dayOfWeek3(subDays(new Date(), 2).getDay())} (${format(subDays(new Date(), 2), 'MM/dd')})`, `${dayOfWeek3(subDays(new Date(), 1).getDay())} (${format(subDays(new Date(), 1), 'MM/dd')})`, `${dayOfWeek3(new Date().getDay())} (${format(new Date(), 'MM/dd')})`],
        datasets: _.map(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => {
          let data = [dailySessions(staff, subDays(new Date(), 6)), dailySessions(staff, subDays(new Date(), 5)), dailySessions(staff, subDays(new Date(), 4)), dailySessions(staff, subDays(new Date(), 3)), dailySessions(staff, subDays(new Date(), 2)), dailySessions(staff, subDays(new Date(), 1)), dailySessions(staff, (new Date()))]
          return {
            label: `${staff.name} (${Math.round(_.reduce(data, (acc:number, time:number) => acc + time, 0) * 10) / 10})`,
            data: data,
            backgroundColor: staff.color,
            borderColor: staff.border
          }
        })
      }

      const monthlyData = {
        labels: [format(subWeeks(addDays(new Date(), 1), 4), 'MM/dd'),format(subWeeks(addDays(new Date(), 1), 3), 'MM/dd'),format(subWeeks(addDays(new Date(), 1), 2), 'MM/dd'),format(subWeeks(addDays(new Date(), 1), 1), 'MM/dd')],
        datasets: _.map(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => {
          let data = [weeklySessions(staff, subWeeks(addDays(new Date(), 1), 4)),weeklySessions(staff, subWeeks(addDays(new Date(), 1), 3)),weeklySessions(staff, subWeeks(addDays(new Date(), 1), 2)),weeklySessions(staff, subWeeks(addDays(new Date(), 1), 1))]
          return {
            label: `${staff.name} (${Math.round(_.reduce(data, (acc:number, time:number) => acc + time, 0) * 10) / 10})`,
            data: data,
            backgroundColor: staff.color,
            borderColor: staff.border
          }
        })
      }

      const yearlyData = {
        labels: [format(subMonths(startOfMonth(new Date()), 11), 'LLL'),format(subMonths(startOfMonth(new Date()), 10), 'LLL'),format(subMonths(startOfMonth(new Date()), 9), 'LLL'),format(subMonths(startOfMonth(new Date()), 8), 'LLL'),format(subMonths(startOfMonth(new Date()), 7), 'LLL'),format(subMonths(startOfMonth(new Date()), 6), 'LLL'),format(subMonths(startOfMonth(new Date()), 5), 'LLL'),format(subMonths(startOfMonth(new Date()), 4), 'LLL'),format(subMonths(startOfMonth(new Date()), 3), 'LLL'),format(subMonths(startOfMonth(new Date()), 2), 'LLL'),format(subMonths(startOfMonth(new Date()), 1), 'LLL'),format(startOfMonth(new Date()), 'LLL')],
        datasets: _.map(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => {
          let data = [monthlySessions(staff, subMonths(startOfMonth(new Date()), 11)),monthlySessions(staff, subMonths(startOfMonth(new Date()), 10)),monthlySessions(staff, subMonths(startOfMonth(new Date()), 9)),monthlySessions(staff, subMonths(startOfMonth(new Date()), 8)),monthlySessions(staff, subMonths(startOfMonth(new Date()), 7)),monthlySessions(staff, subMonths(startOfMonth(new Date()), 6)),monthlySessions(staff, subMonths(startOfMonth(new Date()), 5)),monthlySessions(staff, subMonths(startOfMonth(new Date()), 4)),monthlySessions(staff, subMonths(startOfMonth(new Date()), 3)),monthlySessions(staff, subMonths(startOfMonth(new Date()), 2)),monthlySessions(staff, subMonths(startOfMonth(new Date()), 1)),monthlySessions(staff, startOfMonth(new Date()))]
          return {
            label: `${staff.name} (${Math.round(_.reduce(data, (acc:number, time:number) => acc + time, 0) * 10) / 10})`,
            data: data,
            backgroundColor: staff.color,
            borderColor: staff.border
          }
        })
      }

      const customData = () => {
        const diffDays = differenceInDays(new Date(this.props.filters.endDate), new Date(this.props.filters.startDate))
        const diffWeeks = differenceInWeeks(new Date(this.props.filters.endDate), new Date(this.props.filters.startDate))
        const diffMonths = differenceInMonths(new Date(this.props.filters.endDate), new Date(this.props.filters.startDate))
        if (this.props.filters.startDate == this.props.filters.endDate) {
          let start = new Date(new Date(this.props.filters.startDate).toDateString())
          let dates = [start,addHours(start, 1),addHours(start, 2),addHours(start, 3),addHours(start, 4),addHours(start, 5),addHours(start, 6),addHours(start, 7),addHours(start, 8),addHours(start, 9),addHours(start, 10),addHours(start, 11),addHours(start, 12),addHours(start, 13),addHours(start, 14),addHours(start, 15),addHours(start, 16),addHours(start, 17),addHours(start, 18),addHours(start, 19),addHours(start, 20),addHours(start, 21),addHours(start, 22),addHours(start, 23)]
          let drop = true
          while (drop && dates.length > 0) {
            if (_.all(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => hourlySessions(staff, dates[0]) == 0)) {
              dates.shift()
            } else {
              drop = false
            }
          }
          drop = true
          while (drop && dates.length > 0) {
            if (_.all(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => hourlySessions(staff, dates[dates.length-1]) == 0)) {
              dates.pop()
            } else {
              drop = false
            }
          }

          return {
            labels: _.map(dates, (date:any) => date.getHours()),
            datasets: _.map(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => {
              return {
                label: `${staff.name} (${Math.round(_.reduce(dates, (acc:number, date:any) => acc + hourlySessions(staff, date), 0) * 10) / 10})`,
                data: _.map(dates, (date:any) => hourlySessions(staff, date)),
                backgroundColor: staff.color,
                borderColor: staff.border
              }
            })
          }
        } else if (diffWeeks < 3) {
          let days = [new Date(this.props.filters.startDate)]
          _.times(diffDays, (i:number) => { days.push(addDays(new Date(this.props.filters.startDate), i+1)) })
          return {
            labels: _.map(days, (day:any) => format(day, 'MM/dd')),
            datasets: _.map(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => {
              let data = _.map(days, (day:any) => dailySessions(staff, day))
              return {
                label: `${staff.name} (${Math.round(_.reduce(data, (acc:number, time:number) => acc + time, 0) * 10) / 10})`,
                data: data,
                backgroundColor: staff.color,
                borderColor: staff.border
              }
            })
          }
        } else if (diffMonths < 3) {
          let days = [new Date(this.props.filters.startDate)]
          _.times(diffWeeks, (i:number) => { days.push(addWeeks(new Date(this.props.filters.startDate), i+1)) })
          return {
            labels: _.map(days, (day:any) => format(day, 'MM/dd')),
            datasets: _.map(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => {
              let data = _.map(days, (day:any) => weeklySessions(staff, day))
              return {
                label: `${staff.name} (${Math.round(_.reduce(data, (acc:number, time:number) => acc + time, 0) * 10) / 10})`,
                data: data,
                backgroundColor: staff.color,
                borderColor: staff.border
              }
            })
          }
        } else {
          let days = [new Date(this.props.filters.startDate)]
          _.times(diffMonths, (i:number) => { days.push(addMonths(new Date(this.props.filters.startDate), i+1)) })
          return {
            labels: _.map(days, (day:any) => format(day, 'MM/dd')),
            datasets: _.map(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => {
              let data = _.map(days, (day:any) => monthlySessions(staff, day))
              return {
                label: `${staff.name} (${Math.round(_.reduce(data, (acc:number, time:number) => acc + time, 0) * 10) / 10})`,
                data: data,
                backgroundColor: staff.color,
                borderColor: staff.border
              }
            })
          }
        }
      }

      return (
        <Fragment>
          <style>
            {_.map(_.filter(getStaff(), (staff:any) => staff.checked), (staff:any) => {
              return `.color-${staff.color.replace(/[^0-9a-z]/g, '')} label::before { background-color: ${staff.color} !important }`
            })}
          </style>
          <div style={{overflow:'auto'}}>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Input}
                  placeholder='Last name'
                  value={this.props.filters.lastName}
                  onChange={(e:any, data:any) => this.props.updateStaffFilter('lastName', data.value.validate())}
                  width={2}
                />
                <Form.Field
                  control={Input}
                  placeholder='First name'
                  value={this.props.filters.firstName}
                  onChange={(e:any, data:any) => this.props.updateStaffFilter('firstName', data.value.validate())}
                  width={2}
                />
                <Form.Field
                  control={Input}
                  placeholder='Nickname'
                  value={this.props.filters.nickname}
                  onChange={(e:any, data:any) => this.props.updateStaffFilter('nickname', data.value.validate())}
                  width={2}
                />
                <Form.Field
                  control={Select}
                  placeholder='Facility'
                  options={[{key:0,value:'',text:''}].concat(_.map(this.props.options.allFacilities, (f:any) => { return { key: f.text, value: f.text, text: f.text }}))}
                  value={this.props.filters.facility.length > 0 ? this.props.filters.facility : null}
                  onChange={(e:any, data:any) => this.props.updateStaffFilter('facility', data.value as number)}
                  width={2}
                />
                <Form.Field
                  control={Select}
                  placeholder='Department'
                  options={[{key:0,value:null,text:''}].concat(this.props.options.programs ? this.props.options.programs : [])}
                  value={this.props.filters.department}
                  onChange={(e:any, data:any) => this.props.updateStaffFilter('department', data.value)}
                  width={2}
                />
                <Form.Field
                  control={Select}
                  placeholder='Position'
                  options={[{key:0,value:null,text:''}].concat(this.props.options.titles ? _.filter(this.props.options.titles, (title:any) => this.props.filters.department ? title.department == this.props.filters.department : true) : [])}
                  value={this.props.filters.position}
                  onChange={(e:any, data:any) => this.props.updateStaffFilter('position', data.value)}
                  width={2}
                />
                <Form.Field
                  control={Select}
                  placeholder='Active'
                  options={[{key:0,value:true,text:'Active'},{key:1,value:false,text:'Archived'}]}
                  value={this.props.filters.active}
                  onChange={(e:any, data:any) => this.props.updateStaffFilter('active', data.value as boolean)}
                  width={2}
                />
                <Button color='blue' content='Reset Filters' onClick={this.props.resetFilters} style={{marginLeft:5}} />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Select}
                  options={[{key:0,value:'line',text:'Line'},{key:1,value:'bar',text:'Bar'}]}
                  value={this.props.filters.graphType}
                  onChange={(e:any, data:any) => this.props.updateStaffFilter('graphType', data.value as string)}
                  width={2}
                />
                <Form.Field
                  control={Select}
                  options={[{key:0,value:'day',text:'Day'},{key:1,value:'week',text:'Week'},{key:2,value:'month',text:'Month'},{key:3,value:'year',text:'Year'},{key:4,value:'custom',text:'Custom'}]}
                  value={this.props.filters.graph}
                  onChange={(e:any, data:any) => this.props.updateStaffFilter('graph', data.value as string)}
                  width={2}
                />
                {this.props.filters.graph == 'custom' ? <Fragment>
                  <Form.Field
                    placeholderText="Start Date"
                    control={DatePicker}
                    selected={this.props.filters.startDate ? new Date(this.props.filters.startDate) : null}
                    onChange={(date:any) => this.props.updateStaffFilter('startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date <= new Date(new Date().toDateString()) && (this.props.filters.endDate == null || date <= new Date(this.props.filters.endDate))}
                    width={2}
                  />
                  <Form.Field
                    placeholderText="End Date"
                    control={DatePicker}
                    selected={this.props.filters.endDate ? new Date(this.props.filters.endDate) : null}
                    onChange={(date:any) => this.props.updateStaffFilter('endDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date <= new Date(new Date().toDateString()) && (this.props.filters.startDate == null || date >= new Date(this.props.filters.startDate))}
                    style={{visibility:(this.props.filters.graph == 'custom' ? 'visible' : 'hidden')}}
                    width={2}
                  />
                </Fragment> : null}
                <div style={{marginLeft:'auto',color:'#aaa',marginTop:10,marginRight:10}}>Generated at {format(new Date(), 'MM/dd/yyyy HH:mm')}</div>
              </Form.Group>
            </Form>
            {_.filter(getStaff(), (staff:any) => staff.checked).length > 0 ? <div id='chart' style={{height:400,display:'flex'}}>
              {this.props.filters.graph == 'day' ? (this.props.filters.graphType == 'line' ? <Line options={dailyOpts} data={dailyData} redraw /> : this.props.filters.graphType == 'bar' ? <Bar options={dailyOpts} data={dailyData} redraw />  : null) : null}
              {this.props.filters.graph == 'week' ? (this.props.filters.graphType == 'line' ? <Line options={weeklyOpts} data={weeklyData} redraw /> : this.props.filters.graphType == 'bar' ? <Bar options={weeklyOpts} data={weeklyData} redraw />  : null) : null}
              {this.props.filters.graph == 'month' ? (this.props.filters.graphType == 'line' ? <Line options={monthlyOpts} data={monthlyData} redraw /> : this.props.filters.graphType == 'bar' ? <Bar options={monthlyOpts} data={monthlyData} redraw />  : null) : null}
              {this.props.filters.graph == 'year' ? (this.props.filters.graphType == 'line' ? <Line options={yearlyOpts} data={yearlyData} redraw /> : this.props.filters.graphType == 'bar' ? <Bar options={yearlyOpts} data={yearlyData} redraw />  : null) : null}
              {this.props.filters.graph == 'custom' && this.props.filters.startDate && this.props.filters.endDate && new Date(this.props.filters.startDate) <= new Date(this.props.filters.endDate) ? (this.props.filters.graphType == 'line' ? <Line options={customOpts} data={customData()} redraw /> : this.props.filters.graphType == 'bar' ? <Bar options={customOpts} data={customData()} redraw />  : null) : null}
            </div> : null}
            <Table id='staff-table' compact celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ width:35,position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleStaffOrder('id')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Staff ID <HeaderArrow orderBy='id' /></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleStaffOrder('name')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Staff Name <HeaderArrow orderBy='name' /></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleStaffOrder('lastSession')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Last Session <HeaderArrow orderBy='lastSession' /></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleStaffOrder('daily')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Daily <HeaderArrow orderBy='daily' /></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleStaffOrder('weekly')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Weekly <HeaderArrow orderBy='weekly' /></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleStaffOrder('monthly')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Monthly <HeaderArrow orderBy='monthly' /></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {_.filter(getStaff(), (staff:any) => staff.sessions.length > 0).map((staff:any) => (
                  <Table.Row key={staff.recordID}>
                    <Table.Cell><Checkbox className={staff.checked ? `color-${staff.color.replace(/[^0-9a-z]/g, '')}` : ''} checked={staff.checked} onClick={() => this.props.checkStaff(staff.recordID)} /></Table.Cell>
                    <Table.Cell>{staff.recordID}</Table.Cell>
                    <Table.Cell>
                      {this.props.mode == 'dual' ? <Popup
                        hideOnScroll
                        on='click'
                        trigger={<div className='link' style={{whiteSpace:'normal'}}>{staff.name}</div>}
                      >
                        <Popup.Header>Open in pane</Popup.Header>
                        <Popup.Content>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchStaff(1, staff.recordID) }}>1</Label>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchStaff(2, staff.recordID) }}>2</Label>
                        </Popup.Content>
                      </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchStaff(this.props.mode == 'left' ? 1 : 2, staff.recordID) }}>{staff.name}</div>}
                    </Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'normal'}}>{staff.lastSession}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'normal'}}>{staff.daily}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'normal'}}>{staff.weekly}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'normal'}}>{staff.monthly}</div></Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { staff: state.admin.staff, options: state.admin.staff.options, filters: state.admin.staff.filters, tab: state.drawer.tab, mode: state.panes.mode } },
  { ...DrawerStore.actionCreators, ...AdminStore.actionCreators, ...PanesStore.actionCreators }
)(StaffActivity as any)