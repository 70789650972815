import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as ClassesStore from '../../store/reducers/classes'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns'
import {
  Form
} from 'semantic-ui-react'

type TableProps =
    { field: string, name: string, label: string, width: 1|2|3|4|5|6|7|8 } &
    ClassesStore.ClassFilters &
    typeof ClassesStore.actionCreators &
    WithRouterProps<{}>

class FormDatePicker extends React.PureComponent<TableProps> {
    public render() {
        return (
          <Form.Field
            label={this.props.label}
            control={DatePicker}
            selected={this.props.field ? new Date(this.props.field) : null}
            onChange={(date:any) => this.props.setFilter(this.props.name, date ? format(date, 'MM/dd/yyyy') : null)}
            width={2}
          />
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { field: state.classes.filters[ownProps.name], ...ownProps } },
  ClassesStore.actionCreators
)(FormDatePicker as any)