import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as ClassesStore from '../../store/reducers/classes'
import ClassFilters from './ClassFilters'
import TemplateGroup from './TemplateGroup'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Form,
  Modal,
  Search,
  Table,
  Dimmer,
  Loader,
  Button,
  Radio
} from 'semantic-ui-react'
const _ = require('underscore')

type TableProps =
    { open: boolean, loading: boolean, hasMore: boolean, staff: any, modals: any, tab: string, filters: ClassesStore.ClassFilters, courses: ClassesStore.Course[] } &
    typeof ClassesStore.actionCreators &
    WithRouterProps<{}>

class TemplatesTable extends React.PureComponent<TableProps> {
    public render() {
      return (
        <Fragment>
          <ClassFilters />
          <div id='templates-table' style={{top:this.props.open?200:70}}>
            <Dimmer inverted active={this.props.loading}><Loader inverted content='Loading templates ...' /></Dimmer>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.props.fetchNextTemplates}
              hasMore={!this.props.loading && this.props.hasMore}
              initialLoad={false}
              useWindow={false}
              getScrollParent={() => document.getElementById('templates-table') }
            >
              <Table compact celled striped style={{borderTop:0,marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Facilities</div></Table.HeaderCell>
                    <Table.HeaderCell width={6} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Class Name</div></Table.HeaderCell>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,zIndex:1,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Actions</div></Table.HeaderCell>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Funds</div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.courses.map((course:any, c:number) => (
                    <Fragment key={c}><TemplateGroup course={course} /></Fragment>
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </div>
          <Modal
            open={this.props.modals.waitlist.open}
            onClose={this.props.toggleWaitlist}
            size="small"
          >
            <Modal.Header content={`Waitlist`} />
            <Modal.Content>
              <Table compact celled striped style={{marginBottom:20}}>
                <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>SID</Table.HeaderCell>
                  <Table.HeaderCell>Facility</Table.HeaderCell>
                  <Table.HeaderCell>Housing</Table.HeaderCell>
                  <Table.HeaderCell>Date Added</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.modals.waitlist.inmates.filter((waitlist:any) => this.props.modals.waitlist.all ? true : this.props.staff.facilities.includes(waitlist.facilityAbbr)).map((waitlist:any, i:number) => (
                    <Table.Row key={waitlist.recordID}>
                      <Table.Cell>{i+1}</Table.Cell>
                      <Table.Cell><div className='link'>{`${waitlist.lastName}, ${waitlist.firstName}`}</div></Table.Cell>
                      <Table.Cell>{waitlist.sid}</Table.Cell>
                      <Table.Cell>{waitlist.facilityAbbr}</Table.Cell>
                      <Table.Cell>{waitlist.housing}</Table.Cell>
                      <Table.Cell>{waitlist.dateAdded}</Table.Cell>
                      <Table.Cell><Button negative size='mini' content='Remove' onClick={_.debounce(() => this.props.removeWaitlist(waitlist.recordID), 10000, true)} /></Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Form>
                <Form.Group>
                  <Search
                    placeholder='Add Inmate'
                    loading={this.props.modals.waitlist.loading}
                    onResultSelect={(e:any, data:any) => this.props.selectInmate(data.result)}
                    onSearchChange={(e:any, data:any) => this.props.searchInmates(data.value as string)}
                    results={this.props.modals.waitlist.search}
                    value={this.props.modals.waitlist.value}
                    onFocus={this.props.clearInmates}
                  />
                  <Button positive content='Add Inmate' onClick={_.debounce(this.props.addWaitlist, 10000, true)} style={{marginLeft:10}} />
                  <label style={{marginTop:8,marginLeft:10,fontWeight:700}}>My Facilities</label>
                  <Radio toggle checked={this.props.modals.waitlist.all} onChange={(e:any, data:any) => this.props.waitlistFacilityToggle(data.checked)} style={{verticalAlign:'middle',fontWeight:700,marginTop:7,marginLeft:10}} />
                  <label style={{marginTop:8,marginLeft:10,fontWeight:700}}>All Facilities</label>
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleWaitlist}>Close</Button>
            </Modal.Actions>
          </Modal>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { open: state.classes.open, loading: state.classes.tabs.templates.loading, hasMore: state.classes.tabs.templates.hasMore, modals: state.classes.modals, tab: state.drawer.tab, courses: state.classes.tabs.templates.data, staff: state.staff.staff } },
  ClassesStore.actionCreators
)(TemplatesTable as any)