import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Page,
  View,
  BlobProvider,
  Document,
  Text,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import {
  Dimmer,
  Loader,
  Icon
} from 'semantic-ui-react'
import { format } from "date-fns";
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { pdf: any, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class DoePDF extends React.PureComponent<ReportProps> {
    public render() {
      return (
        <Fragment>
          {this.props.pdf.generating ? <div className='pdf generating' style={{display:'inline-block',verticalAlign:'top',marginLeft:10}}>
            <Icon.Group>
              <Icon name='circle notch' color='grey' loading style={{fontSize:'2.5em'}} />
              <Icon name='file pdf outline' color='grey' style={{fontSize:'1.4em',marginTop:1,marginLeft:-1}} />
            </Icon.Group>
          </div> : null}
          {this.props.pdf.ready ? <BlobProvider document={this.doc()}>
            {(data:any) => {
              return (
                <a target='_window' href={data.url} style={{marginLeft:10}}>
                  <Icon name='file pdf outline' size='big' />
                </a>
              )
            }}
          </BlobProvider> : null}
        </Fragment>
      )
    }

    public doc() {
        const styles = StyleSheet.create({
          page: {
            paddingTop: 30,
            paddingBottom: 30,
          },
          body: {
            flexDirection: "column",
            fontSize: 8,
          },
          block: {
            flexDirection: "row",
            marginBottom: 10
          },
          box: {
            flexDirection: "column",
          },
          text: {
            paddingVertical: 1,
            paddingHorizontal: 2,
          },
          textbox: {
            border: 1,
            borderTop: 0,
            borderLeft: 0,
          },
          addressSpacing: {
            justifyContent: "flex-start",
            paddingRight: 20
          },
          borderLeft: {
            borderLeft: 1,
          },
          alignRight: {
            textAlign: "right",
          },
          alignCenter: {
            textAlign: "center",
          },
          flex1: {
            flex: 1,
          },
          flex2: {
            flex: 2,
          },
          flex3: {
            flex: 3,
          },
          flex4: {
            flex: 4,
          },
          flex5: {
            flex: 5,
          },
          flex6: {
            flex: 6,
          },
          flex7: {
            flex: 7,
          },
          flex8: {
            flex: 8,
          },
          flex9: {
            flex: 9,
          },
          flex10: {
            flex: 10,
          },
          check_image: {
            width: "7pt",
            height: "7pt"
          },
          left_image: {
            width: "54pt",
          },
          footerCenter: {
            position: 'absolute',
            textAlign: 'center',
            bottom: 10,
            fontSize: 8,
            color: 'grey'
          },
          footerLeft: {
            position: 'absolute',
            bottom: 10,
            left: 10,
            fontSize: 8,
            color: 'grey'
          },
          footerRight: {
            position: 'absolute',
            bottom: 10,
            right: 10,
            fontSize: 8,
            color: 'grey'
          }
        });
      
        const dayOfWeek1 = (day:number) => {
          switch(day) {
            case 0: {
              return 'S'
            }
            case 1: {
              return 'M'
            }
            case 2: {
              return 'T'
            }
            case 3: {
              return 'W'
            }
            case 4: {
              return 'Th'
            }
            case 5: {
              return 'F'
            }
            case 6: {
              return 'Sa'
            }
            default: {
              return ''
            }
          }
        }
      
        const dayOfWeek3 = (day:number) => {
          switch(day) {
            case 0: {
              return 'Sun'
            }
            case 1: {
              return 'Mon'
            }
            case 2: {
              return 'Tue'
            }
            case 3: {
              return 'Wed'
            }
            case 4: {
              return 'Thu'
            }
            case 5: {
              return 'Fri'
            }
            case 6: {
              return 'Sat'
            }
            default: {
              return ''
            }
          }
        }

        const quarterEnd = (dateStart:any) => {
          var date = new Date()
          if (dateStart) {
            var date = new Date(dateStart)
          }
          var quarter = Math.floor((date.getMonth() / 3))
          var firstDate = new Date(date.getFullYear(), quarter * 3, 1)
          var endDate = new Date(firstDate.getFullYear(), firstDate.getMonth() + 3, 0)
          return endDate
        }
      
        let data = this.props.pdf.data

        const doc = (
          <Document>
            {data.rosters && data.rosters.length > 0 ? (
              data.rosters.map((roster: any) => (
                <Page size="LETTER" style={styles.page} key={roster.recordID}>
                  <View style={styles.body}>
                    <Fragment key={roster.recordID}>
                      <View style={styles.block}>
                        <View style={[styles.flex1]} />
                        <View style={[styles.flex4, styles.addressSpacing, styles.block]}>
                          <View style={[styles.flex1]}>
                            <Image style={[styles.left_image]} src="/assets/HawaiiSeal.png"></Image>
                          </View>
                          <View style={[styles.flex1]}>
                            <View style={[styles.box]}>
                              <Text style={[styles.text]}>McKinley CSA</Text>
                              <Text style={[styles.text]}>635 Pensacola</Text>
                              <Text style={[styles.text]}>Honolulu, HI 96814</Text>
                              <Text style={[styles.text]}>Moanalua Campus</Text>
                            </View>
                          </View>
                        </View>
                        <View style={[styles.flex8, styles.box]}>
                          <View style={[styles.flex1, styles.block]}>
                            <View style={[styles.flex2, styles.block]}>
                              <Text>Class: </Text>
                              <Text>{roster.className}</Text>
                            </View>
                            <View style={[styles.flex1, styles.block]}>
                              <Text>Days of the Week: </Text>
                              <Text>{roster.schedules.map((schedule:any) => dayOfWeek1(schedule.dayOfWeek)).join(" / ")}</Text>
                            </View>
                          </View>
                          <View style={[styles.flex1, styles.block]}>
                            <View style={[styles.flex2, styles.block]}>
                              <Text>Instructor: </Text>
                              <Text>{roster.instructorName}</Text>
                            </View>
                            <View style={[styles.flex1, styles.block]}>
                              <Text>Location: </Text>
                              <Text>{roster.facilityAbbr}</Text>
                            </View>
                          </View>
                          <View style={[styles.flex8, styles.block]}>
                            <View style={[styles.flex1, styles.block]}>
                              <Text>Start Date: </Text>
                              <Text>{roster.dateStart ? roster.dateStart : null}</Text>
                            </View>
                            <View style={[styles.flex1, styles.block]}>
                              <Text>End Date: </Text>
                              <Text>{roster.dateEnd ? roster.dateEnd : format(quarterEnd(roster.dateStart), "MM/dd/yyyy")}</Text>
                            </View>
                            <View style={[styles.flex1, styles.block]}>
                              <Text>Class Times: </Text>
                              <View style={[styles.box]}>
                                {roster.schedules.map((schedule:any) =>
                                  schedule.times.map((time:any, i:number) => (
                                    <Text key={i}>{dayOfWeek3(schedule.dayOfWeek)}: {time.startTime} - {time.endTime}</Text>
                                  ))
                                )}
                              </View>
                            </View>
                          </View>
                        </View>
                        <View style={[styles.flex1]} />
                      </View>
    
                      <View style={styles.block}>
                        <View style={[styles.flex1]} />
                        <View style={[styles.flex8, styles.box]}>
                          <View style={styles.block}>
                            <Text>Intake Date: </Text>
                            <Text>{roster.entryDate ? roster.entryDate : format(new Date(), "MM/dd/yyyy")}</Text>
                          </View>
                          <View style={styles.block}>
                            <Text>SSN: </Text>
                            <Text>{roster.ssn}</Text>
                          </View>
                          <View style={[styles.block]}>
                            <View style={[styles.box, styles.flex1]}>
                              <View style={styles.block}>
                                <Text>Name: </Text>
                                <View style={[styles.box]}>
                                  <Text>{roster.lastName}</Text>
                                  <Text>Last Name</Text>
                                </View>
                              </View>
                            </View>
                            <View style={[styles.box, styles.flex1]}>
                              <Text>{roster.firstName}</Text>
                              <Text>First Name</Text>
                            </View>
                            <View style={[styles.box, styles.flex1]}>
                              <Text>{roster.mi}</Text>
                              <Text>MI</Text>
                            </View>
                          </View>
                          <View style={[styles.block]}>
                            <View style={[styles.box, styles.flex1]}>
                              <View style={[styles.block]}>
                                <Text>Birth Date: </Text>
                                <Text>{roster.dob}</Text>
                              </View>
                            </View>
                            <View style={[styles.box, styles.flex2]}>
                              <View style={[styles.block]}>
                                <Text>Age: </Text>
                                <Text>{roster.age}</Text>
                              </View>
                            </View>
                          </View>
                          <View style={[styles.block]}>
                            <View style={[styles.box, styles.flex2]}>
                              <View style={[styles.block]}>
                                <Text>Education: </Text>
                                <View style={[styles.box]}>
                                  <View style={[styles.block]}>
                                    <Text>Last School Attended: </Text>
                                    <Text>{roster.lastHS}</Text>
                                  </View>
                                  <View style={[styles.block]}>
                                    <Text>Last Grade Completed: </Text>
                                    <Text>{roster.highGradeCompleted}</Text>
                                  </View>
                                </View>
                              </View>
                            </View>
                            <View style={[styles.box, styles.flex1]}>
                              <View style={[styles.block]}>
                                <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                                <Text>U.S.-Based School</Text>
                              </View>
                              <View style={[styles.block]}>
                                <Image style={styles.check_image} src="/assets/blank.jpg" />
                                <Text>Non U.S.-Based School</Text>
                              </View>
                            </View>
                          </View>
                        </View>
                        <View style={[styles.flex4, styles.box]}>
                          <Text>TABE Scores:</Text>
                          <View style={[styles.block]}>
                            <View style={[styles.flex2]}>
                              <Text>Date</Text>
                            </View>
                            <View style={[styles.flex2]}>
                              <Text>Subtest</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>L/F</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>Scale</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>GE</Text>
                            </View>
                          </View>
                          {roster.reTABE ? <View style={[styles.block]}>
                            <View style={[styles.flex2]}>
                              <Text>{roster.reTABE.date}</Text>
                            </View>
                            <View style={[styles.flex2]}>
                              <Text>Reading</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>{roster.reTABE.lf}</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>{roster.reTABE.ss}</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>{roster.reTABE.ge}</Text>
                            </View>
                          </View> : null}
                          {roster.laTABE ? <View style={[styles.block]}>
                            <View style={[styles.flex2]}>
                              <Text>{roster.laTABE.date}</Text>
                            </View>
                            <View style={[styles.flex2]}>
                              <Text>Language</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>{roster.laTABE.lf}</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>{roster.laTABE.ss}</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>{roster.laTABE.ge}</Text>
                            </View>
                          </View> : null}
                          {roster.tmTABE ? <View style={[styles.block]}>
                            <View style={[styles.flex2]}>
                              <Text>{roster.tmTABE.date}</Text>
                            </View>
                            <View style={[styles.flex2]}>
                              <Text>Mathematics</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>{roster.tmTABE.lf}</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>{roster.tmTABE.ss}</Text>
                            </View>
                            <View style={[styles.flex1]}>
                              <Text>{roster.tmTABE.ge}</Text>
                            </View>
                          </View> : null}
                        </View>
                        <View style={[styles.flex1]} />
                      </View>
                      <View style={[styles.box, styles.flex4]}>
                        <View style={[styles.block]}>
                          <View style={[styles.flex1]} />
                          <View style={{flex:12}}><Text>Check all that apply:</Text></View>
                          <View style={[styles.flex1]} />
                        </View>
                        <View style={[styles.block]}>
                          <View style={[styles.flex1]} />
                          <View style={[styles.box, styles.flex4]}>
                            <View style={[styles.block]}>
                              <View style={[styles.box]}></View>
                              <View style={[styles.box]}>
                                <View style={[styles.block]}>
                                  {roster.ethnicity === "I" ? (
                                    <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                                  ) : (
                                    <Image style={styles.check_image} src="/assets/blank.jpg" />
                                  )}
                                  <Text>American Indian/Alaskan Native</Text>
                                </View>
                                <View style={[styles.block]}>
                                  {roster.race === "B" ? (
                                    <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                                  ) : (
                                    <Image style={styles.check_image} src="/assets/blank.jpg" />
                                  )}
                                  <Text>Black/Africa American</Text>
                                </View>
                                <View style={[styles.block]}>
                                  {roster.ethnicity === "C" ? (
                                    <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                                  ) : (
                                    <Image style={styles.check_image} src="/assets/blank.jpg" />
                                  )}
                                  <Text>Chinese</Text>
                                </View>
                                <View style={[styles.block]}>
                                  {roster.ethnicity === "F" ? (
                                    <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                                  ) : (
                                    <Image style={styles.check_image} src="/assets/blank.jpg" />
                                  )}
                                  <Text>Filipino</Text>
                                </View>
                                <View style={[styles.block]}>
                                  {roster.ethnicity === "H" ? (
                                    <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                                  ) : (
                                    <Image style={styles.check_image} src="/assets/blank.jpg" />
                                  )}
                                  <Text>Hawaiian/Part Hawaiian</Text>
                                </View>
                              </View>
                            </View>
                          </View>
                          <View style={[styles.box, styles.flex4]}>
                            <View style={[styles.block]}>
                              {roster.race === "H" ? (
                                <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                              ) : (
                                <Image style={styles.check_image} src="/assets/blank.jpg" />
                              )}
                              <Text>Hispanic</Text>
                            </View>
                            <View style={[styles.block]}>
                              <Image style={styles.check_image} src="/assets/blank.jpg" />
                              <Text>Indo Chinese</Text>
                            </View>
                            <View style={[styles.block]}>
                              {roster.ethnicity === "J" ? (
                                <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                              ) : (
                                <Image style={styles.check_image} src="/assets/blank.jpg" />
                              )}
                              <Text>Japanese</Text>
                            </View>
                            <View style={[styles.block]}>
                              {roster.ethnicity === "K" ? (
                                <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                              ) : (
                                <Image style={styles.check_image} src="/assets/blank.jpg" />
                              )}
                              <Text>Korean</Text>
                            </View>
                            <View style={[styles.block]}>
                              {roster.ethnicity === "MICRONESIAN" ? (
                                <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                              ) : (
                                <Image style={styles.check_image} src="/assets/blank.jpg" />
                              )}
                              <Text>Micronesian</Text>
                            </View>
                          </View>
                          <View style={[styles.box, styles.flex4]}>
                            <View style={[styles.block]}>
                              {roster.ethnicity === "A" ? (
                                <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                              ) : (
                                <Image style={styles.check_image} src="/assets/blank.jpg" />
                              )}
                              <Text>Other Asian</Text>
                            </View>
                            <View style={[styles.block]}>
                              {roster.race === "O" ? (
                                <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                              ) : (
                                <Image style={styles.check_image} src="/assets/blank.jpg" />
                              )}
                              <Text>Other Minorities</Text>
                            </View>
                            <View style={[styles.block]}>
                              {roster.ethnicity === "G" ? (
                                <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                              ) : (
                                <Image style={styles.check_image} src="/assets/blank.jpg" />
                              )}
                              <Text>Other Pacific Islander</Text>
                            </View>
                            <View style={[styles.block]}>
                              {roster.ethnicity === "S" ? (
                                <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                              ) : (
                                <Image style={styles.check_image} src="/assets/blank.jpg" />
                              )}
                              <Text>Samoan</Text>
                            </View>
                            <View style={[styles.block]}>
                              {roster.race === "W" ? (
                                <Image style={styles.check_image} src="/assets/check-mark-icon.jpg" />
                              ) : (
                                <Image style={styles.check_image} src="/assets/blank.jpg" />
                              )}
                              <Text>White</Text>
                            </View>
                          </View>
                          <View style={[styles.flex1]} />
                        </View>
                      </View>
                    </Fragment>
                  </View>
                  <View fixed style={styles.footerCenter}>
                    <Text>{this.props.settings.copyright}</Text>
                  </View>
                  <View fixed style={styles.footerLeft}>
                    <Text>{new Date().today() + " @ " + new Date().timeNow()}</Text>
                  </View>
                  <View fixed style={styles.footerRight}>
                    <Text>{data.staffID} / {data.printID}</Text>
                  </View>
                </Page>
              ))
            ) : (
              <Page>
                <Text>No roster selected</Text>
                <View fixed style={styles.footerCenter}>
                  <Text>{this.props.settings.copyright}</Text>
                </View>
                <View fixed style={styles.footerLeft}>
                  <Text>{new Date().today() + " @ " + new Date().timeNow()}</Text>
                </View>
                <View fixed style={styles.footerRight}>
                  <Text>{data.staffID} / {data.printID}</Text>
                </View>
              </Page>
            )}
          </Document>
        );
    
        return doc
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { pdf: ownProps.pdf, options: ownProps.options, settings: state.staff.settings } },
  ReportsStore.actionCreators
)(DoePDF as any)
