import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import {
  Button,
  Form,
  Input
} from 'semantic-ui-react'

type LogProps =
    { settings: any, loading: boolean, hasMore: boolean } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class AppSite extends React.PureComponent<LogProps> {
    public componentDidMount() {
        this.props.fetchAppSettings()
    }

    public render() {
      return (
        <Fragment>
          <Form>
            <Form.Group>
              <Form.Field
                control={Input}
                label="Department Name"
                name="deptName"
                value={this.props.settings.data.deptName}
                onChange={(e:any, data:any) => this.props.updateSetting('deptName', data.value)}
                width={6}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                label="Copyright Text"
                name="copyright"
                value={this.props.settings.data.copyright}
                onChange={(e:any, data:any) => this.props.updateSetting('copyright', data.value)}
                width={6}
              />
            </Form.Group>
            <Button positive content='Save' onClick={() => this.props.saveSettings()} />
          </Form>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { settings: state.admin.settings, loading: state.admin.logs.pdfs.loading, hasMore: state.admin.logs.pdfs.hasMore } },
  AdminStore.actionCreators
)(AppSite as any)
