import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import ClassRosterInmates from './ClassRosterInmates'
import ClassRosterBulk from './ClassRosterBulk'
import ClassRosterEdit from './ClassRosterEdit'
import ClassEnrollmentForms from './ClassEnrollmentForms'
import ClassNotificationLetters from './ClassNotificationLetters'
import {
  Container,
  Table,
  Checkbox,
  Popup,
  Label,
  Icon,
  Button,
  Header
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { class: PanesStore.Class, num: 1 | 2, print: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassRoster extends React.PureComponent<RosterProps> {
    /*public componentDidMount() {
        this.props.refreshClass(this.props.num, this.props.class.info.recordID)
        this.props.fetchRosters(this.props.num, this.props.class.info.recordID)
        this.props.fetchClassWaitlist(this.props.num, this.props.class.info.recordID)
        this.props.fetchClassApprovals(this.props.num, this.props.class.info.recordID)
    }*/

    public render() {
        return (
          <Container fluid>
            <div className='ui secondary menu'>
              <a className={`item ${this.props.class.roster.active.includes('inmates') ? 'active' : ''}`} onClick={() => this.props.toggleTab(this.props.num, 'inmates')}>Inmates</a>
              <a className={`item ${this.props.class.roster.active.includes('bulk') && !this.props.print ? 'active' : ''}`} onClick={() => this.props.toggleTab(this.props.num, 'bulk')}>Bulk Edit</a>
              <a className={`item ${this.props.class.roster.active.includes('notification') && !this.props.print ? 'active' : ''}`} onClick={() => this.props.toggleTab(this.props.num, 'notification')}>Notification Letters</a>
              {this.props.class.data.doe ? <a className={`item ${this.props.class.roster.active.includes('doe') && !this.props.print ? 'active' : ''}`} onClick={() => this.props.toggleTab(this.props.num, 'doe')}>DOE Enrollment Forms</a> : null}
              {this.props.class.roster.active.includes('edit') ? <a className={`item ${this.props.class.roster.active.includes('edit') && !this.props.print ? 'active' : ''}`} onClick={() => this.props.toggleTab(this.props.num, 'edit')}>Edit</a> : null}
            </div>
            {this.props.class.roster.active.includes('edit') && !this.props.print ? <ClassRosterEdit num={this.props.num} class={this.props.class} /> : null}
            {this.props.class.roster.active.includes('doe') && !this.props.print ? <ClassEnrollmentForms num={this.props.num} class={this.props.class} /> : null}
            {this.props.class.roster.active.includes('notification') && !this.props.print ? <ClassNotificationLetters num={this.props.num} class={this.props.class} /> : null}
            {this.props.class.roster.active.includes('bulk') && !this.props.print ? <ClassRosterBulk num={this.props.num} class={this.props.class} /> : null}
            {this.props.class.roster.active.includes('inmates') ? <ClassRosterInmates num={this.props.num} class={this.props.class} print={this.props.print} /> : null}
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, print: ownProps.print } },
  PanesStore.actionCreators
)(ClassRoster as any)