import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Button,
  Table,
  Icon,
  Grid,
  Header,
  Form,
  Select,
  Input,
  Modal
} from 'semantic-ui-react'
const _ = require('underscore')

type StaffProps =
    { holidays: any } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class Holidays extends React.PureComponent<StaffProps> {
    public componentDidMount() {
        this.props.fetchHolidays()
    }

    public render() {
      return (
        <div style={{overflow:'auto'}}>
          <Grid>
            <Grid.Column width={16}>
              <Header as="h2" style={{paddingTop: 20}}><Icon name="calendar minus" /> Holidays and Furlough Days</Header>
              <Form>
                <Form.Group>
                  <Form.Field
                    label="Date"
                    control={DatePicker}
                    selected={this.props.holidays.new.date ? new Date(this.props.holidays.new.date) : null}
                    onChange={(date:any) => this.props.updateHoliday('date', date ? format(date, 'MM/dd/yyyy') : null)}
                  />
                  <Form.Field
                    control={Select}
                    label="Type"
                    placeholder="Type"
                    options={[{key:'H',value:'H',text:'Holiday'},{key:'F',value:'F',text:'Furlough Day'}]}
                    value={this.props.holidays.new.type}
                    onChange={(e:any, data:any) => this.props.updateHoliday('type', data.value as string)}
                  />
                  <Form.Field
                    control={Input}
                    label="Name"
                    placeholder="Name"
                    value={this.props.holidays.new.name}
                    onChange={(e:any, data:any) => this.props.updateHoliday('name', data.value.validate())}
                  />
                  <Button positive content="Add Holiday" onClick={_.debounce(this.props.addHoliday, 10000, true)} style={{marginTop:18}} />
                </Form.Group>
              </Form>
              <Table celled striped selectable style={{ marginTop: "1em" }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1}>Date</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Type</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.holidays.list.map((data:any) => (
                    <Table.Row key={data.recordID}>
                      <Table.Cell onClick={() => this.props.openHolidayModal(data)}>{data.date}</Table.Cell>
                      <Table.Cell onClick={() => this.props.openHolidayModal(data)}>{data.type}</Table.Cell>
                      <Table.Cell onClick={() => this.props.openHolidayModal(data)}>{data.name}</Table.Cell>
                      <Table.Cell style={{textAlign:'center'}}><Button size='mini' color="red" content="Remove" onClick={_.debounce(() => this.props.removeHoliday(data.recordID), 10000, true)} /></Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
          <Modal
            open={this.props.holidays.modals.edit.open}
            onClose={this.props.toggleHolidayModal}
            size="tiny"
          >
            <Modal.Header content={`Edit Holiday`} />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    label="Date"
                    control={DatePicker}
                    selected={this.props.holidays.modals.edit.data.date ? new Date(this.props.holidays.modals.edit.data.date) : null}
                    onChange={(date:any) => this.props.updateHolidayModal('date', date ? format(date, 'MM/dd/yyyy') : null)}
                  />
                  <Form.Field
                    control={Select}
                    label="Type"
                    placeholder="Type"
                    options={[{key:'H',value:'H',text:'Holiday'},{key:'F',value:'F',text:'Furlough Day'}]}
                    value={this.props.holidays.modals.edit.data.type}
                    onChange={(e:any, data:any) => this.props.updateHolidayModal('type', data.value as string)}
                  />
                  <Form.Field
                    control={Input}
                    label="Name"
                    placeholder="Name"
                    value={this.props.holidays.modals.edit.data.name}
                    onChange={(e:any, data:any) => this.props.updateHolidayModal('name', data.value.validate())}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleHolidayModal}>Close</Button>
              <Button
                positive
                content="Save"
                onClick={_.debounce(this.props.saveHoliday, 10000, true)}
              />
            </Modal.Actions>
          </Modal>
        </div>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { holidays: state.admin.holidays } },
  AdminStore.actionCreators
)(Holidays as any)