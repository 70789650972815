import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as InmatesStore from '../../store/reducers/inmates'
import FormInput from './FormInput'
import FormSelect from './FormSelect'
import FormDatePicker from './FormDatePicker'
import {
  Container,
  Button,
  Form
} from 'semantic-ui-react'
const _ = require('underscore')

type FilterProps =
    { facilities: any[], sorts: any[], open: boolean, tab: string } &
    InmatesStore.InmateFilters &
    typeof InmatesStore.actionCreators &
    WithRouterProps<{}>

class InmateFilters extends React.PureComponent<FilterProps> {
    public componentDidMount() {
      this.props.loadFilters()
    }

    public render() {
      if (this.props.open) {
        return (
          <Form style={{width:'100%'}} id='filters'>
            <Form.Group>
              <FormInput name='lastName' label='Last name' placeholder='Last name' width={2} />
              <FormInput name='firstName' label='First name' placeholder='First name' width={2} />
              <FormInput name='sid' label='SID' placeholder='SID' width={2} />
              <FormSelect name='gender' label='Gender' placeholder='Gender' width={2} fluid
                options={[{key:0,value:'B',text:'Both'},{key:1,value:'M',text:'Male'},{key:2,value:'F',text:'Female'}]} />
              <FormSelect name='facility' label='Facility' placeholder='Facility' width={2} fluid search
                options={[{ key: 'all', value: 'all', text: 'All facilities' },{ key: 'my', value: 'my', text: 'My facilities' },{ key: 'released', value: 'released', text: 'Released' }].concat(this.props.facilities)} />
              <FormDatePicker name='dobFrom' label='DoB From' width={2} />
              <FormDatePicker name='dobTo' label='DoB To' width={2} />
              <FormSelect name='active' label='Active' placeholder='Active' width={2} fluid
                options={[{key:0,value:true,text:'Active inmates'},{key:1,value:false,text:'All inmates'}]} />
            </Form.Group>
            <Form.Group>
              {this.props.tab == 'inmates' ? <FormSelect name='searchType' label='Search type' placeholder='Search type' width={2} fluid
                options={[{key:0,value:'starts',text:'Starts with'},{key:1,value:'contains',text:'Contains'}]} /> : null}
              {this.props.tab == 'inmates' ? <Form.Select fluid multiple label='Sort' placeholder='Columns'
                options={[{key:0,value:'name',text:'Name'},{key:1,value:'sid',text:'SID'},{key:2,value:'facility',text:'Facility'},{key:3,value:'age',text:'Age'},{key:4,value:'housing',text:'Housing'},{key:5,value:'highSchool',text:'High School'},{key:6,value:'college',text:'College'},{key:7,value:'tabe',text:'TABE'},{key:8,value:'ged',text:'GED'},{key:9,value:'hiset',text:'HiSET'},{key:10,value:'riasec',text:'RIASEC'},{key:11,value:'refused',text:'Refused'},{key:12,value:'pretrial',text:'Pretrial'}]}
                value={_.map(this.props.sorts, (sort:any) => sort.column)}
                onChange={(e, { value }) => this.props.setOrder(value as string[])}
                width={6}
              /> : null}
              {this.props.tab == 'tabe' ? <Form.Select fluid multiple label='Sort' placeholder='Columns'
                options={[{key:0,value:'name',text:'Name'},{key:1,value:'facility',text:'Facility'},{key:2,value:'date',text:'Date'},{key:3,value:'relf',text:'ReLF'},{key:4,value:'ress',text:'ReSS'},{key:5,value:'rege',text:'ReGE'},{key:6,value:'renrs',text:'ReNRS'},{key:7,value:'tmlf',text:'TmLF'},{key:8,value:'tmss',text:'TmSS'},{key:9,value:'tmge',text:'TmGE'},{key:10,value:'tmnrs',text:'TmNRS'},{key:11,value:'lalf',text:'LaLF'},{key:12,value:'lass',text:'LaSS'},{key:13,value:'lage',text:'LaGE'},{key:14,value:'lanrs',text:'LaNRS'}]}
                value={_.map(this.props.sorts, (sort:any) => sort.column)}
                onChange={(e, { value }) => this.props.setOrder(value as string[])}
                width={4}
              /> : null}
              {this.props.tab == 'ged' ? <Form.Select fluid multiple label='Sort' placeholder='Columns'
                options={[{key:0,value:'name',text:'Name'},{key:1,value:'date',text:'Date'},{key:2,value:'facility',text:'Facility'},{key:3,value:'social',text:'Social'},{key:4,value:'science',text:'Science'},{key:5,value:'literature',text:'Literature'},{key:6,value:'math',text:'Math'},{key:7,value:'civics',text:'CivicsAZ'},{key:8,value:'practice',text:'Practice'}]}
                value={_.map(this.props.sorts, (sort:any) => sort.column)}
                onChange={(e, { value }) => this.props.setOrder(value as string[])}
                width={4}
              /> : null}
              {this.props.tab == 'hiset' ? <Form.Select fluid multiple label='Sort' placeholder='Columns'
                options={[{key:0,value:'name',text:'Name'},{key:1,value:'facility',text:'Facility'},{key:2,value:'date',text:'Date'},{key:3,value:'math',text:'Math'},{key:4,value:'science',text:'Science'},{key:5,value:'social',text:'Social'},{key:6,value:'reading',text:'Reading'},{key:7,value:'writing',text:'Writing'},{key:8,value:'essay',text:'Essay'}]}
                value={_.map(this.props.sorts, (sort:any) => sort.column)}
                onChange={(e, { value }) => this.props.setOrder(value as string[])}
                width={4}
              /> : null}
              {this.props.tab == 'riasec' ? <Form.Select fluid multiple label='Sort' placeholder='Columns'
                options={[{key:0,value:'name',text:'Name'},{key:1,value:'date',text:'Date'},{key:2,value:'realistic',text:'Realistic'},{key:3,value:'investigative',text:'Investigative'},{key:4,value:'artistic',text:'Artistic'},{key:5,value:'social',text:'Social'},{key:6,value:'enterprising',text:'Enterprising'},{key:7,value:'conventional',text:'Conventional'}]}
                value={_.map(this.props.sorts, (sort:any) => sort.column)}
                onChange={(e, { value }) => this.props.setOrder(value as string[])}
                width={4}
              /> : null}
              <FormSelect name='education' label='Education status' placeholder='Education status' width={['tabe', 'ged','hiset','riasec'].includes(this.props.tab) ? 4 : 6} fluid multiple
                options={[{key:1,value:'current',text:'Currently enrolled'},{key:2,value:'not',text:'Not currently enrolled'},{key:3,value:'previous',text:'Previously enrolled'},{key:4,value:'never',text:'Never enrolled'},{key:5,value:'recent',text:'Recent arrival'},{key:6,value:'title1',text:'Title 1'},{key:7,value:'abe',text:'ABE student'},{key:8,value:'tabe',text:'Ready for TABE'},{key:9,value:'verified',text:'Verified Diploma/HSE'},{key:10,value:'unverified',text:'Unverified Diploma/HSE'},{key:11,value:'pretrial',text:'In Pretrial'}]} />
              {this.props.tab == 'tabe' ? <Fragment>
                <FormDatePicker name='tabeFrom' label='TABE From' width={2} />
                <FormDatePicker name='tabeTo' label='TABE To' width={2} />
                <FormSelect name='tabeLevel' label='TABE Level' width={2} fluid
                  options={[{key:0,value:'all',text:'All'},{key:1,value:'best',text:'Best'},{key:2,value:'8',text:'7 / 8'},{key:3,value:'10',text:'9 / 10'},{key:4,value:'12',text:'11 / 12'}]} />
              </Fragment> : null}
              {this.props.tab == 'ged' ? <Fragment>
                <FormDatePicker name='gedFrom' label='GED From' width={2} />
                <FormDatePicker name='gedTo' label='GED To' width={2} />
                <FormSelect name='gedFilter' label='GED Filter' width={2} fluid
                  options={[{key:0,value:'all',text:'All'},{key:1,value:'best',text:'Best'}]} />
              </Fragment> : null}
              {this.props.tab == 'hiset' ? <Fragment>
                <FormDatePicker name='hisetFrom' label='HiSET From' width={2} />
                <FormDatePicker name='hisetTo' label='HiSET To' width={2} />
                <FormSelect name='hisetFilter' label='HiSET Filter' width={2} fluid
                  options={[{key:0,value:'all',text:'All'},{key:1,value:'best',text:'Best'}]} />
              </Fragment> : null}
              {this.props.tab == 'riasec' ? <Fragment>
                <FormDatePicker name='riasecFrom' label='RIASEC From' width={2} />
                <FormDatePicker name='riasecTo' label='RIASEC To' width={2} />
              </Fragment> : null}
              <Form.Field
                control={Button}
                color='blue'
                content='Reset filters'
                onClick={this.props.resetFilters}
                width={2}
                style={{marginTop:17}}
              />
            </Form.Group>
          </Form>
        )
      } else {
        return (
          <Fragment></Fragment>
        )
      }
    }
}

export default connect(
  (state: ApplicationState) => { return { sorts: state.inmates.sorts, facilities: state.inmates.tabs.inmates.facilities, open: state.inmates.open, tab: state.drawer.tab } },
  InmatesStore.actionCreators
)(InmateFilters as any)