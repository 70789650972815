import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import * as PanesStore from '../../store/reducers/panes'
import InmateFilters from './InmateFilters'
import RIASECRow from './RIASECRow'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Popup,
  Table,
  Dimmer,
  Loader
} from 'semantic-ui-react'
const _ = require('underscore')

type TableProps =
    { open: boolean, loading: boolean, hasMore: boolean, tab: string, sorts: any, filters: InmatesStore.InmateFilters, inmates: InmatesStore.Inmate[] } &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class RIASECTable extends React.PureComponent<TableProps> {
    private HeaderArrow = (props:any) => {
      var sort = _.find(this.props.sorts, (sort:any) => sort.column == props.orderBy)
      if (sort != null) {
        if (sort.dir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    public render() {
      const HeaderArrow = this.HeaderArrow
      return (
        <Fragment>
          <InmateFilters />
          <div id='riasec-table' style={{top:this.props.open?200:70}}>
            <Dimmer inverted active={this.props.loading}><Loader inverted content="Loading RIASECs ..." /></Dimmer>
            <InfiniteScroll
                pageStart={0}
                loadMore={this.props.fetchNextRIASEC}
                hasMore={!this.props.loading && this.props.hasMore}
                initialLoad={false}
                useWindow={false}
                getScrollParent={() => document.getElementById('riasec-table') }
            >
              <Table compact celled striped style={{borderTop:0,marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Popup content="Click to sort inmates by name" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('name')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Inmate Name<HeaderArrow orderBy='name' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by SID" trigger={<Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>SID</div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by date" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('date')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Test Date<HeaderArrow orderBy='date' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by realistic" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('realistic')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Realistic<HeaderArrow orderBy='realistic' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by investigative" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('investigative')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Investigative<HeaderArrow orderBy='investigative' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by artistic" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('artistic')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Artistic<HeaderArrow orderBy='artistic' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by social" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('social')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Social<HeaderArrow orderBy='social' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by enterprising" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('enterprising')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Enterprising<HeaderArrow orderBy='enterprising' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by conventional" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('conventional')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Conventional<HeaderArrow orderBy='conventional' /></div></Table.HeaderCell>} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.inmates.length > 0 && (this.props.inmates[0] as any).riasecs && (this.props.inmates[0] as any).riasecs.length > 0 ? this.props.inmates.map((inmate:any, i:number) => inmate.riasecs.map((test:any, t:number) => (
                    <RIASECRow inmate={inmate} test={test} key={t} i={i} r={t} />
                  ))) : this.props.inmates.map((tabe:any, t:number) => (
                    <RIASECRow inmate={tabe} test={tabe} key={t} i={t} r={0} />
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </div>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { open: state.inmates.open, loading: state.inmates.tabs.riasec.loading, hasMore: state.inmates.tabs.riasec.hasMore, sorts: state.inmates.sorts, filters: state.inmates.filters, inmates: state.inmates.tabs.riasec.data, tab: state.drawer.tab } },
  { ...DrawerStore.actionCreators, ...InmatesStore.actionCreators, ...PanesStore.actionCreators }
)(RIASECTable as any)