import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../withRouter'
import { history } from '../store/configureStore'
import { ApplicationState } from '../store'
import * as StaffStore from '../store/reducers/staff'
import * as DrawerStore from '../store/reducers/drawer'
import * as PanesStore from '../store/reducers/panes'
import * as DashboardStore from '../store/reducers/dashboard'
import SMSLanding from './landing/SMS'
import SASLanding from './landing/SAS'
import NavBar from './NavBar'
import Drawer from './drawer/Drawer'
import Pane from './Pane'
import {
  Container,
  Image,
  Modal,
  Form,
  Table,
  Button,
  Select,
  Radio,
  Divider,
  Icon
} from 'semantic-ui-react'
import { toast } from 'react-toastify'
const qs = require('qs')
const _ = require('underscore')

type HomeProps =
    { print: boolean, modals: any } &
    StaffStore.StaffState &
    PanesStore.PanesState &
    typeof DrawerStore.actionCreators &
    typeof PanesStore.actionCreators &
    typeof DashboardStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class Home extends React.PureComponent<HomeProps> {
    private keyListener:any;
    private mouseListener:any;

    public componentDidMount() {
      let params = qs.parse(history.location.search.substr(1, history.location.search.length))
      if (params.drawer) {
        this.props.toggleDrawer(params.drawer)
      }
      this.props.loadMode()
      this.mouseListener = this.handleActivity.bind(this);
      this.keyListener = this.handleActivity.bind(this);
      window.addEventListener('mousemove', this.mouseListener);
      window.addEventListener('keyup', this.keyListener);
      this.props.socketConnect()
      setInterval(() => {
        this.props.update()
      }, 10000)
    }

    public componentWillUnmount() {
      window.removeEventListener('mousemove', this.mouseListener);
      window.removeEventListener('keyup', this.keyListener);
      this.props.socketDisconnect()
    }

    public componentDidUpdate(prev:any) {
      if (prev.latest > 0 && prev.latest < this.props.latest) {
        toast.success('OffenderTrak has been updated. Information has been refreshed.')
      }
    }

    public handleActivity() {
      if (this.props.loggedIn) {
        this.props.activity()
      }
    }

    public render() {
      switch (this.props.loggedIn) {
      case true:
          switch (window.location.host.split(".")[0]) {
          case "sms":
              return (
                <Fragment>
                  {/* @ts-ignore */}
                  <Helmet>
                    <title>SMS</title>
                    <link rel="shortcut icon" href="/favicon-sms.ico"></link>
                    {this.props.staff!.theme && this.props.staff!.theme.fontFamily ? <style>{`
                      body {
                        font-family: ${this.props.staff!.theme.fontFamily} !important;
                      }
                    `}</style> : null}
                    {this.props.staff!.theme && this.props.staff!.theme.pageBackgroundColor ? <style>{`
                      body {
                        background-color: ${this.props.staff!.theme.pageBackgroundColor} !important;
                      }
                    `}</style> : null}
                    {this.props.staff!.theme && this.props.staff!.theme.paneBackgroundColor ? <style>{`
                      .pane .active.tab {
                        background-color: ${this.props.staff!.theme.paneBackgroundColor};
                      }
                    `}</style> : null}
                    {this.props.staff!.theme && this.props.staff!.theme.menuBarBackgroundColor ? <style>{`
                      .ui.menu:not(.secondary, .attached) {
                        background-color: ${this.props.staff!.theme.menuBarBackgroundColor} !important;
                      }
                    `}</style> : null}
                    {this.props.staff!.theme && this.props.staff!.theme.menuBarTextColor ? <style>{`
                      .ui.menu:not(.secondary, .attached) .item {
                        color: ${this.props.staff!.theme.menuBarTextColor};
                      }
                    `}</style> : null}
                    {this.props.staff!.theme && this.props.staff!.theme.drawerTextColor ? <style>{`
                      #drawer, #drawer .table, #drawer .label, #drawer .header {
                        color: ${this.props.staff!.theme.drawerTextColor} !important;
                      }
                    `}</style> : null}
                    {this.props.staff!.theme && this.props.staff!.theme.paneTextColor ? <style>{`
                      .pane, .pane .table, .pane .header, .pane label {
                        color: ${this.props.staff!.theme.paneTextColor} !important;
                      }
                    `}</style> : null}
                    {this.props.staff!.theme && this.props.staff!.theme.inputBackgroundColor ? <style>{`
                      input {
                        background: ${this.props.staff!.theme.inputBackgroundColor} !important;
                      }
                      .ui.selection.dropdown {
                        background: ${this.props.staff!.theme.inputBackgroundColor} !important;
                      }
                      .ui.selection.dropdown .menu {
                        background: ${this.props.staff!.theme.inputBackgroundColor} !important;
                      }
                    `}</style> : null}
                    {this.props.staff!.theme && this.props.staff!.theme.inputBorderColor ? <style>{`
                      input {
                        border: thin solid ${this.props.staff!.theme.inputBorderColor} !important;
                      }
                      .ui.selection.dropdown {
                        border: thin solid ${this.props.staff!.theme.inputBorderColor} !important;
                      }
                    `}</style> : null}
                    {this.props.staff!.theme && this.props.staff!.theme.inputTextColor ? <style>{`
                      input {
                        color: ${this.props.staff!.theme.inputTextColor} !important;
                      }
                      .ui.selection.dropdown {
                        color: ${this.props.staff!.theme.inputTextColor} !important;
                      }
                      .ui.selection.dropdown .menu .item span {
                        color: ${this.props.staff!.theme.inputTextColor} !important;
                      }
                    `}</style> : null}
                  </Helmet>
                  {this.props.print ? null : <NavBar key={`bar-${this.props.latest}`} />}
                  <Drawer key={`drawer-${this.props.latest}`} />
                  <Container fluid id='panes'>
                    <Pane key={`p1-${this.props.latest}`} num={1} />
                    <Pane key={`p2-${this.props.latest}`} num={2} />
                  </Container>
                  {this.props.print ? null : <div id='footer'>
                    <div id='pane-arrow' style={{position:'absolute',bottom:15,left:'calc(50% - 7px)'}}><Icon name='arrow up' color={'blue'} /></div>
                    <div id='pane-drawer' style={{position:'absolute',backgroundColor:'#2185d0',overflow:'hidden',width:80,height:0,bottom:0,left:'calc(50% - 40px)',transition:'height .5s',borderRadius:5}}><Image className='mode' src='/assets/left.png' onClick={() => this.props.updateMode('left')} style={{ height:40, marginRight:6, marginTop:4, opacity: this.props.mode == 'left' ? 1 : 0.5 }} /><Image className='mode' src='/assets/dual.png' onClick={() => this.props.updateMode('dual')} style={{ height:40, marginTop:4, opacity: this.props.mode == 'dual' ? 1 : 0.5 }} /><Image className='mode' src='/assets/right.png' onClick={() => this.props.updateMode('right')} style={{ height:40, marginLeft:6, marginTop:4, opacity: this.props.mode == 'right' ? 1 : 0.5 }} /></div>
                    <span>{this.props.settings.copyright}</span>
                  </div>}
                  <Modal
                    open={this.props.modals.survey.open}
                    onClose={this.props.closeSurveyModal}
                  >
                    <Modal.Header content={this.props.modals.survey.name} />
                    <Modal.Content>
                      <Form>
                        {_.map(this.props.modals.survey.grids, (grid:any) => <Fragment>
                          <Table definition compact celled striped>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell />
                                {_.map(grid.options, (option:any) => <Table.HeaderCell>{option.option}</Table.HeaderCell>)}
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {_.map(grid.questions, (question:any) => <Table.Row>
                                <Table.Cell>{question.question}</Table.Cell>
                                {_.map(grid.options, (option:any) => <Table.Cell>
                                  <Form.Field
                                    control={Radio}
                                    checked={question.answerID == option.recordID}
                                    value={option.recordID}
                                    onChange={(e:any, data:any) => this.props.selectGridOption(grid.recordID, question.recordID, data.value as number)}
                                  />
                                </Table.Cell>)}
                              </Table.Row>)}
                            </Table.Body>
                          </Table>
                          <Divider />
                        </Fragment>)}
                        {_.map(this.props.modals.survey.questions, (question:any) => <Form.Group>
                          <Form.Field
                            control={Select}
                            label={question.question}
                            options={[{key:0,value:0,text:''}].concat(_.map(question.options, (option:any) => { return { key: option.recordID, value: option.recordID, text: option.option } }))}
                            value={question.answerID}
                            onChange={(e:any, data:any) => this.props.selectOption(question.recordID, data.value as number)}
                            width={16}
                          />
                        </Form.Group>)}
                      </Form>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button color='black' onClick={this.props.closeSurveyModal}>Close</Button>
                      <Button color='red' onClick={_.debounce(() => this.props.refuseSurvey(), 10000, true)}>Refuse</Button>
                      <Button
                        positive
                        content="Submit"
                        onClick={_.debounce(() => this.props.submitSurvey(), 10000, true)}
                      />
                    </Modal.Actions>
                  </Modal>
                  <chatlio-widget widgetid="dd22dc01-29fb-47ef-5f3d-58b336bd8ec2" widgettype="inlines" />
                  {/*<div id="chatlioWidgetPlaceholder" style={{margin:'auto',height:400,width:400}} />*/}
                </Fragment>
              )
          case "sas":
              return (
                <Fragment>
                  {/* @ts-ignore */}
                  <Helmet>
                    <title>SAS</title>
                    <link rel="shortcut icon" href="/favicon-sas.ico"></link>
                  </Helmet>
                  {this.props.print ? null : <NavBar />}
                  <Drawer />
                  <Container fluid id='panes'>
                    <Pane num={1} />
                    <Pane num={2} />
                  </Container>
                  {this.props.print ? null : <div id='footer'>
                    <div id='pane-arrow' style={{position:'absolute',bottom:15,left:'calc(50% - 7px)'}}><Icon name='arrow up' color={'blue'} /></div>
                    <div id='pane-drawer' style={{position:'absolute',backgroundColor:'#2185d0',overflow:'hidden',width:80,height:0,bottom:0,left:'calc(50% - 40px)',transition:'height .5s',borderRadius:5}}><Image className='mode' src='/assets/left.png' onClick={() => this.props.updateMode('left')} style={{ height:40, marginRight:6, marginTop:4, opacity: this.props.mode == 'left' ? 1 : 0.5 }} /><Image className='mode' src='/assets/dual.png' onClick={() => this.props.updateMode('dual')} style={{ height:40, marginTop:4, opacity: this.props.mode == 'dual' ? 1 : 0.5 }} /><Image className='mode' src='/assets/right.png' onClick={() => this.props.updateMode('right')} style={{ height:40, marginLeft:6, marginTop:4, opacity: this.props.mode == 'right' ? 1 : 0.5 }} /></div>
                    <span>{this.props.settings.copyright}</span>
                  </div>}
                </Fragment>
              )
          default:
              return null
          }
      case false:
          switch (window.location.host.split(".")[0]) {
          case "sms":
              return (
                <Fragment>
                  {/* @ts-ignore */}
                  <Helmet>
                    <title>SMS</title>
                  </Helmet>
                  <SMSLanding />
                </Fragment>
              )
          case "sas":
              return (
                <Fragment>
                  {/* @ts-ignore */}
                  <Helmet>
                    <title>SAS</title>
                  </Helmet>
                  <SASLanding />
                </Fragment>
              )
          default:
              return null
          }
      default:
          return null
      }
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.staff, ...state.panes, modals: state.dashboard.modals, print: state.panes.panes[1].options.print || state.panes.panes[2].options.print } },
    { ...DrawerStore.actionCreators, ...PanesStore.actionCreators, ...DashboardStore.actionCreators, ...StaffStore.actionCreators }
)(Home as any)
