import React, { Fragment } from 'react'
import {
  Table,
  Container,
  Checkbox
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class Classes extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public report() {
        let data = this.props.report.data
        let num = 0

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Class List</div><div style={{marginTop:4}}>{`${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
            </div>
            {/*<div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Sort by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'name' ? 'Name' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'housing' ? 'Housing' : params.reportType == 'class' ? 'Class' : params.reportType == 'tabe' ? 'TABE' : params.reportType == 'staff' ? 'Staff' : ''}</div></div>
            </div>*/}
            <Table compact celled striped unstackable style={{borderCollapse:'collapse',marginTop:0}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1} style={{border:0}}><div><span>#</span></div></Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{border:0}}><div><span>Class ID</span></div></Table.HeaderCell>
                  <Table.HeaderCell style={{border:0}}><div><span>Class Name</span></div></Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{border:0}}><div><span>Students</span></div></Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{border:0}}><div><span>Schedule</span></div></Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{border:0}}><div><span>Location</span></div></Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{border:0}}><div><span>Instructor</span></div></Table.HeaderCell>
                  <Table.HeaderCell width={1} style={{border:0}}><div><span>Funds</span></div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                  <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                </Table.Row> : null}
                {data.map((course:any, c:number) => 
                  course.classes.map((klass:any, k:number) => {
                    num += 1
                    return (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)', borderTop: k == 0 ? '1px solid rgba(0,0,50,.05)' : 0}} key={klass.recordID}>
                        <Table.Cell>{num}</Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>{klass.facilityAbbr}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>{klass.recordID}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>{klass.className}</div></Table.Cell>
                        <Table.Cell>{klass.rosterCount == klass.activeCount ? <div style={{whiteSpace:'nowrap'}}>{klass.rosterCount}</div> : <div style={{whiteSpace:'nowrap'}}>{klass.activeCount} / {klass.rosterCount}</div>}</Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>
                          {this.props.options.dates ? klass.dateBegins ? <div style={{fontSize:13}}>{klass.dateBegins}</div> : <div style={{fontSize:13}}>No start date</div> : null}
                          {this.props.options.dates && klass.hiatusBegins != null ? <div style={{color:'#db2828',marginBottom:3,fontSize:13}}>{klass.hiatusBegins}</div> : null}
                          {_.map(klass.schedules, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}><span style={{display:'inline-block',width:32}}>{this.dayOfWeek3(schedule.dayOfWeek)}.</span> {schedule.startTime} - {schedule.endTime}</div> })}
                          {this.props.options.dates && klass.hiatusEnds != null ? <div style={{color:'#db2828',marginTop:3,fontSize:13}}>{klass.hiatusEnds}</div> : null}
                          {this.props.options.dates ? klass.dateEnds ? <div style={{fontSize:13}}>{klass.dateEnds}</div> : <div style={{fontSize:13}}>No end date</div> : null}
                        </div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>
                          {_.map(klass.schedules, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}>{schedule.location}</div> })}
                        </div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>{klass.instructorName}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap'}}>{klass.fundingSource}</div></Table.Cell>
                      </Table.Row>
                    )
                  })
                )}
              </Table.Body>
            </Table>
          </Container>
        )
    }
}

export default Classes