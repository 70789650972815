import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import ClassCompletionsGrades from './ClassCompletionsGrades'
import ClassCompletionsCertificates from './ClassCompletionsCertificates'
import ClassCompletionsWaitlist from './ClassCompletionsWaitlist'
import ClassCompletionsSubmit from './ClassCompletionsSubmit'
import {
  Container,
  Table,
  Select,
  Button,
  Modal,
  Form,
  Input,
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type CompletionProps =
    { class: PanesStore.Class, num: 1 | 2, print: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassCompletions extends React.PureComponent<CompletionProps> {
    public componentDidMount() {
        this.props.fetchCompletions(this.props.num, this.props.class.info.recordID)
    }

    public render() {
        var panes = [ 
          { menuItem: 'Grades', render: () => <Tab.Pane><ClassCompletionsGrades num={this.props.num} class={this.props.class} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Certificates', render: () => <Tab.Pane><ClassCompletionsCertificates num={this.props.num} class={this.props.class} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Waitlist', render: () => <Tab.Pane><ClassCompletionsWaitlist num={this.props.num} class={this.props.class} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Submit', render: () => <Tab.Pane><ClassCompletionsSubmit num={this.props.num} class={this.props.class} print={this.props.print} /></Tab.Pane> }
        ]

        return (
          <Tab menu={{ secondary: true }} panes={panes} activeIndex={this.props.class.completions.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchCompletionsTab(this.props.num, activeIndex) }} />
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, print: ownProps.print } },
  PanesStore.actionCreators
)(ClassCompletions as any)