import React, { Fragment } from 'react'
import { format } from 'date-fns'
import {
  Image,
  Container,
  Divider
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class TranscriptRequest extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <html lang="en" style={{height:'auto'}}>
            <head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Roboto') || document.fonts.check('bold 1em Roboto'))
              }`}} />
            </head>
            <body style={{fontSize:'1.2em',lineHeight:'1.2em',fontFamily:'Roboto',backgroundColor:'white !important',height:'auto'}}>
              {this.report()}
            </body>
          </html>
        )
    }

    public report() {
        let data = this.props.report.data

        const gradType = () => {
            switch (this.props.options.gradType) {
              case "D":
                return "Diploma"
              case "G":
                return "GED"
              case "H":
                return "HiSET"
              default:
                return ""
            }
          }
        
          const gradMonth = () => {
            var date = new Date(this.props.options.gradDate)
            switch (date.getUTCMonth()) {
              case 0:
                return "January"
              case 1:
                return "February"
              case 2:
                return "March"
              case 3:
                return "April"
              case 4:
                return "May"
              case 5:
                return "June"
              case 6:
                return "July"
              case 7:
                return "August"
              case 8:
                return "September"
              case 9:
                return "October"
              case 10:
                return "November"
              case 11:
                return "December"
              default:
                return ""
            }
          }
        
          const gradYear = () => {
            var date = new Date(this.props.options.gradDate)
            return date.getUTCFullYear()
          }

        return (
            <Container fluid>
              <div style={{textAlign:'center'}}>
                <Image src="https://sms.psd-hi.com/assets/HawaiiSeal.png" style={{width:100,margin:'auto'}} />
                <div style={{fontWeight:700,marginTop:30}}>
                  <div>State of Hawai‘i</div>
                  <div>{this.props.settings.deptName}</div>
                  <div style={{marginTop:5}}>{data.facility.name}</div>
                  {data.facility.address1 ? <div>{data.facility.address1}</div> : null}
                  {data.facility.address2 ? <div>{data.facility.address2}</div> : null}
                  {data.facility.csz ? <div>{data.facility.csz}</div> : null}
                  {data.facility.address1 ? <div>United States</div> : null}
                  {data.facility.phone ? <div>phone: {data.facility.phone}</div> : null}
                  {data.facility.fax ? <div>fax: {data.facility.fax}</div> : null}
                </div>
              </div>
              <div style={{marginTop:40,marginLeft:20}}>
                <div>{data.school.name}</div>
                <div>{data.school.address}</div>
                <div>{data.school.city}, {data.school.state} {data.school.zip}</div>
                <div>{data.school.country}</div>
                {data.school.phone ? <div>phone: {data.school.phone}</div> : null}
                {data.school.fax ? <div>fax: {data.school.fax}</div> : null}
                {data.school.email ? <div>email: {data.school.email}</div> : null}
              </div>
              <div style={{marginTop:20,marginLeft:20}}>
                <div>Attn: Registrar</div>
              </div>
              <div style={{marginTop:20,marginLeft:20}}>
                <div>I, {data.inmate.name}, am incarcerated at the facility named above. I request the following information be sent to the Hawaii {this.props.settings.deptName} at this facility.</div>
              </div>
              <div style={{marginTop:20,marginLeft:30}}>
                {this.props.options.verification ? <div>• Verification that I earned a {gradType()} in {gradMonth()} of {gradYear()}.</div> : null}
                {this.props.options.copy ? <div>• A copy of my {this.props.options.gradType == 'G' ? 'GED' : this.props.options.gradType == 'H' ? 'HiSET' : 'HSE'} test record.</div> : null}
                {this.props.options.hsTranscript ? <div>• A copy of my high school transcript.</div> : null}
                {this.props.options.collegeTranscript ? <div>• A copy of college transcript.</div> : null}
                {this.props.options.enrollmentVerification ? <div>• A copy of my college enrollment verification.</div> : null}
                {this.props.options.campusReport ? <div>• A copy of my college campus report.</div> : null}
              </div>
              <div style={{marginTop:20,marginLeft:20}}>
                <div>The last four digits of my SSN are {data.inmate.ssn}. I was born on {data.inmate.dob}. Thank you.</div>
              </div>
              <div style={{marginTop:30,marginLeft:20}}>
                <div style={{display:'inline-block',width:400,borderTop:'thin solid black',paddingTop:4}}>{data.inmate.name}</div>
                <div style={{display:'inline-block',width:200,borderTop:'thin solid black',paddingTop:4,marginLeft:20}}>Date</div>
              </div>
              {this.props.options.verification || this.props.options.copy || this.props.options.hsTranscript || this.props.options.collegeTranscript || this.props.options.enrollmentVerification || this.props.options.campusReport ? <Fragment>
                <Divider style={{marginTop:20}} />
                <div style={{marginTop:20,marginLeft:20,fontWeight:700}}>
                  <div>Regarding {data.inmate.name}</div>
                </div>
                <div style={{marginTop:15,marginLeft:30}}>
                  <div>This is to verify that:</div>
                </div>
                {this.props.options.verification ? <div style={{marginTop:5,marginLeft:40}}>
                  <div>__ received a {gradType()} in {gradMonth()} of {gradYear()}</div>
                  <div style={{marginTop:2}}>__ did NOT receive a {gradType()} from this institution</div>
                </div> : null}
                {this.props.options.copy ? <div style={{marginTop:5,marginLeft:40}}>
                  <div>{data.inmate.gender == 'M' ? 'His' : 'Her'} HSE test records: __ are attached __ are not attached</div>
                </div> : null}
                {this.props.options.hsTranscript ? <div style={{marginTop:5,marginLeft:40}}>
                  <div>{data.inmate.gender == 'M' ? 'His' : 'Her'} HS transcript: __ is attached __ is not attached</div>
                </div> : null}
                {this.props.options.collegeTranscript ? <div style={{marginTop:5,marginLeft:40}}>
                  <div>{data.inmate.gender == 'M' ? 'His' : 'Her'} college transcript: __ is attached __ is not attached</div>
                </div> : null}
                {this.props.options.enrollmentVerification ? <div style={{marginTop:5,marginLeft:40}}>
                  <div>{data.inmate.gender == 'M' ? 'His' : 'Her'} enrollment verification __ is attached __ is  not attached</div>
                </div> : null}
                {this.props.options.campusReport ? <div style={{marginTop:5,marginLeft:40}}>
                  <div>{data.inmate.gender == 'M' ? 'His' : 'Her'} campus report: __ is attached __ is not attached</div>
                </div> : null}
                <div style={{marginTop:30,marginLeft:20}}>
                  <div style={{display:'inline-block',width:400,borderTop:'thin solid black',paddingTop:4}}>Signature</div>
                  <div style={{display:'inline-block',width:200,borderTop:'thin solid black',paddingTop:4,marginLeft:20}}>Date</div>
                </div>
                <div style={{marginTop:30,marginLeft:20}}>
                  <div style={{display:'inline-block',width:400,borderTop:'thin solid black',paddingTop:4}}>Printed Name</div>
                  <div style={{display:'inline-block',width:200,borderTop:'thin solid black',paddingTop:4,marginLeft:20}}>Title</div>
                </div>
              </Fragment> : null}
            </Container>
          )
    }
}

export default TranscriptRequest