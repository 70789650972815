import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as ClassesStore from '../../store/reducers/classes'
import * as PanesStore from '../../store/reducers/panes'
import ClassFilters from './ClassFilters'
import CertificateGroup from './CertificateGroup'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Icon,
  Table,
  Dimmer,
  Loader
} from 'semantic-ui-react'
const _ = require('underscore')

type TableProps =
    { open: boolean, loading: boolean, hasMore: boolean, tab: string, filters: ClassesStore.ClassFilters, courses: ClassesStore.Course[] } &
    typeof DrawerStore.actionCreators &
    typeof ClassesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class CertificatesTable extends React.PureComponent<TableProps> {
    public render() {
      return (
        <Fragment>
          <ClassFilters />
          <div id='certificates-table' style={{top:this.props.open?200:70}}>
            <Dimmer inverted active={this.props.loading}><Loader inverted content='Loading certificates ...' /></Dimmer>
            <InfiniteScroll
              pageStart={0}
              loadMore={this.props.fetchNextCertificates}
              hasMore={!this.props.loading && this.props.hasMore}
              initialLoad={false}
              useWindow={false}
              getScrollParent={() => document.getElementById('certificates-table') }
            >
              <Table compact celled striped style={{borderTop:0,marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Facility</div></Table.HeaderCell>
                    <Table.HeaderCell width={3} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Class</div></Table.HeaderCell>
                    <Table.HeaderCell width={3} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Inmate</div></Table.HeaderCell>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Date</div></Table.HeaderCell>
                    <Table.HeaderCell width={6} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Certificate</div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.courses.map((course:any, c:number) => (
                    <Fragment key={c}><CertificateGroup course={course} key={course.key} /></Fragment>
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </div>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { open: state.classes.open, loading: state.classes.tabs.certificates.loading, hasMore: state.classes.tabs.certificates.hasMore, tab: state.drawer.tab, courses: state.classes.tabs.certificates.data } },
  { ...DrawerStore.actionCreators, ...ClassesStore.actionCreators, ...PanesStore.actionCreators }
)(CertificatesTable as any)