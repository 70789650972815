import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import InfiniteScroll from 'react-infinite-scroller'
import LogPDFRow from './LogPDFRow'
import {
  Button,
  Form,
  Select,
  Input
} from 'semantic-ui-react'

type LogProps =
    { settings: any, loading: boolean, hasMore: boolean } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class AppClasses extends React.PureComponent<LogProps> {
    public componentDidMount() {
        this.props.fetchAppSettings()
    }

    public render() {
      return (
        <Fragment>
          <Form>
            <Form.Group>
              <Form.Field
                control={Select}
                label="Approval Required"
                name="approvalRequired"
                options={[{key:0,value:false,text:'No'},{key:1,value:true,text:'Yes'}]}
                value={this.props.settings.data.approvalRequired}
                onChange={(e:any, data:any) => this.props.updateSetting('approvalRequired', data.value)}
                width={2}
              />
              {this.props.settings.data.approvalRequired ? <Form.Field
                control={Input}
                label="After X Classes"
                name="maxRepeats"
                value={this.props.settings.data.maxRepeats}
                onChange={(e:any, data:any) => this.props.updateSetting('maxRepeats', data.value.validateNum())}
                width={2}
              /> : null}
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                label="Auto Suspend"
                name="autoSuspend"
                options={[{key:0,value:false,text:'No'},{key:1,value:true,text:'Yes'}]}
                value={this.props.settings.data.autoSuspend}
                onChange={(e:any, data:any) => this.props.updateSetting('autoSuspend', data.value)}
                width={2}
              />
              {this.props.settings.data.autoSuspend ? <Form.Field
                control={Select}
                label="Suspend Type"
                name="suspendType"
                options={[{key:0,value:'D',text:'Days'},{key:1,value:'P',text:'Percentage'}]}
                value={this.props.settings.data.suspendType}
                onChange={(e:any, data:any) => this.props.updateSetting('suspendType', data.value)}
                width={2}
              /> : null}
              {this.props.settings.data.autoSuspend && this.props.settings.data.suspendType == 'D' ? <Form.Field
                control={Input}
                label="Days"
                name="suspendDays"
                value={this.props.settings.data.suspendDays}
                onChange={(e:any, data:any) => this.props.updateSetting('suspendDays', data.value.validateNum())}
                width={2}
              /> : null}
              {this.props.settings.data.autoSuspend && this.props.settings.data.suspendType == 'P' ? <Form.Field
                control={Input}
                label="Percentage"
                name="suspendPercent"
                value={this.props.settings.data.suspendPercent}
                onChange={(e:any, data:any) => this.props.updateSetting('suspendPercent', data.value.validateNum())}
                width={2}
              /> : null}
            </Form.Group>
            <Button positive content='Save' onClick={() => this.props.saveSettings()} />
          </Form>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { settings: state.admin.settings, loading: state.admin.logs.pdfs.loading, hasMore: state.admin.logs.pdfs.hasMore } },
  AdminStore.actionCreators
)(AppClasses as any)