import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import Cleave from "cleave.js/react"
import {
  Container,
  Input,
  Table,
  Select,
  Button,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    DrawerStore.CreateModal &
    typeof DrawerStore.actionCreators &
    WithRouterProps<{}>

class CreateClassSchedule extends React.PureComponent<RosterProps> {
    public render() {
        const days = [{ key: 0, value: 0, text: 'Sunday' }, { key: 1, value: 1, text: 'Monday' }, { key: 2, value: 2, text: 'Tuesday' }, { key: 3, value: 3, text: 'Wednesday' }, { key: 4, value: 4, text: 'Thursday' }, { key: 5, value: 5, text: 'Friday' }, { key: 6, value: 6, text: 'Saturday' }]
        return (
          <Container fluid>
            <Table compact celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={4}>Instructor</Table.HeaderCell>
                  <Table.HeaderCell width={3}>Day</Table.HeaderCell>
                  <Table.HeaderCell width={2}>Start Time</Table.HeaderCell>
                  <Table.HeaderCell width={2}>End Time</Table.HeaderCell>
                  <Table.HeaderCell width={4}>Location</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.data.schedules.map((schedule:any) => (
                  <Table.Row key={schedule.id}>
                    <Table.Cell><Select fluid name="instructorID" options={this.props.options.instructors} value={schedule.instructorID || this.props.data.instructorID} onChange={(e:any, data:any) => this.props.scheduleChange(schedule.id, 'instructorID', data.value as number)} style={{minWidth:125}} /></Table.Cell>
                    <Table.Cell><Select fluid name="dayOfWeek" options={days} value={schedule.dayOfWeek} onChange={(e:any, data:any) => this.props.scheduleChange(schedule.id, 'dayOfWeek', data.value as number)} style={{minWidth:125}} /></Table.Cell>
                    <Table.Cell><Cleave name="startTime" placeholder="15:30" value={schedule.startTime} onChange={(e:any) => this.props.scheduleChange(schedule.id, 'startTime', e.target.value)} options={{ time: true, timePattern: ['h','m'] }} style={{width:80}} /></Table.Cell>
                    <Table.Cell><Cleave name="endTime" placeholder="17:00" value={schedule.endTime} onChange={(e:any) => this.props.scheduleChange(schedule.id, 'endTime', e.target.value)} options={{ time: true, timePattern: ['h','m'] }} style={{width:80}} /></Table.Cell>
                    <Table.Cell><Select fluid name="locationID" options={this.props.options.locations} value={schedule.locationID} onChange={(e:any, data:any) => this.props.scheduleChange(schedule.id, 'locationID', data.value as number)} style={{minWidth:125}} /></Table.Cell>
                    <Table.Cell><Button size="mini" color="red" content="-" onClick={_.debounce(() => this.props.removeSchedule(schedule.id), 10000, true)} /></Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell><Select fluid name="instructorID" options={this.props.options.instructors} value={this.props.newSchedule.instructorID || this.props.data.instructorID} onChange={(e:any, data:any) => this.props.newScheduleChange('instructorID', data.value as number)} style={{minWidth:125}} /></Table.Cell>
                  <Table.Cell><Select fluid name="dayOfWeek" placeholder="Day of Week" options={days} onChange={(e:any, data:any) => this.props.newScheduleChange('dayOfWeek', data.value as number)} style={{minWidth:125}} /></Table.Cell>
                  <Popup content="Specify time in military time (3PM = 15:00)" position="bottom center" trigger={<Table.Cell><Cleave name="startTime" placeholder="Start" onChange={(e:any) => this.props.newScheduleChange('startTime', e.target.value)} options={{ time: true, timePattern: ['h','m'] }} style={{width:80}} /></Table.Cell>} />
                  <Popup content="Specify time in military time (3PM = 15:00)" position="bottom center" trigger={<Table.Cell><Cleave name="endTime" placeholder="End" onChange={(e:any) => this.props.newScheduleChange('endTime', e.target.value)} options={{ time: true, timePattern: ['h','m'] }} style={{width:80}} /></Table.Cell>} />
                  <Table.Cell><Select fluid name="locationID" options={this.props.options.locations} value={this.props.newSchedule.locationID} onChange={(e:any, data:any) => this.props.newScheduleChange('locationID', data.value as number)} style={{minWidth:125}} /></Table.Cell>
                  <Table.Cell><Button size="mini" color="green" content="+" onClick={_.debounce(this.props.addSchedule, 10000, true)} /></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState) => state.drawer.createClassM,
  DrawerStore.actionCreators
)(CreateClassSchedule as any)