import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import DoePDF from '../reports/pdf/DOE'
import {
  Container,
  Form,
  Select,
  Button,
  Header,
  Divider
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { class: PanesStore.Class, num: 1 | 2 } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassEnrollmentForms extends React.PureComponent<RosterProps> {
    public render() {
      var bulk = this.props.class.bulk
      var rosters = this.props.class.rosters
      return (
        <Container fluid>
          <div style={{display:'inline-block'}}><Header as='h3'>DoE Enrollment Forms</Header></div>
          <Button color='blue' circular icon='print' onClick={() => this.props.generateDOE(this.props.num)} style={{marginLeft:10}} />
          <DoePDF num={this.props.num} pdf={this.props.class.pdfs.doe} options={{}} />
          <Form>
            <Form.Group style={{marginLeft:0,marginRight:0,marginTop:10}}>
              <Form.Field
                control={Select}
                label='Inmates'
                placeholder='Inmates'
                options={_.map(rosters, (roster:any) => { return { key: roster.recordID, value: roster.recordID, text: `${roster.lastName}, ${roster.firstName}` } })}
                value={bulk.rosters || []}
                onChange={(e:any, data:any) => this.props.editBulkField(this.props.num, 'rosters', data.value as number[])}
                width={16}
                multiple
              />
            </Form.Group>
          </Form>
          <Divider />
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num } },
  PanesStore.actionCreators
)(ClassEnrollmentForms as any)