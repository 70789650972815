import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Grid,
  Input,
  Select,
  Radio,
  Checkbox,
  TextArea,
  Button,
  Header,
  Divider,
  Icon,
  Table,
  Modal,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type TestProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean, hide: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateTests extends React.PureComponent<TestProps> {
    public componentDidMount() {
        this.props.fetchTABE(this.props.num, this.props.inmate.info.recordID)
        this.props.fetchGED(this.props.num, this.props.inmate.info.recordID)
        this.props.fetchHiSET(this.props.num, this.props.inmate.info.recordID)
        this.props.fetchRIASEC(this.props.num, this.props.inmate.info.recordID)
    }

    private TABEHeaderArrow = (props:any) => {
      if (this.props.inmate.tests.sorts.tabe.column == props.column) {
        if (this.props.inmate.tests.sorts.tabe.dir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    private ArchiveHeaderArrow = (props:any) => {
      if (this.props.inmate.tests.sorts.archive.column == props.column) {
        if (this.props.inmate.tests.sorts.archive.dir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    private GEDHeaderArrow = (props:any) => {
      if (this.props.inmate.tests.sorts.ged.column == props.column) {
        if (this.props.inmate.tests.sorts.ged.dir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    private HiSETHeaderArrow = (props:any) => {
      if (this.props.inmate.tests.sorts.hiset.column == props.column) {
        if (this.props.inmate.tests.sorts.hiset.dir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    private RIASECHeaderArrow = (props:any) => {
      if (this.props.inmate.tests.sorts.riasec.column == props.column) {
        if (this.props.inmate.tests.sorts.riasec.dir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    public render() {
      const today = new Date(new Date().toDateString())
      const TABEHeaderArrow = this.TABEHeaderArrow
      const ArchiveHeaderArrow = this.ArchiveHeaderArrow
      const GEDHeaderArrow = this.GEDHeaderArrow
      const HiSETHeaderArrow = this.HiSETHeaderArrow
      const RIASECHeaderArrow = this.RIASECHeaderArrow
      return (
        <Container fluid>
          {!this.props.print || (this.props.hide ? this.props.inmate.tests.tabe.list.length > 0 : true) ? <Fragment>
            <div style={{display:'inline-block'}}>
              <Header as='h3'>TABE 11/12</Header>
            </div>
            {this.props.print ? null : <Popup content={`TABEs are imported nightly in a single batch. You can use this button to add a new TABE record but be aware that it may become a duplicate record.`} position="top center" trigger={<Button positive circular icon='plus' onClick={() => this.props.toggleTABE(this.props.num)} style={{marginLeft:10}} />} />}
            <Table compact celled striped selectable style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={3}></Table.HeaderCell>
                  <Table.HeaderCell colSpan={4}>Reading</Table.HeaderCell>
                  <Table.HeaderCell colSpan={4}>Total Math</Table.HeaderCell>
                  <Table.HeaderCell colSpan={4}>Language</Table.HeaderCell>
                </Table.Row>
                <Table.Row className='hover'>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'date')}><div style={{whiteSpace:'nowrap'}}>Date<TABEHeaderArrow column='date' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'test')}><div style={{whiteSpace:'nowrap'}}>Test<TABEHeaderArrow column='test' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'reLF')}><div style={{whiteSpace:'nowrap'}}>LF<TABEHeaderArrow column='reLF' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'reSS')}><div style={{whiteSpace:'nowrap'}}>SS<TABEHeaderArrow column='reSS' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'reGE')}><div style={{whiteSpace:'nowrap'}}>GE<TABEHeaderArrow column='reGE' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'reNRS')}><div style={{whiteSpace:'nowrap'}}>NRS<TABEHeaderArrow column='reNRS' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'tmLF')}><div style={{whiteSpace:'nowrap'}}>LF<TABEHeaderArrow column='tmLF' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'tmSS')}><div style={{whiteSpace:'nowrap'}}>SS<TABEHeaderArrow column='tmSS' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'tmGE')}><div style={{whiteSpace:'nowrap'}}>GE<TABEHeaderArrow column='tmGE' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'tmNRS')}><div style={{whiteSpace:'nowrap'}}>NRS<TABEHeaderArrow column='tmNRS' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'laLF')}><div style={{whiteSpace:'nowrap'}}>LF<TABEHeaderArrow column='laLF' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'laSS')}><div style={{whiteSpace:'nowrap'}}>SS<TABEHeaderArrow column='laSS' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'laGE')}><div style={{whiteSpace:'nowrap'}}>GE<TABEHeaderArrow column='laGE' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateTABESort(this.props.num, 'laNRS')}><div style={{whiteSpace:'nowrap'}}>NRS<TABEHeaderArrow column='laNRS' /></div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.inmate.tests.tabe.list.map((tabe:any) => (
                  <Table.Row key={tabe.recordID}>
                    <Table.Cell><Checkbox checked={tabe.checked ? tabe.checked : false} onClick={() => this.props.checkTABE(this.props.num, tabe.recordID)} /></Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.testDate}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}><div style={{whiteSpace:'normal'}}>{tabe.test}</div></Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.reLF}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.reSS}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.reGE}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.reNRS}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.tmLF}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.tmSS}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.tmGE}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.tmNRS}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.laLF}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.laSS}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.laGE}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditTABE(this.props.num, tabe)}>{tabe.laNRS}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {this.props.print ? null : <Fragment>
              <Button color='black' content='Archive TABEs' onClick={_.debounce(() => this.props.archiveTABEs(this.props.num), 10000, true)} style={{marginBottom:20}} />
              <Button color='blue' content='Combine TABEs' onClick={_.debounce(() => this.props.combineTABEs(this.props.num), 10000, true)} style={{marginBottom:20}} />
            </Fragment>}
          </Fragment> : null}
          {!this.props.print || (this.props.hide ? this.props.inmate.tests.tabe.archive.length > 0 : true) ? <Fragment>
            {!this.props.print || (this.props.hide ? this.props.inmate.tests.tabe.list.length > 0 : true) ? <Divider /> : null}
            <div style={{display:'inline-block'}}>
              <Header as='h3'>TABE Archive</Header>
            </div>
            <Table compact celled striped selectable style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={2}></Table.HeaderCell>
                  <Table.HeaderCell colSpan={3}>Reading</Table.HeaderCell>
                  <Table.HeaderCell colSpan={3}>Total Math</Table.HeaderCell>
                  <Table.HeaderCell colSpan={3}>Language</Table.HeaderCell>
                  <Table.HeaderCell colSpan={3}>Spelling</Table.HeaderCell>
                  <Table.HeaderCell colSpan={3}>Vocabulary</Table.HeaderCell>
                </Table.Row>
                <Table.Row className='hover'>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'date')}><div style={{whiteSpace:'nowrap'}}>Date<ArchiveHeaderArrow column='date' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'test')}><div style={{whiteSpace:'nowrap'}}>Test<ArchiveHeaderArrow column='test' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'reLF')}><div style={{whiteSpace:'nowrap'}}>LF<ArchiveHeaderArrow column='reLF' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'reSS')}><div style={{whiteSpace:'nowrap'}}>SS<ArchiveHeaderArrow column='reSS' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'reGE')}><div style={{whiteSpace:'nowrap'}}>GE<ArchiveHeaderArrow column='reGE' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'tmLF')}><div style={{whiteSpace:'nowrap'}}>LF<ArchiveHeaderArrow column='tmLF' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'tmSS')}><div style={{whiteSpace:'nowrap'}}>SS<ArchiveHeaderArrow column='tmSS' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'tmGE')}><div style={{whiteSpace:'nowrap'}}>GE<ArchiveHeaderArrow column='tmGE' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'laLF')}><div style={{whiteSpace:'nowrap'}}>LF<ArchiveHeaderArrow column='laLF' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'laSS')}><div style={{whiteSpace:'nowrap'}}>SS<ArchiveHeaderArrow column='laSS' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'laGE')}><div style={{whiteSpace:'nowrap'}}>GE<ArchiveHeaderArrow column='laGE' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'spLF')}><div style={{whiteSpace:'nowrap'}}>LF<ArchiveHeaderArrow column='spLF' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'spSS')}><div style={{whiteSpace:'nowrap'}}>SS<ArchiveHeaderArrow column='spSS' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'spGE')}><div style={{whiteSpace:'nowrap'}}>GE<ArchiveHeaderArrow column='spGE' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'voLF')}><div style={{whiteSpace:'nowrap'}}>LF<ArchiveHeaderArrow column='voLF' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'voSS')}><div style={{whiteSpace:'nowrap'}}>SS<ArchiveHeaderArrow column='voSS' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateArchiveSort(this.props.num, 'voGE')}><div style={{whiteSpace:'nowrap'}}>GE<ArchiveHeaderArrow column='voGE' /></div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.inmate.tests.tabe.archive.map((tabe:any) => (
                  <Table.Row key={tabe.recordID} onClick={() => this.props.toggleArchive(this.props.num, tabe)}>
                    <Table.Cell>{tabe.testDate}</Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'normal'}}>{tabe.test}</div></Table.Cell>
                    <Table.Cell>{tabe.reLF}</Table.Cell>
                    <Table.Cell>{tabe.reSS}</Table.Cell>
                    <Table.Cell>{tabe.reGE}</Table.Cell>
                    <Table.Cell>{tabe.tmLF}</Table.Cell>
                    <Table.Cell>{tabe.tmSS}</Table.Cell>
                    <Table.Cell>{tabe.tmGE}</Table.Cell>
                    <Table.Cell>{tabe.laLF}</Table.Cell>
                    <Table.Cell>{tabe.laSS}</Table.Cell>
                    <Table.Cell>{tabe.laGE}</Table.Cell>
                    <Table.Cell>{tabe.spLF}</Table.Cell>
                    <Table.Cell>{tabe.spSS}</Table.Cell>
                    <Table.Cell>{tabe.spGE}</Table.Cell>
                    <Table.Cell>{tabe.voLF}</Table.Cell>
                    <Table.Cell>{tabe.voSS}</Table.Cell>
                    <Table.Cell>{tabe.voGE}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Fragment> : null}
          {!this.props.print || (this.props.hide ? this.props.inmate.tests.ged.list.length > 0 : true) ? <Fragment>
            <Divider />
            <div style={{display:'inline-block'}}>
              <Header as='h3'>GED</Header>
            </div>
            {this.props.print ? null : <Popup content={`Add a new GED Record. GED tests are not automatically imported at this time.`} position="top center" trigger={<Button positive circular icon='plus' onClick={() => this.props.toggleGED(this.props.num)} style={{marginLeft:10}} />} />}
            {this.props.inmate.tests.ged.passed ? <div style={{display:'inline-block',fontSize:'1.25em',marginLeft:20}}>
              <Icon name='check' color='green'></Icon>
              <span>Passed</span>
            </div> : null}
            <Table compact celled striped selectable style={{marginBottom:20}}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell positive colSpan={10} style={{padding:'5px 7px'}}><b>Best Exam Scores</b></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan={2}><b>Science</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>Math</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>Social Studies</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>Literature</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>CivicsAZ</b></Table.Cell>
                </Table.Row>
                <Table.Row style={{backgroundColor:'rgba(0,0,50,.02)'}}>
                  <Table.Cell><b>Date</b></Table.Cell>
                  <Table.Cell><b>Score</b></Table.Cell>
                  <Table.Cell><b>Date</b></Table.Cell>
                  <Table.Cell><b>Score</b></Table.Cell>
                  <Table.Cell><b>Date</b></Table.Cell>
                  <Table.Cell><b>Score</b></Table.Cell>
                  <Table.Cell><b>Date</b></Table.Cell>
                  <Table.Cell><b>Score</b></Table.Cell>
                  <Table.Cell><b>Date</b></Table.Cell>
                  <Table.Cell><b>Score</b></Table.Cell>
                </Table.Row>
                <Table.Row style={{backgroundColor:'white'}}>
                  <Table.Cell>{this.props.inmate.tests.ged.top.science.testDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.top.science.science}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.top.math.testDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.top.math.mathematics}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.top.social.testDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.top.social.socialStudies}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.top.literature.testDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.top.literature.literature}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.top.civics.testDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.top.civics.civicsAZ}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Table compact celled striped selectable style={{marginBottom:20}}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell positive colSpan={10} style={{padding:'5px 7px'}}><b>Best Ready Scores</b></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan={2}><b>Science</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>Math</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>Social Studies</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>Literature</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>CivicsAZ</b></Table.Cell>
                </Table.Row>
                <Table.Row style={{backgroundColor:'rgba(0,0,50,.02)'}}>
                  <Table.Cell><b>Date</b></Table.Cell>
                  <Table.Cell><b>Score</b></Table.Cell>
                  <Table.Cell><b>Date</b></Table.Cell>
                  <Table.Cell><b>Score</b></Table.Cell>
                  <Table.Cell><b>Date</b></Table.Cell>
                  <Table.Cell><b>Score</b></Table.Cell>
                  <Table.Cell><b>Date</b></Table.Cell>
                  <Table.Cell><b>Score</b></Table.Cell>
                  <Table.Cell><b>Date</b></Table.Cell>
                  <Table.Cell><b>Score</b></Table.Cell>
                </Table.Row>
                <Table.Row style={{backgroundColor:'white'}}>
                  <Table.Cell>{this.props.inmate.tests.ged.ready.science.testDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.ready.science.science}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.ready.math.testDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.ready.math.mathematics}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.ready.social.testDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.ready.social.socialStudies}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.ready.literature.testDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.ready.literature.literature}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.ready.civics.testDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.ged.ready.civics.civicsAZ}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Table compact celled striped selectable style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row className='hover'>
                  <Table.HeaderCell />
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'facility')}><div style={{whiteSpace:'nowrap'}}>Facility<GEDHeaderArrow column='facility' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'date')}><div style={{whiteSpace:'nowrap'}}>Date<GEDHeaderArrow column='date' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'science')}><div style={{whiteSpace:'nowrap'}}>Science<GEDHeaderArrow column='science' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'mathematics')}><div style={{whiteSpace:'nowrap'}}>Math<GEDHeaderArrow column='mathematics' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'social')}><div style={{whiteSpace:'nowrap'}}>Social Studies<GEDHeaderArrow column='social' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'literature')}><div style={{whiteSpace:'nowrap'}}>Literature<GEDHeaderArrow column='literature' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'reading')}><div style={{whiteSpace:'nowrap'}}>Reading<GEDHeaderArrow column='reading' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'writing')}><div style={{whiteSpace:'nowrap'}}>Writing<GEDHeaderArrow column='writing' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'essay')}><div style={{whiteSpace:'nowrap'}}>Essay<GEDHeaderArrow column='essay' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'civics')}><div style={{whiteSpace:'nowrap'}}>CivicsAZ<GEDHeaderArrow column='civics' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'series')}><div style={{whiteSpace:'nowrap'}}>Series<GEDHeaderArrow column='series' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateGEDSort(this.props.num, 'practice')}><div style={{whiteSpace:'nowrap'}}>Practice<GEDHeaderArrow column='practice' /></div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.inmate.tests.ged.list.map((ged:any) => (
                  <Table.Row key={ged.recordID}>
                    <Table.Cell><Checkbox checked={ged.checked ? ged.checked : false} onClick={() => this.props.checkGED(this.props.num, ged.recordID)} /></Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.facilityAbbr}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.testDate}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.science}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.mathematics}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.socialStudies}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.literature}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.reading}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.writing}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.essay}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.civicsAZ}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.series}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditGED(this.props.num, ged)}>{ged.practice ? "Yes" : "No"}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {this.props.print ? null : <Fragment>
              <Button color='black' content='Archive GEDs' onClick={_.debounce(() => this.props.archiveGEDs(this.props.num), 10000, true)} style={{marginBottom:20}} />
            </Fragment>}
          </Fragment> : null}
          {!this.props.print || (this.props.hide ? this.props.inmate.tests.hiset.list.length > 0 : true) ? <Fragment>
            <Divider />
            <div style={{display:'inline-block'}}>
              <Header as='h3'>HiSET</Header>
            </div>
            {this.props.print ? null : <Popup content={`Add a new HiSET Record. HiSET tests are not automatically imported at this time.`} position="top center" trigger={<Button positive circular icon='plus' onClick={() => this.props.toggleHiSET(this.props.num)} style={{marginLeft:10}} />} />}
            {this.props.inmate.tests.hiset.passed ? <div style={{display:'inline-block',fontSize:'1.25em',marginLeft:20}}>
              <Icon name='check' color='green'></Icon>
              <span>Passed</span>
            </div> : null}
            <Table compact celled striped selectable style={{marginBottom:20}}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell positive colSpan={12} style={{padding:'5px 7px'}}><b>Best Scores</b></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan={2}><b>Math</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>Science</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>Social Studies</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>Reading</b></Table.Cell>
                  <Table.Cell colSpan={2}><b>Writing</b></Table.Cell>
                  <Table.Cell><b>Essay</b></Table.Cell>
                </Table.Row>
                <Table.Row style={{backgroundColor:'rgba(0,0,50,.02)'}}>
                  <Table.Cell>Date</Table.Cell>
                  <Table.Cell>Score</Table.Cell>
                  <Table.Cell>Date</Table.Cell>
                  <Table.Cell>Score</Table.Cell>
                  <Table.Cell>Date</Table.Cell>
                  <Table.Cell>Score</Table.Cell>
                  <Table.Cell>Date</Table.Cell>
                  <Table.Cell>Score</Table.Cell>
                  <Table.Cell>Date</Table.Cell>
                  <Table.Cell>Score</Table.Cell>
                  <Table.Cell>Score</Table.Cell>
                </Table.Row>
                <Table.Row style={{backgroundColor:'white'}}>
                  <Table.Cell>{this.props.inmate.tests.hiset.top.math.mathDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.hiset.top.math.math}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.hiset.top.science.scienceDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.hiset.top.science.science}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.hiset.top.social.socialStudiesDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.hiset.top.social.socialStudies}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.hiset.top.reading.readingDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.hiset.top.reading.reading}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.hiset.top.writing.writingDate}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.hiset.top.writing.writing}</Table.Cell>
                  <Table.Cell>{this.props.inmate.tests.hiset.top.essay.essay}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Table compact celled striped selectable style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row className='hover'>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateHiSETSort(this.props.num, 'recordID')}><HiSETHeaderArrow column='recordID' /></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateHiSETSort(this.props.num, 'facility')}><div style={{whiteSpace:'nowrap'}}>Facility<HiSETHeaderArrow column='facility' /></div></Table.HeaderCell>
                  <Table.HeaderCell colSpan={2} onClick={() => this.props.toggleInmateHiSETSort(this.props.num, 'math')}><div style={{whiteSpace:'nowrap'}}>Math<HiSETHeaderArrow column='math' /></div></Table.HeaderCell>
                  <Table.HeaderCell colSpan={2} onClick={() => this.props.toggleInmateHiSETSort(this.props.num, 'science')}><div style={{whiteSpace:'nowrap'}}>Science<HiSETHeaderArrow column='science' /></div></Table.HeaderCell>
                  <Table.HeaderCell colSpan={2} onClick={() => this.props.toggleInmateHiSETSort(this.props.num, 'social')}><div style={{whiteSpace:'nowrap'}}>Social Studies<HiSETHeaderArrow column='social' /></div></Table.HeaderCell>
                  <Table.HeaderCell colSpan={2} onClick={() => this.props.toggleInmateHiSETSort(this.props.num, 'reading')}><div style={{whiteSpace:'nowrap'}}>Reading<HiSETHeaderArrow column='reading' /></div></Table.HeaderCell>
                  <Table.HeaderCell colSpan={2} onClick={() => this.props.toggleInmateHiSETSort(this.props.num, 'writing')}><div style={{whiteSpace:'nowrap'}}>Writing<HiSETHeaderArrow column='writing' /></div></Table.HeaderCell>
                  <Table.HeaderCell onClick={() => this.props.toggleInmateHiSETSort(this.props.num, 'essay')}><div style={{whiteSpace:'nowrap'}}>Essay<HiSETHeaderArrow column='essay' /></div></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.inmate.tests.hiset.list.map((hiset:any) => (
                  <Table.Row key={hiset.recordID}>
                    <Table.Cell><Checkbox checked={hiset.checked ? hiset.checked : false} onClick={() => this.props.checkHiSET(this.props.num, hiset.recordID)} /></Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.facilityAbbr}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.mathDate}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.math}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.scienceDate}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.science}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.socialStudiesDate}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.socialStudies}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.readingDate}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.reading}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.writingDate}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.writing}</Table.Cell>
                    <Table.Cell onClick={() => this.props.openEditHiSET(this.props.num, hiset)}>{hiset.essay}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {this.props.print ? null : <Button color='black' content='Archive HiSETs' onClick={_.debounce(() => this.props.archiveHiSETs(this.props.num), 10000, true)} style={{marginBottom:20}} />}
          </Fragment> : null}
          {!this.props.print || (this.props.hide ? this.props.inmate.tests.riasec.length > 0 : true) ? <Fragment>
            <Divider />
            <div style={{display:'inline-block'}}>
              <Header as='h3'>RIASEC</Header>
            </div>
            {this.props.print ? null : <Popup content={`Add a new RIASEC Record. RIASEC tests are not automatically imported at this time.`} position="top center" trigger={<Button positive circular icon='plus' onClick={() => this.props.toggleRIASEC(this.props.num)} style={{marginLeft:10}} />} />}
            <Table compact celled striped selectable style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Test Date</Table.HeaderCell>
                  <Table.HeaderCell>Realistic</Table.HeaderCell>
                  <Table.HeaderCell>Investigative</Table.HeaderCell>
                  <Table.HeaderCell>Artistic</Table.HeaderCell>
                  <Table.HeaderCell>Social</Table.HeaderCell>
                  <Table.HeaderCell>Enterprising</Table.HeaderCell>
                  <Table.HeaderCell>Conventional</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.inmate.tests.riasec.map((riasec:any) => (
                  <Table.Row key={riasec.recordID} onClick={() => this.props.openEditRIASEC(this.props.num, riasec)}>
                    <Table.Cell>{riasec.testDate}</Table.Cell>
                    <Table.Cell>{riasec.hollandR}</Table.Cell>
                    <Table.Cell>{riasec.hollandI}</Table.Cell>
                    <Table.Cell>{riasec.hollandA}</Table.Cell>
                    <Table.Cell>{riasec.hollandS}</Table.Cell>
                    <Table.Cell>{riasec.hollandE}</Table.Cell>
                    <Table.Cell>{riasec.hollandC}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Fragment> : null}
          <Modal
            open={this.props.inmate.modals.archive.open}
            onClose={() => this.props.toggleTABE(this.props.num)}
          >
            <Modal.Header content='View TABE' />
            <Modal.Content>
              <div><b>Test Name: </b>{this.props.inmate.modals.archive.data.test}</div>
              <div><b>Date: </b>{this.props.inmate.modals.archive.data.testDate}</div>
              <Table definition compact celled striped style={{marginBottom:20}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Subtests</Table.HeaderCell>
                    <Table.HeaderCell>L/F</Table.HeaderCell>
                    <Table.HeaderCell>NC</Table.HeaderCell>
                    <Table.HeaderCell>NA</Table.HeaderCell>
                    <Table.HeaderCell>SS</Table.HeaderCell>
                    <Table.HeaderCell>GE</Table.HeaderCell>
                    <Table.HeaderCell>NP</Table.HeaderCell>
                    <Table.HeaderCell>NRS</Table.HeaderCell>
                    <Table.HeaderCell>NS</Table.HeaderCell>
                    <Table.HeaderCell>OM</Table.HeaderCell>
                    <Table.HeaderCell>Predicted GED</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Reading</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reLF}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reNC == 0 ? "" : this.props.inmate.modals.archive.data.reNC}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reNA == 0 ? "" : this.props.inmate.modals.archive.data.reNA}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reSS == 0 ? "" : this.props.inmate.modals.archive.data.reSS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reGE == 0 ? "" : this.props.inmate.modals.archive.data.reGE}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reNP == 0 ? "" : this.props.inmate.modals.archive.data.reNP}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reNRS == 0 ? "" : this.props.inmate.modals.archive.data.reNRS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reNS == 0 ? "" : this.props.inmate.modals.archive.data.reNS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reOM == 0 ? "" : this.props.inmate.modals.archive.data.reOM}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reading ? `Reading ${this.props.inmate.modals.archive.data.reading} ${this.props.inmate.modals.archive.data.readingType}` : ""}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Math Computed</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.mcLF}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.mcNC == 0 ? "" : this.props.inmate.modals.archive.data.mcNC}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.mcNA == 0 ? "" : this.props.inmate.modals.archive.data.mcNA}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.mcSS == 0 ? "" : this.props.inmate.modals.archive.data.mcSS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.mcGE == 0 ? "" : this.props.inmate.modals.archive.data.mcGE}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.mcNP == 0 ? "" : this.props.inmate.modals.archive.data.mcNP}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.mcNRS == 0 ? "" : this.props.inmate.modals.archive.data.mcNRS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.mcNS == 0 ? "" : this.props.inmate.modals.archive.data.mcNS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.mcOM == 0 ? "" : this.props.inmate.modals.archive.data.mcOM}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.mathematics ? `Math ${this.props.inmate.modals.archive.data.mathematics} ${this.props.inmate.modals.archive.data.mathematicsType}` : ""}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Applied Math</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.amLF == 0 ? "" : this.props.inmate.modals.archive.data.amLF}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.amNC == 0 ? "" : this.props.inmate.modals.archive.data.amNC}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.amNA == 0 ? "" : this.props.inmate.modals.archive.data.amNA}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.amSS == 0 ? "" : this.props.inmate.modals.archive.data.amSS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.amGE == 0 ? "" : this.props.inmate.modals.archive.data.amGE}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.amNP == 0 ? "" : this.props.inmate.modals.archive.data.amNP}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.amNRS == 0 ? "" : this.props.inmate.modals.archive.data.amNRS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.amNS == 0 ? "" : this.props.inmate.modals.archive.data.amNS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.amOM == 0 ? "" : this.props.inmate.modals.archive.data.amOM}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reading ? `Writing ${this.props.inmate.modals.archive.data.writing} ${this.props.inmate.modals.archive.data.writingType}` : ""}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Language</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.laLF == 0 ? "" : this.props.inmate.modals.archive.data.laLF}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.laNC == 0 ? "" : this.props.inmate.modals.archive.data.laNC}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.laNA == 0 ? "" : this.props.inmate.modals.archive.data.laNA}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.laSS == 0 ? "" : this.props.inmate.modals.archive.data.laSS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.laGE == 0 ? "" : this.props.inmate.modals.archive.data.laGE}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.laNP == 0 ? "" : this.props.inmate.modals.archive.data.laNP}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.laNRS == 0 ? "" : this.props.inmate.modals.archive.data.laNRS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.laNS == 0 ? "" : this.props.inmate.modals.archive.data.laNS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.laOM == 0 ? "" : this.props.inmate.modals.archive.data.laOM}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.science ? `Science ${this.props.inmate.modals.archive.data.science} ${this.props.inmate.modals.archive.data.scienceType}` : ""}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Vocabulary</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.voLF == 0 ? "" : this.props.inmate.modals.archive.data.voLF}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.voNC == 0 ? "" : this.props.inmate.modals.archive.data.voNC}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.voNA == 0 ? "" : this.props.inmate.modals.archive.data.voNA}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.voSS == 0 ? "" : this.props.inmate.modals.archive.data.voSS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.voGE == 0 ? "" : this.props.inmate.modals.archive.data.voGE}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.voNP == 0 ? "" : this.props.inmate.modals.archive.data.voNP}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.voNRS == 0 ? "" : this.props.inmate.modals.archive.data.voNRS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.voNS == 0 ? "" : this.props.inmate.modals.archive.data.voNS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.voOM == 0 ? "" : this.props.inmate.modals.archive.data.voOM}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.socialStudies ? `Social ${this.props.inmate.modals.archive.data.socialStudies} ${this.props.inmate.modals.archive.data.socialStudiesType}` : ""}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Language Mechanics</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.lmLF == 0 ? "" : this.props.inmate.modals.archive.data.lmLF}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.lmNC == 0 ? "" : this.props.inmate.modals.archive.data.lmNC}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.lmNA == 0 ? "" : this.props.inmate.modals.archive.data.lmNA}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.lmSS == 0 ? "" : this.props.inmate.modals.archive.data.lmSS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.lmGE == 0 ? "" : this.props.inmate.modals.archive.data.lmGE}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.lmNP == 0 ? "" : this.props.inmate.modals.archive.data.lmNP}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.lmNRS == 0 ? "" : this.props.inmate.modals.archive.data.lmNRS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.lmNS == 0 ? "" : this.props.inmate.modals.archive.data.lmNS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.lmOM == 0 ? "" : this.props.inmate.modals.archive.data.lmOM}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.reading ? `Average ${this.props.inmate.modals.archive.data.average} ${this.props.inmate.modals.archive.data.averageType}` : ""}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Spelling</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.spLF == 0 ? "" : this.props.inmate.modals.archive.data.spLF}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.spNC == 0 ? "" : this.props.inmate.modals.archive.data.spNC}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.spNA == 0 ? "" : this.props.inmate.modals.archive.data.spNA}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.spSS == 0 ? "" : this.props.inmate.modals.archive.data.spSS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.spGE == 0 ? "" : this.props.inmate.modals.archive.data.spGE}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.spNP == 0 ? "" : this.props.inmate.modals.archive.data.spNP}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.spNRS == 0 ? "" : this.props.inmate.modals.archive.data.spNRS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.spNS == 0 ? "" : this.props.inmate.modals.archive.data.spNS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.spOM == 0 ? "" : this.props.inmate.modals.archive.data.spOM}</Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total Math</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tmLF == 0 ? "" : this.props.inmate.modals.archive.data.tmLF}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tmNC == 0 ? "" : this.props.inmate.modals.archive.data.tmNC}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tmNA == 0 ? "" : this.props.inmate.modals.archive.data.tmNA}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tmSS == 0 ? "" : this.props.inmate.modals.archive.data.tmSS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tmGE == 0 ? "" : this.props.inmate.modals.archive.data.tmGE}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tmNP == 0 ? "" : this.props.inmate.modals.archive.data.tmNP}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tmNRS == 0 ? "" : this.props.inmate.modals.archive.data.tmNRS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tmNS == 0 ? "" : this.props.inmate.modals.archive.data.tmNS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tmOM == 0 ? "" : this.props.inmate.modals.archive.data.tmOM}</Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total Battery</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tbLF == 0 ? "" : this.props.inmate.modals.archive.data.tbLF}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tbNC == 0 ? "" : this.props.inmate.modals.archive.data.tbNC}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tbNA == 0 ? "" : this.props.inmate.modals.archive.data.tbNA}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tbSS == 0 ? "" : this.props.inmate.modals.archive.data.tbSS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tbGE == 0 ? "" : this.props.inmate.modals.archive.data.tbGE}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tbNP == 0 ? "" : this.props.inmate.modals.archive.data.tbNP}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tbNRS == 0 ? "" : this.props.inmate.modals.archive.data.tbNRS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tbNS == 0 ? "" : this.props.inmate.modals.archive.data.tbNS}</Table.Cell>
                    <Table.Cell>{this.props.inmate.modals.archive.data.tbOM == 0 ? "" : this.props.inmate.modals.archive.data.tbOM}</Table.Cell>
                    <Table.Cell></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Header as='h3'>Column Legend</Header>
              <Table definition compact celled striped style={{marginBottom:20}}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width={1}>L/F</Table.Cell>
                    <Table.Cell>Test Level & Form</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>NC</Table.Cell>
                    <Table.Cell>Number Correct</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>NA</Table.Cell>
                    <Table.Cell>Number Attempted</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>SS</Table.Cell>
                    <Table.Cell>Scale Score</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>GE</Table.Cell>
                    <Table.Cell>Grade Equivalent</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>NP</Table.Cell>
                    <Table.Cell>National Percentile</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>NRS</Table.Cell>
                    <Table.Cell>Literary Level</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>NS</Table.Cell>
                    <Table.Cell>National Standard</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>OM</Table.Cell>
                    <Table.Cell>Objectives Mastered</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>I</Table.Cell>
                    <Table.Cell>Instruct</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>R</Table.Cell>
                    <Table.Cell>Review</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width={1}>T</Table.Cell>
                    <Table.Cell>Test</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleArchive(this.props.num, {})}>Close</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.tabe.open}
            onClose={() => this.props.toggleTABE(this.props.num)}
          >
            <Modal.Header content='Add TABE' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Facility'
                    name='facilityID'
                    options={this.props.inmate.options.facilities}
                    value={this.props.inmate.modals.tabe.data.facilityID}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'facilityID', data.value as number)}
                    style={{minWidth:140}}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Test Date"
                    name="testDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.tabe.data.testDate ? new Date(this.props.inmate.modals.tabe.data.testDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'tabe', 'testDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Test'
                    name='test'
                    value={this.props.inmate.modals.tabe.data.test || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'test', data.value.validate())}
                    width={4}
                  />
                  <Form.Field
                    control={Select}
                    label='Pre-Test'
                    placeholder='Pre-Test'
                    name='preTest'
                    options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                    value={this.props.inmate.modals.tabe.data.preTest}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'preTest', data.value as boolean)}
                    style={{minWidth:140}}
                  />
                  <Form.Field
                    control={Select}
                    label='Intake assessment'
                    placeholder='Intake assessment'
                    name='intakeAssessment'
                    options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                    value={this.props.inmate.modals.tabe.data.intakeAssessment}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'intakeAssessment', data.value as boolean)}
                    style={{minWidth:200}}
                  />
                </Form.Group>
              </Form>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Test Results</Table.HeaderCell>
                    <Table.HeaderCell>LF</Table.HeaderCell>
                    <Table.HeaderCell>SS</Table.HeaderCell>
                    <Table.HeaderCell>NRS</Table.HeaderCell>
                    <Table.HeaderCell>O/R</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Reading</Table.Cell>
                    <Table.Cell>
                      <Select
                        options={[{key:0,value:'',text:''}].concat(this.props.inmate.options.tabeForms)}
                        name="reLF"
                        value={this.props.inmate.modals.tabe.data.reLF || null}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'reLF', data.value as string)}
                      />
                    </Table.Cell>
                    {this.props.inmate.modals.tabe.data.reOR ? (
                      <Fragment>
                        <Table.Cell>N/A</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Table.Cell>
                          <Input
                            name="reSS"
                            placeholder="0"
                            value={this.props.inmate.modals.tabe.data.reSS || ""}
                            onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'reSS', data.value.validateSS())}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {this.props.inmate.modals.tabe.data.reNRS === 0 ? "" : this.props.inmate.modals.tabe.data.reNRS || ""}
                        </Table.Cell>
                      </Fragment>
                    )}
                    <Table.Cell>
                      <Checkbox
                        name="reOR"
                        checked={this.props.inmate.modals.tabe.data.reOR}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'reOR', data.checked as boolean)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total Mathematics</Table.Cell>
                    <Table.Cell>
                      <Select
                        options={[{key:0,value:'',text:''}].concat(this.props.inmate.options.tabeForms)}
                        name="tmLF"
                        value={this.props.inmate.modals.tabe.data.tmLF || null}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'tmLF', data.value as string)}
                      />
                    </Table.Cell>
                    {this.props.inmate.modals.tabe.data.tmOR ? (
                      <Fragment>
                        <Table.Cell>N/A</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Table.Cell>
                          <Input
                            name="tmSS"
                            placeholder="0"
                            value={this.props.inmate.modals.tabe.data.tmSS || ""}
                            onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'tmSS', data.value.validateSS())}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {this.props.inmate.modals.tabe.data.tmNRS === 0 ? "" : this.props.inmate.modals.tabe.data.tmNRS || ""}
                        </Table.Cell>
                      </Fragment>
                    )}
                    <Table.Cell>
                      <Checkbox
                        name="tmOR"
                        checked={this.props.inmate.modals.tabe.data.tmOR}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'tmOR', data.checked as boolean)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Language</Table.Cell>
                    <Table.Cell>
                      <Select
                        options={[{key:0,value:'',text:''}].concat(this.props.inmate.options.tabeForms)}
                        name="laLF"
                        value={this.props.inmate.modals.tabe.data.laLF || null}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'laLF', data.value as string)}
                      />
                    </Table.Cell>
                    {this.props.inmate.modals.tabe.data.laOR ? (
                      <Fragment>
                        <Table.Cell>N/A</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Table.Cell>
                          <Input
                            name="laSS"
                            placeholder="0"
                            value={this.props.inmate.modals.tabe.data.laSS || ""}
                            onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'laSS', data.value.validateSS())}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {this.props.inmate.modals.tabe.data.laNRS === 0 ? "" : this.props.inmate.modals.tabe.data.laNRS || ""}
                        </Table.Cell>
                      </Fragment>
                    )}
                    <Table.Cell>
                      <Checkbox
                        name="laOR"
                        checked={this.props.inmate.modals.tabe.data.laOR}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'tabe', 'laOR', data.checked as boolean)}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleTABE(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.addTABE(this.props.num), 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editTABE.open}
            onClose={() => this.props.toggleEditTABE(this.props.num)}
          >
            <Modal.Header content='Edit TABE' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Facility'
                    name='facilityID'
                    options={this.props.inmate.options.facilities}
                    value={this.props.inmate.modals.editTABE.data.facilityID}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'facilityID', data.value as number)}
                    style={{minWidth:140}}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Test Date"
                    name="testDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.editTABE.data.testDate ? new Date(this.props.inmate.modals.editTABE.data.testDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'testDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Test'
                    name='test'
                    value={this.props.inmate.modals.editTABE.data.test || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'test', data.value.validate())}
                    width={4}
                  />
                  <Form.Field
                    control={Select}
                    label='Pre-Test'
                    placeholder='Pre-Test'
                    name='preTest'
                    options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                    value={this.props.inmate.modals.editTABE.data.preTest}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'preTest', data.value as boolean)}
                    style={{minWidth:140}}
                  />
                  <Form.Field
                    control={Select}
                    label='Intake assessment'
                    placeholder='Intake assessment'
                    name='intakeAssessment'
                    options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                    value={this.props.inmate.modals.editTABE.data.intakeAssessment}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'intakeAssessment', data.value as boolean)}
                    style={{minWidth:200}}
                  />
                </Form.Group>
              </Form>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Subtest</Table.HeaderCell>
                    <Table.HeaderCell>LF</Table.HeaderCell>
                    <Table.HeaderCell>SS</Table.HeaderCell>
                    <Table.HeaderCell>NRS</Table.HeaderCell>
                    <Table.HeaderCell>O/R</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Reading</Table.Cell>
                    <Table.Cell>
                      <Select
                        options={[{key:0,value:'',text:''}].concat(this.props.inmate.options.tabeForms)}
                        name="reLF"
                        value={this.props.inmate.modals.editTABE.data.reLF || null}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'reLF', data.value as string)}
                      />
                    </Table.Cell>
                    {this.props.inmate.modals.editTABE.data.reOR ? (
                      <Fragment>
                        <Table.Cell>N/A</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Table.Cell>
                          <Input
                            name="reSS"
                            placeholder="0"
                            value={this.props.inmate.modals.editTABE.data.reSS || ""}
                            onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'reSS', data.value.validateSS())}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {this.props.inmate.modals.editTABE.data.reNRS === 0 ? "" : this.props.inmate.modals.editTABE.data.reNRS || ""}
                        </Table.Cell>
                      </Fragment>
                    )}
                    <Table.Cell>
                      <Checkbox
                        name="reOR"
                        checked={this.props.inmate.modals.editTABE.data.reOR}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'reOR', data.checked as boolean)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total Mathematics</Table.Cell>
                    <Table.Cell>
                      <Select
                        options={[{key:0,value:'',text:''}].concat(this.props.inmate.options.tabeForms)}
                        name="tmLF"
                        value={this.props.inmate.modals.editTABE.data.tmLF || null}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'tmLF', data.value as string)}
                      />
                    </Table.Cell>
                    {this.props.inmate.modals.editTABE.data.tmOR ? (
                      <Fragment>
                        <Table.Cell>N/A</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Table.Cell>
                          <Input
                            name="tmSS"
                            placeholder="0"
                            value={this.props.inmate.modals.editTABE.data.tmSS || ""}
                            onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'tmSS', data.value.validateSS())}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {this.props.inmate.modals.editTABE.data.tmNRS === 0 ? "" : this.props.inmate.modals.editTABE.data.tmNRS || ""}
                        </Table.Cell>
                      </Fragment>
                    )}
                    <Table.Cell>
                      <Checkbox
                        name="tmOR"
                        checked={this.props.inmate.modals.editTABE.data.tmOR}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'tmOR', data.checked as boolean)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Language</Table.Cell>
                    <Table.Cell>
                      <Select
                        options={[{key:0,value:'',text:''}].concat(this.props.inmate.options.tabeForms)}
                        name="laLF"
                        value={this.props.inmate.modals.editTABE.data.laLF || null}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'laLF', data.value as string)}
                      />
                    </Table.Cell>
                    {this.props.inmate.modals.editTABE.data.laOR ? (
                      <Fragment>
                        <Table.Cell>N/A</Table.Cell>
                        <Table.Cell>-</Table.Cell>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Table.Cell>
                          <Input
                            name="laSS"
                            placeholder="0"
                            value={this.props.inmate.modals.editTABE.data.laSS || ""}
                            onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'laSS', data.value.validateSS())}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {this.props.inmate.modals.editTABE.data.laNRS === 0 ? "" : this.props.inmate.modals.editTABE.data.laNRS || ""}
                        </Table.Cell>
                      </Fragment>
                    )}
                    <Table.Cell>
                      <Checkbox
                        name="laOR"
                        checked={this.props.inmate.modals.editTABE.data.laOR}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editTABE', 'laOR', data.checked as boolean)}
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              {!this.props.inmate.modals.editTABE.data.domains || this.props.inmate.modals.editTABE.data.domains.length == 0 ? null : <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Subtest</Table.HeaderCell>
                    <Table.HeaderCell>Domain</Table.HeaderCell>
                    <Table.HeaderCell>Obtained Points</Table.HeaderCell>
                    <Table.HeaderCell>Total Points</Table.HeaderCell>
                    <Table.HeaderCell>Number of Items</Table.HeaderCell>
                    <Table.HeaderCell>Performance Category</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.inmate.modals.editTABE.data.domains.map((domain:any) => (
                    <Table.Row key={domain.recordID}>
                      <Table.Cell>{domain.subtest}</Table.Cell>
                      <Table.Cell>{domain.domainName}</Table.Cell>
                      <Table.Cell>{domain.obtainedPoints}</Table.Cell>
                      <Table.Cell>{domain.totalPoints}</Table.Cell>
                      <Table.Cell>{domain.itemNumber}</Table.Cell>
                      <Table.Cell>{domain.perfCategory}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>}
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditTABE(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.saveTABE(this.props.num), 10000, true)}>Save</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.ged.open}
            onClose={() => this.props.toggleGED(this.props.num)}
          >
            <Modal.Header content='Add GED' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Series'
                    name='series'
                    options={[{key:1,value:1,text:'1'},{key:2,value:2,text:'2'},{key:3,value:3,text:'3'},{key:4,value:4,text:'4'},{key:5,value:5,text:'5'}]}
                    value={this.props.inmate.modals.ged.data.series}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'series', data.value as number)}
                  />
                  <Form.Field
                    control={Select}
                    label='Facility'
                    name='facilityID'
                    options={this.props.inmate.options.facilities}
                    value={this.props.inmate.modals.ged.data.facilityID}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'facilityID', data.value as number)}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Test Date"
                    name="testDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.ged.data.testDate ? new Date(this.props.inmate.modals.ged.data.testDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'ged', 'testDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                  />
                  <Form.Field
                    control={Select}
                    label='Practice'
                    placeholder='Practice'
                    name='practice'
                    options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                    value={this.props.inmate.modals.ged.data.practice}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'practice', data.value as boolean)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Science'
                    name='science'
                    value={this.props.inmate.modals.ged.data.science || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'science', data.value.validate())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Mathematics'
                    name='mathematics'
                    value={this.props.inmate.modals.ged.data.mathematics || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'mathematics', data.value.validate())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Social Studies'
                    name='socialStudies'
                    value={this.props.inmate.modals.ged.data.socialStudies || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'socialStudies', data.value.validate())}
                    width={3}
                  />
                  {this.props.inmate.modals.ged.data.series == 5 ? <Fragment>
                    <Form.Field
                      control={Input}
                      label='Literature and Arts'
                      name='literature'
                      value={this.props.inmate.modals.ged.data.literature || ''}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'literature', data.value.validate())}
                      width={3}
                    />
                    <Form.Field
                      control={Input}
                      label='CivicsAZ'
                      name='civicsAZ'
                      value={this.props.inmate.modals.ged.data.civicsAZ || ''}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'civicsAZ', data.value.validate())}
                      width={3}
                    />
                  </Fragment> : null}
                  {this.props.inmate.modals.ged.data.series == 4 ? <Fragment>
                    <Form.Field
                      control={Input}
                      label='Reading'
                      name='reading'
                      value={this.props.inmate.modals.ged.data.reading || ''}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'reading', data.value.validate())}
                      width={3}
                    />
                    <Form.Field
                      control={Input}
                      label='Writing'
                      name='writing'
                      value={this.props.inmate.modals.ged.data.writing || ''}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'writing', data.value.validate())}
                      width={3}
                    />
                  </Fragment> : null}
                  {this.props.inmate.modals.ged.data.series < 4 ? <Fragment>
                    <Form.Field
                      control={Input}
                      label='Literature and Arts'
                      name='literature'
                      value={this.props.inmate.modals.ged.data.literature || ''}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'literature', data.value.validate())}
                      width={3}
                    />
                    <Form.Field
                      control={Input}
                      label='Writing'
                      name='writing'
                      value={this.props.inmate.modals.ged.data.writing || ''}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'writing', data.value.validate())}
                      width={2}
                    />
                    <Form.Field
                      control={Input}
                      label='Essay'
                      name='essay'
                      value={this.props.inmate.modals.ged.data.essay || ''}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'ged', 'essay', data.value.validate())}
                      width={2}
                    />
                  </Fragment> : null}
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleGED(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.addGED(this.props.num), 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editGED.open}
            onClose={() => this.props.toggleEditGED(this.props.num)}
          >
            <Modal.Header content='Edit GED' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Series'
                    name='series'
                    options={[{key:1,value:1,text:'1'},{key:2,value:2,text:'2'},{key:3,value:3,text:'3'},{key:4,value:4,text:'4'},{key:5,value:5,text:'5'}]}
                    value={this.props.inmate.modals.editGED.data.series}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editGED', 'series', data.value as number)}
                  />
                  <Form.Field
                    control={Select}
                    label='Facility'
                    name='facilityID'
                    options={this.props.inmate.options.facilities}
                    value={this.props.inmate.modals.editGED.data.facilityID}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editGED', 'facilityID', data.value as number)}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Test Date"
                    name="testDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.editGED.data.testDate ? new Date(this.props.inmate.modals.editGED.data.testDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'editGED', 'testDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                  />
                  <Form.Field
                    control={Select}
                    label='Practice'
                    placeholder='Practice'
                    name='practice'
                    options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                    value={this.props.inmate.modals.editGED.data.practice}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editGED', 'practice', data.value as boolean)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Science'
                    name='science'
                    value={this.props.inmate.modals.editGED.data.science || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editGED', 'science', data.value.validate())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Mathematics'
                    name='mathematics'
                    value={this.props.inmate.modals.editGED.data.mathematics || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editGED', 'mathematics', data.value.validate())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Social Studies'
                    name='socialStudies'
                    value={this.props.inmate.modals.editGED.data.socialStudies || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editGED', 'socialStudies', data.value.validate())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Literature and Arts'
                    name='literature'
                    value={this.props.inmate.modals.editGED.data.literature || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editGED', 'literature', data.value.validate())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='CivicsAZ'
                    name='civicsAZ'
                    value={this.props.inmate.modals.editGED.data.civicsAZ || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editGED', 'civicsAZ', data.value.validate())}
                    width={3}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditGED(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeGED(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.saveGED(this.props.num), 10000, true)}>Save</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.hiset.open}
            onClose={() => this.props.toggleHiSET(this.props.num)}
          >
            <Modal.Header content='Add HiSET' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Facility'
                    name='facilityID'
                    options={this.props.inmate.options.facilities}
                    value={this.props.inmate.modals.hiset.data.facilityID}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'hiset', 'facilityID', data.value as number)}
                  />
                  <Form.Field
                    control={Input}
                    label='ETS ID'
                    name='etsID'
                    value={this.props.inmate.modals.hiset.data.etsID || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'hiset', 'etsID', data.value.validate())}
                  />
                  <Form.Field
                    control={Select}
                    label='Essay score'
                    name='essay'
                    options={[{ key: 0, value: 0, text: '0' },{ key: 1, value: 1, text: '1' },{ key: 2, value: 2, text: '2' },{ key: 3, value: 3, text: '3' },{ key: 4, value: 4, text: '4' },{ key: 5, value: 5, text: '5' },{ key: 6, value: 6, text: '6' }]}
                    value={this.props.inmate.modals.hiset.data.essay}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'hiset', 'essay', data.value as number)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Math'
                    name='math'
                    value={this.props.inmate.modals.hiset.data.math || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'hiset', 'math', data.value.validateHiSET())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Science'
                    name='science'
                    value={this.props.inmate.modals.hiset.data.science || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'hiset', 'science', data.value.validateHiSET())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Social Studies'
                    name='socialStudies'
                    value={this.props.inmate.modals.hiset.data.socialStudies || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'hiset', 'socialStudies', data.value.validateHiSET())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Reading'
                    name='reading'
                    value={this.props.inmate.modals.hiset.data.reading || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'hiset', 'reading', data.value.validateHiSET())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Writing'
                    name='writing'
                    value={this.props.inmate.modals.hiset.data.writing || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'hiset', 'writing', data.value.validateHiSET())}
                    width={3}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={DatePicker}
                    label="Math date"
                    name="mathDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.hiset.data.mathDate ? new Date(this.props.inmate.modals.hiset.data.mathDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'hiset', 'mathDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Science date"
                    name="scienceDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.hiset.data.scienceDate ? new Date(this.props.inmate.modals.hiset.data.scienceDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'hiset', 'scienceDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Social Studies date"
                    name="socialStudiesDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.hiset.data.socialStudiesDate ? new Date(this.props.inmate.modals.hiset.data.socialStudiesDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'hiset', 'socialStudiesDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Reading date"
                    name="readingDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.hiset.data.readingDate ? new Date(this.props.inmate.modals.hiset.data.readingDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'hiset', 'readingDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Writing date"
                    name="writingDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.hiset.data.writingDate ? new Date(this.props.inmate.modals.hiset.data.writingDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'hiset', 'writingDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleHiSET(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.addHiSET(this.props.num), 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editHiSET.open}
            onClose={() => this.props.toggleEditHiSET(this.props.num)}
          >
            <Modal.Header content='Edit HiSET' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Facility'
                    name='facilityID'
                    options={this.props.inmate.options.facilities}
                    value={this.props.inmate.modals.editHiSET.data.facilityID}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'facilityID', data.value as number)}
                  />
                  <Form.Field
                    control={Input}
                    label='ETS ID'
                    name='etsID'
                    value={this.props.inmate.modals.editHiSET.data.etsID || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'etsID', data.value.validate())}
                  />
                  <Form.Field
                    control={Select}
                    label='Essay score'
                    name='essay'
                    options={[{ key: 0, value: 0, text: '0' },{ key: 1, value: 1, text: '1' },{ key: 2, value: 2, text: '2' },{ key: 3, value: 3, text: '3' },{ key: 4, value: 4, text: '4' },{ key: 5, value: 5, text: '5' },{ key: 6, value: 6, text: '6' }]}
                    value={this.props.inmate.modals.editHiSET.data.essay}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'essay', data.value as number)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Math'
                    name='math'
                    value={this.props.inmate.modals.editHiSET.data.math || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'math', data.value.validateHiSET())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Science'
                    name='science'
                    value={this.props.inmate.modals.editHiSET.data.science || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'science', data.value.validateHiSET())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Social Studies'
                    name='socialStudies'
                    value={this.props.inmate.modals.editHiSET.data.socialStudies || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'socialStudies', data.value.validateHiSET())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Reading'
                    name='reading'
                    value={this.props.inmate.modals.editHiSET.data.reading || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'reading', data.value.validateHiSET())}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Writing'
                    name='writing'
                    value={this.props.inmate.modals.editHiSET.data.writing || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'writing', data.value.validateHiSET())}
                    width={3}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={DatePicker}
                    label="Math date"
                    name="mathDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.editHiSET.data.mathDate ? new Date(this.props.inmate.modals.editHiSET.data.mathDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'mathDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Science date"
                    name="scienceDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.editHiSET.data.scienceDate ? new Date(this.props.inmate.modals.editHiSET.data.scienceDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'scienceDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Social Studies date"
                    name="socialStudiesDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.editHiSET.data.socialStudiesDate ? new Date(this.props.inmate.modals.editHiSET.data.socialStudiesDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'socialStudiesDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Reading date"
                    name="readingDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.editHiSET.data.readingDate ? new Date(this.props.inmate.modals.editHiSET.data.readingDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'readingDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={DatePicker}
                    label="Writing date"
                    name="writingDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.editHiSET.data.writingDate ? new Date(this.props.inmate.modals.editHiSET.data.writingDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'editHiSET', 'writingDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditHiSET(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.saveHiSET(this.props.num), 10000, true)}>Save</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.riasec.open}
            onClose={() => this.props.toggleRIASEC(this.props.num)}
          >
            <Modal.Header content='Add RIASEC' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={DatePicker}
                    label="Test date"
                    name="testDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.riasec.data.testDate ? new Date(this.props.inmate.modals.riasec.data.testDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'riasec', 'testDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Realistic'
                    name='hollandR'
                    value={this.props.inmate.modals.riasec.data.hollandR || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'riasec', 'hollandR', data.value.validateRIASEC())}
                    width={2}
                  />
                  <Form.Field
                    control={Input}
                    label='Investigative'
                    name='hollandI'
                    value={this.props.inmate.modals.riasec.data.hollandI || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'riasec', 'hollandI', data.value.validateRIASEC())}
                    width={2}
                  />
                  <Form.Field
                    control={Input}
                    label='Artistic'
                    name='hollandA'
                    value={this.props.inmate.modals.riasec.data.hollandA || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'riasec', 'hollandA', data.value.validateRIASEC())}
                    width={2}
                  />
                  <Form.Field
                    control={Input}
                    label='Social'
                    name='hollandS'
                    value={this.props.inmate.modals.riasec.data.hollandS || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'riasec', 'hollandS', data.value.validateRIASEC())}
                    width={2}
                  />
                  <Form.Field
                    control={Input}
                    label='Enterprising'
                    name='hollandE'
                    value={this.props.inmate.modals.riasec.data.hollandE || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'riasec', 'hollandE', data.value.validateRIASEC())}
                    width={2}
                  />
                  <Form.Field
                    control={Input}
                    label='Conventional'
                    name='hollandC'
                    value={this.props.inmate.modals.riasec.data.hollandC || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'riasec', 'hollandC', data.value.validateRIASEC())}
                    width={2}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleRIASEC(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.addRIASEC(this.props.num), 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editRIASEC.open}
            onClose={() => this.props.toggleEditRIASEC(this.props.num)}
          >
            <Modal.Header content='Edit RIASEC' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={DatePicker}
                    label="Test date"
                    name="testDate"
                    placeholder="MM/DD/YYYY"
                    selected={this.props.inmate.modals.editRIASEC.data.testDate ? new Date(this.props.inmate.modals.editRIASEC.data.testDate) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'editRIASEC', 'testDate', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                    width={3}
                  />
                  <Form.Field
                    control={Input}
                    label='Realistic'
                    name='hollandR'
                    value={this.props.inmate.modals.editRIASEC.data.hollandR || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editRIASEC', 'hollandR', data.value.validateRIASEC())}
                    width={2}
                  />
                  <Form.Field
                    control={Input}
                    label='Investigative'
                    name='hollandI'
                    value={this.props.inmate.modals.editRIASEC.data.hollandI || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editRIASEC', 'hollandI', data.value.validateRIASEC())}
                    width={2}
                  />
                  <Form.Field
                    control={Input}
                    label='Artistic'
                    name='hollandA'
                    value={this.props.inmate.modals.editRIASEC.data.hollandA || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editRIASEC', 'hollandA', data.value.validateRIASEC())}
                    width={2}
                  />
                  <Form.Field
                    control={Input}
                    label='Social'
                    name='hollandS'
                    value={this.props.inmate.modals.editRIASEC.data.hollandS || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editRIASEC', 'hollandS', data.value.validateRIASEC())}
                    width={2}
                  />
                  <Form.Field
                    control={Input}
                    label='Enterprising'
                    name='hollandE'
                    value={this.props.inmate.modals.editRIASEC.data.hollandE || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editRIASEC', 'hollandE', data.value.validateRIASEC())}
                    width={2}
                  />
                  <Form.Field
                    control={Input}
                    label='Conventional'
                    name='hollandC'
                    value={this.props.inmate.modals.editRIASEC.data.hollandC || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editRIASEC', 'hollandC', data.value.validateRIASEC())}
                    width={2}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditRIASEC(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeRIASEC(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.saveRIASEC(this.props.num), 10000, true)}>Save</Button>
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print, hide: ownProps.hide } },
  PanesStore.actionCreators
)(InmateTests as any)