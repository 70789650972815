import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Grid,
  Form,
  Header,
  Select,
  Table,
  TextArea,
  Input,
  Button,
  Modal,
  Divider,
  Checkbox,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2 } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateFollowups extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchFollowups(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      return (
        <Container fluid>
          <div style={{display:'inline-block',marginTop:20}}>
            <Header as='h3'>Followups</Header>
          </div>
          <Button positive circular icon='plus' onClick={() => this.props.toggleFollowup(this.props.num)} style={{marginLeft:10,marginBottom:10}} />
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Info Source</Table.HeaderCell>
                        <Table.HeaderCell>Employed</Table.HeaderCell>
                        <Table.HeaderCell>UA Date</Table.HeaderCell>
                        <Table.HeaderCell>UA Substances</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell><Popup position='top center' content='Incarcerated' trigger={<span>Incar.</span>} /></Table.HeaderCell>
                        <Table.HeaderCell><Popup position='top center' content='New Charges' trigger={<span>Charg.</span>} /></Table.HeaderCell>
                        <Table.HeaderCell><Popup position='top center' content='Aftercare' trigger={<span>After.</span>} /></Table.HeaderCell>
                        <Table.HeaderCell><Popup position='top center' content='Support' trigger={<span>Supp.</span>} /></Table.HeaderCell>
                        <Table.HeaderCell><Popup position='top center' content='Reentered' trigger={<span>Reent.</span>} /></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.followups ? this.props.inmate.followups.map((followup:any) => (
                        <Table.Row key={followup.recordID} onClick={() => this.props.editFollowup(this.props.num, this.props.inmate.info.recordID, followup.recordID)}>
                          <Table.Cell>{followup.followupDate}</Table.Cell>
                          <Table.Cell><Popup position='top center' content={followup.followupTypeDisplay} trigger={<span>{followup.followupType}</span>} /></Table.Cell>
                          <Table.Cell><Popup position='top center' content={followup.infoSourceDisplay} trigger={<span>{followup.infoSource}</span>} /></Table.Cell>
                          <Table.Cell><Checkbox checked={followup.employed} /></Table.Cell>
                          <Table.Cell>{followup.uaDate}</Table.Cell>
                          <Table.Cell><Popup position='top center' content={followup.uaSubstancesDisplay} trigger={<span>{followup.uaSubstances}</span>} /></Table.Cell>
                          <Table.Cell><Popup position='top center' content={followup.paroleStatusDisplay} trigger={<span>{followup.paroleStatus}</span>} /></Table.Cell>
                          <Table.Cell><Checkbox checked={followup.incarcerated} /></Table.Cell>
                          <Table.Cell><Checkbox checked={followup.newCharges} /></Table.Cell>
                          <Table.Cell><Checkbox checked={followup.aftercare} /></Table.Cell>
                          <Table.Cell><Checkbox checked={followup.support} /></Table.Cell>
                          <Table.Cell><Checkbox checked={followup.reentered} /></Table.Cell>
                        </Table.Row>
                      )) : null}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <Modal
            open={this.props.inmate.modals.followup.open}
            onClose={() => this.props.toggleFollowup(this.props.num)}
          >
            <Modal.Header content={this.props.inmate.modals.followup.data ? 'Save Followup' : 'Add Followup'} />
            <Modal.Content>
              <Grid padded>
                <Grid.Column width={16} style={{marginBottom:0}}>
                  <Form>
                    <Form.Group>
                      <Form.Field
                        label="Followup Date"
                        control={DatePicker}
                        selected={this.props.inmate.modals.followup.data.followupDate ? new Date(this.props.inmate.modals.followup.data.followupDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'followup', 'followupDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                      <Form.Field
                        control={Select}
                        label="Followup Type"
                        placeholder="Followup Type"
                        name="followupType"
                        options={[{key:'L',value:'L',text:'Letter'},{key:'P',value:'P',text:'Phone call'},{key:'M',value:'M',text:'Meeting'},{key:'R',value:'R',text:'Parole report'},{key:'O',value:'O',text:'OBTS'}]}
                        value={this.props.inmate.modals.followup.data.followupType}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'followupType', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Select}
                        label="Information Source"
                        placeholder="Information Source"
                        name="informationSource"
                        options={[{key:'P',value:'P',text:'Parole officer'},{key:'R',value:'R',text:'Records'},{key:'C',value:'C',text:'Client'}]}
                        value={this.props.inmate.modals.followup.data.infoSource}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'infoSource', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Select}
                        label="Currently Employed"
                        placeholder="Currently Employed"
                        name="employed"
                        options={[{key:1,value:true,text:'Yes'},{key:0,value:false,text:'No'}]}
                        value={this.props.inmate.modals.followup.data.employed}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'employed', data.value)}
                        width={4}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        label="Positive UA within six months"
                        placeholder="Positive UA"
                        name="positive"
                        options={[{key:1,value:true,text:'Yes'},{key:0,value:false,text:'No'}]}
                        value={this.props.inmate.modals.followup.data.positive}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'positive', data.value)}
                        width={4}
                      />
                      <Form.Field
                        label="UA Date"
                        control={DatePicker}
                        selected={this.props.inmate.modals.followup.data.uaDate ? new Date(this.props.inmate.modals.followup.data.uaDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'followup', 'uaDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={3}
                        style={{width:'100%'}}
                      />
                      <Form.Field
                        control={Select}
                        label="Drug Type 1"
                        placeholder="Drug Type 1"
                        name="drugType1"
                        options={this.props.inmate.options.drugs}
                        value={this.props.inmate.modals.followup.data.drugType1}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'drugType1', data.value)}
                        width={3}
                        style={{minWidth:'100%'}}
                      />
                      <Form.Field
                        control={Select}
                        label="Drug Type 2"
                        placeholder="Drug Type 2"
                        name="drugType2"
                        options={this.props.inmate.options.drugs}
                        value={this.props.inmate.modals.followup.data.drugType2}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'drugType2', data.value)}
                        width={3}
                        style={{minWidth:'100%'}}
                      />
                      <Form.Field
                        control={Select}
                        label="Drug Type 3"
                        placeholder="Drug Type 3"
                        name="drugType3"
                        options={this.props.inmate.options.drugs}
                        value={this.props.inmate.modals.followup.data.drugType3}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'drugType3', data.value)}
                        width={3}
                        style={{minWidth:'100%'}}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        label="Parole Status"
                        placeholder="Parole Status"
                        name="paroleStatus"
                        options={[{key:'O',value:'O',text:'On parole'},{key:'W',value:'W',text:'Warrant'},{key:'R',value:'R',text:'Revoked'},{key:'P',value:'P',text:'Discharged'}]}
                        value={this.props.inmate.modals.followup.data.paroleStatus}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'paroleStatus', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Select}
                        label="New Charges"
                        placeholder="New Charges"
                        name="newCharges"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.followup.data.newCharges}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'newCharges', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Select}
                        label="Aftercare within six months"
                        placeholder="Aftercare"
                        name="aftercare"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.followup.data.aftercare}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'aftercare', data.value)}
                        width={4}
                      />
                      <Form.Field
                        control={Select}
                        label="Attending Support Group"
                        placeholder="Attending Support"
                        name="support"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.followup.data.support}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'support', data.value)}
                        width={4}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        label="Returned to Prison"
                        placeholder="Returned to Prison"
                        name="returned"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.followup.data.returned}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'returned', data.value)}
                        width={4}
                      />
                      <Form.Field
                        label="Returned Date"
                        control={DatePicker}
                        selected={this.props.inmate.modals.followup.data.returnedDate ? new Date(this.props.inmate.modals.followup.data.returnedDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'followup', 'returnedDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                      <Form.Field
                        control={Select}
                        label="Reentered Treatment"
                        placeholder="Reentered Treatment"
                        name="reentered"
                        options={[{key:'U',value:'U',text:'Unknown'},{key:'Y',value:'Y',text:'Yes'},{key:'N',value:'N',text:'No'}]}
                        value={this.props.inmate.modals.followup.data.reentered}
                        onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'followup', 'reentered', data.value)}
                        width={4}
                      />
                      <Form.Field
                        label="Reentered Date"
                        control={DatePicker}
                        selected={this.props.inmate.modals.followup.data.reenteredDate ? new Date(this.props.inmate.modals.followup.data.reenteredDate) : null}
                        onChange={(date:any) => this.props.modalDataChange(this.props.num, 'followup', 'reenteredDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        dateFormat="MM/dd/yyyy"
                        width={4}
                        style={{width:'100%'}}
                      />
                    </Form.Group>
                  </Form>
                </Grid.Column>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleFollowup(this.props.num)}>Close</Button>
              {!this.props.inmate.modals.followup.data.followupDate ? <Popup position='top center' content='Please make sure the followup has a date.' trigger={<Button>{this.props.inmate.modals.followup.data.recordID ? 'Save' : 'Add'}</Button>} /> : <Button color='green' onClick={() => this.props.inmate.modals.followup.data.recordID ? this.props.saveFollowup(this.props.num) : this.props.addFollowup(this.props.num)}>{this.props.inmate.modals.followup.data.recordID ? 'Save' : 'Add'}</Button>}
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num } },
  PanesStore.actionCreators
)(InmateFollowups as any)