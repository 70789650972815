import React, { Fragment } from 'react'
import {
  Table,
  Container
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class Kamakani extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let params = this.props.report.data
        let data = this.props.report.generated
        let num = 0

        switch (params.reportType) {
          case 'course':
            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Kamakani Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'course-facility' ? 'Course / Facility' : params.reportType == 'course' ? 'Course' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'funding' ? 'Funding' : params.reportType == 'funding-facility' ? 'Funding / Facility' : ''}</div></div>
                </div>
                <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{border:0}}><div><span>Program & Course</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Unique Inmates</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Actual Sessions</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Actual Hours</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Scheduled Sessions</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Scheduled Hours</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Percent Scheduled</span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.courses.map((course:any, c:number) => (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={c}>
                        <Table.Cell warning={course.highlight}>{`${course.programName} -- ${course.courseName}`}</Table.Cell>
                        <Table.Cell warning={course.scheduledHighlight}>{course.unique}</Table.Cell>
                        <Table.Cell warning={course.actualHighlight}>{course.actualSessions}</Table.Cell>
                        <Table.Cell warning={course.actualHighlight}>{course.actualHours}</Table.Cell>
                        <Table.Cell warning={course.scheduledHighlight}>{course.scheduledSessions}</Table.Cell>
                        <Table.Cell warning={course.scheduledHighlight}>{course.scheduledHours}</Table.Cell>
                        <Table.Cell>{`${course.percentScheduled}%`}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Container>
            )
          case 'course-facility':
              return (
                <Container fluid>
                  <div style={{textAlign:'center',marginBottom:15}}>
                    <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Kamakani Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                  </div>
                  <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'course-facility' ? 'Course / Facility' : params.reportType == 'course' ? 'Course' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'funding' ? 'Funding' : params.reportType == 'funding-facility' ? 'Funding / Facility' : ''}</div></div>
                  </div>
                  <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{border:0}}><div><span>Program & Course</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Unique Inmates</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Actual Sessions</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Actual Hours</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Scheduled Sessions</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Scheduled Hours</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Percent Scheduled</span></div></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.courses.map((course:any, c:number) =>
                        course.facilities.map((facility:any, f:number) => (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={`${c}:${f}`}>
                            <Table.Cell warning={course.highlight}>{f == 0 ? `${course.programName} -- ${course.courseName}` : ''}</Table.Cell>
                            <Table.Cell warning={facility.highlight}>{facility.abbreviation}</Table.Cell>
                            <Table.Cell warning={facility.scheduledHighlight}>{facility.unique}</Table.Cell>
                            <Table.Cell warning={facility.actualHighlight}>{facility.actualSessions}</Table.Cell>
                            <Table.Cell warning={facility.actualHighlight}>{facility.actualHours}</Table.Cell>
                            <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledSessions}</Table.Cell>
                            <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledHours}</Table.Cell>
                            <Table.Cell>{`${facility.percentScheduled}%`}</Table.Cell>
                          </Table.Row>
                        ))
                      )}
                    </Table.Body>
                  </Table>
                </Container>
              )
          case 'funding-facility':
              return (
                <Container fluid>
                  <div style={{textAlign:'center',marginBottom:15}}>
                    <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Kamakani Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                  </div>
                  <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'course-facility' ? 'Course / Facility' : params.reportType == 'course' ? 'Course' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'funding' ? 'Funding' : params.reportType == 'funding-facility' ? 'Funding / Facility' : ''}</div></div>
                  </div>
                  <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{border:0}}><div><span>Funding Source</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Description</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Unique Inmates</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Actual Sessions</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Actual Hours</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Scheduled Sessions</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Scheduled Hours</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Percent Scheduled</span></div></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.funding.map((funding:any, u:number) =>
                        funding.facilities.map((facility:any, f:number) => (
                          <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={`${u}:${f}`}>
                            <Table.Cell>{f == 0 ? `${funding.fundingSource}` : ''}</Table.Cell>
                            <Table.Cell>{f == 0 ? `${funding.fundingDescription}` : ''}</Table.Cell>
                            <Table.Cell warning={facility.highlight}>{facility.abbreviation}</Table.Cell>
                            <Table.Cell warning={facility.scheduledHighlight}>{facility.unique}</Table.Cell>
                            <Table.Cell warning={facility.actualHighlight}>{facility.actualSessions}</Table.Cell>
                            <Table.Cell warning={facility.actualHighlight}>{facility.actualHours}</Table.Cell>
                            <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledSessions}</Table.Cell>
                            <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledHours}</Table.Cell>
                            <Table.Cell>{`${facility.percentScheduled}%`}</Table.Cell>
                          </Table.Row>
                        ))
                      )}
                    </Table.Body>
                  </Table>
                </Container>
              )
          case 'funding':
              return (
                <Container fluid>
                  <div style={{textAlign:'center',marginBottom:15}}>
                    <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Kamakani Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                  </div>
                  <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'course-facility' ? 'Course / Facility' : params.reportType == 'course' ? 'Course' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'funding' ? 'Funding' : params.reportType == 'funding-facility' ? 'Funding / Facility' : ''}</div></div>
                  </div>
                  <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{border:0}}><div><span>Funding Source</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Description</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Unique Inmates</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Actual Sessions</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Actual Hours</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Scheduled Sessions</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Scheduled Hours</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Percent Scheduled</span></div></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.funding.map((funding:any, f:number) => (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={f}>
                          <Table.Cell>{funding.fundingSource}</Table.Cell>
                          <Table.Cell>{funding.fundingDescription}</Table.Cell>
                          <Table.Cell warning={funding.scheduledHighlight}>{funding.unique}</Table.Cell>
                          <Table.Cell warning={funding.actualHighlight}>{funding.actualSessions}</Table.Cell>
                          <Table.Cell warning={funding.actualHighlight}>{funding.actualHours}</Table.Cell>
                          <Table.Cell warning={funding.scheduledHighlight}>{funding.scheduledSessions}</Table.Cell>
                          <Table.Cell warning={funding.scheduledHighlight}>{funding.scheduledHours}</Table.Cell>
                          <Table.Cell>{`${funding.percentScheduled}%`}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Container>
              )
          case 'facility':
              return (
                <Container fluid>
                  <div style={{textAlign:'center',marginBottom:15}}>
                    <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Kamakani Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                  </div>
                  <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                    <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'course-facility' ? 'Course / Facility' : params.reportType == 'course' ? 'Course' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'funding' ? 'Funding' : params.reportType == 'funding-facility' ? 'Funding / Facility' : ''}</div></div>
                  </div>
                  <Table compact celled striped unstackable style={{marginTop:0}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Unique Inmates</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Actual Sessions</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Actual Hours</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Scheduled Sessions</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Scheduled Hours</span></div></Table.HeaderCell>
                        <Table.HeaderCell style={{border:0}}><div><span>Percent Scheduled</span></div></Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data.facilityData.map((facility:any, f:number) => (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={f}>
                          <Table.Cell warning={facility.highlight}>{facility.abbreviation}</Table.Cell>
                          <Table.Cell warning={facility.scheduledHighlight}>{facility.unique}</Table.Cell>
                          <Table.Cell warning={facility.actualHighlight}>{facility.actualSessions}</Table.Cell>
                          <Table.Cell warning={facility.actualHighlight}>{facility.actualHours}</Table.Cell>
                          <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledSessions}</Table.Cell>
                          <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledHours}</Table.Cell>
                          <Table.Cell>{`${facility.percentScheduled}%`}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Container>
              )
          default:
              return null
          }
    }
}

export default Kamakani