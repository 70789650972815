import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format, getDay } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Search,
  Checkbox,
  Button,
  Select,
  Modal,
  Popup,
  Divider,
  Accordion,
  Table,
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, staff: any } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateAddClassSessions extends React.PureComponent<RosterProps> {
    public componentDidMount() {
        this.props.fetchAttendanceAddClass(this.props.num, this.props.inmate.add.id)
    }

    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public render() {
        const add = this.props.inmate.add
        return (
          <Container fluid style={{marginTop:20}}>
            <Form>
              <Form.Group>
                <Form.Field
                  control={DatePicker}
                  label="Date From"
                  name="dateFrom"
                  placeholder="MM/DD/YYYY"
                  selected={add.dateFrom && add.dateFrom.length > 0 ? new Date(add.dateFrom) : null}
                  onChange={(date:any) => this.props.updateAddClassAttendanceFilter(this.props.num, 'dateFrom', date ? format(date, 'MM/dd/yyyy') : null)}
                  filterDate={(date:any) => date >= new Date(add.dateBegins) && date <= new Date(add.dateEnds)}
                  width={6}
                />
                <Form.Field
                  control={DatePicker}
                  label="Date To"
                  name="dateTo"
                  placeholder="MM/DD/YYYY"
                  selected={add.dateTo && add.dateTo.length > 0 ? new Date(add.dateTo) : null}
                  onChange={(date:any) => this.props.updateAddClassAttendanceFilter(this.props.num, 'dateTo', date ? format(date, 'MM/dd/yyyy') : null)}
                  filterDate={(date:any) => date >= new Date(add.dateBegins) && date <= new Date(add.dateEnds)}
                  width={6}
                />
                <Button color='blue' content='Clear dates' onClick={() => this.props.clearAddClassAttendanceDates(this.props.num)} style={{height:33,marginTop:21,marginLeft:8}}  />
              </Form.Group>
            </Form>
            <div>
              {add.attendance.map((session:any, i:number) => (
                <div key={i} style={{marginLeft:6,width:(i % 2 ? 230 : 250),marginBottom:4,display:'inline-block'}}><Checkbox
                  label={`${this.dayOfWeek3(getDay(new Date(session.meetingDate)))}. ${session.meetingDate} — ${session.startTime} - ${session.endTime}`}
                  checked={session.checked == true}
                  onChange={() => this.props.toggleAddClassSession(this.props.num, session.sessionID)}
                /></div>
              ))}
            </div>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, staff: state.staff.staff } },
  PanesStore.actionCreators
)(InmateAddClassSessions as any)