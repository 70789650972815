import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps = { report: any }

class RecidivismGlobal extends React.PureComponent<ReportProps> {
    public render() {
      let params = this.props.report.data
      let data = this.props.report.generated

      return (
        <div>
          <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
            <div style={{fontWeight:700,fontSize:16}}>Global</div>
          </div>
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
            <div style={{flexBasis:`${90/3}%`}}>
              <Table compact celled striped unstackable style={{margin:'2em 0',height:'0%'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell>Number of Returns</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>Recidivism</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.one.total == 0 ? '0' : Math.round(data.globals.returns.one.recidivism / data.globals.returns.one.total * 100) > 100 ? '100' : Math.round(data.globals.returns.one.recidivism / data.globals.returns.one.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.one.recidivism.toFixed(0)} / {data.globals.returns.one.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.two.total == 0 ? '0' : Math.round(data.globals.returns.two.recidivism / data.globals.returns.two.total * 100) > 100 ? '100' : Math.round(data.globals.returns.two.recidivism / data.globals.returns.two.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.two.recidivism.toFixed(0)} / {data.globals.returns.two.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.three.total == 0 ? '0' : Math.round(data.globals.returns.three.recidivism / data.globals.returns.three.total * 100) > 100 ? '100' : Math.round(data.globals.returns.three.recidivism / data.globals.returns.three.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.three.recidivism.toFixed(0)} / {data.globals.returns.three.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.four.total == 0 ? '0' : Math.round(data.globals.returns.four.recidivism / data.globals.returns.four.total * 100) > 100 ? '100' : Math.round(data.globals.returns.four.recidivism / data.globals.returns.four.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.four.recidivism.toFixed(0)} / {data.globals.returns.four.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.five.total == 0 ? '0' : Math.round(data.globals.returns.five.recidivism / data.globals.returns.five.total * 100) > 100 ? '100' : Math.round(data.globals.returns.five.recidivism / data.globals.returns.five.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.five.recidivism.toFixed(0)} / {data.globals.returns.five.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.six.total == 0 ? '0' : Math.round(data.globals.returns.six.recidivism / data.globals.returns.six.total * 100) > 100 ? '100' : Math.round(data.globals.returns.six.recidivism / data.globals.returns.six.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.six.recidivism.toFixed(0)} / {data.globals.returns.six.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.seven.total == 0 ? '0' : Math.round(data.globals.returns.seven.recidivism / data.globals.returns.seven.total * 100) > 100 ? '100' : Math.round(data.globals.returns.seven.recidivism / data.globals.returns.seven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.seven.recidivism.toFixed(0)} / {data.globals.returns.seven.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.eight.total == 0 ? '0' : Math.round(data.globals.returns.eight.recidivism / data.globals.returns.eight.total * 100) > 100 ? '100' : Math.round(data.globals.returns.eight.recidivism / data.globals.returns.eight.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.eight.recidivism.toFixed(0)} / {data.globals.returns.eight.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.nine.total == 0 ? '0' : Math.round(data.globals.returns.nine.recidivism / data.globals.returns.nine.total * 100) > 100 ? '100' : Math.round(data.globals.returns.nine.recidivism / data.globals.returns.nine.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.nine.recidivism.toFixed(0)} / {data.globals.returns.nine.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.ten.total == 0 ? '0' : Math.round(data.globals.returns.ten.recidivism / data.globals.returns.ten.total * 100) > 100 ? '100' : Math.round(data.globals.returns.ten.recidivism / data.globals.returns.ten.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.ten.recidivism.toFixed(0)} / {data.globals.returns.ten.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.eleven.total == 0 ? '0' : Math.round(data.globals.returns.eleven.recidivism / data.globals.returns.eleven.total * 100) > 100 ? '100' : Math.round(data.globals.returns.eleven.recidivism / data.globals.returns.eleven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.eleven.recidivism.toFixed(0)} / {data.globals.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.twelve.total == 0 ? '0' : Math.round(data.globals.returns.twelve.recidivism / data.globals.returns.twelve.total * 100) > 100 ? '100' : Math.round(data.globals.returns.twelve.recidivism / data.globals.returns.twelve.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.twelve.recidivism.toFixed(0)} / {data.globals.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.thirteen.total == 0 ? '0' : Math.round(data.globals.returns.thirteen.recidivism / data.globals.returns.thirteen.total * 100) > 100 ? '100' : Math.round(data.globals.returns.thirteen.recidivism / data.globals.returns.thirteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.thirteen.recidivism.toFixed(0)} / {data.globals.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.fourteen.total == 0 ? '0' : Math.round(data.globals.returns.fourteen.recidivism / data.globals.returns.fourteen.total * 100) > 100 ? '100' : Math.round(data.globals.returns.fourteen.recidivism / data.globals.returns.fourteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.fourteen.recidivism.toFixed(0)} / {data.globals.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.fifteenPlus.total == 0 ? '0' : Math.round(data.globals.returns.fifteenPlus.recidivism / data.globals.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(data.globals.returns.fifteenPlus.recidivism / data.globals.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.returns.fifteenPlus.recidivism.toFixed(0)} / {data.globals.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            <div style={{flexBasis:`${90/3}%`}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Total</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.total == 0 ? '0' : Math.round(data.globals.recidivism / data.globals.total * 100) > 100 ? '100' : Math.round(data.globals.recidivism / data.globals.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.recidivism.toFixed(0)} / {data.globals.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table compact celled striped unstackable style={{margin:'2em 0',height:'0%'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell>Gender</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>Recidivism</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Male</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genders.male.total == 0 ? '0' : Math.round(data.globals.genders.male.recidivism / data.globals.genders.male.total * 100) > 100 ? '100' : Math.round(data.globals.genders.male.recidivism / data.globals.genders.male.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genders.male.recidivism.toFixed(0)} / {data.globals.genders.male.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Female</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genders.female.total == 0 ? '0' : Math.round(data.globals.genders.female.recidivism / data.globals.genders.female.total * 100) > 100 ? '100' : Math.round(data.globals.genders.female.recidivism / data.globals.genders.female.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genders.female.recidivism.toFixed(0)} / {data.globals.genders.female.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table compact celled striped unstackable style={{margin:'2em 0',height:'0%'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell>Age</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>Recidivism</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.ageUnder22.total == 0 ? '0' : Math.round(data.globals.ages.ageUnder22.recidivism / data.globals.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(data.globals.ages.ageUnder22.recidivism / data.globals.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.ageUnder22.recidivism.toFixed(0)} / {data.globals.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age22To30.total == 0 ? '0' : Math.round(data.globals.ages.age22To30.recidivism / data.globals.ages.age22To30.total * 100) > 100 ? '100' : Math.round(data.globals.ages.age22To30.recidivism / data.globals.ages.age22To30.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age22To30.recidivism.toFixed(0)} / {data.globals.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age30To40.total == 0 ? '0' : Math.round(data.globals.ages.age30To40.recidivism / data.globals.ages.age30To40.total * 100) > 100 ? '100' : Math.round(data.globals.ages.age30To40.recidivism / data.globals.ages.age30To40.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age30To40.recidivism.toFixed(0)} / {data.globals.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age40To50.total == 0 ? '0' : Math.round(data.globals.ages.age40To50.recidivism / data.globals.ages.age40To50.total * 100) > 100 ? '100' : Math.round(data.globals.ages.age40To50.recidivism / data.globals.ages.age40To50.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age40To50.recidivism.toFixed(0)} / {data.globals.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age50To60.total == 0 ? '0' : Math.round(data.globals.ages.age50To60.recidivism / data.globals.ages.age50To60.total * 100) > 100 ? '100' : Math.round(data.globals.ages.age50To60.recidivism / data.globals.ages.age50To60.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age50To60.recidivism.toFixed(0)} / {data.globals.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age60To70.total == 0 ? '0' : Math.round(data.globals.ages.age60To70.recidivism / data.globals.ages.age60To70.total * 100) > 100 ? '100' : Math.round(data.globals.ages.age60To70.recidivism / data.globals.ages.age60To70.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age60To70.recidivism.toFixed(0)} / {data.globals.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age70To80.total == 0 ? '0' : Math.round(data.globals.ages.age70To80.recidivism / data.globals.ages.age70To80.total * 100) > 100 ? '100' : Math.round(data.globals.ages.age70To80.recidivism / data.globals.ages.age70To80.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age70To80.recidivism.toFixed(0)} / {data.globals.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age80To90.total == 0 ? '0' : Math.round(data.globals.ages.age80To90.recidivism / data.globals.ages.age80To90.total * 100) > 100 ? '100' : Math.round(data.globals.ages.age80To90.recidivism / data.globals.ages.age80To90.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.ages.age80To90.recidivism.toFixed(0)} / {data.globals.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            <div style={{flexBasis:`${90/3}%`}}>
              <Table compact celled striped unstackable style={{margin:'2em 0',height:'0%'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell>Length of Stay</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>Recidivism</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.globals.stayLengths.lengthUnder3M.recidivism / data.globals.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.globals.stayLengths.lengthUnder3M.recidivism / data.globals.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.globals.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length3To6M.total == 0 ? '0' : Math.round(data.globals.stayLengths.length3To6M.recidivism / data.globals.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.globals.stayLengths.length3To6M.recidivism / data.globals.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length3To6M.recidivism.toFixed(0)} / {data.globals.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length6To12M.total == 0 ? '0' : Math.round(data.globals.stayLengths.length6To12M.recidivism / data.globals.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.globals.stayLengths.length6To12M.recidivism / data.globals.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length6To12M.recidivism.toFixed(0)} / {data.globals.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(data.globals.stayLengths.length1To2Y.recidivism / data.globals.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.globals.stayLengths.length1To2Y.recidivism / data.globals.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length1To2Y.recidivism.toFixed(0)} / {data.globals.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(data.globals.stayLengths.length2To5Y.recidivism / data.globals.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.globals.stayLengths.length2To5Y.recidivism / data.globals.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length2To5Y.recidivism.toFixed(0)} / {data.globals.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(data.globals.stayLengths.length5To10Y.recidivism / data.globals.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.globals.stayLengths.length5To10Y.recidivism / data.globals.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.stayLengths.length5To10Y.recidivism.toFixed(0)} / {data.globals.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Table compact celled striped unstackable style={{margin:'2em 0',height:'0%'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell>Length Between Returns</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>Recidivism</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.globals.outLengths.lengthUnder3M.recidivism / data.globals.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.globals.outLengths.lengthUnder3M.recidivism / data.globals.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.globals.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length3To6M.total == 0 ? '0' : Math.round(data.globals.outLengths.length3To6M.recidivism / data.globals.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.globals.outLengths.length3To6M.recidivism / data.globals.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length3To6M.recidivism.toFixed(0)} / {data.globals.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length6To12M.total == 0 ? '0' : Math.round(data.globals.outLengths.length6To12M.recidivism / data.globals.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.globals.outLengths.length6To12M.recidivism / data.globals.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length6To12M.recidivism.toFixed(0)} / {data.globals.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length1To2Y.total == 0 ? '0' : Math.round(data.globals.outLengths.length1To2Y.recidivism / data.globals.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.globals.outLengths.length1To2Y.recidivism / data.globals.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length1To2Y.recidivism.toFixed(0)} / {data.globals.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length2To5Y.total == 0 ? '0' : Math.round(data.globals.outLengths.length2To5Y.recidivism / data.globals.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.globals.outLengths.length2To5Y.recidivism / data.globals.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length2To5Y.recidivism.toFixed(0)} / {data.globals.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length5To10Y.total == 0 ? '0' : Math.round(data.globals.outLengths.length5To10Y.recidivism / data.globals.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.globals.outLengths.length5To10Y.recidivism / data.globals.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.outLengths.length5To10Y.recidivism.toFixed(0)} / {data.globals.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
            <div style={{flexBasis:`40%`}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Length of Stay and Gender</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.genders.male.stayLengths.lengthUnder3M.recidivism / data.genders.male.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.genders.male.stayLengths.lengthUnder3M.recidivism / data.genders.male.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.genders.male.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.genders.female.stayLengths.lengthUnder3M.recidivism / data.genders.female.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.genders.female.stayLengths.lengthUnder3M.recidivism / data.genders.female.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.genders.female.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length3To6M.total == 0 ? '0' : Math.round(data.genders.male.stayLengths.length3To6M.recidivism / data.genders.male.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.genders.male.stayLengths.length3To6M.recidivism / data.genders.male.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length3To6M.recidivism.toFixed(0)} / {data.genders.male.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length3To6M.total == 0 ? '0' : Math.round(data.genders.female.stayLengths.length3To6M.recidivism / data.genders.female.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.genders.female.stayLengths.length3To6M.recidivism / data.genders.female.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length3To6M.recidivism.toFixed(0)} / {data.genders.female.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length6To12M.total == 0 ? '0' : Math.round(data.genders.male.stayLengths.length6To12M.recidivism / data.genders.male.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.genders.male.stayLengths.length6To12M.recidivism / data.genders.male.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length6To12M.recidivism.toFixed(0)} / {data.genders.male.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length6To12M.total == 0 ? '0' : Math.round(data.genders.female.stayLengths.length6To12M.recidivism / data.genders.female.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.genders.female.stayLengths.length6To12M.recidivism / data.genders.female.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length6To12M.recidivism.toFixed(0)} / {data.genders.female.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(data.genders.male.stayLengths.length1To2Y.recidivism / data.genders.male.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.genders.male.stayLengths.length1To2Y.recidivism / data.genders.male.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length1To2Y.recidivism.toFixed(0)} / {data.genders.male.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(data.genders.female.stayLengths.length1To2Y.recidivism / data.genders.female.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.genders.female.stayLengths.length1To2Y.recidivism / data.genders.female.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length1To2Y.recidivism.toFixed(0)} / {data.genders.female.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(data.genders.male.stayLengths.length2To5Y.recidivism / data.genders.male.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.genders.male.stayLengths.length2To5Y.recidivism / data.genders.male.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length2To5Y.recidivism.toFixed(0)} / {data.genders.male.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(data.genders.female.stayLengths.length2To5Y.recidivism / data.genders.female.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.genders.female.stayLengths.length2To5Y.recidivism / data.genders.female.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length2To5Y.recidivism.toFixed(0)} / {data.genders.female.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(data.genders.male.stayLengths.length5To10Y.recidivism / data.genders.male.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.genders.male.stayLengths.length5To10Y.recidivism / data.genders.male.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.stayLengths.length5To10Y.recidivism.toFixed(0)} / {data.genders.male.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(data.genders.female.stayLengths.length5To10Y.recidivism / data.genders.female.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.genders.female.stayLengths.length5To10Y.recidivism / data.genders.female.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.stayLengths.length5To10Y.recidivism.toFixed(0)} / {data.genders.female.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            <div style={{flexBasis:`40%`}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Length Between Returns and Gender</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.genders.male.outLengths.lengthUnder3M.recidivism / data.genders.male.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.genders.male.outLengths.lengthUnder3M.recidivism / data.genders.male.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.genders.male.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.genders.female.outLengths.lengthUnder3M.recidivism / data.genders.female.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.genders.female.outLengths.lengthUnder3M.recidivism / data.genders.female.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.genders.female.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length3To6M.total == 0 ? '0' : Math.round(data.genders.male.outLengths.length3To6M.recidivism / data.genders.male.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.genders.male.outLengths.length3To6M.recidivism / data.genders.male.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length3To6M.recidivism.toFixed(0)} / {data.genders.male.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length3To6M.total == 0 ? '0' : Math.round(data.genders.female.outLengths.length3To6M.recidivism / data.genders.female.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.genders.female.outLengths.length3To6M.recidivism / data.genders.female.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length3To6M.recidivism.toFixed(0)} / {data.genders.female.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length6To12M.total == 0 ? '0' : Math.round(data.genders.male.outLengths.length6To12M.recidivism / data.genders.male.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.genders.male.outLengths.length6To12M.recidivism / data.genders.male.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length6To12M.recidivism.toFixed(0)} / {data.genders.male.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length6To12M.total == 0 ? '0' : Math.round(data.genders.female.outLengths.length6To12M.recidivism / data.genders.female.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.genders.female.outLengths.length6To12M.recidivism / data.genders.female.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length6To12M.recidivism.toFixed(0)} / {data.genders.female.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length1To2Y.total == 0 ? '0' : Math.round(data.genders.male.outLengths.length1To2Y.recidivism / data.genders.male.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.genders.male.outLengths.length1To2Y.recidivism / data.genders.male.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length1To2Y.recidivism.toFixed(0)} / {data.genders.male.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length1To2Y.total == 0 ? '0' : Math.round(data.genders.female.outLengths.length1To2Y.recidivism / data.genders.female.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.genders.female.outLengths.length1To2Y.recidivism / data.genders.female.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length1To2Y.recidivism.toFixed(0)} / {data.genders.female.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length2To5Y.total == 0 ? '0' : Math.round(data.genders.male.outLengths.length2To5Y.recidivism / data.genders.male.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.genders.male.outLengths.length2To5Y.recidivism / data.genders.male.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length2To5Y.recidivism.toFixed(0)} / {data.genders.male.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length2To5Y.total == 0 ? '0' : Math.round(data.genders.female.outLengths.length2To5Y.recidivism / data.genders.female.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.genders.female.outLengths.length2To5Y.recidivism / data.genders.female.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length2To5Y.recidivism.toFixed(0)} / {data.genders.female.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length5To10Y.total == 0 ? '0' : Math.round(data.genders.male.outLengths.length5To10Y.recidivism / data.genders.male.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.genders.male.outLengths.length5To10Y.recidivism / data.genders.male.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.outLengths.length5To10Y.recidivism.toFixed(0)} / {data.genders.male.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length5To10Y.total == 0 ? '0' : Math.round(data.genders.female.outLengths.length5To10Y.recidivism / data.genders.female.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.genders.female.outLengths.length5To10Y.recidivism / data.genders.female.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.outLengths.length5To10Y.recidivism.toFixed(0)} / {data.genders.female.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
            <div style={{flexBasis:`40%`}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Age and Gender</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.genders.male.total == 0 ? '0' : Math.round(data.ages.ageUnder22.genders.male.recidivism / data.ages.ageUnder22.genders.male.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.genders.male.recidivism / data.ages.ageUnder22.genders.male.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.genders.male.recidivism.toFixed(0)} / {data.ages.ageUnder22.genders.male.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.genders.female.total == 0 ? '0' : Math.round(data.ages.ageUnder22.genders.female.recidivism / data.ages.ageUnder22.genders.female.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.genders.female.recidivism / data.ages.ageUnder22.genders.female.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.genders.female.recidivism.toFixed(0)} / {data.ages.ageUnder22.genders.female.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.genders.male.total == 0 ? '0' : Math.round(data.ages.age22To30.genders.male.recidivism / data.ages.age22To30.genders.male.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.genders.male.recidivism / data.ages.age22To30.genders.male.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.genders.male.recidivism.toFixed(0)} / {data.ages.age22To30.genders.male.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.genders.female.total == 0 ? '0' : Math.round(data.ages.age22To30.genders.female.recidivism / data.ages.age22To30.genders.female.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.genders.female.recidivism / data.ages.age22To30.genders.female.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.genders.female.recidivism.toFixed(0)} / {data.ages.age22To30.genders.female.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.genders.male.total == 0 ? '0' : Math.round(data.ages.age30To40.genders.male.recidivism / data.ages.age30To40.genders.male.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.genders.male.recidivism / data.ages.age30To40.genders.male.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.genders.male.recidivism.toFixed(0)} / {data.ages.age30To40.genders.male.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.genders.female.total == 0 ? '0' : Math.round(data.ages.age30To40.genders.female.recidivism / data.ages.age30To40.genders.female.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.genders.female.recidivism / data.ages.age30To40.genders.female.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.genders.female.recidivism.toFixed(0)} / {data.ages.age30To40.genders.female.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.genders.male.total == 0 ? '0' : Math.round(data.ages.age40To50.genders.male.recidivism / data.ages.age40To50.genders.male.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.genders.male.recidivism / data.ages.age40To50.genders.male.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.genders.male.recidivism.toFixed(0)} / {data.ages.age40To50.genders.male.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.genders.female.total == 0 ? '0' : Math.round(data.ages.age40To50.genders.female.recidivism / data.ages.age40To50.genders.female.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.genders.female.recidivism / data.ages.age40To50.genders.female.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.genders.female.recidivism.toFixed(0)} / {data.ages.age40To50.genders.female.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.genders.male.total == 0 ? '0' : Math.round(data.ages.age50To60.genders.male.recidivism / data.ages.age50To60.genders.male.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.genders.male.recidivism / data.ages.age50To60.genders.male.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.genders.male.recidivism.toFixed(0)} / {data.ages.age50To60.genders.male.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.genders.female.total == 0 ? '0' : Math.round(data.ages.age50To60.genders.female.recidivism / data.ages.age50To60.genders.female.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.genders.female.recidivism / data.ages.age50To60.genders.female.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.genders.female.recidivism.toFixed(0)} / {data.ages.age50To60.genders.female.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.genders.male.total == 0 ? '0' : Math.round(data.ages.age60To70.genders.male.recidivism / data.ages.age60To70.genders.male.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.genders.male.recidivism / data.ages.age60To70.genders.male.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.genders.male.recidivism.toFixed(0)} / {data.ages.age60To70.genders.male.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.genders.female.total == 0 ? '0' : Math.round(data.ages.age60To70.genders.female.recidivism / data.ages.age60To70.genders.female.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.genders.female.recidivism / data.ages.age60To70.genders.female.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.genders.female.recidivism.toFixed(0)} / {data.ages.age60To70.genders.female.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.genders.male.total == 0 ? '0' : Math.round(data.ages.age70To80.genders.male.recidivism / data.ages.age70To80.genders.male.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.genders.male.recidivism / data.ages.age70To80.genders.male.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.genders.male.recidivism.toFixed(0)} / {data.ages.age70To80.genders.male.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.genders.female.total == 0 ? '0' : Math.round(data.ages.age70To80.genders.female.recidivism / data.ages.age70To80.genders.female.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.genders.female.recidivism / data.ages.age70To80.genders.female.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.genders.female.recidivism.toFixed(0)} / {data.ages.age70To80.genders.female.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.genders.male.total == 0 ? '0' : Math.round(data.ages.age80To90.genders.male.recidivism / data.ages.age80To90.genders.male.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.genders.male.recidivism / data.ages.age80To90.genders.male.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.genders.male.recidivism.toFixed(0)} / {data.ages.age80To90.genders.male.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.genders.female.total == 0 ? '0' : Math.round(data.ages.age80To90.genders.female.recidivism / data.ages.age80To90.genders.female.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.genders.female.recidivism / data.ages.age80To90.genders.female.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.genders.female.recidivism.toFixed(0)} / {data.ages.age80To90.genders.female.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            <div style={{flexBasis:`40%`}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Number of Returns and Gender</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.one.total == 0 ? '0' : Math.round(data.genders.male.returns.one.recidivism / data.genders.male.returns.one.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.one.recidivism / data.genders.male.returns.one.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.one.recidivism.toFixed(0)} / {data.genders.male.returns.one.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.one.total == 0 ? '0' : Math.round(data.genders.female.returns.one.recidivism / data.genders.female.returns.one.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.one.recidivism / data.genders.female.returns.one.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.one.recidivism.toFixed(0)} / {data.genders.female.returns.one.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.two.total == 0 ? '0' : Math.round(data.genders.male.returns.two.recidivism / data.genders.male.returns.two.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.two.recidivism / data.genders.male.returns.two.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.two.recidivism.toFixed(0)} / {data.genders.male.returns.two.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.two.total == 0 ? '0' : Math.round(data.genders.female.returns.two.recidivism / data.genders.female.returns.two.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.two.recidivism / data.genders.female.returns.two.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.two.recidivism.toFixed(0)} / {data.genders.female.returns.two.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.three.total == 0 ? '0' : Math.round(data.genders.male.returns.three.recidivism / data.genders.male.returns.three.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.three.recidivism / data.genders.male.returns.three.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.three.recidivism.toFixed(0)} / {data.genders.male.returns.three.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.three.total == 0 ? '0' : Math.round(data.genders.female.returns.three.recidivism / data.genders.female.returns.three.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.three.recidivism / data.genders.female.returns.three.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.three.recidivism.toFixed(0)} / {data.genders.female.returns.three.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.four.total == 0 ? '0' : Math.round(data.genders.male.returns.four.recidivism / data.genders.male.returns.four.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.four.recidivism / data.genders.male.returns.four.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.four.recidivism.toFixed(0)} / {data.genders.male.returns.four.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.four.total == 0 ? '0' : Math.round(data.genders.female.returns.four.recidivism / data.genders.female.returns.four.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.four.recidivism / data.genders.female.returns.four.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.four.recidivism.toFixed(0)} / {data.genders.female.returns.four.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.five.total == 0 ? '0' : Math.round(data.genders.male.returns.five.recidivism / data.genders.male.returns.five.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.five.recidivism / data.genders.male.returns.five.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.five.recidivism.toFixed(0)} / {data.genders.male.returns.five.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.five.total == 0 ? '0' : Math.round(data.genders.female.returns.five.recidivism / data.genders.female.returns.five.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.five.recidivism / data.genders.female.returns.five.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.five.recidivism.toFixed(0)} / {data.genders.female.returns.five.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.six.total == 0 ? '0' : Math.round(data.genders.male.returns.six.recidivism / data.genders.male.returns.six.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.six.recidivism / data.genders.male.returns.six.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.six.recidivism.toFixed(0)} / {data.genders.male.returns.six.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.six.total == 0 ? '0' : Math.round(data.genders.female.returns.six.recidivism / data.genders.female.returns.six.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.six.recidivism / data.genders.female.returns.six.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.six.recidivism.toFixed(0)} / {data.genders.female.returns.six.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.seven.total == 0 ? '0' : Math.round(data.genders.male.returns.seven.recidivism / data.genders.male.returns.seven.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.seven.recidivism / data.genders.male.returns.seven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.seven.recidivism.toFixed(0)} / {data.genders.male.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.seven.total == 0 ? '0' : Math.round(data.genders.female.returns.seven.recidivism / data.genders.female.returns.seven.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.seven.recidivism / data.genders.female.returns.seven.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.seven.recidivism.toFixed(0)} / {data.genders.female.returns.seven.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.eight.total == 0 ? '0' : Math.round(data.genders.male.returns.eight.recidivism / data.genders.male.returns.eight.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.eight.recidivism / data.genders.male.returns.eight.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.eight.recidivism.toFixed(0)} / {data.genders.male.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.eight.total == 0 ? '0' : Math.round(data.genders.female.returns.eight.recidivism / data.genders.female.returns.eight.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.eight.recidivism / data.genders.female.returns.eight.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.eight.recidivism.toFixed(0)} / {data.genders.female.returns.eight.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.nine.total == 0 ? '0' : Math.round(data.genders.male.returns.nine.recidivism / data.genders.male.returns.nine.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.nine.recidivism / data.genders.male.returns.nine.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.nine.recidivism.toFixed(0)} / {data.genders.male.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.nine.total == 0 ? '0' : Math.round(data.genders.female.returns.nine.recidivism / data.genders.female.returns.nine.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.nine.recidivism / data.genders.female.returns.nine.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.nine.recidivism.toFixed(0)} / {data.genders.female.returns.nine.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.ten.total == 0 ? '0' : Math.round(data.genders.male.returns.ten.recidivism / data.genders.male.returns.ten.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.ten.recidivism / data.genders.male.returns.ten.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.ten.recidivism.toFixed(0)} / {data.genders.male.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.ten.total == 0 ? '0' : Math.round(data.genders.female.returns.ten.recidivism / data.genders.female.returns.ten.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.ten.recidivism / data.genders.female.returns.ten.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.ten.recidivism.toFixed(0)} / {data.genders.female.returns.ten.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.eleven.total == 0 ? '0' : Math.round(data.genders.male.returns.eleven.recidivism / data.genders.male.returns.eleven.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.eleven.recidivism / data.genders.male.returns.eleven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.eleven.recidivism.toFixed(0)} / {data.genders.male.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.eleven.total == 0 ? '0' : Math.round(data.genders.female.returns.eleven.recidivism / data.genders.female.returns.eleven.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.eleven.recidivism / data.genders.female.returns.eleven.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.eleven.recidivism.toFixed(0)} / {data.genders.female.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.twelve.total == 0 ? '0' : Math.round(data.genders.male.returns.twelve.recidivism / data.genders.male.returns.twelve.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.twelve.recidivism / data.genders.male.returns.twelve.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.twelve.recidivism.toFixed(0)} / {data.genders.male.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.twelve.total == 0 ? '0' : Math.round(data.genders.female.returns.twelve.recidivism / data.genders.female.returns.twelve.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.twelve.recidivism / data.genders.female.returns.twelve.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.twelve.recidivism.toFixed(0)} / {data.genders.female.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.thirteen.total == 0 ? '0' : Math.round(data.genders.male.returns.thirteen.recidivism / data.genders.male.returns.thirteen.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.thirteen.recidivism / data.genders.male.returns.thirteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.thirteen.recidivism.toFixed(0)} / {data.genders.male.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.thirteen.total == 0 ? '0' : Math.round(data.genders.female.returns.thirteen.recidivism / data.genders.female.returns.thirteen.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.thirteen.recidivism / data.genders.female.returns.thirteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.thirteen.recidivism.toFixed(0)} / {data.genders.female.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.fourteen.total == 0 ? '0' : Math.round(data.genders.male.returns.fourteen.recidivism / data.genders.male.returns.fourteen.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.fourteen.recidivism / data.genders.male.returns.fourteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.fourteen.recidivism.toFixed(0)} / {data.genders.male.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.fourteen.total == 0 ? '0' : Math.round(data.genders.female.returns.fourteen.recidivism / data.genders.female.returns.fourteen.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.fourteen.recidivism / data.genders.female.returns.fourteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.fourteen.recidivism.toFixed(0)} / {data.genders.female.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.fifteenPlus.total == 0 ? '0' : Math.round(data.genders.male.returns.fifteenPlus.recidivism / data.genders.male.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(data.genders.male.returns.fifteenPlus.recidivism / data.genders.male.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.male.returns.fifteenPlus.recidivism.toFixed(0)} / {data.genders.male.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.fifteenPlus.total == 0 ? '0' : Math.round(data.genders.female.returns.fifteenPlus.recidivism / data.genders.female.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(data.genders.female.returns.fifteenPlus.recidivism / data.genders.female.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.genders.female.returns.fifteenPlus.recidivism.toFixed(0)} / {data.genders.female.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
            <div style={{flexBasis:'95%'}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Age and Length of Stay</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.ageUnder22.stayLengths.lengthUnder3M.recidivism / data.ages.ageUnder22.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.stayLengths.lengthUnder3M.recidivism / data.ages.ageUnder22.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.ageUnder22.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.ageUnder22.stayLengths.length3To6M.recidivism / data.ages.ageUnder22.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.stayLengths.length3To6M.recidivism / data.ages.ageUnder22.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.ageUnder22.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.ageUnder22.stayLengths.length6To12M.recidivism / data.ages.ageUnder22.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.stayLengths.length6To12M.recidivism / data.ages.ageUnder22.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.ageUnder22.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.ageUnder22.stayLengths.length1To2Y.recidivism / data.ages.ageUnder22.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.stayLengths.length1To2Y.recidivism / data.ages.ageUnder22.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.ageUnder22.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.ageUnder22.stayLengths.length2To5Y.recidivism / data.ages.ageUnder22.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.stayLengths.length2To5Y.recidivism / data.ages.ageUnder22.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.ageUnder22.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.ageUnder22.stayLengths.length5To10Y.recidivism / data.ages.ageUnder22.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.stayLengths.length5To10Y.recidivism / data.ages.ageUnder22.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.stayLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.ageUnder22.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age22To30.stayLengths.lengthUnder3M.recidivism / data.ages.age22To30.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.stayLengths.lengthUnder3M.recidivism / data.ages.age22To30.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age22To30.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age22To30.stayLengths.length3To6M.recidivism / data.ages.age22To30.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.stayLengths.length3To6M.recidivism / data.ages.age22To30.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age22To30.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age22To30.stayLengths.length6To12M.recidivism / data.ages.age22To30.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.stayLengths.length6To12M.recidivism / data.ages.age22To30.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age22To30.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age22To30.stayLengths.length1To2Y.recidivism / data.ages.age22To30.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.stayLengths.length1To2Y.recidivism / data.ages.age22To30.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age22To30.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age22To30.stayLengths.length2To5Y.recidivism / data.ages.age22To30.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.stayLengths.length2To5Y.recidivism / data.ages.age22To30.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age22To30.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age22To30.stayLengths.length5To10Y.recidivism / data.ages.age22To30.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.stayLengths.length5To10Y.recidivism / data.ages.age22To30.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.stayLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age22To30.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age30To40.stayLengths.lengthUnder3M.recidivism / data.ages.age30To40.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.stayLengths.lengthUnder3M.recidivism / data.ages.age30To40.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age30To40.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age30To40.stayLengths.length3To6M.recidivism / data.ages.age30To40.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.stayLengths.length3To6M.recidivism / data.ages.age30To40.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age30To40.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age30To40.stayLengths.length6To12M.recidivism / data.ages.age30To40.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.stayLengths.length6To12M.recidivism / data.ages.age30To40.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age30To40.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age30To40.stayLengths.length1To2Y.recidivism / data.ages.age30To40.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.stayLengths.length1To2Y.recidivism / data.ages.age30To40.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age30To40.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age30To40.stayLengths.length2To5Y.recidivism / data.ages.age30To40.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.stayLengths.length2To5Y.recidivism / data.ages.age30To40.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age30To40.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age30To40.stayLengths.length5To10Y.recidivism / data.ages.age30To40.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.stayLengths.length5To10Y.recidivism / data.ages.age30To40.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.stayLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age30To40.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age40To50.stayLengths.lengthUnder3M.recidivism / data.ages.age40To50.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.stayLengths.lengthUnder3M.recidivism / data.ages.age40To50.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age40To50.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age40To50.stayLengths.length3To6M.recidivism / data.ages.age40To50.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.stayLengths.length3To6M.recidivism / data.ages.age40To50.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age40To50.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age40To50.stayLengths.length6To12M.recidivism / data.ages.age40To50.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.stayLengths.length6To12M.recidivism / data.ages.age40To50.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age40To50.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age40To50.stayLengths.length1To2Y.recidivism / data.ages.age40To50.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.stayLengths.length1To2Y.recidivism / data.ages.age40To50.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age40To50.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age40To50.stayLengths.length2To5Y.recidivism / data.ages.age40To50.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.stayLengths.length2To5Y.recidivism / data.ages.age40To50.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age40To50.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age40To50.stayLengths.length5To10Y.recidivism / data.ages.age40To50.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.stayLengths.length5To10Y.recidivism / data.ages.age40To50.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.stayLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age40To50.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age50To60.stayLengths.lengthUnder3M.recidivism / data.ages.age50To60.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.stayLengths.lengthUnder3M.recidivism / data.ages.age50To60.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age50To60.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age50To60.stayLengths.length3To6M.recidivism / data.ages.age50To60.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.stayLengths.length3To6M.recidivism / data.ages.age50To60.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age50To60.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age50To60.stayLengths.length6To12M.recidivism / data.ages.age50To60.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.stayLengths.length6To12M.recidivism / data.ages.age50To60.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age50To60.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age50To60.stayLengths.length1To2Y.recidivism / data.ages.age50To60.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.stayLengths.length1To2Y.recidivism / data.ages.age50To60.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age50To60.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age50To60.stayLengths.length2To5Y.recidivism / data.ages.age50To60.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.stayLengths.length2To5Y.recidivism / data.ages.age50To60.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age50To60.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age50To60.stayLengths.length5To10Y.recidivism / data.ages.age50To60.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.stayLengths.length5To10Y.recidivism / data.ages.age50To60.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.stayLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age50To60.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age60To70.stayLengths.lengthUnder3M.recidivism / data.ages.age60To70.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.stayLengths.lengthUnder3M.recidivism / data.ages.age60To70.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age60To70.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age60To70.stayLengths.length3To6M.recidivism / data.ages.age60To70.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.stayLengths.length3To6M.recidivism / data.ages.age60To70.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age60To70.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age60To70.stayLengths.length6To12M.recidivism / data.ages.age60To70.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.stayLengths.length6To12M.recidivism / data.ages.age60To70.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age60To70.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age60To70.stayLengths.length1To2Y.recidivism / data.ages.age60To70.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.stayLengths.length1To2Y.recidivism / data.ages.age60To70.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age60To70.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age60To70.stayLengths.length2To5Y.recidivism / data.ages.age60To70.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.stayLengths.length2To5Y.recidivism / data.ages.age60To70.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age60To70.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age60To70.stayLengths.length5To10Y.recidivism / data.ages.age60To70.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.stayLengths.length5To10Y.recidivism / data.ages.age60To70.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.stayLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age60To70.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age70To80.stayLengths.lengthUnder3M.recidivism / data.ages.age70To80.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.stayLengths.lengthUnder3M.recidivism / data.ages.age70To80.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age70To80.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age70To80.stayLengths.length3To6M.recidivism / data.ages.age70To80.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.stayLengths.length3To6M.recidivism / data.ages.age70To80.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age70To80.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age70To80.stayLengths.length6To12M.recidivism / data.ages.age70To80.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.stayLengths.length6To12M.recidivism / data.ages.age70To80.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age70To80.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age70To80.stayLengths.length1To2Y.recidivism / data.ages.age70To80.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.stayLengths.length1To2Y.recidivism / data.ages.age70To80.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age70To80.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age70To80.stayLengths.length2To5Y.recidivism / data.ages.age70To80.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.stayLengths.length2To5Y.recidivism / data.ages.age70To80.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age70To80.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age70To80.stayLengths.length5To10Y.recidivism / data.ages.age70To80.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.stayLengths.length5To10Y.recidivism / data.ages.age70To80.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.stayLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age70To80.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age80To90.stayLengths.lengthUnder3M.recidivism / data.ages.age80To90.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.stayLengths.lengthUnder3M.recidivism / data.ages.age80To90.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age80To90.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age80To90.stayLengths.length3To6M.recidivism / data.ages.age80To90.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.stayLengths.length3To6M.recidivism / data.ages.age80To90.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age80To90.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age80To90.stayLengths.length6To12M.recidivism / data.ages.age80To90.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.stayLengths.length6To12M.recidivism / data.ages.age80To90.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age80To90.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age80To90.stayLengths.length1To2Y.recidivism / data.ages.age80To90.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.stayLengths.length1To2Y.recidivism / data.ages.age80To90.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age80To90.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age80To90.stayLengths.length2To5Y.recidivism / data.ages.age80To90.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.stayLengths.length2To5Y.recidivism / data.ages.age80To90.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age80To90.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age80To90.stayLengths.length5To10Y.recidivism / data.ages.age80To90.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.stayLengths.length5To10Y.recidivism / data.ages.age80To90.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.stayLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age80To90.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
            <div style={{flexBasis:'95%'}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Age and Length Between Returns</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.ageUnder22.outLengths.lengthUnder3M.recidivism / data.ages.ageUnder22.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.outLengths.lengthUnder3M.recidivism / data.ages.ageUnder22.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.ageUnder22.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.ageUnder22.outLengths.length3To6M.recidivism / data.ages.ageUnder22.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.outLengths.length3To6M.recidivism / data.ages.ageUnder22.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.ageUnder22.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.ageUnder22.outLengths.length6To12M.recidivism / data.ages.ageUnder22.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.outLengths.length6To12M.recidivism / data.ages.ageUnder22.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.ageUnder22.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.ageUnder22.outLengths.length1To2Y.recidivism / data.ages.ageUnder22.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.outLengths.length1To2Y.recidivism / data.ages.ageUnder22.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.ageUnder22.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.ageUnder22.outLengths.length2To5Y.recidivism / data.ages.ageUnder22.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.outLengths.length2To5Y.recidivism / data.ages.ageUnder22.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.ageUnder22.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.ageUnder22.outLengths.length5To10Y.recidivism / data.ages.ageUnder22.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.outLengths.length5To10Y.recidivism / data.ages.ageUnder22.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.outLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.ageUnder22.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age22To30.outLengths.lengthUnder3M.recidivism / data.ages.age22To30.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.outLengths.lengthUnder3M.recidivism / data.ages.age22To30.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age22To30.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age22To30.outLengths.length3To6M.recidivism / data.ages.age22To30.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.outLengths.length3To6M.recidivism / data.ages.age22To30.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age22To30.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age22To30.outLengths.length6To12M.recidivism / data.ages.age22To30.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.outLengths.length6To12M.recidivism / data.ages.age22To30.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age22To30.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age22To30.outLengths.length1To2Y.recidivism / data.ages.age22To30.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.outLengths.length1To2Y.recidivism / data.ages.age22To30.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age22To30.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age22To30.outLengths.length2To5Y.recidivism / data.ages.age22To30.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.outLengths.length2To5Y.recidivism / data.ages.age22To30.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age22To30.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age22To30.outLengths.length5To10Y.recidivism / data.ages.age22To30.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.outLengths.length5To10Y.recidivism / data.ages.age22To30.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.outLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age22To30.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age30To40.outLengths.lengthUnder3M.recidivism / data.ages.age30To40.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.outLengths.lengthUnder3M.recidivism / data.ages.age30To40.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age30To40.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age30To40.outLengths.length3To6M.recidivism / data.ages.age30To40.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.outLengths.length3To6M.recidivism / data.ages.age30To40.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age30To40.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age30To40.outLengths.length6To12M.recidivism / data.ages.age30To40.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.outLengths.length6To12M.recidivism / data.ages.age30To40.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age30To40.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age30To40.outLengths.length1To2Y.recidivism / data.ages.age30To40.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.outLengths.length1To2Y.recidivism / data.ages.age30To40.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age30To40.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age30To40.outLengths.length2To5Y.recidivism / data.ages.age30To40.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.outLengths.length2To5Y.recidivism / data.ages.age30To40.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age30To40.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age30To40.outLengths.length5To10Y.recidivism / data.ages.age30To40.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.outLengths.length5To10Y.recidivism / data.ages.age30To40.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.outLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age30To40.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age40To50.outLengths.lengthUnder3M.recidivism / data.ages.age40To50.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.outLengths.lengthUnder3M.recidivism / data.ages.age40To50.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age40To50.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age40To50.outLengths.length3To6M.recidivism / data.ages.age40To50.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.outLengths.length3To6M.recidivism / data.ages.age40To50.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age40To50.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age40To50.outLengths.length6To12M.recidivism / data.ages.age40To50.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.outLengths.length6To12M.recidivism / data.ages.age40To50.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age40To50.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age40To50.outLengths.length1To2Y.recidivism / data.ages.age40To50.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.outLengths.length1To2Y.recidivism / data.ages.age40To50.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age40To50.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age40To50.outLengths.length2To5Y.recidivism / data.ages.age40To50.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.outLengths.length2To5Y.recidivism / data.ages.age40To50.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age40To50.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age40To50.outLengths.length5To10Y.recidivism / data.ages.age40To50.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.outLengths.length5To10Y.recidivism / data.ages.age40To50.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.outLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age40To50.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age50To60.outLengths.lengthUnder3M.recidivism / data.ages.age50To60.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.outLengths.lengthUnder3M.recidivism / data.ages.age50To60.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age50To60.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age50To60.outLengths.length3To6M.recidivism / data.ages.age50To60.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.outLengths.length3To6M.recidivism / data.ages.age50To60.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age50To60.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age50To60.outLengths.length6To12M.recidivism / data.ages.age50To60.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.outLengths.length6To12M.recidivism / data.ages.age50To60.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age50To60.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age50To60.outLengths.length1To2Y.recidivism / data.ages.age50To60.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.outLengths.length1To2Y.recidivism / data.ages.age50To60.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age50To60.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age50To60.outLengths.length2To5Y.recidivism / data.ages.age50To60.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.outLengths.length2To5Y.recidivism / data.ages.age50To60.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age50To60.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age50To60.outLengths.length5To10Y.recidivism / data.ages.age50To60.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.outLengths.length5To10Y.recidivism / data.ages.age50To60.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.outLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age50To60.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age60To70.outLengths.lengthUnder3M.recidivism / data.ages.age60To70.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.outLengths.lengthUnder3M.recidivism / data.ages.age60To70.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age60To70.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age60To70.outLengths.length3To6M.recidivism / data.ages.age60To70.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.outLengths.length3To6M.recidivism / data.ages.age60To70.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age60To70.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age60To70.outLengths.length6To12M.recidivism / data.ages.age60To70.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.outLengths.length6To12M.recidivism / data.ages.age60To70.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age60To70.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age60To70.outLengths.length1To2Y.recidivism / data.ages.age60To70.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.outLengths.length1To2Y.recidivism / data.ages.age60To70.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age60To70.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age60To70.outLengths.length2To5Y.recidivism / data.ages.age60To70.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.outLengths.length2To5Y.recidivism / data.ages.age60To70.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age60To70.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age60To70.outLengths.length5To10Y.recidivism / data.ages.age60To70.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.outLengths.length5To10Y.recidivism / data.ages.age60To70.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.outLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age60To70.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age70To80.outLengths.lengthUnder3M.recidivism / data.ages.age70To80.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.outLengths.lengthUnder3M.recidivism / data.ages.age70To80.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age70To80.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age70To80.outLengths.length3To6M.recidivism / data.ages.age70To80.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.outLengths.length3To6M.recidivism / data.ages.age70To80.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age70To80.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age70To80.outLengths.length6To12M.recidivism / data.ages.age70To80.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.outLengths.length6To12M.recidivism / data.ages.age70To80.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age70To80.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age70To80.outLengths.length1To2Y.recidivism / data.ages.age70To80.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.outLengths.length1To2Y.recidivism / data.ages.age70To80.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age70To80.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age70To80.outLengths.length2To5Y.recidivism / data.ages.age70To80.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.outLengths.length2To5Y.recidivism / data.ages.age70To80.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age70To80.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age70To80.outLengths.length5To10Y.recidivism / data.ages.age70To80.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.outLengths.length5To10Y.recidivism / data.ages.age70To80.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.outLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age70To80.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(data.ages.age80To90.outLengths.lengthUnder3M.recidivism / data.ages.age80To90.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.outLengths.lengthUnder3M.recidivism / data.ages.age80To90.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {data.ages.age80To90.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length3To6M.total == 0 ? '0' : Math.round(data.ages.age80To90.outLengths.length3To6M.recidivism / data.ages.age80To90.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.outLengths.length3To6M.recidivism / data.ages.age80To90.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length3To6M.recidivism.toFixed(0)} / {data.ages.age80To90.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length6To12M.total == 0 ? '0' : Math.round(data.ages.age80To90.outLengths.length6To12M.recidivism / data.ages.age80To90.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.outLengths.length6To12M.recidivism / data.ages.age80To90.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length6To12M.recidivism.toFixed(0)} / {data.ages.age80To90.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length1To2Y.total == 0 ? '0' : Math.round(data.ages.age80To90.outLengths.length1To2Y.recidivism / data.ages.age80To90.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.outLengths.length1To2Y.recidivism / data.ages.age80To90.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length1To2Y.recidivism.toFixed(0)} / {data.ages.age80To90.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length2To5Y.total == 0 ? '0' : Math.round(data.ages.age80To90.outLengths.length2To5Y.recidivism / data.ages.age80To90.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.outLengths.length2To5Y.recidivism / data.ages.age80To90.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length2To5Y.recidivism.toFixed(0)} / {data.ages.age80To90.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length5To10Y.total == 0 ? '0' : Math.round(data.ages.age80To90.outLengths.length5To10Y.recidivism / data.ages.age80To90.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.outLengths.length5To10Y.recidivism / data.ages.age80To90.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.outLengths.length5To10Y.recidivism.toFixed(0)} / {data.ages.age80To90.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
            <div style={{flexBasis:'95%'}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={30} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Age and Number of Returns</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.one.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.one.recidivism / data.ages.ageUnder22.returns.one.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.one.recidivism / data.ages.ageUnder22.returns.one.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.one.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.one.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.one.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.one.recidivism / data.ages.age22To30.returns.one.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.one.recidivism / data.ages.age22To30.returns.one.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.one.recidivism.toFixed(0)} / {data.ages.age22To30.returns.one.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.one.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.one.recidivism / data.ages.age30To40.returns.one.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.one.recidivism / data.ages.age30To40.returns.one.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.one.recidivism.toFixed(0)} / {data.ages.age30To40.returns.one.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.one.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.one.recidivism / data.ages.age40To50.returns.one.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.one.recidivism / data.ages.age40To50.returns.one.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.one.recidivism.toFixed(0)} / {data.ages.age40To50.returns.one.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.one.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.one.recidivism / data.ages.age50To60.returns.one.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.one.recidivism / data.ages.age50To60.returns.one.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.one.recidivism.toFixed(0)} / {data.ages.age50To60.returns.one.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.one.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.one.recidivism / data.ages.age60To70.returns.one.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.one.recidivism / data.ages.age60To70.returns.one.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.one.recidivism.toFixed(0)} / {data.ages.age60To70.returns.one.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.one.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.one.recidivism / data.ages.age70To80.returns.one.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.one.recidivism / data.ages.age70To80.returns.one.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.one.recidivism.toFixed(0)} / {data.ages.age70To80.returns.one.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.one.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.one.recidivism / data.ages.age80To90.returns.one.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.one.recidivism / data.ages.age80To90.returns.one.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.one.recidivism.toFixed(0)} / {data.ages.age80To90.returns.one.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.two.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.two.recidivism / data.ages.ageUnder22.returns.two.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.two.recidivism / data.ages.ageUnder22.returns.two.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.two.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.two.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.two.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.two.recidivism / data.ages.age22To30.returns.two.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.two.recidivism / data.ages.age22To30.returns.two.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.two.recidivism.toFixed(0)} / {data.ages.age22To30.returns.two.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.two.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.two.recidivism / data.ages.age30To40.returns.two.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.two.recidivism / data.ages.age30To40.returns.two.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.two.recidivism.toFixed(0)} / {data.ages.age30To40.returns.two.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.two.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.two.recidivism / data.ages.age40To50.returns.two.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.two.recidivism / data.ages.age40To50.returns.two.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.two.recidivism.toFixed(0)} / {data.ages.age40To50.returns.two.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.two.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.two.recidivism / data.ages.age50To60.returns.two.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.two.recidivism / data.ages.age50To60.returns.two.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.two.recidivism.toFixed(0)} / {data.ages.age50To60.returns.two.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.two.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.two.recidivism / data.ages.age60To70.returns.two.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.two.recidivism / data.ages.age60To70.returns.two.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.two.recidivism.toFixed(0)} / {data.ages.age60To70.returns.two.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.two.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.two.recidivism / data.ages.age70To80.returns.two.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.two.recidivism / data.ages.age70To80.returns.two.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.two.recidivism.toFixed(0)} / {data.ages.age70To80.returns.two.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.two.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.two.recidivism / data.ages.age80To90.returns.two.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.two.recidivism / data.ages.age80To90.returns.two.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.two.recidivism.toFixed(0)} / {data.ages.age80To90.returns.two.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.three.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.three.recidivism / data.ages.ageUnder22.returns.three.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.three.recidivism / data.ages.ageUnder22.returns.three.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.three.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.three.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.three.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.three.recidivism / data.ages.age22To30.returns.three.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.three.recidivism / data.ages.age22To30.returns.three.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.three.recidivism.toFixed(0)} / {data.ages.age22To30.returns.three.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.three.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.three.recidivism / data.ages.age30To40.returns.three.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.three.recidivism / data.ages.age30To40.returns.three.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.three.recidivism.toFixed(0)} / {data.ages.age30To40.returns.three.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.three.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.three.recidivism / data.ages.age40To50.returns.three.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.three.recidivism / data.ages.age40To50.returns.three.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.three.recidivism.toFixed(0)} / {data.ages.age40To50.returns.three.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.three.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.three.recidivism / data.ages.age50To60.returns.three.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.three.recidivism / data.ages.age50To60.returns.three.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.three.recidivism.toFixed(0)} / {data.ages.age50To60.returns.three.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.three.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.three.recidivism / data.ages.age60To70.returns.three.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.three.recidivism / data.ages.age60To70.returns.three.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.three.recidivism.toFixed(0)} / {data.ages.age60To70.returns.three.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.three.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.three.recidivism / data.ages.age70To80.returns.three.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.three.recidivism / data.ages.age70To80.returns.three.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.three.recidivism.toFixed(0)} / {data.ages.age70To80.returns.three.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.three.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.three.recidivism / data.ages.age80To90.returns.three.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.three.recidivism / data.ages.age80To90.returns.three.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.three.recidivism.toFixed(0)} / {data.ages.age80To90.returns.three.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.four.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.four.recidivism / data.ages.ageUnder22.returns.four.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.four.recidivism / data.ages.ageUnder22.returns.four.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.four.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.four.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.four.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.four.recidivism / data.ages.age22To30.returns.four.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.four.recidivism / data.ages.age22To30.returns.four.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.four.recidivism.toFixed(0)} / {data.ages.age22To30.returns.four.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.four.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.four.recidivism / data.ages.age30To40.returns.four.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.four.recidivism / data.ages.age30To40.returns.four.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.four.recidivism.toFixed(0)} / {data.ages.age30To40.returns.four.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.four.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.four.recidivism / data.ages.age40To50.returns.four.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.four.recidivism / data.ages.age40To50.returns.four.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.four.recidivism.toFixed(0)} / {data.ages.age40To50.returns.four.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.four.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.four.recidivism / data.ages.age50To60.returns.four.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.four.recidivism / data.ages.age50To60.returns.four.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.four.recidivism.toFixed(0)} / {data.ages.age50To60.returns.four.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.four.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.four.recidivism / data.ages.age60To70.returns.four.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.four.recidivism / data.ages.age60To70.returns.four.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.four.recidivism.toFixed(0)} / {data.ages.age60To70.returns.four.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.four.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.four.recidivism / data.ages.age70To80.returns.four.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.four.recidivism / data.ages.age70To80.returns.four.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.four.recidivism.toFixed(0)} / {data.ages.age70To80.returns.four.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.four.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.four.recidivism / data.ages.age80To90.returns.four.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.four.recidivism / data.ages.age80To90.returns.four.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.four.recidivism.toFixed(0)} / {data.ages.age80To90.returns.four.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.five.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.five.recidivism / data.ages.ageUnder22.returns.five.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.five.recidivism / data.ages.ageUnder22.returns.five.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.five.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.five.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.five.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.five.recidivism / data.ages.age22To30.returns.five.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.five.recidivism / data.ages.age22To30.returns.five.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.five.recidivism.toFixed(0)} / {data.ages.age22To30.returns.five.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.five.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.five.recidivism / data.ages.age30To40.returns.five.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.five.recidivism / data.ages.age30To40.returns.five.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.five.recidivism.toFixed(0)} / {data.ages.age30To40.returns.five.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.five.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.five.recidivism / data.ages.age40To50.returns.five.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.five.recidivism / data.ages.age40To50.returns.five.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.five.recidivism.toFixed(0)} / {data.ages.age40To50.returns.five.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.five.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.five.recidivism / data.ages.age50To60.returns.five.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.five.recidivism / data.ages.age50To60.returns.five.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.five.recidivism.toFixed(0)} / {data.ages.age50To60.returns.five.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.five.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.five.recidivism / data.ages.age60To70.returns.five.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.five.recidivism / data.ages.age60To70.returns.five.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.five.recidivism.toFixed(0)} / {data.ages.age60To70.returns.five.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.five.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.five.recidivism / data.ages.age70To80.returns.five.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.five.recidivism / data.ages.age70To80.returns.five.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.five.recidivism.toFixed(0)} / {data.ages.age70To80.returns.five.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.five.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.five.recidivism / data.ages.age80To90.returns.five.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.five.recidivism / data.ages.age80To90.returns.five.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.five.recidivism.toFixed(0)} / {data.ages.age80To90.returns.five.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.six.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.six.recidivism / data.ages.ageUnder22.returns.six.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.six.recidivism / data.ages.ageUnder22.returns.six.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.six.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.six.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.six.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.six.recidivism / data.ages.age22To30.returns.six.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.six.recidivism / data.ages.age22To30.returns.six.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.six.recidivism.toFixed(0)} / {data.ages.age22To30.returns.six.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.six.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.six.recidivism / data.ages.age30To40.returns.six.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.six.recidivism / data.ages.age30To40.returns.six.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.six.recidivism.toFixed(0)} / {data.ages.age30To40.returns.six.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.six.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.six.recidivism / data.ages.age40To50.returns.six.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.six.recidivism / data.ages.age40To50.returns.six.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.six.recidivism.toFixed(0)} / {data.ages.age40To50.returns.six.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.six.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.six.recidivism / data.ages.age50To60.returns.six.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.six.recidivism / data.ages.age50To60.returns.six.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.six.recidivism.toFixed(0)} / {data.ages.age50To60.returns.six.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.six.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.six.recidivism / data.ages.age60To70.returns.six.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.six.recidivism / data.ages.age60To70.returns.six.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.six.recidivism.toFixed(0)} / {data.ages.age60To70.returns.six.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.six.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.six.recidivism / data.ages.age70To80.returns.six.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.six.recidivism / data.ages.age70To80.returns.six.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.six.recidivism.toFixed(0)} / {data.ages.age70To80.returns.six.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.six.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.six.recidivism / data.ages.age80To90.returns.six.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.six.recidivism / data.ages.age80To90.returns.six.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.six.recidivism.toFixed(0)} / {data.ages.age80To90.returns.six.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.seven.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.seven.recidivism / data.ages.ageUnder22.returns.seven.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.seven.recidivism / data.ages.ageUnder22.returns.seven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.seven.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.seven.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.seven.recidivism / data.ages.age22To30.returns.seven.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.seven.recidivism / data.ages.age22To30.returns.seven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.seven.recidivism.toFixed(0)} / {data.ages.age22To30.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.seven.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.seven.recidivism / data.ages.age30To40.returns.seven.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.seven.recidivism / data.ages.age30To40.returns.seven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.seven.recidivism.toFixed(0)} / {data.ages.age30To40.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.seven.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.seven.recidivism / data.ages.age40To50.returns.seven.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.seven.recidivism / data.ages.age40To50.returns.seven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.seven.recidivism.toFixed(0)} / {data.ages.age40To50.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.seven.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.seven.recidivism / data.ages.age50To60.returns.seven.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.seven.recidivism / data.ages.age50To60.returns.seven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.seven.recidivism.toFixed(0)} / {data.ages.age50To60.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.seven.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.seven.recidivism / data.ages.age60To70.returns.seven.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.seven.recidivism / data.ages.age60To70.returns.seven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.seven.recidivism.toFixed(0)} / {data.ages.age60To70.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.seven.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.seven.recidivism / data.ages.age70To80.returns.seven.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.seven.recidivism / data.ages.age70To80.returns.seven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.seven.recidivism.toFixed(0)} / {data.ages.age70To80.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.seven.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.seven.recidivism / data.ages.age80To90.returns.seven.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.seven.recidivism / data.ages.age80To90.returns.seven.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.seven.recidivism.toFixed(0)} / {data.ages.age80To90.returns.seven.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.eight.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.eight.recidivism / data.ages.ageUnder22.returns.eight.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.eight.recidivism / data.ages.ageUnder22.returns.eight.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.eight.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.eight.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.eight.recidivism / data.ages.age22To30.returns.eight.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.eight.recidivism / data.ages.age22To30.returns.eight.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.eight.recidivism.toFixed(0)} / {data.ages.age22To30.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.eight.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.eight.recidivism / data.ages.age30To40.returns.eight.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.eight.recidivism / data.ages.age30To40.returns.eight.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.eight.recidivism.toFixed(0)} / {data.ages.age30To40.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.eight.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.eight.recidivism / data.ages.age40To50.returns.eight.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.eight.recidivism / data.ages.age40To50.returns.eight.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.eight.recidivism.toFixed(0)} / {data.ages.age40To50.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.eight.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.eight.recidivism / data.ages.age50To60.returns.eight.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.eight.recidivism / data.ages.age50To60.returns.eight.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.eight.recidivism.toFixed(0)} / {data.ages.age50To60.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.eight.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.eight.recidivism / data.ages.age60To70.returns.eight.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.eight.recidivism / data.ages.age60To70.returns.eight.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.eight.recidivism.toFixed(0)} / {data.ages.age60To70.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.eight.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.eight.recidivism / data.ages.age70To80.returns.eight.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.eight.recidivism / data.ages.age70To80.returns.eight.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.eight.recidivism.toFixed(0)} / {data.ages.age70To80.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.eight.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.eight.recidivism / data.ages.age80To90.returns.eight.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.eight.recidivism / data.ages.age80To90.returns.eight.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.eight.recidivism.toFixed(0)} / {data.ages.age80To90.returns.eight.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.nine.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.nine.recidivism / data.ages.ageUnder22.returns.nine.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.nine.recidivism / data.ages.ageUnder22.returns.nine.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.nine.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.nine.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.nine.recidivism / data.ages.age22To30.returns.nine.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.nine.recidivism / data.ages.age22To30.returns.nine.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.nine.recidivism.toFixed(0)} / {data.ages.age22To30.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.nine.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.nine.recidivism / data.ages.age30To40.returns.nine.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.nine.recidivism / data.ages.age30To40.returns.nine.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.nine.recidivism.toFixed(0)} / {data.ages.age30To40.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.nine.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.nine.recidivism / data.ages.age40To50.returns.nine.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.nine.recidivism / data.ages.age40To50.returns.nine.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.nine.recidivism.toFixed(0)} / {data.ages.age40To50.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.nine.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.nine.recidivism / data.ages.age50To60.returns.nine.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.nine.recidivism / data.ages.age50To60.returns.nine.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.nine.recidivism.toFixed(0)} / {data.ages.age50To60.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.nine.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.nine.recidivism / data.ages.age60To70.returns.nine.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.nine.recidivism / data.ages.age60To70.returns.nine.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.nine.recidivism.toFixed(0)} / {data.ages.age60To70.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.nine.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.nine.recidivism / data.ages.age70To80.returns.nine.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.nine.recidivism / data.ages.age70To80.returns.nine.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.nine.recidivism.toFixed(0)} / {data.ages.age70To80.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.nine.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.nine.recidivism / data.ages.age80To90.returns.nine.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.nine.recidivism / data.ages.age80To90.returns.nine.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.nine.recidivism.toFixed(0)} / {data.ages.age80To90.returns.nine.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.ten.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.ten.recidivism / data.ages.ageUnder22.returns.ten.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.ten.recidivism / data.ages.ageUnder22.returns.ten.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.ten.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.ten.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.ten.recidivism / data.ages.age22To30.returns.ten.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.ten.recidivism / data.ages.age22To30.returns.ten.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.ten.recidivism.toFixed(0)} / {data.ages.age22To30.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.ten.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.ten.recidivism / data.ages.age30To40.returns.ten.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.ten.recidivism / data.ages.age30To40.returns.ten.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.ten.recidivism.toFixed(0)} / {data.ages.age30To40.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.ten.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.ten.recidivism / data.ages.age40To50.returns.ten.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.ten.recidivism / data.ages.age40To50.returns.ten.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.ten.recidivism.toFixed(0)} / {data.ages.age40To50.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.ten.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.ten.recidivism / data.ages.age50To60.returns.ten.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.ten.recidivism / data.ages.age50To60.returns.ten.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.ten.recidivism.toFixed(0)} / {data.ages.age50To60.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.ten.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.ten.recidivism / data.ages.age60To70.returns.ten.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.ten.recidivism / data.ages.age60To70.returns.ten.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.ten.recidivism.toFixed(0)} / {data.ages.age60To70.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.ten.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.ten.recidivism / data.ages.age70To80.returns.ten.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.ten.recidivism / data.ages.age70To80.returns.ten.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.ten.recidivism.toFixed(0)} / {data.ages.age70To80.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.ten.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.ten.recidivism / data.ages.age80To90.returns.ten.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.ten.recidivism / data.ages.age80To90.returns.ten.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.ten.recidivism.toFixed(0)} / {data.ages.age80To90.returns.ten.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.eleven.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.eleven.recidivism / data.ages.ageUnder22.returns.eleven.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.eleven.recidivism / data.ages.ageUnder22.returns.eleven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.eleven.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.eleven.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.eleven.recidivism / data.ages.age22To30.returns.eleven.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.eleven.recidivism / data.ages.age22To30.returns.eleven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.eleven.recidivism.toFixed(0)} / {data.ages.age22To30.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.eleven.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.eleven.recidivism / data.ages.age30To40.returns.eleven.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.eleven.recidivism / data.ages.age30To40.returns.eleven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.eleven.recidivism.toFixed(0)} / {data.ages.age30To40.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.eleven.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.eleven.recidivism / data.ages.age40To50.returns.eleven.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.eleven.recidivism / data.ages.age40To50.returns.eleven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.eleven.recidivism.toFixed(0)} / {data.ages.age40To50.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.eleven.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.eleven.recidivism / data.ages.age50To60.returns.eleven.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.eleven.recidivism / data.ages.age50To60.returns.eleven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.eleven.recidivism.toFixed(0)} / {data.ages.age50To60.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.eleven.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.eleven.recidivism / data.ages.age60To70.returns.eleven.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.eleven.recidivism / data.ages.age60To70.returns.eleven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.eleven.recidivism.toFixed(0)} / {data.ages.age60To70.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.eleven.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.eleven.recidivism / data.ages.age70To80.returns.eleven.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.eleven.recidivism / data.ages.age70To80.returns.eleven.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.eleven.recidivism.toFixed(0)} / {data.ages.age70To80.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.eleven.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.eleven.recidivism / data.ages.age80To90.returns.eleven.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.eleven.recidivism / data.ages.age80To90.returns.eleven.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.eleven.recidivism.toFixed(0)} / {data.ages.age80To90.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.twelve.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.twelve.recidivism / data.ages.ageUnder22.returns.twelve.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.twelve.recidivism / data.ages.ageUnder22.returns.twelve.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.twelve.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.twelve.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.twelve.recidivism / data.ages.age22To30.returns.twelve.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.twelve.recidivism / data.ages.age22To30.returns.twelve.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.twelve.recidivism.toFixed(0)} / {data.ages.age22To30.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.twelve.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.twelve.recidivism / data.ages.age30To40.returns.twelve.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.twelve.recidivism / data.ages.age30To40.returns.twelve.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.twelve.recidivism.toFixed(0)} / {data.ages.age30To40.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.twelve.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.twelve.recidivism / data.ages.age40To50.returns.twelve.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.twelve.recidivism / data.ages.age40To50.returns.twelve.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.twelve.recidivism.toFixed(0)} / {data.ages.age40To50.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.twelve.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.twelve.recidivism / data.ages.age50To60.returns.twelve.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.twelve.recidivism / data.ages.age50To60.returns.twelve.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.twelve.recidivism.toFixed(0)} / {data.ages.age50To60.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.twelve.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.twelve.recidivism / data.ages.age60To70.returns.twelve.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.twelve.recidivism / data.ages.age60To70.returns.twelve.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.twelve.recidivism.toFixed(0)} / {data.ages.age60To70.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.twelve.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.twelve.recidivism / data.ages.age70To80.returns.twelve.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.twelve.recidivism / data.ages.age70To80.returns.twelve.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.twelve.recidivism.toFixed(0)} / {data.ages.age70To80.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.twelve.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.twelve.recidivism / data.ages.age80To90.returns.twelve.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.twelve.recidivism / data.ages.age80To90.returns.twelve.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.twelve.recidivism.toFixed(0)} / {data.ages.age80To90.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.thirteen.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.thirteen.recidivism / data.ages.ageUnder22.returns.thirteen.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.thirteen.recidivism / data.ages.ageUnder22.returns.thirteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.thirteen.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.thirteen.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.thirteen.recidivism / data.ages.age22To30.returns.thirteen.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.thirteen.recidivism / data.ages.age22To30.returns.thirteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.thirteen.recidivism.toFixed(0)} / {data.ages.age22To30.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.thirteen.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.thirteen.recidivism / data.ages.age30To40.returns.thirteen.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.thirteen.recidivism / data.ages.age30To40.returns.thirteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.thirteen.recidivism.toFixed(0)} / {data.ages.age30To40.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.thirteen.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.thirteen.recidivism / data.ages.age40To50.returns.thirteen.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.thirteen.recidivism / data.ages.age40To50.returns.thirteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.thirteen.recidivism.toFixed(0)} / {data.ages.age40To50.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.thirteen.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.thirteen.recidivism / data.ages.age50To60.returns.thirteen.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.thirteen.recidivism / data.ages.age50To60.returns.thirteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.thirteen.recidivism.toFixed(0)} / {data.ages.age50To60.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.thirteen.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.thirteen.recidivism / data.ages.age60To70.returns.thirteen.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.thirteen.recidivism / data.ages.age60To70.returns.thirteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.thirteen.recidivism.toFixed(0)} / {data.ages.age60To70.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.thirteen.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.thirteen.recidivism / data.ages.age70To80.returns.thirteen.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.thirteen.recidivism / data.ages.age70To80.returns.thirteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.thirteen.recidivism.toFixed(0)} / {data.ages.age70To80.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.thirteen.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.thirteen.recidivism / data.ages.age80To90.returns.thirteen.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.thirteen.recidivism / data.ages.age80To90.returns.thirteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.thirteen.recidivism.toFixed(0)} / {data.ages.age80To90.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.fourteen.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.fourteen.recidivism / data.ages.ageUnder22.returns.fourteen.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.fourteen.recidivism / data.ages.ageUnder22.returns.fourteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.fourteen.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.fourteen.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.fourteen.recidivism / data.ages.age22To30.returns.fourteen.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.fourteen.recidivism / data.ages.age22To30.returns.fourteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.fourteen.recidivism.toFixed(0)} / {data.ages.age22To30.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.fourteen.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.fourteen.recidivism / data.ages.age30To40.returns.fourteen.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.fourteen.recidivism / data.ages.age30To40.returns.fourteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.fourteen.recidivism.toFixed(0)} / {data.ages.age30To40.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.fourteen.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.fourteen.recidivism / data.ages.age40To50.returns.fourteen.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.fourteen.recidivism / data.ages.age40To50.returns.fourteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.fourteen.recidivism.toFixed(0)} / {data.ages.age40To50.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.fourteen.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.fourteen.recidivism / data.ages.age50To60.returns.fourteen.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.fourteen.recidivism / data.ages.age50To60.returns.fourteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.fourteen.recidivism.toFixed(0)} / {data.ages.age50To60.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.fourteen.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.fourteen.recidivism / data.ages.age60To70.returns.fourteen.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.fourteen.recidivism / data.ages.age60To70.returns.fourteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.fourteen.recidivism.toFixed(0)} / {data.ages.age60To70.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.fourteen.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.fourteen.recidivism / data.ages.age70To80.returns.fourteen.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.fourteen.recidivism / data.ages.age70To80.returns.fourteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.fourteen.recidivism.toFixed(0)} / {data.ages.age70To80.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.fourteen.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.fourteen.recidivism / data.ages.age80To90.returns.fourteen.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.fourteen.recidivism / data.ages.age80To90.returns.fourteen.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.fourteen.recidivism.toFixed(0)} / {data.ages.age80To90.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.fifteenPlus.total == 0 ? '0' : Math.round(data.ages.ageUnder22.returns.fifteenPlus.recidivism / data.ages.ageUnder22.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(data.ages.ageUnder22.returns.fifteenPlus.recidivism / data.ages.ageUnder22.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.ageUnder22.returns.fifteenPlus.recidivism.toFixed(0)} / {data.ages.ageUnder22.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.fifteenPlus.total == 0 ? '0' : Math.round(data.ages.age22To30.returns.fifteenPlus.recidivism / data.ages.age22To30.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(data.ages.age22To30.returns.fifteenPlus.recidivism / data.ages.age22To30.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age22To30.returns.fifteenPlus.recidivism.toFixed(0)} / {data.ages.age22To30.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.fifteenPlus.total == 0 ? '0' : Math.round(data.ages.age30To40.returns.fifteenPlus.recidivism / data.ages.age30To40.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(data.ages.age30To40.returns.fifteenPlus.recidivism / data.ages.age30To40.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age30To40.returns.fifteenPlus.recidivism.toFixed(0)} / {data.ages.age30To40.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.fifteenPlus.total == 0 ? '0' : Math.round(data.ages.age40To50.returns.fifteenPlus.recidivism / data.ages.age40To50.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(data.ages.age40To50.returns.fifteenPlus.recidivism / data.ages.age40To50.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age40To50.returns.fifteenPlus.recidivism.toFixed(0)} / {data.ages.age40To50.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.fifteenPlus.total == 0 ? '0' : Math.round(data.ages.age50To60.returns.fifteenPlus.recidivism / data.ages.age50To60.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(data.ages.age50To60.returns.fifteenPlus.recidivism / data.ages.age50To60.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age50To60.returns.fifteenPlus.recidivism.toFixed(0)} / {data.ages.age50To60.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.fifteenPlus.total == 0 ? '0' : Math.round(data.ages.age60To70.returns.fifteenPlus.recidivism / data.ages.age60To70.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(data.ages.age60To70.returns.fifteenPlus.recidivism / data.ages.age60To70.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age60To70.returns.fifteenPlus.recidivism.toFixed(0)} / {data.ages.age60To70.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.fifteenPlus.total == 0 ? '0' : Math.round(data.ages.age70To80.returns.fifteenPlus.recidivism / data.ages.age70To80.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(data.ages.age70To80.returns.fifteenPlus.recidivism / data.ages.age70To80.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age70To80.returns.fifteenPlus.recidivism.toFixed(0)} / {data.ages.age70To80.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.fifteenPlus.total == 0 ? '0' : Math.round(data.ages.age80To90.returns.fifteenPlus.recidivism / data.ages.age80To90.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(data.ages.age80To90.returns.fifteenPlus.recidivism / data.ages.age80To90.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.ages.age80To90.returns.fifteenPlus.recidivism.toFixed(0)} / {data.ages.age80To90.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
            <div style={{flexBasis:'40%'}}>
              <Table compact celled striped unstackable style={{margin:'2em 0',height:'0%'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell>Facility</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2}>Recidivism</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(data.facilities, (facility:any) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                    <Table.Cell><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.total == 0 ? '0' : Math.round(facility.recidivism / facility.total * 100) > 100 ? '100' : Math.round(facility.recidivism / facility.total * 100)}%</div></Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.recidivism.toFixed(0)} / {facility.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>)}
                </Table.Body>
              </Table>
            </div>
            <div style={{flexBasis:'40%'}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Facility and Gender</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(data.facilities, (facility:any, i:number) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genders.male.total == 0 ? '0' : Math.round(facility.genders.male.recidivism / facility.genders.male.total * 100) > 100 ? '100' : Math.round(facility.genders.male.recidivism / facility.genders.male.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genders.male.recidivism.toFixed(0)} / {facility.genders.male.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genders.female.total == 0 ? '0' : Math.round(facility.genders.female.recidivism / facility.genders.female.total * 100) > 100 ? '100' : Math.round(facility.genders.female.recidivism / facility.genders.female.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genders.female.recidivism.toFixed(0)} / {facility.genders.female.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>)}
                </Table.Body>
              </Table>
            </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
            <div style={{flexBasis:'95%'}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={16} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Facility and Age</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(data.facilities, (facility:any, i:number) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.ages.ageUnder22.recidivism / facility.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.ages.ageUnder22.recidivism / facility.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.ageUnder22.recidivism.toFixed(0)} / {facility.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age22To30.total == 0 ? '0' : Math.round(facility.ages.age22To30.recidivism / facility.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.ages.age22To30.recidivism / facility.ages.age22To30.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age22To30.recidivism.toFixed(0)} / {facility.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age30To40.total == 0 ? '0' : Math.round(facility.ages.age30To40.recidivism / facility.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.ages.age30To40.recidivism / facility.ages.age30To40.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age30To40.recidivism.toFixed(0)} / {facility.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age40To50.total == 0 ? '0' : Math.round(facility.ages.age40To50.recidivism / facility.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.ages.age40To50.recidivism / facility.ages.age40To50.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age40To50.recidivism.toFixed(0)} / {facility.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age50To60.total == 0 ? '0' : Math.round(facility.ages.age50To60.recidivism / facility.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.ages.age50To60.recidivism / facility.ages.age50To60.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age50To60.recidivism.toFixed(0)} / {facility.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age60To70.total == 0 ? '0' : Math.round(facility.ages.age60To70.recidivism / facility.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.ages.age60To70.recidivism / facility.ages.age60To70.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age60To70.recidivism.toFixed(0)} / {facility.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age70To80.total == 0 ? '0' : Math.round(facility.ages.age70To80.recidivism / facility.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.ages.age70To80.recidivism / facility.ages.age70To80.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age70To80.recidivism.toFixed(0)} / {facility.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age80To90.total == 0 ? '0' : Math.round(facility.ages.age80To90.recidivism / facility.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.ages.age80To90.recidivism / facility.ages.age80To90.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.ages.age80To90.recidivism.toFixed(0)} / {facility.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>)}
                </Table.Body>
              </Table>
            </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
            <div style={{flexBasis:'95%'}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Facility and Length of Stay</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(data.facilities, (facility:any, i:number) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.stayLengths.lengthUnder3M.recidivism / facility.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.stayLengths.lengthUnder3M.recidivism / facility.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.stayLengths.length3To6M.recidivism / facility.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.stayLengths.length3To6M.recidivism / facility.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.stayLengths.length6To12M.recidivism / facility.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.stayLengths.length6To12M.recidivism / facility.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.stayLengths.length1To2Y.recidivism / facility.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.stayLengths.length1To2Y.recidivism / facility.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.stayLengths.length2To5Y.recidivism / facility.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.stayLengths.length2To5Y.recidivism / facility.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.stayLengths.length5To10Y.recidivism / facility.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.stayLengths.length5To10Y.recidivism / facility.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>)}
                </Table.Body>
              </Table>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Facility and Length Between Returns</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                    <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {_.map(data.facilities, (facility:any, i:number) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.outLengths.lengthUnder3M.recidivism / facility.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.outLengths.lengthUnder3M.recidivism / facility.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.outLengths.length3To6M.recidivism / facility.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.outLengths.length3To6M.recidivism / facility.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.outLengths.length6To12M.recidivism / facility.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.outLengths.length6To12M.recidivism / facility.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.outLengths.length1To2Y.recidivism / facility.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.outLengths.length1To2Y.recidivism / facility.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.outLengths.length2To5Y.recidivism / facility.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.outLengths.length2To5Y.recidivism / facility.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    <Table.Cell style={{borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.outLengths.length5To10Y.recidivism / facility.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.outLengths.length5To10Y.recidivism / facility.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                    <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                  </Table.Row>)}
                </Table.Body>
              </Table>
            </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
            <div style={{flexBasis:'95%'}}>
              <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                    <Table.HeaderCell colSpan={30} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Recidivism by Facility and Number of Returns</Table.HeaderCell>
                  </Table.Row>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                    {_.map(data.facilities, (facility:any, i:number) => <Table.HeaderCell key={facility.abbreviation} colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}>{facility.abbreviation}</Table.HeaderCell>)}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.one.total == 0 ? '0' : Math.round(facility.returns.one.recidivism / facility.returns.one.total * 100) > 100 ? '100' : Math.round(facility.returns.one.recidivism / facility.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.one.recidivism.toFixed(0)} / {facility.returns.one.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.two.total == 0 ? '0' : Math.round(facility.returns.two.recidivism / facility.returns.two.total * 100) > 100 ? '100' : Math.round(facility.returns.two.recidivism / facility.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.two.recidivism.toFixed(0)} / {facility.returns.two.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.three.total == 0 ? '0' : Math.round(facility.returns.three.recidivism / facility.returns.three.total * 100) > 100 ? '100' : Math.round(facility.returns.three.recidivism / facility.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.three.recidivism.toFixed(0)} / {facility.returns.three.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.four.total == 0 ? '0' : Math.round(facility.returns.four.recidivism / facility.returns.four.total * 100) > 100 ? '100' : Math.round(facility.returns.four.recidivism / facility.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.four.recidivism.toFixed(0)} / {facility.returns.four.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.five.total == 0 ? '0' : Math.round(facility.returns.five.recidivism / facility.returns.five.total * 100) > 100 ? '100' : Math.round(facility.returns.five.recidivism / facility.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.five.recidivism.toFixed(0)} / {facility.returns.five.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.six.total == 0 ? '0' : Math.round(facility.returns.six.recidivism / facility.returns.six.total * 100) > 100 ? '100' : Math.round(facility.returns.six.recidivism / facility.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.six.recidivism.toFixed(0)} / {facility.returns.six.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.seven.total == 0 ? '0' : Math.round(facility.returns.seven.recidivism / facility.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.returns.seven.recidivism / facility.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.seven.recidivism.toFixed(0)} / {facility.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.eight.total == 0 ? '0' : Math.round(facility.returns.eight.recidivism / facility.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.returns.eight.recidivism / facility.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.eight.recidivism.toFixed(0)} / {facility.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.nine.total == 0 ? '0' : Math.round(facility.returns.nine.recidivism / facility.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.returns.nine.recidivism / facility.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.nine.recidivism.toFixed(0)} / {facility.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.ten.total == 0 ? '0' : Math.round(facility.returns.ten.recidivism / facility.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.returns.ten.recidivism / facility.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.ten.recidivism.toFixed(0)} / {facility.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.eleven.total == 0 ? '0' : Math.round(facility.returns.eleven.recidivism / facility.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.returns.eleven.recidivism / facility.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.eleven.recidivism.toFixed(0)} / {facility.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.twelve.total == 0 ? '0' : Math.round(facility.returns.twelve.recidivism / facility.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.returns.twelve.recidivism / facility.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.twelve.recidivism.toFixed(0)} / {facility.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.thirteen.total == 0 ? '0' : Math.round(facility.returns.thirteen.recidivism / facility.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.returns.thirteen.recidivism / facility.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.thirteen.recidivism.toFixed(0)} / {facility.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.fourteen.total == 0 ? '0' : Math.round(facility.returns.fourteen.recidivism / facility.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.returns.fourteen.recidivism / facility.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.fourteen.recidivism.toFixed(0)} / {facility.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                  <Table.Row style={{backgroundColor: 'white'}}>
                    <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                    {_.map(data.facilities, (facility:any, i:number) => <Fragment key={facility.abbreviation}>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.returns.fifteenPlus.recidivism / facility.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.returns.fifteenPlus.recidivism / facility.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)',borderRight:(i == data.facilities.length-1 ? '1px solid rgba(34,36,38,.1)' : '0')}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.returns.one.total.toFixed(0)}</div></Table.Cell>
                    </Fragment>)}
                  </Table.Row>
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      )
    }
}

export default RecidivismGlobal