import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Grid,
  Form,
  Header,
  Select,
  Radio,
  Table,
  TextArea,
  Input,
  Button,
  Modal,
  Divider,
  Checkbox,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean, hide: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateEmployment extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchEducation(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      const today = new Date(new Date().toDateString())
      const currentYear = today.getFullYear()
      let years:any = [{ key: 1900, value: 1900, text: "1900" }]
      let nextYear = years[years.length-1].value + 1

      while (nextYear <= currentYear) {
          years.push({ key: nextYear, value: nextYear, text: nextYear.toString() })
          nextYear++
      }
      years.unshift({ key: 0, value: null, text: "" })

      const dobYear = new Date(this.props.inmate.info.dob).getFullYear();
      const filteredOptions = _.filter(years, ({ key, value }: any) => key === 0 || value >= dobYear);

      return (
        <Container fluid>
          {!this.props.print || (this.props.hide ? this.props.inmate.education.data.jobs.length > 0 : true) ? <Fragment>
            <div style={{display:'inline-block',marginTop:20}}>
              <Header as='h3'>Employment History</Header>
            </div>
            {this.props.print ? null : <Popup content={`Add Employment History`} position="top center" trigger={<Button positive circular icon='plus' onClick={() => this.props.toggleJob(this.props.num)} style={{marginLeft:10,marginBottom:10}} />} />}
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <Table compact celled striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Title</Table.HeaderCell>
                          <Table.HeaderCell>Company</Table.HeaderCell>
                          <Table.HeaderCell>Location</Table.HeaderCell>
                          <Table.HeaderCell>Status</Table.HeaderCell>
                          <Table.HeaderCell>Start date</Table.HeaderCell>
                          <Table.HeaderCell>End date</Table.HeaderCell>
                          <Table.HeaderCell>Currently Employed</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.props.inmate.education.data.jobs ? this.props.inmate.education.data.jobs.map((job:any) => (
                          <Table.Row key={job.recordID} onClick={() => this.props.openEditJob(this.props.num, job)}>
                            <Table.Cell>{job.title}</Table.Cell>
                            <Table.Cell>{job.company}</Table.Cell>
                            <Table.Cell>{job.location}</Table.Cell>
                            <Table.Cell>{job.status}</Table.Cell>
                            <Table.Cell>{job.startDate}</Table.Cell>
                            <Table.Cell>{job.current ? '' : job.endDate}</Table.Cell>
                            <Table.Cell><Checkbox checked={job.current} /></Table.Cell>
                          </Table.Row>
                        )) : null}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
            <Divider />
            <Form>
              <Grid>
                <Grid.Row style={{paddingBottom:0}}>
                  <Grid.Column width={16}>
                    <Form.Group>
                      <Form.Field
                        control={Select}
                        label="Professional & Vocational Licenses"
                        placeholder='Licenses'
                        options={this.props.inmate.options.licenses}
                        value={this.props.inmate.education.data.licenses || []}
                        onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'licenses', data.value as string[])}
                        width={14}
                        className={this.props.print ? 'transparent' : ''}
                        multiple
                        search
                      />
                      <Button color='green' onClick={_.debounce(() => this.props.saveLicenses(this.props.num), 10000, true)} style={{marginTop:15}}>Save</Button>
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
            <Divider />
            <Header as='h4' style={{marginTop:0}}>Commercial Driver's License</Header>
            <Form>
              <Grid>
                <Grid.Row style={{paddingBottom:0}}>
                  <Grid.Column width={16}>
                    <Form.Group>
                      <Popup
                        position="top center"
                        content="Commercial Driver's License"
                        trigger={<Form.Field
                          control={Radio}
                          label="CDL"
                          value="CDL"
                          name="commLicenseType"
                          checked={this.props.inmate.education.data.commLicenseType == "CDL"}
                          onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'commLicenseType', data.value)}
                          width={2}
                        />}
                      />
                      <Popup
                        position="top center"
                        content="Commercial Learner's Permit"
                        trigger={<Form.Field
                          control={Radio}
                          label="CLP"
                          value="CLP"
                          name="commLicenseType"
                          checked={this.props.inmate.education.data.commLicenseType == "CLP"}
                          onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'commLicenseType', data.value)}
                          width={2}
                        />}
                      />
                      <Form.Field
                        control={Radio}
                        label="None"
                        value={null}
                        name="commLicenseType"
                        checked={this.props.inmate.education.data.commLicenseType != "CDL" && this.props.inmate.education.data.commLicenseType != "CLP"}
                        onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'commLicenseType', data.value)}
                        width={2}
                      />
                    </Form.Group>
                    {this.props.inmate.education.data.commLicenseType == "CDL" || this.props.inmate.education.data.commLicenseType == "CLP" ? <Form.Group>
                        <Form.Field
                          control={Radio}
                          label='Class A'
                          name='commLicenseClass'
                          value={'A'}
                          checked={this.props.inmate.education.data.commLicenseClass == "A"}
                          onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'commLicenseClass', data.value as string)}
                          width={2}
                          className={this.props.print ? 'transparent' : ''}
                        />
                        <Form.Field
                          control={Radio}
                          label='Class B'
                          name='commLicenseClass'
                          value={'B'}
                          checked={this.props.inmate.education.data.commLicenseClass == "B"}
                          onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'commLicenseClass', data.value as string)}
                          width={2}
                          className={this.props.print ? 'transparent' : ''}
                        />
                        <Form.Field
                          control={Radio}
                          label='Class C'
                          name='commLicenseClass'
                          value={'C'}
                          checked={this.props.inmate.education.data.commLicenseClass == "C"}
                          onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'commLicenseClass', data.value as string)}
                          width={2}
                          className={this.props.print ? 'transparent' : ''}
                        />
                      </Form.Group> : null}
                      {this.props.inmate.education.data.commLicenseType == "CDL" || this.props.inmate.education.data.commLicenseType == "CLP" ? <Form.Group>
                        <Form.Field
                          control={Input}
                          label='License Company'
                          placeholder='License Company'
                          value={this.props.inmate.education.data.commLicenseCompany || ''}
                          onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'commLicenseCompany', data.value.validate())}
                          width={4}
                          className={this.props.print ? 'transparent' : ''}
                        />
                        <Form.Field
                          control={Input}
                          label='License Number'
                          placeholder='License Number'
                          value={this.props.inmate.education.data.commLicenseNumber || ''}
                          onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'commLicenseNumber', data.value.validate())}
                          width={4}
                          className={this.props.print ? 'transparent' : ''}
                        />
                      </Form.Group> : null}
                      {this.props.inmate.education.data.commLicenseType == "CDL" || this.props.inmate.education.data.commLicenseType == "CLP" ? <Form.Group>
                        <Form.Field
                          control={Select}
                          label='Endorsements'
                          placeholder='Endorsements'
                          options={this.props.inmate.education.data.commLicenseType == "CLP" ? _.filter(this.props.inmate.options.endorsements, (endorsement:any) => ["P","S","N"].includes(endorsement.value)) : this.props.inmate.options.endorsements}
                          value={this.props.inmate.education.data.commLicenseType == "CLP" ? _.filter(this.props.inmate.education.data.endorsements, (endorsement:any) => ["P","S","N"].includes(endorsement)) : this.props.inmate.education.data.endorsements}
                          onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'endorsements', data.value as string[])}
                          width={8}
                          className={this.props.print ? 'transparent' : ''}
                          multiple
                        />
                        <Form.Field
                          control={Select}
                          label='Restrictions'
                          placeholder='Restrictions'
                          options={this.props.inmate.options.restrictions}
                          value={this.props.inmate.education.data.restrictions || []}
                          onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'restrictions', data.value as string[])}
                          width={8}
                          className={this.props.print ? 'transparent' : ''}
                          multiple
                        />
                      </Form.Group> : null}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
            <Button color='green' onClick={_.debounce(() => this.props.saveLicenses(this.props.num), 10000, true)} style={{marginTop:15}}>Save</Button>
          </Fragment> : null}
          {!this.props.print || (this.props.hide ? this.props.inmate.education.data.goals.length > 0 : true) ? <Fragment>
            {!this.props.print || (this.props.hide ? this.props.inmate.education.data.jobs.length > 0 : true) ? <Divider /> : null}
            <div style={{display:'inline-block',marginTop:10}}>
              <Header as='h3'>Goals</Header>
            </div>
            {this.props.print ? null : <Popup content={`Add Goals`} position="top center" trigger={<Button positive circular icon='plus' onClick={() => this.props.toggleGoal(this.props.num)} style={{marginLeft:10,marginBottom:10}} />} />}
            <Form>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={12}>
                    <Table compact celled striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Goal</Table.HeaderCell>
                          <Table.HeaderCell>Explanation</Table.HeaderCell>
                          <Table.HeaderCell>Date</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {this.props.inmate.education.data.goals ? this.props.inmate.education.data.goals.map((goal:any) => (
                          <Table.Row key={goal.recordID} onClick={() => this.props.openEditGoal(this.props.num, goal)}>
                            <Table.Cell>{goal.goal}</Table.Cell>
                            <Table.Cell><div style={{whiteSpace:'normal'}}>{goal.explanation}</div></Table.Cell>
                            <Table.Cell>{goal.date}</Table.Cell>
                          </Table.Row>
                        )) : null}
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Fragment> : null}
          <Divider />
          <Form>
            <Grid>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column width={16}>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Comment</Table.HeaderCell>
                        <Table.HeaderCell>Staff</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.education.data.empNotes ? this.props.inmate.education.data.empNotes.map((note:any, i:number) => (
                        <Table.Row key={i} onClick={() => this.props.openEditEmploymentNote(this.props.num, note)}>
                          <Table.Cell><div style={{whiteSpace:'normal'}}>{note.content}</div></Table.Cell>
                          <Table.Cell>{note.staffName}</Table.Cell>
                          <Table.Cell>{note.date}</Table.Cell>
                        </Table.Row>
                      )) : null}
                      <Table.Row>
                        <Table.Cell colSpan={2}><Input style={{width:'100%'}} value={this.props.inmate.education.data.newNote||''} onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'newNote', data.value.validate())} /></Table.Cell>
                        <Table.Cell style={{textAlign:'center'}}><Button positive size='mini' content='Add' style={{width:'100%'}} onClick={_.debounce(() => this.props.addEmploymentNote(this.props.num), 10000, true)} /></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          <Modal
            open={this.props.inmate.modals.job.open}
            onClose={() => this.props.toggleJob(this.props.num)}
            size='large'
          >
            <Modal.Header content='Add Job' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Title'
                    name='title'
                    value={this.props.inmate.modals.job.data.title || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'job', 'title', data.value.validate())}
                    width={4}
                  />
                  <Form.Field
                    control={Input}
                    label='Company'
                    name='company'
                    value={this.props.inmate.modals.job.data.company || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'job', 'company', data.value.validate())}
                    width={4}
                  />
                  <Form.Field
                    control={Input}
                    label='Location'
                    name='location'
                    value={this.props.inmate.modals.job.data.location || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'job', 'location', data.value.validate())}
                    width={4}
                  />
                  <Form.Field
                    control={Select}
                    label="Status"
                    placeholder='Status'
                    options={[{ key: 0, value: "Full-time", text: "Full-time" },{ key: 1, value: "Part-time", text: "Part-time" },{ key: 2, value: "Self-employed", text: "Self-employed" },{ key: 3, value: "Freelance", text: "Freelance" },{ key: 4, value: "Contract", text: "Contract" },{ key: 5, value: "Internship", text: "Internship" },{ key: 6, value: "Apprenticeship", text: "Apprenticeship" },{ key: 7, value: "Seasonal", text: "Seasonal" }]}
                    value={this.props.inmate.modals.job.data.status}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'job', 'status', data.value as string)}
                    width={4}
                    fluid
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label="Start Year"
                    placeholder='Start Year'
                    options={filteredOptions}
                    value={this.props.inmate.modals.job.data.startYear}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'job', 'startYear', data.value as string)}
                    width={2}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Select}
                    label="Start Month"
                    placeholder='Start Month'
                    options={_.filter([{ key: 0, value: null, text: "" },{ key: 1, value: 1, text: "1" },{ key: 2, value: 2, text: "2" },{ key: 3, value: 3, text: "3" },{ key: 4, value: 4, text: "4" },{ key: 5, value: 5, text: "5" },{ key: 6, value: 6, text: "6" },{ key: 7, value: 7, text: "7" },{ key: 8, value: 8, text: "8" },{ key: 9, value: 9, text: "9" },{ key: 10, value: 10, text: "10" },{ key: 11, value: 11, text: "11" },{ key: 12, value: 12, text: "12" }], (opt:any) => opt.key == 0 || (this.props.inmate.modals.job.data.startYear >= new Date(this.props.inmate.info.dob).getFullYear() && (this.props.inmate.modals.job.data.startYear == new Date().getFullYear() ? opt.value <= new Date().getMonth()+1 : true) && (this.props.inmate.modals.job.data.startYear > new Date(this.props.inmate.info.dob).getFullYear() || new Date(this.props.inmate.modals.job.data.startYear, opt.value-1, (!this.props.inmate.modals.job.data.startDay ? new Date(this.props.inmate.modals.job.data.startYear, opt.value-1, 0).getDate() : this.props.inmate.modals.job.data.startDay)) >= new Date(this.props.inmate.info.dob))))}
                    value={this.props.inmate.modals.job.data.startMonth}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'job', 'startMonth', data.value as string)}
                    width={2}
                    disabled={!this.props.inmate.modals.job.data.startYear}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Select}
                    label="Start Day"
                    placeholder='Start Day'
                    options={_.filter([{ key: 0, value: null, text: "" },{ key: 1, value: 1, text: "1" },{ key: 2, value: 2, text: "2" },{ key: 3, value: 3, text: "3" },{ key: 4, value: 4, text: "4" },{ key: 5, value: 5, text: "5" },{ key: 6, value: 6, text: "6" },{ key: 7, value: 7, text: "7" },{ key: 8, value: 8, text: "8" },{ key: 9, value: 9, text: "9" },{ key: 10, value: 10, text: "10" },{ key: 11, value: 11, text: "11" },{ key: 12, value: 12, text: "12" },{ key: 13, value: 13, text: "13" },{ key: 14, value: 14, text: "14" },{ key: 15, value: 15, text: "15" },{ key: 16, value: 16, text: "16" },{ key: 17, value: 17, text: "17" },{ key: 18, value: 18, text: "18" },{ key: 19, value: 19, text: "19" },{ key: 20, value: 20, text: "20" },{ key: 21, value: 21, text: "21" },{ key: 22, value: 22, text: "22" },{ key: 23, value: 23, text: "23" },{ key: 24, value: 24, text: "24" },{ key: 25, value: 25, text: "25" },{ key: 26, value: 26, text: "26" },{ key: 27, value: 27, text: "27" },{ key: 28, value: 28, text: "28" },{ key: 29, value: 29, text: "29" },{ key: 30, value: 30, text: "30" },{ key: 31, value: 31, text: "31" }], (opt:any) => opt.key == 0 || (this.props.inmate.modals.job.data.startYear >= new Date(this.props.inmate.info.dob).getFullYear() && (this.props.inmate.modals.job.data.startYear == new Date().getFullYear() && this.props.inmate.modals.job.data.startMonth == new Date().getMonth()+1 ? opt.value <= new Date().getDate() : true) && (this.props.inmate.modals.job.data.startYear > new Date(this.props.inmate.info.dob).getFullYear() || new Date(this.props.inmate.modals.job.data.startYear, this.props.inmate.modals.job.data.startMonth-1, opt.value) >= new Date(this.props.inmate.info.dob))))}
                    value={this.props.inmate.modals.job.data.startDay}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'job', 'startDay', data.value as string)}
                    width={2}
                    disabled={!this.props.inmate.modals.job.data.startYear || !this.props.inmate.modals.job.data.startMonth}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Select}
                    label="End Year"
                    placeholder='End Year'
                    options={filteredOptions}
                    value={this.props.inmate.modals.job.data.current ? null : this.props.inmate.modals.job.data.endYear}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'job', 'endYear', data.value as string)}
                    width={2}
                    disabled={this.props.inmate.modals.job.data.current}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Select}
                    label="End Month"
                    placeholder='End Month'
                    options={_.filter([{ key: 0, value: null, text: "" },{ key: 1, value: 1, text: "1" },{ key: 2, value: 2, text: "2" },{ key: 3, value: 3, text: "3" },{ key: 4, value: 4, text: "4" },{ key: 5, value: 5, text: "5" },{ key: 6, value: 6, text: "6" },{ key: 7, value: 7, text: "7" },{ key: 8, value: 8, text: "8" },{ key: 9, value: 9, text: "9" },{ key: 10, value: 10, text: "10" },{ key: 11, value: 11, text: "11" },{ key: 12, value: 12, text: "12" }], (opt:any) => opt.key == 0 || (this.props.inmate.modals.job.data.endYear >= new Date(this.props.inmate.info.dob).getFullYear() && (this.props.inmate.modals.job.data.endYear == new Date().getFullYear() ? opt.value <= new Date().getMonth()+1 : true) && (this.props.inmate.modals.job.data.endYear > new Date(this.props.inmate.info.dob).getFullYear() || new Date(this.props.inmate.modals.job.data.endYear, opt.value-1, (!this.props.inmate.modals.job.data.endDay ? new Date(this.props.inmate.modals.job.data.endYear, opt.value-1, 0).getDate() : this.props.inmate.modals.job.data.endDay)) >= new Date(this.props.inmate.info.dob))))}
                    value={this.props.inmate.modals.job.data.current ? null : this.props.inmate.modals.job.data.endMonth}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'job', 'endMonth', data.value as string)}
                    width={2}
                    disabled={!this.props.inmate.modals.job.data.endYear || this.props.inmate.modals.job.data.current}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Select}
                    label="End Day"
                    placeholder='End Day'
                    options={_.filter([{ key: 0, value: null, text: "" },{ key: 1, value: 1, text: "1" },{ key: 2, value: 2, text: "2" },{ key: 3, value: 3, text: "3" },{ key: 4, value: 4, text: "4" },{ key: 5, value: 5, text: "5" },{ key: 6, value: 6, text: "6" },{ key: 7, value: 7, text: "7" },{ key: 8, value: 8, text: "8" },{ key: 9, value: 9, text: "9" },{ key: 10, value: 10, text: "10" },{ key: 11, value: 11, text: "11" },{ key: 12, value: 12, text: "12" },{ key: 13, value: 13, text: "13" },{ key: 14, value: 14, text: "14" },{ key: 15, value: 15, text: "15" },{ key: 16, value: 16, text: "16" },{ key: 17, value: 17, text: "17" },{ key: 18, value: 18, text: "18" },{ key: 19, value: 19, text: "19" },{ key: 20, value: 20, text: "20" },{ key: 21, value: 21, text: "21" },{ key: 22, value: 22, text: "22" },{ key: 23, value: 23, text: "23" },{ key: 24, value: 24, text: "24" },{ key: 25, value: 25, text: "25" },{ key: 26, value: 26, text: "26" },{ key: 27, value: 27, text: "27" },{ key: 28, value: 28, text: "28" },{ key: 29, value: 29, text: "29" },{ key: 30, value: 30, text: "30" },{ key: 31, value: 31, text: "31" }], (opt:any) => opt.key == 0 || (this.props.inmate.modals.job.data.endYear >= new Date(this.props.inmate.info.dob).getFullYear() && (this.props.inmate.modals.job.data.endYear == new Date().getFullYear() && this.props.inmate.modals.job.data.endMonth == new Date().getMonth()+1 ? opt.value <= new Date().getDate() : true) && (this.props.inmate.modals.job.data.endYear > new Date(this.props.inmate.info.dob).getFullYear() || new Date(this.props.inmate.modals.job.data.endYear, this.props.inmate.modals.job.data.endMonth-1, opt.value) >= new Date(this.props.inmate.info.dob))))}
                    value={this.props.inmate.modals.job.data.current ? null : this.props.inmate.modals.job.data.endDay}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'job', 'endDay', data.value as string)}
                    width={2}
                    disabled={!this.props.inmate.modals.job.data.endYear || !this.props.inmate.modals.job.data.endMonth || this.props.inmate.modals.job.data.current}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Checkbox}
                    label="Currently employed"
                    name="current"
                    checked={this.props.inmate.modals.job.data.current}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'job', 'current', data.checked)}
                    width={4}
                    style={{marginTop:28}}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleJob(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.addJob(this.props.num), 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editJob.open}
            onClose={() => this.props.toggleEditJob(this.props.num)}
            size='large'
          >
            <Modal.Header content='Edit Job' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Title'
                    name='title'
                    value={this.props.inmate.modals.editJob.data.title || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editJob', 'title', data.value.validate())}
                    width={4}
                  />
                  <Form.Field
                    control={Input}
                    label='Company'
                    name='company'
                    value={this.props.inmate.modals.editJob.data.company || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editJob', 'company', data.value.validate())}
                    width={4}
                  />
                  <Form.Field
                    control={Input}
                    label='Location'
                    name='location'
                    value={this.props.inmate.modals.editJob.data.location || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editJob', 'location', data.value.validate())}
                    width={4}
                  />
                  <Form.Field
                    control={Select}
                    label="Status"
                    placeholder='Status'
                    options={[{ key: 0, value: "Full-time", text: "Full-time" },{ key: 1, value: "Part-time", text: "Part-time" },{ key: 2, value: "Self-employed", text: "Self-employed" },{ key: 3, value: "Freelance", text: "Freelance" },{ key: 4, value: "Contract", text: "Contract" },{ key: 5, value: "Internship", text: "Internship" },{ key: 6, value: "Apprenticeship", text: "Apprenticeship" },{ key: 7, value: "Seasonal", text: "Seasonal" }]}
                    value={this.props.inmate.modals.editJob.data.status}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editJob', 'status', data.value as string)}
                    width={4}
                    fluid
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label="Start Year"
                    placeholder='Start Year'
                    options={filteredOptions}
                    value={this.props.inmate.modals.editJob.data.startYear}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editJob', 'startYear', data.value as string)}
                    width={2}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Select}
                    label="Start Month"
                    placeholder='Start Month'
                    options={[{ key: 0, value: null, text: "" },{ key: 1, value: 1, text: "1" },{ key: 2, value: 2, text: "2" },{ key: 3, value: 3, text: "3" },{ key: 4, value: 4, text: "4" },{ key: 5, value: 5, text: "5" },{ key: 6, value: 6, text: "6" },{ key: 7, value: 7, text: "7" },{ key: 8, value: 8, text: "8" },{ key: 9, value: 9, text: "9" },{ key: 10, value: 10, text: "10" },{ key: 11, value: 11, text: "11" },{ key: 12, value: 12, text: "12" }]}
                    value={this.props.inmate.modals.editJob.data.startMonth}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editJob', 'startMonth', data.value as string)}
                    width={2}
                    disabled={!this.props.inmate.modals.editJob.data.startYear}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Select}
                    label="Start Day"
                    placeholder='Start Day'
                    options={[{ key: 0, value: null, text: "" },{ key: 1, value: 1, text: "1" },{ key: 2, value: 2, text: "2" },{ key: 3, value: 3, text: "3" },{ key: 4, value: 4, text: "4" },{ key: 5, value: 5, text: "5" },{ key: 6, value: 6, text: "6" },{ key: 7, value: 7, text: "7" },{ key: 8, value: 8, text: "8" },{ key: 9, value: 9, text: "9" },{ key: 10, value: 10, text: "10" },{ key: 11, value: 11, text: "11" },{ key: 12, value: 12, text: "12" },{ key: 13, value: 13, text: "13" },{ key: 14, value: 14, text: "14" },{ key: 15, value: 15, text: "15" },{ key: 16, value: 16, text: "16" },{ key: 17, value: 17, text: "17" },{ key: 18, value: 18, text: "18" },{ key: 19, value: 19, text: "19" },{ key: 20, value: 20, text: "20" },{ key: 21, value: 21, text: "21" },{ key: 22, value: 22, text: "22" },{ key: 23, value: 23, text: "23" },{ key: 24, value: 24, text: "24" },{ key: 25, value: 25, text: "25" },{ key: 26, value: 26, text: "26" },{ key: 27, value: 27, text: "27" },{ key: 28, value: 28, text: "28" },{ key: 29, value: 29, text: "29" },{ key: 30, value: 30, text: "30" },{ key: 31, value: 31, text: "31" }]}
                    value={this.props.inmate.modals.editJob.data.startDay}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editJob', 'startDay', data.value as string)}
                    width={2}
                    disabled={!this.props.inmate.modals.editJob.data.startYear || !this.props.inmate.modals.editJob.data.startMonth}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Select}
                    label="End Year"
                    placeholder='End Year'
                    options={filteredOptions}
                    value={this.props.inmate.modals.job.data.current ? null : this.props.inmate.modals.editJob.data.endYear}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editJob', 'endYear', data.value as string)}
                    width={2}
                    disabled={this.props.inmate.modals.editJob.data.current}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Select}
                    label="End Month"
                    placeholder='End Month'
                    options={[{ key: 0, value: null, text: "" },{ key: 1, value: 1, text: "1" },{ key: 2, value: 2, text: "2" },{ key: 3, value: 3, text: "3" },{ key: 4, value: 4, text: "4" },{ key: 5, value: 5, text: "5" },{ key: 6, value: 6, text: "6" },{ key: 7, value: 7, text: "7" },{ key: 8, value: 8, text: "8" },{ key: 9, value: 9, text: "9" },{ key: 10, value: 10, text: "10" },{ key: 11, value: 11, text: "11" },{ key: 12, value: 12, text: "12" }]}
                    value={this.props.inmate.modals.job.data.current ? null : this.props.inmate.modals.editJob.data.endMonth}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editJob', 'endMonth', data.value as string)}
                    width={2}
                    disabled={!this.props.inmate.modals.editJob.data.endYear || this.props.inmate.modals.editJob.data.current}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Select}
                    label="End Day"
                    placeholder='End Day'
                    options={[{ key: 0, value: null, text: "" },{ key: 1, value: 1, text: "1" },{ key: 2, value: 2, text: "2" },{ key: 3, value: 3, text: "3" },{ key: 4, value: 4, text: "4" },{ key: 5, value: 5, text: "5" },{ key: 6, value: 6, text: "6" },{ key: 7, value: 7, text: "7" },{ key: 8, value: 8, text: "8" },{ key: 9, value: 9, text: "9" },{ key: 10, value: 10, text: "10" },{ key: 11, value: 11, text: "11" },{ key: 12, value: 12, text: "12" },{ key: 13, value: 13, text: "13" },{ key: 14, value: 14, text: "14" },{ key: 15, value: 15, text: "15" },{ key: 16, value: 16, text: "16" },{ key: 17, value: 17, text: "17" },{ key: 18, value: 18, text: "18" },{ key: 19, value: 19, text: "19" },{ key: 20, value: 20, text: "20" },{ key: 21, value: 21, text: "21" },{ key: 22, value: 22, text: "22" },{ key: 23, value: 23, text: "23" },{ key: 24, value: 24, text: "24" },{ key: 25, value: 25, text: "25" },{ key: 26, value: 26, text: "26" },{ key: 27, value: 27, text: "27" },{ key: 28, value: 28, text: "28" },{ key: 29, value: 29, text: "29" },{ key: 30, value: 30, text: "30" },{ key: 31, value: 31, text: "31" }]}
                    value={this.props.inmate.modals.job.data.current ? null : this.props.inmate.modals.editJob.data.endDay}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editJob', 'endDay', data.value as string)}
                    width={2}
                    disabled={!this.props.inmate.modals.editJob.data.endYear || !this.props.inmate.modals.editJob.data.endMonth || this.props.inmate.modals.editJob.data.current}
                    fluid
                    search
                  />
                  <Form.Field
                    control={Checkbox}
                    label="Currently employed"
                    name="current"
                    checked={this.props.inmate.modals.editJob.data.current}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editJob', 'current', data.checked)}
                    width={4}
                    style={{marginTop:28}}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditJob(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeJob(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.editJob(this.props.num), 10000, true)}>Save</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.goal.open}
            onClose={() => this.props.toggleGoal(this.props.num)}
          >
            <Modal.Header content='Add Goal' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label="Goal"
                    placeholder='Goal'
                    options={[{ key: 0, value: "Enter employment", text: "Enter employment" },{ key: 1, value: "Retain employment", text: "Retain employment" },{ key: 2, value: "Obtain secondary school diploma or HSE", text: "Obtain secondary school diploma or HSE" },{ key: 3, value: "Place in post secondary education", text: "Place in post secondary education" },{ key: 4, value: "Place in training program", text: "Place in training program" },{ key: 5, value: "Achieve citizenship skills", text: "Achieve citizenship skills" },{ key: 6, value: "Achieve citizenship", text: "Achieve citizenship" },{ key: 7, value: "Discontinue public assistance", text: "Discontinue public assistance" },{ key: 8, value: "Register to vote / vote for first time", text: "Register to vote / vote for first time" },{ key: 9, value: "Increase involvement in child's education", text: "Increase involvement in child's education" },{ key: 10, value: "Other", text: "Other" }]}
                    value={this.props.inmate.modals.goal.data.goal}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'goal', 'goal', data.value as string)}
                    width={6}
                    fluid
                  />
                  <Form.Field
                    control={TextArea}
                    label='Explanation'
                    name='explanation'
                    value={this.props.inmate.modals.goal.data.explanation || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'goal', 'explanation', data.value)}
                    rows={3}
                    width={10}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleGoal(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.addGoal(this.props.num), 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editGoal.open}
            onClose={() => this.props.toggleEditGoal(this.props.num)}
          >
            <Modal.Header content='Edit Goal' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label="Goal"
                    placeholder='Goal'
                    options={[{ key: 0, value: "Enter employment", text: "Enter employment" },{ key: 1, value: "Retain employment", text: "Retain employment" },{ key: 2, value: "Obtain secondary school diploma or HSE", text: "Obtain secondary school diploma or HSE" },{ key: 3, value: "Place in post secondary education", text: "Place in post secondary education" },{ key: 4, value: "Place in training program", text: "Place in training program" },{ key: 5, value: "Achieve citizenship skills", text: "Achieve citizenship skills" },{ key: 6, value: "Achieve citizenship", text: "Achieve citizenship" },{ key: 7, value: "Discontinue public assistance", text: "Discontinue public assistance" },{ key: 8, value: "Register to vote / vote for first time", text: "Register to vote / vote for first time" },{ key: 9, value: "Increase involvement in child's education", text: "Increase involvement in child's education" },{ key: 10, value: "Other", text: "Other" }]}
                    value={this.props.inmate.modals.editGoal.data.goal}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editGoal', 'goal', data.value as string)}
                    width={6}
                    fluid
                  />
                  <Form.Field
                    control={TextArea}
                    label='Explanation'
                    name='explanation'
                    value={this.props.inmate.modals.editGoal.data.explanation || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editGoal', 'explanation', data.value)}
                    rows={3}
                    width={10}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditGoal(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeGoal(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.editGoal(this.props.num), 10000, true)}>Edit</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editEmploymentNote.open}
            onClose={() => this.props.toggleEditEmploymentNote(this.props.num)}
            size='tiny'
          >
            <Modal.Header content='Edit Comment' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    name='content'
                    value={this.props.inmate.modals.editEmploymentNote.data.content || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editEmploymentNote', 'content', data.value)}
                    rows={3}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditEmploymentNote(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeEmploymentNote(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.editEmploymentNote(this.props.num), 10000, true)}>Edit</Button>
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print, hide: ownProps.hide } },
  PanesStore.actionCreators
)(InmateEmployment as any)