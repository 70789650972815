import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as ClassesStore from '../../store/reducers/classes'
import TemplateRow from './TemplateRow'
import {
  Table
} from 'semantic-ui-react'

type CourseProps =
    { course: ClassesStore.Course, tab: string } &
    WithRouterProps<{}>

class TemplateGroup extends React.PureComponent<CourseProps> {
    public render() {
        return (
          <Fragment>
            <Table.Row positive>
              <Table.Cell colSpan={8} style={{ fontWeight: 700, padding: '.5em' }}>{this.props.course.name}</Table.Cell>
            </Table.Row>
            {this.props.course.templates.map((template:any, t:number) => (
              <TemplateRow template={template} key={t} />
            ))}
          </Fragment>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { course: ownProps.course, tab: state.drawer.tab } },
  {}
)(TemplateGroup as any)