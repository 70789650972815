import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Grid,
  Input,
  Select,
  Radio,
  Checkbox,
  TextArea,
  Button,
  Header,
  Divider,
  Icon,
  Table,
  Modal,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type ReviewProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean, hide: boolean, staff: any } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateReview extends React.PureComponent<ReviewProps> {
    public componentDidMount() {
        this.props.fetchRefusals(this.props.num, this.props.inmate.info.recordID)
        this.props.fetchReviews(this.props.num, this.props.inmate.info.recordID)
        this.props.fetchEducation(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      const today = new Date(new Date().toDateString())
      const recommendationOpts = [{ key: 0, text: "AA/NA", value: "joinAANA" },{ key: 1, text: "Obtain HSE", value: "joinHSE" },{ key: 2, text: "Available workline", value: "joinWorkline" },{ key: 3, text: "Pre-employment prep", value: "joinPrep" },{ key: 4, text: "Cognitive skills", value: "joinCogSkills" },{ key: 5, text: "Social skills", value: "joinSocialSkills" },{ key: 6, text: "Career & technical education", value: "joinCTE" },{ key: 7, text: "Verify diploma / HSE", value: "verifyDiplomaHSE" },{ key: 8, text: "Substance abuse", value: "joinSubstanceAbuse" },{ key: 9, text: "Substance abuse II", value: "joinSAL2" },{ key: 10, text: "Substance abuse III", value: "joinSAL3" },{ key: 11, text: "Stress management", value: "joinStressManagement" },{ key: 12, text: "Parenting", value: "joinParenting" },{ key: 13, text: "Domestic violence", value: "joinDomesticViolence" },{ key: 14, text: "SOTP", value: "joinSOTP" },{ key: 15, text: "Work Furlough", value: "joinFurlough" },{ key: 16, text: "Mental Health", value: "joinHealth" },{ key: 17, text: this.props.inmate.education.data.otherProgramWhat ? this.props.inmate.education.data.otherProgramWhat : 'Other', value: "joinOther" }]
      return (
        <Container fluid>
          {!this.props.print || (this.props.hide ? this.props.inmate.education.data.recommendations.length > 0 : true) ? <Fragment>
            <div style={{display:'inline-block',marginTop:20}}>
              <Header as='h3'>Recommended Programs</Header>
            </div>
            {this.props.print ? null : <Popup content={`Add Recommended Programs from RAD.`} position="top right" trigger={<Button positive circular icon='plus' onClick={() => this.props.toggleRecommendations(this.props.num)} style={{marginLeft:10,marginBottom:10}} />} />}
            <Form>
              <Grid>
                <Grid.Row style={{paddingBottom:0}}>
                  <Grid.Column width={16}>
                    <Table definition compact celled striped style={{marginBottom:20}}>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell width={1}>Programs</Table.Cell>
                          <Table.Cell>{this.props.inmate.education.data.recommendations ? _.map(this.props.inmate.education.data.recommendations, (r:string) => _.find(recommendationOpts, (o:any) => o.value == r).text).join(', ') : null}</Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          </Fragment> : null}
          {!this.props.print || (this.props.hide ? this.props.inmate.refusals.length > 0 : true) ? <Fragment>
            {!this.props.print || (this.props.hide ? this.props.inmate.education.data.recommendations.length > 0 : true) ? <Divider /> : null}
            <div style={{display:'inline-block',marginTop:20}}>
              <Header as='h3'>Refusals</Header>
            </div>
            {this.props.print ? null : <Popup content={`Add Education Refusals`} position="bottom center" trigger={<Button positive circular icon='plus' onClick={() => this.props.toggleRefusal(this.props.num)} style={{marginLeft:10,marginBottom:10}} />} />}
            <Table compact celled striped style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Refusal Date</Table.HeaderCell>
                  <Table.HeaderCell>Facility</Table.HeaderCell>
                  <Table.HeaderCell>Staff</Table.HeaderCell>
                  <Table.HeaderCell>Refused to participate</Table.HeaderCell>
                  <Table.HeaderCell>Refused placement testing</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.inmate.refusals.map((refusal:any) => (
                  <Table.Row key={refusal.recordID} onClick={() => this.props.openEditRefusal(this.props.num, refusal)}>
                    <Table.Cell>{refusal.date}</Table.Cell>
                    <Table.Cell>{refusal.facilityAbbr}</Table.Cell>
                    <Table.Cell>{refusal.staffName}</Table.Cell>
                    <Table.Cell>{refusal.refusedToParticipate ? 'Yes' : 'No'}</Table.Cell>
                    <Table.Cell>{refusal.refusedPlacementTesting ? 'Yes' : 'No'}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Fragment> : null}
          {!this.props.print || (this.props.hide ? this.props.inmate.reviews.length > 0 : true) ? <Fragment>
            <Divider />
            <div style={{display:'inline-block',marginTop:20}}>
              <Header as='h3'>Reviews</Header>
            </div>
            {this.props.print ? null : <Popup content={`Add Administrative Review`} position="bottom center" trigger={<Button positive circular icon='plus' onClick={() => this.props.toggleReview(this.props.num)} style={{marginLeft:10,marginBottom:10}} />} />}
            <Table compact celled striped style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Reviewed By</Table.HeaderCell>
                  <Table.HeaderCell>Review Date</Table.HeaderCell>
                  <Table.HeaderCell>Remarks</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.inmate.reviews.map((review:any) => (
                  <Table.Row key={review.recordID} onClick={() => this.props.openEditReview(this.props.num, review)}>
                    <Table.Cell>{review.staffName}</Table.Cell>
                    <Table.Cell>{review.date}</Table.Cell>
                    <Table.Cell><div style={{whiteSpace:'normal'}}>{review.remarks}</div></Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Fragment> : null}
          <Modal
            open={this.props.inmate.modals.refusal.open}
            onClose={() => this.props.toggleRefusal(this.props.num)}
            size='tiny'
          >
            <Modal.Header content='Add Refusal' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Refused to participate'
                    placeholder='Refused to participate'
                    name='refusedToParticipate'
                    options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                    value={this.props.inmate.modals.refusal.data.refusedToParticipate}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'refusal', 'refusedToParticipate', data.value as boolean)}
                  />
                  <Form.Field
                    control={Select}
                    label='Refused placement testing'
                    placeholder='Refused placement testing'
                    name='refusedPlacementTesting'
                    options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                    value={this.props.inmate.modals.refusal.data.refusedPlacementTesting}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'refusal', 'refusedPlacementTesting', data.value as boolean)}
                  />
                  <Form.Field
                    label="Refusal Date"
                    control={DatePicker}
                    selected={this.props.inmate.modals.refusal.data.date ? new Date(this.props.inmate.modals.refusal.data.date) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'refusal', 'date', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleRefusal(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.addRefusal(this.props.num), 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.review.open}
            onClose={() => this.props.toggleReview(this.props.num)}
            size='small'
          >
            <Modal.Header content='Add Review' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    label='Remarks'
                    name='remarks'
                    value={this.props.inmate.modals.review.data.remarks}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'review', 'remarks', data.value)}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleReview(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.addReview(this.props.num), 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.recommendations.open}
            onClose={() => this.props.toggleRecommendations(this.props.num)}
            size='small'
          >
            <Modal.Header content='Recommended Programs' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  {this.props.inmate.education.data.hasRADDoc ? <Popup content={`Recommended programs locked by inmate document`} position="right center" trigger={<div style={{position:'relative',width:700}}>
                    <div className='lock' style={{position:'absolute',right:0,top:25,zIndex:999}} onClick={() => this.props.openInmateDocumentModal(this.props.num, _.find(this.props.inmate.documents, (doc:any) => doc.typeName == "RAD Record"))}><Icon name='lock' /></div>
                    <Form.Field
                      control={Select}
                      label='Programs'
                      placeholder='Programs'
                      options={recommendationOpts}
                      value={this.props.inmate.education.data.recommendations || []}
                      onChange={(e:any, data:any) => {}}
                      width={16}
                      multiple
                      className='transparent'
                    />
                  </div>} /> : <Form.Field
                  control={Select}
                    label='Programs'
                    placeholder='Programs'
                    options={recommendationOpts}
                    value={this.props.inmate.education.data.recommendations || []}
                    onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'recommendations', data.value as string[])}
                    width={12}
                    multiple
                  />}
                  {!this.props.inmate.education.data.hasRADDoc && _.any(this.props.inmate.education.data.recommendations, (rec:any) => rec == 'joinOther') ? <Form.Field
                    control={Input}
                    label='Other'
                    placeholder='Other'
                    name='otherProgramWhat'
                    value={this.props.inmate.education.data.otherProgramWhat || ''}
                    onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'otherProgramWhat', data.value.validate())}
                    width={4}
                  /> : null}
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleRecommendations(this.props.num)}>Close</Button>
              <Button color='green' onClick={_.debounce(() => this.props.saveRecommendations(this.props.num), 10000, true)}>Save</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editRefusal.open}
            onClose={() => this.props.toggleEditRefusal(this.props.num)}
            size='tiny'
          >
            <Modal.Header content='Edit Refusal' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Refused to participate'
                    placeholder='Refused to participate'
                    name='refusedToParticipate'
                    options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                    value={this.props.inmate.modals.editRefusal.data.refusedToParticipate}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editRefusal', 'refusedToParticipate', data.value as boolean)}
                  />
                  <Form.Field
                    control={Select}
                    label='Refused placement testing'
                    placeholder='Refused placement testing'
                    name='refusedPlacementTesting'
                    options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                    value={this.props.inmate.modals.editRefusal.data.refusedPlacementTesting}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editRefusal', 'refusedPlacementTesting', data.value as boolean)}
                  />
                  <Form.Field
                    label="Refusal Date"
                    control={DatePicker}
                    selected={this.props.inmate.modals.editRefusal.data.date ? new Date(this.props.inmate.modals.editRefusal.data.date) : null}
                    onChange={(date:any) => this.props.modalDataChange(this.props.num, 'editRefusal', 'date', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditRefusal(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeRefusal(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.editRefusal(this.props.num), 10000, true)}>Edit</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editReview.open}
            onClose={() => this.props.toggleEditReview(this.props.num)}
            size='small'
          >
            <Modal.Header content='Edit Review' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    label='Remarks'
                    name='remarks'
                    value={this.props.inmate.modals.editReview.data.remarks}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editReview', 'remarks', data.value)}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditReview(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeReview(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.editReview(this.props.num), 10000, true)}>Edit</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.document.open}
            onClose={() => this.props.toggleInmateDocumentModal(this.props.num)}
            size="small"
          >
            <Modal.Header content={`View Document`} />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Document Name'
                    name='docName'
                    value={this.props.inmate.modals.document.data.fileName}
                    width={11}
                    readOnly
                  />
                  <Form.Field
                    control={Select}
                    label='Document Type'
                    name='typeID'
                    options={this.props.inmate.options.docTypes.concat({key:0,value:0,text:'Unknown'})}
                    value={this.props.inmate.modals.document.data.typeID}
                    onChange={(e:any, data:any) => {}}
                    width={5}
                    className={'transparent'}
                  />
                </Form.Group>
                {this.props.inmate.modals.document.data.recordID && (this.props.inmate.modals.document.data.fields.length > 0 || this.props.inmate.modals.document.data.certificateID > 0 || this.props.inmate.modals.document.data.schoolID > 0) ? <Fragment>
                  <Divider />
                  <Form.Group style={{alignItems:'baseline'}}>
                    <Header size='small'>Associated Data</Header>
                  </Form.Group>
                  <Table compact celled striped definition>
                    <Table.Body>
                      {this.props.inmate.modals.document.data.certificateID > 0 ?
                        <Table.Row>
                          <Table.Cell width={5}>Certificate</Table.Cell>
                          <Table.Cell width={11}>{this.props.inmate.modals.document.data.certificateName}</Table.Cell>
                        </Table.Row>
                      : null}
                      {this.props.inmate.modals.document.data.schoolID > 0 ?
                        <Table.Row>
                          <Table.Cell width={5}>School</Table.Cell>
                          <Table.Cell width={11}>{this.props.inmate.modals.document.data.schoolName}</Table.Cell>
                        </Table.Row>
                      : null}
                      {this.props.inmate.modals.document.data.fields.map((field:any, i:number) => (
                        <Table.Row key={i}>
                          <Table.Cell width={5}>{field.name}</Table.Cell>
                          <Table.Cell width={11}>{field.value}</Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </Fragment> : null}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleInmateDocumentModal(this.props.num)}>Close</Button>
              {this.props.staff.admin ? (this.props.inmate.modals.document.data.archive ? <Button
                color='blue'
                content="Restore"
                onClick={_.debounce(() => this.props.restoreDocument(this.props.num, this.props.inmate.modals.document.data.recordID), 10000, true)}
              /> : <Button
                negative
                content="Remove"
                loading={this.props.inmate.uploading}
                onClick={_.debounce(() => this.props.removeDocument(this.props.num, this.props.inmate.modals.document.data.recordID), 10000, true)}
              />) : null}
              <a target='_blank' href={`https://sms.psd-hi.com/assets/documents/${this.props.inmate.modals.document.data.fileName}`} style={{marginLeft:10}}>
                <Button positive content="Open" />
              </a>
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print, hide: ownProps.hide, staff: state.staff.staff } },
  PanesStore.actionCreators
)(InmateReview as any)