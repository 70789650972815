import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import * as StaffStore from '../../store/reducers/staff'
import PermissionCells from './PermissionCells'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import Cleave from "cleave.js/react"
import {
  Container,
  Button,
  Form,
  Input,
  Select,
  Modal,
  Table,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type StaffProps =
    { admin: boolean, staff: any, options: any, permissions: any, presets: any[], facility: number, program: number, title: number, modals: any } &
    typeof AdminStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class StaffAdd extends React.PureComponent<StaffProps> {
    public componentDidMount() {
        this.props.fetchProgramOptions()
    }

    public render() {
        return (
          <Container fluid>
            <Form style={{marginTop:14}}>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='Last Name'
                  name='lastName'
                  value={this.props.staff.lastName||''}
                  onChange={(e:any, data:any) => this.props.editNewField('lastName', data.value.validateName())}
                />
                <Form.Field
                  control={Input}
                  label='First Name'
                  name='firstName'
                  value={this.props.staff.firstName||''}
                  onChange={(e:any, data:any) => this.props.editNewField('firstName', data.value.validateName())}
                />
                <Form.Field
                  control={Input}
                  label='Login'
                  name='login'
                  value={this.props.staff.username||''}
                  onChange={(e:any, data:any) => this.props.editNewField('username', data.value.validateEmail())}
                />
                <Form.Field
                  control={Input}
                  label='Email'
                  name='email'
                  value={this.props.staff.email||''}
                  onChange={(e:any, data:any) => this.props.editNewField('email', data.value.validateEmail())}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='Nickname'
                  name='nickname'
                  value={this.props.staff.nickname||''}
                  onChange={(e:any, data:any) => this.props.editNewField('nickname', data.value.validateNick())}
                />
                <div className='field'>
                  <label>Phone</label>
                  <Cleave
                    name='phone'
                    value={this.props.staff.phone||''}
                    onChange={(e:any) => this.props.editNewField('phone', e.target.value)}
                    options={{
                      blocks: [3,3,4],
                      delimiter: '-',
                      numericOnly: true
                    }}
                  />
                </div>
                <Form.Field
                  control={DatePicker}
                  label='Exp Date'
                  name='expDate'
                  placeholder='MM/DD/YYYY'
                  selected={this.props.staff.expDate ? new Date(this.props.staff.expDate) : null}
                  onChange={(date:any) => this.props.editNewField('expDate', date ? format(date, 'MM/dd/yyyy') : null)}
                  width={4}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Select}
                  label='Facility'
                  name='facility'
                  options={this.props.options.addFacilities}
                  value={this.props.facility}
                  onChange={(e:any, data:any) => this.props.editNewStaffFacility(data.value)}
                />
                <Form.Field
                  control={Select}
                  label='Program'
                  name='program'
                  options={this.props.options.programs}
                  value={this.props.program}
                  onChange={(e:any, data:any) => this.props.fetchNewDepartmentOptions(data.value)}
                />
                <Form.Field
                  control={Select}
                  label='Position'
                  name='position'
                  options={this.props.options.titles}
                  value={this.props.title}
                  onChange={(e:any, data:any) => this.props.editNewStaffTitle(data.value)}
                />
              </Form.Group>
            </Form>
            {!this.props.staff.lastName || !this.props.staff.firstName || !this.props.staff.expDate || !this.props.facility || !this.props.program || !this.props.title ? <Popup position='right center' content="Please make sure the staff name, expiration date, facility, program, and position are filled out." trigger={<Button content='Add Staff' />} /> : <Button positive content='Add Staff' onClick={_.debounce(() => this.props.addStaff(), 10000, true)} />}
            <Modal
              open={this.props.modals.duplicate.open}
              onClose={this.props.toggleDuplicateModal}
              size="small"
            >
              <Modal.Header content={`${this.props.staff.lastName}, ${this.props.staff.firstName}`} />
              <Modal.Content>
                <div>A staff member already exists with this name. Are you sure you wish to <b>create</b> this staff member?</div>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={this.props.toggleDuplicateModal}>Close</Button>
                <Button
                  positive
                  content="Create"
                  onClick={_.debounce(() => this.props.addStaff(true), 10000, true)}
                />
              </Modal.Actions>
            </Modal>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState) => { return { staff: state.admin.staff.new, options: state.admin.staff.options, modals: state.admin.staff.modals, facility: state.admin.staff.facility, program: state.admin.staff.program, title: state.admin.staff.title, tab: state.drawer.tab } },
  { ...AdminStore.actionCreators, ...StaffStore.actionCreators }
)(StaffAdd as any)