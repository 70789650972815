import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import * as DrawerStore from '../../store/reducers/drawer'
import * as PanesStore from '../../store/reducers/panes'
import Cleave from "cleave.js/react"
import {
  Container,
  Table,
  Modal,
  Form,
  Button,
  Checkbox,
  Input,
  Select,
  Radio,
  Popup,
  Label
} from 'semantic-ui-react'
const _ = require('underscore')

type FacilityProps =
    { facilities: any, tab: string, mode: string } &
    typeof AdminStore.actionCreators &
    typeof DrawerStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class Facilities extends React.PureComponent<FacilityProps> {
    public componentDidMount() {
        this.props.fetchFacilities()
    }
  
    public render() {
      return (
        <Container fluid style={{overflow:'auto'}}>
          <Table compact celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Facility name</Table.HeaderCell>
                <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Phone number</Table.HeaderCell>
                <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Abbreviation</Table.HeaderCell>
                <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',zIndex:1 }}>Active</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.facilities.list.map((facility:any) => (
                <Table.Row key={facility.recordID}>
                  <Table.Cell>
                    {this.props.mode == 'dual' ? <Popup
                      hideOnScroll
                      on='click'
                      trigger={<div className='link' style={{whiteSpace:'normal'}}>{facility.name}</div>}
                    >
                      <Popup.Header>Open in pane</Popup.Header>
                      <Popup.Content>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchFacility(1, facility.recordID) }}>1</Label>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchFacility(2, facility.recordID) }}>2</Label>
                      </Popup.Content>
                    </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchFacility(this.props.mode == 'left' ? 1 : 2, facility.recordID) }}>{facility.name}</div>}
                  </Table.Cell>
                  <Table.Cell>{facility.phone}</Table.Cell>
                  <Table.Cell>{facility.abbreviation}</Table.Cell>
                  <Table.Cell><Checkbox checked={!facility.deleted} /></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Button positive content='Add Facility' onClick={this.props.toggleFacilitiesModal} />
          <Modal
            open={this.props.facilities.modal.open}
            onClose={this.props.toggleProgramModal}
          >
            <Modal.Header content={`${this.props.facilities.modal.data.recordID ? 'Edit' : 'Add'} Facility`} />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Facility name'
                    name='Facility name'
                    value={this.props.facilities.modal.data.name||''}
                    onChange={(e:any, data:any) => this.props.editFacilityField('name', data.value.validate())}
                    width={6}
                  />
                  <Form.Field
                    control={Input}
                    label='Abbreviation'
                    name='Abbreviation'
                    value={this.props.facilities.modal.data.abbreviation||''}
                    onChange={(e:any, data:any) => this.props.editFacilityField('abbreviation', data.value.validate())}
                  />
                  <div className="four wide field">
                    <label>Facility Type</label>
                    <Checkbox
                      name="prison"
                      label="Prison"
                      checked={this.props.facilities.modal.data.prison||false}
                      onChange={(e:any, data:any) => this.props.editFacilityField('prison', data.checked)}
                      style={{marginRight:20,marginTop:10}}
                    />
                    <Checkbox
                      name="jail"
                      label="Jail"
                      checked={this.props.facilities.modal.data.jail||false}
                      onChange={(e:any, data:any) => this.props.editFacilityField('jail', data.checked)}
                    />
                  </div>
                  <div className="two wide field">
                    <label>Active</label>
                    <Checkbox
                      name="deleted"
                      label="Active"
                      checked={!this.props.facilities.modal.data.deleted}
                      onChange={(e:any, data:any) => this.props.editFacilityField('deleted', !data.checked)}
                      style={{marginRight:20,marginTop:10}}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Funding source'
                    placeholder='Funding source'
                    options={this.props.facilities.options.funding||[]}
                    value={this.props.facilities.modal.data.fundingSource}
                    onChange={(e:any, data:any) => this.props.editFacilityField('fundingSource', data.value)}
                  />
                  <div>
                    <label style={{fontWeight:700,width:100,marginLeft:10}}>Inmate Gender</label>
                    <div className="inline-fields" style={{marginTop:14,marginLeft:14}}>
                      <Form.Field
                        name="gender"
                        label="Male"
                        control={Radio}
                        checked={this.props.facilities.modal.data.sex === 'M'}
                        value="M"
                        onChange={(e:any, data:any) => this.props.editFacilityField('sex', data.value)}
                      />
                      <Form.Field
                        name="gender"
                        label="Female"
                        control={Radio}
                        checked={this.props.facilities.modal.data.sex === 'F'}
                        value="F"
                        onChange={(e:any, data:any) => this.props.editFacilityField('sex', data.value)}
                      />
                      <Form.Field
                        name="gender"
                        label="Both"
                        control={Radio}
                        checked={this.props.facilities.modal.data.sex === 'B'}
                        value="B"
                        onChange={(e:any, data:any) => this.props.editFacilityField('sex', data.value)}
                      />
                    </div>
                  </div>
                  <div className="four wide field">
                    <label>Enforce Minimum Class Size</label>
                    <Checkbox
                      name="enforceMinimumClassSize"
                      checked={this.props.facilities.modal.data.enforceMinimumClassSize||false}
                      onChange={(e:any, data:any) => this.props.editFacilityField('enforceMinimumClassSize', data.checked)}
                      style={{marginTop:10}}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <div className='field'>
                    <label>Main Phone Number</label>
                    <Cleave
                      name='phone'
                      value={this.props.facilities.modal.data.phone||''}
                      onChange={(e:any) => this.props.editFacilityField('phone', e.target.value)}
                      options={{
                        blocks: [3,3,4],
                        delimiter: '-',
                        numericOnly: true
                      }}
                    />
                  </div>
                  <div className='field'>
                    <label>Fax number</label>
                    <Cleave
                      name='phone'
                      value={this.props.facilities.modal.data.fax||''}
                      onChange={(e:any) => this.props.editFacilityField('fax', e.target.value)}
                      options={{
                        blocks: [3,3,4],
                        delimiter: '-',
                        numericOnly: true
                      }}
                    />
                  </div>
                  <Form.Field
                    control={Input}
                    label="Appropriation Code"
                    name="appnCode"
                    value={this.props.facilities.modal.data.appnCode||''}
                    onChange={(e:any, data:any) => this.props.editFacilityField('appnCode', data.value.validate())}
                  />
                  <Form.Field
                    control={Input}
                    label="Warden's Name"
                    name="warden"
                    value={this.props.facilities.modal.data.warden||''}
                    onChange={(e:any, data:any) => this.props.editFacilityField('warden', data.value.validate())}
                    width={6}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label="Address1"
                    name="address1"
                    value={this.props.facilities.modal.data.address1||''}
                    onChange={(e:any, data:any) => this.props.editFacilityField('address1', data.value.validate())}
                  />
                  <Form.Field
                    control={Input}
                    label="Address2"
                    name="address2"
                    value={this.props.facilities.modal.data.address2||''}
                    onChange={(e:any, data:any) => this.props.editFacilityField('address2', data.value.validate())}
                  />
                  <Form.Field
                    control={Input}
                    label="City, State Zip"
                    name="csz"
                    value={this.props.facilities.modal.data.csz||''}
                    onChange={(e:any, data:any) => this.props.editFacilityField('csz', data.value.validate())}
                  />
                </Form.Group>
                <Form.Group>
                  <div className="four wide field">
                    <label>Paid Attendance</label>
                    <Checkbox
                      name="paidAttendance"
                      checked={this.props.facilities.modal.data.paidAttendance||false}
                      onChange={(e:any, data:any) => this.props.editFacilityField('paidAttendance', data.checked)}
                      style={{marginTop:10}}
                    />
                  </div>
                  <div className="five wide field">
                    <label>Include this facility in reporting</label>
                    <Checkbox
                      name="reportOn"
                      checked={this.props.facilities.modal.data.reportOn||false}
                      onChange={(e:any, data:any) => this.props.editFacilityField('reportOn', data.checked)}
                      style={{marginTop:10}}
                    />
                  </div>
                  <div className="seven wide field">
                    <label>Enable "Not Yet Assigned" for a GenEd assignment</label>
                    <Checkbox
                      name="notYetAssigned"
                      checked={this.props.facilities.modal.data.notYetAssigned||false}
                      onChange={(e:any, data:any) => this.props.editFacilityField('notYetAssigned', data.checked)}
                      style={{marginTop:10}}
                    />
                  </div>
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleFacilitiesModal}>Close</Button>
              <Button color='green' onClick={_.debounce(this.props.saveFacility, 10000, true)}>{this.props.facilities.modal.data.recordID ? 'Save' : 'Add'}</Button>
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { facilities: state.admin.facilities, tab: state.drawer.tab, mode: state.panes.mode } },
  { ...DrawerStore.actionCreators, ...PanesStore.actionCreators, ...AdminStore.actionCreators }
)(Facilities as any)