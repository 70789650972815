import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import {
  Table,
  Radio
} from 'semantic-ui-react'
const _ = require('underscore')

type PermissionProps =
    { display: string, subsystem: string, module: string, options?: string[], department: number, preset: string, presetType: string, presets: any[], editPreset: any } &
    WithRouterProps<{}>

class AccessPermissionCells extends React.PureComponent<PermissionProps> {
    public render() {
      return (
        <Fragment>
          <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}>{this.props.display}</Table.Cell>
          <Table.Cell>{!this.props.options || this.props.options.includes('V') ? <Radio name={`${this.props.subsystem}:${this.props.module}`} value="view" checked={_.any(this.props.presets, (p:any) => p.name == this.props.preset && p.departmentID == this.props.department && p.subsystem == this.props.subsystem && p.module == this.props.module && (this.props.presetType == 'facility' ? p.facilityPermission == 'view' : p.departmentPermission == 'view'))} onChange={() => this.props.editPreset(this.props.preset, this.props.presetType, this.props.department, this.props.subsystem, this.props.module, 'view')} /> : null}</Table.Cell>
          <Table.Cell>{!this.props.options || this.props.options.includes('E') ? <Radio name={`${this.props.subsystem}:${this.props.module}`} value="edit" checked={_.any(this.props.presets, (p:any) => p.name == this.props.preset && p.departmentID == this.props.department && p.subsystem == this.props.subsystem && p.module == this.props.module && (this.props.presetType == 'facility' ? p.facilityPermission == 'edit' : p.departmentPermission == 'edit'))} onChange={() => this.props.editPreset(this.props.preset, this.props.presetType, this.props.department, this.props.subsystem, this.props.module, 'edit')} /> : null}</Table.Cell>
          <Table.Cell>{!this.props.options || this.props.options.includes('A') ? <Radio name={`${this.props.subsystem}:${this.props.module}`} value="add" checked={_.any(this.props.presets, (p:any) => p.name == this.props.preset && p.departmentID == this.props.department && p.subsystem == this.props.subsystem && p.module == this.props.module && (this.props.presetType == 'facility' ? p.facilityPermission == 'add' : p.departmentPermission == 'add'))} onChange={() => this.props.editPreset(this.props.preset, this.props.presetType, this.props.department, this.props.subsystem, this.props.module, 'add')} /> : null}</Table.Cell>
          <Table.Cell>{!this.props.options || this.props.options.includes('D') ? <Radio name={`${this.props.subsystem}:${this.props.module}`} value="del" checked={_.any(this.props.presets, (p:any) => p.name == this.props.preset && p.departmentID == this.props.department && p.subsystem == this.props.subsystem && p.module == this.props.module && (this.props.presetType == 'facility' ? p.facilityPermission == 'del' : p.departmentPermission == 'del'))} onChange={() => this.props.editPreset(this.props.preset, this.props.presetType, this.props.department, this.props.subsystem, this.props.module, 'del')} /> : null}</Table.Cell>
          <Table.Cell><Radio name={`${this.props.subsystem}:${this.props.module}`} value="none" checked={_.any(this.props.presets, (p:any) => p.name == this.props.preset && p.departmentID == this.props.department && p.subsystem == this.props.subsystem && p.module == this.props.module && (this.props.presetType == 'facility' ? p.facilityPermission == 'none' : p.departmentPermission == 'none')) || !_.any(this.props.presets, (p:any) => p.name == this.props.preset && p.departmentID == this.props.department && p.subsystem == this.props.subsystem && p.module == this.props.module)} onChange={() => this.props.editPreset(this.props.preset, this.props.presetType, this.props.department, this.props.subsystem, this.props.module, 'none')} /></Table.Cell>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => ownProps,
  {}
)(AccessPermissionCells as any)