import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import * as PanesStore from '../../store/reducers/panes'
import * as DrawerStore from '../../store/reducers/drawer'
import ProgramInfo from './ProgramInfo'
import {
  Container,
  Accordion,
  Button,
  List,
  Modal,
  Form,
  Input,
  Select,
  TextArea,
  Checkbox,
  Label,
  Tab,
  Popup,
  Icon,
  Grid,
  Header,
  Divider
} from 'semantic-ui-react'
const _ = require('underscore')

type StaffProps =
    { programs: any, tab: string, mode: string } &
    typeof AdminStore.actionCreators &
    typeof PanesStore.actionCreators &
    typeof DrawerStore.actionCreators &
    WithRouterProps<{}>

class Programs extends React.PureComponent<StaffProps> {
    public componentDidMount() {
        this.props.fetchPrograms()
    }

    public render() {
      const templatePanels = (course:any) => {
        return (
          <Fragment>
            <List link style={{marginBottom:0,marginLeft:12}}>
              {course.templates.map((template:any) => {
                return (<Fragment key={template.recordID}>
                    {this.props.mode == 'dual' ? <Popup
                      hideOnScroll
                      on='click'
                      trigger={<List.Item key={template.recordID}>{template.name}</List.Item>}
                    >
                      <Popup.Header>Open in pane</Popup.Header>
                      <Popup.Content>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchTemplate(1, template.recordID) }}>1</Label>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchTemplate(2, template.recordID) }}>2</Label>
                      </Popup.Content>
                    </Popup> : <List.Item key={template.recordID} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchTemplate(this.props.mode == 'left' ? 1 : 2, template.recordID) }}>{template.name}</List.Item>}
                  {template.active ? null : <div style={{float:'right'}}><Icon name='archive' /></div>}
                </Fragment>)
              })}
            </List>
            <Button positive content={`Add ${course.name} Template`} size="mini" onClick={() => this.props.addTemplate(course)} style={{marginTop:12,marginLeft:12}} />
            {this.props.mode == 'dual' ? <Popup
              hideOnScroll
              on='click'
              trigger={<Button color="blue" content={`Edit ${course.name} Course`} size="mini" style={{marginTop:12}} />}
            >
              <Popup.Header>Open in pane</Popup.Header>
              <Popup.Content>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchCourse(1, course.recordID) }}>1</Label>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchCourse(2, course.recordID) }}>2</Label>
              </Popup.Content>
            </Popup> : <Button color="blue" content={`Edit ${course.name} Course`} size="mini" style={{marginTop:12}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchCourse(this.props.mode == 'left' ? 1 : 2, course.recordID) }} />}
          </Fragment>
        )
      }

      const coursePanels = (courses:any) => {
        return _.filter(courses, (course:any) => this.props.programs.hide ? course.templates.length > 0 : true).map((course:any) => {
          return { key: course.recordID, title: { content:
            <Fragment>
              {course.name}
              {course.active ? null : <div style={{float:'right'}}><Icon name='archive' /></div>}
            </Fragment>
          }, content: { content: templatePanels(course) } }
        })
      }

      const programPanels = _.filter(this.props.programs.list, (program:any) => this.props.programs.hide ? _.any(program.courses, (course:any) => course.templates.length > 0) : true).map((program:any) => {
        return { key: program.recordID, title: { content:
          <Fragment>
            {program.name}
            {program.active ? null : <div style={{float:'right'}}><Icon name='archive' /></div>}
          </Fragment>
        }, content: { content:
          <Fragment>
            <Accordion panels={coursePanels(program.courses)} />
            <Button positive content={`Add ${program.name} Course`} size="mini" onClick={() => this.props.addCourse(program)} style={{marginTop:12}} />
            {this.props.mode == 'dual' ? <Popup
              hideOnScroll
              on='click'
              trigger={<Button color="blue" content={`Edit ${program.name} Program`} size="mini" style={{marginTop:12}} />}
            >
              <Popup.Header>Open in pane</Popup.Header>
              <Popup.Content>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchProgram(1, program.recordID) }}>1</Label>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchProgram(2, program.recordID) }}>2</Label>
              </Popup.Content>
            </Popup> : <Button color="blue" content={`Edit ${program.name} Program`} size="mini" style={{marginTop:12}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchProgram(this.props.mode == 'left' ? 1 : 2, program.recordID) }} />}
          </Fragment>
        }}
      })

      return (
        <Container fluid>
          <div style={{overflow:'auto'}}>
            <Form style={{marginBottom:10}}>
              <Form.Field
                control={Checkbox}
                label="Hide empty templates"
                checked={this.props.programs.hide}
                onChange={this.props.toggleEmptyTemplates}
              />
            </Form>
            <Accordion styled panels={programPanels} style={{marginTop:1,marginLeft:1,marginBottom:1}} />
            <Button positive content={`Add Program`} size="mini" onClick={this.props.addProgram} style={{marginTop:12}} />
          </div>
          <Modal
            open={this.props.programs.modals.template.open}
            onClose={this.props.toggleTemplateModal}
          >
            <Modal.Header content={`Add Class Template`} />
            <Modal.Content>
              <ProgramInfo data={this.props.programs.modals.template.data} options={this.props.programs.options} />
              <Divider />
              <Header as='h3' style={{marginTop:10}}>Default Class Options</Header>
              <Form style={{marginTop:20}}>
                <Grid>
                  <Grid.Column width={5}>
                    <Form.Group>
                      <Form.Field width={12} label="Display on transcript" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="transcript"
                          checked={this.props.programs.modals.template.data.transcript}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('transcript', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Include class on movement list" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="movementList"
                          checked={this.props.programs.modals.template.data.movementList}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('movementList', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Include class on signin list" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="signinList"
                          checked={this.props.programs.modals.template.data.signinList}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('signinList', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Meet on holiday" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="meetOnHoliday"
                          checked={this.props.programs.modals.template.data.meetOnHoliday}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('meetOnHoliday', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Meet on furlough day" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="meetOnFurloughDay"
                          checked={this.props.programs.modals.template.data.meetOnFurloughDay}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('meetOnFurloughDay', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Accumulate Hours" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="accumulateHours"
                          checked={this.props.programs.modals.template.data.accumulateHours}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('accumulateHours', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Group>
                      <Form.Field width={10} label="Title 1 class" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="title1"
                          checked={this.props.programs.modals.template.data.title1}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('title1', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="ABE class" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="abe"
                          checked={this.props.programs.modals.template.data.abe}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('abe', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="DoE" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="doe"
                          checked={this.props.programs.modals.template.data.doe}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('doe', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="Online" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.template.data.online}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('online', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="Correspondence" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.template.data.correspondence}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('correspondence', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="No grade" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="noGrade"
                          checked={this.props.programs.modals.template.data.noGrade}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('noGrade', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Group>
                      <Form.Field width={12} label="Workshop" />
                      <Form.Field>
                        <Checkbox
                          name='workshop'
                          checked={this.props.programs.modals.template.data.workshop}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('workshop', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Post Secondary" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.template.data.postSecondary}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('postSecondary', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Workforce Development Diploma" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.template.data.workforceDiploma}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('workforceDiploma', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Ignore diploma/HSE requirement" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="ignoreEdStatus"
                          checked={this.props.programs.modals.template.data.ignoreEdStatus}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('ignoreEdStatus', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Ignore hours requirement" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="ignoreHoursRqt"
                          checked={this.props.programs.modals.template.data.ignoreHoursRqt}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('ignoreHoursRqt', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="No attendance" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="noAttendance"
                          checked={this.props.programs.modals.template.data.noAttendance}
                          onChange={(e:any, data:any) => this.props.editAdminTemplateField('noAttendance', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                </Grid>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleTemplateModal}>Close</Button>
              <Button color='green' onClick={_.debounce(this.props.saveAdminTemplate, 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.programs.modals.course.open}
            onClose={this.props.toggleCourseModal}
          >
            <Modal.Header content={`Add Course`} />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Course name'
                    name='Course name'
                    value={this.props.programs.modals.course.data.courseName||''}
                    onChange={(e:any, data:any) => this.props.editAdminCourseField('courseName', data.value.validate())}
                  />
                  <Form.Field
                    control={Select}
                    label='Funding source'
                    placeholder='Funding source'
                    options={this.props.programs.options.funding||[]}
                    value={this.props.programs.modals.course.data.fundingSource}
                    onChange={(e:any, data:any) => this.props.editAdminCourseField('fundingSource', data.value)}
                  />
                  <Form.Field
                    control={Input}
                    label='Required hours'
                    name='Required hours'
                    value={this.props.programs.modals.course.data.requiredHours||''}
                    onChange={(e:any, data:any) => this.props.editAdminCourseField('requiredHours', data.value.validate())}
                  />
                </Form.Group>
                <Form.Field
                  control={TextArea}
                  label='Description'
                  name='Description'
                  value={this.props.programs.modals.course.data.description||''}
                  onChange={(e:any, data:any) => this.props.editAdminCourseField('description', data.value)}
                />
                <Form.Field
                  control={TextArea}
                  label='Course objectives'
                  name='Course objectives'
                  value={this.props.programs.modals.course.data.courseObjectives||''}
                  onChange={(e:any, data:any) => this.props.editAdminCourseField('courseObjectives', data.value)}
                />
                <Form.Field
                  control={TextArea}
                  label='Course outcomes'
                  name='Course outcomes'
                  value={this.props.programs.modals.course.data.courseOutcomes||''}
                  onChange={(e:any, data:any) => this.props.editAdminCourseField('courseOutcomes', data.value)}
                />
              </Form>
              <Divider />
              <Header as='h3' style={{marginTop:10}}>Default Course Options</Header>
              <Form style={{marginTop:20}}>
                <Grid>
                  <Grid.Column width={5}>
                    <Form.Group>
                      <Form.Field width={12} label="Display on transcript" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="transcript"
                          checked={this.props.programs.modals.course.data.transcript}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('transcript', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Include class on movement list" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="movementList"
                          checked={this.props.programs.modals.course.data.movementList}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('movementList', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Include class on signin list" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="signinList"
                          checked={this.props.programs.modals.course.data.signinList}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('signinList', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Meet on holiday" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="meetOnHoliday"
                          checked={this.props.programs.modals.course.data.meetOnHoliday}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('meetOnHoliday', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Meet on furlough day" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="meetOnFurloughDay"
                          checked={this.props.programs.modals.course.data.meetOnFurloughDay}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('meetOnFurloughDay', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Accumulate hours" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="accumulateHours"
                          checked={this.props.programs.modals.course.data.accumulateHours}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('accumulateHours', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Group>
                      <Form.Field width={10} label="Title 1 class" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="title1"
                          checked={this.props.programs.modals.course.data.title1}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('title1', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="ABE class" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="abe"
                          checked={this.props.programs.modals.course.data.abe}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('abe', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="DoE" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="doe"
                          checked={this.props.programs.modals.course.data.doe}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('doe', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="Online" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.course.data.online}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('online', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="Correspondence" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.course.data.correspondence}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('correspondence', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="No grade" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="noGrade"
                          checked={this.props.programs.modals.course.data.noGrade}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('noGrade', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Group>
                      <Form.Field width={12} label="Workshop" />
                      <Form.Field>
                        <Checkbox
                          name='workshop'
                          checked={this.props.programs.modals.course.data.workshop}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('workshop', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Post Secondary" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.course.data.postSecondary}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('postSecondary', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Workforce Development Diploma" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.course.data.workforceDiploma}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('workforceDiploma', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Ignore diploma/HSE requirement" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="ignoreEdStatus"
                          checked={this.props.programs.modals.course.data.ignoreEdStatus}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('ignoreEdStatus', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Ignore hours requirement" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="ignoreHoursRqt"
                          checked={this.props.programs.modals.course.data.ignoreHoursRqt}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('ignoreHoursRqt', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="No attendance" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="noAttendance"
                          checked={this.props.programs.modals.course.data.noAttendance}
                          onChange={(e:any, data:any) => this.props.editAdminCourseField('noAttendance', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                </Grid>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleCourseModal}>Close</Button>
              <Button color='green' onClick={_.debounce(this.props.saveAdminCourse, 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.programs.modals.program.open}
            onClose={this.props.toggleProgramModal}
          >
            <Modal.Header content={`Add Program`} />
            <Modal.Content>
              <Form>
                <Form.Field
                  control={Input}
                  label='Program name'
                  name='Program name'
                  value={this.props.programs.modals.program.data.programName||''}
                  onChange={(e:any, data:any) => this.props.editAdminProgramField('programName', data.value.validate())}
                />
                <Form.Field
                  control={TextArea}
                  label='Program description'
                  name='Program description'
                  value={this.props.programs.modals.program.data.description||''}
                  onChange={(e:any, data:any) => this.props.editAdminProgramField('description', data.value)}
                />
                <Form.Group>
                  <Form.Field
                    control={Checkbox}
                    label="Non-HS Grads"
                    name="hsNonGrads"
                    checked={this.props.programs.modals.program.data.hsNonGrads}
                    onChange={(e:any, data:any) => this.props.editAdminProgramField('hsNonGrads', data.checked)}
                  />
                  <Form.Field
                    control={Checkbox}
                    label="HS Grads"
                    name="hsGrads"
                    checked={this.props.programs.modals.program.data.hsGrads}
                    onChange={(e:any, data:any) => this.props.editAdminProgramField('hsGrads', data.checked)}
                  />
                </Form.Group>
              </Form>
              <Divider />
              <Header as='h3' style={{marginTop:10}}>Default Class Template Options</Header>
              <Form style={{marginTop:20}}>
                <Grid>
                  <Grid.Column width={5}>
                    <Form.Group>
                      <Form.Field width={12} label="Display on transcript" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="transcript"
                          checked={this.props.programs.modals.program.data.transcript}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('transcript', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Include class on movement list" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="movementList"
                          checked={this.props.programs.modals.program.data.movementList}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('movementList', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Include class on signin list" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="signinList"
                          checked={this.props.programs.modals.program.data.signinList}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('signinList', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Meet on holiday" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="meetOnHoliday"
                          checked={this.props.programs.modals.program.data.meetOnHoliday}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('meetOnHoliday', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Meet on furlough day" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="meetOnFurloughDay"
                          checked={this.props.programs.modals.program.data.meetOnFurloughDay}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('meetOnFurloughDay', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Accumulate Hours" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="accumulateHours"
                          checked={this.props.programs.modals.program.data.accumulateHours}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('accumulateHours', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Form.Group>
                      <Form.Field width={10} label="Title 1 class" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="title1"
                          checked={this.props.programs.modals.program.data.title1}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('title1', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="ABE class" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="abe"
                          checked={this.props.programs.modals.program.data.abe}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('abe', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="DoE" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="doe"
                          checked={this.props.programs.modals.program.data.doe}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('doe', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="Online" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.program.data.online}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('online', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="Correspondence" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.program.data.correspondence}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('correspondence', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={10} label="No grade" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="noGrade"
                          checked={this.props.programs.modals.program.data.noGrade}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('noGrade', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Form.Group>
                      <Form.Field width={12} label="Workshop" />
                      <Form.Field>
                        <Checkbox
                          name='workshop'
                          checked={this.props.programs.modals.program.data.workshop}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('workshop', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Post Secondary" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.program.data.postSecondary}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('postSecondary', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Workforce Development Diploma" />
                      <Form.Field>
                        <Checkbox
                          name='online'
                          checked={this.props.programs.modals.program.data.workforceDiploma}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('workforceDiploma', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Ignore diploma/HSE requirement" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="ignoreEdStatus"
                          checked={this.props.programs.modals.program.data.ignoreEdStatus}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('ignoreEdStatus', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="Ignore hours requirement" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="ignoreHoursRqt"
                          checked={this.props.programs.modals.program.data.ignoreHoursRqt}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('ignoreHoursRqt', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Group>
                      <Form.Field width={12} label="No attendance" />
                      <Form.Field width={2}>
                        <Checkbox
                          name="noAttendance"
                          checked={this.props.programs.modals.program.data.noAttendance}
                          onChange={(e:any, data:any) => this.props.editAdminProgramField('noAttendance', data.checked as boolean)}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                </Grid>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleProgramModal}>Close</Button>
              <Button color='green' onClick={_.debounce(this.props.saveAdminProgram, 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { programs: state.admin.programs, tab: state.drawer.tab, mode: state.panes.mode } },
  { ...AdminStore.actionCreators, ...PanesStore.actionCreators, ...DrawerStore.actionCreators }
)(Programs as any)