import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import * as DrawerStore from '../../store/reducers/drawer'
import * as PanesStore from '../../store/reducers/panes'
import {
  Loader,
  Table,
  Icon,
  Popup,
  Label,
  Form,
  Input,
  Select,
  Button
} from 'semantic-ui-react'
const _ = require('underscore')

type StaffProps =
    { staff: any, options: any, filters: any, tab: string, mode: string } &
    typeof AdminStore.actionCreators &
    typeof DrawerStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class StaffList extends React.PureComponent<StaffProps> {
    public componentDidMount() {
        this.props.fetchStaffList()
    }

    public render() {
      const getStaff = () => {
          var staff = this.props.staff.list
          if (this.props.filters.lastName.length > 0) { staff = _.filter(staff, (s:any) => s.lastName.toLowerCase().includes(this.props.filters.lastName.toLowerCase())) }
          if (this.props.filters.firstName.length > 0) { staff = _.filter(staff, (s:any) => s.firstName.toLowerCase().includes(this.props.filters.firstName.toLowerCase())) }
          if (this.props.filters.nickname.length > 0) { staff = _.filter(staff, (s:any) => s.nickname != null && s.nickname.toLowerCase().includes(this.props.filters.nickname.toLowerCase())) }
          if (this.props.filters.facility.length > 0) { staff = _.filter(staff, (s:any) => s.facilities.includes(this.props.filters.facility)) }
          if (this.props.filters.department) {
            staff = _.map(staff, (s:any) => Object.assign(_.clone(s), { titleOpts: _.filter(s.titleOpts, (title:any) => title.departmentID == this.props.filters.department) }))
            staff = _.filter(staff, (s:any) => s.titleOpts.length > 0)
          }
          if (this.props.filters.position) { staff = _.filter(staff, (s:any) => _.any(s.titleOpts, (title:any) => title.titleID == this.props.filters.position)) }
          staff = _.filter(staff, (s:any) => s.deleted == !this.props.filters.active)
          if (this.props.filters.department && this.props.filters.departmentPreset) {
            var presets = _.filter(this.props.staff.presets, (preset:any) => preset.departmentID == this.props.filters.department && preset.name == this.props.filters.departmentPreset)
            staff = _.filter(staff, (s:any) => {
              return _.all(presets, (preset:any) => {
                switch (preset.departmentPermission) {
                case "del":
                  return _.any(s.departmentPermissions, (p:any) => p.departmentID == this.props.filters.department && p.subsystem == preset.subsystem && p.module == preset.module && ['del'].includes(p.permission))
                case "add":
                  return _.any(s.departmentPermissions, (p:any) => p.departmentID == this.props.filters.department && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add'].includes(p.permission))
                case "edit":
                  return _.any(s.departmentPermissions, (p:any) => p.departmentID == this.props.filters.department && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add','edit'].includes(p.permission))
                case "view":
                  return _.any(s.departmentPermissions, (p:any) => p.departmentID == this.props.filters.department && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add','edit','view'].includes(p.permission))
                case "none":
                  return true
                default:
                  return false
                }
              })
            })
          }
          if (this.props.filters.facility.length > 0 && this.props.filters.department && this.props.filters.facilityPreset) {
            var presets = _.filter(this.props.staff.presets, (preset:any) => preset.departmentID == this.props.filters.department && preset.name == this.props.filters.facilityPreset)
            staff = _.filter(staff, (s:any) => {
              return _.all(presets, (preset:any) => {
                switch (preset.facilityPermission) {
                case "del":
                  return _.any(s.facilityPermissions, (p:any) => p.facilityAbbr == this.props.filters.facility && p.subsystem == preset.subsystem && p.module == preset.module && ['del'].includes(p.permission))
                case "add":
                  return _.any(s.facilityPermissions, (p:any) => p.facilityAbbr == this.props.filters.facility && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add'].includes(p.permission))
                case "edit":
                  return _.any(s.facilityPermissions, (p:any) => p.facilityAbbr == this.props.filters.facility && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add','edit'].includes(p.permission))
                case "view":
                  return _.any(s.facilityPermissions, (p:any) => p.facilityAbbr == this.props.filters.facility && p.subsystem == preset.subsystem && p.module == preset.module && ['del','add','edit','view'].includes(p.permission))
                case "none":
                  return true
                default:
                  return false
                }
              })
            })
          }
          return staff
      }

      return (
        <div style={{overflow:'auto'}}>
          <Form>
            <Form.Group>
              <Form.Field
                control={Input}
                placeholder='Last name'
                value={this.props.filters.lastName}
                onChange={(e:any, data:any) => this.props.updateStaffFilter('lastName', data.value.validate())}
                width={2}
              />
              <Form.Field
                control={Input}
                placeholder='First name'
                value={this.props.filters.firstName}
                onChange={(e:any, data:any) => this.props.updateStaffFilter('firstName', data.value.validate())}
                width={2}
              />
              <Form.Field
                control={Input}
                placeholder='Nickname'
                value={this.props.filters.nickname}
                onChange={(e:any, data:any) => this.props.updateStaffFilter('nickname', data.value.validate())}
                width={2}
              />
              <Form.Field
                control={Select}
                placeholder='Facility'
                options={[{key:0,value:'',text:''}].concat(_.map(this.props.options.allFacilities, (f:any) => { return { key: f.text, value: f.text, text: f.text }}))}
                value={this.props.filters.facility.length > 0 ? this.props.filters.facility : null}
                onChange={(e:any, data:any) => this.props.updateStaffFilter('facility', data.value as number)}
                width={2}
              />
              <Form.Field
                control={Select}
                placeholder='Department'
                options={[{key:0,value:null,text:''}].concat(this.props.options.programs ? this.props.options.programs : [])}
                value={this.props.filters.department}
                onChange={(e:any, data:any) => this.props.updateStaffFilter('department', data.value)}
                width={2}
              />
              <Form.Field
                control={Select}
                placeholder='Position'
                options={[{key:0,value:null,text:''}].concat(this.props.options.titles ? _.filter(this.props.options.titles, (title:any) => this.props.filters.department ? title.department == this.props.filters.department : true) : [])}
                value={this.props.filters.position}
                onChange={(e:any, data:any) => this.props.updateStaffFilter('position', data.value)}
                width={2}
              />
              <Form.Field
                control={Select}
                placeholder='Active'
                options={[{key:0,value:true,text:'Active'},{key:1,value:false,text:'Archived'}]}
                value={this.props.filters.active}
                onChange={(e:any, data:any) => this.props.updateStaffFilter('active', data.value as boolean)}
                width={2}
              />
              <Button color='blue' content='Reset Filters' onClick={this.props.resetFilters} style={{marginLeft:5}} />
            </Form.Group>
            {this.props.filters.facility.length > 0 || this.props.filters.department ? <Form.Group>
              <div style={{marginLeft:464,width:150}}>
                {this.props.filters.facility.length > 0 && this.props.filters.department ? <Form.Field
                  control={Select}
                  placeholder='Permissions'
                  options={[{key:0,value:null,text:''}].concat(_.filter(this.props.options.presets, (preset:any) => preset.department == this.props.filters.department))}
                  value={this.props.filters.facilityPreset}
                  onChange={(e:any, data:any) => this.props.updateStaffFilter('facilityPreset', data.value)}
                  width={2}
                /> : null}
              </div>
              <div style={{marginLeft:13,width:150}}>
                {this.props.filters.department ? <Form.Field
                  control={Select}
                  placeholder='Permissions'
                  options={[{key:0,value:null,text:''}].concat(_.filter(this.props.options.presets, (preset:any) => preset.department == this.props.filters.department))}
                  value={this.props.filters.departmentPreset}
                  onChange={(e:any, data:any) => this.props.updateStaffFilter('departmentPreset', data.value)}
                  width={2}
                /> : null}
              </div>
            </Form.Group> : null}
          </Form>
          <Table compact celled striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Staff ID</Table.HeaderCell>
                <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Staff Name</Table.HeaderCell>
                <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Alerts</Table.HeaderCell>
                <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Facilities</Table.HeaderCell>
                <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}>Positions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getStaff().length > 0 ? null : <Table.Row>
                <Table.Cell warning colSpan={5}><Loader active style={{position:'relative',marginTop:30}} /></Table.Cell>
              </Table.Row>}
              {getStaff().map((staff:any) => (
                <Table.Row key={staff.recordID}>
                  <Table.Cell>{staff.recordID}</Table.Cell>
                  <Table.Cell>
                    {this.props.mode == 'dual' ? <Popup
                      hideOnScroll
                      on='click'
                      trigger={<div className='link' style={{whiteSpace:'normal'}}>{staff.name}</div>}
                    >
                      <Popup.Header>Open in pane</Popup.Header>
                      <Popup.Content>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchStaff(1, staff.recordID) }}>1</Label>
                        <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchStaff(2, staff.recordID) }}>2</Label>
                      </Popup.Content>
                    </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchStaff(this.props.mode == 'left' ? 1 : 2, staff.recordID) }}>{staff.name}</div>}
                  </Table.Cell>
                  <Table.Cell>
                    {staff.expDate && new Date(staff.expDate) <= new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) ? <Popup content="Expiring soon" position="top center" trigger={<Icon name="calendar times" color="red" />} /> : null}
                  </Table.Cell>
                  <Table.Cell><div style={{whiteSpace:'normal'}}>{_.map(staff.titleOpts, (title:any, t:number) => <div key={t}>{title.facilityAbbr}</div>)}</div></Table.Cell>
                  <Table.Cell><div style={{whiteSpace:'normal'}}>{_.map(staff.titleOpts, (title:any, t:number) => <div key={t}>{title.title}</div>)}</div></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { staff: state.admin.staff, options: state.admin.staff.options, filters: state.admin.staff.filters, tab: state.drawer.tab, mode: state.panes.mode } },
  { ...DrawerStore.actionCreators, ...AdminStore.actionCreators, ...PanesStore.actionCreators }
)(StaffList as any)