import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as CalendarStore from '../../store/reducers/calendar'
import * as PanesStore from '../../store/reducers/panes'
import { format, compareAsc, startOfMonth, endOfMonth, addDays, subDays } from 'date-fns'
import { Popup, Table, Button } from 'semantic-ui-react'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  EllipsisHorizontalIcon
} from '@heroicons/react/20/solid'
const tinycolor = require('tinycolor2')
const _ = require('underscore')

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

type ClassProps =
    { inmate: number, num: 1 | 2, mode: string } &
    CalendarStore.CalendarState &
    typeof CalendarStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class Calendar extends React.PureComponent<ClassProps> {
    public componentDidMount() {
			this.props.fetchInmateSessions(this.props.inmate)
    }

    private shadeColor = (color: string, decimal: number) => {
      const base = color.startsWith('#') ? 1 : 0;
  
      let r = parseInt(color.substring(base, 3), 16);
      let g = parseInt(color.substring(base + 2, 5), 16);
      let b = parseInt(color.substring(base + 4, 7), 16);
  
      r = Math.round(r / decimal);
      g = Math.round(g / decimal);
      b = Math.round(b / decimal);
  
      r = (r < 255)? r : 255;
      g = (g < 255)? g : 255;
      b = (b < 255)? b : 255;
  
      const rr = ((r.toString(16).length === 1)? `0${r.toString(16)}` : r.toString(16));
      const gg = ((g.toString(16).length === 1)? `0${g.toString(16)}` : g.toString(16));
      const bb = ((b.toString(16).length === 1)? `0${b.toString(16)}` : b.toString(16));
  
      return `#${rr}${gg}${bb}`;
    }

    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

		private month = (date:Date) => {
			switch(date.getMonth()) {
				case 0: {
					return 'January'
				}
				case 1: {
					return 'February'
				}
				case 2: {
					return 'March'
				}
				case 3: {
					return 'April'
				}
				case 4: {
					return 'May'
				}
				case 5: {
					return 'June'
				}
				case 6: {
					return 'July'
				}
				case 7: {
					return 'August'
				}
				case 8: {
					return 'September'
				}
				case 9: {
					return 'October'
				}
				case 10: {
					return 'November'
				}
				case 11: {
					return 'December'
				}
				default: {
					return ''
				}
			}
		}

		private dayOfWeek = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public render() {
			const today = new Date(new Date().toDateString())
      let days: any[] = []
			let day = startOfMonth(this.props.date)
			while (day.getDay() != 0) { day = subDays(day, 1) }
			while (day <= endOfMonth(this.props.date)) { days.push({ date: day }); day = addDays(day, 1); }
			while (day.getDay() != 0) { day = addDays(day, 1); days.push({ date: day }); }

			_.each(days, (day:any) => {
				day.sessions = _.filter(this.props.sessions, (session:any) => compareAsc(day.date, new Date(session.date)) == 0)
			});

			const classes = _.sortBy(_.uniq(this.props.sessions, (session:any) => session.classID), 'className')

      return (
				<Fragment>
          <style>
            {_.map(this.props.colors, (color:string, id:number) => {
              return `.color-${color.replace(/[^0-9a-z]/g, '')}:hover { background-color: ${this.shadeColor(color, 1.2)} !important }`
            })}
          </style>
					<div className='flex gap-x-10'>
						{_.map(classes, (klass:any) => <div key={klass.classID} className='mb-3 font-bold'>
							<span className='inline-block rounded-xl w-[10px] h-[10px] mr-[4px] mt-[3px]' style={{backgroundColor: this.props.colors[klass.classID]}} />
							<Popup
                hideOnScroll
                position='bottom left'
                on='click'
                trigger={<a href='#' onClick={(e:any) => e.preventDefault()}>{klass.className} - {klass.classID}</a>}
                style={{overflowY:'auto',maxHeight:500}}
                basic
              >
                <Popup.Content>
                  <Table compact celled striped definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>Class</Table.Cell>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.className} - {klass.classID}</div></Table.Cell>
                      </Table.Row>
                      {klass.instructorName ? <Table.Row>
                        <Table.Cell>Instructor</Table.Cell>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.instructorName}</div></Table.Cell>
                      </Table.Row> : null}
                      <Table.Row>
                        <Table.Cell>Dates</Table.Cell>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.dateBegins} - {klass.dateEnds}</div></Table.Cell>
                      </Table.Row>
                      {klass.hiatusBegins ? <Table.Row>
                        <Table.Cell>Hiatus</Table.Cell>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.hiatusBegins} - {klass.hiatusEnds}</div></Table.Cell>
                      </Table.Row> : null}
                      {klass.fundingSource ? <Table.Row>
                        <Table.Cell>Funding</Table.Cell>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.fundingSource}</div></Table.Cell>
                      </Table.Row> : null}
                      {klass.schedules.length > 0 ? <Table.Row>
                        <Table.Cell>Schedule</Table.Cell>
                        <Table.Cell>{_.map(klass.schedules, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}><span style={{display:'inline-block',width:32}}>{this.dayOfWeek3(schedule.dayOfWeek)}.</span> {schedule.startTime} - {schedule.endTime} / {schedule.location}{schedule.instructor ? ` / ${schedule.instructor}` : null}</div> })}</Table.Cell>
                      </Table.Row> : null}
                    </Table.Body>
                  </Table>
                  {klass.rosters.length > 0 ? <Table compact celled striped style={{marginBottom:20}}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>SID</Table.HeaderCell>
                        <Table.HeaderCell>Housing</Table.HeaderCell>
                        <Table.HeaderCell>Attendance</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {_.map(klass.rosters, (roster:any) => <Table.Row key={roster.recordID}>
                        <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{roster.lastName}, {roster.firstName}</div></Table.Cell>
                        <Table.Cell>{roster.sid}</Table.Cell>
                        <Table.Cell>{roster.housing}</Table.Cell>
                        <Table.Cell>{roster.percentAttended}</Table.Cell>
                      </Table.Row>)}
                    </Table.Body>
                  </Table> : null}
                  {this.props.mode == 'dual' ? <div className='flex justify-between'>
                    <Button size='mini' color='blue' content='Open in Pane 1' onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleInmateCalModal(this.props.num); this.props.fetchClass(1, klass.classID) }} />
                    <Button size='mini' color='blue' content='Open in Pane 2' onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleInmateCalModal(this.props.num); this.props.fetchClass(2, klass.classID) }} />
                  </div> : <div className='flex justify-between'>
                    <Button size='mini' color='blue' content='Open' onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleInmateCalModal(this.props.num); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, klass.classID) }} />
                  </div>}
                </Popup.Content>
              </Popup>
						</div>)}
					</div>
					<div className="lg:flex lg:h-full lg:flex-col border rounded border-gray-300">
						<header className="flex items-center justify-between bg-gray-50 border-b border-gray-200 py-4 px-6 lg:flex-none">
							<h1 className="text-lg font-semibold text-gray-900">
								<time>{this.month(this.props.date)} {this.props.date.getFullYear()}</time>
							</h1>
							<div className="flex items-center">
								<div className="flex items-center rounded-md shadow-sm md:items-stretch">
									<button
										type="button"
										className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
									>
										<ChevronLeftIcon className="h-5 w-5" onClick={() => this.props.prevMonth(this.props.inmate)} />
									</button>
									<button
										type="button"
										className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-[12px] font-bold text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
										onClick={() => this.props.today(this.props.inmate)}
									>
										Today
									</button>
									<span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
									<button
										type="button"
										className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
									>
										<ChevronRightIcon className="h-5 w-5"  onClick={() => this.props.nextMonth(this.props.inmate)} />
									</button>
								</div>
							</div>
						</header>
						<div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
							<div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-[12px] font-semibold leading-6 text-gray-700 lg:flex-none">
								<div className="bg-white py-2">
									S<span className="sr-only sm:not-sr-only">un</span>
								</div>
								<div className="bg-white py-2">
									M<span className="sr-only sm:not-sr-only">on</span>
								</div>
								<div className="bg-white py-2">
									T<span className="sr-only sm:not-sr-only">ue</span>
								</div>
								<div className="bg-white py-2">
									W<span className="sr-only sm:not-sr-only">ed</span>
								</div>
								<div className="bg-white py-2">
									T<span className="sr-only sm:not-sr-only">hu</span>
								</div>
								<div className="bg-white py-2">
									F<span className="sr-only sm:not-sr-only">ri</span>
								</div>
								<div className="bg-white py-2">
									S<span className="sr-only sm:not-sr-only">at</span>
								</div>
							</div>
							<div className="flex bg-gray-200 text-[12px] leading-6 text-gray-700 lg:flex-auto">
								<div className={`hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-${days.length / 7} lg:gap-px`}>
									{days.map((day:any, i:number) => (
										<div
											key={i}
											className={classNames(
												day.date >= startOfMonth(this.props.date) && day.date <= endOfMonth(this.props.date) ? 'bg-white' : 'bg-gray-50 text-gray-500',
												'relative py-2 px-3 min-h-[80px]'
											)}
										>
											<time
												className={
													compareAsc(day.date, today) == 0
														? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white'
														: undefined
												}
											>
												{day.date.getDate()}
											</time>
											{day.sessions.length > 0 && (
												<ol className="mt-2">
													{day.sessions.map((session:any, s:number) => (
														<Popup key={s} position='top center' on='click' trigger={<li>
															<a href='#' className={`rounded group flex mb-2 pr-2 pl-2 pt-[3px] color-${this.props.colors[session.classID].replace(/[^0-9a-z]/g, '')}`} style={{backgroundColor: this.props.colors[session.classID], color: tinycolor.mostReadable(this.props.colors[session.classID], ['#fff', '#000']).toHexString()}} onClick={(e:any) => e.preventDefault()}>
																<p className={`flex-auto truncate font-bold`}>
																	{session.className}
																</p>
																<time
																	dateTime={session.date}
																	className="ml-3 hidden flex-none xl:block"
																>
																	{session.startTime}
																</time>
															</a>
														</li>}>
															<Popup.Content>
																<Table compact celled striped definition>
																	<Table.Body>
																		<Table.Row>
																			<Table.Cell>Class</Table.Cell>
																			<Table.Cell><div style={{whiteSpace: 'nowrap'}}>{session.className} - {session.classID}</div></Table.Cell>
																		</Table.Row>
																		<Table.Row>
																			<Table.Cell>Time</Table.Cell>
																			<Table.Cell>{this.dayOfWeek(new Date(session.date).getDay())}. {session.startTime} - {session.endTime}</Table.Cell>
																		</Table.Row>
																		{session.log ? <Table.Row>
																			<Table.Cell>Attendance</Table.Cell>
																			<Table.Cell>{session.log} -- {session.logDescription}</Table.Cell>
																		</Table.Row> : null}
																	</Table.Body>
																</Table>
                                <div className='flex justify-between'>
                                  <Button size='mini' color='blue' content='Open in Pane 1' onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleInmateCalModal(this.props.num); this.props.fetchClass(1, session.classID) }} />
                                  <Button size='mini' color='blue' content='Open in Pane 2' onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleInmateCalModal(this.props.num); this.props.fetchClass(2, session.classID) }} />
                                </div>
															</Popup.Content>
														</Popup>
													))}
												</ol>
											)}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
				</Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { ...state.calendar, inmate: ownProps.inmate, num: ownProps.num, mode: ownProps.mode } },
  { ...CalendarStore.actionCreators, ...PanesStore.actionCreators }
)(Calendar as any)