import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import { Editor, Block, addNewBlock, ImageSideButton } from 'medium-draft'
import axios from 'axios'
import {
  Container,
  Form,
  Button,
  Input,
  Select,
  Divider
} from 'semantic-ui-react'
const _ = require('underscore')

class ImageUploadButton extends ImageSideButton {
  async onChange(e:any) {
    const file = e.target.files[0]
    if (file.type.indexOf('image/') === 0) {
      const data = new FormData()
      data.append('image', file)
      const response = await axios.put('/admin/upload_image', data, { headers: { 'Content-Type': 'multipart/form-data' } })
      const { success, fileName } = response.data
      if (success) {
        this.props.setEditorState(addNewBlock(
          this.props.getEditorState(),
          Block.IMAGE, { src: `https://sms.psd-hi.com/assets/images/${fileName}` }
        ))
      }
    }
    this.props.close();
  }
}

type SupportProps =
    { support: any } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class HelpGuides extends React.PureComponent<SupportProps> {
    public componentDidMount() {
        this.props.fetchCategories()
    }

    public render() {
      return (
        <Container fluid style={{overflowY:'auto',height:740,paddingRight:5,overflowX:'visible'}}>
          <Form>
            <Form.Group style={{marginRight:0}}>
              <Form.Field
                control={Select}
                placeholder="Category"
                options={this.props.support.options.categories}
                value={this.props.support.category}
                onChange={(e:any, data:any) => this.props.selectCategory(data.value as number)}
              />
              {this.props.support.category ? <Button negative size='mini' content="Remove Category" onClick={this.props.removeCategory} /> : null}
              <Form.Field
                control={Input}
                placeholder="New Category"
                value={this.props.support.data.newCategory||''}
                onChange={(e:any, data:any) => this.props.updateNewCategory(data.value.validate())}
              />
              {this.props.support.data.newCategory ? <Button positive size='mini' content="Add Category" onClick={this.props.addCategory} /> : null}
            </Form.Group>
            {this.props.support.category > 0 ? <Form.Group style={{marginRight:0}}>
              <Form.Field
                control={Select}
                placeholder="Guide"
                options={this.props.support.options.guides}
                value={this.props.support.guide}
                onChange={(e:any, data:any) => this.props.selectGuide(data.value as number)}
                width={8}
              />
              <Button positive size='mini' content="Add Guide" onClick={this.props.addGuide} />
            </Form.Group> : null}
          </Form>
          <Divider />
          {this.props.support.guide > 0 ? (
            <Form>
              <Form.Field
                control={Input}
                label="Title"
                name="title"
                value={this.props.support.data.title||''}
                onChange={(e:any, data:any) => this.props.updateGuide('title', data.value.validate())}
              />
              <Form.Field
                control={Select}
                label="Category"
                placeholder="Category"
                options={this.props.support.options.categories}
                value={this.props.support.data.categoryID}
                onChange={(e:any, data:any) => this.props.updateGuide('categoryID', data.value as number)}
              />
              <div style={{border:'thin solid #ddd',padding:1,borderRadius:5,marginBottom:12}}>
                <Editor placeholder="Guide Content" editorState={this.props.support.data.content} sideButtons={[{title: 'Image', component: ImageUploadButton}]} onChange={(state:any) => this.props.updateGuide('content', state)} />
              </div>
              <Button positive content="Save" onClick={this.props.saveGuide} />
              <Button negative content="Remove" onClick={this.props.removeGuide} />
            </Form>
          ) : null}
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { support: state.admin.support } },
  AdminStore.actionCreators
)(HelpGuides as any)