import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import Cleave from "cleave.js/react"
import {
  Container,
  Icon,
  Form,
  Button,
  Checkbox,
  Input,
  Select,
  Radio,
  Grid,
  Header,
  Message,
  Divider,
  Table,
  Popup,
  Label
} from 'semantic-ui-react'
const _ = require('underscore')

type PaneProps =
    { facility: PanesStore.Facility, num: 1 | 2, print: boolean, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class FacilityPane extends React.PureComponent<PaneProps> {
    public render() {
        return (
          <Container fluid>
            {this.props.print ? null : <div className='close' style={{position:'absolute',top:0,left:0,zIndex:1}}>
              <Button circular color='red' icon='close' size='mini' onClick={() => this.props.togglePane(this.props.num)} />
            </div>}
            <Button color='blue' circular icon='print' onClick={() => this.props.togglePrint(this.props.num)} style={{float:'right',fontSize:'1.2em',marginTop:15}} />
            {this.props.facility.info.deleted ? <Message warning compact header='Archived' style={{float:'right',marginRight:10}} /> : null}
            <Grid style={{marginBottom:10}}>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column>
                  <Header as='h2' style={{display:'inline-block',marginTop:0,width:750,whiteSpace:'normal'}}><div><Icon name='building' /> {this.props.facility.data.facility.name} <span style={{fontSize:'0.8em',color:'#ccc',marginLeft:10,verticalAlign:'middle'}}>{this.props.facility.info.recordID}</span></div></Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Header as='h3'>Info</Header>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='Facility name'
                  name='Facility name'
                  value={this.props.facility.info.name||''}
                  onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'name', data.value.validate())}
                  width={6}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Input}
                  label='Abbreviation'
                  name='Abbreviation'
                  value={this.props.facility.info.abbreviation||''}
                  onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'abbreviation', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                />
                <div className="four wide field">
                  <label>Facility Type</label>
                  <Checkbox
                    name="prison"
                    label="Prison"
                    checked={this.props.facility.info.prison||false}
                    onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'prison', data.checked)}
                    style={{marginRight:20,marginTop:10}}
                  />
                  <Checkbox
                    name="jail"
                    label="Jail"
                    checked={this.props.facility.info.jail||false}
                    onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'jail', data.checked)}
                  />
                </div>
                <div className="two wide field">
                  <label>Active</label>
                  <Checkbox
                    name="deleted"
                    label="Active"
                    checked={!this.props.facility.info.deleted}
                    onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'deleted', !data.checked)}
                    style={{marginRight:20,marginTop:10}}
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Select}
                  label='Funding source'
                  placeholder='Funding source'
                  options={this.props.facility.options.funding||[]}
                  value={this.props.facility.info.fundingSource}
                  onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'fundingSource', data.value)}
                  className={this.props.print ? 'transparent' : ''}
                />
                <div>
                  <label style={{fontWeight:700,width:100,marginLeft:10}}>Inmate Gender</label>
                  <div className="inline-fields" style={{marginTop:14,marginLeft:14}}>
                    <Form.Field
                      name="gender"
                      label="Male"
                      control={Radio}
                      checked={this.props.facility.info.sex === 'M'}
                      value="M"
                      onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'sex', data.value)}
                    />
                    <Form.Field
                      name="gender"
                      label="Female"
                      control={Radio}
                      checked={this.props.facility.info.sex === 'F'}
                      value="F"
                      onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'sex', data.value)}
                    />
                    <Form.Field
                      name="gender"
                      label="Both"
                      control={Radio}
                      checked={this.props.facility.info.sex === 'B'}
                      value="B"
                      onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'sex', data.value)}
                    />
                  </div>
                </div>
                <div className="four wide field">
                  <label>Enforce Minimum Class Size</label>
                  <Checkbox
                    name="enforceMinimumClassSize"
                    checked={this.props.facility.info.enforceMinimumClassSize||false}
                    onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'enforceMinimumClassSize', data.checked)}
                    style={{marginTop:10}}
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <div className='field'>
                  <label>Main Phone Number</label>
                  <Cleave
                    name='phone'
                    value={this.props.facility.info.phone||''}
                    onChange={(e:any) => this.props.editFacilityField(this.props.num, 'phone', e.target.value)}
                    options={{
                      blocks: [3,3,4],
                      delimiter: '-',
                      numericOnly: true
                    }}
                    className={this.props.print ? 'transparent' : ''}
                  />
                </div>
                <div className='field'>
                  <label>Fax number</label>
                  <Cleave
                    name='fax'
                    value={this.props.facility.info.fax||''}
                    onChange={(e:any) => this.props.editFacilityField(this.props.num, 'fax', e.target.value)}
                    options={{
                      blocks: [3,3,4],
                      delimiter: '-',
                      numericOnly: true
                    }}
                    className={this.props.print ? 'transparent' : ''}
                  />
                </div>
                <Form.Field
                  control={Input}
                  label="Appropriation Code"
                  name="appnCode"
                  value={this.props.facility.info.appnCode||''}
                  onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'appnCode', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Input}
                  label="Warden's Name"
                  name="warden"
                  value={this.props.facility.info.warden||''}
                  onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'warden', data.value.validate())}
                  width={6}
                  className={this.props.print ? 'transparent' : ''}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label="Address1"
                  name="address1"
                  value={this.props.facility.info.address1||''}
                  onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'address1', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Input}
                  label="Address2"
                  name="address2"
                  value={this.props.facility.info.address2||''}
                  onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'address2', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Input}
                  label="City, State Zip"
                  name="csz"
                  value={this.props.facility.info.csz||''}
                  onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'csz', data.value.validate())}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Select}
                  label='Timezone'
                  placeholder='Timezone'
                  options={[{key:0,value:'Pacific/Honolulu',text:'HST'},{key:1,value:'America/Phoenix',text:'MST'}]}
                  value={this.props.facility.info.zone}
                  onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'zone', data.value)}
                  className={this.props.print ? 'transparent' : ''}
                />
              </Form.Group>
              <Form.Group>
                <div className="four wide field">
                  <label>Paid Attendance</label>
                  <Checkbox
                    name="paidAttendance"
                    checked={this.props.facility.info.paidAttendance||false}
                    onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'paidAttendance', data.checked)}
                    style={{marginTop:10}}
                  />
                </div>
                <div className="five wide field">
                  <label>Include this facility in reporting</label>
                  <Checkbox
                    name="reportOn"
                    checked={this.props.facility.info.reportOn||false}
                    onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'reportOn', data.checked)}
                    style={{marginTop:10}}
                  />
                </div>
                <div className="seven wide field">
                  <label>Enable "Not Yet Assigned" for a GenEd assignment</label>
                  <Checkbox
                    name="notYetAssigned"
                    checked={this.props.facility.info.notYetAssigned||false}
                    onChange={(e:any, data:any) => this.props.editFacilityField(this.props.num, 'notYetAssigned', data.checked)}
                    style={{marginTop:10}}
                  />
                </div>
              </Form.Group>
              {this.props.print ? null : <Button positive content='Save' onClick={_.debounce(() => this.props.saveFacility(this.props.num), 10000, true)} />}
              {this.props.print ? null : <Button negative content='Remove' onClick={_.debounce(() => this.props.deleteFacility(this.props.num), 10000, true)} />}
            </Form>
            <Divider />
            <Header as='h3'>Facility Housing</Header>
            <Table compact celled striped selectable style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Housing</Table.HeaderCell>
                  <Table.HeaderCell>Abbreviation</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.facility.data.housing.map((housing:any) => (
                  <Table.Row key={housing.recordID}>
                    <Table.Cell><Input value={housing.name} style={{width:'100%'}} onChange={(e:any, data:any) => this.props.editHousing(this.props.num, housing.recordID, 'name', data.value.validate())} /></Table.Cell>
                    <Table.Cell><Input value={housing.abbreviation||''} style={{width:'100%'}} onChange={(e:any, data:any) => this.props.editHousing(this.props.num, housing.recordID, 'abbreviation', data.value.validate())} /></Table.Cell>
                    <Table.Cell>
                      {this.props.print ? null : <Button positive size='mini' content='Save' onClick={_.debounce(() => this.props.saveHousing(this.props.num, housing), 10000, true)} />}
                      {this.props.print ? null : <Button negative size='mini' content='Remove' onClick={_.debounce(() => this.props.removeHousing(this.props.num, housing.recordID), 10000, true)} />}
                    </Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell><Input value={this.props.facility.data.newHousing} placeholder='New Housing' style={{width:'100%'}} onChange={(e:any, data:any) => this.props.editNewHousing(this.props.num, data.value.validate())} /></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>{this.props.print ? null : <Button positive size='mini' content='Add' onClick={_.debounce(() => this.props.addHousing(this.props.num), 10000, true)} />}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Divider />
            <Header as='h3'>Class Locations</Header>
            <Table compact celled striped selectable style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Location</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.facility.data.locations.map((location:any) => (
                  <Table.Row key={location.recordID}>
                    <Table.Cell><Input value={location.name} style={{width:'100%'}} onChange={(e:any, data:any) => this.props.editLocation(this.props.num, location.recordID, 'name', data.value.validate())} /></Table.Cell>
                    <Table.Cell>
                      {this.props.print ? null : <Button positive size='mini' content='Save' onClick={_.debounce(() => this.props.saveLocation(this.props.num, location), 10000, true)} />}
                      {this.props.print ? null : <Button negative size='mini' content='Remove' onClick={_.debounce(() => this.props.removeLocation(this.props.num, location.recordID), 10000, true)} />}
                    </Table.Cell>
                  </Table.Row>
                ))}
                <Table.Row>
                  <Table.Cell><Input value={this.props.facility.data.newLocation} placeholder='New Location' style={{width:'100%'}} onChange={(e:any, data:any) => this.props.editNewLocation(this.props.num, data.value.validate())} /></Table.Cell>
                  <Table.Cell>{this.props.print ? null : <Button positive size='mini' content='Add' onClick={_.debounce(() => this.props.addLocation(this.props.num), 10000, true)} />}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Divider />
            <Header as='h3'>Directory</Header>
            <Table compact celled striped selectable style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Staff Name</Table.HeaderCell>
                  <Table.HeaderCell>Position</Table.HeaderCell>
                  <Table.HeaderCell>Phone</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Notifications</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.facility.data.staff.map((staff:any) => (
                  <Table.Row key={staff.recordID}>
                    <Table.Cell>
                      {this.props.mode == 'dual' ? <Popup
                        hideOnScroll
                        on='click'
                        trigger={<div className='link' style={{whiteSpace:'normal'}}>{staff.name}</div>}
                      >
                        <Popup.Header>Open in pane</Popup.Header>
                        <Popup.Content>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchStaff(1, staff.recordID) }}>1</Label>
                          <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchStaff(2, staff.recordID) }}>2</Label>
                        </Popup.Content>
                      </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.fetchStaff(this.props.mode == 'left' ? 1 : 2, staff.recordID) }}>{staff.name}</div>}
                    </Table.Cell>
                    <Table.Cell style={{lineHeight:'15px'}}>{staff.titleNames.map((title:string, i:number) => <div key={i} style={{whiteSpace:'normal'}}>{title}</div>)}</Table.Cell>
                    <Table.Cell>{staff.phone}</Table.Cell>
                    <Table.Cell>{staff.email}</Table.Cell>
                    <Table.Cell>
                      <Form.Field
                        control={Select}
                        placeholder='Email notifications'
                        options={this.props.facility.options.notifications||[]}
                        value={staff.notifications}
                        onChange={(e:any, data:any) => this.props.updateNotifications(this.props.num, staff.recordID, data.value)}
                        multiple
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { facility: ownProps.facility, num: ownProps.num, print: ownProps.options.print, mode: state.panes.mode } },
  PanesStore.actionCreators
)(FacilityPane as any)