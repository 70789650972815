import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as ClassesStore from '../../store/reducers/classes'
import FormInput from './FormInput'
import FormSelect from './FormSelect'
import FormDatePicker from './FormDatePicker'
import {
  Button,
  Form
} from 'semantic-ui-react'
const _ = require('underscore')

type FilterProps =
    { open: boolean, options: any, data: any, filters: any, facility: string } &
    typeof ClassesStore.actionCreators &
    WithRouterProps<{}>

class ClassFilters extends React.PureComponent<FilterProps> {
    public componentWillMount() {
      this.props.loadFilters()
    }

    public render() {
        if (this.props.open) {
            return (
              <Form style={{width:'100%'}} id='filters'>
                <Form.Group>
                  <FormInput name='className' label='Class name' placeholder='Class name' width={2} />
                  <FormInput name='classID' label='Class ID' placeholder='Class ID' width={2} />
                  <FormSelect name='facility' label='Facility' placeholder='Facility' width={3} fluid search
                    options={[{ key: 'all', value: 'all', text: 'All facilities' },{ key: 'my', value: 'my', text: 'My facilities' }].concat(this.props.options.facilities)} />
                  <FormSelect name='instructor' label='Instructor' placeholder='Instructor' width={3} fluid search
                    options={[{key:0,value:null,text:'All instructors'}].concat(this.props.options.instructors)} />
                  <FormSelect name='program' label='Program' placeholder='Program' width={3} fluid search
                    options={[{key:0,value:null,text:'All programs'}].concat(this.props.options.programs)} />
                  <FormSelect name='funding' label='Funding' placeholder='Funding' width={3} fluid search
                    options={[{key:0,value:null,text:'All funding'}].concat(this.props.options.funding)} />
                </Form.Group>
                <Form.Group>
                  <FormSelect name='classStatus' label='Class status' placeholder='Class status' width={2} fluid
                      options={[{key:0,value:'active',text:'Active'},{key:1,value:'archived',text:'Archived'}]} />
                  <FormSelect name='gender' label='Gender' placeholder='Gender' width={2} fluid
                    options={this.props.options.genders} />
                  <FormDatePicker name='activeOn' label='Active on' width={2} />
                  <FormSelect name='days' label='Days' placeholder='Days' width={6} fluid multiple
                    options={this.props.options.days} />
                  <Form.Field
                    control={Button}
                    color='teal'
                    content='Show dates'
                    onClick={this.props.toggleDates}
                    width={2}
                    style={{marginTop:18}}
                  />
                  <Form.Field
                    control={Button}
                    color='blue'
                    content='Reset filters'
                    onClick={this.props.resetFilters}
                    width={2}
                    style={{marginTop:18}}
                  />
                </Form.Group>
              </Form>
            )
        } else {
            return (
              <Fragment></Fragment>
            )
        }
    }
}

export default connect(
  (state: ApplicationState) => { return { options: state.classes.options, open: state.classes.open, data: state.classes.data, facility: state.classes.filters.facility, filters: state.classes.filters } },
  ClassesStore.actionCreators
)(ClassFilters as any)