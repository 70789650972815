import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { renderToString } from 'react-dom/server'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import { pdfshift } from '../../../agent'
import * as PanesStore from '../../../store/reducers/panes'
import EducationalHistory from '../shift/EducationalHistory'
import Footer from '../shift/NumFooter'
import {
  Icon
} from 'semantic-ui-react'

const css = require("!!css-loader!../../../custom.css");

type ReportProps =
    { num: 1 | 2, report: any, options: any, settings: any } &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class EducationalHistoryPDF extends React.PureComponent<ReportProps> {
    public componentDidUpdate(prevProps:any) {
      if (!prevProps.report.ready && this.props.report.ready) {
        const pdf = renderToString(<EducationalHistory report={this.props.report} options={this.props.options} settings={this.props.settings} />)
        const footer = renderToString(<Footer report={this.props.report} options={this.props.options} settings={this.props.settings} />)

        pdfshift({
            source: pdf,
            footer: {
              source: footer,
              height: 70
            },
            margin: {
              top: 30,
              bottom: 0,
              left: 30,
              right: 30
            },
            css: css.default.toString(),
            format: 'Letter',
            wait_for: 'ready',
            filename: 'edhistory.pdf'
        }).then((response:any) => {
          this.props.setInmateURL(this.props.num, 'edhistory', response.data.url)
        })
      }
    }

    public render() {
        return (
          <Fragment>
            {this.props.report.generating || (this.props.report.ready && this.props.report.url.length == 0) ? <div className='pdf generating' style={{display:'inline-block',verticalAlign:'top',marginLeft:10}}>
              <Icon.Group>
                <Icon name='circle notch' color='grey' loading style={{fontSize:'2.5em'}} />
                <Icon name='file pdf outline' color='grey' style={{fontSize:'1.4em',marginTop:1,marginLeft:-1}} />
              </Icon.Group>
            </div> : null}
            {this.props.report.ready && this.props.report.url.length > 0 ? <a target='_window' href={this.props.report.url} style={{marginLeft:10}}>
              <Icon name='file pdf outline' size='big' />
            </a> : null}
          </Fragment>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { report: ownProps.report, options: ownProps.options, num: ownProps.num, settings: state.staff.settings } },
  PanesStore.actionCreators
)(EducationalHistoryPDF as any)