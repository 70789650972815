import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import StaffList from './StaffList'
import StaffActivity from './StaffActivity'
import StaffAdd from './StaffAdd'
import {
  Container,
  Tab,
  Form,
  Input,
  Select
} from 'semantic-ui-react'
const _ = require('underscore')

type StaffProps =
    { staff: any, admin: boolean } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class StaffManagement extends React.PureComponent<StaffProps> {
    public componentDidMount() {
        this.props.fetchStaffFacilities()
    }

    public render() {
      var panes = []

      if (this.props.admin) {
        panes = [
          { menuItem: 'Staff', render: () => <Tab.Pane><StaffList /></Tab.Pane> },
          { menuItem: 'Activity', render: () => <Tab.Pane><StaffActivity /></Tab.Pane> },
          { menuItem: 'Add', render: () => <Tab.Pane><StaffAdd /></Tab.Pane> }
        ]
      } else {
        panes = [
          { menuItem: 'Staff', render: () => <Tab.Pane><StaffList /></Tab.Pane> },
          { menuItem: 'Add', render: () => <Tab.Pane><StaffAdd /></Tab.Pane> }
        ]
      }

      return (
        <Container fluid>
          <Tab menu={{ secondary: true }} panes={panes} activeIndex={this.props.staff.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchStaffTab(activeIndex) }} />
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { staff: state.admin.staff, admin: state.staff.staff!.admin } },
  AdminStore.actionCreators
)(StaffManagement as any)