import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store'
import * as ReportsStore from '../../store/reducers/reports'
import { Editor } from 'medium-draft'
import mediumDraftExporter from 'medium-draft/lib/exporter';
import {
  Header,
  Form,
  Button,
  Select,
  Divider,
  Input,
  Checkbox
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { admin: boolean } &
    ReportsStore.ReportsState &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class EdHistory extends React.PureComponent<ReportProps> {
    public params() {
      let params = new URLSearchParams()
      for (let [key, value] of Object.entries(this.props.reports.edhistory.data)) {
        if (value != undefined && value != null && value != '') {
            if (typeof value == 'object') {
                for (let ovalue of Object.values(value as any)) {
                  params.append(key, ovalue as string)
                }
            } else {
                params.append(key, (value as any).toString())
            }
        }
      }
      return params.toString()
    }

    public render() {
      return (
        <Fragment>
          <Header as="h2">Educational History Report</Header>
          {this.props.admin ? <Fragment>
            <div style={{border:'thin solid #ddd',padding:1,borderRadius:5,marginBottom:12}}>
              <Editor placeholder="Enter a report description..." editorState={this.props.reports.edhistory.description} sideButtons={[]} onChange={(state:any) => this.props.updateDescription(state)} />
            </div>
            <Button positive content='Save' onClick={this.props.saveDescription} />
          </Fragment> : null}
          {this.props.admin ? null : <div style={{whiteSpace:'normal'}} dangerouslySetInnerHTML={{__html: mediumDraftExporter(this.props.reports.edhistory.description.getCurrentContent())}} />}
          <Divider />
          <Form>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Display type'
                placeholder='Display type'
                options={[{key:0,value:'pdf',text:'PDF'}]}
                value={this.props.reports.edhistory.data.displayType}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'displayType', data.value)}
                width={4}
              />
              <Form.Field
                control={Select}
                label='Items to print'
                placeholder='Items to print'
                options={[{ key: 0, value: "all", text: "Print all" },{ key: 1, value: "intake", text: "Intake info" },{ key: 2, value: "incareration", text: "Incarceration info" },{ key: 3, value: "education", text: "Educational history" },{ key: 4, value: "employment", text: "Employment history" },{ key: 5, value: "community", text: "Community involvement" },{ key: 6, value: "health", text: "Health history" },{ key: 7, value: "recommendations", text: "Recommended programs" },{ key: 8, value: "classes", text: "Current classes" },{ key: 9, value: "transcript", text: "Transcript" },{ key: 10, value: "ged", text: "GED tests" },{ key: 11, value: "hiset", text: "HiSET tests" },{ key: 12, value: "tabe", text: "TABE tests" },{ key: 13, value: "domains", text: "TABE domains" }]}
                value={this.props.reports.edhistory.data.info || []}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'info', data.value as string[])}
                width={12}
                multiple
              />
            </Form.Group>
            <Form.Group>
              {(this.props.reports.edhistory.data.info||[]).includes('transcript') ? <Form.Field
                control={Select}
                label='Transcript options'
                placeholder='Transcript options'
                options={[{ key: 0, value: "completed", text: "Show ONLY completed classes" },{ key: 1, value: "transcript", text: "Show ONLY classes that appear on the transcript" },{ key: 2, value: "grades", text: "Show grades" }]}
                value={this.props.reports.edhistory.data.transcript || []}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'transcript', data.value as string[])}
                width={8}
                multiple
              /> : null}
              {(this.props.reports.edhistory.data.info||[]).includes('tabe') ? <Form.Field
                control={Select}
                label='TABE options'
                placeholder='TABE options'
                options={[{ key: 0, value: "all", text: "All TABE tests" },{ key: 1, value: "last", text: "Last TABE test" }]}
                value={this.props.reports.edhistory.data.tabe || []}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'tabe', data.value as string[])}
                width={4}
              /> : null}
              {(this.props.reports.edhistory.data.info||[]).includes('tabe') ? <Form.Field
                control={Select}
                label='Include domains'
                placeholder='Include domains'
                options={[{ key: 0, value: true, text: "Yes" },{ key: 1, value: false, text: "No" }]}
                value={this.props.reports.edhistory.data.domains}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'domains', data.value as string[])}
                width={4}
              /> : null}
            </Form.Group>
            <div style={{marginBottom:6}}><b>Signatory</b></div>
            <Form.Group>
              <Form.Field
                control={Input}
                placeholder="Name"
                value={this.props.reports.edhistory.data.signatoryName || ''}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'signatoryName', data.value.validate())}
                width={3}
              />
              <Form.Field
                control={Input}
                placeholder="Title"
                value={this.props.reports.edhistory.data.signatoryTitle || ''}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'signatoryTitle', data.value.validate())}
                width={3}
              />
              <Form.Field
                control={Input}
                placeholder="Facility"
                value={this.props.reports.edhistory.data.signatoryFacility || ''}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'signatoryFacility', data.value.validate())}
                width={3}
              />
              <Form.Field
                control={Input}
                placeholder="Phone"
                value={this.props.reports.edhistory.data.signatoryPhone || ''}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'signatoryPhone', data.value.validate())}
                width={3}
              />
              <Form.Field
                control={Select}
                placeholder='Show signature block'
                options={[{ key: 0, value: true, text: "Yes" },{ key: 1, value: false, text: "No" }]}
                value={this.props.reports.edhistory.data.signature}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'signature', data.value as boolean)}
                width={3}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Inmates'
                placeholder='Inmates'
                options={this.props.options.inmates || []}
                value={this.props.reports.edhistory.data.inmates || []}
                searchQuery={this.props.reports.edhistory.data.inmate || ''}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'inmates', data.value as number[])}
                onSearchChange={(e:any, data:any) => this.props.searchUpdate(data.searchQuery as string)}
                loading={this.props.reports.edhistory.data.inmatesLoading}
                width={12}
                search
                multiple
              />
              <Checkbox label="Print empty fields" checked={this.props.reports.edhistory.data.empty} onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'empty', data.checked as boolean)} style={{marginTop:23,marginLeft:7}} />
            </Form.Group>
            {false ? <Form.Group>
              <Form.Field
                control={Select}
                label='Include administrative deletes'
                options={[{key:0,value:false,text:'No'},{key:1,value:true,text:'Yes'}]}
                value={this.props.reports.edhistory.data.adminDeletes}
                onChange={(e:any, data:any) => this.props.dataChange('edhistory', 'adminDeletes', data.value)}
                width={4}
              />
            </Form.Group> : null}
            <Button color='blue' content='Generate report' onClick={this.props.generateEdHistory} />
          </Form>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { ...state.reports, admin: state.staff.staff!.admin } },
  ReportsStore.actionCreators
)(EdHistory as any)
