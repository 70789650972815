import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import * as PanesStore from '../../store/reducers/panes'
import InmateFilters from './InmateFilters'
import UARow from './UARow'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Popup,
  Table,
  Dimmer,
  Loader
} from 'semantic-ui-react'
const _ = require('underscore')

type TableProps =
    { open: boolean, loading: boolean, hasMore: boolean, tab: string, sorts: any, filters: InmatesStore.InmateFilters, inmates: InmatesStore.Inmate[] } &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class UATable extends React.PureComponent<TableProps> {
    private HeaderArrow = (props:any) => {
      var sort = _.find(this.props.sorts, (sort:any) => sort.column == props.orderBy)
      if (sort != null) {
        if (sort.dir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    public render() {
      const HeaderArrow = this.HeaderArrow
      return (
        <Fragment>
          <InmateFilters />
          <div id='uas-table' style={{top:this.props.open?200:70}}>
            <Dimmer inverted active={this.props.loading}><Loader inverted content="Loading UAs ..." /></Dimmer>
            <InfiniteScroll
                pageStart={0}
                loadMore={this.props.fetchNextUAs}
                hasMore={!this.props.loading && this.props.hasMore}
                initialLoad={false}
                useWindow={false}
                getScrollParent={() => document.getElementById('uas-table') }
            >
              <Table compact celled striped style={{borderTop:0,marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Popup content="Click to sort inmates by Last Name" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('name')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Inmate Name<HeaderArrow orderBy='name' /></div></Table.HeaderCell>} />
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>SID</div></Table.HeaderCell>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Facility</div></Table.HeaderCell>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>UA Date</div></Table.HeaderCell>
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Analysis</div></Table.HeaderCell>
                    <Table.HeaderCell width={1} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Conf.</div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.inmates.map((inmate:any, i:number) => inmate.uas.map((ua:any, r:number) => (
                    <UARow inmate={inmate} ua={ua} key={ua.recordID} i={i} r={r} />
                  )))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </div>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { open: state.inmates.open, loading: state.inmates.tabs.uas.loading, hasMore: state.inmates.tabs.uas.hasMore, filters: state.inmates.filters, sorts: state.inmates.sorts, inmates: state.inmates.tabs.uas.data, tab: state.drawer.tab } },
  { ...DrawerStore.actionCreators, ...InmatesStore.actionCreators, ...PanesStore.actionCreators }
)(UATable as any)