import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as InmatesStore from '../../store/reducers/inmates'
import DatePicker from 'react-datepicker'
import { format } from 'date-fns'
import {
  Popup,
  Form
} from 'semantic-ui-react'

type TableProps =
    { field: string, name: string, label: string, width: 1|2|3|4|5|6|7|8 } &
    InmatesStore.InmateFilters &
    typeof InmatesStore.actionCreators &
    WithRouterProps<{}>

class FormDatePicker extends React.PureComponent<TableProps> {
    public render() {
      return (
        <Form.Field
          label={this.props.label}
          control={DatePicker}
          selected={this.props.field ? new Date(this.props.field) : null}
          onChange={(date:any) => this.props.setFilter(this.props.name, date ? format(date, 'MM/dd/yyyy') : null)}
          width={2}
        />
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { field: state.inmates.filters[ownProps.name], ...ownProps } },
  InmatesStore.actionCreators
)(FormDatePicker as any)