import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Table,
  Select,
  Button,
  Modal,
  Form,
  Input,
  Tab,
  Search,
  Icon,
  Message
} from 'semantic-ui-react'
const _ = require('underscore')

type CompletionProps =
    { class: PanesStore.Class, num: 1 | 2, print: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassCompletionsWaitlist extends React.PureComponent<CompletionProps> {
    public render() {
        const rosters = this.props.class.rosters == undefined ? [] : _.filter(this.props.class.rosters, (roster:any) => !roster.waitlist)
        return (
          <Container fluid>
            {_.any(rosters, (roster:any) => roster.pending) ? <Message color='yellow'>
              <Icon name='warning' />
              Inmates with pending attendance cannot be completed.
            </Message> : null}
            <Table compact celled striped style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>SID</Table.HeaderCell>
                  <Table.HeaderCell>Housing</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.class.waitlist.inmates.map((waitlist:any, i:number) => (
                  <Table.Row key={waitlist.recordID}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell><div className='link'>{`${waitlist.lastName}, ${waitlist.firstName}`}</div></Table.Cell>
                    <Table.Cell>{waitlist.sid}</Table.Cell>
                    <Table.Cell>{waitlist.housing}</Table.Cell>
                    <Table.Cell><Button negative size='mini' content='Remove' onClick={_.debounce(() => this.props.removeWaitlist(this.props.num, waitlist.recordID), 10000, true)} /></Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Form>
              <Form.Group>
                <Search
                  placeholder='Add Inmate'
                  loading={this.props.class.waitlist.loading}
                  onResultSelect={(e:any, data:any) => this.props.selectWaitlistInmate(this.props.num, data.result)}
                  onSearchChange={(e:any, data:any) => this.props.searchWaitlistInmates(this.props.num, data.value as string)}
                  results={this.props.class.waitlist.search}
                  value={this.props.class.waitlist.value}
                  onFocus={() => this.props.clearWaitlist(this.props.num)}
                />
                <Button positive circular icon='plus' onClick={_.debounce(() => this.props.addCompletionsWaitlist(this.props.num), 10000, true)} style={{marginLeft:10}} />
                <Form.Field
                  control={Select}
                  placeholder='Add Inmate from Waitlist'
                  options={_.map(_.filter(this.props.class.waitlists, (roster:any) => !_.any(this.props.class.waitlist.inmates, (inmate:any) => inmate.inmateID == roster.inmateID)), (roster:any) => { return { key: roster.inmateID, value: roster.inmateID, text: `${roster.lastName}, ${roster.firstName}` }})}
                  value={this.props.class.waitlist.waitIDs}
                  onChange={(e:any, data:any) => this.props.selectClassWaitlistInmate(this.props.num, data.value as number[])}
                  style={{marginLeft:20}}
                  multiple
                  search
                />
                <Button positive circular icon='plus' onClick={_.debounce(() => this.props.addWaitlistInmate(this.props.num), 10000, true)} style={{marginLeft:20}} />
                <Form.Field
                  control={Select}
                  placeholder='Add Continuing Student'
                  options={_.map(_.filter(this.props.class.rosters, (roster:any) => roster.grade == "CE" && !_.any(this.props.class.waitlist.inmates, (inmate:any) => inmate.inmateID == roster.inmateID)), (roster:any) => { return { key: roster.inmateID, value: roster.inmateID, text: `${roster.lastName}, ${roster.firstName}` }})}
                  value={this.props.class.waitlist.contIDs}
                  onChange={(e:any, data:any) => this.props.selectContinuingInmate(this.props.num, data.value as number[])}
                  style={{marginLeft:20}}
                  multiple
                  search
                />
                <Button positive circular icon='plus' onClick={_.debounce(() => this.props.addContinuingInmate(this.props.num), 10000, true)} style={{marginLeft:20}} />
              </Form.Group>
              {this.props.print ? null : <Fragment>
                <Button color='black' content='Back' onClick={() => this.props.completionsBack(this.props.num)} style={{marginBottom:20}} />
                <Button color='blue' content='Next' onClick={() => this.props.completionsNext(this.props.num)} style={{marginBottom:20}} />
              </Fragment>}
            </Form>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, print: ownProps.print } },
  PanesStore.actionCreators
)(ClassCompletionsWaitlist as any)