import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import {
  Container,
  Checkbox,
  Button,
  Input,
  Select,
  Grid,
  Table,
  Header
} from 'semantic-ui-react'
const _ = require('underscore')

type SurveyProps =
    { surveys: any } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class SurveyList extends React.PureComponent<SurveyProps> {
    public render() {
      return (
        <Container fluid style={{overflowY:'auto',height:740,paddingRight:5}}>
          <Grid>
            <Grid.Column width={16}>
              <Header size='large'>Surveys</Header>
              <Table compact celled striped style={{ marginTop: "1em" }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4}>Name</Table.HeaderCell>
                    <Table.HeaderCell width={9}>Call to Action</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Active</Table.HeaderCell>
                    <Table.HeaderCell width={1} colSpan={2}>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.surveys.data.map((data:any, i:number) => (
                    <Table.Row key={data.recordID}>
                      <Table.Cell><Input value={data.name} name="name" onChange={(e:any, d:any) => this.props.updateSurveyField(data.recordID, 'name', d.value.validate())} style={{width:'100%'}} /></Table.Cell>
                      <Table.Cell><Input value={data.cta} name="cta" onChange={(e:any, d:any) => this.props.updateSurveyField(data.recordID, 'cta', d.value.validate())} style={{width:'100%'}} /></Table.Cell>
                      <Table.Cell style={{textAlign:'center'}}><Checkbox checked={data.active} recordid={data.recordID} name="active" onChange={(e:any, d:any) => this.props.updateSurveyField(data.recordID, 'active', d.checked as boolean)} /></Table.Cell>
                      <Table.Cell style={{textAlign:'center'}}>
                        <Button positive content="+" size="mini" onClick={_.debounce(() => this.props.saveSurvey(data.recordID), 10000, true)} />
                      </Table.Cell>
                      <Table.Cell style={{textAlign:'center'}}>
                        <Button negative content="-" size="mini" onClick={_.debounce(() => this.props.removeSurvey(data.recordID), 10000, true)} />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                  <Table.Row>
                    <Table.Cell><Input value={this.props.surveys.new.survey.name} name="name" placeholder="New Survey Name" onChange={(e:any, data:any) => this.props.changeNewSurvey('name', data.value.validate())} style={{width:'100%'}} /></Table.Cell>
                    <Table.Cell><Input value={this.props.surveys.new.survey.cta} name="cta" placeholder="New Survey CTA" onChange={(e:any, data:any) => this.props.changeNewSurvey('cta', data.value.validate())} style={{width:'100%'}} /></Table.Cell>
                    <Table.Cell style={{textAlign:'center'}}><Checkbox checked={this.props.surveys.new.survey.active} name="active" onChange={(e:any, d:any) => this.props.changeNewSurvey('active', d.checked as boolean)} /></Table.Cell>
                    <Table.Cell style={{textAlign:'center'}}><Button positive content="+" size="mini" onClick={_.debounce(this.props.addSurvey, 10000, true)} style={{width:'100%'}} /></Table.Cell>
                    <Table.Cell />
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { surveys: state.admin.surveys } },
  AdminStore.actionCreators
)(SurveyList as any)