import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import InmateClassesList from './InmateClassesList'
import EditTranscript from './EditTranscript'
import {
  Container,
  Search,
  Button,
  Form,
  Modal,
  Divider,
  Input,
  Select,
  Tab,
  Table
} from 'semantic-ui-react'
const _ = require('underscore')

type ClassProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean, hide: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateClasses extends React.PureComponent<ClassProps> {
    private inputFile = React.createRef<HTMLInputElement>()
    private typeID = 0

    public file(): any {
      return this.inputFile.current && this.inputFile.current.files && this.inputFile.current.files[0] ? this.inputFile.current.files[0] : null
    }

    public render() {
      const today = new Date(new Date().toDateString())
      if (this.props.inmate.class.index == 1) {
        var panes = [
          { menuItem: 'Classes', render: () => <Tab.Pane><InmateClassesList inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> },
          { menuItem: 'Edit', render: () => <Tab.Pane><EditTranscript inmate={this.props.inmate} num={this.props.num} print={this.props.print} /></Tab.Pane> }
        ]
      } else {
        var panes = [
          { menuItem: 'Classes', render: () => <Tab.Pane><InmateClassesList inmate={this.props.inmate} num={this.props.num} print={this.props.print} hide={this.props.hide} /></Tab.Pane> }
        ]
      }

      return (
        <Container fluid>
          <input ref={this.inputFile} type="file" onChange={(e:any) => { this.props.modalDataChange(this.props.num, 'certificate', 'typeID', this.typeID) }} hidden />
          <Tab menu={{ secondary: true }} panes={panes} activeIndex={this.props.inmate.class.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchClassesTab(this.props.num, activeIndex) }} />
          <Modal
            open={this.props.inmate.modals.certificate.open}
            onClose={() => this.props.toggleInmateCertificateModal(this.props.num)}
            className='assign-certificate'
            size="small"
          >
            <Modal.Header content={`Assign Certificate`} />
            <Modal.Content>
              <Form>
                <Form.Group style={{margin:0}}>
                  <Search
                    placeholder='Class template'
                    loading={this.props.inmate.template.loading}
                    onResultSelect={(e:any, data:any) => this.props.selectTemplate(this.props.num, data.result)}
                    onSearchChange={(e:any, data:any) => this.props.searchTemplates(this.props.num, data.value as string)}
                    results={this.props.inmate.options.templates}
                    value={this.props.inmate.template.value}
                    onFocus={() => this.props.clearTemplates(this.props.num)}
                    style={{display:'inline-block',width:'50%'}}
                    category
                  />
                  <Form.Field
                    control={Select}
                    placeholder='Certificate'
                    options={this.props.inmate.template.id == 0 ? this.props.inmate.options.certificateOrphans : this.props.inmate.options.certificates}
                    value={this.props.inmate.modals.certificate.data.recordID}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'certificate', 'recordID', data.value as number)}
                    width={8}
                  />
                </Form.Group>
                {this.props.inmate.modals.certificate.data.recordID ? <Fragment>
                  <Divider />
                  {_.any(this.props.inmate.classes.past, (klass:any) => klass.templateID == this.props.inmate.template.id) ? <Form.Group style={{marginTop:10}}>
                    <Form.Field
                      control={Select}
                      label='Transcript'
                      placeholder='Transcript'
                      options={[{key:0,value:null,text:''}].concat(_.map(_.filter(this.props.inmate.classes.past, (klass:any) => klass.templateID == this.props.inmate.template.id), (klass:any) => { return { key: klass.recordID, value: klass.recordID, text: klass.className } }))}
                      value={this.props.inmate.modals.certificate.data.transcriptID}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'certificate', 'transcriptID', data.value as number)}
                      width={16}
                    />
                  </Form.Group> : null}
                  <Form.Group style={{marginTop:10}}>
                    <Form.Field
                      control={Input}
                      label='Number'
                      placeholder='Number'
                      value={this.props.inmate.modals.certificate.data.number || ''}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'certificate', 'number', data.value.validate())}
                      width={5}
                    />
                    <Form.Field
                      label="Date"
                      control={DatePicker}
                      selected={this.props.inmate.modals.certificate.data.date && this.props.inmate.modals.certificate.data.date.length > 0 ? new Date(this.props.inmate.modals.certificate.data.date) : null}
                      onChange={(date:any) => this.props.modalDataChange(this.props.num, 'certificate', 'date', date ? format(date, 'MM/dd/yyyy') : '')}
                      filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                      width={6}
                    />
                    <Form.Field
                      label="Exp. Date"
                      control={DatePicker}
                      selected={this.props.inmate.modals.certificate.data.expDate && this.props.inmate.modals.certificate.data.expDate.length > 0 ? new Date(this.props.inmate.modals.certificate.data.expDate) : null}
                      onChange={(date:any) => this.props.modalDataChange(this.props.num, 'certificate', 'expDate', date ? format(date, 'MM/dd/yyyy') : '')}
                      filterDate={(date:any) => this.props.inmate.modals.certificate.data.date ? date >= new Date(this.props.inmate.modals.certificate.data.date) : true}
                      width={5}
                    />
                  </Form.Group>
                  <Form.Group style={{marginTop:10}}>
                    <Form.Field
                      control={Input}
                      label='Notes'
                      placeholder='Notes'
                      value={this.props.inmate.modals.certificate.data.notes || ''}
                      onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'certificate', 'notes', data.value.validate())}
                      width={16}
                    />
                  </Form.Group>
                  {this.file() ? <Table compact celled striped definition>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell width={5}>Document</Table.Cell>
                        <Table.Cell width={11}>{this.file().name}</Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table> : null}
                </Fragment>: null}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button className='icon-only' color='blue' labelPosition='left' icon='upload' loading={this.props.inmate.uploading} onClick={() => { this.inputFile.current!.click(); this.typeID = _.find(this.props.inmate.options.docTypes, (type:any) => type.text == 'Certificate').value }} style={{paddingLeft:14,height:33,marginLeft:8,verticalAlign:'top'}} />
              <Button color='black' onClick={() => this.props.toggleInmateCertificateModal(this.props.num)}>Close</Button>
              <Button
                positive
                content="Assign"
                onClick={_.debounce(() => this.props.assignCertificate(this.props.num, this.file()), 10000, true)}
              />
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print, hide: ownProps.hide } },
  PanesStore.actionCreators
)(InmateClasses as any)