import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import * as PanesStore from '../../store/reducers/panes'
import {
  Icon,
  Table,
  Popup,
  Checkbox,
  Label
} from 'semantic-ui-react'

type InmateProps =
    { inmate: InmatesStore.Inmate, test: any, tab: string, mode: string, i:number, h:number } &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class HiSETRow extends React.PureComponent<InmateProps> {
    public render() {
      return (
        <Table.Row key={`${this.props.i}:${this.props.h}`}>
          <Table.Cell style={{textAlign:'center'}}>{this.props.h > 0 ? <Icon /> : this.props.inmate.hisetPassed ? <Popup content="HiSET Passed" position="right center" trigger={<i className="icon icon-h" />} /> : <Popup content="HiSET Failed" position="right center" trigger={<i className="icon icon-h" style={{color:"#db2828"}} />} />}</Table.Cell>
          <Table.Cell style={{textAlign:'center'}}>{this.props.inmate.diplomaGEDVerified == "Y" ? <Popup content="Diploma / HSE Verified" position="top center" trigger={<Icon name="graduation" />} /> : <Icon />}</Table.Cell>
          <Table.Cell>
            {this.props.h > 0 ? null : this.props.mode == 'dual' ? <Popup
              hideOnScroll
              on='click'
              trigger={<div className='link' style={{whiteSpace:'normal'}}>{this.props.inmate.lastName}, {this.props.inmate.firstName}{this.props.inmate.mi ? ` ${this.props.inmate.mi.charAt(0)}` : null}</div>}
            >
              <Popup.Header>Open in pane</Popup.Header>
              <Popup.Content>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(1, this.props.inmate.recordID) }}>1</Label>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(2, this.props.inmate.recordID) }}>2</Label>
              </Popup.Content>
            </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, this.props.inmate.recordID) }}>{this.props.inmate.lastName}, {this.props.inmate.firstName}{this.props.inmate.mi ? ` ${this.props.inmate.mi.charAt(0)}` : null}</div>}
          </Table.Cell>
          <Table.Cell>{this.props.h == 0 ? this.props.inmate.sid : null}</Table.Cell>
          <Table.Cell>{this.props.test.facilityAbbr}</Table.Cell>
          <Table.Cell>{this.props.test.testDate}</Table.Cell>
          <Table.Cell>{this.props.test.etsID}</Table.Cell>
          <Table.Cell>{this.props.test.mathematics}</Table.Cell>
          <Table.Cell>{this.props.test.science}</Table.Cell>
          <Table.Cell>{this.props.test.socialStudies}</Table.Cell>
          <Table.Cell>{this.props.test.reading}</Table.Cell>
          <Table.Cell>{this.props.test.writing}</Table.Cell>
          <Table.Cell>{this.props.test.essay}</Table.Cell>
        </Table.Row>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, test: ownProps.test, tab: state.drawer.tab, mode: state.panes.mode, i: ownProps.i, h: ownProps.h } },
  { ...DrawerStore.actionCreators, ...PanesStore.actionCreators }
)(HiSETRow as any)