import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Button,
  Input,
  Table,
  Dropdown,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type StaffProps =
    { options: any, data: any, titles: any[], num: 1 | 2, print: boolean, admin: boolean } &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class TemplateTitles extends React.PureComponent<StaffProps> {
    public render() {
      return (
        <Container fluid>
          <Table celled striped style={{ marginTop: "1em" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell width={6}>Facilities</Table.HeaderCell>
                <Table.HeaderCell colSpan={2} width={2}>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.titles.map((title:any) => (
                <Table.Row key={title.recordID}>
                  <Table.Cell><Input value={title.title} disabled={!this.props.admin} onChange={(e:any, data:any) => this.props.editTitleField(this.props.num, title.recordID, 'title', data.value.validate())} style={{width:'90%'}} /></Table.Cell>
                  <Table.Cell><Dropdown value={title.facilities} onChange={(e:any, data:any) => this.props.editTitleField(this.props.num, title.recordID, 'facilities', data.value as number[])} placeholder='Facilities' fluid multiple selection options={this.props.options.facilities} /></Table.Cell>
                  <Table.Cell style={{textAlign:'center'}}>{this.props.print ? null : <Button positive content="Save" onClick={_.debounce(() => this.props.saveTemplateTitle(this.props.num, title), 10000, true)} />}</Table.Cell>
                  <Table.Cell style={{textAlign:'center'}}>{this.props.print ? null : this.props.admin ? <Button negative content="Remove" onClick={_.debounce(() => this.props.removeTemplateTitle(this.props.num, title.recordID), 10000, true)} /> : <Popup position="left center" content="You do not have permission to remove this class title, please contact an administrator." trigger={<Button negative content="Remove" style={{opacity:'.45'}} />} />}</Table.Cell>
                </Table.Row>
              ))}
              <Table.Row>
                <Table.Cell>
                  <Input placeholder="New Title" value={this.props.data.newTitle||''} disabled={!this.props.admin} onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'newTitle', data.value.validate())} style={{width:'90%'}} />
                </Table.Cell>
                <Table.Cell><Dropdown placeholder='Facilities' value={this.props.data.newFacilities||[]} onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'newFacilities', data.value as number[])} fluid multiple selection options={this.props.options.facilities} /></Table.Cell>
                <Table.Cell style={{textAlign:'center'}}>{this.props.print ? null : this.props.admin ? <Button positive content="Add" onClick={_.debounce(() => this.props.addTemplateTitle(this.props.num), 10000, true)} /> : <Popup position="left center" content="You do not have permission to add a class title, please contact an administrator." trigger={<Button positive content="Add" style={{opacity:'.45'}} />} />}</Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Container>
      )
    }
}

export default connect(
    (state: ApplicationState, ownProps: any) => { return { data: ownProps.data, titles: ownProps.titles, options: ownProps.options, num: ownProps.num, print: ownProps.print, admin: state.staff.staff!.programAdmin } },
    PanesStore.actionCreators
)(TemplateTitles as any)