import { Action, Reducer } from 'redux'
import { AppThunkAction } from '../'
import agent from '../../agent'
const store = require('store2')
const _ = require('underscore')

type PDF = {
    generating: boolean,
    ready: boolean,
    error: boolean,
    omissions: string,
    data: any,
    url: string,
    startDate: string,
    endDate: string,
    copyright: string,
    staffID: number,
    printID: string
}

export interface ClearSchedulesAction { type: 'CLEAR_SCHEDULES' }
interface ClearPDFAction { type: 'CLEAR_PDFS' }
interface ClearDataAction { type: 'CLEAR_DATA' }
interface SetURLAction { type: 'SET_SCHEDULES_URL', pdf: string, url: string }
interface EditScheduleField { type: 'EDIT_SCHEDULE_FIELD', key: string, value: any }
interface FetchFacilities { type: 'FETCH_SCHEDULE_FACILITIES', facilities: any[] }
interface FacilityOptions { type: 'SCHEDULE_FACILITY_OPTIONS', staff: any[], housing: any[] }
interface GeneratingScheduleAction { type: 'GENERATING_SCHEDULE', pdf: string }
interface ScheduleErrorAction { type: 'SCHEDULE_ERROR', pdf: string }
interface GenerateSchedulesAction { type: 'GENERATE_SCHEDULES', master: any[], inmates: any[], startDate: string, endDate: string, copyright: string, staffID: number, printID: string }
interface CalculateMissingAction { type: 'CALCULATE_MISSING', data: any }
export interface DataInjectionAction { type: 'SCHEDULES_DATA_INJECTION', data: any }
export interface FetchSchedulesFavoritesAction { type: 'FETCH_SCHEDULES_FAVORITES', favorited: boolean }
export interface ToggleMissingAction { type: 'TOGGLE_MISSING_MODAL' }
interface TogglePrintAction { type: 'TOGGLE_PRINT_MODAL' }

type KnownAction = SetURLAction | DataInjectionAction | ScheduleErrorAction | FetchSchedulesFavoritesAction | ClearDataAction | CalculateMissingAction | TogglePrintAction | ToggleMissingAction | ClearSchedulesAction | ClearPDFAction | EditScheduleField | FetchFacilities | FacilityOptions | GenerateSchedulesAction | GeneratingScheduleAction

export interface SchedulesState {
    favorited: boolean,
    data: {
        combine: boolean,
        omissions: string,
        printDates: string,
        dateStart: string,
        dateEnd: string,
        orientation: 'portrait' | 'landscape',
        listings: string[],
        facilities: string[],
        staff: string[],
        housing: string[],
        masterOptions: string[],
        inmateOptions: string[],
        instructorOptions: string[],
        attendanceOptions: string[],
        signinOptions: string[]
    },
    options: {
        facilities: any[],
        staff: any[],
        housing: any[]
    },
    pdfs: {[key:string]: PDF},
    modals: {
        print: {
            open: boolean,
            ready: boolean
        },
        missing: {
            open: boolean,
            data: any
        }
    }
}

const buildParams = (data: any, params: URLSearchParams) => {
    for (let [key, value] of Object.entries(data)) {
        if (value != undefined && value != null && value != '') {
            if (typeof value == 'object') {
                for (let ovalue of Object.values(value as any)) {
                  params.append(key, ovalue as string)
                }
            } else {
                params.append(key, (value as any).toString())
            }
        }
    }
    return params
}

export const actionCreators = {
    setSchedulesURL: (pdf:string, url:string) => ({ type: 'SET_SCHEDULES_URL', pdf: pdf, url: url } as SetURLAction),
    clearSchedules: () => ({ type: 'CLEAR_SCHEDULES' } as ClearSchedulesAction),
    clearData: () => ({ type: 'CLEAR_DATA' } as ClearDataAction),
    togglePrintModal: () => ({ type: 'TOGGLE_PRINT_MODAL' } as TogglePrintAction),
    toggleMissingModal: () => ({ type: 'TOGGLE_MISSING_MODAL' } as ToggleMissingAction),
    fetchFacilities: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const { facilities } = await agent.Data.fetchFacilities()
        dispatch({ type: 'FETCH_SCHEDULE_FACILITIES', facilities: facilities } as FetchFacilities)

        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0

        var schedules = store(`${id}:schedules:favorite`) || store(`${id}:schedules`) || {}
        store.remove(`${id}:schedules:favorite`)
        _.each(schedules, async (value:any,key:any) => {
            switch (key) {
            case 'facilities':
                let params = new URLSearchParams()
                for (let facility of Object.values(value)) {
                    params.append(key, facility as string)
                }
                const { staffData, housingData } = await agent.Schedules.facilityOptions(params)
                dispatch({ type: 'SCHEDULE_FACILITY_OPTIONS', staff: staffData, housing: housingData } as FacilityOptions)
                break
            case 'masterOptions':
                value.reverse()
                var sortFound = false
                _.each(value, (opt:string) => {
                    if (opt != 'instructors' && sortFound) value = _.filter(value, (o:string) => o != opt)
                    if (opt != 'instructors' && !sortFound) sortFound = true
                })
                if (!sortFound) value.push('inmate')
                break
            case 'inmateOptions':
                value.reverse()
                var sortFound = false
                _.each(value, (opt:string) => {
                    if (opt != 'instructors' && sortFound) value = _.filter(value, (o:string) => o != opt)
                    if (opt != 'instructors' && !sortFound) sortFound = true
                })
                if (!sortFound) value.push('shift')
                break
            case 'instructorOptions':
                value.reverse()
                var sortFound = false
                _.each(value, (opt:string) => {
                    if (sortFound) value = _.filter(value, (o:string) => o != opt)
                    if (!sortFound) sortFound = true
                })
                if (!sortFound) value.push('name')
                break
            }
            dispatch({ type: 'EDIT_SCHEDULE_FIELD', key: key, value: value } as EditScheduleField)
        })
    },
    editScheduleField: (key: string, value: any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        switch (key) {
        case 'facilities':
            let params = new URLSearchParams()
            for (let facility of Object.values(value)) {
                params.append(key, facility as string)
            }
            const { staffData, housingData } = await agent.Schedules.facilityOptions(params)
            dispatch({ type: 'SCHEDULE_FACILITY_OPTIONS', staff: staffData, housing: housingData } as FacilityOptions)
            break
        case 'masterOptions':
            value.reverse()
            var sortFound = false
            _.each(value, (opt:string) => {
                if (opt != 'instructors' && sortFound) value = _.filter(value, (o:string) => o != opt)
                if (opt != 'instructors' && !sortFound) sortFound = true
            })
            if (!sortFound) value.push('inmate')
            break
        case 'inmateOptions':
            value.reverse()
            var sortFound = false
            _.each(value, (opt:string) => {
                if (opt != 'instructors' && sortFound) value = _.filter(value, (o:string) => o != opt)
                if (opt != 'instructors' && !sortFound) sortFound = true
            })
            if (!sortFound) value.push('shift')
            break
        case 'instructorOptions':
            value.reverse()
            var sortFound = false
            _.each(value, (opt:string) => {
                if (sortFound) value = _.filter(value, (o:string) => o != opt)
                if (!sortFound) sortFound = true
            })
            if (!sortFound) value.push('name')
            break
        }
        dispatch({ type: 'EDIT_SCHEDULE_FIELD', key: key, value: value } as EditScheduleField)

        let id = getState().staff.staff ? getState().staff.staff!.recordID : 0
        var schedules = store(`${id}:schedules`) || {}
        schedules[key] = value
        store(`${id}:schedules`, schedules)
    },
    generateSchedules: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().schedules
        let copyright = getState().staff.settings.copyright
        if (state.data.listings.length > 0 && state.data.facilities.length > 0 && state.data.printDates.length > 0) {
            let params = new URLSearchParams()
            buildParams(state.data, params)
            dispatch({ type: 'CLEAR_PDFS' } as ClearPDFAction)
            dispatch({ type: 'TOGGLE_PRINT_MODAL' } as TogglePrintAction)
            if (state.modals.missing.open) { dispatch({ type: 'TOGGLE_MISSING_MODAL' } as ToggleMissingAction) }
            if (state.data.combine) {
                dispatch({ type: 'GENERATING_SCHEDULE', pdf: 'combined' } as GeneratingScheduleAction)
            } else {
                for (let listing of state.data.listings) {
                    dispatch({ type: 'GENERATING_SCHEDULE', pdf: listing } as GeneratingScheduleAction)
                }
            }
            try {
                const { masterData, dateData, startDate, endDate, staffID, printID } = await agent.Schedules.generate(params)
                dispatch({ type: 'GENERATE_SCHEDULES', master: masterData, inmates: dateData, startDate: startDate, endDate: endDate, copyright: copyright, staffID: staffID, printID: printID } as GenerateSchedulesAction)
            } catch (error) {
                if (state.data.combine) {
                    dispatch({ type: 'SCHEDULE_ERROR', pdf: 'combined' } as ScheduleErrorAction)
                } else {
                    for (let listing of state.data.listings) {
                        dispatch({ type: 'SCHEDULE_ERROR', pdf: listing } as ScheduleErrorAction)
                    }
                }
                throw error
            }
        }
    },
    calculateMissing: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().schedules
        let params = new URLSearchParams()
        buildParams(state.data, params)
        dispatch({ type: 'CLEAR_PDFS' } as ClearPDFAction)
        dispatch({ type: 'TOGGLE_PRINT_MODAL' } as TogglePrintAction)
        const data = await agent.Schedules.missing(params)
        dispatch({ type: 'CALCULATE_MISSING', data: data } as CalculateMissingAction)
    },
    fetchFavorites: (): AppThunkAction<KnownAction> => async (dispatch) => {
        const { schedulesFavorited } = await agent.Data.fetchFavorites()
        dispatch({ type: 'FETCH_SCHEDULES_FAVORITES', favorited: schedulesFavorited } as FetchSchedulesFavoritesAction)
    },
}

export const reducer: Reducer<SchedulesState> = (state: SchedulesState | undefined, incomingAction: Action): SchedulesState => {
    if (state === undefined) {
        return {
            favorited: false,
            data: {
                combine: false,
                omissions: 'N',
                printDates: '',
                dateStart: '',
                dateEnd: '',
                orientation: 'portrait',
                listings: [],
                facilities: [],
                staff: [],
                housing: [],
                masterOptions: ['inmate'],
                inmateOptions: ['shift'],
                instructorOptions: ['name'],
                attendanceOptions: [],
                signinOptions: []
            },
            options: {
                facilities: [],
                staff: [],
                housing: []
            },
            pdfs: {
                combined: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                master: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                inmates: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                instructors: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                attendance: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                signin: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' }
            },
            modals: {
                print: {
                    open: false,
                    ready: false
                },
                missing: {
                    open: false,
                    data: {}
                }
            }
        }
    }

    const action = incomingAction as KnownAction

    switch (action.type) {
        case 'CLEAR_SCHEDULES':
            return {
                favorited: false,
                data: {
                    combine: false,
                    omissions: 'N',
                    printDates: '',
                    dateStart: '',
                    dateEnd: '',
                    orientation: 'portrait',
                    listings: [],
                    facilities: [],
                    staff: [],
                    housing: [],
                    masterOptions: ['inmate'],
                    inmateOptions: ['shift'],
                    instructorOptions: ['name'],
                    attendanceOptions: [],
                    signinOptions: []
                },
                options: {
                    facilities: [],
                    staff: [],
                    housing: []
                },
                pdfs: {
                    combined: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    master: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    inmates: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    instructors: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    attendance: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    signin: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' }
                },
                modals: {
                    print: {
                        open: false,
                        ready: false
                    },
                    missing: {
                        open: false,
                        data: {}
                    }
                }
            }
        case 'CLEAR_DATA':
            return {
                ...state,
                data: {
                    combine: false,
                    omissions: 'N',
                    printDates: '',
                    dateStart: '',
                    dateEnd: '',
                    orientation: 'portrait',
                    listings: [],
                    facilities: [],
                    staff: [],
                    housing: [],
                    masterOptions: ['inmate'],
                    inmateOptions: ['shift'],
                    instructorOptions: ['name'],
                    attendanceOptions: [],
                    signinOptions: []
                },
                pdfs: {
                    combined: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    master: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    inmates: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    instructors: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    attendance: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    signin: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' }
                },
                modals: {
                    print: {
                        open: false,
                        ready: false
                    },
                    missing: {
                        open: false,
                        data: {}
                    }
                }
            }
        case 'SET_SCHEDULES_URL':
            return {
                ...state,
                pdfs: {
                    ...state.pdfs,
                    [action.pdf]: {
                        ...state.pdfs[action.pdf],
                        url: action.url
                    }
                }
            }
        case 'FETCH_SCHEDULES_FAVORITES':
            return {
                ...state,
                favorited: action.favorited
            }
        case 'TOGGLE_PRINT_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    print: {
                        open: !state.modals.print.open,
                        ready: false
                    }
                }
            }
        case 'TOGGLE_MISSING_MODAL':
            return {
                ...state,
                modals: {
                    ...state.modals,
                    missing: {
                        ...state.modals.missing,
                        open: !state.modals.missing.open
                    }
                }
            }
        case 'CLEAR_PDFS':
            return {
                ...state,
                pdfs: {
                    combined: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    master: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    inmates: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    instructors: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    attendance: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' },
                    signin: { generating: false, ready: false, error: false, omissions: 'N', data: {}, url: '', startDate: '', endDate: '', copyright: '', staffID: 0, printID: '' }
                }
            }
        case 'EDIT_SCHEDULE_FIELD':
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.key]: action.value
                }
            }
        case 'FETCH_SCHEDULE_FACILITIES':
            return {
                ...state,
                options: {
                    ...state.options,
                    facilities: action.facilities
                }
            }
        case 'SCHEDULE_FACILITY_OPTIONS':
            return {
                ...state,
                options: {
                    ...state.options,
                    staff: action.staff,
                    housing: action.housing
                }
            }
        case 'SCHEDULE_ERROR':
            return {
                ...state,
                pdfs: {
                    ...state.pdfs,
                    [action.pdf]: {
                        error: true,
                        generating: false,
                        ready: false,
                        omissions: 'N',
                        data: {},
                        url: '',
                        startDate: '',
                        endDate: '',
                        copyright: '', 
                        staffID: 0,
                        printID: ''
                    }
                }
            }
        case 'GENERATING_SCHEDULE':
            return {
                ...state,
                pdfs: {
                    ...state.pdfs,
                    [action.pdf]: {
                        generating: true,
                        ready: false,
                        error: false,
                        omissions: 'N',
                        data: {},
                        url: '',
                        startDate: '',
                        endDate: '',
                        copyright: '', 
                        staffID: 0,
                        printID: ''
                    }
                }
            }
        case 'GENERATE_SCHEDULES':
            return {
                ...state,
                pdfs: {
                    ...state.pdfs,
                    combined: {
                        generating: false,
                        error: false,
                        url: '',
                        ready: state.data.combine,
                        omissions: state.data.omissions,
                        data: { master: action.master, inmates: action.inmates },
                        startDate: action.startDate,
                        endDate: action.endDate,
                        copyright: action.copyright,
                        staffID: action.staffID,
                        printID: action.printID
                    },
                    master: {
                        generating: false,
                        error: false,
                        url: '',
                        omissions: state.data.omissions,
                        ready: !state.data.combine && state.data.listings.includes('master'),
                        data: action.master,
                        startDate: action.startDate,
                        endDate: action.endDate,
                        copyright: action.copyright,
                        staffID: action.staffID,
                        printID: action.printID
                    },
                    inmates: {
                        generating: false,
                        error: false,
                        url: '',
                        omissions: state.data.omissions,
                        ready: !state.data.combine && state.data.listings.includes('inmates'),
                        data: action.inmates,
                        startDate: action.startDate,
                        endDate: action.endDate,
                        copyright: action.copyright,
                        staffID: action.staffID,
                        printID: action.printID
                    },
                    instructors: {
                        generating: false,
                        error: false,
                        url: '',
                        omissions: state.data.omissions,
                        ready: !state.data.combine && state.data.listings.includes('instructors'),
                        data: action.inmates,
                        startDate: action.startDate,
                        endDate: action.endDate,
                        copyright: action.copyright,
                        staffID: action.staffID,
                        printID: action.printID
                    },
                    attendance: {
                        generating: false,
                        error: false,
                        url: '',
                        omissions: state.data.omissions,
                        ready: !state.data.combine && state.data.listings.includes('attendance'),
                        data: action.inmates,
                        startDate: action.startDate,
                        endDate: action.endDate,
                        copyright: action.copyright,
                        staffID: action.staffID,
                        printID: action.printID
                    },
                    signin: {
                        generating: false,
                        error: false,
                        url: '',
                        omissions: state.data.omissions,
                        ready: !state.data.combine && state.data.listings.includes('signin'),
                        data: action.inmates,
                        startDate: action.startDate,
                        endDate: action.endDate,
                        copyright: action.copyright,
                        staffID: action.staffID,
                        printID: action.printID
                    }
                }
            }
        case 'CALCULATE_MISSING':
            return {
                ...state,
                modals: {
                    print: {
                        open: true,
                        ready: true
                    },
                    missing: {
                        open: false,
                        data: action.data
                    }
                }
            }
        default:
            return state
    }
}
