import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as ClassesStore from '../../store/reducers/classes'
import {
  Popup,
  Form
} from 'semantic-ui-react'

type TableProps =
    { field: string, name: string, label: string, placeholder: string, popup: string, width: 1|2|3|4|5|6|7|8 } &
    ClassesStore.ClassFilters &
    typeof ClassesStore.actionCreators &
    WithRouterProps<{}>

class FormInput extends React.PureComponent<TableProps> {
    public render() {
        if (this.props.popup) {
            return (
              <Popup content={this.props.popup} position='top center' trigger={<Form.Input
                fluid
                name={this.props.name}
                label={this.props.label}
                placeholder={this.props.placeholder}
                value={this.props.field||''}
                onChange={e => this.props.setFilter(e.target.name, e.target.value)}
                width={this.props.width}
              />} />
            )
        } else {
            return (
              <Form.Input
                fluid
                name={this.props.name}
                label={this.props.label}
                placeholder={this.props.placeholder}
                value={this.props.field||''}
                onChange={e => this.props.setFilter(e.target.name, e.target.value)}
                width={this.props.width}
              />
            )
        }
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { field: state.classes.filters[ownProps.name], ...ownProps } },
  ClassesStore.actionCreators
)(FormInput as any)