import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format, getDay } from 'date-fns'
import DatePicker from 'react-datepicker'
import Cleave from "cleave.js/react"
import {
  Container,
  Form,
  Grid,
  Input,
  Select,
  Radio,
  Checkbox,
  Popup,
  Button,
  Header,
  Label,
  Accordion,
  Table,
  Modal,
  Icon
} from 'semantic-ui-react'
const _ = require('underscore')

type AttendanceProps =
    { class: PanesStore.Class, num: 1 | 2, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassAttendanceScheduled extends React.PureComponent<AttendanceProps> {
    public componentDidMount() {
        this.props.fetchAttendanceScheduled(this.props.num, this.props.class.info.recordID)
    }

    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public render() {
        const days = [{ key: 0, value: 0, text: 'Sunday' }, { key: 1, value: 1, text: 'Monday' }, { key: 2, value: 2, text: 'Tuesday' }, { key: 3, value: 3, text: 'Wednesday' }, { key: 4, value: 4, text: 'Thursday' }, { key: 5, value: 5, text: 'Friday' }, { key: 6, value: 6, text: 'Saturday' }]
        const options = this.props.class.options
        const info = this.props.class.info
        const attendance = this.props.class.attendance
        const scheduled = attendance.scheduled
        const selectedS = attendance.selected.scheduled
        return (
          <Container fluid>
            <Form>
              <Form.Group>
                <Form.Field
                  control={DatePicker}
                  label="Date From"
                  name="dateFrom"
                  placeholder="MM/DD/YYYY"
                  selected={attendance.dateFrom && attendance.dateFrom.length > 0 ? new Date(attendance.dateFrom) : null}
                  onChange={(date:any) => this.props.updateAttendanceFilter(this.props.num, 'dateFrom', date ? format(date, 'MM/dd/yyyy') : null)}
                  filterDate={(date:any) => date >= new Date(info.dateBegins) && date <= new Date(info.dateEnds)}
                  width={4}
                />
                <Form.Field
                  control={DatePicker}
                  label="Date To"
                  name="dateTo"
                  placeholder="MM/DD/YYYY"
                  selected={attendance.dateTo && attendance.dateTo.length > 0 ? new Date(attendance.dateTo) : null}
                  onChange={(date:any) => this.props.updateAttendanceFilter(this.props.num, 'dateTo', date ? format(date, 'MM/dd/yyyy') : null)}
                  filterDate={(date:any) => date >= new Date(info.dateBegins) && date <= new Date(info.dateEnds)}
                  width={4}
                />
                <Button color='blue' content='Clear dates' onClick={() => this.props.clearAttendanceDates(this.props.num)} style={{height:33,marginTop:17,marginLeft:8}} />
                <Button positive content='Add session' onClick={() => this.props.toggleAddSessionModal(this.props.num)} style={{height:33,marginTop:17,marginLeft:12}} />
              </Form.Group>
            </Form>
            <Accordion styled style={{width:'100%',marginBottom:20}}>
              {scheduled.map((record:any) => (
                <Fragment key={record.sessionID}>
                  <Accordion.Title active={selectedS.sessionID == record.sessionID} onClick={() => this.props.selectScheduled(this.props.num, record.sessionID)}><Icon name='user' style={{visibility:(record.rosterCount == 0 ? 'hidden' : 'visible')}} />[{this.props.class.info.recordID}] {this.props.class.info.subTitle} — {this.dayOfWeek3(getDay(new Date(record.meetingDate)))}. {record.meetingDate} — {record.startTime} - {record.endTime}</Accordion.Title>
                  <Accordion.Content active={selectedS.sessionID == record.sessionID}>
                    {selectedS.sessionID == record.sessionID ? <Fragment>
                      <Table compact celled striped>
                        <Table.Header>
                          <Table.Row key='1'>
                            <Table.HeaderCell width={1}></Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>SID</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {selectedS.rosters.map((roster:any, i:number) => (
                            <Table.Row key={i}>
                              <Table.Cell>{i+1}</Table.Cell>
                              <Table.Cell>
                                {this.props.mode == 'dual' ? <Popup
                                  hideOnScroll
                                  on='click'
                                  trigger={<div className='link' style={{whiteSpace:'normal'}}>{`${roster.lastName}, ${roster.firstName}`}</div>}
                                >
                                  <Popup.Header>Open in pane</Popup.Header>
                                  <Popup.Content>
                                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, roster.inmateID) }}>1</Label>
                                    <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, roster.inmateID) }}>2</Label>
                                  </Popup.Content>
                                </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, roster.inmateID) }}>{`${roster.lastName}, ${roster.firstName}`}</div>}
                              </Table.Cell>
                              <Table.Cell>{roster.sid}</Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                      <Button negative content='Remove session' onClick={_.debounce(() => this.props.removeSession(this.props.num, record.sessionID), 10000, true)} />
                    </Fragment> : null}
                  </Accordion.Content>
                </Fragment>
              ))}
            </Accordion>
            <Modal
              open={this.props.class.modals.addSession.open}
              onClose={() => this.props.toggleAddSessionModal(this.props.num)}
              size="tiny"
            >
              <Modal.Header content={`Add Session`} />
              <Modal.Content>
                <Form>
                  <Form.Group>
                    <Form.Field
                      control={DatePicker}
                      label="Date"
                      name="date"
                      selected={this.props.class.modals.addSession.data.date ? new Date(this.props.class.modals.addSession.data.date) : null}
                      onChange={(date:any) => this.props.addSessionChange(this.props.num, 'date', date ? format(date, 'MM/dd/yyyy') : null)}
                      filterDate={(date:any) => date >= new Date(info.dateBegins) && date <= new Date(info.dateEnds)}
                      width={4}
                    />
                    <div className='four wide field'>
                      <label>Start Time</label>
                      <Popup content="Specify time in military time (3PM = 15:00)" position="bottom center" trigger={<Cleave
                        name='startTime'
                        placeholder='15:30'
                        value={this.props.class.modals.addSession.data.startTime}
                        onChange={(e:any) => this.props.addSessionChange(this.props.num, 'startTime', e.target.value as string)}
                        options={{
                          time: true,
                          timePattern: ['h','m']
                        }}
                      />} />
                    </div>
                    <div className='four wide field'>
                      <label>End Time</label>
                      <Popup content="Specify time in military time (3PM = 15:00)" position="bottom center" trigger={<Cleave
                        name='endTime'
                        placeholder='17:00'
                        value={this.props.class.modals.addSession.data.endTime}
                        onChange={(e:any) => this.props.addSessionChange(this.props.num, 'endTime', e.target.value as string)}
                        options={{
                          time: true,
                          timePattern: ['h','m']
                        }}
                      />} />
                    </div>
                    <Popup content="Leave blank for default" position="bottom center" trigger={<Form.Field
                      control={Input}
                      label="Capacity"
                      name="capacity"
                      placeholder={info.capacity}
                      value={this.props.class.modals.addSession.data.capacity}
                      onChange={(e:any, data:any) => this.props.addSessionChange(this.props.num, 'capacity', data.value.validateNum() as string)}
                      width={4}
                    />} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label="Instructor"
                      name="instructorID"
                      options={options.instructors}
                      value={this.props.class.modals.addSession.data.instructorID || info.instructorID}
                      onChange={(e:any, data:any) => this.props.addSessionChange(this.props.num, 'instructorID', data.value as number)}
                      width={8}
                    />
                    <Form.Field
                      control={Select}
                      label="Location"
                      name="locationID"
                      options={options.locations}
                      value={this.props.class.modals.addSession.data.locationID}
                      onChange={(e:any, data:any) => this.props.addSessionChange(this.props.num, 'locationID', data.value as number)}
                      width={8}
                    />
                  </Form.Group>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleAddSessionModal(this.props.num)}>Close</Button>
                <Button
                  positive
                  content="Add"
                  onClick={_.debounce(() => this.props.addSession(this.props.num), 10000, true)}
                />
              </Modal.Actions>
            </Modal>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, mode: state.panes.mode } },
  PanesStore.actionCreators
)(ClassAttendanceScheduled as any)