import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import * as PanesStore from '../../store/reducers/panes'
import {
  Table,
  Popup,
  Label
} from 'semantic-ui-react'

type InmateProps =
    { inmate: InmatesStore.Inmate, and: any, tab: string, mode: string, i:number, r:number } &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ANDRow extends React.PureComponent<InmateProps> {
    public render() {
      return (
        <Table.Row key={this.props.and.recordID}>
          <Table.Cell>
            {this.props.r > 0 ? null : this.props.mode == 'dual' ? <Popup
              hideOnScroll
              on='click'
              trigger={<div className='link' style={{whiteSpace:'normal'}}>{this.props.inmate.lastName}, {this.props.inmate.firstName}{this.props.inmate.mi ? ` ${this.props.inmate.mi.charAt(0)}` : null}</div>}
            >
              <Popup.Header>Open in pane</Popup.Header>
              <Popup.Content>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(1, this.props.inmate.recordID) }}>1</Label>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(2, this.props.inmate.recordID) }}>2</Label>
              </Popup.Content>
            </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, this.props.inmate.recordID) }}>{this.props.inmate.lastName}, {this.props.inmate.firstName}{this.props.inmate.mi ? ` ${this.props.inmate.mi.charAt(0)}` : null}</div>}
          </Table.Cell>
          <Table.Cell>{this.props.r == 0 ? this.props.inmate.sid : null}</Table.Cell>
          <Table.Cell>{this.props.and.facilityAbbr}</Table.Cell>
          <Table.Cell>{this.props.and.admissionDate}</Table.Cell>
          <Table.Cell>{this.props.and.dischargeDate}</Table.Cell>
          <Table.Cell>{this.props.and.level}</Table.Cell>
          <Table.Cell>{this.props.and.primaryCounselor}</Table.Cell>
        </Table.Row>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, and: ownProps.and, tab: state.drawer.tab, mode: state.panes.mode, i: ownProps.i, r: ownProps.r } },
  { ...DrawerStore.actionCreators, ...PanesStore.actionCreators }
)(ANDRow as any)