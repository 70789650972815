import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Select,
  Input,
  Button
} from 'semantic-ui-react'

type RosterProps =
    DrawerStore.CreateModal &
    typeof DrawerStore.actionCreators &
    WithRouterProps<{}>

class CreateClassInfo extends React.PureComponent<RosterProps> {
    public render() {
        return (
          <Container fluid>
            <Form>
              <Form.Group>
                <Form.Field
                  control={Select}
                  label='Facility'
                  name='facilityID'
                  options={this.props.options.facilities}
                  value={this.props.data.facilityID}
                  onChange={(e:any, data:any) => this.props.dataChange('facilityID', data.value as number)}
                  width={4}
                />
                <Form.Field
                  control={Select}
                  label='Default Class Location'
                  name='defaultLocationID'
                  options={[{key:0,value:null,text:''}].concat(this.props.options.locations)}
                  value={this.props.data.defaultLocationID}
                  onChange={(e:any, data:any) => this.props.dataChange('defaultLocationID', data.value as number)}
                  width={4}
                />
                <Form.Field
                  control={Select}
                  label='Internal Name'
                  name='titleID'
                  value={this.props.data.titleID}
                  options={this.props.options.titles}
                  onChange={(e:any, data:any) => this.props.dataChange('titleID', data.value as number)}
                  width={4}
                />
                <Form.Field
                  control={Select}
                  label='Instructor'
                  name='instructorID'
                  options={this.props.options.instructors}
                  value={this.props.data.instructorID}
                  onChange={(e:any, data:any) => this.props.dataChange('instructorID', data.value as number)}
                  width={4}
                  search
                  selection
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Select}
                  label='Funding Source'
                  name='fundingSource'
                  options={this.props.options.funding}
                  value={this.props.data.fundingSource}
                  onChange={(e:any, data:any) => this.props.dataChange('fundingSource', data.value as number)}
                  width={8}
                />
                <Form.Field
                  control={Input}
                  label='Capacity'
                  name='capacity'
                  width={4}
                  value={this.props.data.capacity||''}
                  onChange={(e:any, data:any) => this.props.dataChange('capacity', data.value.validate())}
                />
                <Form.Field
                  control={Input}
                  label='Required Hours'
                  name='requiredHours'
                  width={4}
                  value={this.props.data.requiredHours||''}
                  onChange={(e:any, data:any) => this.props.dataChange('requiredHours', data.value.validate())}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={DatePicker}
                  label="Date Begins"
                  name="dateBegins"
                  placeholder="MM/DD/YYYY"
                  selected={this.props.data.dateBegins ? new Date(this.props.data.dateBegins) : null}
                  onChange={(date:any) => this.props.dataChange('dateBegins', date ? format(date, 'MM/dd/yyyy') : null)}
                  width={4}
                />
                <Form.Field
                  control={DatePicker}
                  label="Date Ends"
                  name="dateEnds"
                  placeholder="MM/DD/YYYY"
                  selected={this.props.data.dateEnds ? new Date(this.props.data.dateEnds) : null}
                  onChange={(date:any) => this.props.dataChange('dateEnds', date ? format(date, 'MM/dd/yyyy') : null)}
                  width={4}
                />
              </Form.Group>
            </Form>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState) => state.drawer.createClassM,
  DrawerStore.actionCreators
)(CreateClassInfo as any)