import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Grid,
  Form,
  Header,
  Select,
  Table,
  TextArea,
  Input,
  Button,
  Modal,
  Divider,
  Checkbox,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2 } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateLSIR extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchLSIR(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      return (
        <Container fluid>
          <div style={{display:'inline-block',marginTop:20,marginBottom:10}}>
            <Header as='h3'>LSIR Data</Header>
          </div>
          <Form>
            <Form.Group>
              <Form.Field
                control={Input}
                label="LSIR Score"
                placeholder="LSIR Score"
                name="score"
                value={this.props.inmate.lsir.score||''}
                onChange={(e:any, data:any) => this.props.changeLSIRData(this.props.num, 'score', data.value.validate())}
                width={4}
              />
              <Form.Field
                control={Select}
                label="Top Criminal Area 1"
                name="topThreeCriminalAreas1"
                options={this.props.inmate.options.criminal}
                value={this.props.inmate.lsir.topThreeCriminalAreas1||[]}
                onChange={(e:any, data:any) => this.props.changeLSIRData(this.props.num, 'topThreeCriminalAreas1', data.value)}
                width={4}
              />
              <Form.Field
                control={Select}
                label="Top Criminal Area 2"
                name="topThreeCriminalAreas2"
                options={this.props.inmate.options.criminal}
                value={this.props.inmate.lsir.topThreeCriminalAreas2||[]}
                onChange={(e:any, data:any) => this.props.changeLSIRData(this.props.num, 'topThreeCriminalAreas2', data.value)}
                width={4}
              />
              <Form.Field
                control={Select}
                label="Top Criminal Area 3"
                name="topThreeCriminalAreas3"
                options={this.props.inmate.options.criminal}
                value={this.props.inmate.lsir.topThreeCriminalAreas3||[]}
                onChange={(e:any, data:any) => this.props.changeLSIRData(this.props.num, 'topThreeCriminalAreas3', data.value)}
                width={4}
              />
            </Form.Group>
          </Form>
          <Divider />
          <div style={{display:'inline-block',marginTop:10,marginBottom:10}}>
            <Header as='h3'>ASUS Data</Header>
          </div>
          <Form>
            <Form.Group>
              <Form.Field
                label="ASUS Date"
                control={DatePicker}
                selected={this.props.inmate.asus.date ? new Date(this.props.inmate.asus.date) : null}
                onChange={(date:any) => this.props.changeASUSData(this.props.num, 'date', date ? format(date, 'MM/dd/yyyy') : null)}
                dateFormat="MM/dd/yyyy"
                width={3}
                style={{width:'100%'}}
              />
              <Form.Field
                control={Input}
                label="Mood Score"
                placeholder="Score"
                name="moodScore"
                value={this.props.inmate.asus.moodScore||''}
                onChange={(e:any, data:any) => this.props.changeASUSData(this.props.num, 'moodScore', data.value.validate())}
                width={3}
              />
              <Form.Field
                control={Input}
                label="Defensive Score"
                placeholder="Score"
                name="defensiveScore"
                value={this.props.inmate.asus.defensiveScore||''}
                onChange={(e:any, data:any) => this.props.changeASUSData(this.props.num, 'defensiveScore', data.value.validate())}
                width={2}
              />
              <Form.Field
                control={Input}
                label="Motivation Score"
                placeholder="Score"
                name="motivationScore"
                value={this.props.inmate.asus.motivationScore||''}
                onChange={(e:any, data:any) => this.props.changeASUSData(this.props.num, 'motivationScore', data.value.validate())}
                width={3}
              />
              <Form.Field
                control={Input}
                label="Disruption Score"
                placeholder="Score"
                name="disruptionScore"
                value={this.props.inmate.asus.disruptionScore||''}
                onChange={(e:any, data:any) => this.props.changeASUSData(this.props.num, 'disruptionScore', data.value.validate())}
                width={2}
              />
              <Form.Field
                control={Input}
                label="Global Score"
                placeholder="Score"
                name="globalScore"
                value={this.props.inmate.asus.globalScore||''}
                onChange={(e:any, data:any) => this.props.changeASUSData(this.props.num, 'globalScore', data.value.validate())}
                width={3}
              />
            </Form.Group>
          </Form>
          <Divider />
          <div style={{display:'inline-block',marginTop:10,marginBottom:10}}>
            <Header as='h3'>Top Three Target Behaviors</Header>
          </div>
          <Form>
            <Form.Group>
              <Form.Field
                control={Input}
                label="Target Behavior 1"
                placeholder="Target Behavior 1"
                name="targetBehavior1"
                value={this.props.inmate.target.targetBehavior1||''}
                onChange={(e:any, data:any) => this.props.changeTargetData(this.props.num, 'targetBehavior1', data.value.validate())}
                width={5}
              />
              <Form.Field
                control={Input}
                label="Target Behavior 2"
                placeholder="Target Behavior 2"
                name="targetBehavior2"
                value={this.props.inmate.target.targetBehavior2||''}
                onChange={(e:any, data:any) => this.props.changeTargetData(this.props.num, 'targetBehavior2', data.value.validate())}
                width={6}
              />
              <Form.Field
                control={Input}
                label="Target Behavior 3"
                placeholder="Target Behavior 3"
                name="targetBehavior3"
                value={this.props.inmate.target.targetBehavior3||''}
                onChange={(e:any, data:any) => this.props.changeTargetData(this.props.num, 'targetBehavior3', data.value.validate())}
                width={5}
              />
            </Form.Group>
          </Form>
          <Divider />
          <Button positive content='Save' onClick={_.debounce(() => this.props.saveLSIR(this.props.num), 10000, true)} />
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num } },
  PanesStore.actionCreators
)(InmateLSIR as any)