import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import * as PanesStore from '../../store/reducers/panes'
import {
  Icon,
  Table,
  Popup,
  Label
} from 'semantic-ui-react'

type InmateProps =
    { inmate: InmatesStore.Inmate, test: any, tab: string, mode: string, i:number, t:number } &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class TABERow extends React.PureComponent<InmateProps> {
    public render() {
      var reLF = this.props.test.reLF
      var reSS = this.props.test.reSS
      var reGE = this.props.test.reGE
      var reOR = this.props.test.reOR
      var reNRS = this.props.test.reNRS
      var laLF = this.props.test.laLF
      var laSS = this.props.test.laSS
      var laGE = this.props.test.laGE
      var laOR = this.props.test.laOR
      var laNRS = this.props.test.laNRS
      var tmLF = this.props.test.tmLF
      var tmSS = this.props.test.tmSS
      var tmGE = this.props.test.tmGE
      var tmOR = this.props.test.tmOR
      var tmNRS = this.props.test.tmNRS

      return (
        <Table.Row key={this.props.test.recordID} style={{colors: this.props.test.level == '11' || this.props.test.level == '12' ? '#1E88E5' : this.props.test.level == '9' || this.props.test.level == '10' ? '#00897B' : this.props.test.level == '7' || this.props.test.level == '8' ? '#D81B60' : 'black'}}>
          <Table.Cell style={{textAlign:'center'}}>{this.props.t > 0 || this.props.inmate.abeMinutes == null || this.props.inmate.abeMinutes == 0 ? <Icon /> : <Popup content={`${Math.round(this.props.inmate.abeMinutes/60*100) / 100} TABE Hours`} position="right center" trigger={<i className="icon icon-t" style={{color: this.props.inmate.abeMinutes < 30*60 ? "black" : this.props.inmate.abeMinutes < 45*60 ? "#2185d0" : this.props.inmate.abeMinutes < 60*60 ? "#21ba45" : "#db2828"}} />} />}</Table.Cell>
          <Table.Cell>
            {this.props.t > 0 ? null : this.props.mode == 'dual' ? <Popup
              hideOnScroll
              on='click'
              trigger={<div className='link'>{this.props.inmate.lastName}, {this.props.inmate.firstName}{this.props.inmate.mi ? ` ${this.props.inmate.mi.charAt(0)}` : null}</div>}
            >
              <Popup.Header>Open in pane</Popup.Header>
              <Popup.Content>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(1, this.props.inmate.recordID, 2) }}>1</Label>
                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(2, this.props.inmate.recordID, 2) }}>2</Label>
              </Popup.Content>
            </Popup> : <div className='link' onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, this.props.inmate.recordID, 2) }}>{this.props.inmate.lastName}, {this.props.inmate.firstName}{this.props.inmate.mi ? ` ${this.props.inmate.mi.charAt(0)}` : null}</div>}
          </Table.Cell>
          <Table.Cell>{this.props.t == 0 ? this.props.inmate.sid : null}</Table.Cell>
          <Table.Cell>{this.props.test.facilityAbbr}</Table.Cell>
          <Table.Cell>{this.props.test.testDate}</Table.Cell>
          <Table.Cell>{reLF}</Table.Cell>
          <Table.Cell>{reLF == null && (reSS == null || reSS == 0) ? null : reOR ? "N/A" : reSS}</Table.Cell>
          <Table.Cell>{reLF == null && (reSS == null || reSS == 0) ? null : reOR ? "-" : reGE}</Table.Cell>
          <Table.Cell>{reLF == null && (reSS == null || reSS == 0) ? null : reOR ? "O/R" : reNRS}</Table.Cell>
          <Table.Cell>{tmLF}</Table.Cell>
          <Table.Cell>{tmLF == null && (tmSS == null || tmSS == 0) ? null : tmOR ? "N/A" : tmSS}</Table.Cell>
          <Table.Cell>{tmLF == null && (tmSS == null || tmSS == 0) ? null : tmOR ? "-" : tmGE}</Table.Cell>
          <Table.Cell>{tmLF == null && (tmSS == null || tmSS == 0) ? null : tmOR ? "O/R" : tmNRS}</Table.Cell>
          <Table.Cell>{laLF}</Table.Cell>
          <Table.Cell>{laLF == null && (laSS == null || laSS == 0) ? null : laOR ? "N/A" : laSS}</Table.Cell>
          <Table.Cell>{laLF == null && (laSS == null || laSS == 0) ? null : laOR ? "-" : laGE}</Table.Cell>
          <Table.Cell>{laLF == null && (laSS == null || laSS == 0) ? null : laOR ? "O/R" : laNRS}</Table.Cell>
        </Table.Row>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, test: ownProps.test, tab: state.drawer.tab, mode: state.panes.mode, i: ownProps.i, t: ownProps.t } },
  { ...DrawerStore.actionCreators, ...PanesStore.actionCreators }
)(TABERow as any)