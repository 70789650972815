import * as Staff from './reducers/staff'
import * as Drawer from './reducers/drawer'
import * as Dashboard from './reducers/dashboard'
import * as Landing from './reducers/landing'
import * as Inmates from './reducers/inmates'
import * as Classes from './reducers/classes'
import * as Schedules from './reducers/schedules'
import * as Reports from './reducers/reports'
import * as Panes from './reducers/panes'
import * as Admin from './reducers/admin'
import * as Support from './reducers/support'
import * as Calendar from './reducers/calendar'

export interface ApplicationState {
    landing: Landing.LandingState,
    staff: Staff.StaffState,
    drawer: Drawer.DrawerState,
    dashboard: Dashboard.DashboardState,
    inmates: Inmates.InmatesState,
    classes: Classes.ClassesState,
    schedules: Schedules.SchedulesState,
    reports: Reports.ReportsState,
    panes: Panes.PanesState,
    admin: Admin.AdminState,
    support: Support.SupportState,
    calendar: Calendar.CalendarState
}

export const reducers = {
    landing: Landing.reducer,
    staff: Staff.reducer,
    drawer: Drawer.reducer,
    dashboard: Dashboard.reducer,
    inmates: Inmates.reducer,
    classes: Classes.reducer,
    schedules: Schedules.reducer,
    reports: Reports.reducer,
    panes: Panes.reducer,
    admin: Admin.reducer,
    support: Support.reducer,
    calendar: Calendar.reducer
}

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void
}