import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../store'
import * as StaffStore from '../../store/reducers/staff'
import * as LandingStore from '../../store/reducers/landing'
import {
  Container,
  Segment,
  Header,
  Button,
  Image,
  Grid,
  Form,
  Input,
  Modal,
  Popup
} from 'semantic-ui-react'

type LandingProps =
    LandingStore.LandingState &
    StaffStore.StaffState &
    typeof LandingStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>


class SMSLanding extends React.PureComponent<LandingProps> {
    public render() {
      return (
        <Segment inverted textAlign="center" vertical className="masthead">
          <Grid padded>
            <Grid.Row
              columns="equal"
              textAlign="center"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Grid.Column>
                <Header as="h3" inverted style={{ padding: 10 }}>{this.props.settings.heading1}</Header>{this.props.settings.heading1Content}
              </Grid.Column>
              <Grid.Column>
                <Header as="h3" inverted style={{ padding: 10 }}>{this.props.settings.heading2}</Header>{this.props.settings.heading2Content}
              </Grid.Column>
              <Grid.Column>
                <Header as="h3" inverted style={{ padding: 10 }}>{this.props.settings.heading3}</Header>{this.props.settings.heading3Content}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="3">
              <Grid.Column width={4}>
                <Image src="/assets/TreeOfLife.gif" alt="logo" floated="right" />
              </Grid.Column>
              <Grid.Column width={8}>
                <Header as="h1" inverted>
                  Hawai'i {this.props.settings.deptName}
                </Header>
                <Header as="h2" inverted>
                  Corrections Program Services
                </Header>
                <Header as="h2" inverted>
                  Education Branch
                </Header>
                {this.props.loggedIn ? (
                  <Fragment>
                    <Header
                      as="h3"
                      inverted
                      content={`Welcome back ${this.props.staff!.displayName}`}
                    />
                    <Button as={Link} to="/home" size="huge" inverted>
                      Enter
                    </Button>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Header as="h3" inverted content="Student Management System" />
                      <Button onClick={() => this.props.toggleModal(true)} size="huge" inverted>Login</Button>
                      <div style={{marginTop: 10}}>
                      <Popup content='@sms-hi.com email accounts are able to signin with Google' position="right center" trigger={<Image
                        centered
                        src="/assets/btn_google_signin_light_normal_web.png"
                        as="a"
                        href="https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://sms.psd-hi.com/auth/google&response_type=code&client_id=350937911919-41gpqauhubogf31mrkscg20566t7t2g9.apps.googleusercontent.com&scope=email&access_type=offline&prompt=consent"
                      />} />
                      </div>
                  </Fragment>
                )}
              </Grid.Column>
              <Grid.Column width={4}></Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ marginTop: "1em" }}>
              <Container>
                {this.props.settings.finePrint}
                <br />
                <br />
                {this.props.settings.copyright}
                <Header as="h5">
                  Entering this system indicates your consent to allow monitoring of
                  your use of the system.
                </Header>
              </Container>
            </Grid.Row>
          </Grid>
          <Modal
            open={this.props.modal.open}
            onClose={() => this.props.toggleModal(false)}
            size="tiny"
          >
            <Modal.Header content="Login to SMS" />
            <Modal.Content>
              {this.props.modal.tab == 'login' ? <Form>
                <Form.Field
                  control={Input}
                  label="Username"
                  name="username"
                  value={this.props.modal.username}
                  onChange={(e:any, data:any) => this.props.setUsername(data.value)}
                />
                <Form.Field
                  control={Input}
                  label="Password"
                  name="password"
                  type="password"
                  value={this.props.modal.password}
                  onChange={(e:any, data:any) => this.props.setPassword(data.value)}
                />
              </Form> : this.props.modal.tab == 'code' ? <Form>
                <Form.Field
                  control={Input}
                  label="Code"
                  name="code"
                  value={this.props.modal.code}
                  onChange={(e:any, data:any) => this.props.setCode(data.value)}
                />
              </Form> : this.props.modal.tab == 'forgot' ? <Form>
                <Form.Field
                  control={Input}
                  label="Username"
                  name="username"
                  value={this.props.modal.username}
                  onChange={(e:any, data:any) => this.props.setUsername(data.value)}
                />
              </Form> : null}
            </Modal.Content>
            <Modal.Actions>
              {this.props.modal.tab == 'login' ? <Button onClick={() => this.props.setTab('forgot')}>Forgot Password</Button> : null}
              {this.props.modal.tab == 'login' ? <Button color='black' onClick={() => this.props.toggleModal(false)}>Close</Button> : <Button color='black' onClick={() => this.props.setTab('login')}>Back</Button>}
              {this.props.modal.tab == 'login' ? <Button color='blue' onClick={() => this.props.authenticate(this.props.modal.username, this.props.modal.password)}>Login</Button> : null}
              {this.props.modal.tab == 'code' ? <Button color='blue' onClick={() => this.props.authenticate2(this.props.modal.username, this.props.modal.password, this.props.modal.code)}>Login</Button> : null}
              {this.props.modal.tab == 'forgot' ? <Button color='blue' onClick={() => this.props.forgotPassword(this.props.modal.username)}>Submit</Button> : null}
            </Modal.Actions>
          </Modal>
        </Segment>
      )
    }
}

export default connect(
    (state: ApplicationState) => { return { ...state.landing, ...state.staff } },
    { ...LandingStore.actionCreators, ...StaffStore.actionCreators }
)(SMSLanding as any)
