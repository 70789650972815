import { Action, Reducer } from 'redux'
import { AppThunkAction } from '../'
import agent from '../../agent'
import { format, addMonths, subMonths } from 'date-fns'
import { toast } from 'react-toastify'
const randomColor = require('randomcolor')
const _ = require('underscore')

interface SetDateAction { type: 'SET_CALENDAR_DATE', date: Date }
interface FetchInmateSessionsAction { type: 'FETCH_INMATE_SESSIONS', sessions: any[] }

type KnownAction = SetDateAction | FetchInmateSessionsAction

export interface CalendarState {
    date: Date,
    colors: any,
    sessions: any[]
}

export const actionCreators = {
    fetchInmateSessions: (inmateID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().calendar
        let params = new URLSearchParams()
        params.append('date', format(state.date, 'MM/dd/yyyy'))
        const { sessionData } = await agent.Inmates.fetchSessions(inmateID, params)
        dispatch({ type: 'FETCH_INMATE_SESSIONS', sessions: sessionData } as FetchInmateSessionsAction)
    },
    today: (inmateID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().calendar
        let params = new URLSearchParams()
        const today = new Date(new Date().toDateString())
        params.append('date', format(today, 'MM/dd/yyyy'))
        const { sessionData } = await agent.Inmates.fetchSessions(inmateID, params)
        dispatch({ type: 'SET_CALENDAR_DATE', date: today } as SetDateAction)
        dispatch({ type: 'FETCH_INMATE_SESSIONS', sessions: sessionData } as FetchInmateSessionsAction)
    },
    nextMonth: (inmateID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().calendar
        let params = new URLSearchParams()
        let date = addMonths(state.date, 1)
        params.append('date', format(date, 'MM/dd/yyyy'))
        const { sessionData, hasMore } = await agent.Inmates.fetchSessions(inmateID, params)
        if (hasMore) {
            dispatch({ type: 'SET_CALENDAR_DATE', date: date } as SetDateAction)
            dispatch({ type: 'FETCH_INMATE_SESSIONS', sessions: sessionData } as FetchInmateSessionsAction)
        } else {
            toast.error('No more sessions beyond this month')
        }
    },
    prevMonth: (inmateID: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().calendar
        let params = new URLSearchParams()
        let date = subMonths(state.date, 1)
        params.append('date', format(date, 'MM/dd/yyyy'))
        const { sessionData, hasPrev } = await agent.Inmates.fetchSessions(inmateID, params)
        if (hasPrev) {
            dispatch({ type: 'SET_CALENDAR_DATE', date: date } as SetDateAction)
            dispatch({ type: 'FETCH_INMATE_SESSIONS', sessions: sessionData } as FetchInmateSessionsAction)
        } else {
            toast.error('No more sessions before this month')
        }
    }
}

export const reducer: Reducer<CalendarState> = (state: CalendarState | undefined, incomingAction: Action): CalendarState => {
    if (state === undefined) {
        return {
            date: new Date(new Date().toDateString()),
            colors: {},
            sessions: []
        }
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'SET_CALENDAR_DATE':
            return {
                ...state,
                date: action.date
            }
        case 'FETCH_INMATE_SESSIONS':
            let newState = { ...state, sessions: action.sessions }
            _.each(action.sessions, (session:any) => {
                if (!state.colors[session.classID]) {
                    newState.colors[session.classID] = randomColor({ lumosity: 'light' })
                }
            })
            return newState
        default:
            return state
    }
}