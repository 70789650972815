import React, { Fragment } from 'react'
import {
  Dimmer,
  Loader,
  Table,
  Container
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class Utilization extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let data = this.props.report.generated
        var num = 0

        return (
            <Container fluid>
              <Table compact celled striped unstackable className='slanted'>
                <Table.Header>
                  <Table.Row style={{textAlign:'center'}}>
                    <Table.HeaderCell colSpan={8}>
                      <div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div>
                      <div style={{fontSize:14}}>Classroom Utilization Report</div>
                      <div style={{marginTop:4}}>{`${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div>
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    <Table.HeaderCell style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Program</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Course</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Template</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Class</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Currently Enrolled</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Capacity</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Percent Filled</span></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.facilities.map((facility:any, f:number) =>
                    facility.programs.map((program:any, p:number) =>
                      program.courses.map((course:any, c:number) =>
                        course.templates.map((template:any, t:number) =>
                          template.classes.map((klass:any, k:number) => (
                            <Table.Row style={{breakInside:'avoid',backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={`${f}:${p}:${c}:${t}:${k}`}>
                              <Table.Cell warning={p == 0 && c == 0 && t == 0 && k == 0 && facility.highlight}>{p == 0 && c == 0 && t == 0 && k == 0 ? `${facility.abbreviation}` : ''}</Table.Cell>
                              <Table.Cell warning={c == 0 && t == 0 && k == 0 && program.highlight}>{c == 0 && t == 0 && k == 0 ? `${program.name}` : ''}</Table.Cell>
                              <Table.Cell warning={t == 0 && k == 0 && course.highlight}>{t == 0 && k == 0 ? `${course.name}` : ''}</Table.Cell>
                              <Table.Cell warning={k == 0 && template.highlight}>{k == 0 ? `${template.name}` : ''}</Table.Cell>
                              <Table.Cell warning={klass.highlight}>{klass.name}</Table.Cell>
                              <Table.Cell warning={klass.rosterHighlight}>{klass.enrolled}</Table.Cell>
                              <Table.Cell>{klass.capacity}</Table.Cell>
                              <Table.Cell>{`${klass.percent}%`}</Table.Cell>
                            </Table.Row>
                          ))
                        )
                      )
                    )
                  )}
                </Table.Body>
              </Table>
            </Container>
          )
    }
}

export default Utilization