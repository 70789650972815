import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import AttendancePending from './ClassAttendancePending'
import AttendanceScheduled from './ClassAttendanceScheduled'
import AttendanceRecords from './ClassAttendanceRecords'
import {
  Container,
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type AttendanceProps =
    { class: PanesStore.Class, num: 1 | 2, mode: string } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassAttendance extends React.PureComponent<AttendanceProps> {
    public render() {
        var panes = [
          { menuItem: 'Pending', render: () => <Tab.Pane><AttendancePending class={this.props.class} num={this.props.num} /></Tab.Pane> },
          { menuItem: 'Scheduled', render: () => <Tab.Pane><AttendanceScheduled class={this.props.class} num={this.props.num} /></Tab.Pane> },
          { menuItem: 'Records', render: () => <Tab.Pane><AttendanceRecords class={this.props.class} num={this.props.num} /></Tab.Pane> }
        ]

        return (
          <Container fluid>
            <Tab menu={{ secondary: true, pointing: true }} panes={panes} activeIndex={this.props.class.attendance.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchAttendanceTab(this.props.num, activeIndex) }} />
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, mode: state.panes.mode } },
  PanesStore.actionCreators
)(ClassAttendance as any)