import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Grid,
  Form,
  Input,
  Select,
  Button,
  TextArea,
  Header,
  Divider,
  Message,
  Icon,
  Popup,
  Table,
  Modal
} from 'semantic-ui-react'
const _ = require('underscore')

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateMilitary extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchMilitary(this.props.num, this.props.inmate.info.recordID)
        this.props.fetchEducation(this.props.num, this.props.inmate.info.recordID)
        this.props.fetchServiceRequests(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      const today = new Date(new Date().toDateString())
      const services = (<Table basic='very' style={{width:400}}>
        <Table.Body>
          {this.props.inmate.services.map((service:any) => (<Table.Row key={service.recordID}>
              <Table.Cell style={{paddingTop:4,paddingBottom:4}}>{service.received ? service.received : service.sent}</Table.Cell>
              <Table.Cell style={{paddingTop:4,paddingBottom:4}}>{service.received ? service.receivedBy : service.sentBy}</Table.Cell>
              <Table.Cell style={{paddingTop:4,paddingBottom:4}}><Button size='tiny' color={service.received || this.props.print || !this.props.inmate.military.selectiveServiceNumber_ || !this.props.inmate.military.selectiveServiceDate_ ? undefined : 'green'} content={service.received ? 'Verified' : 'Mark as verified'} onClick={() => service.received || this.props.print || !this.props.inmate.military.selectiveServiceNumber_ || !this.props.inmate.military.selectiveServiceDate_ ? null : this.props.receiveService(this.props.num, this.props.inmate.info.recordID, service.recordID)} /></Table.Cell>
            </Table.Row>))}
          </Table.Body>
      </Table>);
      return (
        <Container fluid>
          <Header as='h3' style={{marginTop:10,display:'inline-block'}}>Veteran</Header>
          {this.props.inmate.military.veteran && !this.props.print ? <Popup position='top center' content='Print DD214 Form' trigger={<Button color='blue' circular icon='print' as={Link} to='/assets/standard-form-180.pdf' target='_window' style={{marginLeft:10}} />} /> : null}
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label='Veteran'
                      placeholder='Veteran'
                      name='veteran'
                      options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                      value={this.props.inmate.military.veteran}
                      onChange={(e:any, data:any) => this.props.editMilitaryField(this.props.num, 'veteran', data.value as boolean)}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                    />
                    {this.props.inmate.military.veteran ? <Form.Field
                      control={Select}
                      label='Combat vet'
                      placeholder='Combat vet'
                      name='combatVet'
                      options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                      value={this.props.inmate.military.combatVet}
                      onChange={(e:any, data:any) => this.props.editMilitaryField(this.props.num, 'combatVet', data.value as boolean)}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                    /> : null}
                  </Form.Group>
                  {this.props.inmate.military.veteran ? <Form.Group>
                    <Form.Field
                      control={Select}
                      label='DD214 on file'
                      placeholder='DD214 on file'
                      name='dd214OnFile'
                      options={[{ key: "U", text: "Unknown", value: "U" }, { key: "N", text: "No", value: "N" }, { key: "Y", text: "Yes", value: "Y" }]}
                      value={this.props.inmate.education.data.dd214OnFile}
                      onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'dd214OnFile', data.value as string)}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                    />
                    <Form.Field
                      control={Select}
                      label='Combat zones'
                      placeholder='Combat zones'
                      options={[{ key: 0, text: "Korea", value: "K" }, { key: 1, text: "Vietnam", value: "V" }, { key: 2, text: "Gulf War (1991)", value: "G1" }, { key: 3, text: "Bosnia", value: "B" }, { key: 4, text: "Gulf War (2003)", value: "G2" }, { key: 5, text: "Afghanistan", value: "A" }, { key: 6, text: "Other", value: "O" }]}
                      value={this.props.inmate.military.combatZones || []}
                      onChange={(e:any, data:any) => this.props.editMilitaryField(this.props.num, 'combatZones', data.value as string)}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                      multiple
                    />
                  </Form.Group> : null}
                </Grid.Column>
                {this.props.inmate.military.veteran ? <Grid.Column width={8}>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label='Discharge type'
                      placeholder='Discharge type'
                      options={this.props.inmate.options.discharges}
                      value={this.props.inmate.military.dischargeType || ''}
                      onChange={(e:any, data:any) => this.props.editMilitaryField(this.props.num, 'dischargeType', data.value as string)}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                    />
                    <Form.Field
                      control={Select}
                      label='Military branch'
                      placeholder='Military branch'
                      options={this.props.inmate.options.branches}
                      value={this.props.inmate.military.militaryBranch || ''}
                      onChange={(e:any, data:any) => this.props.editMilitaryField(this.props.num, 'militaryBranch', data.value as string)}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Field
                      control={Input}
                      label='Other combat zone'
                      placeholder='Other combat zone'
                      name='combatZoneOther'
                      value={this.props.inmate.military.combatZoneOther || ''}
                      onChange={(e:any, data:any) => this.props.editMilitaryField(this.props.num, 'combatZoneOther', data.value.validate())}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                    />
                    <Form.Field
                      control={Select}
                      label='Service connected disability'
                      placeholder='Service connected disability'
                      name='serviceConnectedDisability'
                      options={[{ key: 0, text: "No", value: false }, { key: 1, text: "Yes", value: true }]}
                      value={this.props.inmate.military.serviceConnectedDisability}
                      onChange={(e:any, data:any) => this.props.editMilitaryField(this.props.num, 'serviceConnectedDisability', data.value as boolean)}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                    />
                  </Form.Group>
                </Grid.Column> : null}
              </Grid.Row>
              {this.props.inmate.military.veteran ? <Grid.Row style={{paddingTop:0}}>
                <Grid.Column>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Comment</Table.HeaderCell>
                        <Table.HeaderCell>Staff</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.military.notes.map((note:any, i:number) => (
                        <Table.Row key={i} onClick={() => this.props.openEditMilitaryNote(this.props.num, note)}>
                          <Table.Cell><div style={{whiteSpace:'normal'}}>{note.content}</div></Table.Cell>
                          <Table.Cell>{note.staffName}</Table.Cell>
                          <Table.Cell>{note.date}</Table.Cell>
                        </Table.Row>
                      ))}
                      <Table.Row>
                        <Table.Cell colSpan={2}><Input style={{width:'100%'}} value={this.props.inmate.military.newNote||''} onChange={(e:any, data:any) => this.props.editMilitaryField(this.props.num, 'newNote', data.value.validate())} /></Table.Cell>
                        <Table.Cell style={{textAlign:'center'}}><Button positive size='mini' content='Add' style={{width:'100%'}} onClick={_.debounce(() => this.props.addMilitaryNote(this.props.num), 10000, true)} /></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row> : null}
            </Grid>
          </Form>
          {this.props.inmate.info.sex == 'M' ? <Fragment>
            <Divider />
            <Popup content={`This table is used to mark the selective service information as verified once it is filled in.`} position="top right" trigger={<div><Popup position='left center' on='click' content={services} trigger={<Button circular content={this.props.inmate.services.length} color={_.filter(this.props.inmate.services, (service:any) => !service.received).length > 0 ? 'blue' : undefined} style={{float:'right'}} />} /></div>} />
            <Header as='h3' style={{marginTop:10,display:'inline-block'}}>Selective Service</Header>
            {this.props.print ? null : <Popup position='top center' content='Print SSS Form 1: Registration' trigger={<Button color='blue' circular icon='print' as={Link} to='/assets/regform_copyINT_1.pdf' target='_window' style={{marginLeft:10}} onClick={() => this.props.generateService(this.props.num, this.props.inmate.info.recordID)} />} />}
            {this.props.print ? null : <Popup position='top center' content='Selective Service Online Registration' trigger={<a href='https://www.sss.gov/register/' target='_window'><Button color='blue' circular icon='linkify' style={{marginLeft:10}} /></a>} />}
            <Form style={{marginTop:5}}>
              <Grid>
                <Grid.Row style={{paddingBottom:0}}>
                  <Grid.Column width={8}>
                    <Form.Group>
                      <Form.Field
                        control={Input}
                        label='Selective service number'
                        placeholder='Selective service number'
                        name='selectiveServiceNumber'
                        value={this.props.inmate.military.selectiveServiceNumber || ''}
                        onChange={(e:any, data:any) => this.props.editMilitaryField(this.props.num, 'selectiveServiceNumber', data.value.validateSSN())}
                        width={8}
                        className={this.props.print ? 'transparent' : ''}
                      />
                      <Form.Field
                        control={DatePicker}
                        label='Selective service date'
                        name='selectiveServiceDate'
                        selected={this.props.inmate.military.selectiveServiceDate ? new Date(this.props.inmate.military.selectiveServiceDate) : null}
                        onChange={(date:any) => this.props.editMilitaryField(this.props.num, 'selectiveServiceDate', date ? format(date, 'MM/dd/yyyy') : null)}
                        filterDate={(date:any) => date >= new Date(this.props.inmate.info.dob) && date <= today}
                        width={8}
                        className={this.props.print ? 'transparent' : ''}
                      />
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
                {this.props.inmate.data.sex == 'M' && this.props.inmate.data.age >= 18 && this.props.inmate.data.age <= 25 ? <Grid.Row style={{paddingTop:0}}>
                  <Grid.Column>
                    <Message color='yellow'>
                      <Icon name='warning' />
                      Men ages 18-25 must be registered with Selective Services.
                    </Message>
                  </Grid.Column>
                </Grid.Row> : null}
                {this.props.print ? null : <Grid.Row style={{paddingTop:0}}>
                  <Grid.Column>
                    <Button
                      positive
                      content='Save'
                      onClick={_.debounce(() => this.props.saveMilitary(this.props.num), 10000, true)}
                    />
                  </Grid.Column>
                </Grid.Row>}
              </Grid>
            </Form>
          </Fragment> : null}
          <Modal
            open={this.props.inmate.modals.selectiveConfirmation.open}
            onClose={() => this.props.toggleSelectiveConfirmation(this.props.num)}
            size="small"
          >
            <Modal.Header content={`Save military info`} />
            <Modal.Content>
              <div>Selective service verification will be <b>removed</b>. Are you sure you wish to save?</div>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' content="Close" onClick={() => this.props.toggleSelectiveConfirmation(this.props.num)} />
              <Button positive content="Save" onClick={_.debounce(() => this.props.saveMilitaryConfirm(this.props.num), 10000, true)} />
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editMilitaryNote.open}
            onClose={() => this.props.toggleEditMilitaryNote(this.props.num)}
            size='tiny'
          >
            <Modal.Header content='Edit Comment' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    name='content'
                    value={this.props.inmate.modals.editMilitaryNote.data.content || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editMilitaryNote', 'content', data.value)}
                    rows={3}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditMilitaryNote(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeMilitaryNote(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.editMilitaryNote(this.props.num), 10000, true)}>Edit</Button>
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print } },
  PanesStore.actionCreators
)(InmateMilitary as any)