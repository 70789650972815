import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  Container,
  Checkbox,
  Button,
  Input,
  Select,
  Grid,
  Table,
  Header,
  Form
} from 'semantic-ui-react'
const _ = require('underscore')

type SurveyProps =
    { surveys: any } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class SurveyOptions extends React.PureComponent<SurveyProps> {
    public render() {
      const onDragEnd = (result:any) => {
        var {draggableId, source, destination} = result
        var newItems = this.props.surveys.options.data
        var item = _.find(newItems, (data:any) => { return data.recordID == draggableId })
        newItems = _.filter(newItems, (data:any) => { return data.recordID != draggableId })
        newItems.splice(destination.index, 0, item)
        this.props.reorderSurveyOptions(newItems)
      }

      return (
        <Container fluid style={{overflowY:'auto',height:740,paddingRight:5}}>
          <Grid>
            <Grid.Column width={16}>
              <Header size='large'>Group Options</Header>
              <Form>
                <Form.Field
                  control={Select}
                  placeholder='Group'
                  options={[{key:0,value:null,text:''}].concat(_.map(this.props.surveys.groups.data, (group:any) => { return { key: group.recordID, value: group.recordID, text: group.name } }))}
                  value={this.props.surveys.options.groupID}
                  onChange={(e:any, data:any) => this.props.selectQuestion(data.value as number)}
                  width={4}
                />
              </Form>
              {this.props.surveys.options.groupID ? <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'0'}>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Table compact celled striped style={{ marginTop: "1em" }}>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell width={1}></Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell width={1} colSpan={2}>Actions</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.props.surveys.options.data.map((data:any, i:number) => (
                            <Fragment key={data.recordID}>
                              <Draggable draggableId={data.recordID.toString()} index={i}>
                                {(provided) => (
                                  <tr ref={provided.innerRef} {...provided.draggableProps}>
                                    <Table.Cell {...provided.dragHandleProps} style={{textAlign:'center'}}><i className="icon bars" /></Table.Cell>
                                    <Table.Cell><Input value={data.option} name="option" onChange={(e:any, d:any) => this.props.updateOptionField(data.recordID, 'option', d.value.validate())} style={{width:'100%'}} /></Table.Cell>
                                      <Table.Cell style={{textAlign:'center'}}>
                                      <Button positive content="+" size="mini" onClick={_.debounce(() => this.props.saveSurveyOption(data.recordID), 10000, true)} />
                                    </Table.Cell>
                                    <Table.Cell style={{textAlign:'center'}}>
                                      <Button negative content="-" size="mini" onClick={_.debounce(() => this.props.removeSurveyOption(data.recordID), 10000, true)} />
                                    </Table.Cell>
                                  </tr>
                                )}
                              </Draggable>
                            </Fragment>
                          ))}
                          <Table.Row>
                            <Table.Cell />
                            <Table.Cell><Input value={this.props.surveys.new.option.option} name="option" placeholder="New Group Option" onChange={(e:any, data:any) => this.props.changeNewSurveyOption('option', data.value.validate())} style={{width:'100%'}} /></Table.Cell>
                            <Table.Cell style={{textAlign:'center'}}><Button positive content="+" size="mini" onClick={_.debounce(this.props.addSurveyOption, 10000, true)} style={{width:'100%'}} /></Table.Cell>
                            <Table.Cell />
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </div>
                  )}
                </Droppable>
              </DragDropContext> : null}
            </Grid.Column>
          </Grid>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { surveys: state.admin.surveys } },
  AdminStore.actionCreators
)(SurveyOptions as any)