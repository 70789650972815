import React, { Fragment } from 'react'

type ReportProps =
    { pdf: any, options: any }

class Footer extends React.PureComponent<ReportProps> {
    public render() {
        return (<Fragment>
            <div style={{fontSize:'0.7em',fontFamily:'Inconsolata',color:'grey',display:'flex',justifyContent:'space-between',alignItems:'flex-end',marginBottom:20,marginLeft:30,marginRight:30}}>
                <div style={{verticalAlign:'bottom',marginLeft:10}}>
                    <div>{new Date().today() + " @ " + new Date().timeNow()}</div>
                </div>
                <div style={{textAlign:'center'}}>
                    <div>{`{{page}} / {{total}}`}</div>
                    <div>{this.props.pdf.copyright}</div>
                </div>
                <div style={{verticalAlign:'bottom',marginRight:10}}>
                    <div>{this.props.pdf.staffID} / {this.props.pdf.printID}</div>
                </div>
            </div>
        </Fragment>)
    }
}

export default Footer
