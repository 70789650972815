import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store'
import * as ReportsStore from '../../store/reducers/reports'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import { Editor } from 'medium-draft'
import mediumDraftExporter from 'medium-draft/lib/exporter';
import {
  Header,
  Form,
  Button,
  Select,
  Divider,
  Input,
  Checkbox
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { admin: boolean } &
    ReportsStore.ReportsState &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class Recidivism extends React.PureComponent<ReportProps> {
    public params() {
      let params = new URLSearchParams()
      for (let [key, value] of Object.entries(this.props.reports.recidivism.data)) {
        if (value != undefined && value != null && value != '') {
            if (typeof value == 'object') {
                for (let ovalue of Object.values(value as any)) {
                  params.append(key, ovalue as string)
                }
            } else {
                params.append(key, (value as any).toString())
            }
        }
      }
      return params.toString()
    }

    public render() {
      return (
        <Fragment>
          <Form>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Report type'
                placeholder='Report type'
                options={this.props.reports.recidivism.data.reportTypes}
                value={this.props.reports.recidivism.data.reportType}
                onChange={(e:any, data:any) => this.props.dataChange('recidivism', 'reportType', data.value)}
                width={4}
              />
            </Form.Group>
          </Form>
          <Header as="h2">Recidivism Report</Header>
          {this.props.admin ? <Fragment>
            <div style={{border:'thin solid #ddd',padding:1,borderRadius:5,marginBottom:12}}>
              <Editor placeholder="Enter a report description..." editorState={this.props.reports.recidivism.description} sideButtons={[]} onChange={(state:any) => this.props.updateDescription(state)} />
            </div>
            <Button positive content='Save' onClick={this.props.saveDescription} />
          </Fragment> : null}
          {this.props.admin ? null : <div style={{whiteSpace:'normal'}} dangerouslySetInnerHTML={{__html: mediumDraftExporter(this.props.reports.recidivism.description.getCurrentContent())}} />}
          <Divider />
          <Form>
            <Form.Group>
              <Form.Field
                control={DatePicker}
                label="Beginning date"
                name="startDate"
                placeholder="MM/DD/YYYY"
                selected={this.props.reports.recidivism.data.startDate ? new Date(this.props.reports.recidivism.data.startDate) : null}
                onChange={(date:any) => this.props.dataChange('recidivism', 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                width={4}
              />
              <Form.Field
                control={DatePicker}
                label="Ending date"
                name="endDate"
                placeholder="MM/DD/YYYY"
                selected={this.props.reports.recidivism.data.endDate ? new Date(this.props.reports.recidivism.data.endDate) : null}
                onChange={(date:any) => this.props.dataChange('recidivism', 'endDate', date ? format(date, 'MM/dd/yyyy') : null)}
                width={4}
              />
              <Form.Field
                control={Select}
                label='Display type'
                placeholder='Display type'
                options={[{key:0,value:'html',text:'HTML'},{key:1,value:'pdf',text:'PDF'}]}
                value={this.props.reports.recidivism.data.displayType}
                onChange={(e:any, data:any) => this.props.dataChange('recidivism', 'displayType', data.value)}
                width={4}
              />
            </Form.Group>
            {false ? <Form.Group>
              <Form.Field
                control={Select}
                label='Include administrative deletes'
                options={[{key:0,value:false,text:'No'},{key:1,value:true,text:'Yes'}]}
                value={this.props.reports.recidivism.data.adminDeletes}
                onChange={(e:any, data:any) => this.props.dataChange('recidivism', 'adminDeletes', data.value)}
                width={4}
              />
            </Form.Group> : null}
            {this.props.reports.recidivism.data.displayType == 'html' ? <Button color='blue' content='Generate report' as={Link} to={`/reports/recidivism/html?${this.params()}`} target='_window' /> : null}
            {this.props.reports.recidivism.data.displayType != 'html' ? <Button color='blue' content='Generate report' onClick={this.props.generateRecidivism} /> : null}
          </Form>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { ...state.reports, admin: state.staff.staff!.admin } },
  ReportsStore.actionCreators
)(Recidivism as any)