import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Grid,
  Input,
  Select,
  Radio,
  Checkbox,
  TextArea,
  Button,
  Header,
  Divider,
  Icon,
  Table,
  Modal,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type TestProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean, hide: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateChangelog extends React.PureComponent<TestProps> {
    public componentDidMount() {
        this.props.fetchInmateChangelog(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      return (
        <Container fluid>
          <Header as='h3'>Changelog</Header>
          <Table compact celled striped selectable style={{marginBottom:20}}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Column</Table.HeaderCell>
                <Table.HeaderCell>Replaced value</Table.HeaderCell>
                <Table.HeaderCell>Updated value</Table.HeaderCell>
                <Table.HeaderCell>Updated By</Table.HeaderCell>
                <Table.HeaderCell>Timestamp</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.props.inmate.changelog.map((update:any) => (
                <Table.Row key={update.recordID}>
                  <Table.Cell>{update.column}</Table.Cell>
                  <Table.Cell>{update.replacedValue}</Table.Cell>
                  <Table.Cell>{update.updatedValue}</Table.Cell>
                  <Table.Cell>{update.staffName}</Table.Cell>
                  <Table.Cell>{update.timestamp}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print, hide: ownProps.hide } },
  PanesStore.actionCreators
)(InmateChangelog as any)