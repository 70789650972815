import React, { Fragment } from 'react'
import { format } from 'date-fns'
import {
  Image,
  Container,
  Table
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class ClassEnrollment extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <html lang="en">
            <head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Roboto') || document.fonts.check('bold 1em Roboto'))
              }`}} />
            </head>
            <body style={{fontSize:'1.2em',lineHeight:'1.2em',fontFamily:'Roboto',backgroundColor:'white !important'}}>
              {this.report()}
            </body>
          </html>
        )
    }

    public report() {
        let data = this.props.report.data

        const dayrow = (schedules:any, day:number, row:number) => {
          var schedules = _.filter(schedules, (schedule:any) => { return schedule.dayOfWeek == day })
          return schedules[row]
        }

        const weekday = (schedules:any, day:number) => {
          return _.filter(schedules, (schedule:any) => { return schedule.dayOfWeek == day })
        }

        const daymax = (schedules:any) => {
          var mon = weekday(schedules, 1).length
          var tue = weekday(schedules, 2).length
          var wed = weekday(schedules, 3).length
          var thu = weekday(schedules, 4).length
          var fri = weekday(schedules, 5).length
          return _.max([mon,tue,wed,thu,fri])
        }

        return (
            <Container fluid>
              {data.rosters.map((roster: any) => (
                <div key={roster.recordID} style={{pageBreakAfter:'always'}}>
                  <div style={{flexDirection:'column'}}>
                    <div>
                      <div style={{flexDirection:'row'}}>
                        <Image src="https://sms.psd-hi.com/assets/HawaiiSeal.png" style={{float:'left',width:70}} />
                        <Image src="https://sms.psd-hi.com/assets/RightGraphic.jpg" style={{float:'right',width:70}} />
                        <div style={{flexDirection:'column',textAlign:'center',fontWeight:700}}>
                          <div>State of Hawai‘i</div>
                          <div>{this.props.settings.deptName}</div>
                          <div>Education Services</div>
                          <div>Notification of Enrollment</div>
                          <div>{format(new Date(), "MM/dd/yyyy")}</div>
                        </div>
                      </div>
                      <div style={{marginTop:40,flexDirection:'column'}}>
                        <div>
                          <span style={{marginRight:5,fontWeight:700}}>To:</span>
                          <span>{roster.lastName + ", " + roster.firstName}{roster.housing ? " - Housing: " + roster.housing : " - Housing: _____"}</span>
                        </div>
                        <div style={{marginTop:10}}>
                          <span style={{marginRight:5,fontWeight:700}}>From:</span>
                          <span>Education Unit</span>
                        </div>
                        <div style={{marginTop:10}}>
                          <span style={{marginRight:5,fontWeight:700}}>Subject:</span>
                          <span>{roster.className}</span>
                        </div>
                        <div style={{marginTop:10}}>
                          <span style={{marginRight:5,fontWeight:700}}>Start Date:</span>
                          <span>{roster.dateStart.length > 0 ? roster.dateStart : "NONE"}</span>
                        </div>
                        <div style={{marginTop:20}}>
                          <span>You are scheduled to attend this class which will be held in the Education Unit on the dates shown. If you are unable to attend because of some other activity, please notify us as soon as possible.</span>
                        </div>
                        <div style={{marginTop:20}}>
                          <div style={{fontWeight:700}}>Here is your new schedule{data.startDate ? ` for the week of ${data.startDate} - ${data.endDate}.` : ''}:</div>
                          <Table compact celled striped definition unstackable style={{fontSize:'0.9em'}}>
                            <Table.Header>
                              <Table.Row>
                                <Table.HeaderCell width={1}></Table.HeaderCell>
                                <Table.HeaderCell width={3}>Monday</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Tuesday</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Wednesday</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Thursday</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Friday</Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              {roster.times.map((time:any) => Array.from(Array(daymax(time.schedules))).map((_, i) => {
                                var row1 = dayrow(time.schedules, 1, i)
                                var row2 = dayrow(time.schedules, 2, i)
                                var row3 = dayrow(time.schedules, 3, i)
                                var row4 = dayrow(time.schedules, 4, i)
                                var row5 = dayrow(time.schedules, 5, i)
                                return (
                                  <Table.Row key={i}>
                                    <Table.Cell><span style={{whiteSpace:'nowrap'}}>{time.time}</span></Table.Cell>
                                    <Table.Cell style={{fontSize:'0.9em'}}>{row1 ? <Fragment>{row1.classID} - <span style={{fontWeight:700}}>{row1.className}</span>{data.showDates ? <span> [{row1.startDate}]</span> : null}</Fragment> : null}</Table.Cell>
                                    <Table.Cell style={{fontSize:'0.9em'}}>{row2 ? <Fragment>{row2.classID} - <span style={{fontWeight:700}}>{row2.className}</span>{data.showDates ? <span> [{row2.startDate}]</span> : null}</Fragment> : null}</Table.Cell>
                                    <Table.Cell style={{fontSize:'0.9em'}}>{row3 ? <Fragment>{row3.classID} - <span style={{fontWeight:700}}>{row3.className}</span>{data.showDates ? <span> [{row3.startDate}]</span> : null}</Fragment> : null}</Table.Cell>
                                    <Table.Cell style={{fontSize:'0.9em'}}>{row4 ? <Fragment>{row4.classID} - <span style={{fontWeight:700}}>{row4.className}</span>{data.showDates ? <span> [{row4.startDate}]</span> : null}</Fragment> : null}</Table.Cell>
                                    <Table.Cell style={{fontSize:'0.9em'}}>{row5 ? <Fragment>{row5.classID} - <span style={{fontWeight:700}}>{row5.className}</span>{data.showDates ? <span> [{row5.startDate}]</span> : null}</Fragment> : null}</Table.Cell>
                                  </Table.Row>
                                )
                              }))}
                            </Table.Body>
                          </Table>
                        </div>
                        {(data.comments && data.comments.length > 0) || (roster.suspended || roster.quarantined) ? <div style={{marginTop:40}}>
                          <div style={{fontWeight:700}}>Comments:</div>
                          {data.comments && data.comments.length > 0 ? <div style={{marginTop:4}}>{data.comments}</div> : null}
                          {roster.suspended ? <div style={{marginTop:4}}>{roster.firstName + " " + roster.lastName} is suspended.</div> : null}
                          {roster.quarantined ? <div style={{marginTop:4}}>{roster.firstName + " " + roster.lastName} is quarantined.</div> : null}
                        </div> : null}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Container>
          )
    }
}

export default ClassEnrollment