import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import * as ReportsStore from '../../store/reducers/reports'
import NotificationsWarningPDF from '../reports/pdf/NotificationsWarning'
import ClassEnrollmentPDF from '../reports/pdf/ClassEnrollment'
import UnexcusedAbsencePDF from '../reports/pdf/UnexcusedAbsence'
import DatePicker from 'react-datepicker'
import { format, addDays } from 'date-fns'
import {
  Container,
  Form,
  Select,
  TextArea,
  Button,
  Header,
  Divider
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { class: PanesStore.Class, num: 1 | 2 } &
    PanesStore.PanesState &
    ReportsStore.ReportsState &
    typeof PanesStore.actionCreators &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class ClassNotificationLetters extends React.PureComponent<RosterProps> {
    public params() {
      let params = new URLSearchParams()
      for (let [key, value] of Object.entries(this.props.class.bulk.rosters)) {
        params.append('rosters', (value as any).toString())
      }
      for (let [key, value] of Object.entries(this.props.class.notifications)) {
        if (key != "inmates") { params.append(key, (value as any).toString()) }
      }
      return params.toString()
    }

    public friday(date: Date) {
      date = addDays(date, 1)
      while (date.getDay() != 5) {
        date = addDays(date, 1)
      }
      return date
    }

    public render() {
      var bulk = this.props.class.bulk
      var rosters = this.props.class.rosters
      return (
        <Container fluid>
          <div style={{display:'inline-block'}}><Header as='h3'>Notification Letters</Header></div>
          <Button color='blue' circular icon='print' onClick={() => this.props.class.notifications.type == 'warning' ? this.props.generateWarning(this.props.num) : this.props.class.notifications.type == 'enrollment' ? this.props.generateEnrollment(this.props.num) : this.props.class.notifications.type == 'unexcused' ? this.props.generateUnexcused(this.props.num) : null} style={{marginLeft:10}} />
          {this.props.class.notifications.type == 'enrollment' ? <ClassEnrollmentPDF num={this.props.num} report={this.props.class.pdfs.enrollment} options={{schedule: this.props.class.notifications.schedule}} /> : null}
          {this.props.class.notifications.type == 'warning' ? <NotificationsWarningPDF num={this.props.num} report={this.props.class.pdfs.warning} options={{}} /> : null}
          {this.props.class.notifications.type == 'unexcused' ? <UnexcusedAbsencePDF num={this.props.num} report={this.props.class.pdfs.unexcused} options={{}} /> : null}
          <Form>
            <Form.Group style={{marginLeft:0,marginRight:0,marginTop:10}}>
              <Form.Field
                control={Select}
                label='Inmates'
                placeholder='Inmates'
                options={_.map(rosters, (roster:any) => { return { key: roster.recordID, value: roster.recordID, text: `${roster.lastName}, ${roster.firstName}` } })}
                value={bulk.rosters || []}
                onChange={(e:any, data:any) => this.props.editBulkField(this.props.num, 'rosters', data.value as number[])}
                width={16}
                multiple
              />
            </Form.Group>
            <Form.Group style={{marginLeft:0,marginRight:0}}>
              <Form.Field
                control={Select}
                label='Notification Type'
                placeholder='Notification Type'
                options={[{key:0,value:'enrollment',text:'Class Enrollment'},{key:1,value:'warning',text:'Warning Notification'},{key:2,value:'unexcused',text:'Unexcused Absence Letter'}]}
                value={this.props.class.notifications.type}
                onChange={(e:any, data:any) => { this.props.updateNotificationType(this.props.num, data.value as string)} }
                width={4}
              />
              {this.props.class.notifications.type == 'enrollment' ? <Form.Field
                control={Select}
                label='Schedule Type'
                placeholder='Schedule Type'
                options={[{key:0,value:'class',text:'Class Schedule'},{key:1,value:'full',text:'Full Schedule'}]}
                value={this.props.class.notifications.schedule}
                onChange={(e:any, data:any) => this.props.updateNotificationSchedule(this.props.num, data.value as string)}
                width={4}
              /> : null}
              {this.props.class.notifications.type == 'enrollment' ? <Form.Field
                control={Select}
                label='Show Start Dates'
                options={[{key:0,value:false,text:'No'},{key:1,value:true,text:'Yes'}]}
                value={this.props.class.notifications.showDates}
                onChange={(e:any, data:any) => this.props.updateNotificationShowDates(this.props.num, data.value as boolean)}
                width={4}
              /> : null}
              {this.props.class.notifications.type == 'enrollment' ? <Form.Field
                control={DatePicker}
                label="Start Date"
                placeholder="Start Date"
                selected={this.props.class.notifications.startDate ? new Date(this.props.class.notifications.startDate) : null}
                onChange={(date:any) => this.props.updateNotificationDates(this.props.num, (date ? format(date, 'MM/dd/yyyy') : null), (date ? format(this.friday(date), 'MM/dd/yyyy') : null))}
                width={4}
              /> : null}
              {this.props.class.notifications.type == 'enrollment' ? <Form.Field
                control={DatePicker}
                label="End Date"
                placeholder="End Date"
                selected={this.props.class.notifications.endDate ? new Date(this.props.class.notifications.endDate) : null}
                onChange={(date:any) => {}}
                width={4}
              /> : null}
              {this.props.class.notifications.type == 'unexcused' ? <Form.Field
                control={Select}
                label='Staff'
                placeholder='Staff'
                options={[{key:0,value:0,text:''}].concat(this.props.class.options.staff)}
                value={this.props.class.notifications.staffID}
                onChange={(e:any, data:any) => this.props.updateNotificationStaff(this.props.num, data.value as number)}
                width={4}
              /> : null}
            </Form.Group>
            <Form.Group style={{marginLeft:0,marginRight:0}}>
              <Form.Field
                control={TextArea}
                label='Comments'
                placeholder='Comments'
                value={this.props.class.notifications.comments}
                onChange={(e:any, data:any) => this.props.updateNotificationComments(this.props.num, data.value as string)}
                width={16}
              />
            </Form.Group>
          </Form>
          <Divider />
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, ...state.reports } },
  { ...PanesStore.actionCreators, ...ReportsStore.actionCreators }
)(ClassNotificationLetters as any)