import React, { Fragment } from 'react'
import {
  Dimmer,
  Loader,
  Table,
  Container
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class HiSET extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let params = this.props.report.data
        let data = this.props.report.generated
        let options = this.props.options
        let facilities = typeof params.facilities == 'string' ? [params.facilities] : params.facilities
        let num = 0

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>HiSET Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
            </div>
            <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == "standard" ? 'Standard' : 'Graduated'}</div></div>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Sort by:</div><div style={{display:'inline-block',fontWeight:400}}>{params.sortBy == "name" ? 'Inmate name' : 'Test date'}</div></div>
              <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Facilities:</div><div style={{display:'inline-block',fontWeight:400}}>{params.facilities == '0' ? 'All' : _.map(facilities, (id:any) => _.find(options.facilities, (f:any) => f.value == id).text).join(', ')}</div></div>
            </div>
            <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
              <Table.Body>
                {data.facilities.length == 0 ? <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                  <Table.HeaderCell style={{backgroundColor:'white',padding:6,whiteSpace:'nowrap'}}>No data available</Table.HeaderCell>
                </Table.Row> : null}
                {data.facilities.map((facility:any, f:number) => (
                  <Fragment key={facility.abbreviation}>
                    <Table.Row style={{background:'rgba(0,0,50,.02)'}}>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Inmate</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>SID</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0}}><div><span>Last Test Date</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Reading</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Writing</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Mathematics</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Science</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Social Studies</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Essay</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:40}}><div><span>Grad Date</span></div></Table.HeaderCell>
                      <Table.HeaderCell className='rotate' style={{border:0,width:80}}><div><span style={{paddingRight:20}}></span></div></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row positive>
                      <Table.HeaderCell colSpan={10} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)',paddingTop:4,paddingBottom:4}}><b>{facility.abbreviation}</b></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0,width:80,background:'rgb(249, 250, 251)'}}></Table.HeaderCell>
                    </Table.Row>
                    {facility.inmates.map((inmate:any, i:number) => {
                      num += 1
                      return (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={inmate.recordID}>
                          <Table.Cell warning={inmate.highlight}><div style={{whiteSpace:'nowrap'}}>{inmate.name}</div></Table.Cell>
                          <Table.Cell warning={inmate.highlight}>{inmate.sid}</Table.Cell>
                          <Table.Cell warning={inmate.highlight}>{inmate.testDate}</Table.Cell>
                          <Table.Cell warning={inmate.highlight || inmate.readingHighlight}>{inmate.reading}</Table.Cell>
                          <Table.Cell warning={inmate.highlight || inmate.writingHighlight}>{inmate.writing}</Table.Cell>
                          <Table.Cell warning={inmate.highlight || inmate.mathHighlight}>{inmate.math}</Table.Cell>
                          <Table.Cell warning={inmate.highlight || inmate.scienceHighlight}>{inmate.science}</Table.Cell>
                          <Table.Cell warning={inmate.highlight || inmate.socialStudiesHighlight}>{inmate.socialStudies}</Table.Cell>
                          <Table.Cell warning={inmate.highlight || inmate.essayHighlight}>{inmate.essay}</Table.Cell>
                          <Table.Cell warning={inmate.highlight}>{inmate.gradDate}</Table.Cell>
                          <Table.Cell style={{background:'rgb(249, 250, 251)',borderTop:0}}></Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Fragment>
                ))}
              </Table.Body>
            </Table>
          </Container>
        )
    }
}

export default HiSET
