import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../withRouter'
import { ApplicationState } from '../store'
import * as StaffStore from '../store/reducers/staff'
import {
  Container,
  Form,
  Input,
  Button
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ResetProps =
    { auth: boolean, password: string } &
    typeof StaffStore.actionCreators &
    WithRouterProps<{ code: string }>

class ResetPassword extends React.PureComponent<ResetProps> {
    public componentDidMount() {
        this.props.validateReset(this.props.match.params.code)
    }

    public render() {
        return (
          <Container fluid>
            {this.props.auth ? <Form style={{background:'white',margin:'auto',width:600,padding:50,borderRadius:20,marginTop:40,border:'thin solid #ddd'}}>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label="New Password"
                  placeholder="New Password"
                  type="password"
                  value={this.props.password}
                  onChange={(e:any, data:any) => this.props.setReset(data.value)}
                  width={16}
                />
              </Form.Group>
              <Button color='blue' content='Reset Password' onClick={_.debounce(() => this.props.resetPassword(this.props.match.params.code), 10000, true)} />
            </Form> : null}
          </Container>
        )
    }
}

export default withRouter(connect(
    (state: ApplicationState) => { return { auth: state.staff.reset.auth, password: state.staff.reset.password } },
    StaffStore.actionCreators
)(ResetPassword as any))