import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format, getDay } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Search,
  Checkbox,
  Button,
  Select,
  Modal,
  Popup,
  Divider,
  Accordion,
  Table,
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type RosterProps =
    { class: PanesStore.Class, num: 1 | 2, staff: any } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassRosterMoveSchedules extends React.PureComponent<RosterProps> {
    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    public render() {
        const move = this.props.class.modals.move
        const schedules = this.props.class.move.schedules
        return (
          <Container fluid style={{marginTop:20}}>
            {schedules.length > 0 ? null : <div style={{marginLeft:6}}>No class meeting times set</div>}
            {schedules.map((schedule:any, i:number) => (
              <Fragment key={i}>
                <div style={{marginLeft:6,width:150,marginBottom:4,display:'inline-block'}}><Checkbox
                  label={`${this.dayOfWeek3(schedule.dayOfWeek)}. ${schedule.startTime} - ${schedule.endTime}`}
                  checked={_.any(move.data.schedules, (s:any) => s.recordID == schedule.recordID)}
                  onChange={() => this.props.toggleMoveSchedule(this.props.num, schedule)}
                /></div>
                {schedule.grouped.map((schedule:any, i:number) => (
                  <div style={{marginLeft:6,width:150,marginBottom:4,display:'inline-block'}}><Checkbox
                    label={`${this.dayOfWeek3(schedule.dayOfWeek)}. ${schedule.startTime} - ${schedule.endTime}`}
                    checked={_.any(move.data.schedules, (s:any) => s.recordID == schedule.recordID)}
                    onChange={() => this.props.toggleMoveSchedule(this.props.num, schedule)}
                  /></div>
                ))}
              </Fragment>
            ))}
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, staff: state.staff.staff } },
  PanesStore.actionCreators
)(ClassRosterMoveSchedules as any)