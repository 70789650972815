import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import AppSite from './AppSite'
import AppClasses from './AppClasses'
import AppLanding from './AppLanding'
import {
  Container,
  Tab,
  Form,
  Input,
  Select
} from 'semantic-ui-react'
const _ = require('underscore')

type AppProps =
    {  } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class AppSettings extends React.PureComponent<AppProps> {
    public render() {
      var panes = [
        { menuItem: 'General', render: () => <Tab.Pane><AppSite /></Tab.Pane> },
        { menuItem: 'Classes', render: () => <Tab.Pane><AppClasses /></Tab.Pane> },
        { menuItem: 'Landing', render: () => <Tab.Pane><AppLanding /></Tab.Pane> }
      ]

      return (
        <Container fluid>
          <Tab menu={{ secondary: true }} panes={panes} />
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return {  } },
  AdminStore.actionCreators
)(AppSettings as any)
