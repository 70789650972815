import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { renderToString } from 'react-dom/server'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import { pdfshift } from '../../../agent'
import * as ClassesStore from '../../../store/reducers/classes'
import Classes from '../shift/Classes'
import Footer from '../shift/NumFooter'
import {
  Icon
} from 'semantic-ui-react'

const css = require("!!css-loader!../../../custom.css");

type ReportProps =
    { report: any, dates: boolean, settings: any } &
    typeof ClassesStore.actionCreators &
    WithRouterProps<{}>

class ClassesPDF extends React.PureComponent<ReportProps> {
    public componentDidUpdate(prevProps:any) {
      if (!prevProps.report.ready && this.props.report.ready) {
        const pdf = renderToString(<Classes report={this.props.report} options={{ dates: this.props.dates }} settings={this.props.settings} />);
        const footer = renderToString(<Footer report={this.props.report} options={{}} settings={this.props.settings} />);

        pdfshift({
            source: pdf,
            footer: {
              source: footer,
              height: 70
            },
            margin: {
              top: 30,
              bottom: 0,
              left: 30,
              right: 30
            },
            landscape: true,
            css: css.default.toString(),
            format: 'Letter',
            wait_for: 'ready',
            filename: 'classes.pdf'
        }).then((response:any) => {
          this.props.setURL('classes', response.data.url)
        })
      }
    }

    public render() {
        return (
          <Fragment>
            {this.props.report.generating || (this.props.report.ready && this.props.report.url.length == 0) ? <div className='pdf generating'>
              <Icon.Group style={{marginTop:12,marginRight:10}}>
                <Icon name='circle notch' color='grey' size='big' loading  />
                <Icon name='file pdf outline' color='grey' size='small' style={{fontSize:'1em',marginLeft:-1}} />
              </Icon.Group>
            </div> : null}
            {this.props.report.ready && this.props.report.url.length > 0 ? <a target='_window' href={this.props.report.url}>
              <div className='pdf ready' style={{marginTop:12,marginRight:10}}>
                <Icon name='file pdf outline' size='big' />
              </div>
            </a> : null}
          </Fragment>
        )
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.classes.tabs.classes.pdf, dates: state.classes.dates, settings: state.staff.settings } },
  ClassesStore.actionCreators
)(ClassesPDF as any)