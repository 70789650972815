import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../withRouter'
import { ApplicationState } from '../store'
import * as DashboardStore from '../store/reducers/dashboard'
import * as DrawerStore from '../store/reducers/drawer'
import * as PanesStore from '../store/reducers/panes'
import * as AdminStore from '../store/reducers/admin'
import { format, addMonths, addYears } from 'date-fns'
import DatePicker from 'react-datepicker'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import mediumDraftExporter from 'medium-draft/lib/exporter';
import {
  Button,
  Table,
  Dimmer,
  Loader,
  Segment,
  Container,
  Icon,
  Modal,
  Form,
  Checkbox,
  Popup,
  Label,
  Radio,
  Search,
  Select
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type TableProps =
    { panes: any, tab: string, mode: string, admin: boolean } &
    DashboardStore.DashboardState &
    typeof DashboardStore.actionCreators &
    typeof DrawerStore.actionCreators &
    typeof PanesStore.actionCreators &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class Dashboard extends React.PureComponent<TableProps> {
    public componentDidMount() {
        let facility = 'all'
        if (this.props.admin) {
          this.props.loadFilter('all')
        } else {
          this.props.loadFilter('my')
          facility = 'my'
        }
        this.props.fetchDashboardOptions()
        this.props.fetchNotifications(facility)
        this.props.fetchNews(facility)
        this.props.fetchDashboardStaff(facility)
        this.props.fetchOrphans(facility)
        this.props.fetchApprovals(facility)
        this.props.fetchTABEs(facility)
        this.props.fetchSchools(facility)
        this.props.fetchTranscripts(facility)
        this.props.fetchFunding(facility)
        this.props.fetchInstructors(facility)
        this.props.fetchClasses(facility)
        this.props.fetchArrivals(facility)
        this.props.fetchPendingAttendance(facility)
        this.props.fetchLowAttendance(facility)
        this.props.fetchAnds(facility)

        /*let params = qs.parse(window.location.search.substr(1, window.location.search.length))
        if (params.dashboard) {
          setTimeout(() => {
            var container = document.getElementById('dashboard')
            var el = document.getElementById(params.dashboard as string)
            if (container && el) {
              container!.scrollTop = el!.offsetTop
            }
          }, 1000)
        }*/
    }

    private dayOfWeek3 = (day:number) => {
      switch(day) {
        case 0: {
          return 'Sun'
        }
        case 1: {
          return 'Mon'
        }
        case 2: {
          return 'Tue'
        }
        case 3: {
          return 'Wed'
        }
        case 4: {
          return 'Thu'
        }
        case 5: {
          return 'Fri'
        }
        case 6: {
          return 'Sat'
        }
        default: {
          return ''
        }
      }
    }

    private HeaderArrow = (props:any) => {
      var sort = _.find(this.props[props.table as DashboardStore.DashboardIndex].sorts, (sort:any) => sort.column == props.orderBy)
      if (sort != null) {
        if (sort.dir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    private onDragEnd = (result:any) => {
        var {draggableId, source, destination} = result
        var newOrder = this.props.order
        var order = _.find(newOrder, (o:any) => { return o.name == draggableId })
        newOrder = _.filter(newOrder, (o:any) => { return o.name != draggableId })
        newOrder.splice(destination.index, 0, order)
        this.props.reorder(newOrder)
    }

    public render() {
      const HeaderArrow = this.HeaderArrow
      const today = new Date(new Date().toDateString())
      return (
        <Container id='dashboard' fluid style={{overflow:'auto',position:'absolute',top:80,left:0,right:0,bottom:0,paddingRight:8}} key={this.props.refresh}>
          <div style={{marginBottom:20}}>
            <Form>
              <Form.Field
                control={Select}
                options={[{ key: 'all', value: 'all', text: 'All facilities' },{ key: 'my', value: 'my', text: 'My facilities' }].concat(this.props.options.facilities)}
                value={this.props.facility}
                onChange={(e:any, data:any) => {
                  this.props.setFacility(data.value)
                  this.props.fetchNotifications(data.value)
                  this.props.fetchNews(data.value)
                  this.props.fetchDashboardStaff(data.value)
                  this.props.fetchOrphans(data.value)
                  this.props.fetchApprovals(data.value)
                  this.props.fetchTABEs(data.value)
                  this.props.fetchSchools(data.value)
                  this.props.fetchTranscripts(data.value)
                  this.props.fetchFunding(data.value)
                  this.props.fetchInstructors(data.value)
                  this.props.fetchClasses(data.value)
                  this.props.fetchArrivals(data.value)
                  this.props.fetchPendingAttendance(data.value)
                  this.props.fetchLowAttendance(data.value)
                  this.props.fetchAnds(data.value)
                }}
                width={4}
              />
            </Form>
          </div>
          {this.props.news.notices ? <Table id='notices' compact celled striped>
            <Table.Header>
              <Table.Row colSpan={6} warning>
                <Table.HeaderCell colSpan={6} style={{background: "none", fontSize: 24 }}>
                  <div><Icon name="bullhorn" /> Notices</div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell><div style={{whiteSpace:'normal'}} dangerouslySetInnerHTML={{__html: mediumDraftExporter(this.props.news.notices.getCurrentContent())}} /></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table> : null}
          {this.props.news.announcements ? <Table id='announcements' compact celled striped>
            <Table.Header>
              <Table.Row colSpan={6} warning>
                <Table.HeaderCell colSpan={6} style={{background: "none", fontSize: 24 }}>
                  <div><Icon name="bell" color="red" /> Announcements</div>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell><div style={{whiteSpace:'normal'}} dangerouslySetInnerHTML={{__html: mediumDraftExporter(this.props.news.announcements.getCurrentContent())}} /></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table> : null}
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId={'0'}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {this.props.order.map((o:any) => {
                    switch (o.name) {
                    case 'staff':
                      return (
                        <Draggable draggableId='staff' index={o.order} key={o.order}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div style={{marginBottom:'1em'}}>
                                <Table id='staff' compact celled striped {...provided.dragHandleProps}>
                                 <Table.Header>
                                   <Table.Row warning>
                                     <Table.HeaderCell colSpan={6} style={{ background: "none" }}>
                                       <div className='pointer' style={{float:'right'}} onClick={() => this.props.updateStaffCollapse('staff')}><Label><Icon name={`caret ${this.props.staff.collapse ? 'left' : 'down'}`} />{this.props.staff.data.length}</Label></div>
                                       <Popup position='right center' content={`For security, all logins have an expiration date. Supervisors are able to reset or archive those in their facility except other Supervisors, and Admin are able to reset or archive staff in all facilities. Logins have a maximum expiration date of one year in the future.`} trigger={<div style={{display:'inline-block'}}>
                                         <Checkbox checked={this.props.staff.notify} style={{marginRight:10,verticalAlign:'middle'}} onChange={(e:any, data:any) => this.props.updateStaffNotification('staff', data.checked)} />Staff Users Expiring Soon
                                       </div>} />
                                     </Table.HeaderCell>
                                   </Table.Row>
                                   {this.props.staff.loading || this.props.staff.collapse || this.props.staff.data.length == 0 ? null : <Table.Row>
                                     <Table.HeaderCell onClick={() => this.props.toggleOrder('staff', 'name')}>Staff Name<HeaderArrow table='staff' orderBy='name' /></Table.HeaderCell>
                                     <Table.HeaderCell width={2} onClick={() => this.props.toggleOrder('staff', 'facilities')}>Facilities<HeaderArrow table='staff' orderBy='facilities' /></Table.HeaderCell>
                                     <Table.HeaderCell width={4} onClick={() => this.props.toggleOrder('staff', 'positions')}>Positions<HeaderArrow table='staff' orderBy='positions' /></Table.HeaderCell>
                                     <Table.HeaderCell width={2} onClick={() => this.props.toggleOrder('staff', 'date')}>Expiry Date<HeaderArrow table='staff' orderBy='date' /></Table.HeaderCell>
                                     <Table.HeaderCell colSpan={2} width={3}>Actions</Table.HeaderCell>
                                    </Table.Row>}
                                  </Table.Header>
                                  <Table.Body>
                                    {this.props.staff.loading ? <Table.Row>
                                        <Table.Cell style={{height:100}}><Segment style={{height:100}}><Dimmer active inverted><Loader content="Loading staff..." /></Dimmer></Segment></Table.Cell>
                                      </Table.Row> : this.props.staff.collapse ? null : this.props.staff.data.map((staff:any) => (
                                      <Table.Row key={staff.recordID} className={`${staff.daysLeft <= 7 ? 'warning' : ''}`}>
                                        <Table.Cell>
                                          {this.props.mode == 'dual' ? <Popup
                                            hideOnScroll
                                            on='click'
                                            trigger={<div className='link' style={{whiteSpace:'normal'}}>{staff.name}</div>}
                                          >
                                            <Popup.Header>Open in pane</Popup.Header>
                                            <Popup.Content>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchStaff(1, staff.recordID) }}>1</Label>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchStaff(2, staff.recordID) }}>2</Label>
                                            </Popup.Content>
                                          </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchStaff(this.props.mode == 'left' ? 1 : 2, staff.recordID) }}>{staff.name}</div>}
                                        </Table.Cell>
                                        <Table.Cell>{staff.facilities.join(", ")}</Table.Cell>
                                        <Table.Cell><div style={{whiteSpace:'normal'}}>{staff.positions.join(", ")}</div></Table.Cell>
                                        <Table.Cell>
                                          {staff.daysLeft <= 30 ? <Popup
                                            position='top center'
                                            on='hover'
                                            trigger={<div style={{whiteSpace:'normal',fontWeight:700}}>{staff.expDate}</div>}
                                          >
                                            <Popup.Content>Auto archives in {staff.daysLeft} days</Popup.Content>
                                          </Popup> : <div style={{whiteSpace:'normal'}}>{staff.expDate}</div>}
                                        </Table.Cell>
                                        <Table.Cell style={{textAlign:'center'}}>
                                          <Button color="black" content="Archive" size="mini" onClick={_.debounce(() => this.props.selectArchiveStaff(staff), 10000, true)} />
                                        </Table.Cell>
                                        <Table.Cell style={{textAlign:'center'}}>
                                          <Button positive content="Extend" size="mini" onClick={_.debounce(() => this.props.selectExtendStaff(staff), 10000, true)} />
                                          <Popup position='top center' content={`Extend six months`} trigger={<Button positive content="6" size="mini" onClick={_.debounce(() => this.props.selectExtendStaff(staff, addMonths(today, 6)), 10000, true)} />} />
                                          <Popup position='top center' content={`Extend twelve months`} trigger={<Button positive content="12" size="mini" onClick={_.debounce(() => this.props.selectExtendStaff(staff, addMonths(today, 12)), 10000, true)} />} />
                                        </Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    case 'approvals':
                      return (
                        <Draggable draggableId='approvals' index={o.order} key={o.order}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div style={{marginBottom:'1em'}}>
                                <Table id='approvals' compact celled striped {...provided.dragHandleProps}>
                                <Table.Header>
                                  <Table.Row warning>
                                    <Table.HeaderCell colSpan={6} style={{ background: "none" }}>
                                      <div className='pointer' style={{float:'right'}} onClick={() => this.props.updateStaffCollapse('approvals')}><Label><Icon name={`caret ${this.props.approvals.collapse ? 'left' : 'down'}`} />{this.props.approvals.data.length}</Label></div>
                                      <Checkbox checked={this.props.approvals.notify} style={{marginRight:10,verticalAlign:'middle'}} onChange={(e:any, data:any) => this.props.updateStaffNotification('approvals', data.checked)} />Pending Class Approvals
                                    </Table.HeaderCell>
                                  </Table.Row>
                                  {this.props.approvals.loading || this.props.approvals.collapse || this.props.approvals.data.length == 0 ? null : <Table.Row>
                                    <Table.HeaderCell width={1} onClick={() => this.props.toggleOrder('approvals', 'id')}>ID<HeaderArrow table='approvals' orderBy='id' /></Table.HeaderCell>
                                    <Table.HeaderCell width={1} onClick={() => this.props.toggleOrder('approvals', 'facility')}>Facility<HeaderArrow table='approvals' orderBy='facility' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('approvals', 'class')}>Class<HeaderArrow table='approvals' orderBy='class' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('approvals', 'inmate')}>Inmate<HeaderArrow table='approvals' orderBy='inmate' /></Table.HeaderCell>
                                    <Table.HeaderCell width={1} onClick={() => this.props.toggleOrder('approvals', 'date')}>Date<HeaderArrow table='approvals' orderBy='date' /></Table.HeaderCell>
                                    <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                                   </Table.Row>}
                                  </Table.Header>
                                  <Table.Body>
                                    {this.props.approvals.loading ? <Table.Row>
                                        <Table.Cell style={{height:100}}><Segment style={{height:100}}><Dimmer active inverted><Loader content="Loading approvals..." /></Dimmer></Segment></Table.Cell>
                                      </Table.Row> : this.props.approvals.collapse ? null : this.props.approvals.data.map((approval:any) => (
                                      <Table.Row key={approval.recordID}>
                                        <Table.Cell>{approval.classID}</Table.Cell>
                                        <Table.Cell>{approval.facility}</Table.Cell>
                                        <Table.Cell>
                                          {this.props.mode == 'dual' ? <Popup
                                            hideOnScroll
                                            on='click'
                                            trigger={<div className='link' style={{whiteSpace:'normal'}}>{approval.class}</div>}
                                          >
                                            <Popup.Header>Open in pane</Popup.Header>
                                            <Popup.Content>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, approval.classID, 1) }}>1</Label>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, approval.classID, 1) }}>2</Label>
                                            </Popup.Content>
                                          </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, approval.classID) }}>{approval.class}</div>}
                                        </Table.Cell>
                                        <Table.Cell>{approval.inmate}</Table.Cell>
                                        <Table.Cell>{approval.date}</Table.Cell>
                                        <Table.Cell style={{textAlign:'center'}}><Button positive content="Approve" size="mini" onClick={_.debounce(() => this.props.approveDashApproval(approval.classID, approval.recordID), 10000, true)} /><Button negative content="Deny" size="mini" onClick={_.debounce(() => this.props.denyDashApproval(approval.classID, approval.recordID), 10000, true)} /></Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    case 'orphans':
                      return (
                        <Draggable draggableId='orphans' index={o.order} key={o.order}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div style={{marginBottom:'1em'}}>
                                <Table id='orphans' compact celled striped {...provided.dragHandleProps}>
                                <Table.Header>
                                  <Table.Row warning>
                                    <Table.HeaderCell colSpan={6} style={{ background: "none" }}>
                                      <div className='pointer' style={{float:'right'}} onClick={() => this.props.updateStaffCollapse('orphans')}><Label><Icon name={`caret ${this.props.orphans.collapse ? 'left' : 'down'}`} />{this.props.orphans.data.list ? this.props.orphans.data.list.length : 0}</Label></div>
                                      <Checkbox checked={this.props.orphans.notify} style={{marginRight:10,verticalAlign:'middle'}} onChange={(e:any, data:any) => this.props.updateStaffNotification('orphans', data.checked)} />DRC Orphans
                                    </Table.HeaderCell>
                                  </Table.Row>
                                  {this.props.orphans.loading || this.props.orphans.collapse || this.props.orphans.data.list.length == 0 ? null : <Table.Row>
                                    <Table.HeaderCell width={1} onClick={() => this.props.toggleOrder('orphans', 'id')}>TABE ID<HeaderArrow table='orphans' orderBy='id' /></Table.HeaderCell>
                                    <Table.HeaderCell width={2} onClick={() => this.props.toggleOrder('orphans', 'date')}>Test Date<HeaderArrow table='orphans' orderBy='date' /></Table.HeaderCell>
                                    <Table.HeaderCell width={2} onClick={() => this.props.toggleOrder('orphans', 'facility')}>Facility<HeaderArrow table='orphans' orderBy='facility' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('orphans', 'inmate')}>Import Name<HeaderArrow table='orphans' orderBy='inmate' /></Table.HeaderCell>
                                    <Table.HeaderCell width={3}>Assign To</Table.HeaderCell>
                                    <Table.HeaderCell width={1}>Actions</Table.HeaderCell>
                                   </Table.Row>}
                                  </Table.Header>
                                  <Table.Body>
                                    {this.props.orphans.loading ? <Table.Row>
                                        <Table.Cell style={{height:100}}><Segment style={{height:100}}><Dimmer active inverted><Loader content="Loading orphans..." /></Dimmer></Segment></Table.Cell>
                                      </Table.Row> : this.props.orphans.collapse ? null : this.props.orphans.data.list.map((orphan:any) => (
                                      <Table.Row key={orphan.recordID}>
                                        <Table.Cell>{orphan.recordID}</Table.Cell>
                                        <Table.Cell>{orphan.date}</Table.Cell>
                                        <Table.Cell>{orphan.facilityAbbr}</Table.Cell>
                                        <Table.Cell>{orphan.name}</Table.Cell>
                                        <Table.Cell>
                                          <Search
                                            placeholder='Search Inmates'
                                            loading={orphan.loading}
                                            onResultSelect={(e:any, data:any) => this.props.selectOrphanInmate(orphan.recordID, data.result)}
                                            onSearchChange={(e:any, data:any) => this.props.searchOrphanInmates(orphan.recordID, data.value as string)}
                                            results={this.props.orphans.data.inmates}
                                            value={orphan.value||''}
                                          />
                                        </Table.Cell>
                                        <Table.Cell style={{textAlign:'center'}}><Button color={orphan.id == undefined || orphan.id == 0 ? "grey" : "green"} content="Assign" size="mini" onClick={orphan.id == undefined || orphan.id == 0 ? null : _.debounce(() => this.props.assignOrphan(orphan), 10000, true)} /></Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    case 'pending':
                      return (
                        <Draggable draggableId='pending' index={o.order} key={o.order}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div style={{marginBottom:'1em'}}>
                                <Table id='pending' compact celled striped {...provided.dragHandleProps}>
                                <Table.Header>
                                  <Table.Row warning>
                                    <Table.HeaderCell colSpan={4} style={{ background: "none" }}>
                                      <div className='pointer' style={{float:'right'}} onClick={() => this.props.updateStaffCollapse('pending')}><Label><Icon name={`caret ${this.props.pending.collapse ? 'left' : 'down'}`} />{this.props.pending.data.length}</Label></div>
                                      <Checkbox checked={this.props.pending.notify} style={{marginRight:10,verticalAlign:'middle'}} onChange={(e:any, data:any) => this.props.updateStaffNotification('pending', data.checked)} />Pending Attendance
                                    </Table.HeaderCell>
                                  </Table.Row>
                                  {this.props.pending.loading || this.props.pending.collapse || this.props.pending.data.length == 0 ? null : <Table.Row>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('pending', 'facility')}>Facility<HeaderArrow table='pending' orderBy='facility' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('pending', 'id')}>Class ID<HeaderArrow table='pending' orderBy='id' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('pending', 'class')}>Class Name<HeaderArrow table='pending' orderBy='class' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('pending', 'name')}>Instructor Name<HeaderArrow table='pending' orderBy='name' /></Table.HeaderCell>
                                  </Table.Row>}
                                </Table.Header>
                                 <Table.Body>
                                    {this.props.pending.loading ? <Table.Row>
                                        <Table.Cell style={{height:100}}><Segment style={{height:100}}><Dimmer active inverted><Loader content="Loading pending attendance..." /></Dimmer></Segment></Table.Cell>
                                      </Table.Row> : this.props.pending.collapse ? null : this.props.pending.data.map((data:any) => (
                                      <Table.Row key={data.recordID}>
                                        <Table.Cell>{data.facilityAbbr}</Table.Cell>
                                        <Table.Cell>{data.recordID}</Table.Cell>
                                        <Table.Cell>
                                          {this.props.mode == 'dual' ? <Popup
                                            hideOnScroll
                                            on='click'
                                            trigger={<div className='link' style={{whiteSpace:'normal'}}>{data.className}</div>}
                                          >
                                            <Popup.Header>Open in pane</Popup.Header>
                                            <Popup.Content>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, data.recordID) }}>1</Label>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, data.recordID) }}>2</Label>
                                            </Popup.Content>
                                          </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, data.recordID) }}>{data.className}</div>}
                                        </Table.Cell>
                                        <Table.Cell>{data.instructorName}</Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    case 'transcripts':
                      return (
                        <Draggable draggableId='transcripts' index={o.order} key={o.order}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div style={{marginBottom:'1em'}}>
                                <Table id='transcripts' compact celled striped {...provided.dragHandleProps}>
                                <Table.Header>
                                  <Table.Row warning>
                                    <Table.HeaderCell colSpan={8} style={{ background: "none" }}>
                                      <div className='pointer' style={{float:'right'}} onClick={() => this.props.updateStaffCollapse('transcripts')}><Label><Icon name={`caret ${this.props.transcripts.collapse ? 'left' : 'down'}`} />{this.props.transcripts.data.length}</Label></div>
                                      <Popup position='bottom center' content={`These are transcript requests that have been made to other institutions on behalf of students and have not been marked as received.`} trigger={<div>
                                        <Checkbox checked={this.props.transcripts.notify} style={{marginRight:10,verticalAlign:'middle'}} onChange={(e:any, data:any) => this.props.updateStaffNotification('transcripts', data.checked)} />Transcript Requests
                                      </div>} />
                                    </Table.HeaderCell>
                                  </Table.Row>
                                  {this.props.transcripts.loading || this.props.transcripts.collapse || this.props.transcripts.data.length == 0 ? null : <Table.Row>
                                    <Table.HeaderCell width={1}></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('transcripts', 'inmate')}>Inmate name<HeaderArrow table='transcripts' orderBy='inmate' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('transcripts', 'school')}>School name<HeaderArrow table='transcripts' orderBy='school' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('transcripts', 'staff')}>Sent by<HeaderArrow table='transcripts' orderBy='staff' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('transcripts', 'date')}>Sent on<HeaderArrow table='transcripts' orderBy='date' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('transcripts', 'followup')}>Needs Followup<HeaderArrow table='transcripts' orderBy='followup' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('transcripts', 'hs')}>HS verified<HeaderArrow table='transcripts' orderBy='hs' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('transcripts', 'diploma')}>Diploma verified<HeaderArrow table='transcripts' orderBy='diploma' /></Table.HeaderCell>
                                   </Table.Row>}
                                  </Table.Header>
                                  <Table.Body>
                                    {this.props.transcripts.loading ? <Table.Row>
                                        <Table.Cell style={{height:100}}><Segment style={{height:100}}><Dimmer active inverted><Loader content="Loading transcripts..." /></Dimmer></Segment></Table.Cell>
                                      </Table.Row> : this.props.transcripts.collapse ? null : this.props.transcripts.data.map((request:any, i:number) => (
                                      <Table.Row key={request.recordID} warning={request.followup}>
                                        <Table.Cell>{i+1}</Table.Cell>
                                        <Table.Cell>
                                          {this.props.mode == 'dual' ? <Popup
                                            hideOnScroll
                                            on='click'
                                            trigger={<div className='link' style={{whiteSpace:'normal'}}>{request.inmateName}</div>}
                                          >
                                            <Popup.Header>Open in pane</Popup.Header>
                                            <Popup.Content>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(1, request.inmateID, 9) }}>1</Label>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(2, request.inmateID, 9) }}>2</Label>
                                            </Popup.Content>
                                          </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, request.inmateID, 9) }}>{request.inmateName}</div>}
                                        </Table.Cell>
                                        <Table.Cell>{request.schoolName}</Table.Cell>
                                        <Table.Cell>{request.staffName}</Table.Cell>
                                        <Table.Cell>{request.sent}</Table.Cell>
                                        <Table.Cell>{request.followup ? '✓' : ''}</Table.Cell>
                                        <Table.Cell>{request.hs ? '✓' : ''}</Table.Cell>
                                        <Table.Cell>{request.diploma ? '✓' : ''}</Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    case 'schools':
                      return (
                        <Draggable draggableId='schools' index={o.order} key={o.order}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div style={{marginBottom:'1em'}}>
                                <Table id='schools' compact celled striped {...provided.dragHandleProps}>
                                <Table.Header>
                                  <Table.Row warning>
                                    <Table.HeaderCell colSpan={6} style={{ background: "none" }}>
                                      <div className='pointer' style={{float:'right'}} onClick={() => this.props.updateStaffCollapse('schools')}><Label><Icon name={`caret ${this.props.schools.collapse ? 'left' : 'down'}`} />{this.props.schools.data.length}</Label></div>
                                      <Checkbox checked={this.props.schools.notify} style={{marginRight:10,verticalAlign:'middle'}} onChange={(e:any, data:any) => this.props.updateStaffNotification('schools', data.checked)} />Requests to Add Schools to Database
                                    </Table.HeaderCell>
                                  </Table.Row>
                                  {this.props.schools.loading || this.props.schools.collapse || this.props.schools.data.length == 0 ? null : <Table.Row>
                                    <Table.HeaderCell width={1} onClick={() => this.props.toggleOrder('schools', 'id')}><HeaderArrow table='schools' orderBy='id' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('schools', 'name')}>School name<HeaderArrow table='schools' orderBy='name' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('schools', 'address')}>Address<HeaderArrow table='schools' orderBy='address' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('schools', 'city')}>City<HeaderArrow table='schools' orderBy='city' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('schools', 'state')}>State<HeaderArrow table='schools' orderBy='state' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('schools', 'staff')}>Requested by<HeaderArrow table='schools' orderBy='staff' /></Table.HeaderCell>
                                   </Table.Row>}
                                  </Table.Header>
                                  <Table.Body>
                                    {this.props.schools.loading ? <Table.Row>
                                        <Table.Cell style={{height:100}}><Segment style={{height:100}}><Dimmer active inverted><Loader content="Loading schools..." /></Dimmer></Segment></Table.Cell>
                                      </Table.Row> : this.props.schools.collapse ? null : this.props.schools.data.map((request:any, i:number) => (
                                      <Table.Row key={request.recordID}>
                                        <Table.Cell>{i+1}</Table.Cell>
                                        <Table.Cell onClick={() => { this.props.setTab('admin'); this.props.switchTab(7); this.props.fetchAndSelectRequest(request.recordID) }}><div className='link'>{request.name}</div></Table.Cell>
                                        <Table.Cell>{request.address}</Table.Cell>
                                        <Table.Cell>{request.city}</Table.Cell>
                                        <Table.Cell>{request.state}</Table.Cell>
                                        <Table.Cell>{request.staffName}</Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    case 'instructors':
                      return (
                        <Draggable draggableId='instructors' index={o.order} key={o.order}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div style={{marginBottom:'1em'}}>
                                <Table id='instructors' compact celled striped {...provided.dragHandleProps}>
                                <Table.Header>
                                  <Table.Row warning>
                                    <Table.HeaderCell colSpan={3} style={{ background: "none" }}>
                                      <div className='pointer' style={{float:'right'}} onClick={() => this.props.updateStaffCollapse('instructors')}><Label><Icon name={`caret ${this.props.instructors.collapse ? 'left' : 'down'}`} />{this.props.instructors.data.length}</Label></div>
                                      <Checkbox checked={this.props.instructors.notify} style={{marginRight:10,verticalAlign:'middle'}} onChange={(e:any, data:any) => this.props.updateStaffNotification('instructors', data.checked)} />Classes Without Default Instructors
                                    </Table.HeaderCell>
                                  </Table.Row>
                                  {this.props.instructors.loading || this.props.instructors.collapse || this.props.instructors.data.length == 0 ? null : <Table.Row>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('instructors', 'facility')}>Facility<HeaderArrow table='instructors' orderBy='facility' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('instructors', 'id')}>Class ID<HeaderArrow table='instructors' orderBy='id' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('instructors', 'name')}>Class Name<HeaderArrow table='instructors' orderBy='name' /></Table.HeaderCell>
                                  </Table.Row>}
                                </Table.Header>
                                <Table.Body>
                                   {this.props.instructors.loading ? <Table.Row>
                                        <Table.Cell style={{height:100}}><Segment style={{height:100}}><Dimmer active inverted><Loader content="Loading classes..." /></Dimmer></Segment></Table.Cell>
                                      </Table.Row> : this.props.instructors.collapse ? null : this.props.instructors.data.map((klass:any) => (
                                      <Table.Row key={klass.recordID}>
                                        <Table.Cell>{klass.facilityAbbr}</Table.Cell>
                                        <Table.Cell>
                                          <Popup
                                            hideOnScroll
                                            on='click'
                                            trigger={<div className='link' style={{whiteSpace:'normal'}}>{klass.recordID}</div>}
                                            style={{overflowY:'auto',maxHeight:500}}
                                            basic
                                          >
                                            <Popup.Content>
                                              <Table compact celled striped definition>
                                                <Table.Body>
                                                  <Table.Row>
                                                    <Table.Cell>Class</Table.Cell>
                                                    <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.className} - {klass.recordID}</div></Table.Cell>
                                                  </Table.Row>
                                                  {klass.instructorName ? <Table.Row>
                                                    <Table.Cell>Instructor</Table.Cell>
                                                    <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.instructorName}</div></Table.Cell>
                                                  </Table.Row> : null}
                                                  {klass.fundingSource ? <Table.Row>
                                                    <Table.Cell>Funding</Table.Cell>
                                                    <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.fundingSource}</div></Table.Cell>
                                                  </Table.Row> : null}
                                                  {klass.schedules.length > 0 ? <Table.Row>
                                                    <Table.Cell>Schedule</Table.Cell>
                                                    <Table.Cell>{_.map(klass.schedules, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}><span style={{display:'inline-block',width:32}}>{this.dayOfWeek3(schedule.dayOfWeek)}.</span> {schedule.startTime} - {schedule.endTime} / {schedule.location}</div> })}</Table.Cell>
                                                  </Table.Row> : null}
                                                  {klass.rosters.length > 0 ? <Table.Row>
                                                    <Table.Cell>Roster</Table.Cell>
                                                    <Table.Cell>{_.map(klass.rosters, (roster:any, i:number) => { return <div key={i} style={{whiteSpace:'nowrap'}}>{roster}</div> })}</Table.Cell>
                                                  </Table.Row> : null}
                                                </Table.Body>
                                              </Table>
                                            </Popup.Content>
                                          </Popup>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {this.props.mode == 'dual' ? <Popup
                                            hideOnScroll
                                            on='click'
                                            trigger={<div className='link' style={{whiteSpace:'normal'}}>{klass.className}</div>}
                                          >
                                            <Popup.Header>Open in pane</Popup.Header>
                                            <Popup.Content>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, klass.recordID) }}>1</Label>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, klass.recordID) }}>2</Label>
                                            </Popup.Content>
                                          </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, klass.recordID) }}>{klass.className}</div>}
                                        </Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    case 'funding':
                      return (
                        <Draggable draggableId='funding' index={o.order} key={o.order}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div style={{marginBottom:'1em'}}>
                                <Table id='funding' compact celled striped {...provided.dragHandleProps}>
                                <Table.Header>
                                  <Table.Row warning>
                                    <Table.HeaderCell colSpan={3} style={{ background: "none" }}>
                                      <div className='pointer' style={{float:'right'}} onClick={() => this.props.updateStaffCollapse('funding')}><Label><Icon name={`caret ${this.props.funding.collapse ? 'left' : 'down'}`} />{this.props.funding.data.length}</Label></div>
                                      <Checkbox checked={this.props.funding.notify} style={{marginRight:10,verticalAlign:'middle'}} onChange={(e:any, data:any) => this.props.updateStaffNotification('funding', data.checked)} />Classes Without Funding
                                    </Table.HeaderCell>
                                  </Table.Row>
                                  {this.props.funding.loading || this.props.funding.collapse || this.props.funding.data.length == 0 ? null : <Table.Row>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('funding', 'facility')}>Facility<HeaderArrow table='funding' orderBy='facility' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('funding', 'id')}>Class ID<HeaderArrow table='funding' orderBy='id' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('funding', 'name')}>Class Name<HeaderArrow table='funding' orderBy='name' /></Table.HeaderCell>
                                  </Table.Row>}
                                </Table.Header>
                                <Table.Body>
                                   {this.props.funding.loading ? <Table.Row>
                                        <Table.Cell style={{height:100}}><Segment style={{height:100}}><Dimmer active inverted><Loader content="Loading classes..." /></Dimmer></Segment></Table.Cell>
                                      </Table.Row> : this.props.funding.collapse ? null : this.props.funding.data.map((klass:any) => (
                                      <Table.Row key={klass.recordID}>
                                        <Table.Cell>{klass.facilityAbbr}</Table.Cell>
                                        <Table.Cell>
                                          <Popup
                                            hideOnScroll
                                            on='click'
                                            trigger={<div className='link' style={{whiteSpace:'normal'}}>{klass.recordID}</div>}
                                            style={{overflowY:'auto',maxHeight:500}}
                                            basic
                                          >
                                            <Popup.Content>
                                              <Table compact celled striped definition>
                                                <Table.Body>
                                                  <Table.Row>
                                                    <Table.Cell>Class</Table.Cell>
                                                    <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.className} - {klass.recordID}</div></Table.Cell>
                                                  </Table.Row>
                                                  {klass.instructorName ? <Table.Row>
                                                    <Table.Cell>Instructor</Table.Cell>
                                                    <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.instructorName}</div></Table.Cell>
                                                  </Table.Row> : null}
                                                  {klass.fundingSource ? <Table.Row>
                                                    <Table.Cell>Funding</Table.Cell>
                                                    <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.fundingSource}</div></Table.Cell>
                                                  </Table.Row> : null}
                                                  {klass.schedules.length > 0 ? <Table.Row>
                                                    <Table.Cell>Schedule</Table.Cell>
                                                    <Table.Cell>{_.map(klass.schedules, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}><span style={{display:'inline-block',width:32}}>{this.dayOfWeek3(schedule.dayOfWeek)}.</span> {schedule.startTime} - {schedule.endTime} / {schedule.location}</div> })}</Table.Cell>
                                                  </Table.Row> : null}
                                                  {klass.rosters.length > 0 ? <Table.Row>
                                                    <Table.Cell>Roster</Table.Cell>
                                                    <Table.Cell>{_.map(klass.rosters, (roster:any, i:number) => { return <div key={i} style={{whiteSpace:'nowrap'}}>{roster}</div> })}</Table.Cell>
                                                  </Table.Row> : null}
                                                </Table.Body>
                                              </Table>
                                            </Popup.Content>
                                          </Popup>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {this.props.mode == 'dual' ? <Popup
                                            hideOnScroll
                                            on='click'
                                            trigger={<div className='link' style={{whiteSpace:'normal'}}>{klass.className}</div>}
                                          >
                                            <Popup.Header>Open in pane</Popup.Header>
                                            <Popup.Content>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, klass.recordID) }}>1</Label>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, klass.recordID) }}>2</Label>
                                            </Popup.Content>
                                          </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, klass.recordID) }}>{klass.className}</div>}
                                        </Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    case 'classes':
                      return (
                        <Draggable draggableId='classes' index={o.order} key={o.order}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div style={{marginBottom:'1em'}}>
                                <Table id='classes' compact celled striped {...provided.dragHandleProps}>
                                <Table.Header>
                                  <Table.Row warning>
                                    <Table.HeaderCell colSpan={7} style={{ background: "none" }}>
                                      <div className='pointer' style={{float:'right'}} onClick={() => this.props.updateStaffCollapse('classes')}><Label><Icon name={`caret ${this.props.classes.collapse ? 'left' : 'down'}`} />{this.props.classes.data.length}</Label></div>
                                      <Checkbox checked={this.props.classes.notify} style={{marginRight:10,verticalAlign:'middle'}} onChange={(e:any, data:any) => this.props.updateStaffNotification('classes', data.checked)} />Classes Ending Soon
                                    </Table.HeaderCell>
                                  </Table.Row>
                                  {this.props.classes.loading || this.props.classes.collapse || this.props.classes.data.length == 0 ? null : <Table.Row>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('classes', 'facility')}>Facility<HeaderArrow table='classes' orderBy='facility' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('classes', 'id')}>Class ID<HeaderArrow table='classes' orderBy='id' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('classes', 'class')}>Class Name<HeaderArrow table='classes' orderBy='class' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('classes', 'staff')}>Instructor<HeaderArrow table='classes' orderBy='staff' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('classes', 'startDate')}>Start Date<HeaderArrow table='classes' orderBy='startDate' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('classes', 'endDate')}>End Date<HeaderArrow table='classes' orderBy='endDate' /></Table.HeaderCell>
                                    <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
                                  </Table.Row>}
                                  </Table.Header>
                                  <Table.Body>
                                    {this.props.classes.loading ? <Table.Row>
                                        <Table.Cell style={{height:100}}><Segment style={{height:100}}><Dimmer active inverted><Loader content="Loading classes..." /></Dimmer></Segment></Table.Cell>
                                      </Table.Row> : this.props.classes.collapse ? null : this.props.classes.data.map((klass:any) => (
                                      <Table.Row key={klass.recordID}>
                                        <Table.Cell>{klass.facilityAbbr}</Table.Cell>
                                        <Table.Cell>
                                          <Popup
                                            hideOnScroll
                                            on='click'
                                            trigger={<div className='link' style={{whiteSpace:'normal'}}>{klass.recordID}</div>}
                                            style={{overflowY:'auto',maxHeight:500}}
                                            basic
                                          >
                                            <Popup.Content>
                                              <Table compact celled striped definition>
                                                <Table.Body>
                                                  <Table.Row>
                                                    <Table.Cell>Class</Table.Cell>
                                                    <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.className} - {klass.recordID}</div></Table.Cell>
                                                  </Table.Row>
                                                  {klass.instructorName ? <Table.Row>
                                                    <Table.Cell>Instructor</Table.Cell>
                                                    <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.instructorName}</div></Table.Cell>
                                                  </Table.Row> : null}
                                                  {klass.fundingSource ? <Table.Row>
                                                    <Table.Cell>Funding</Table.Cell>
                                                    <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.fundingSource}</div></Table.Cell>
                                                  </Table.Row> : null}
                                                  {klass.schedules.length > 0 ? <Table.Row>
                                                    <Table.Cell>Schedule</Table.Cell>
                                                    <Table.Cell>{_.map(klass.schedules, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}><span style={{display:'inline-block',width:32}}>{this.dayOfWeek3(schedule.dayOfWeek)}.</span> {schedule.startTime} - {schedule.endTime} / {schedule.location}</div> })}</Table.Cell>
                                                  </Table.Row> : null}
                                                  {klass.rosters.length > 0 ? <Table.Row>
                                                    <Table.Cell>Roster</Table.Cell>
                                                    <Table.Cell>{_.map(klass.rosters, (roster:any, i:number) => { return <div key={i} style={{whiteSpace:'nowrap'}}>{roster}</div> })}</Table.Cell>
                                                  </Table.Row> : null}
                                                </Table.Body>
                                              </Table>
                                            </Popup.Content>
                                          </Popup>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {this.props.mode == 'dual' ? <Popup
                                            hideOnScroll
                                            on='click'
                                            trigger={<div className='link' style={{whiteSpace:'normal'}}>{klass.className}</div>}
                                          >
                                            <Popup.Header>Open in pane</Popup.Header>
                                            <Popup.Content>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, klass.recordID) }}>1</Label>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, klass.recordID) }}>2</Label>
                                            </Popup.Content>
                                          </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, klass.recordID) }}>{klass.className}</div>}
                                        </Table.Cell>
                                        <Table.Cell>{klass.instructorName}</Table.Cell>
                                        <Table.Cell>{klass.dateBegins}</Table.Cell>
                                        <Table.Cell>{klass.dateEnds}</Table.Cell>
                                        <Table.Cell style={{textAlign:'center'}}><Button positive content="Extend" size="mini" onClick={_.debounce(() => this.props.selectExtendClass(klass), 10000, true)} /></Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    case 'arrivals':
                      return (
                        <Draggable draggableId='arrivals' index={o.order} key={o.order}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div style={{marginBottom:'1em'}}>
                                <Table id='arrivals' compact celled striped {...provided.dragHandleProps}>
                                <Table.Header>
                                  <Table.Row warning>
                                    <Table.HeaderCell colSpan={6} style={{ background: "none" }}>
                                      <div className='pointer' style={{float:'right'}} onClick={() => this.props.updateStaffCollapse('arrivals')}><Label><Icon name={`caret ${this.props.arrivals.collapse ? 'left' : 'down'}`} />{this.props.arrivals.data.length}</Label></div>
                                      <Checkbox checked={this.props.arrivals.notify} style={{marginRight:10,verticalAlign:'middle'}} onChange={(e:any, data:any) => this.props.updateStaffNotification('arrivals', data.checked)} />Recently transferred inmates
                                    </Table.HeaderCell>
                                  </Table.Row>
                                  {this.props.arrivals.loading || this.props.arrivals.collapse || this.props.arrivals.data.length == 0 ? null : <Table.Row>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('arrivals', 'name')}>Inmate Name<HeaderArrow table='arrivals' orderBy='name' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('arrivals', 'sid')}>SID<HeaderArrow table='arrivals' orderBy='sid' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('arrivals', 'type')}>Transfer Type<HeaderArrow table='arrivals' orderBy='type' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('arrivals', 'date')}>Transfer Date<HeaderArrow table='arrivals' orderBy='date' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('arrivals', 'to')}>Transferred To<HeaderArrow table='arrivals' orderBy='to' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('arrivals', 'from')}>Transferred From<HeaderArrow table='arrivals' orderBy='from' /></Table.HeaderCell>
                                   </Table.Row>}
                                  </Table.Header>
                                  <Table.Body>
                                    {this.props.arrivals.loading ? <Table.Row>
                                        <Table.Cell style={{height:100}}><Segment style={{height:100}}><Dimmer active inverted><Loader content="Loading arrivals..." /></Dimmer></Segment></Table.Cell>
                                      </Table.Row> : this.props.arrivals.collapse ? null : this.props.arrivals.data.map((inmate:any, i:number) => (
                                      <Table.Row key={i}>
                                        <Table.Cell>
                                          {this.props.mode == 'dual' ? <Popup
                                            hideOnScroll
                                            on='click'
                                            trigger={<div className='link' style={{whiteSpace:'normal'}}>{inmate.hasTransferredClasses ? <Popup content="Has active classes in previous facility" position="right center" trigger={<Icon name="exclamation" color="red" />} /> : null} {inmate.name}</div>}
                                          >
                                            <Popup.Header>Open in pane</Popup.Header>
                                            <Popup.Content>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(1, inmate.recordID) }}>1</Label>
                                              <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(2, inmate.recordID) }}>2</Label>
                                            </Popup.Content>
                                          </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, inmate.recordID) }}>{inmate.hasTransferredClasses ? <Popup content="Has active classes in previous facility" position="right center" trigger={<Icon name="exclamation" color="red" />} /> : null} {inmate.name}</div>}
                                        </Table.Cell>
                                        <Table.Cell>{inmate.sid}</Table.Cell>
                                        <Table.Cell>{inmate.transferType}</Table.Cell>
                                        <Table.Cell>{inmate.transferDate}</Table.Cell>
                                        <Table.Cell>{inmate.transferredTo}</Table.Cell>
                                        <Table.Cell>{inmate.transferredFrom}</Table.Cell>
                                      </Table.Row>
                                    ))}
                                  </Table.Body>
                                </Table>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    case 'attendance':
                      return (
                        <Draggable draggableId='attendance' index={o.order} key={o.order}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <div style={{marginBottom:'1em'}}>
                                <Table id='attendance' compact celled striped {...provided.dragHandleProps}>
                                <Table.Header>
                                  <Table.Row warning>
                                    <Table.HeaderCell colSpan={5} style={{ background: "none" }}>
                                      <div className='pointer' style={{float:'right'}} onClick={() => this.props.updateStaffCollapse('attendance')}><Label><Icon name={`caret ${this.props.attendance.collapse ? 'left' : 'down'}`} />{this.props.attendance.data.length}</Label></div>
                                      <Checkbox checked={this.props.attendance.notify} style={{marginRight:10,verticalAlign:'middle'}} onChange={(e:any, data:any) => this.props.updateStaffNotification('attendance', data.checked)} />Inmates with low attendance
                                    </Table.HeaderCell>
                                  </Table.Row>
                                  {this.props.attendance.loading || this.props.attendance.collapse || this.props.attendance.data.length == 0 ? null : <Table.Row>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('attendance', 'inmate')}>Inmate Name<HeaderArrow table='attendance' orderBy='inmate' /></Table.HeaderCell>
                                    <Table.HeaderCell onClick={() => this.props.toggleOrder('attendance', 'facility')}>Facility<HeaderArrow table='attendance' orderBy='facility' /></Table.HeaderCell>
                                    <Table.HeaderCell>Class ID</Table.HeaderCell>
                                    <Table.HeaderCell>Class Name</Table.HeaderCell>
                                    <Table.HeaderCell>Attendance</Table.HeaderCell>
                                  </Table.Row>}
                                 </Table.Header>
                                  <Table.Body>
                                    {this.props.attendance.loading ? <Table.Row>
                                        <Table.Cell style={{height:100}}><Segment style={{height:100}}><Dimmer active inverted><Loader content="Loading attendance..." /></Dimmer></Segment></Table.Cell>
                                      </Table.Row> : this.props.attendance.collapse ? null : this.props.attendance.data.map((inmate:any) =>
                                      inmate.classes.map((klass:any, i:number) => (
                                        <Table.Row key={klass.recordID}>
                                          <Table.Cell>
                                            {i > 0 ? null : this.props.mode == 'dual' ? <Popup
                                              hideOnScroll
                                              on='click'
                                              trigger={<div className='link' style={{whiteSpace:'normal'}}>{inmate.name}</div>}
                                              style={{overflowY:'auto',maxHeight:500}}
                                              basic
                                            >
                                              <Popup.Header>Open in pane</Popup.Header>
                                              <Popup.Content>
                                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(1, inmate.recordID) }}>1</Label>
                                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(2, inmate.recordID) }}>2</Label>
                                              </Popup.Content>
                                            </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, inmate.recordID) }}>{inmate.name}</div>}
                                          </Table.Cell>
                                          <Table.Cell>{klass.facilityAbbr}</Table.Cell>
                                          <Table.Cell>
                                            <Popup
                                              hideOnScroll
                                              on='click'
                                              trigger={<div className='link' style={{whiteSpace:'normal'}}>{klass.recordID}</div>}
                                              style={{overflowY:'auto',maxHeight:500}}
                                              basic
                                            >
                                              <Popup.Content>
                                                <Table compact celled striped definition>
                                                  <Table.Body>
                                                    <Table.Row>
                                                      <Table.Cell>Class</Table.Cell>
                                                      <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.name} - {klass.recordID}</div></Table.Cell>
                                                    </Table.Row>
                                                    {klass.instructorName ? <Table.Row>
                                                      <Table.Cell>Instructor</Table.Cell>
                                                      <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.instructorName}</div></Table.Cell>
                                                    </Table.Row> : null}
                                                    {klass.fundingSource ? <Table.Row>
                                                      <Table.Cell>Funding</Table.Cell>
                                                      <Table.Cell><div style={{whiteSpace: 'nowrap'}}>{klass.fundingSource}</div></Table.Cell>
                                                    </Table.Row> : null}
                                                    {klass.schedules.length > 0 ? <Table.Row>
                                                      <Table.Cell>Schedule</Table.Cell>
                                                      <Table.Cell>{_.map(klass.schedules, (schedule:any) => { return <div key={schedule.recordID} style={{whiteSpace:'nowrap'}}><span style={{display:'inline-block',width:32}}>{this.dayOfWeek3(schedule.dayOfWeek)}.</span> {schedule.startTime} - {schedule.endTime} / {schedule.location}</div> })}</Table.Cell>
                                                    </Table.Row> : null}
                                                    {klass.rosters.length > 0 ? <Table.Row>
                                                      <Table.Cell>Roster</Table.Cell>
                                                      <Table.Cell>{_.map(klass.rosters, (roster:any, i:number) => { return <div key={i} style={{whiteSpace:'nowrap'}}>{roster}</div> })}</Table.Cell>
                                                    </Table.Row> : null}
                                                  </Table.Body>
                                                </Table>
                                              </Popup.Content>
                                            </Popup>
                                          </Table.Cell>
                                          <Table.Cell>
                                            {this.props.mode == 'dual' ? <Popup
                                              hideOnScroll
                                              on='click'
                                              trigger={<div className='link' style={{whiteSpace:'normal'}}>{klass.name}</div>}
                                            >
                                              <Popup.Header>Open in pane</Popup.Header>
                                              <Popup.Content>
                                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(1, klass.recordID) }}>1</Label>
                                                <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.toggleDrawer(this.props.tab); this.props.fetchClass(2, klass.recordID) }}>2</Label>
                                              </Popup.Content>
                                            </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.toggleDrawer(this.props.tab); this.props.fetchClass(this.props.mode == 'left' ? 1 : 2, klass.recordID) }}>{klass.name}</div>}
                                          </Table.Cell>
                                          <Table.Cell>{klass.aCount + klass.xCount + klass.zCount > 0 ? Math.floor(((klass.aCount + klass.xCount) / (klass.aCount + klass.xCount + klass.zCount))*100) : 0}%</Table.Cell>
                                        </Table.Row>
                                      ))
                                    )}
                                  </Table.Body>
                                </Table>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    }
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Modal
            open={this.props.modals.extend.open}
            onClose={this.props.toggleExtendModal}
            size="mini"
          >
            <Modal.Header content={`Extend ${this.props.modals.extend.name}`} />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    label="Exp Date"
                    control={DatePicker}
                    selected={this.props.modals.extend.date ? new Date(this.props.modals.extend.date) : null}
                    onChange={(date:any) => this.props.updateExtend('date', date ? format(date, 'MM/dd/yyyy') : null)}
                    filterDate={(date:any) => date >= new Date(this.props.modals.extend.expDate) && date <= addYears(new Date(),1)}
                  />
                </Form.Group>
                <Form.Group>
                  <Checkbox
                    name="all"
                    label="Apply to all staff"
                    checked={this.props.modals.extend.all}
                    onChange={(e:any, data:any) => this.props.updateExtend('all', data.checked)}
                    style={{marginLeft:5}}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleExtendModal}>Close</Button>
              <Button
                positive
                content="Extend"
                onClick={_.debounce(() => this.props.extendStaff(this.props.facility), 10000, true)}
              />
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.modals.archive.open}
            onClose={this.props.toggleDashArchiveModal}
            size="mini"
          >
            <Modal.Header content={`Archive ${this.props.modals.archive.name}`} />
            <Modal.Content>
              <div>Are you sure you wish to <b>archive</b> this staff member?</div>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleDashArchiveModal}>Close</Button>
              <Button
                positive
                content="Archive"
                onClick={_.debounce(() => this.props.archiveStaff(this.props.facility), 10000, true)}
              />
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.modals.expire.open}
            onClose={this.props.toggleDashExpireModal}
            size="mini"
          >
            <Modal.Header content={`Expire ${this.props.modals.expire.name}`} />
            <Modal.Content>
              <div>Are you sure you wish to <b>expire</b> this staff member?</div>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleDashExpireModal}>Close</Button>
              <Button
                negative
                content="Expire"
                onClick={_.debounce(() => this.props.expireStaff(this.props.facility), 10000, true)}
              />
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.modals.class.open}
            onClose={this.props.toggleClassModal}
            size="tiny"
          >
            <Modal.Header content={`Extend ${this.props.modals.class.name}`} />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    label="Exp Date"
                    control={DatePicker}
                    selected={this.props.modals.class.date ? new Date(this.props.modals.class.date) : null}
                    onChange={(date:any) => this.props.updateClass('date', date ? format(date, 'MM/dd/yyyy') : null)}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleClassModal}>Close</Button>
              <Button
                positive
                content="Extend"
                onClick={_.debounce(() => this.props.extendClass(this.props.facility), 10000, true)}
              />
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { ...state.dashboard, panes: state.panes.panes, mode: state.panes.mode, tab: state.drawer.tab, admin: state.staff.staff!.admin } },
  { ...DrawerStore.actionCreators, ...DashboardStore.actionCreators, ...PanesStore.actionCreators, ...AdminStore.actionCreators }
)(Dashboard as any)
