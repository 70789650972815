import React, { Fragment } from 'react'
import {
  Table,
  Container
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class Recidivism extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <Fragment>
            <html lang="en">
              <head>
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
              </head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Inconsolata') || document.fonts.check('bold 1em Inconsolata'))
              }`}} />
              <body style={{fontSize:'0.8em',fontFamily:'Inconsolata',backgroundColor:'white !important'}}>
                {this.report()}
              </body>
            </html>
          </Fragment>
        )
    }

    public report() {
        let params = this.props.report.data
        let data = this.props.report.generated

        return (
          <Container fluid>
            <div style={{textAlign:'center',marginBottom:15}}>
              <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Recidivism Report</div><div style={{marginTop:4}}>{`${data.firstTransfer} through ${data.lastTransfer}`}</div></div>
            </div>
            <div>
              <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
                <div style={{fontWeight:700,fontSize:16}}>Inmate Counts</div>
              </div>
              <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
                <div style={{flexBasis:`90%`}}>
                  <Table compact celled striped unstackable definition style={{margin:'0 0 2em 0',height:'0%',border:0}}>
                    <Table.Header>
                      <Table.Row style={{textAlign:'center'}}>
                        <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Total</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Released</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Active</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male Total</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male Released</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male Active</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female Total</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Female Released</Table.HeaderCell>
                        <Table.HeaderCell style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female Active</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {_.map(data.facilities, (facility:any, i:number) => <Table.Row key={facility.abbreviation} style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>{facility.abbreviation}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.counts.total}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.counts.released}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.counts.active}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genderCounts.male.total}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genderCounts.male.released}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genderCounts.male.active}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genderCounts.female.total}</div></Table.Cell>
                        <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genderCounts.female.released}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.genderCounts.female.active}</div></Table.Cell>
                      </Table.Row>)}
                      <Table.Row style={{backgroundColor: 'white'}}>
                        <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Totals</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.counts.total}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.counts.released}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.counts.active}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genderCounts.male.total}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genderCounts.male.released}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genderCounts.male.active}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genderCounts.female.total}</div></Table.Cell>
                        <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genderCounts.female.total}</div></Table.Cell>
                        <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{data.globals.genderCounts.female.total}</div></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </div>
              </div>
            </div>
          </Container>
        )
      }
}

export default Recidivism