import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Table,
  Select,
  Button,
  Modal,
  Popup,
  Icon,
  Header,
  Message,
  Divider
} from 'semantic-ui-react'
const _ = require('underscore')

type CompletionProps =
    { class: PanesStore.Class, num: 1 | 2, print: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassCompletionsSubmit extends React.PureComponent<CompletionProps> {
    public render() {
        const rosters = this.props.class.rosters == undefined ? [] : _.filter(this.props.class.rosters, (roster:any) => !roster.waitlist)
        const transcripts = this.props.class.transcripts == undefined ? [] : this.props.class.transcripts
        const waitlist = this.props.class.waitlist.inmates == undefined ? [] : this.props.class.waitlist.inmates
        return (
          <Container fluid>
            <Header as='h3'>Grades</Header>
            {_.any(rosters, (roster:any) => roster.pending) ? <Message color='yellow'>
              <Icon name='warning' />
              Inmates with pending attendance cannot be completed.
            </Message> : null}
            {rosters.length > 0 ? <Table compact celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell width={6}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={1}>SID</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Hours</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Grade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rosters.map((roster:any, i:number) => (
                  <Table.Row key={roster.recordID} className={`${roster.pending ? 'warning' : ''}`}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>{`${roster.lastName}, ${roster.firstName}`}{_.find(this.props.class.options.certificates, (o:any) => o.key == roster.certID) ? <Popup on='hover' position='right center' content={_.find(this.props.class.options.certificates, (o:any) => o.key == roster.certID).text} trigger={<Icon color='blue' name='certificate' style={{marginLeft:5}} />} /> : null}</Table.Cell>
                    <Table.Cell>{roster.sid}</Table.Cell>
                    <Table.Cell>{roster.aTime}</Table.Cell>
                    <Table.Cell>{roster.grade}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table> : <Message color='yellow'>
              <Icon name='warning' />
              No inmates to complete.
            </Message>}
            <Header as='h3'>Waitlist</Header>
            <Table compact celled striped style={{marginBottom:20}}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>SID</Table.HeaderCell>
                  <Table.HeaderCell>Housing</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.class.waitlist.inmates.map((waitlist:any, i:number) => (
                  <Table.Row key={waitlist.recordID}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell><div className='link'>{`${waitlist.lastName}, ${waitlist.firstName}`}</div></Table.Cell>
                    <Table.Cell>{waitlist.sid}</Table.Cell>
                    <Table.Cell>{waitlist.housing}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {this.props.print ? null : <Fragment>
              <Button color='black' content='Back' onClick={() => this.props.completionsBack(this.props.num)} style={{marginBottom:20}} />
              {rosters.length > 0 && !_.any(rosters, (roster:any) => roster.pending) ? <Button positive content='Submit completions and close class' onClick={() => this.props.toggleCompletions(this.props.num)} /> : null}
            </Fragment>}
            <Header as='h3'>Transcripts</Header>
            <Table compact celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell width={6}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={1}>SID</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Hours</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Grade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {transcripts.map((transcript:any, i:number) => (
                  <Table.Row key={transcript.recordID}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>{`${transcript.lastName}, ${transcript.firstName}`}{_.find(this.props.class.options.certificates, (o:any) => o.key == transcript.certID) ? <Popup on='hover' position='right center' content={_.find(this.props.class.options.certificates, (o:any) => o.key == transcript.certID).text} trigger={<Icon color='blue' name='certificate' style={{marginLeft:5}} />} /> : null}</Table.Cell>
                    <Table.Cell>{transcript.sid}</Table.Cell>
                    <Table.Cell>{transcript.aTime}</Table.Cell>
                    <Table.Cell>{transcript.grade}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <Modal
              open={this.props.class.modals.completions.open}
              onClose={() => this.props.toggleCompletions(this.props.num)}
              size="small"
            >
              <Modal.Header content='Submit Completions' />
              <Modal.Content>
                <div>Are you sure you want to <b>submit these completions</b> and <b>close this class?</b></div>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleCompletions(this.props.num)}>Close</Button>
                <Button
                  positive
                  content="Submit"
                  onClick={_.debounce(() => this.props.submitCompletions(this.props.num), 10000, true)}
                />
              </Modal.Actions>
            </Modal>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, print: ownProps.boolean } },
  PanesStore.actionCreators
)(ClassCompletionsSubmit as any)