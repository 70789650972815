import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import {
  Container,
  Checkbox,
  Button,
  Input,
  Select,
  Grid,
  Table,
  Header,
  Form
} from 'semantic-ui-react'
const _ = require('underscore')

type SurveyProps =
    { surveys: any } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class SurveyOptionGroups extends React.PureComponent<SurveyProps> {
    public render() {
      return (
        <Container fluid style={{overflowY:'auto',height:740,paddingRight:5}}>
          <Grid>
            <Grid.Column width={16}>
              <Header size='large'>Option Groups</Header>
              <Table compact celled striped style={{ marginTop: "1em" }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Group Type</Table.HeaderCell>
                    <Table.HeaderCell width={1} colSpan={2}>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                    {this.props.surveys.groups.data.map((data:any, i:number) => (
                      <Table.Row>
                        <Table.Cell><Input value={data.name} name="name" onChange={(e:any, d:any) => this.props.updateGroupField(data.recordID, 'name', d.value.validate())} style={{width:'100%'}} /></Table.Cell>
                        <Table.Cell><Select name="groupType" placeholder="Group Type" options={[{key:0,value:'dropdown',text:'Dropdown'},{key:1,value:'grid',text:'Grid'}]} value={data.groupType} onChange={(e:any, d:any) => this.props.updateGroupField(data.recordID, 'groupType', d.value as number)} /></Table.Cell>
                        <Table.Cell style={{textAlign:'center'}}>
                          <Button positive content="+" size="mini" onClick={_.debounce(() => this.props.saveSurveyOptionGroup(data.recordID), 10000, true)} />
                        </Table.Cell>
                        <Table.Cell style={{textAlign:'center'}}>
                          <Button negative content="-" size="mini" onClick={_.debounce(() => this.props.removeSurveyOptionGroup(data.recordID), 10000, true)} />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                    <Table.Row>
                      <Table.Cell><Input value={this.props.surveys.new.group.name} name="name" placeholder="New Option Group" onChange={(e:any, data:any) => this.props.changeNewSurveyOptionGroup('name', data.value.validate())} style={{width:'100%'}} /></Table.Cell>
                      <Table.Cell><Select name="groupType" placeholder="Group Type" options={[{key:0,value:'dropdown',text:'Dropdown'},{key:1,value:'grid',text:'Grid'}]} value={this.props.surveys.new.group.groupType} onChange={(e:any, d:any) => this.props.changeNewSurveyOptionGroup('groupType', d.value as number)} /></Table.Cell>
                      <Table.Cell style={{textAlign:'center'}}><Button positive content="+" size="mini" onClick={_.debounce(this.props.addSurveyOptionGroup, 10000, true)} style={{width:'100%'}} /></Table.Cell>
                      <Table.Cell />
                    </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { surveys: state.admin.surveys } },
  AdminStore.actionCreators
)(SurveyOptionGroups as any)