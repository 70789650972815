import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as ClassesStore from '../../store/reducers/classes'
import ClassesTable from '../classes/ClassesTable'
import ClassesPDF from '../reports/pdf/Classes'
import {
  Icon,
  Header,
  Button,
  Form,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type DrawerProps =
    { staff: any } &
    DrawerStore.DrawerState &
    ClassesStore.ClassesState &
    typeof DrawerStore.actionCreators &
    typeof ClassesStore.actionCreators &
    WithRouterProps<{}>

class Classes extends React.PureComponent<DrawerProps> {
    public componentDidMount() {
        this.props.fetchClasses()
    }

    public render() {
      const activeFilters = () => {
          return !_.isEqual(_.pick(this.props.filters, (v:any,k:string) => k != 'showFilters'), { classStatus: 'active', gender: 'B', facility: (this.props.staff.facilities.includes('PSD') || this.props.staff.facilities.includes('HPA')) ? 'all' : 'my', instructor: null, program: null, funding: null, activeOn: null, className: '', classID: '', days: [] })
      }

      return (
        <Fragment>
          <Form style={{position:'absolute',top:10,right:80}}>
            <Form.Group>
              <ClassesPDF />
              <Button size='small' color='blue' circular icon='print' onClick={() => this.props.generateClassesPDF()} style={{height:30,marginRight:10,marginTop:10}} />
              <Button size='small' color={this.props.tab == 'classes' ? 'blue' : undefined} content='Classes' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('classes')} />
              <Button size='small' color={this.props.tab == 'templates' ? 'blue' : undefined} content='Templates' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('templates')} />
              <Button size='small' color={this.props.tab == 'certificates' ? 'blue' : undefined} content='Certificates' style={{borderRadius:20,marginRight:10,marginTop:10,height:30}} onClick={() => this.props.setTab('certificates')} />
              <Popup content={`Filter toggle. If the filter icon is blue then the filter is in its default state. If the filter icon is red then there is an entry in one or more fields or one or more selections have changed from the default.`} position="bottom center" trigger={<Button circular icon='filter' color={activeFilters() ? 'red' : 'blue'} onClick={this.props.toggleFilters} style={{fontSize:'1.5em',marginLeft:30}} />} />
            </Form.Group>
          </Form>
          <Button circular icon='arrow left' onClick={() => this.props.toggleDrawer(this.props.tab)} style={{position:'absolute',top:20,right:0}} />
          <Header as='h2' style={{display:'inline-block'}}>
            <Icon name='book' /> Classes
          </Header>
          <div style={{display:'inline-block',marginLeft:10,verticalAlign:'top',marginTop:32}}>[{this.props.tabs.classes.count} records]</div>
          <Popup content={`Create a new class from the available templates.`} position="top center" trigger={<Button circular positive icon='plus' onClick={this.props.toggleCreateClass} style={{marginLeft:40,verticalAlign:'super'}} />} />
          <ClassesTable />
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { ...state.drawer, ...state.classes, staff: state.staff.staff } },
  { ...DrawerStore.actionCreators, ...ClassesStore.actionCreators }
)(Classes as any)