import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Table,
  Select,
  Button,
  Modal,
  Form,
  Input,
  Tab,
  Icon,
  Message,
  Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type CompletionProps =
    { class: PanesStore.Class, num: 1 | 2, print: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class ClassCompletionsGrades extends React.PureComponent<CompletionProps> {
    public render() {
        const rosters = this.props.class.rosters == undefined ? [] : _.filter(this.props.class.rosters, (roster:any) => !roster.waitlist)
        return (
          rosters.length > 0 ? <Container fluid>
            {_.any(rosters, (roster:any) => roster.pending) ? <Message color='yellow'>
              <Icon name='warning' />
              Inmates with pending attendance cannot be completed.
            </Message> : null}
            <Table compact celled striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell width={6}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={1}>SID</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Hours</Table.HeaderCell>
                  <Table.HeaderCell>Grade</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rosters.map((roster:any, i:number) => (
                  <Table.Row key={roster.recordID}>
                    <Table.Cell>{i+1}</Table.Cell>
                    <Table.Cell>
                      {this.props.class.info.requiredHours && roster.aTime >= this.props.class.info.requiredHours ? <Popup content="Inmate has met required hours" position="top center" trigger={<Icon name="calendar check" color="green" />} /> : null}
                      <div style={{display:'inline-block'}}>{`${roster.lastName}, ${roster.firstName}`}</div>
                    </Table.Cell>
                    <Table.Cell>{roster.sid}</Table.Cell>
                    <Table.Cell>{roster.aTime}</Table.Cell>
                    <Table.Cell><Select placeholder='Grade' options={this.props.class.options.grades} value={roster.grade} onChange={(e:any, data:any) => { this.props.selectGrade(this.props.num, roster.recordID, data.value as string) }} style={{width:'100%'}} /></Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {this.props.print ? null : <Button color='blue' content='Next' onClick={() => this.props.completionsNext(this.props.num)} style={{marginBottom:20}} />}
          </Container> : <Message color='yellow'>
            <Icon name='warning' />
            No inmates to complete.
          </Message>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { class: ownProps.class, num: ownProps.num, print: ownProps.print } },
  PanesStore.actionCreators
)(ClassCompletionsGrades as any)