import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container,
  Icon
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps =
    { report: any, connected: boolean, options: any, settings: any } &
    typeof ReportsStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class KamakaniHTML extends React.PureComponent<ReportProps> {
    public componentDidMount() {
        this.props.socketConnect()
        this.props.fetchSettings()
    }

    public componentDidUpdate(prevProps:any) {
      if (!prevProps.connected && this.props.connected && !this.props.report) {
          this.props.generateKamakani()
      }
    }

    public render() {
      if (!this.props.report) {
        return (
          <Fragment>
            <Dimmer active inverted>
              <Loader content='Generating report...' />
            </Dimmer>
          </Fragment>
        )
      } else if (this.props.report.loading) {
        if (this.props.connected) {
          return (
            <Fragment>
              <Dimmer active inverted>
                <Loader content='Generating report...' />
              </Dimmer>
            </Fragment>
          )
        } else {
          return (
            <Fragment>
              <Dimmer active inverted>
                <Icon name='warning sign' color='red' size='huge' />
                <div className='name' style={{textAlign:'center',color:'black',marginTop:10}}><b>Error</b></div>
              </Dimmer>
            </Fragment>
          )
        }
      } else {
        let params = qs.parse(window.location.search.substr(1, window.location.search.length));
        let data = this.props.report.generated
        let num = 0

        switch (params.reportType) {
        case 'course':
          return (
            <Container fluid>
              <div style={{textAlign:'center',marginBottom:15}}>
                <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Kamakani Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
              </div>
              <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'course-facility' ? 'Course / Facility' : params.reportType == 'course' ? 'Course' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'funding' ? 'Funding' : params.reportType == 'funding-facility' ? 'Funding / Facility' : ''}</div></div>
              </div>
              <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{border:0}}><div><span>Program & Course</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Unique Inmates</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Actual Sessions</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Actual Hours</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Scheduled Sessions</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Scheduled Hours</span></div></Table.HeaderCell>
                    <Table.HeaderCell style={{border:0}}><div><span>Percent Scheduled</span></div></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.courses.map((course:any, c:number) => (
                    <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={c}>
                      <Table.Cell warning={course.highlight}>{`${course.programName} -- ${course.courseName}`}</Table.Cell>
                      <Table.Cell warning={course.scheduledHighlight}>{course.unique}</Table.Cell>
                      <Table.Cell warning={course.actualHighlight}>{course.actualSessions}</Table.Cell>
                      <Table.Cell warning={course.actualHighlight}>{course.actualHours}</Table.Cell>
                      <Table.Cell warning={course.scheduledHighlight}>{course.scheduledSessions}</Table.Cell>
                      <Table.Cell warning={course.scheduledHighlight}>{course.scheduledHours}</Table.Cell>
                      <Table.Cell>{`${course.percentScheduled}%`}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <div className='footer'>
                <div className='left'>
                    {new Date().today() + " @ " + new Date().timeNow()}
                </div>
                <div className='right'>
                    {data.staffID} / {data.printID}
                </div>
                <div className='center'>{this.props.report.copyright}</div>
              </div>
            </Container>
          )
        case 'course-facility':
            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Kamakani Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'course-facility' ? 'Course / Facility' : params.reportType == 'course' ? 'Course' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'funding' ? 'Funding' : params.reportType == 'funding-facility' ? 'Funding / Facility' : ''}</div></div>
                </div>
                <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{border:0}}><div><span>Program & Course</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Unique Inmates</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Actual Sessions</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Actual Hours</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Scheduled Sessions</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Scheduled Hours</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Percent Scheduled</span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.courses.map((course:any, c:number) =>
                      course.facilities.map((facility:any, f:number) => (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={`${c}:${f}`}>
                          <Table.Cell warning={course.highlight}>{f == 0 ? `${course.programName} -- ${course.courseName}` : ''}</Table.Cell>
                          <Table.Cell warning={facility.highlight}>{facility.abbreviation}</Table.Cell>
                          <Table.Cell warning={facility.scheduledHighlight}>{facility.unique}</Table.Cell>
                          <Table.Cell warning={facility.actualHighlight}>{facility.actualSessions}</Table.Cell>
                          <Table.Cell warning={facility.actualHighlight}>{facility.actualHours}</Table.Cell>
                          <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledSessions}</Table.Cell>
                          <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledHours}</Table.Cell>
                          <Table.Cell>{`${facility.percentScheduled}%`}</Table.Cell>
                        </Table.Row>
                      ))
                    )}
                  </Table.Body>
                </Table>
                <div className='footer'>
                  <div className='left'>
                      {new Date().today() + " @ " + new Date().timeNow()}
                  </div>
                  <div className='right'>
                      {data.staffID} / {data.printID}
                  </div>
                  <div className='center'>{this.props.report.copyright}</div>
                </div>
              </Container>
            )
        case 'funding-facility':
            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Kamakani Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'course-facility' ? 'Course / Facility' : params.reportType == 'course' ? 'Course' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'funding' ? 'Funding' : params.reportType == 'funding-facility' ? 'Funding / Facility' : ''}</div></div>
                </div>
                <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{border:0}}><div><span>Funding Source</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Description</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Unique Inmates</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Actual Sessions</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Actual Hours</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Scheduled Sessions</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Scheduled Hours</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Percent Scheduled</span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.funding.map((funding:any, u:number) =>
                      funding.facilities.map((facility:any, f:number) => (
                        <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={`${u}:${f}`}>
                          <Table.Cell>{f == 0 ? `${funding.fundingSource}` : ''}</Table.Cell>
                          <Table.Cell>{f == 0 ? `${funding.fundingDescription}` : ''}</Table.Cell>
                          <Table.Cell warning={facility.highlight}>{facility.abbreviation}</Table.Cell>
                          <Table.Cell warning={facility.scheduledHighlight}>{facility.unique}</Table.Cell>
                          <Table.Cell warning={facility.actualHighlight}>{facility.actualSessions}</Table.Cell>
                          <Table.Cell warning={facility.actualHighlight}>{facility.actualHours}</Table.Cell>
                          <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledSessions}</Table.Cell>
                          <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledHours}</Table.Cell>
                          <Table.Cell>{`${facility.percentScheduled}%`}</Table.Cell>
                        </Table.Row>
                      ))
                    )}
                  </Table.Body>
                </Table>
                <div className='footer'>
                  <div className='left'>
                      {new Date().today() + " @ " + new Date().timeNow()}
                  </div>
                  <div className='right'>
                      {data.staffID} / {data.printID}
                  </div>
                  <div className='center'>{this.props.report.copyright}</div>
                </div>
              </Container>
            )
        case 'funding':
            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Kamakani Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'course-facility' ? 'Course / Facility' : params.reportType == 'course' ? 'Course' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'funding' ? 'Funding' : params.reportType == 'funding-facility' ? 'Funding / Facility' : ''}</div></div>
                </div>
                <Table compact celled striped unstackable className='slanted' style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{border:0}}><div><span>Funding Source</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Description</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Unique Inmates</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Actual Sessions</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Actual Hours</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Scheduled Sessions</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Scheduled Hours</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Percent Scheduled</span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.funding.map((funding:any, f:number) => (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={f}>
                        <Table.Cell>{funding.fundingSource}</Table.Cell>
                        <Table.Cell>{funding.fundingDescription}</Table.Cell>
                        <Table.Cell warning={funding.scheduledHighlight}>{funding.unique}</Table.Cell>
                        <Table.Cell warning={funding.actualHighlight}>{funding.actualSessions}</Table.Cell>
                        <Table.Cell warning={funding.actualHighlight}>{funding.actualHours}</Table.Cell>
                        <Table.Cell warning={funding.scheduledHighlight}>{funding.scheduledSessions}</Table.Cell>
                        <Table.Cell warning={funding.scheduledHighlight}>{funding.scheduledHours}</Table.Cell>
                        <Table.Cell>{`${funding.percentScheduled}%`}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <div className='footer'>
                  <div className='left'>
                      {new Date().today() + " @ " + new Date().timeNow()}
                  </div>
                  <div className='right'>
                      {data.staffID} / {data.printID}
                  </div>
                  <div className='center'>{this.props.report.copyright}</div>
                </div>
              </Container>
            )
        case 'facility':
            return (
              <Container fluid>
                <div style={{textAlign:'center',marginBottom:15}}>
                  <div style={{fontWeight:700}}><div style={{fontSize:12,marginBottom:10}}>State of Hawaii - {this.props.settings.deptName} - CPS-E</div><div style={{fontSize:14}}>Kamakani Report</div><div style={{marginTop:4}}>{params.startDate != null && params.endDate != null ? `${new Date(params.startDate as string).getMonth()+1}/${new Date(params.startDate as string).getDate()}/${new Date(params.startDate as string).getFullYear()} through ${new Date(params.endDate as string).getMonth()+1}/${new Date(params.endDate as string).getDate()}/${new Date(params.endDate as string).getFullYear()}` : `${new Date().getMonth()+1}/${new Date().getDate()}/${new Date().getFullYear()}`}</div></div>
                </div>
                <div style={{display:'flex',flexWrap:'wrap',borderTop:'1px solid rgba(34, 36, 38, 0.15)',color:'#333'}}>
                  <div style={{borderTop:'1px solid rgba(34, 36, 38, 0.15)',borderBottom:'1px solid rgba(34, 36, 38, 0.15)',borderRight:'2px solid rgba(34, 36, 38, 0.15)',padding:6,whiteSpace:'nowrap'}}><div style={{marginRight:6,display:'inline-block',verticalAlign:'top',fontWeight:700}}>Report type:</div><div style={{display:'inline-block',fontWeight:400}}>{params.reportType == 'course-facility' ? 'Course / Facility' : params.reportType == 'course' ? 'Course' : params.reportType == 'facility' ? 'Facility' : params.reportType == 'funding' ? 'Funding' : params.reportType == 'funding-facility' ? 'Funding / Facility' : ''}</div></div>
                </div>
                <Table compact celled striped unstackable style={{marginTop:0}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{border:0}}><div><span>Facility</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Unique Inmates</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Actual Sessions</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Actual Hours</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Scheduled Sessions</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Scheduled Hours</span></div></Table.HeaderCell>
                      <Table.HeaderCell style={{border:0}}><div><span>Percent Scheduled</span></div></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data.facilityData.map((facility:any, f:number) => (
                      <Table.Row style={{backgroundColor: num % 2 === 0 ? 'white' : 'rgba(0,0,50,.05)'}} key={f}>
                        <Table.Cell warning={facility.highlight}>{facility.abbreviation}</Table.Cell>
                        <Table.Cell warning={facility.scheduledHighlight}>{facility.unique}</Table.Cell>
                        <Table.Cell warning={facility.actualHighlight}>{facility.actualSessions}</Table.Cell>
                        <Table.Cell warning={facility.actualHighlight}>{facility.actualHours}</Table.Cell>
                        <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledSessions}</Table.Cell>
                        <Table.Cell warning={facility.scheduledHighlight}>{facility.scheduledHours}</Table.Cell>
                        <Table.Cell>{`${facility.percentScheduled}%`}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <div className='footer'>
                  <div className='left'>
                      {new Date().today() + " @ " + new Date().timeNow()}
                  </div>
                  <div className='right'>
                      {data.staffID} / {data.printID}
                  </div>
                  <div className='center'>{this.props.report.copyright}</div>
                </div>
              </Container>
            )
        default:
            return null
        }
      }
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.kamakani, connected: state.reports.connected, options: state.reports.options, settings: state.staff.settings } },
  { ...StaffStore.actionCreators, ...ReportsStore.actionCreators }
)(KamakaniHTML as any)
