import { Action, Reducer } from 'redux'

type Modal = {
    open: boolean,
    tab: string,
    username: string,
    password: string,
    code: string
}

export interface ToggleModalAction { type: 'TOGGLE_MODAL', open: boolean }
export interface SetModalTabAction { type: 'SET_LOGIN_TAB', tab: 'login' | 'code' }
interface SetUsernameAction { type: 'SET_USERNAME', username: string }
interface SetPasswordAction { type: 'SET_PASSWORD', password: string }
interface SetCodeAction { type: 'SET_CODE', code: string }

type KnownAction = ToggleModalAction | SetModalTabAction | SetUsernameAction | SetPasswordAction | SetCodeAction

export interface LandingState {
    modal: Modal
}

export const actionCreators = {
    toggleModal: (open: boolean) => ({ type: 'TOGGLE_MODAL', open: open } as ToggleModalAction),
    setTab: (tab: 'login' | 'code' | 'forgot') => ({ type: 'SET_LOGIN_TAB', tab: tab } as SetModalTabAction),
    setUsername: (username: string) => ({ type: 'SET_USERNAME', username: username } as SetUsernameAction),
    setPassword: (password: string) => ({ type: 'SET_PASSWORD', password: password } as SetPasswordAction),
    setCode: (code: string) => ({ type: 'SET_CODE', code: code } as SetCodeAction)
}

export const reducer: Reducer<LandingState> = (state: LandingState | undefined, incomingAction: Action): LandingState => {
    if (state === undefined) {
        return {
            modal: { open: false, tab: 'login', username: '', password: '', code: '' }
        }
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'TOGGLE_MODAL':
            return {
                ...state,
                modal: { open: action.open, tab: 'login', username: '', password: '', code: '' }
            }
        case 'SET_LOGIN_TAB':
            return {
                ...state,
                modal: { ...state.modal, tab: action.tab }
            }
        case 'SET_USERNAME':
            return {
                ...state,
                modal: { ...state.modal, username: action.username }
            }
        case 'SET_PASSWORD':
            return {
                ...state,
                modal: { ...state.modal, password: action.password }
            }
        case 'SET_CODE':
            return {
                ...state,
                modal: { ...state.modal, code: action.code }
            }
        default:
            return state
    }
}