import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import * as StaffStore from '../../store/reducers/staff'
import PermissionCells from './PermissionCells'
import { SliderPicker } from 'react-color'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import Cleave from "cleave.js/react"
import {
    Container,
    Button,
    Form,
    Input,
    Select,
    Modal,
    Table,
    Icon,
    Grid,
    Header,
    Message,
    Popup
} from 'semantic-ui-react'
const _ = require('underscore')

type PaneProps =
    { staff: PanesStore.Staff, admin: boolean, num: 1 | 2, print: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class StaffPane extends React.PureComponent<PaneProps> {
    public facility() {
        var facility = ''
        _.each(this.props.staff.options.facilities, (f:any) => {
            if (f.key == this.props.staff.facility) facility = f.text
        })
        return facility
    }

    public program() {
        var program = ''
        _.each(this.props.staff.options.programs, (p:any) => {
            if (p.key == this.props.staff.program) program = p.text
        })
        return program
    }

    public render() {
        const getPresets = () => {
            return _.groupBy(_.filter(this.props.staff.presets, (preset:any) => preset.departmentID == this.props.staff.program), (preset:any) => preset.name)
        }

        const textColor = (bgColor:string, lightColor:string, darkColor:string) => {
          var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor
          var r = parseInt(color.substring(0, 2), 16)
          var g = parseInt(color.substring(2, 4), 16)
          var b = parseInt(color.substring(4, 6), 16)
          return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? darkColor : lightColor
        }

        return (
          <Container fluid>
            {this.props.print ? null : <div className='close' style={{position:'absolute',top:0,left:0,zIndex:1}}>
              <Button circular color='red' icon='close' size='mini' onClick={() => this.props.togglePane(this.props.num)} />
            </div>}
            <Button color='blue' circular icon='print' onClick={() => this.props.togglePrint(this.props.num)} style={{float:'right',fontSize:'1.2em',marginTop:15}} />
            {this.props.staff.info.deleted ? <Message warning compact header='Archived' style={{float:'right',marginRight:10}} /> : null}
            <Grid style={{marginBottom:10}}>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column>
                  <Header as='h2' style={{display:'inline-block',marginTop:0,width:750,whiteSpace:'normal'}}><div><Icon name='building' /> {this.props.staff.data.name} <span style={{fontSize:'0.8em',color:'#ccc',marginLeft:10,verticalAlign:'middle'}}>{this.props.staff.info.recordID}</span></div></Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {_.map(this.props.staff.options.facilities, (facility:any) => (
              <Button size='mini' color={facility.key == this.props.staff.facility ? 'green' : undefined} content={facility.text} onClick={() => this.props.selectStaffFacility(this.props.num, this.props.staff.data.recordID, this.props.staff.program, facility.key)} key={facility.key} />
            ))}
            {this.props.print ? null : <Button size='mini' color='red' content='-' onClick={_.debounce(() => this.props.removeFacility(this.props.num), 10000, true)} />}
            {this.props.print ? null : <Button size='mini' color='blue' content='+' onClick={() => this.props.toggleFacilityModal(this.props.num)} />}
            <Form style={{marginTop:14}}>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='Last Name'
                  name='lastName'
                  value={this.props.staff.data.lastName||''}
                  onChange={(e:any, data:any) => this.props.editStaffField(this.props.num, 'lastName', data.value.validateName())}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Input}
                  label='First Name'
                  name='firstName'
                  value={this.props.staff.data.firstName||''}
                  onChange={(e:any, data:any) => this.props.editStaffField(this.props.num, 'firstName', data.value.validateName())}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Input}
                  label='Login'
                  name='login'
                  value={this.props.staff.data.username||''}
                  onChange={(e:any, data:any) => this.props.editStaffField(this.props.num, 'username', data.value.validateEmail())}
                  className={this.props.print ? 'transparent' : ''}
                />
                {this.props.print ? null : <Button color='blue' content='Reset Password' onClick={_.debounce(() => this.props.passwordReset(this.props.staff.data.recordID), 10000, true)} style={{marginTop:18}} />}
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='Email'
                  name='email'
                  value={this.props.staff.data.email||''}
                  onChange={(e:any, data:any) => this.props.editStaffField(this.props.num, 'email', data.value.validateEmail())}
                  className={this.props.print ? 'transparent' : ''}
                />
                <div className='field'>
                  <label>Phone</label>
                  <Cleave
                    name='phone'
                    value={this.props.staff.data.phone||''}
                    onChange={(e:any) => this.props.editStaffField(this.props.num, 'phone', e.target.value)}
                    options={{
                      blocks: [3,3,4],
                      delimiter: '-',
                      numericOnly: true
                    }}
                    className={this.props.print ? 'transparent' : ''}
                  />
                </div>
                <Form.Field
                  control={Select}
                  label='Program'
                  name='program'
                  options={this.props.staff.options.programs}
                  value={this.props.staff.program}
                  onChange={(e:any, data:any) => this.props.fetchDepartmentOptions(this.props.num, this.props.staff.data.recordID, data.value)}
                  className={this.props.print ? 'transparent' : ''}
                  width={3}
                />
                <Form.Field
                  control={Select}
                  label='Position'
                  name='position'
                  options={this.props.staff.options.titles}
                  value={this.props.staff.titles||[]}
                  onChange={(e:any, data:any) => this.props.editStaffTitles(this.props.num, data.value)}
                  className={this.props.print ? 'transparent' : ''}
                  width={4}
                  multiple
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={DatePicker}
                  label='Exp Date'
                  name='expDate'
                  placeholder='MM/DD/YYYY'
                  selected={this.props.staff.data.expDate ? new Date(this.props.staff.data.expDate) : null}
                  onChange={(date:any) => this.props.editStaffField(this.props.num, 'expDate', date ? format(date, 'MM/dd/yyyy') : null)}
                  width={4}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Input}
                  label='Nickname'
                  name='nickname'
                  value={this.props.staff.data.nickname||''}
                  onChange={(e:any, data:any) => this.props.editStaffField(this.props.num, 'nickname', data.value.validateNick())}
                  className={this.props.print ? 'transparent' : ''}
                />
                <Form.Field
                  control={Select}
                  label='Active'
                  placeholder='Active'
                  options={[{ key: 0, text: 'No', value: false }, { key: 1, text: 'Yes', value: true }]}
                  value={!this.props.staff.data.deleted}
                  onChange={(e:any, data:any) => this.props.editStaffField(this.props.num, 'deleted', !data.value)}
                  className={this.props.print ? 'transparent' : ''}
                />
              </Form.Group>
              <Form.Group>
                <Form.Field
                  control={Input}
                  label='Daily Activity'
                  name='daily'
                  value={this.props.staff.data.daily||''}
                  onChange={(e:any, data:any) => {}}
                  className={'transparent'}
                  readOnly
                />
                <Form.Field
                  control={Input}
                  label='Weekly Activity'
                  name='weekly'
                  value={this.props.staff.data.weekly||''}
                  onChange={(e:any, data:any) => {}}
                  className={'transparent'}
                  readOnly
                />
                <Form.Field
                  control={Input}
                  label='Monthly Activity'
                  name='monthly'
                  value={this.props.staff.data.monthly||''}
                  onChange={(e:any, data:any) => {}}
                  className={'transparent'}
                  readOnly
                />
              </Form.Group>
              {this.props.print ? null : <Fragment>
                <Button positive content='Save' onClick={_.debounce(() => this.props.saveStaff(this.props.num), 10000, true)} />
                <Button color='blue' content={`Edit ${this.program()} permissions`} onClick={() => this.props.toggleDepartmentModal(this.props.num)} />
                {this.props.staff.facility > 0 ? <Button color='blue' content={`Edit ${this.facility()} permissions`} onClick={() => this.props.togglePermissionsModal(this.props.num)} /> : null}
                {this.props.admin ? <Button color='blue' content='Login as' onClick={() => this.props.loginAs(this.props.staff.data.recordID)} style={{marginLeft:10}} /> : null}
              </Fragment>}
            </Form>
            <Modal
              open={this.props.staff.modals.facility.open}
              onClose={() => this.props.toggleFacilityModal(this.props.num)}
              size='tiny'
            >
              <Modal.Header content='Add Facility' />
              <Modal.Content>
                <Form>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label="Facility"
                      name="facilityID"
                      options={_.filter(this.props.staff.options.allFacilities, (facility:any) => !_.any(this.props.staff.options.facilities, (f:any) => f.text == facility.text))}
                      value={this.props.staff.modals.facility.data.facilityID}
                      onChange={(e:any, data:any) => this.props.facilityModalChange(this.props.num, 'facilityID', data.value as number)}
                    />
                    <Form.Field
                      control={Select}
                      label="Position"
                      name="titleID"
                      options={this.props.staff.options.titles}
                      value={this.props.staff.modals.facility.data.titleID}
                      onChange={(e:any, data:any) => this.props.facilityModalChange(this.props.num, 'titleID', data.value as number)}
                    />
                  </Form.Group>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleFacilityModal(this.props.num)}>Close</Button>
                <Button color='green' onClick={_.debounce(() => this.props.addFacility(this.props.num), 10000, true)}>Add</Button>
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.staff.modals.department.open}
              onClose={() => this.props.toggleDepartmentModal(this.props.num)}
            >
              <Modal.Header content={`Edit ${this.program()} permissions`} />
              <Modal.Content>
                {Object.entries(getPresets()).map(([name, presets]: [string, any]) => (
                  <Button color='blue' content={name} onClick={() => this.props.applyDepartmentPresets(this.props.num, presets)} key={name} />
                ))}
                <Table compact celled striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Subsystem</Table.HeaderCell>
                      <Table.HeaderCell>Access Type</Table.HeaderCell>
                      <Table.HeaderCell>View</Table.HeaderCell>
                      <Table.HeaderCell>Edit</Table.HeaderCell>
                      <Table.HeaderCell>Add</Table.HeaderCell>
                      <Table.HeaderCell>Del</Table.HeaderCell>
                      <Table.HeaderCell>None</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell rowSpan={9} style={{ verticalAlign: 'top' }}><b>Admin</b></Table.Cell>
                      <PermissionCells num={this.props.num} display="System Administrator" subsystem="admin" module="system" options={['D']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Staff" subsystem="admin" module="staff" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Opening Messages" subsystem="admin" module="messages" options={['V','E']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Programs" subsystem="admin" module="programs" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Facilities" subsystem="admin" module="facilities" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Access Levels" subsystem="admin" module="access" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Menu Items" subsystem="admin" module="menus" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Schools" subsystem="admin" module="schools" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Holidays" subsystem="admin" module="holidays" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell rowSpan={this.props.staff.program == 1 || this.props.staff.program == 2 ? 5 : 4} style={{ verticalAlign: 'top' }}><b>Inmates</b></Table.Cell>
                      <PermissionCells num={this.props.num} display="Info" subsystem="inmates" module="info" options={['V','E']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Classes" subsystem="inmates" module="classes" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Tests" subsystem="inmates" module="tests" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 2 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Substance Abuse" subsystem="inmates" module="substance" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Printouts" subsystem="inmates" module="pdfs" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Admin Review" subsystem="inmates" module="review" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell rowSpan={6} style={{ verticalAlign: 'top' }}><b>Classes</b></Table.Cell>
                      <PermissionCells num={this.props.num} display="Info" subsystem="classes" module="info" options={['V','E','A','D']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Roster" subsystem="classes" module="roster" permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Attendance" subsystem="classes" module="attendance" options={['V','E','A','D']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Completions" subsystem="classes" module="completions" options={['V','A']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Waitlists" subsystem="classes" module="waitlists" options={['V','A','D']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Approvals" subsystem="classes" module="approvals" options={['V','E']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell rowSpan={1} style={{ verticalAlign: 'top' }}><b>Schedules</b></Table.Cell>
                      <PermissionCells num={this.props.num} display="Schedules" subsystem="schedules" module="schedules" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell rowSpan={17} style={{ verticalAlign: 'top' }}><b>Reports</b></Table.Cell>
                      <PermissionCells num={this.props.num} display="Classroom Utilization" subsystem="reports" module="utilization" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row>
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Contact Tracing" subsystem="reports" module="tracing" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="CSPR" subsystem="reports" module="cspr" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Quarterly" subsystem="reports" module="quarterly" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Full Roster" subsystem="reports" module="roster" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Funding Sources" subsystem="reports" module="funding" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Educational History" subsystem="reports" module="edhistory" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="GED Tests" subsystem="reports" module="ged" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="HiSET Tests" subsystem="reports" module="hiset" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Kamakani Report" subsystem="reports" module="kamakani" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Program Description" subsystem="reports" module="descriptions" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 2 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Admissions & Discharges" subsystem="reports" module="ands" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 2 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Screenings Drug of Choice" subsystem="reports" module="screenings" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 2 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="UAs" subsystem="reports" module="uas" options={['V']} permissions={this.props.staff.permissions.department} editPermission={this.props.editDepartmentPermission} removePermission={this.props.removeDepartmentPermission} />
                    </Table.Row> : null}
                  </Table.Body>
                </Table>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.toggleDepartmentModal(this.props.num)}>Close</Button>
                <Button color='green' onClick={_.debounce(() => this.props.saveDepartmentPermissions(this.props.num), 10000, true)}>Save</Button>
              </Modal.Actions>
            </Modal>
            <Modal
              open={this.props.staff.modals.permissions.open}
              onClose={() => this.props.togglePermissionsModal(this.props.num)}
            >
              <Modal.Header content={`Edit ${this.facility()} permissions`} />
              <Modal.Content>
                {Object.entries(getPresets()).map(([name, presets]: [string, any]) => (
                  <Button color='blue' content={name} onClick={() => this.props.applyFacilityPresets(this.props.num, presets)} key={name} />
                ))}
                <Table compact celled striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Subsystem</Table.HeaderCell>
                      <Table.HeaderCell>Access Type</Table.HeaderCell>
                      <Table.HeaderCell>View</Table.HeaderCell>
                      <Table.HeaderCell>Edit</Table.HeaderCell>
                      <Table.HeaderCell>Add</Table.HeaderCell>
                      <Table.HeaderCell>Del</Table.HeaderCell>
                      <Table.HeaderCell>None</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell rowSpan={2} style={{ verticalAlign: 'top' }}><b>Admin</b></Table.Cell>
                      <PermissionCells num={this.props.num} display="Staff" subsystem="admin" module="staff" permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Opening Messages" subsystem="admin" module="messages" options={['V','E']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell rowSpan={this.props.staff.program == 1 || this.props.staff.program == 2 ? 5 : 4} style={{ verticalAlign: 'top' }}><b>Inmates</b></Table.Cell>
                      <PermissionCells num={this.props.num} display="Info" subsystem="inmates" module="info" options={['V','E']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Classes" subsystem="inmates" module="classes" permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Tests" subsystem="inmates" module="tests" permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 2 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Substance Abuse" subsystem="inmates" module="substance" permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Printouts" subsystem="inmates" module="pdfs" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Admin Review" subsystem="inmates" module="review" permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell rowSpan={6} style={{ verticalAlign: 'top' }}><b>Classes</b></Table.Cell>
                      <PermissionCells num={this.props.num} display="Info" subsystem="classes" module="info" options={['V','E','A','D']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Roster" subsystem="classes" module="roster" permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Attendance" subsystem="classes" module="attendance" options={['V','E','A','D']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Completions" subsystem="classes" module="completions" options={['V','A']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Waitlists" subsystem="classes" module="waitlists" options={['V','A','D']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <PermissionCells num={this.props.num} display="Approvals" subsystem="classes" module="approvals" options={['V','E']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell rowSpan={1} style={{ verticalAlign: 'top' }}><b>Schedules</b></Table.Cell>
                      <PermissionCells num={this.props.num} display="Schedules" subsystem="schedules" module="schedules" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell rowSpan={17} style={{ verticalAlign: 'top' }}><b>Reports</b></Table.Cell>
                      <PermissionCells num={this.props.num} display="Classroom Utilization" subsystem="reports" module="utilization" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row>
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Contact Tracing" subsystem="reports" module="tracing" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="CSPR" subsystem="reports" module="cspr" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Quarterly" subsystem="reports" module="quarterly" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Full Roster" subsystem="reports" module="roster" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Funding Sources" subsystem="reports" module="funding" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Educational History" subsystem="reports" module="edhistory" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="GED Tests" subsystem="reports" module="ged" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="HiSET Tests" subsystem="reports" module="hiset" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Kamakani Report" subsystem="reports" module="kamakani" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 1 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Program Description" subsystem="reports" module="descriptions" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 2 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Admissions & Discharges" subsystem="reports" module="ands" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 2 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="Screenings Drug of Choice" subsystem="reports" module="screenings" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                    {this.props.staff.program == 2 ? <Table.Row>
                      <PermissionCells num={this.props.num} display="UAs" subsystem="reports" module="uas" options={['V']} permissions={this.props.staff.permissions.facility} editPermission={this.props.editFacilityPermission} removePermission={this.props.removeFacilityPermission} />
                    </Table.Row> : null}
                  </Table.Body>
                </Table>
              </Modal.Content>
              <Modal.Actions>
                <Button color='black' onClick={() => this.props.togglePermissionsModal(this.props.num)}>Close</Button>
                <Button color='green' onClick={_.debounce(() => this.props.saveFacilityPermissions(this.props.num), 10000, true)}>Save</Button>
              </Modal.Actions>
            </Modal>
          </Container>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { staff: ownProps.staff, admin: state.staff.staff ? state.staff.staff.admin : false, num: ownProps.num, print: ownProps.options.print } },
  { ...PanesStore.actionCreators, ...StaffStore.actionCreators }
)(StaffPane as any)
