import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import {
  Container,
  Accordion,
  Button,
  List,
  Modal,
  Form,
  Input,
  Select,
  TextArea,
  Checkbox,
  Tab,
  Grid
} from 'semantic-ui-react'
const _ = require('underscore')

type StaffProps =
    { options: any, data: any, num: 1 | 2, print: boolean } &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class TemplateOptions extends React.PureComponent<StaffProps> {
    public render() {
      return (
        <Form style={{marginTop:20}}>
          <Grid>
            <Grid.Column width={5}>
              <Form.Group>
                <Form.Field width={12} label="Display on transcript" />
                <Form.Field width={2}>
                  <Checkbox
                    name="transcript"
                    checked={this.props.data.transcript}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'transcript', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={12} label="Include class on movement list" />
                <Form.Field width={2}>
                  <Checkbox
                    name="movementList"
                    checked={this.props.data.movementList}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'movementList', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={12} label="Include class on signin list" />
                <Form.Field width={2}>
                  <Checkbox
                    name="signinList"
                    checked={this.props.data.signinList}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'signinList', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={12} label="Meet on holiday" />
                <Form.Field width={2}>
                  <Checkbox
                    name="meetOnHoliday"
                    checked={this.props.data.meetOnHoliday}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'meetOnHoliday', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={12} label="Meet on furlough day" />
                <Form.Field width={2}>
                  <Checkbox
                    name="meetOnFurloughDay"
                    checked={this.props.data.meetOnFurloughDay}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'meetOnFurloughDay', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={12} label="Accumulate Hours" />
                <Form.Field width={2}>
                  <Checkbox
                    name="accumulateHours"
                    checked={this.props.data.accumulateHours}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'accumulateHours', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={12} label="One time only" />
                <Form.Field width={2}>
                  <Checkbox
                    name="oneTimeOnly"
                    checked={this.props.data.oneTimeOnly}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'oneTimeOnly', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
            </Grid.Column>
            <Grid.Column width={6}>
              <Form.Group>
                <Form.Field width={10} label="Title 1 class" />
                <Form.Field width={2}>
                  <Checkbox
                    name="title1"
                    checked={this.props.data.title1}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'title1', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={10} label="ABE class" />
                <Form.Field width={2}>
                  <Checkbox
                    name="abe"
                    checked={this.props.data.abe}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'abe', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={10} label="DoE" />
                <Form.Field width={2}>
                  <Checkbox
                    name="doe"
                    checked={this.props.data.doe}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'doe', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={10} label="Online" />
                <Form.Field>
                  <Checkbox
                    name='online'
                    checked={this.props.data.online}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'online', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={10} label="Correspondence" />
                <Form.Field>
                  <Checkbox
                    name='online'
                    checked={this.props.data.correspondence}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'correspondence', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={10} label="No grade" />
                <Form.Field>
                  <Checkbox
                    name='noGrade'
                    checked={this.props.data.noGrade}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'noGrade', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={10} label="Reset Existing Classes" />
                <Form.Field width={2}>
                  <Checkbox
                    name="resetClasses"
                    checked={this.props.data.resetClasses}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'resetClasses', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
            </Grid.Column>
            <Grid.Column width={5}>
              <Form.Group>
                <Form.Field width={12} label="Workshop" />
                <Form.Field>
                  <Checkbox
                    name='workshop'
                    checked={this.props.data.workshop}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'workshop', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={12} label="Post Secondary" />
                <Form.Field>
                  <Checkbox
                    name='online'
                    checked={this.props.data.postSecondary}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'postSecondary', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={12} label="Workforce Development Diploma" />
                <Form.Field>
                  <Checkbox
                    name='online'
                    checked={this.props.data.workforceDiploma}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'workforceDiploma', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={12} label="Ignore diploma/HSE requirement" />
                <Form.Field width={2}>
                  <Checkbox
                    name="ignoreEdStatus"
                    checked={this.props.data.ignoreEdStatus}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'ignoreEdStatus', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={12} label="Ignore hours requirement" />
                <Form.Field width={2}>
                  <Checkbox
                    name="ignoreHoursRqt"
                    checked={this.props.data.ignoreHoursRqt}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'ignoreHoursRqt', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={12} label="No attendance" />
                <Form.Field width={2}>
                  <Checkbox
                    name="noAttendance"
                    checked={this.props.data.noAttendance}
                    onChange={(e:any, data:any) => this.props.editTemplateField(this.props.num, 'noAttendance', data.checked as boolean)}
                  />
                </Form.Field>
              </Form.Group>
            </Grid.Column>
            {this.props.print ? null : <Grid.Column style={{paddingTop:0}}>
              <Button positive content='Save' onClick={_.debounce(() => this.props.saveTemplate(this.props.num), 10000, true)} />
            </Grid.Column>}
          </Grid>
        </Form>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { data: ownProps.data, options: ownProps.options, num: ownProps.num, print: ownProps.print } },
  PanesStore.actionCreators
)(TemplateOptions as any)