import thunk from 'redux-thunk'
import reduxWebsocket from '@giantmachines/redux-websocket'
import { reducers } from './'
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory } from 'history'

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() })

export const store = configureStore({
    reducer: combineReducers({ ...reducers, router: routerReducer }),
    middleware: [thunk, routerMiddleware, reduxWebsocket({ prefix: 'UPDATES', deserializer: (message:string) => JSON.parse(message) }), reduxWebsocket({ prefix: 'REPORT', deserializer: (message:string) => JSON.parse(message) })]
})

export const history = createReduxHistory(store)
