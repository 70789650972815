import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { Link } from 'react-router-dom'
import { ApplicationState } from '../../store'
import * as ReportsStore from '../../store/reducers/reports'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import { Editor } from 'medium-draft'
import mediumDraftExporter from 'medium-draft/lib/exporter';
import {
  Header,
  Form,
  Button,
  Select,
  Divider,
  Search
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { admin: boolean } &
    ReportsStore.ReportsState &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class Tracing extends React.PureComponent<ReportProps> {
    public params() {
      let params = new URLSearchParams()
      for (let [key, value] of Object.entries(this.props.reports.tracing.data)) {
        if (value != undefined && value != null && value != '' && key != 'reportTypes' && key != 'classes') {
            if (key == 'filterClasses') {
              for (let ovalue of Object.values(value)) {
                  params.append(key, ovalue.key)
              }
            } else if (typeof value == 'object') {
                for (let ovalue of Object.values(value as any)) {
                  params.append(key, ovalue as string)
                }
            } else {
                params.append(key, (value as any).toString())
            }
        }
      }
      return params.toString()
    }

    public render() {
      return (
        <Fragment>
          <Header as="h2">Contact Tracing Report</Header>
          {this.props.admin ? <Fragment>
            <div style={{border:'thin solid #ddd',padding:1,borderRadius:5,marginBottom:12}}>
              <Editor placeholder="Enter a report description..." editorState={this.props.reports.tracing.description} sideButtons={[]} onChange={(state:any) => this.props.updateDescription(state)} />
            </div>
            <Button positive content='Save' onClick={this.props.saveDescription} />
          </Fragment> : null}
          {this.props.admin ? null : <div style={{whiteSpace:'normal'}} dangerouslySetInnerHTML={{__html: mediumDraftExporter(this.props.reports.tracing.description.getCurrentContent())}} />}
          <Divider />
          <Form>
            <Form.Group>
              <Form.Field
                control={DatePicker}
                label="Beginning date"
                name="startDate"
                placeholder="MM/DD/YYYY"
                selected={this.props.reports.tracing.data.startDate ? new Date(this.props.reports.tracing.data.startDate) : null}
                onChange={(date:any) => this.props.dataChange('tracing', 'startDate', date ? format(date, 'MM/dd/yyyy') : null)}
                width={4}
              />
              <Form.Field
                control={DatePicker}
                label="Ending date"
                name="endDate"
                placeholder="MM/DD/YYYY"
                selected={this.props.reports.tracing.data.endDate ? new Date(this.props.reports.tracing.data.endDate) : null}
                onChange={(date:any) => this.props.dataChange('tracing', 'endDate', date ? format(date, 'MM/dd/yyyy') : null)}
                width={4}
              />
              <Form.Field
                control={Select}
                label='Display type'
                placeholder='Display type'
                options={[{key:0,value:'html',text:'HTML'},{key:1,value:'pdf',text:'PDF'}]}
                value={this.props.reports.tracing.data.displayType}
                onChange={(e:any, data:any) => this.props.dataChange('tracing', 'displayType', data.value)}
                width={4}
              />
              {/*this.props.reports.tracing.data.displayType == 'pdf' ? <Form.Field
                control={Select}
                label='Orientation'
                placeholder='Orientation'
                options={[{key:0,value:'landscape',text:'Landscape'},{key:1,value:'portrait',text:'Portrait'}]}
                value={this.props.reports.tracing.data.orientation}
                onChange={(e:any, data:any) => this.props.dataChange('tracing', 'orientation', data.value)}
                width={4}
              /> : null*/}
            </Form.Group>
            <Form.Group>
              <Button color="teal" name="LastFY" content="Last FY" size="mini" onClick={this.props.lastFY} style={{marginLeft:7}} />
              <Button color="teal" name="LastCY" content="Last CY" size="mini" onClick={this.props.lastCY} />
              <Button color="teal" name="LastQTR" content="Last QTR" size="mini" onClick={this.props.lastQTR} />
              <Button color="teal" name="LastHalf" content="Last Half" size="mini" onClick={this.props.lastHalf} />
              <Button color="teal" name="ThisFY" content="This FY" size="mini" onClick={this.props.thisFY} />
              <Button color="teal" name="ThisCY" content="This CY" size="mini" onClick={this.props.thisCY} />
              <Button color="teal" name="LastMonth" content="Last Month" size="mini" onClick={this.props.lastMonth} />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Facilities'
                placeholder='Facilities'
                options={[{key:0,value:0,text:'All facilities'}].concat(this.props.reports.tracing.facilities)}
                value={this.props.reports.tracing.data.facilities}
                onChange={(e:any, data:any) => this.props.dataChange('tracing', 'facilities', data.value)}
                multiple
                width={12}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Select}
                label='Filter classes'
                options={[{key:0,value:'all',text:'No'},{key:1,value:'include',text:'Include'},{key:2,value:'exclude',text:'Exclude'}]}
                value={this.props.reports.tracing.data.classFilter}
                onChange={(e:any, data:any) => this.props.dataChange('tracing', 'classFilter', data.value)}
                width={4}
              />
              {this.props.reports.tracing.data.classFilter == 'all' ? null : <Form.Field
                control={Search}
                label='Search Classes'
                loading={this.props.reports.tracing.data.classesLoading}
                onResultSelect={(e:any, data:any) => { this.props.selectClass('tracing', data.result) }}
                onSearchChange={(e:any, data:any) => { this.props.searchClasses('tracing', data.value as string) }}
                results={this.props.reports.tracing.data.classes}
                value={this.props.reports.tracing.data.class}
                onFocus={() => {}}
                width={4}
                fluid
              />}
              {this.props.reports.tracing.data.classFilter == 'all' ? null : <Form.Field
                control={Select}
                label='Classes'
                placeholder='Classes'
                options={_.map(this.props.reports.tracing.data.filterClasses, (c:any) => { return {key:c.key,value:`[${c.key}] ${c.value}`,text:`[${c.key}] ${c.value}`} })}
                value={_.map(this.props.reports.tracing.data.filterClasses, (c:any) => `[${c.key}] ${c.value}`)}
                onChange={(e:any, data:any) => this.props.dataChange('tracing', 'filterClasses', data.value)}
                multiple
                width={8}
              />}
            </Form.Group>
            {this.props.reports.tracing.data.displayType == 'html' ? <Button color='blue' content='Generate report' as={Link} to={`/reports/tracing/html?${this.params()}`} target='_window' /> : null}
            {this.props.reports.tracing.data.displayType != 'html' ? <Button color='blue' content='Generate report' onClick={this.props.generateTracing} /> : null}
          </Form>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { ...state.reports, admin: state.staff.staff!.admin } },
  ReportsStore.actionCreators
)(Tracing as any)