import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import {
  Button,
  Form,
  Input,
  TextArea
} from 'semantic-ui-react'

type LogProps =
    { settings: any, loading: boolean, hasMore: boolean } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class AppSite extends React.PureComponent<LogProps> {
    public componentDidMount() {
        this.props.fetchAppSettings()
    }

    public render() {
      return (
        <Fragment>
          <Form>
            <Form.Group>
              <Form.Field
                control={Input}
                label="Heading 1 Title"
                name="heading1"
                value={this.props.settings.data.heading1}
                onChange={(e:any, data:any) => this.props.updateSetting('heading1', data.value)}
                width={6}
              />
              <Form.Field
                control={TextArea}
                label="Heading 1 Content"
                name="heading1Content"
                value={this.props.settings.data.heading1Content}
                onChange={(e:any, data:any) => this.props.updateSetting('heading1Content', data.value)}
                width={10}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                label="Heading 2 Title"
                name="heading2"
                value={this.props.settings.data.heading2}
                onChange={(e:any, data:any) => this.props.updateSetting('heading2', data.value)}
                width={6}
              />
              <Form.Field
                control={TextArea}
                label="Heading 2 Content"
                name="heading2Content"
                value={this.props.settings.data.heading2Content}
                onChange={(e:any, data:any) => this.props.updateSetting('heading2Content', data.value)}
                width={10}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={Input}
                label="Heading 3 Title"
                name="heading3"
                value={this.props.settings.data.heading3}
                onChange={(e:any, data:any) => this.props.updateSetting('heading3', data.value)}
                width={6}
              />
              <Form.Field
                control={TextArea}
                label="Heading 3 Content"
                name="heading3Content"
                value={this.props.settings.data.heading3Content}
                onChange={(e:any, data:any) => this.props.updateSetting('heading3Content', data.value)}
                width={10}
              />
            </Form.Group>
            <Form.Group>
              <Form.Field
                control={TextArea}
                label="Fine Print"
                name="finePrint"
                value={this.props.settings.data.finePrint}
                onChange={(e:any, data:any) => this.props.updateSetting('finePrint', data.value)}
                width={16}
              />
            </Form.Group>
            <Button positive content='Save' onClick={() => this.props.saveSettings()} />
          </Form>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { settings: state.admin.settings, loading: state.admin.logs.pdfs.loading, hasMore: state.admin.logs.pdfs.hasMore } },
  AdminStore.actionCreators
)(AppSite as any)
