import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps = { report: any }

class RecidivismFacilityStudentBreakdowns extends React.PureComponent<ReportProps> {
    public render() {
      let params = this.props.report.data
      let data = this.props.report.generated

      return (
        <Fragment>
          {_.map(data.facilities, (facility:any) => <div key={facility.abbreviation}>
            <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
              <div style={{fontWeight:700,fontSize:16}}>Student Breakdowns - {facility.abbreviation}</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              <div style={{flexBasis:`95%`}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={10} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Class Hours and Program Particpation</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Academics</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Vocational</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Self Development</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Social Studies</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Miscellaneous</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 5 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours1To5.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours1To5.recidivism / facility.extraStudents.academics.students.hours1To5.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours1To5.recidivism / facility.extraStudents.academics.students.hours1To5.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours1To5.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours1To5.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours1To5.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours1To5.recidivism / facility.extraStudents.vocational.students.hours1To5.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours1To5.recidivism / facility.extraStudents.vocational.students.hours1To5.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours1To5.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours1To5.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours1To5.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours1To5.recidivism / facility.extraStudents.selfDevelopment.students.hours1To5.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours1To5.recidivism / facility.extraStudents.selfDevelopment.students.hours1To5.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours1To5.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours1To5.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours1To5.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours1To5.recidivism / facility.extraStudents.socialStudies.students.hours1To5.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours1To5.recidivism / facility.extraStudents.socialStudies.students.hours1To5.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours1To5.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours1To5.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours1To5.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours1To5.recidivism / facility.extraStudents.misc.students.hours1To5.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours1To5.recidivism / facility.extraStudents.misc.students.hours1To5.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours1To5.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours1To5.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours5To10.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours5To10.recidivism / facility.extraStudents.academics.students.hours5To10.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours5To10.recidivism / facility.extraStudents.academics.students.hours5To10.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours5To10.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours5To10.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours5To10.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours5To10.recidivism / facility.extraStudents.vocational.students.hours5To10.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours5To10.recidivism / facility.extraStudents.vocational.students.hours5To10.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours5To10.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours5To10.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours5To10.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours5To10.recidivism / facility.extraStudents.selfDevelopment.students.hours5To10.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours5To10.recidivism / facility.extraStudents.selfDevelopment.students.hours5To10.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours5To10.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours5To10.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours5To10.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours5To10.recidivism / facility.extraStudents.socialStudies.students.hours5To10.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours5To10.recidivism / facility.extraStudents.socialStudies.students.hours5To10.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours5To10.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours5To10.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours5To10.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours5To10.recidivism / facility.extraStudents.misc.students.hours5To10.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours5To10.recidivism / facility.extraStudents.misc.students.hours5To10.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours5To10.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours5To10.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>10 - 25 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours10To25.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours10To25.recidivism / facility.extraStudents.academics.students.hours10To25.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours10To25.recidivism / facility.extraStudents.academics.students.hours10To25.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours10To25.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours10To25.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours10To25.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours10To25.recidivism / facility.extraStudents.vocational.students.hours10To25.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours10To25.recidivism / facility.extraStudents.vocational.students.hours10To25.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours10To25.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours10To25.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours10To25.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours10To25.recidivism / facility.extraStudents.selfDevelopment.students.hours10To25.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours10To25.recidivism / facility.extraStudents.selfDevelopment.students.hours10To25.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours10To25.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours10To25.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours10To25.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours10To25.recidivism / facility.extraStudents.socialStudies.students.hours10To25.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours10To25.recidivism / facility.extraStudents.socialStudies.students.hours10To25.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours10To25.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours10To25.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours10To25.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours10To25.recidivism / facility.extraStudents.misc.students.hours10To25.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours10To25.recidivism / facility.extraStudents.misc.students.hours10To25.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours10To25.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours10To25.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>25 - 50 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours25To50.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours25To50.recidivism / facility.extraStudents.academics.students.hours25To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours25To50.recidivism / facility.extraStudents.academics.students.hours25To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours25To50.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours25To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours25To50.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours25To50.recidivism / facility.extraStudents.vocational.students.hours25To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours25To50.recidivism / facility.extraStudents.vocational.students.hours25To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours25To50.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours25To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours25To50.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours25To50.recidivism / facility.extraStudents.selfDevelopment.students.hours25To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours25To50.recidivism / facility.extraStudents.selfDevelopment.students.hours25To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours25To50.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours25To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours25To50.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours25To50.recidivism / facility.extraStudents.socialStudies.students.hours25To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours25To50.recidivism / facility.extraStudents.socialStudies.students.hours25To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours25To50.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours25To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours25To50.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours25To50.recidivism / facility.extraStudents.misc.students.hours25To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours25To50.recidivism / facility.extraStudents.misc.students.hours25To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours25To50.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours25To50.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 100 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours50To100.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours50To100.recidivism / facility.extraStudents.academics.students.hours50To100.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours50To100.recidivism / facility.extraStudents.academics.students.hours50To100.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours50To100.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours50To100.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours50To100.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours50To100.recidivism / facility.extraStudents.vocational.students.hours50To100.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours50To100.recidivism / facility.extraStudents.vocational.students.hours50To100.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours50To100.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours50To100.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours50To100.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours50To100.recidivism / facility.extraStudents.selfDevelopment.students.hours50To100.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours50To100.recidivism / facility.extraStudents.selfDevelopment.students.hours50To100.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours50To100.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours50To100.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours50To100.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours50To100.recidivism / facility.extraStudents.socialStudies.students.hours50To100.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours50To100.recidivism / facility.extraStudents.socialStudies.students.hours50To100.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours50To100.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours50To100.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours50To100.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours50To100.recidivism / facility.extraStudents.misc.students.hours50To100.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours50To100.recidivism / facility.extraStudents.misc.students.hours50To100.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours50To100.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours50To100.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>100 - 250 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours100To250.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours100To250.recidivism / facility.extraStudents.academics.students.hours100To250.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours100To250.recidivism / facility.extraStudents.academics.students.hours100To250.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours100To250.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours100To250.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours100To250.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours100To250.recidivism / facility.extraStudents.vocational.students.hours100To250.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours100To250.recidivism / facility.extraStudents.vocational.students.hours100To250.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours100To250.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours100To250.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours100To250.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours100To250.recidivism / facility.extraStudents.selfDevelopment.students.hours100To250.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours100To250.recidivism / facility.extraStudents.selfDevelopment.students.hours100To250.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours100To250.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours100To250.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours100To250.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours100To250.recidivism / facility.extraStudents.socialStudies.students.hours100To250.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours100To250.recidivism / facility.extraStudents.socialStudies.students.hours100To250.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours100To250.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours100To250.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours100To250.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours100To250.recidivism / facility.extraStudents.misc.students.hours100To250.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours100To250.recidivism / facility.extraStudents.misc.students.hours100To250.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours100To250.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours100To250.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>250 - 500 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours250To500.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hours250To500.recidivism / facility.extraStudents.academics.students.hours250To500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hours250To500.recidivism / facility.extraStudents.academics.students.hours250To500.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hours250To500.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hours250To500.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours250To500.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hours250To500.recidivism / facility.extraStudents.vocational.students.hours250To500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hours250To500.recidivism / facility.extraStudents.vocational.students.hours250To500.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hours250To500.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hours250To500.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours250To500.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hours250To500.recidivism / facility.extraStudents.selfDevelopment.students.hours250To500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hours250To500.recidivism / facility.extraStudents.selfDevelopment.students.hours250To500.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hours250To500.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hours250To500.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours250To500.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hours250To500.recidivism / facility.extraStudents.socialStudies.students.hours250To500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hours250To500.recidivism / facility.extraStudents.socialStudies.students.hours250To500.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hours250To500.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hours250To500.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours250To500.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hours250To500.recidivism / facility.extraStudents.misc.students.hours250To500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hours250To500.recidivism / facility.extraStudents.misc.students.hours250To500.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hours250To500.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hours250To500.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Over 500 Hours</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hoursOver500.total == 0 ? '0' : Math.round(facility.extraStudents.academics.students.hoursOver500.recidivism / facility.extraStudents.academics.students.hoursOver500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.students.hoursOver500.recidivism / facility.extraStudents.academics.students.hoursOver500.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.students.hoursOver500.recidivism.toFixed(0)} / {facility.extraStudents.academics.students.hoursOver500.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hoursOver500.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.students.hoursOver500.recidivism / facility.extraStudents.vocational.students.hoursOver500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.students.hoursOver500.recidivism / facility.extraStudents.vocational.students.hoursOver500.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.students.hoursOver500.recidivism.toFixed(0)} / {facility.extraStudents.vocational.students.hoursOver500.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hoursOver500.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.students.hoursOver500.recidivism / facility.extraStudents.selfDevelopment.students.hoursOver500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.students.hoursOver500.recidivism / facility.extraStudents.selfDevelopment.students.hoursOver500.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.students.hoursOver500.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.students.hoursOver500.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hoursOver500.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.students.hoursOver500.recidivism / facility.extraStudents.socialStudies.students.hoursOver500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.students.hoursOver500.recidivism / facility.extraStudents.socialStudies.students.hoursOver500.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.students.hoursOver500.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.students.hoursOver500.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hoursOver500.total == 0 ? '0' : Math.round(facility.extraStudents.misc.students.hoursOver500.recidivism / facility.extraStudents.misc.students.hoursOver500.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.students.hoursOver500.recidivism / facility.extraStudents.misc.students.hoursOver500.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.students.hoursOver500.recidivism.toFixed(0)} / {facility.extraStudents.misc.students.hoursOver500.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={16} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Education Attained and Age</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>None</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.ageUnder22.recidivism / facility.extraStudents.levelNone.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.ageUnder22.recidivism / facility.extraStudents.levelNone.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age22To30.recidivism / facility.extraStudents.levelNone.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age22To30.recidivism / facility.extraStudents.levelNone.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age30To40.recidivism / facility.extraStudents.levelNone.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age30To40.recidivism / facility.extraStudents.levelNone.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age40To50.recidivism / facility.extraStudents.levelNone.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age40To50.recidivism / facility.extraStudents.levelNone.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age50To60.recidivism / facility.extraStudents.levelNone.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age50To60.recidivism / facility.extraStudents.levelNone.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age60To70.recidivism / facility.extraStudents.levelNone.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age60To70.recidivism / facility.extraStudents.levelNone.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age70To80.recidivism / facility.extraStudents.levelNone.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age70To80.recidivism / facility.extraStudents.levelNone.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.ages.age80To90.recidivism / facility.extraStudents.levelNone.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.ages.age80To90.recidivism / facility.extraStudents.levelNone.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Primary</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.ageUnder22.recidivism / facility.extraStudents.levelPrimary.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.ageUnder22.recidivism / facility.extraStudents.levelPrimary.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age22To30.recidivism / facility.extraStudents.levelPrimary.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age22To30.recidivism / facility.extraStudents.levelPrimary.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age30To40.recidivism / facility.extraStudents.levelPrimary.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age30To40.recidivism / facility.extraStudents.levelPrimary.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age40To50.recidivism / facility.extraStudents.levelPrimary.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age40To50.recidivism / facility.extraStudents.levelPrimary.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age50To60.recidivism / facility.extraStudents.levelPrimary.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age50To60.recidivism / facility.extraStudents.levelPrimary.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age60To70.recidivism / facility.extraStudents.levelPrimary.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age60To70.recidivism / facility.extraStudents.levelPrimary.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age70To80.recidivism / facility.extraStudents.levelPrimary.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age70To80.recidivism / facility.extraStudents.levelPrimary.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.ages.age80To90.recidivism / facility.extraStudents.levelPrimary.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.ages.age80To90.recidivism / facility.extraStudents.levelPrimary.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Secondary</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.ageUnder22.recidivism / facility.extraStudents.levelSecondary.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.ageUnder22.recidivism / facility.extraStudents.levelSecondary.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age22To30.recidivism / facility.extraStudents.levelSecondary.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age22To30.recidivism / facility.extraStudents.levelSecondary.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age30To40.recidivism / facility.extraStudents.levelSecondary.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age30To40.recidivism / facility.extraStudents.levelSecondary.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age40To50.recidivism / facility.extraStudents.levelSecondary.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age40To50.recidivism / facility.extraStudents.levelSecondary.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age50To60.recidivism / facility.extraStudents.levelSecondary.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age50To60.recidivism / facility.extraStudents.levelSecondary.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age60To70.recidivism / facility.extraStudents.levelSecondary.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age60To70.recidivism / facility.extraStudents.levelSecondary.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age70To80.recidivism / facility.extraStudents.levelSecondary.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age70To80.recidivism / facility.extraStudents.levelSecondary.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.ages.age80To90.recidivism / facility.extraStudents.levelSecondary.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.ages.age80To90.recidivism / facility.extraStudents.levelSecondary.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Post Secondary</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.ageUnder22.recidivism / facility.extraStudents.levelPost.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.ageUnder22.recidivism / facility.extraStudents.levelPost.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age22To30.recidivism / facility.extraStudents.levelPost.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age22To30.recidivism / facility.extraStudents.levelPost.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age30To40.recidivism / facility.extraStudents.levelPost.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age30To40.recidivism / facility.extraStudents.levelPost.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age40To50.recidivism / facility.extraStudents.levelPost.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age40To50.recidivism / facility.extraStudents.levelPost.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age50To60.recidivism / facility.extraStudents.levelPost.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age50To60.recidivism / facility.extraStudents.levelPost.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age60To70.recidivism / facility.extraStudents.levelPost.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age60To70.recidivism / facility.extraStudents.levelPost.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age70To80.recidivism / facility.extraStudents.levelPost.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age70To80.recidivism / facility.extraStudents.levelPost.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.ages.age80To90.recidivism / facility.extraStudents.levelPost.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.ages.age80To90.recidivism / facility.extraStudents.levelPost.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={16} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Program Participation and Age</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Academics</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.ageUnder22.recidivism / facility.extraStudents.academics.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.ageUnder22.recidivism / facility.extraStudents.academics.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age22To30.recidivism / facility.extraStudents.academics.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age22To30.recidivism / facility.extraStudents.academics.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age30To40.recidivism / facility.extraStudents.academics.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age30To40.recidivism / facility.extraStudents.academics.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age40To50.recidivism / facility.extraStudents.academics.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age40To50.recidivism / facility.extraStudents.academics.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age50To60.recidivism / facility.extraStudents.academics.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age50To60.recidivism / facility.extraStudents.academics.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age60To70.recidivism / facility.extraStudents.academics.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age60To70.recidivism / facility.extraStudents.academics.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age70To80.recidivism / facility.extraStudents.academics.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age70To80.recidivism / facility.extraStudents.academics.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.academics.ages.age80To90.recidivism / facility.extraStudents.academics.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.ages.age80To90.recidivism / facility.extraStudents.academics.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.academics.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Vocational</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.ageUnder22.recidivism / facility.extraStudents.vocational.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.ageUnder22.recidivism / facility.extraStudents.vocational.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age22To30.recidivism / facility.extraStudents.vocational.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age22To30.recidivism / facility.extraStudents.vocational.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age30To40.recidivism / facility.extraStudents.vocational.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age30To40.recidivism / facility.extraStudents.vocational.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age40To50.recidivism / facility.extraStudents.vocational.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age40To50.recidivism / facility.extraStudents.vocational.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age50To60.recidivism / facility.extraStudents.vocational.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age50To60.recidivism / facility.extraStudents.vocational.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age60To70.recidivism / facility.extraStudents.vocational.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age60To70.recidivism / facility.extraStudents.vocational.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age70To80.recidivism / facility.extraStudents.vocational.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age70To80.recidivism / facility.extraStudents.vocational.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.ages.age80To90.recidivism / facility.extraStudents.vocational.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.ages.age80To90.recidivism / facility.extraStudents.vocational.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.vocational.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Self Development</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.ageUnder22.recidivism / facility.extraStudents.selfDevelopment.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.ageUnder22.recidivism / facility.extraStudents.selfDevelopment.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age22To30.recidivism / facility.extraStudents.selfDevelopment.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age22To30.recidivism / facility.extraStudents.selfDevelopment.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age30To40.recidivism / facility.extraStudents.selfDevelopment.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age30To40.recidivism / facility.extraStudents.selfDevelopment.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age40To50.recidivism / facility.extraStudents.selfDevelopment.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age40To50.recidivism / facility.extraStudents.selfDevelopment.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age50To60.recidivism / facility.extraStudents.selfDevelopment.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age50To60.recidivism / facility.extraStudents.selfDevelopment.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age60To70.recidivism / facility.extraStudents.selfDevelopment.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age60To70.recidivism / facility.extraStudents.selfDevelopment.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age70To80.recidivism / facility.extraStudents.selfDevelopment.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age70To80.recidivism / facility.extraStudents.selfDevelopment.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.ages.age80To90.recidivism / facility.extraStudents.selfDevelopment.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.ages.age80To90.recidivism / facility.extraStudents.selfDevelopment.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Social Studies</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.ageUnder22.recidivism / facility.extraStudents.socialStudies.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.ageUnder22.recidivism / facility.extraStudents.socialStudies.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age22To30.recidivism / facility.extraStudents.socialStudies.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age22To30.recidivism / facility.extraStudents.socialStudies.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age30To40.recidivism / facility.extraStudents.socialStudies.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age30To40.recidivism / facility.extraStudents.socialStudies.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age40To50.recidivism / facility.extraStudents.socialStudies.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age40To50.recidivism / facility.extraStudents.socialStudies.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age50To60.recidivism / facility.extraStudents.socialStudies.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age50To60.recidivism / facility.extraStudents.socialStudies.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age60To70.recidivism / facility.extraStudents.socialStudies.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age60To70.recidivism / facility.extraStudents.socialStudies.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age70To80.recidivism / facility.extraStudents.socialStudies.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age70To80.recidivism / facility.extraStudents.socialStudies.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.ages.age80To90.recidivism / facility.extraStudents.socialStudies.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.ages.age80To90.recidivism / facility.extraStudents.socialStudies.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Miscellaneous</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.ageUnder22.recidivism / facility.extraStudents.misc.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.ageUnder22.recidivism / facility.extraStudents.misc.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age22To30.recidivism / facility.extraStudents.misc.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age22To30.recidivism / facility.extraStudents.misc.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age30To40.recidivism / facility.extraStudents.misc.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age30To40.recidivism / facility.extraStudents.misc.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age40To50.recidivism / facility.extraStudents.misc.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age40To50.recidivism / facility.extraStudents.misc.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age50To60.recidivism / facility.extraStudents.misc.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age50To60.recidivism / facility.extraStudents.misc.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age60To70.recidivism / facility.extraStudents.misc.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age60To70.recidivism / facility.extraStudents.misc.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age70To80.recidivism / facility.extraStudents.misc.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age70To80.recidivism / facility.extraStudents.misc.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.misc.ages.age80To90.recidivism / facility.extraStudents.misc.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.ages.age80To90.recidivism / facility.extraStudents.misc.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.misc.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              <div style={{flexBasis:`95%`}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={16} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Class Hours and Age</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 5 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.ageUnder22.recidivism / facility.extraStudents.hours1To5.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.ageUnder22.recidivism / facility.extraStudents.hours1To5.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age22To30.recidivism / facility.extraStudents.hours1To5.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age22To30.recidivism / facility.extraStudents.hours1To5.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age30To40.recidivism / facility.extraStudents.hours1To5.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age30To40.recidivism / facility.extraStudents.hours1To5.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age40To50.recidivism / facility.extraStudents.hours1To5.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age40To50.recidivism / facility.extraStudents.hours1To5.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age50To60.recidivism / facility.extraStudents.hours1To5.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age50To60.recidivism / facility.extraStudents.hours1To5.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age60To70.recidivism / facility.extraStudents.hours1To5.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age60To70.recidivism / facility.extraStudents.hours1To5.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age70To80.recidivism / facility.extraStudents.hours1To5.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age70To80.recidivism / facility.extraStudents.hours1To5.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.ages.age80To90.recidivism / facility.extraStudents.hours1To5.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.ages.age80To90.recidivism / facility.extraStudents.hours1To5.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.ageUnder22.recidivism / facility.extraStudents.hours5To10.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.ageUnder22.recidivism / facility.extraStudents.hours5To10.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age22To30.recidivism / facility.extraStudents.hours5To10.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age22To30.recidivism / facility.extraStudents.hours5To10.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age30To40.recidivism / facility.extraStudents.hours5To10.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age30To40.recidivism / facility.extraStudents.hours5To10.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age40To50.recidivism / facility.extraStudents.hours5To10.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age40To50.recidivism / facility.extraStudents.hours5To10.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age50To60.recidivism / facility.extraStudents.hours5To10.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age50To60.recidivism / facility.extraStudents.hours5To10.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age60To70.recidivism / facility.extraStudents.hours5To10.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age60To70.recidivism / facility.extraStudents.hours5To10.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age70To80.recidivism / facility.extraStudents.hours5To10.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age70To80.recidivism / facility.extraStudents.hours5To10.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.ages.age80To90.recidivism / facility.extraStudents.hours5To10.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.ages.age80To90.recidivism / facility.extraStudents.hours5To10.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>10 - 25 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.ageUnder22.recidivism / facility.extraStudents.hours10To25.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.ageUnder22.recidivism / facility.extraStudents.hours10To25.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age22To30.recidivism / facility.extraStudents.hours10To25.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age22To30.recidivism / facility.extraStudents.hours10To25.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age30To40.recidivism / facility.extraStudents.hours10To25.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age30To40.recidivism / facility.extraStudents.hours10To25.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age40To50.recidivism / facility.extraStudents.hours10To25.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age40To50.recidivism / facility.extraStudents.hours10To25.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age50To60.recidivism / facility.extraStudents.hours10To25.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age50To60.recidivism / facility.extraStudents.hours10To25.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age60To70.recidivism / facility.extraStudents.hours10To25.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age60To70.recidivism / facility.extraStudents.hours10To25.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age70To80.recidivism / facility.extraStudents.hours10To25.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age70To80.recidivism / facility.extraStudents.hours10To25.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.ages.age80To90.recidivism / facility.extraStudents.hours10To25.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.ages.age80To90.recidivism / facility.extraStudents.hours10To25.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>25 - 50 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.ageUnder22.recidivism / facility.extraStudents.hours25To50.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.ageUnder22.recidivism / facility.extraStudents.hours25To50.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age22To30.recidivism / facility.extraStudents.hours25To50.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age22To30.recidivism / facility.extraStudents.hours25To50.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age30To40.recidivism / facility.extraStudents.hours25To50.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age30To40.recidivism / facility.extraStudents.hours25To50.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age40To50.recidivism / facility.extraStudents.hours25To50.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age40To50.recidivism / facility.extraStudents.hours25To50.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age50To60.recidivism / facility.extraStudents.hours25To50.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age50To60.recidivism / facility.extraStudents.hours25To50.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age60To70.recidivism / facility.extraStudents.hours25To50.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age60To70.recidivism / facility.extraStudents.hours25To50.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age70To80.recidivism / facility.extraStudents.hours25To50.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age70To80.recidivism / facility.extraStudents.hours25To50.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.ages.age80To90.recidivism / facility.extraStudents.hours25To50.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.ages.age80To90.recidivism / facility.extraStudents.hours25To50.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 100 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.ageUnder22.recidivism / facility.extraStudents.hours50To100.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.ageUnder22.recidivism / facility.extraStudents.hours50To100.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age22To30.recidivism / facility.extraStudents.hours50To100.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age22To30.recidivism / facility.extraStudents.hours50To100.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age30To40.recidivism / facility.extraStudents.hours50To100.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age30To40.recidivism / facility.extraStudents.hours50To100.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age40To50.recidivism / facility.extraStudents.hours50To100.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age40To50.recidivism / facility.extraStudents.hours50To100.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age50To60.recidivism / facility.extraStudents.hours50To100.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age50To60.recidivism / facility.extraStudents.hours50To100.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age60To70.recidivism / facility.extraStudents.hours50To100.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age60To70.recidivism / facility.extraStudents.hours50To100.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age70To80.recidivism / facility.extraStudents.hours50To100.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age70To80.recidivism / facility.extraStudents.hours50To100.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.ages.age80To90.recidivism / facility.extraStudents.hours50To100.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.ages.age80To90.recidivism / facility.extraStudents.hours50To100.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>100 - 250 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.ageUnder22.recidivism / facility.extraStudents.hours100To250.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.ageUnder22.recidivism / facility.extraStudents.hours100To250.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age22To30.recidivism / facility.extraStudents.hours100To250.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age22To30.recidivism / facility.extraStudents.hours100To250.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age30To40.recidivism / facility.extraStudents.hours100To250.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age30To40.recidivism / facility.extraStudents.hours100To250.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age40To50.recidivism / facility.extraStudents.hours100To250.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age40To50.recidivism / facility.extraStudents.hours100To250.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age50To60.recidivism / facility.extraStudents.hours100To250.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age50To60.recidivism / facility.extraStudents.hours100To250.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age60To70.recidivism / facility.extraStudents.hours100To250.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age60To70.recidivism / facility.extraStudents.hours100To250.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age70To80.recidivism / facility.extraStudents.hours100To250.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age70To80.recidivism / facility.extraStudents.hours100To250.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.ages.age80To90.recidivism / facility.extraStudents.hours100To250.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.ages.age80To90.recidivism / facility.extraStudents.hours100To250.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>250 - 500 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.ageUnder22.recidivism / facility.extraStudents.hours250To500.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.ageUnder22.recidivism / facility.extraStudents.hours250To500.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age22To30.recidivism / facility.extraStudents.hours250To500.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age22To30.recidivism / facility.extraStudents.hours250To500.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age30To40.recidivism / facility.extraStudents.hours250To500.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age30To40.recidivism / facility.extraStudents.hours250To500.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age40To50.recidivism / facility.extraStudents.hours250To500.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age40To50.recidivism / facility.extraStudents.hours250To500.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age50To60.recidivism / facility.extraStudents.hours250To500.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age50To60.recidivism / facility.extraStudents.hours250To500.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age60To70.recidivism / facility.extraStudents.hours250To500.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age60To70.recidivism / facility.extraStudents.hours250To500.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age70To80.recidivism / facility.extraStudents.hours250To500.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age70To80.recidivism / facility.extraStudents.hours250To500.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.ages.age80To90.recidivism / facility.extraStudents.hours250To500.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.ages.age80To90.recidivism / facility.extraStudents.hours250To500.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Over 500 Hours</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.ageUnder22.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.ageUnder22.recidivism / facility.extraStudents.hoursOver500.ages.ageUnder22.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.ageUnder22.recidivism / facility.extraStudents.hoursOver500.ages.ageUnder22.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.ageUnder22.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.ageUnder22.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age22To30.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age22To30.recidivism / facility.extraStudents.hoursOver500.ages.age22To30.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age22To30.recidivism / facility.extraStudents.hoursOver500.ages.age22To30.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age22To30.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age22To30.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age30To40.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age30To40.recidivism / facility.extraStudents.hoursOver500.ages.age30To40.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age30To40.recidivism / facility.extraStudents.hoursOver500.ages.age30To40.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age30To40.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age30To40.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age40To50.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age40To50.recidivism / facility.extraStudents.hoursOver500.ages.age40To50.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age40To50.recidivism / facility.extraStudents.hoursOver500.ages.age40To50.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age40To50.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age40To50.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age50To60.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age50To60.recidivism / facility.extraStudents.hoursOver500.ages.age50To60.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age50To60.recidivism / facility.extraStudents.hoursOver500.ages.age50To60.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age50To60.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age50To60.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age60To70.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age60To70.recidivism / facility.extraStudents.hoursOver500.ages.age60To70.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age60To70.recidivism / facility.extraStudents.hoursOver500.ages.age60To70.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age60To70.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age60To70.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age70To80.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age70To80.recidivism / facility.extraStudents.hoursOver500.ages.age70To80.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age70To80.recidivism / facility.extraStudents.hoursOver500.ages.age70To80.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age70To80.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age70To80.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age80To90.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.ages.age80To90.recidivism / facility.extraStudents.hoursOver500.ages.age80To90.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.ages.age80To90.recidivism / facility.extraStudents.hoursOver500.ages.age80To90.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.ages.age80To90.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.ages.age80To90.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Education Attained and Length of Stay</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>None</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.levelNone.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.levelNone.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.length3To6M.recidivism / facility.extraStudents.levelNone.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.length3To6M.recidivism / facility.extraStudents.levelNone.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.length6To12M.recidivism / facility.extraStudents.levelNone.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.length6To12M.recidivism / facility.extraStudents.levelNone.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.length1To2Y.recidivism / facility.extraStudents.levelNone.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.length1To2Y.recidivism / facility.extraStudents.levelNone.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.length2To5Y.recidivism / facility.extraStudents.levelNone.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.length2To5Y.recidivism / facility.extraStudents.levelNone.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.stayLengths.length5To10Y.recidivism / facility.extraStudents.levelNone.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.stayLengths.length5To10Y.recidivism / facility.extraStudents.levelNone.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Primary</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.levelPrimary.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.levelPrimary.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length3To6M.recidivism / facility.extraStudents.levelPrimary.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length3To6M.recidivism / facility.extraStudents.levelPrimary.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length6To12M.recidivism / facility.extraStudents.levelPrimary.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length6To12M.recidivism / facility.extraStudents.levelPrimary.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length1To2Y.recidivism / facility.extraStudents.levelPrimary.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length1To2Y.recidivism / facility.extraStudents.levelPrimary.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length2To5Y.recidivism / facility.extraStudents.levelPrimary.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length2To5Y.recidivism / facility.extraStudents.levelPrimary.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length5To10Y.recidivism / facility.extraStudents.levelPrimary.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.stayLengths.length5To10Y.recidivism / facility.extraStudents.levelPrimary.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Secondary</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.levelSecondary.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.levelSecondary.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length3To6M.recidivism / facility.extraStudents.levelSecondary.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length3To6M.recidivism / facility.extraStudents.levelSecondary.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length6To12M.recidivism / facility.extraStudents.levelSecondary.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length6To12M.recidivism / facility.extraStudents.levelSecondary.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length1To2Y.recidivism / facility.extraStudents.levelSecondary.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length1To2Y.recidivism / facility.extraStudents.levelSecondary.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length2To5Y.recidivism / facility.extraStudents.levelSecondary.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length2To5Y.recidivism / facility.extraStudents.levelSecondary.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length5To10Y.recidivism / facility.extraStudents.levelSecondary.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.stayLengths.length5To10Y.recidivism / facility.extraStudents.levelSecondary.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Post Secondary</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.levelPost.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.levelPost.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.length3To6M.recidivism / facility.extraStudents.levelPost.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.length3To6M.recidivism / facility.extraStudents.levelPost.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.length6To12M.recidivism / facility.extraStudents.levelPost.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.length6To12M.recidivism / facility.extraStudents.levelPost.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.length1To2Y.recidivism / facility.extraStudents.levelPost.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.length1To2Y.recidivism / facility.extraStudents.levelPost.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.length2To5Y.recidivism / facility.extraStudents.levelPost.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.length2To5Y.recidivism / facility.extraStudents.levelPost.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.stayLengths.length5To10Y.recidivism / facility.extraStudents.levelPost.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.stayLengths.length5To10Y.recidivism / facility.extraStudents.levelPost.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Program Participation and Length of Stay</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Academics</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.academics.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.academics.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.academics.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.length3To6M.recidivism / facility.extraStudents.academics.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.length3To6M.recidivism / facility.extraStudents.academics.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.academics.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.length6To12M.recidivism / facility.extraStudents.academics.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.length6To12M.recidivism / facility.extraStudents.academics.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.academics.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.length1To2Y.recidivism / facility.extraStudents.academics.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.length1To2Y.recidivism / facility.extraStudents.academics.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.academics.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.length2To5Y.recidivism / facility.extraStudents.academics.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.length2To5Y.recidivism / facility.extraStudents.academics.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.academics.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.academics.stayLengths.length5To10Y.recidivism / facility.extraStudents.academics.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.stayLengths.length5To10Y.recidivism / facility.extraStudents.academics.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.academics.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Vocational</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.vocational.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.vocational.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.vocational.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.length3To6M.recidivism / facility.extraStudents.vocational.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.length3To6M.recidivism / facility.extraStudents.vocational.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.vocational.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.length6To12M.recidivism / facility.extraStudents.vocational.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.length6To12M.recidivism / facility.extraStudents.vocational.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.vocational.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.length1To2Y.recidivism / facility.extraStudents.vocational.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.length1To2Y.recidivism / facility.extraStudents.vocational.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.vocational.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.length2To5Y.recidivism / facility.extraStudents.vocational.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.length2To5Y.recidivism / facility.extraStudents.vocational.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.vocational.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.stayLengths.length5To10Y.recidivism / facility.extraStudents.vocational.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.stayLengths.length5To10Y.recidivism / facility.extraStudents.vocational.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.vocational.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Self Development</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.selfDevelopment.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.selfDevelopment.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length3To6M.recidivism / facility.extraStudents.selfDevelopment.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length3To6M.recidivism / facility.extraStudents.selfDevelopment.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length6To12M.recidivism / facility.extraStudents.selfDevelopment.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length6To12M.recidivism / facility.extraStudents.selfDevelopment.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length1To2Y.recidivism / facility.extraStudents.selfDevelopment.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length1To2Y.recidivism / facility.extraStudents.selfDevelopment.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length2To5Y.recidivism / facility.extraStudents.selfDevelopment.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length2To5Y.recidivism / facility.extraStudents.selfDevelopment.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length5To10Y.recidivism / facility.extraStudents.selfDevelopment.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.stayLengths.length5To10Y.recidivism / facility.extraStudents.selfDevelopment.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Social Studies</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.socialStudies.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.socialStudies.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.length3To6M.recidivism / facility.extraStudents.socialStudies.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.length3To6M.recidivism / facility.extraStudents.socialStudies.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.length6To12M.recidivism / facility.extraStudents.socialStudies.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.length6To12M.recidivism / facility.extraStudents.socialStudies.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.length1To2Y.recidivism / facility.extraStudents.socialStudies.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.length1To2Y.recidivism / facility.extraStudents.socialStudies.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.length2To5Y.recidivism / facility.extraStudents.socialStudies.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.length2To5Y.recidivism / facility.extraStudents.socialStudies.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.stayLengths.length5To10Y.recidivism / facility.extraStudents.socialStudies.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.stayLengths.length5To10Y.recidivism / facility.extraStudents.socialStudies.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Miscellaneous</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.misc.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.misc.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length3To6M.recidivism / facility.extraStudents.misc.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length3To6M.recidivism / facility.extraStudents.misc.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length6To12M.recidivism / facility.extraStudents.misc.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length6To12M.recidivism / facility.extraStudents.misc.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length1To2Y.recidivism / facility.extraStudents.misc.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length1To2Y.recidivism / facility.extraStudents.misc.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length2To5Y.recidivism / facility.extraStudents.misc.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length2To5Y.recidivism / facility.extraStudents.misc.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length5To10Y.recidivism / facility.extraStudents.misc.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length5To10Y.recidivism / facility.extraStudents.misc.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              <div style={{flexBasis:`95%`}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Class Hours and Length of Stay</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 5 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours1To5.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours1To5.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.length3To6M.recidivism / facility.extraStudents.hours1To5.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.length3To6M.recidivism / facility.extraStudents.hours1To5.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.length6To12M.recidivism / facility.extraStudents.hours1To5.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.length6To12M.recidivism / facility.extraStudents.hours1To5.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours1To5.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours1To5.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours1To5.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours1To5.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours1To5.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours1To5.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours5To10.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours5To10.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.length3To6M.recidivism / facility.extraStudents.hours5To10.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.length3To6M.recidivism / facility.extraStudents.hours5To10.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.length6To12M.recidivism / facility.extraStudents.hours5To10.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.length6To12M.recidivism / facility.extraStudents.hours5To10.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours5To10.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours5To10.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours5To10.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours5To10.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours5To10.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours5To10.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>10 - 25 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours10To25.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours10To25.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.length3To6M.recidivism / facility.extraStudents.hours10To25.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.length3To6M.recidivism / facility.extraStudents.hours10To25.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.length6To12M.recidivism / facility.extraStudents.hours10To25.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.length6To12M.recidivism / facility.extraStudents.hours10To25.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours10To25.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours10To25.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours10To25.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours10To25.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours10To25.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours10To25.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>25 - 50 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours25To50.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours25To50.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.length3To6M.recidivism / facility.extraStudents.hours25To50.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.length3To6M.recidivism / facility.extraStudents.hours25To50.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.length6To12M.recidivism / facility.extraStudents.hours25To50.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.length6To12M.recidivism / facility.extraStudents.hours25To50.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours25To50.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours25To50.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours25To50.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours25To50.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours25To50.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours25To50.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 100 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours50To100.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours50To100.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.length3To6M.recidivism / facility.extraStudents.hours50To100.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.length3To6M.recidivism / facility.extraStudents.hours50To100.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.length6To12M.recidivism / facility.extraStudents.hours50To100.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.length6To12M.recidivism / facility.extraStudents.hours50To100.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours50To100.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours50To100.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours50To100.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours50To100.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours50To100.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours50To100.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>100 - 250 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours100To250.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours100To250.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.length3To6M.recidivism / facility.extraStudents.hours100To250.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.length3To6M.recidivism / facility.extraStudents.hours100To250.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.length6To12M.recidivism / facility.extraStudents.hours100To250.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.length6To12M.recidivism / facility.extraStudents.hours100To250.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours100To250.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours100To250.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours100To250.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours100To250.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours100To250.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours100To250.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>250 - 500 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours250To500.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.hours250To500.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.length3To6M.recidivism / facility.extraStudents.hours250To500.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.length3To6M.recidivism / facility.extraStudents.hours250To500.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.length6To12M.recidivism / facility.extraStudents.hours250To500.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.length6To12M.recidivism / facility.extraStudents.hours250To500.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours250To500.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.length1To2Y.recidivism / facility.extraStudents.hours250To500.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours250To500.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.length2To5Y.recidivism / facility.extraStudents.hours250To500.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours250To500.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.stayLengths.length5To10Y.recidivism / facility.extraStudents.hours250To500.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Over 500 Hours</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.misc.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism / facility.extraStudents.misc.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length3To6M.recidivism / facility.extraStudents.misc.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length3To6M.recidivism / facility.extraStudents.misc.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length6To12M.recidivism / facility.extraStudents.misc.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length6To12M.recidivism / facility.extraStudents.misc.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length1To2Y.recidivism / facility.extraStudents.misc.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length1To2Y.recidivism / facility.extraStudents.misc.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length2To5Y.recidivism / facility.extraStudents.misc.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length2To5Y.recidivism / facility.extraStudents.misc.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.stayLengths.length5To10Y.recidivism / facility.extraStudents.misc.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.stayLengths.length5To10Y.recidivism / facility.extraStudents.misc.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Education Attained and Length Between Returns</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>None</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.lengthUnder3M.recidivism / facility.extraStudents.levelNone.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.lengthUnder3M.recidivism / facility.extraStudents.levelNone.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.length3To6M.recidivism / facility.extraStudents.levelNone.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.length3To6M.recidivism / facility.extraStudents.levelNone.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.length6To12M.recidivism / facility.extraStudents.levelNone.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.length6To12M.recidivism / facility.extraStudents.levelNone.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.length1To2Y.recidivism / facility.extraStudents.levelNone.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.length1To2Y.recidivism / facility.extraStudents.levelNone.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.length2To5Y.recidivism / facility.extraStudents.levelNone.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.length2To5Y.recidivism / facility.extraStudents.levelNone.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.outLengths.length5To10Y.recidivism / facility.extraStudents.levelNone.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.outLengths.length5To10Y.recidivism / facility.extraStudents.levelNone.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Primary</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.lengthUnder3M.recidivism / facility.extraStudents.levelPrimary.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.lengthUnder3M.recidivism / facility.extraStudents.levelPrimary.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.length3To6M.recidivism / facility.extraStudents.levelPrimary.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.length3To6M.recidivism / facility.extraStudents.levelPrimary.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.length6To12M.recidivism / facility.extraStudents.levelPrimary.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.length6To12M.recidivism / facility.extraStudents.levelPrimary.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.length1To2Y.recidivism / facility.extraStudents.levelPrimary.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.length1To2Y.recidivism / facility.extraStudents.levelPrimary.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.length2To5Y.recidivism / facility.extraStudents.levelPrimary.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.length2To5Y.recidivism / facility.extraStudents.levelPrimary.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.outLengths.length5To10Y.recidivism / facility.extraStudents.levelPrimary.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.outLengths.length5To10Y.recidivism / facility.extraStudents.levelPrimary.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Secondary</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.lengthUnder3M.recidivism / facility.extraStudents.levelSecondary.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.lengthUnder3M.recidivism / facility.extraStudents.levelSecondary.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.length3To6M.recidivism / facility.extraStudents.levelSecondary.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.length3To6M.recidivism / facility.extraStudents.levelSecondary.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.length6To12M.recidivism / facility.extraStudents.levelSecondary.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.length6To12M.recidivism / facility.extraStudents.levelSecondary.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.length1To2Y.recidivism / facility.extraStudents.levelSecondary.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.length1To2Y.recidivism / facility.extraStudents.levelSecondary.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.length2To5Y.recidivism / facility.extraStudents.levelSecondary.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.length2To5Y.recidivism / facility.extraStudents.levelSecondary.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.outLengths.length5To10Y.recidivism / facility.extraStudents.levelSecondary.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.outLengths.length5To10Y.recidivism / facility.extraStudents.levelSecondary.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Post Secondary</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.lengthUnder3M.recidivism / facility.extraStudents.levelPost.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.lengthUnder3M.recidivism / facility.extraStudents.levelPost.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.length3To6M.recidivism / facility.extraStudents.levelPost.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.length3To6M.recidivism / facility.extraStudents.levelPost.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.length6To12M.recidivism / facility.extraStudents.levelPost.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.length6To12M.recidivism / facility.extraStudents.levelPost.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.length1To2Y.recidivism / facility.extraStudents.levelPost.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.length1To2Y.recidivism / facility.extraStudents.levelPost.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.length2To5Y.recidivism / facility.extraStudents.levelPost.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.length2To5Y.recidivism / facility.extraStudents.levelPost.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.outLengths.length5To10Y.recidivism / facility.extraStudents.levelPost.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.outLengths.length5To10Y.recidivism / facility.extraStudents.levelPost.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Program Participation and Length Between Returns</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Academics</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.lengthUnder3M.recidivism / facility.extraStudents.academics.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.lengthUnder3M.recidivism / facility.extraStudents.academics.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.academics.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.length3To6M.recidivism / facility.extraStudents.academics.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.length3To6M.recidivism / facility.extraStudents.academics.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.academics.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.length6To12M.recidivism / facility.extraStudents.academics.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.length6To12M.recidivism / facility.extraStudents.academics.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.academics.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.length1To2Y.recidivism / facility.extraStudents.academics.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.length1To2Y.recidivism / facility.extraStudents.academics.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.academics.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.length2To5Y.recidivism / facility.extraStudents.academics.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.length2To5Y.recidivism / facility.extraStudents.academics.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.academics.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.academics.outLengths.length5To10Y.recidivism / facility.extraStudents.academics.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.outLengths.length5To10Y.recidivism / facility.extraStudents.academics.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.academics.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Vocational</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.lengthUnder3M.recidivism / facility.extraStudents.vocational.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.lengthUnder3M.recidivism / facility.extraStudents.vocational.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.vocational.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.length3To6M.recidivism / facility.extraStudents.vocational.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.length3To6M.recidivism / facility.extraStudents.vocational.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.vocational.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.length6To12M.recidivism / facility.extraStudents.vocational.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.length6To12M.recidivism / facility.extraStudents.vocational.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.vocational.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.length1To2Y.recidivism / facility.extraStudents.vocational.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.length1To2Y.recidivism / facility.extraStudents.vocational.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.vocational.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.length2To5Y.recidivism / facility.extraStudents.vocational.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.length2To5Y.recidivism / facility.extraStudents.vocational.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.vocational.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.outLengths.length5To10Y.recidivism / facility.extraStudents.vocational.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.outLengths.length5To10Y.recidivism / facility.extraStudents.vocational.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.vocational.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Self Development</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.lengthUnder3M.recidivism / facility.extraStudents.selfDevelopment.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.lengthUnder3M.recidivism / facility.extraStudents.selfDevelopment.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length3To6M.recidivism / facility.extraStudents.selfDevelopment.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length3To6M.recidivism / facility.extraStudents.selfDevelopment.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length6To12M.recidivism / facility.extraStudents.selfDevelopment.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length6To12M.recidivism / facility.extraStudents.selfDevelopment.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length1To2Y.recidivism / facility.extraStudents.selfDevelopment.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length1To2Y.recidivism / facility.extraStudents.selfDevelopment.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length2To5Y.recidivism / facility.extraStudents.selfDevelopment.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length2To5Y.recidivism / facility.extraStudents.selfDevelopment.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length5To10Y.recidivism / facility.extraStudents.selfDevelopment.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.outLengths.length5To10Y.recidivism / facility.extraStudents.selfDevelopment.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Social Studies</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.lengthUnder3M.recidivism / facility.extraStudents.socialStudies.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.lengthUnder3M.recidivism / facility.extraStudents.socialStudies.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.length3To6M.recidivism / facility.extraStudents.socialStudies.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.length3To6M.recidivism / facility.extraStudents.socialStudies.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.length6To12M.recidivism / facility.extraStudents.socialStudies.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.length6To12M.recidivism / facility.extraStudents.socialStudies.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.length1To2Y.recidivism / facility.extraStudents.socialStudies.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.length1To2Y.recidivism / facility.extraStudents.socialStudies.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.length2To5Y.recidivism / facility.extraStudents.socialStudies.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.length2To5Y.recidivism / facility.extraStudents.socialStudies.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.outLengths.length5To10Y.recidivism / facility.extraStudents.socialStudies.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.outLengths.length5To10Y.recidivism / facility.extraStudents.socialStudies.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Miscellaneous</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism / facility.extraStudents.misc.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism / facility.extraStudents.misc.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length3To6M.recidivism / facility.extraStudents.misc.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length3To6M.recidivism / facility.extraStudents.misc.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length6To12M.recidivism / facility.extraStudents.misc.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length6To12M.recidivism / facility.extraStudents.misc.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length1To2Y.recidivism / facility.extraStudents.misc.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length1To2Y.recidivism / facility.extraStudents.misc.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length2To5Y.recidivism / facility.extraStudents.misc.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length2To5Y.recidivism / facility.extraStudents.misc.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length5To10Y.recidivism / facility.extraStudents.misc.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length5To10Y.recidivism / facility.extraStudents.misc.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              <div style={{flexBasis:`95%`}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Class Hours and Length Between Returns</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 5 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours1To5.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours1To5.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.length3To6M.recidivism / facility.extraStudents.hours1To5.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.length3To6M.recidivism / facility.extraStudents.hours1To5.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.length6To12M.recidivism / facility.extraStudents.hours1To5.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.length6To12M.recidivism / facility.extraStudents.hours1To5.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.length1To2Y.recidivism / facility.extraStudents.hours1To5.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.length1To2Y.recidivism / facility.extraStudents.hours1To5.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.length2To5Y.recidivism / facility.extraStudents.hours1To5.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.length2To5Y.recidivism / facility.extraStudents.hours1To5.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.outLengths.length5To10Y.recidivism / facility.extraStudents.hours1To5.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.outLengths.length5To10Y.recidivism / facility.extraStudents.hours1To5.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours5To10.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours5To10.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.length3To6M.recidivism / facility.extraStudents.hours5To10.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.length3To6M.recidivism / facility.extraStudents.hours5To10.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.length6To12M.recidivism / facility.extraStudents.hours5To10.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.length6To12M.recidivism / facility.extraStudents.hours5To10.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.length1To2Y.recidivism / facility.extraStudents.hours5To10.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.length1To2Y.recidivism / facility.extraStudents.hours5To10.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.length2To5Y.recidivism / facility.extraStudents.hours5To10.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.length2To5Y.recidivism / facility.extraStudents.hours5To10.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.outLengths.length5To10Y.recidivism / facility.extraStudents.hours5To10.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.outLengths.length5To10Y.recidivism / facility.extraStudents.hours5To10.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>10 - 25 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours10To25.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours10To25.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.length3To6M.recidivism / facility.extraStudents.hours10To25.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.length3To6M.recidivism / facility.extraStudents.hours10To25.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.length6To12M.recidivism / facility.extraStudents.hours10To25.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.length6To12M.recidivism / facility.extraStudents.hours10To25.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.length1To2Y.recidivism / facility.extraStudents.hours10To25.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.length1To2Y.recidivism / facility.extraStudents.hours10To25.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.length2To5Y.recidivism / facility.extraStudents.hours10To25.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.length2To5Y.recidivism / facility.extraStudents.hours10To25.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.outLengths.length5To10Y.recidivism / facility.extraStudents.hours10To25.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.outLengths.length5To10Y.recidivism / facility.extraStudents.hours10To25.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>25 - 50 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours25To50.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours25To50.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.length3To6M.recidivism / facility.extraStudents.hours25To50.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.length3To6M.recidivism / facility.extraStudents.hours25To50.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.length6To12M.recidivism / facility.extraStudents.hours25To50.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.length6To12M.recidivism / facility.extraStudents.hours25To50.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.length1To2Y.recidivism / facility.extraStudents.hours25To50.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.length1To2Y.recidivism / facility.extraStudents.hours25To50.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.length2To5Y.recidivism / facility.extraStudents.hours25To50.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.length2To5Y.recidivism / facility.extraStudents.hours25To50.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.outLengths.length5To10Y.recidivism / facility.extraStudents.hours25To50.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.outLengths.length5To10Y.recidivism / facility.extraStudents.hours25To50.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 100 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours50To100.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours50To100.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.length3To6M.recidivism / facility.extraStudents.hours50To100.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.length3To6M.recidivism / facility.extraStudents.hours50To100.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.length6To12M.recidivism / facility.extraStudents.hours50To100.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.length6To12M.recidivism / facility.extraStudents.hours50To100.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.length1To2Y.recidivism / facility.extraStudents.hours50To100.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.length1To2Y.recidivism / facility.extraStudents.hours50To100.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.length2To5Y.recidivism / facility.extraStudents.hours50To100.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.length2To5Y.recidivism / facility.extraStudents.hours50To100.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.outLengths.length5To10Y.recidivism / facility.extraStudents.hours50To100.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.outLengths.length5To10Y.recidivism / facility.extraStudents.hours50To100.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>100 - 250 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours100To250.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours100To250.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.length3To6M.recidivism / facility.extraStudents.hours100To250.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.length3To6M.recidivism / facility.extraStudents.hours100To250.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.length6To12M.recidivism / facility.extraStudents.hours100To250.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.length6To12M.recidivism / facility.extraStudents.hours100To250.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.length1To2Y.recidivism / facility.extraStudents.hours100To250.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.length1To2Y.recidivism / facility.extraStudents.hours100To250.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.length2To5Y.recidivism / facility.extraStudents.hours100To250.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.length2To5Y.recidivism / facility.extraStudents.hours100To250.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.outLengths.length5To10Y.recidivism / facility.extraStudents.hours100To250.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.outLengths.length5To10Y.recidivism / facility.extraStudents.hours100To250.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>250 - 500 Hours</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours250To500.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.lengthUnder3M.recidivism / facility.extraStudents.hours250To500.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.length3To6M.recidivism / facility.extraStudents.hours250To500.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.length3To6M.recidivism / facility.extraStudents.hours250To500.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.length6To12M.recidivism / facility.extraStudents.hours250To500.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.length6To12M.recidivism / facility.extraStudents.hours250To500.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.length1To2Y.recidivism / facility.extraStudents.hours250To500.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.length1To2Y.recidivism / facility.extraStudents.hours250To500.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.length2To5Y.recidivism / facility.extraStudents.hours250To500.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.length2To5Y.recidivism / facility.extraStudents.hours250To500.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.outLengths.length5To10Y.recidivism / facility.extraStudents.hours250To500.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.outLengths.length5To10Y.recidivism / facility.extraStudents.hours250To500.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Over 500 Hours</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism / facility.extraStudents.misc.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism / facility.extraStudents.misc.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length3To6M.recidivism / facility.extraStudents.misc.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length3To6M.recidivism / facility.extraStudents.misc.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length6To12M.recidivism / facility.extraStudents.misc.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length6To12M.recidivism / facility.extraStudents.misc.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length1To2Y.recidivism / facility.extraStudents.misc.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length1To2Y.recidivism / facility.extraStudents.misc.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length2To5Y.recidivism / facility.extraStudents.misc.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length2To5Y.recidivism / facility.extraStudents.misc.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraStudents.misc.outLengths.length5To10Y.recidivism / facility.extraStudents.misc.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.outLengths.length5To10Y.recidivism / facility.extraStudents.misc.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraStudents.misc.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={30} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Education Attained and Number of Returns</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>None</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Primary</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Secondary</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Post Secondary</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.one.recidivism / facility.extraStudents.levelNone.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.one.recidivism / facility.extraStudents.levelNone.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.one.recidivism / facility.extraStudents.levelPrimary.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.one.recidivism / facility.extraStudents.levelPrimary.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.one.recidivism / facility.extraStudents.levelSecondary.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.one.recidivism / facility.extraStudents.levelSecondary.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.one.recidivism / facility.extraStudents.levelPost.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.one.recidivism / facility.extraStudents.levelPost.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.one.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.two.recidivism / facility.extraStudents.levelNone.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.two.recidivism / facility.extraStudents.levelNone.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.two.recidivism / facility.extraStudents.levelPrimary.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.two.recidivism / facility.extraStudents.levelPrimary.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.two.recidivism / facility.extraStudents.levelSecondary.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.two.recidivism / facility.extraStudents.levelSecondary.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.two.recidivism / facility.extraStudents.levelPost.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.two.recidivism / facility.extraStudents.levelPost.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.two.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.three.recidivism / facility.extraStudents.levelNone.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.three.recidivism / facility.extraStudents.levelNone.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.three.recidivism / facility.extraStudents.levelPrimary.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.three.recidivism / facility.extraStudents.levelPrimary.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.three.recidivism / facility.extraStudents.levelSecondary.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.three.recidivism / facility.extraStudents.levelSecondary.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.three.recidivism / facility.extraStudents.levelPost.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.three.recidivism / facility.extraStudents.levelPost.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.three.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.four.recidivism / facility.extraStudents.levelNone.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.four.recidivism / facility.extraStudents.levelNone.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.four.recidivism / facility.extraStudents.levelPrimary.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.four.recidivism / facility.extraStudents.levelPrimary.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.four.recidivism / facility.extraStudents.levelSecondary.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.four.recidivism / facility.extraStudents.levelSecondary.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.four.recidivism / facility.extraStudents.levelPost.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.four.recidivism / facility.extraStudents.levelPost.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.four.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.five.recidivism / facility.extraStudents.levelNone.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.five.recidivism / facility.extraStudents.levelNone.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.five.recidivism / facility.extraStudents.levelPrimary.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.five.recidivism / facility.extraStudents.levelPrimary.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.five.recidivism / facility.extraStudents.levelSecondary.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.five.recidivism / facility.extraStudents.levelSecondary.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.five.recidivism / facility.extraStudents.levelPost.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.five.recidivism / facility.extraStudents.levelPost.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.five.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.six.recidivism / facility.extraStudents.levelNone.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.six.recidivism / facility.extraStudents.levelNone.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.six.recidivism / facility.extraStudents.levelPrimary.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.six.recidivism / facility.extraStudents.levelPrimary.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.six.recidivism / facility.extraStudents.levelSecondary.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.six.recidivism / facility.extraStudents.levelSecondary.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.six.recidivism / facility.extraStudents.levelPost.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.six.recidivism / facility.extraStudents.levelPost.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.six.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.seven.recidivism / facility.extraStudents.levelNone.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.seven.recidivism / facility.extraStudents.levelNone.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.seven.recidivism / facility.extraStudents.levelPrimary.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.seven.recidivism / facility.extraStudents.levelPrimary.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.seven.recidivism / facility.extraStudents.levelSecondary.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.seven.recidivism / facility.extraStudents.levelSecondary.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.seven.recidivism / facility.extraStudents.levelPost.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.seven.recidivism / facility.extraStudents.levelPost.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.eight.recidivism / facility.extraStudents.levelNone.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.eight.recidivism / facility.extraStudents.levelNone.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.eight.recidivism / facility.extraStudents.levelPrimary.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.eight.recidivism / facility.extraStudents.levelPrimary.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.eight.recidivism / facility.extraStudents.levelSecondary.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.eight.recidivism / facility.extraStudents.levelSecondary.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.eight.recidivism / facility.extraStudents.levelPost.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.eight.recidivism / facility.extraStudents.levelPost.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.nine.recidivism / facility.extraStudents.levelNone.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.nine.recidivism / facility.extraStudents.levelNone.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.nine.recidivism / facility.extraStudents.levelPrimary.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.nine.recidivism / facility.extraStudents.levelPrimary.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.nine.recidivism / facility.extraStudents.levelSecondary.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.nine.recidivism / facility.extraStudents.levelSecondary.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.nine.recidivism / facility.extraStudents.levelPost.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.nine.recidivism / facility.extraStudents.levelPost.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.ten.recidivism / facility.extraStudents.levelNone.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.ten.recidivism / facility.extraStudents.levelNone.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.ten.recidivism / facility.extraStudents.levelPrimary.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.ten.recidivism / facility.extraStudents.levelPrimary.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.ten.recidivism / facility.extraStudents.levelSecondary.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.ten.recidivism / facility.extraStudents.levelSecondary.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.ten.recidivism / facility.extraStudents.levelPost.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.ten.recidivism / facility.extraStudents.levelPost.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.eleven.recidivism / facility.extraStudents.levelNone.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.eleven.recidivism / facility.extraStudents.levelNone.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.eleven.recidivism / facility.extraStudents.levelPrimary.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.eleven.recidivism / facility.extraStudents.levelPrimary.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.eleven.recidivism / facility.extraStudents.levelSecondary.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.eleven.recidivism / facility.extraStudents.levelSecondary.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.eleven.recidivism / facility.extraStudents.levelPost.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.eleven.recidivism / facility.extraStudents.levelPost.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.twelve.recidivism / facility.extraStudents.levelNone.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.twelve.recidivism / facility.extraStudents.levelNone.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.twelve.recidivism / facility.extraStudents.levelPrimary.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.twelve.recidivism / facility.extraStudents.levelPrimary.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.twelve.recidivism / facility.extraStudents.levelSecondary.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.twelve.recidivism / facility.extraStudents.levelSecondary.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.twelve.recidivism / facility.extraStudents.levelPost.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.twelve.recidivism / facility.extraStudents.levelPost.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.thirteen.recidivism / facility.extraStudents.levelNone.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.thirteen.recidivism / facility.extraStudents.levelNone.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.thirteen.recidivism / facility.extraStudents.levelPrimary.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.thirteen.recidivism / facility.extraStudents.levelPrimary.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.thirteen.recidivism / facility.extraStudents.levelSecondary.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.thirteen.recidivism / facility.extraStudents.levelSecondary.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.thirteen.recidivism / facility.extraStudents.levelPost.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.thirteen.recidivism / facility.extraStudents.levelPost.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.fourteen.recidivism / facility.extraStudents.levelNone.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.fourteen.recidivism / facility.extraStudents.levelNone.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.fourteen.recidivism / facility.extraStudents.levelPrimary.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.fourteen.recidivism / facility.extraStudents.levelPrimary.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.fourteen.recidivism / facility.extraStudents.levelSecondary.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.fourteen.recidivism / facility.extraStudents.levelSecondary.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.fourteen.recidivism / facility.extraStudents.levelPost.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.fourteen.recidivism / facility.extraStudents.levelPost.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraStudents.levelNone.returns.fifteenPlus.recidivism / facility.extraStudents.levelNone.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelNone.returns.fifteenPlus.recidivism / facility.extraStudents.levelNone.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelNone.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraStudents.levelNone.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraStudents.levelPrimary.returns.fifteenPlus.recidivism / facility.extraStudents.levelPrimary.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPrimary.returns.fifteenPlus.recidivism / facility.extraStudents.levelPrimary.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPrimary.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraStudents.levelPrimary.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraStudents.levelSecondary.returns.fifteenPlus.recidivism / facility.extraStudents.levelSecondary.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelSecondary.returns.fifteenPlus.recidivism / facility.extraStudents.levelSecondary.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelSecondary.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraStudents.levelSecondary.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraStudents.levelPost.returns.fifteenPlus.recidivism / facility.extraStudents.levelPost.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.levelPost.returns.fifteenPlus.recidivism / facility.extraStudents.levelPost.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.levelPost.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraStudents.levelPost.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              <div style={{flexBasis:'95%'}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={30} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Program Participation and Number of Returns</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Academics</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Vocational</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Self Development</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Social Studies</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Miscellaneous</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.one.recidivism / facility.extraStudents.academics.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.one.recidivism / facility.extraStudents.academics.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.one.recidivism / facility.extraStudents.vocational.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.one.recidivism / facility.extraStudents.vocational.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.one.recidivism / facility.extraStudents.selfDevelopment.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.one.recidivism / facility.extraStudents.selfDevelopment.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.one.recidivism / facility.extraStudents.socialStudies.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.one.recidivism / facility.extraStudents.socialStudies.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.one.recidivism / facility.extraStudents.misc.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.one.recidivism / facility.extraStudents.misc.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.one.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.two.recidivism / facility.extraStudents.academics.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.two.recidivism / facility.extraStudents.academics.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.two.recidivism / facility.extraStudents.vocational.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.two.recidivism / facility.extraStudents.vocational.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.two.recidivism / facility.extraStudents.selfDevelopment.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.two.recidivism / facility.extraStudents.selfDevelopment.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.two.recidivism / facility.extraStudents.socialStudies.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.two.recidivism / facility.extraStudents.socialStudies.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.two.recidivism / facility.extraStudents.misc.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.two.recidivism / facility.extraStudents.misc.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.two.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.three.recidivism / facility.extraStudents.academics.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.three.recidivism / facility.extraStudents.academics.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.three.recidivism / facility.extraStudents.vocational.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.three.recidivism / facility.extraStudents.vocational.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.three.recidivism / facility.extraStudents.selfDevelopment.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.three.recidivism / facility.extraStudents.selfDevelopment.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.three.recidivism / facility.extraStudents.socialStudies.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.three.recidivism / facility.extraStudents.socialStudies.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.three.recidivism / facility.extraStudents.misc.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.three.recidivism / facility.extraStudents.misc.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.three.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.four.recidivism / facility.extraStudents.academics.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.four.recidivism / facility.extraStudents.academics.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.four.recidivism / facility.extraStudents.vocational.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.four.recidivism / facility.extraStudents.vocational.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.four.recidivism / facility.extraStudents.selfDevelopment.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.four.recidivism / facility.extraStudents.selfDevelopment.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.four.recidivism / facility.extraStudents.socialStudies.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.four.recidivism / facility.extraStudents.socialStudies.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.four.recidivism / facility.extraStudents.misc.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.four.recidivism / facility.extraStudents.misc.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.four.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.five.recidivism / facility.extraStudents.academics.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.five.recidivism / facility.extraStudents.academics.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.five.recidivism / facility.extraStudents.vocational.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.five.recidivism / facility.extraStudents.vocational.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.five.recidivism / facility.extraStudents.selfDevelopment.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.five.recidivism / facility.extraStudents.selfDevelopment.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.five.recidivism / facility.extraStudents.socialStudies.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.five.recidivism / facility.extraStudents.socialStudies.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.five.recidivism / facility.extraStudents.misc.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.five.recidivism / facility.extraStudents.misc.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.five.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.six.recidivism / facility.extraStudents.academics.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.six.recidivism / facility.extraStudents.academics.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.six.recidivism / facility.extraStudents.vocational.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.six.recidivism / facility.extraStudents.vocational.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.six.recidivism / facility.extraStudents.selfDevelopment.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.six.recidivism / facility.extraStudents.selfDevelopment.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.six.recidivism / facility.extraStudents.socialStudies.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.six.recidivism / facility.extraStudents.socialStudies.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.six.recidivism / facility.extraStudents.misc.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.six.recidivism / facility.extraStudents.misc.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.six.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.seven.recidivism / facility.extraStudents.academics.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.seven.recidivism / facility.extraStudents.academics.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.seven.recidivism / facility.extraStudents.vocational.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.seven.recidivism / facility.extraStudents.vocational.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.seven.recidivism / facility.extraStudents.selfDevelopment.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.seven.recidivism / facility.extraStudents.selfDevelopment.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.seven.recidivism / facility.extraStudents.socialStudies.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.seven.recidivism / facility.extraStudents.socialStudies.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.seven.recidivism / facility.extraStudents.misc.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.seven.recidivism / facility.extraStudents.misc.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.eight.recidivism / facility.extraStudents.academics.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.eight.recidivism / facility.extraStudents.academics.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.eight.recidivism / facility.extraStudents.vocational.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.eight.recidivism / facility.extraStudents.vocational.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.eight.recidivism / facility.extraStudents.selfDevelopment.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.eight.recidivism / facility.extraStudents.selfDevelopment.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.eight.recidivism / facility.extraStudents.socialStudies.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.eight.recidivism / facility.extraStudents.socialStudies.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.eight.recidivism / facility.extraStudents.misc.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.eight.recidivism / facility.extraStudents.misc.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.nine.recidivism / facility.extraStudents.academics.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.nine.recidivism / facility.extraStudents.academics.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.nine.recidivism / facility.extraStudents.vocational.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.nine.recidivism / facility.extraStudents.vocational.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.nine.recidivism / facility.extraStudents.selfDevelopment.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.nine.recidivism / facility.extraStudents.selfDevelopment.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.nine.recidivism / facility.extraStudents.socialStudies.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.nine.recidivism / facility.extraStudents.socialStudies.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.nine.recidivism / facility.extraStudents.misc.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.nine.recidivism / facility.extraStudents.misc.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.ten.recidivism / facility.extraStudents.academics.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.ten.recidivism / facility.extraStudents.academics.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.ten.recidivism / facility.extraStudents.vocational.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.ten.recidivism / facility.extraStudents.vocational.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.ten.recidivism / facility.extraStudents.selfDevelopment.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.ten.recidivism / facility.extraStudents.selfDevelopment.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.ten.recidivism / facility.extraStudents.socialStudies.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.ten.recidivism / facility.extraStudents.socialStudies.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.ten.recidivism / facility.extraStudents.misc.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.ten.recidivism / facility.extraStudents.misc.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.eleven.recidivism / facility.extraStudents.academics.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.eleven.recidivism / facility.extraStudents.academics.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.eleven.recidivism / facility.extraStudents.vocational.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.eleven.recidivism / facility.extraStudents.vocational.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.eleven.recidivism / facility.extraStudents.selfDevelopment.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.eleven.recidivism / facility.extraStudents.selfDevelopment.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.eleven.recidivism / facility.extraStudents.socialStudies.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.eleven.recidivism / facility.extraStudents.socialStudies.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.eleven.recidivism / facility.extraStudents.misc.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.eleven.recidivism / facility.extraStudents.misc.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.twelve.recidivism / facility.extraStudents.academics.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.twelve.recidivism / facility.extraStudents.academics.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.twelve.recidivism / facility.extraStudents.vocational.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.twelve.recidivism / facility.extraStudents.vocational.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.twelve.recidivism / facility.extraStudents.selfDevelopment.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.twelve.recidivism / facility.extraStudents.selfDevelopment.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.twelve.recidivism / facility.extraStudents.socialStudies.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.twelve.recidivism / facility.extraStudents.socialStudies.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.twelve.recidivism / facility.extraStudents.misc.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.twelve.recidivism / facility.extraStudents.misc.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.thirteen.recidivism / facility.extraStudents.academics.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.thirteen.recidivism / facility.extraStudents.academics.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.thirteen.recidivism / facility.extraStudents.vocational.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.thirteen.recidivism / facility.extraStudents.vocational.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.thirteen.recidivism / facility.extraStudents.selfDevelopment.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.thirteen.recidivism / facility.extraStudents.selfDevelopment.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.thirteen.recidivism / facility.extraStudents.socialStudies.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.thirteen.recidivism / facility.extraStudents.socialStudies.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.thirteen.recidivism / facility.extraStudents.misc.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.thirteen.recidivism / facility.extraStudents.misc.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.fourteen.recidivism / facility.extraStudents.academics.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.fourteen.recidivism / facility.extraStudents.academics.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.fourteen.recidivism / facility.extraStudents.vocational.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.fourteen.recidivism / facility.extraStudents.vocational.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.fourteen.recidivism / facility.extraStudents.selfDevelopment.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.fourteen.recidivism / facility.extraStudents.selfDevelopment.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.fourteen.recidivism / facility.extraStudents.socialStudies.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.fourteen.recidivism / facility.extraStudents.socialStudies.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.fourteen.recidivism / facility.extraStudents.misc.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.fourteen.recidivism / facility.extraStudents.misc.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraStudents.academics.returns.fifteenPlus.recidivism / facility.extraStudents.academics.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.academics.returns.fifteenPlus.recidivism / facility.extraStudents.academics.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.academics.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraStudents.academics.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraStudents.vocational.returns.fifteenPlus.recidivism / facility.extraStudents.vocational.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.vocational.returns.fifteenPlus.recidivism / facility.extraStudents.vocational.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.vocational.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraStudents.vocational.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraStudents.selfDevelopment.returns.fifteenPlus.recidivism / facility.extraStudents.selfDevelopment.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.selfDevelopment.returns.fifteenPlus.recidivism / facility.extraStudents.selfDevelopment.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.selfDevelopment.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraStudents.selfDevelopment.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraStudents.socialStudies.returns.fifteenPlus.recidivism / facility.extraStudents.socialStudies.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.socialStudies.returns.fifteenPlus.recidivism / facility.extraStudents.socialStudies.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.socialStudies.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraStudents.socialStudies.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraStudents.misc.returns.fifteenPlus.recidivism / facility.extraStudents.misc.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.misc.returns.fifteenPlus.recidivism / facility.extraStudents.misc.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.misc.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraStudents.misc.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              <div style={{flexBasis:'95%'}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={30} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Class Hours and Number of Returns</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 5 Hours</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Hours</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>10 - 25 Hours</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>25 - 50 Hours</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 100 Hours</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>100 - 250 Hours</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>250 - 500 Hours</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Over 500 Hours</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.one.recidivism / facility.extraStudents.hours1To5.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.one.recidivism / facility.extraStudents.hours1To5.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.one.recidivism / facility.extraStudents.hours5To10.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.one.recidivism / facility.extraStudents.hours5To10.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.one.recidivism / facility.extraStudents.hours10To25.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.one.recidivism / facility.extraStudents.hours10To25.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.one.recidivism / facility.extraStudents.hours25To50.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.one.recidivism / facility.extraStudents.hours25To50.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.one.recidivism / facility.extraStudents.hours50To100.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.one.recidivism / facility.extraStudents.hours50To100.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.one.recidivism / facility.extraStudents.hours100To250.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.one.recidivism / facility.extraStudents.hours100To250.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.one.recidivism / facility.extraStudents.hours250To500.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.one.recidivism / facility.extraStudents.hours250To500.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.one.recidivism / facility.extraStudents.hoursOver500.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.one.recidivism / facility.extraStudents.hoursOver500.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.one.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.two.recidivism / facility.extraStudents.hours1To5.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.two.recidivism / facility.extraStudents.hours1To5.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.two.recidivism / facility.extraStudents.hours5To10.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.two.recidivism / facility.extraStudents.hours5To10.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.two.recidivism / facility.extraStudents.hours10To25.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.two.recidivism / facility.extraStudents.hours10To25.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.two.recidivism / facility.extraStudents.hours25To50.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.two.recidivism / facility.extraStudents.hours25To50.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.two.recidivism / facility.extraStudents.hours50To100.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.two.recidivism / facility.extraStudents.hours50To100.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.two.recidivism / facility.extraStudents.hours100To250.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.two.recidivism / facility.extraStudents.hours100To250.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.two.recidivism / facility.extraStudents.hours250To500.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.two.recidivism / facility.extraStudents.hours250To500.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.two.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.two.recidivism / facility.extraStudents.hoursOver500.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.two.recidivism / facility.extraStudents.hoursOver500.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.two.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.two.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.three.recidivism / facility.extraStudents.hours1To5.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.three.recidivism / facility.extraStudents.hours1To5.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.three.recidivism / facility.extraStudents.hours5To10.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.three.recidivism / facility.extraStudents.hours5To10.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.three.recidivism / facility.extraStudents.hours10To25.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.three.recidivism / facility.extraStudents.hours10To25.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.three.recidivism / facility.extraStudents.hours25To50.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.three.recidivism / facility.extraStudents.hours25To50.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.three.recidivism / facility.extraStudents.hours50To100.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.three.recidivism / facility.extraStudents.hours50To100.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.three.recidivism / facility.extraStudents.hours100To250.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.three.recidivism / facility.extraStudents.hours100To250.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.three.recidivism / facility.extraStudents.hours250To500.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.three.recidivism / facility.extraStudents.hours250To500.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.three.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.three.recidivism / facility.extraStudents.hoursOver500.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.three.recidivism / facility.extraStudents.hoursOver500.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.three.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.three.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.four.recidivism / facility.extraStudents.hours1To5.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.four.recidivism / facility.extraStudents.hours1To5.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.four.recidivism / facility.extraStudents.hours5To10.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.four.recidivism / facility.extraStudents.hours5To10.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.four.recidivism / facility.extraStudents.hours10To25.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.four.recidivism / facility.extraStudents.hours10To25.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.four.recidivism / facility.extraStudents.hours25To50.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.four.recidivism / facility.extraStudents.hours25To50.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.four.recidivism / facility.extraStudents.hours50To100.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.four.recidivism / facility.extraStudents.hours50To100.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.four.recidivism / facility.extraStudents.hours100To250.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.four.recidivism / facility.extraStudents.hours100To250.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.four.recidivism / facility.extraStudents.hours250To500.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.four.recidivism / facility.extraStudents.hours250To500.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.four.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.four.recidivism / facility.extraStudents.hoursOver500.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.four.recidivism / facility.extraStudents.hoursOver500.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.four.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.four.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.five.recidivism / facility.extraStudents.hours1To5.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.five.recidivism / facility.extraStudents.hours1To5.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.five.recidivism / facility.extraStudents.hours5To10.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.five.recidivism / facility.extraStudents.hours5To10.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.five.recidivism / facility.extraStudents.hours10To25.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.five.recidivism / facility.extraStudents.hours10To25.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.five.recidivism / facility.extraStudents.hours25To50.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.five.recidivism / facility.extraStudents.hours25To50.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.five.recidivism / facility.extraStudents.hours50To100.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.five.recidivism / facility.extraStudents.hours50To100.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.five.recidivism / facility.extraStudents.hours100To250.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.five.recidivism / facility.extraStudents.hours100To250.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.five.recidivism / facility.extraStudents.hours250To500.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.five.recidivism / facility.extraStudents.hours250To500.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.five.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.five.recidivism / facility.extraStudents.hoursOver500.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.five.recidivism / facility.extraStudents.hoursOver500.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.five.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.five.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.six.recidivism / facility.extraStudents.hours1To5.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.six.recidivism / facility.extraStudents.hours1To5.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.six.recidivism / facility.extraStudents.hours5To10.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.six.recidivism / facility.extraStudents.hours5To10.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.six.recidivism / facility.extraStudents.hours10To25.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.six.recidivism / facility.extraStudents.hours10To25.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.six.recidivism / facility.extraStudents.hours25To50.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.six.recidivism / facility.extraStudents.hours25To50.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.six.recidivism / facility.extraStudents.hours50To100.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.six.recidivism / facility.extraStudents.hours50To100.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.six.recidivism / facility.extraStudents.hours100To250.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.six.recidivism / facility.extraStudents.hours100To250.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.six.recidivism / facility.extraStudents.hours250To500.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.six.recidivism / facility.extraStudents.hours250To500.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.six.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.six.recidivism / facility.extraStudents.hoursOver500.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.six.recidivism / facility.extraStudents.hoursOver500.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.six.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.six.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.seven.recidivism / facility.extraStudents.hours1To5.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.seven.recidivism / facility.extraStudents.hours1To5.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.seven.recidivism / facility.extraStudents.hours5To10.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.seven.recidivism / facility.extraStudents.hours5To10.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.seven.recidivism / facility.extraStudents.hours10To25.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.seven.recidivism / facility.extraStudents.hours10To25.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.seven.recidivism / facility.extraStudents.hours25To50.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.seven.recidivism / facility.extraStudents.hours25To50.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.seven.recidivism / facility.extraStudents.hours50To100.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.seven.recidivism / facility.extraStudents.hours50To100.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.seven.recidivism / facility.extraStudents.hours100To250.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.seven.recidivism / facility.extraStudents.hours100To250.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.seven.recidivism / facility.extraStudents.hours250To500.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.seven.recidivism / facility.extraStudents.hours250To500.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.seven.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.seven.recidivism / facility.extraStudents.hoursOver500.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.seven.recidivism / facility.extraStudents.hoursOver500.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.seven.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.eight.recidivism / facility.extraStudents.hours1To5.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.eight.recidivism / facility.extraStudents.hours1To5.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.eight.recidivism / facility.extraStudents.hours5To10.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.eight.recidivism / facility.extraStudents.hours5To10.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.eight.recidivism / facility.extraStudents.hours10To25.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.eight.recidivism / facility.extraStudents.hours10To25.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.eight.recidivism / facility.extraStudents.hours25To50.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.eight.recidivism / facility.extraStudents.hours25To50.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.eight.recidivism / facility.extraStudents.hours50To100.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.eight.recidivism / facility.extraStudents.hours50To100.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.eight.recidivism / facility.extraStudents.hours100To250.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.eight.recidivism / facility.extraStudents.hours100To250.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.eight.recidivism / facility.extraStudents.hours250To500.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.eight.recidivism / facility.extraStudents.hours250To500.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.eight.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.eight.recidivism / facility.extraStudents.hoursOver500.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.eight.recidivism / facility.extraStudents.hoursOver500.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.eight.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.nine.recidivism / facility.extraStudents.hours1To5.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.nine.recidivism / facility.extraStudents.hours1To5.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.nine.recidivism / facility.extraStudents.hours5To10.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.nine.recidivism / facility.extraStudents.hours5To10.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.nine.recidivism / facility.extraStudents.hours10To25.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.nine.recidivism / facility.extraStudents.hours10To25.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.nine.recidivism / facility.extraStudents.hours25To50.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.nine.recidivism / facility.extraStudents.hours25To50.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.nine.recidivism / facility.extraStudents.hours50To100.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.nine.recidivism / facility.extraStudents.hours50To100.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.nine.recidivism / facility.extraStudents.hours100To250.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.nine.recidivism / facility.extraStudents.hours100To250.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.nine.recidivism / facility.extraStudents.hours250To500.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.nine.recidivism / facility.extraStudents.hours250To500.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.nine.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.nine.recidivism / facility.extraStudents.hoursOver500.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.nine.recidivism / facility.extraStudents.hoursOver500.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.nine.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.ten.recidivism / facility.extraStudents.hours1To5.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.ten.recidivism / facility.extraStudents.hours1To5.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.ten.recidivism / facility.extraStudents.hours5To10.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.ten.recidivism / facility.extraStudents.hours5To10.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.ten.recidivism / facility.extraStudents.hours10To25.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.ten.recidivism / facility.extraStudents.hours10To25.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.ten.recidivism / facility.extraStudents.hours25To50.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.ten.recidivism / facility.extraStudents.hours25To50.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.ten.recidivism / facility.extraStudents.hours50To100.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.ten.recidivism / facility.extraStudents.hours50To100.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.ten.recidivism / facility.extraStudents.hours100To250.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.ten.recidivism / facility.extraStudents.hours100To250.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.ten.recidivism / facility.extraStudents.hours250To500.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.ten.recidivism / facility.extraStudents.hours250To500.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.ten.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.ten.recidivism / facility.extraStudents.hoursOver500.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.ten.recidivism / facility.extraStudents.hoursOver500.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.ten.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.eleven.recidivism / facility.extraStudents.hours1To5.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.eleven.recidivism / facility.extraStudents.hours1To5.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.eleven.recidivism / facility.extraStudents.hours5To10.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.eleven.recidivism / facility.extraStudents.hours5To10.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.eleven.recidivism / facility.extraStudents.hours10To25.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.eleven.recidivism / facility.extraStudents.hours10To25.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.eleven.recidivism / facility.extraStudents.hours25To50.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.eleven.recidivism / facility.extraStudents.hours25To50.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.eleven.recidivism / facility.extraStudents.hours50To100.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.eleven.recidivism / facility.extraStudents.hours50To100.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.eleven.recidivism / facility.extraStudents.hours100To250.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.eleven.recidivism / facility.extraStudents.hours100To250.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.eleven.recidivism / facility.extraStudents.hours250To500.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.eleven.recidivism / facility.extraStudents.hours250To500.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.eleven.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.eleven.recidivism / facility.extraStudents.hoursOver500.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.eleven.recidivism / facility.extraStudents.hoursOver500.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.eleven.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.twelve.recidivism / facility.extraStudents.hours1To5.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.twelve.recidivism / facility.extraStudents.hours1To5.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.twelve.recidivism / facility.extraStudents.hours5To10.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.twelve.recidivism / facility.extraStudents.hours5To10.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.twelve.recidivism / facility.extraStudents.hours10To25.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.twelve.recidivism / facility.extraStudents.hours10To25.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.twelve.recidivism / facility.extraStudents.hours25To50.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.twelve.recidivism / facility.extraStudents.hours25To50.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.twelve.recidivism / facility.extraStudents.hours50To100.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.twelve.recidivism / facility.extraStudents.hours50To100.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.twelve.recidivism / facility.extraStudents.hours100To250.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.twelve.recidivism / facility.extraStudents.hours100To250.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.twelve.recidivism / facility.extraStudents.hours250To500.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.twelve.recidivism / facility.extraStudents.hours250To500.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.twelve.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.twelve.recidivism / facility.extraStudents.hoursOver500.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.twelve.recidivism / facility.extraStudents.hoursOver500.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.twelve.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.thirteen.recidivism / facility.extraStudents.hours1To5.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.thirteen.recidivism / facility.extraStudents.hours1To5.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.thirteen.recidivism / facility.extraStudents.hours5To10.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.thirteen.recidivism / facility.extraStudents.hours5To10.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.thirteen.recidivism / facility.extraStudents.hours10To25.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.thirteen.recidivism / facility.extraStudents.hours10To25.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.thirteen.recidivism / facility.extraStudents.hours25To50.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.thirteen.recidivism / facility.extraStudents.hours25To50.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.thirteen.recidivism / facility.extraStudents.hours50To100.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.thirteen.recidivism / facility.extraStudents.hours50To100.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.thirteen.recidivism / facility.extraStudents.hours100To250.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.thirteen.recidivism / facility.extraStudents.hours100To250.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.thirteen.recidivism / facility.extraStudents.hours250To500.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.thirteen.recidivism / facility.extraStudents.hours250To500.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.thirteen.recidivism / facility.extraStudents.hoursOver500.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.thirteen.recidivism / facility.extraStudents.hoursOver500.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.thirteen.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.fourteen.recidivism / facility.extraStudents.hours1To5.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.fourteen.recidivism / facility.extraStudents.hours1To5.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.fourteen.recidivism / facility.extraStudents.hours5To10.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.fourteen.recidivism / facility.extraStudents.hours5To10.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.fourteen.recidivism / facility.extraStudents.hours10To25.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.fourteen.recidivism / facility.extraStudents.hours10To25.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.fourteen.recidivism / facility.extraStudents.hours25To50.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.fourteen.recidivism / facility.extraStudents.hours25To50.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.fourteen.recidivism / facility.extraStudents.hours50To100.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.fourteen.recidivism / facility.extraStudents.hours50To100.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.fourteen.recidivism / facility.extraStudents.hours100To250.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.fourteen.recidivism / facility.extraStudents.hours100To250.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.fourteen.recidivism / facility.extraStudents.hours250To500.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.fourteen.recidivism / facility.extraStudents.hours250To500.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.fourteen.recidivism / facility.extraStudents.hoursOver500.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.fourteen.recidivism / facility.extraStudents.hoursOver500.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.fourteen.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours1To5.returns.one.recidivism / facility.extraStudents.hours1To5.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours1To5.returns.one.recidivism / facility.extraStudents.hours1To5.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours1To5.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours1To5.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours5To10.returns.one.recidivism / facility.extraStudents.hours5To10.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours5To10.returns.one.recidivism / facility.extraStudents.hours5To10.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours5To10.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours5To10.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours10To25.returns.one.recidivism / facility.extraStudents.hours10To25.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours10To25.returns.one.recidivism / facility.extraStudents.hours10To25.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours10To25.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours10To25.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours25To50.returns.one.recidivism / facility.extraStudents.hours25To50.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours25To50.returns.one.recidivism / facility.extraStudents.hours25To50.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours25To50.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours25To50.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours50To100.returns.one.recidivism / facility.extraStudents.hours50To100.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours50To100.returns.one.recidivism / facility.extraStudents.hours50To100.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours50To100.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours50To100.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours100To250.returns.one.recidivism / facility.extraStudents.hours100To250.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours100To250.returns.one.recidivism / facility.extraStudents.hours100To250.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours100To250.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours100To250.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hours250To500.returns.one.recidivism / facility.extraStudents.hours250To500.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hours250To500.returns.one.recidivism / facility.extraStudents.hours250To500.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hours250To500.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hours250To500.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.one.total == 0 ? '0' : Math.round(facility.extraStudents.hoursOver500.returns.one.recidivism / facility.extraStudents.hoursOver500.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraStudents.hoursOver500.returns.one.recidivism / facility.extraStudents.hoursOver500.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraStudents.hoursOver500.returns.one.recidivism.toFixed(0)} / {facility.extraStudents.hoursOver500.returns.one.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>)}
        </Fragment>
      )
    }
}

export default RecidivismFacilityStudentBreakdowns