import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as InmatesStore from '../../store/reducers/inmates'
import * as PanesStore from '../../store/reducers/panes'
import InmateFilters from './InmateFilters'
import HiSETRow from './HiSETRow'
import InfiniteScroll from 'react-infinite-scroller'
import {
  Popup,
  Table,
  Dimmer,
  Loader,
  Icon
} from 'semantic-ui-react'
const _ = require('underscore')

type TableProps =
    { open: boolean, loading: boolean, hasMore: boolean, tab: string, sorts: any, filters: InmatesStore.InmateFilters, inmates: InmatesStore.Inmate[] } &
    typeof DrawerStore.actionCreators &
    typeof InmatesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class HiSETTable extends React.PureComponent<TableProps> {
    private HeaderArrow = (props:any) => {
      var sort = _.find(this.props.sorts, (sort:any) => sort.column == props.orderBy)
      if (sort != null) {
        if (sort.dir == 'asc') {
          var dir = 'up'
        } else {
          var dir = 'down'
        }
        return (<i style={{color:'#db2828'}} className={`icon angle ${dir}`} />)
      } else {
        return (null)
      }
    }

    public render() {
      const HeaderArrow = this.HeaderArrow
      return (
        <Fragment>
          <InmateFilters />
          <div id='hiset-table' style={{top:this.props.open?200:70}}>
            <Dimmer inverted active={this.props.loading}><Loader inverted content="Loading HiSETs ..." /></Dimmer>
            <InfiniteScroll
                pageStart={0}
                loadMore={this.props.fetchNextHiSET}
                hasMore={!this.props.loading && this.props.hasMore}
                initialLoad={false}
                useWindow={false}
                getScrollParent={() => document.getElementById('hiset-table') }
            >
              <Table compact celled striped style={{borderTop:0,marginTop:0}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }}><div style={{whiteSpace:'nowrap',height:'1em'}}><i className="icon icon-h" /></div></Table.HeaderCell>
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)',textAlign:'center' }}><div style={{whiteSpace:'nowrap',height:'1em'}}><Icon name="graduation" /><HeaderArrow orderBy='college' /></div></Table.HeaderCell>
                    <Popup content="Click to sort inmates by name" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('name')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Inmate Name<HeaderArrow orderBy='name' /></div></Table.HeaderCell>} />
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>SID</div></Table.HeaderCell>
                    <Popup content="Click to sort inmates by facility" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('facility')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Facility<HeaderArrow orderBy='facility' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by date" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('date')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Test Date<HeaderArrow orderBy='date' /></div></Table.HeaderCell>} />
                    <Table.HeaderCell style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>ETS ID</div></Table.HeaderCell>
                    <Popup content="Click to sort inmates by math" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('math')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Math<HeaderArrow orderBy='math' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by science" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('science')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Science<HeaderArrow orderBy='science' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by social" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('social')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Social Studies<HeaderArrow orderBy='social' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by reading" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('reading')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Reading<HeaderArrow orderBy='reading' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by writing" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('writing')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Writing<HeaderArrow orderBy='writing' /></div></Table.HeaderCell>} />
                    <Popup content="Click to sort inmates by essay" trigger={<Table.HeaderCell onClick={() => this.props.toggleOrder('essay')} style={{ position:'sticky',top:0,borderTop:'1px solid rgba(34,36,38,.15)' }}><div style={{whiteSpace:'nowrap',height:'1em'}}>Essay<HeaderArrow orderBy='essay' /></div></Table.HeaderCell>} />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.props.inmates.length > 0 && (this.props.inmates[0] as any).hisets && (this.props.inmates[0] as any).hisets.length > 0 ? this.props.inmates.map((inmate:any, i:number) => inmate.hisets.map((test:any, t:number) => (
                    <HiSETRow inmate={inmate} test={test} key={t} i={i} h={t} />
                  ))) : this.props.inmates.map((tabe:any, t:number) => (
                    <HiSETRow inmate={tabe} test={tabe} key={t} i={t} h={0} />
                  ))}
                </Table.Body>
              </Table>
            </InfiniteScroll>
          </div>
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { open: state.inmates.open, loading: state.inmates.tabs.hiset.loading, hasMore: state.inmates.tabs.hiset.hasMore, filters: state.inmates.filters, sorts: state.inmates.sorts, inmates: state.inmates.tabs.hiset.data, tab: state.drawer.tab } },
  { ...DrawerStore.actionCreators, ...InmatesStore.actionCreators, ...PanesStore.actionCreators }
)(HiSETTable as any)