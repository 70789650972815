import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { renderToString } from 'react-dom/server'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import { pdfshift } from '../../../agent'
import * as StaffStore from '../../../store/reducers/reports'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Icon
} from 'semantic-ui-react'
const papa = require('papaparse')
const _ = require('underscore')

const css = require("!!css-loader!../../../custom.css");

type ReportProps =
    { report: any, options: any } &
    typeof ReportsStore.actionCreators &
    typeof StaffStore.actionCreators &
    WithRouterProps<{}>

class QuarterlyCSV extends React.PureComponent<ReportProps> {
    public componentDidUpdate(prevProps:any) {
      if (!prevProps.report.ready && this.props.report.ready) {
        let params = this.props.report.data
        let generated = this.props.report.generated
        let fields:any = []
        let data:any = []

        switch (params.reportType) {
        case 'ethnic':
          var options = this.props.options
          var ethnicity = _.find(options.ethnicities, (e:any) => e.value == params.ethnicity).text
          fields = ['Program']
          if (params.showCourses) { fields.push('Course') }
          if (params.showClasses) { fields.push('Class') }
          fields = fields.concat(['Enrolled','Completed','Percentage',`${ethnicity} Enrolled`,`${ethnicity} Completed`,`${ethnicity} Percentage`,`Non-${ethnicity} Enrolled`,`Non-${ethnicity} Completed`,`Non-${ethnicity} Percentage`])
          _.each(generated.programs, (program:any, p:number) => {
            if (!params.showCourses) {
              var enrolled = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
              var enrolledUnique = program.inmates.length
              var completed = _.reduce(program.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
              var completedUnique = _.filter(program.inmates, (inmate:any) => inmate.completed > 0).length
              var ethEnrolled = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
              var ethEnrolledUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity).length
              var ethCompleted = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
              var ethCompletedUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity && inmate.completed > 0).length
              var nonEnrolled = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
              var nonEnrolledUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity).length
              var nonCompleted = _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
              var nonCompletedUnique = _.filter(program.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity && inmate.completed > 0).length
              let row = [program.programName]
              row.push(enrolledUnique)
              row.push(completedUnique)
              row.push(`${enrolled == 0 ? "0%" : `${Math.floor((completed / enrolled)*100)}%`}`)
              row.push(ethEnrolledUnique)
              row.push(ethCompletedUnique)
              row.push(`${ethEnrolled == 0 ? "0%" : `${Math.floor((ethCompleted / ethEnrolled)*100)}%`}`)
              row.push(nonEnrolledUnique)
              row.push(nonCompletedUnique)
              row.push(`${nonEnrolled == 0 ? "0%" : `${Math.floor((nonCompleted / nonEnrolled)*100)}%`}`)
              data.push(row)
            } else {
              _.each(program.courses, (course:any, c:number) => {
                if (!params.showClasses) {
                  var enrolled = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
                  var enrolledUnique = course.inmates.length
                  var completed = _.reduce(course.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                  var completedUnique = _.filter(course.inmates, (inmate:any) => inmate.completed > 0).length
                  var ethEnrolled = _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
                  var ethEnrolledUnique = _.filter(course.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity).length
                  var ethCompleted = _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                  var ethCompletedUnique = _.filter(course.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity && inmate.completed > 0).length
                  var nonEnrolled = _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
                  var nonEnrolledUnique = _.filter(course.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity).length
                  var nonCompleted = _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                  var nonCompletedUnique = _.filter(course.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity && inmate.completed > 0).length
                  let row:any = []
                  if (c == 0) { row.push(program.programName) } else { row.push('') }
                  row.push(course.courseName)
                  row.push(enrolledUnique)
                  row.push(completedUnique)
                  row.push(`${enrolled == 0 ? "0%" : `${Math.floor((completed / enrolled)*100)}%`}`)
                  row.push(ethEnrolledUnique)
                  row.push(ethCompletedUnique)
                  row.push(`${ethEnrolled == 0 ? "0%" : `${Math.floor((ethCompleted / ethEnrolled)*100)}%`}`)
                  row.push(nonEnrolledUnique)
                  row.push(nonCompletedUnique)
                  row.push(`${nonEnrolled == 0 ? "0%" : `${Math.floor((nonCompleted / nonEnrolled)*100)}%`}`)
                  data.push(row)
                } else {
                  _.each(course.classes, (klass:any, t:number) => {
                    var enrolled = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
                    var enrolledUnique = klass.inmates.length
                    var completed = _.reduce(klass.inmates, (acc:number, inmate:any) => acc + inmate.completed, 0)
                    var completedUnique = _.filter(klass.inmates, (inmate:any) => inmate.completed > 0).length
                    var ethEnrolled = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
                    var ethEnrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity).length
                    var ethCompleted = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                    var ethCompletedUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity == params.ethnicity && inmate.completed > 0).length
                    var nonEnrolled = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
                    var nonEnrolledUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity).length
                    var nonCompleted = _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity), (acc:number, inmate:any) => acc + inmate.completed, 0)
                    var nonCompletedUnique = _.filter(klass.inmates, (inmate:any) => inmate.ethnicity != params.ethnicity && inmate.completed > 0).length
                    let row:any = []
                    if (t == 0 && c == 0) { row.push(program.programName) } else { row.push('') }
                    if (c == 0) { row.push(course.courseName) } else { row.push('') }
                    row.push(klass.className)
                    row.push(enrolledUnique)
                    row.push(completedUnique)
                    row.push(`${enrolled == 0 ? "0%" : `${Math.floor((completed / enrolled)*100)}%`}`)
                    row.push(ethEnrolledUnique)
                    row.push(ethCompletedUnique)
                    row.push(`${ethEnrolled == 0 ? "0%" : `${Math.floor((ethCompleted / ethEnrolled)*100)}%`}`)
                    row.push(nonEnrolledUnique)
                    row.push(nonCompletedUnique)
                    row.push(`${nonEnrolled == 0 ? "0%" : `${Math.floor((nonCompleted / nonEnrolled)*100)}%`}`)
                    data.push(row)
                  })
                }
              })
              let row:any = []
              if (params.showCourses) { row.push('') }
              if (params.showCourses && params.showClasses) { row.push('') }
              row.push(`${program.programName} Subtotals`)
              row.push(this.totalProgramEnrollmentUnique(program))
              row.push(this.totalProgramCompletedUnique(program))
              row.push(`${this.totalProgramEnrollmentUnique(program) == 0 ? "0%" : `${Math.floor((this.totalProgramCompletedUnique(program) / this.totalProgramEnrollmentUnique(program))*100)}%`}`)
              row.push(this.totalProgramEthnicityEnrollmentUnique(program, params.ethnicity))
              row.push(this.totalProgramEthnicityCompletedUnique(program, params.ethnicity))
              row.push(`${this.totalProgramEthnicityEnrollmentUnique(program, params.ethnicity) == 0 ? "0%" : `${Math.floor((this.totalProgramEthnicityCompletedUnique(program, params.ethnicity) / this.totalProgramEthnicityEnrollmentUnique(program, params.ethnicity))*100)}%`}`)
              row.push(this.totalProgramEthnicityEnrollmentUnique(program, params.ethnicity))
              row.push(this.totalProgramEthnicityCompletedUnique(program, params.ethnicity))
              row.push(`${this.totalProgramNonEthnicityEnrollmentUnique(program, params.ethnicity) == 0 ? "0%" : `${Math.floor((this.totalProgramNonEthnicityCompletedUnique(program, params.ethnicity) / this.totalProgramNonEthnicityEnrollmentUnique(program, params.ethnicity))*100)}%`}`)
              data.push(row)
            }
          })
          let row:any = []
          if (params.showCourses) { row.push('') }
          if (params.showCourses && params.showClasses) { row.push('') }
          row.push('Totals')
          row.push(`${this.totalEnrollment(params.showCourses ? true  : false, params.showClasses ? true : false)} (${this.totalEnrollmentUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})`)
          row.push(`${this.totalCompleted(params.showCourses ? true  : false, params.showClasses ? true : false)} (${this.totalCompletedUnique(params.showCourses ? true  : false, params.showClasses ? true : false)})`)
          row.push(`${this.totalEnrollment(params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? "0%" : `${Math.floor((this.totalCompleted(params.showCourses ? true  : false, params.showClasses ? true : false) / this.totalEnrollment(params.showCourses ? true  : false, params.showClasses ? true : false))*100)}%`}`)
          row.push(`${this.totalEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)} (${this.totalEthnicityEnrollmentUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)})`)
          row.push(`${this.totalEthnicityCompleted(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)} (${this.totalEthnicityCompletedUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)})`)
          row.push(`${this.totalEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? "0%" : `${Math.floor((this.totalEthnicityCompleted(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) / this.totalEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false))*100)}%`}`)
          row.push(`${this.totalNonEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)} (${this.totalNonEthnicityEnrollmentUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)})`)
          row.push(`${this.totalNonEthnicityCompleted(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)} (${this.totalNonEthnicityCompletedUnique(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false)})`)
          row.push(`${this.totalNonEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) == 0 ? "0%" : `${Math.floor((this.totalNonEthnicityCompleted(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false) / this.totalNonEthnicityEnrollment(params.ethnicity, params.showCourses ? true  : false, params.showClasses ? true : false))*100)}%`}`)
          data.push(row)
          break;
        case 'detailed-participation':
          fields = ['SID']
          if (params.showNames) { fields.push('Student Name') }
          if (params.showPrograms) { fields.push('Program') }
          if (params.showCourses) { fields.push('Course') }
          if (params.showClasses) { fields.push('Class') }
          _.each(generated.inmates, (inmate:any, i:number) => {
            if (params.showPrograms && params.showCourses && params.showClasses) {
              _.each(inmate.programs, (program:any) => {
                _.each(program.courses, (course:any) => {
                  _.each(course.classes, (klass:string) => {
                    let row = [inmate.sid]
                    if (params.showNames) { row.push(inmate.name) }
                    row.push(program.name)
                    row.push(course.name)
                    row.push(klass)
                    data.push(row)
                  })
                })
              })
            } else if (params.showPrograms && params.showCourses) {
              _.each(inmate.programs, (program:any) => {
                _.each(program.courses, (course:any) => {
                  let row = [inmate.sid]
                  if (params.showNames) { row.push(inmate.name) }
                  row.push(program.name)
                  row.push(course.name)
                  data.push(row)
                })
              })
            } else if (params.showPrograms) {
              _.each(inmate.programs, (program:any) => {
                let row = [inmate.sid]
                if (params.showNames) { row.push(inmate.name) }
                row.push(program.name)
                data.push(row)
              })
            } else {
              let row = [inmate.sid]
              if (params.showNames) { row.push(inmate.name) }
              data.push(row)
            }
          })
          break;
        case 'detailed-completions':
          if (params.showPrograms && params.showCourses && params.showClasses) {
            fields = ['Program','Course','Class','SID']
            if (params.showNames) { fields.push('Inmate') }
            fields = fields.concat(['Enrolled','Currently Active','Currently Suspended','Currently Quarantined','Deleted from Class','Completed','Not Completed','Cont. Ed'])
            generated.programs.map((program:any, p:number) =>
              program.courses.map((course:any, c:number) =>
                course.classes.map((klass:any, t:number) => {
                  klass.inmates.map((inmate:any, i:number) => {
                    let row = []
                    row.push(program.programName)
                    row.push(course.courseName)
                    row.push(klass.className)
                    row.push(inmate.sid)
                    if (params.showNames) { row.push(inmate.name) }
                    row.push(inmate.grades.length + inmate.missing + (inmate.active ? 1 : 0) + (inmate.suspended ? 1 : 0) + (inmate.quarantined ? 1 : 0))
                    row.push(inmate.active ? "1" : "")
                    row.push(inmate.suspended ? "1" : "")
                    row.push(inmate.quarantined ? "1" : "")
                    row.push(inmate.missing ? inmate.missing : "")
                    row.push(inmate.completed ? inmate.completed : "")
                    row.push(inmate.incomplete ? inmate.incomplete : "")
                    row.push(inmate.contEd ? inmate.contEd : "")
                    data.push(row)
                  })
                })
              )
            )
          } else if (params.showPrograms && params.showCourses) {
            fields = ['Program','Course','SID']
            if (params.showNames) { fields.push('Inmate') }
            fields = fields.concat(['Enrolled','Currently Active','Currently Suspended','Currently Quarantined','Deleted from Class','Completed','Not Completed','Cont. Ed'])
            generated.programs.map((program:any, p:number) =>
              program.courses.map((course:any, c:number) => {
                course.inmates.map((inmate:any, i:number) => {
                  let row = []
                  row.push(program.programName)
                  row.push(course.courseName)
                  row.push(inmate.sid)
                  if (params.showNames) { row.push(inmate.name) }
                  row.push(inmate.grades.length + inmate.missing + (inmate.active ? 1 : 0) + (inmate.suspended ? 1 : 0) + (inmate.quarantined ? 1 : 0))
                  row.push(inmate.active ? "1" : "")
                  row.push(inmate.suspended ? "1" : "")
                  row.push(inmate.quarantined ? "1" : "")
                  row.push(inmate.missing ? inmate.missing : "")
                  row.push(inmate.completed ? inmate.completed : "")
                  row.push(inmate.incomplete ? inmate.incomplete : "")
                  row.push(inmate.contEd ? inmate.contEd : "")
                  data.push(row)
                })
              })
            )
          } else if (params.showPrograms) {
            fields = ['Program','SID']
            if (params.showNames) { fields.push('Inmate') }
            fields = fields.concat(['Enrolled','Currently Active','Currently Suspended','Currently Quarantined','Deleted from Class','Completed','Not Completed','Cont. Ed'])
            generated.programs.map((program:any, p:number) => {
              program.inmates.map((inmate:any, i:number) => {
                let row = []
                row.push(program.programName)
                row.push(inmate.sid)
                if (params.showNames) { row.push(inmate.name) }
                row.push(inmate.grades.length + inmate.missing + (inmate.active ? 1 : 0) + (inmate.suspended ? 1 : 0) + (inmate.quarantined ? 1 : 0))
                row.push(inmate.active ? "1" : "")
                row.push(inmate.suspended ? "1" : "")
                row.push(inmate.quarantined ? "1" : "")
                row.push(inmate.missing ? inmate.missing : "")
                row.push(inmate.completed ? inmate.completed : "")
                row.push(inmate.incomplete ? inmate.incomplete : "")
                row.push(inmate.contEd ? inmate.contEd : "")
                data.push(row)
              })
            })
          } else {
            fields = ['SID']
            if (params.showNames) { fields.push('Inmate') }
            fields = fields.concat(['Enrolled','Currently Active','Currently Suspended','Currently Quarantined','Deleted from Class','Completed','Not Completed','Cont. Ed'])
            generated.completions.map((inmate:any, i:number) => {
              let row = []
              row.push(inmate.sid)
              if (params.showNames) { row.push(inmate.name) }
              row.push(inmate.grades.length + inmate.missing + (inmate.active ? 1 : 0) + (inmate.suspended ? 1 : 0) + (inmate.quarantined ? 1 : 0))
              row.push(inmate.active ? "1" : "")
              row.push(inmate.suspended ? "1" : "")
              row.push(inmate.quarantined ? "1" : "")
              row.push(inmate.missing ? inmate.missing : "")
              row.push(inmate.completed ? inmate.completed : "")
              row.push(inmate.incomplete ? inmate.incomplete : "")
              row.push(inmate.contEd ? inmate.contEd : "")
              data.push(row)
            })
          }
          break;
        case 'detailed':
          fields = ['Program','Course']
          if (params.showDates) { fields.push('Class ID') }
          fields.push(['Class','Inmate','SID'])
          if (params.showDates) {
            fields.push('Start Date')
            fields.push('End Date')
          } else {
            fields = fields.concat(['Enrolled','Currently Active','Currently Suspended','Currently Quarantined'])
          }
          fields = fields.concat(['Deleted from Class','Completed','Not Completed','Cont. Ed','Dropped','Transferred','Paroled','Released','No Show'])
          generated.programs.map((program:any, p:number) =>
            program.courses.map((course:any, c:number) =>
              course.classes.map((klass:any, t:number) =>
                klass.inmates.map((inmate:any, i:number) => {
                  if (params.showDates) {
                    inmate.dates.map((date:any, d:number) => {
                      if (d == 0) {
                        let row = []
                        row.push(program.programName)
                        row.push(course.courseName)
                        row.push(date.classID)
                        row.push(klass.className)
                        row.push(inmate.name)
                        row.push(inmate.sid)
                        row.push(`${date.startDate}`)
                        row.push(`${date.endDate}`)
                        row.push(date.missing ? date.missing : "")
                        row.push(date.completed ? date.completed : "")
                        row.push(date.incomplete ? date.incomplete : "")
                        row.push(date.contEd ? date.contEd : "")
                        row.push(date.dropped ? date.dropped : "")
                        row.push(date.transferred ? date.transferred : "")
                        row.push(date.paroled ? date.paroled : "")
                        row.push(date.released ? date.released : "")
                        row.push(date.noShow ? date.noShow : "")
                        data.push(row)
                      } else {
                        let row = []
                        row.push(program.programName)
                        row.push(course.courseName)
                        row.push(date.classID)
                        row.push(klass.className)
                        row.push(inmate.name)
                        row.push(inmate.sid)
                        row.push(`${date.startDate}`)
                        row.push(`${date.endDate}`)
                        row.push(date.missing ? date.missing : "")
                        row.push(date.completed ? date.completed : "")
                        row.push(date.incomplete ? date.incomplete : "")
                        row.push(date.contEd ? date.contEd : "")
                        row.push(date.dropped ? date.dropped : "")
                        row.push(date.transferred ? date.transferred : "")
                        row.push(date.paroled ? date.paroled : "")
                        row.push(date.released ? date.released : "")
                        row.push(date.noShow ? date.noShow : "")
                        data.push(row)
                      }
                    })
                  } else {
                    row.push(program.programName)
                    row.push(course.courseName)
                    row.push(klass.className)
                    row.push(inmate.name)
                    row.push(inmate.sid)
                    row.push(inmate.grades.length + inmate.missing + (inmate.active ? 1 : 0) + (inmate.suspended ? 1 : 0) + (inmate.quarantined ? 1 : 0))
                    row.push(inmate.active ? "1" : "")
                    row.push(inmate.suspended ? "1" : "")
                    row.push(inmate.quarantined ? "1" : "")
                    row.push(inmate.missing ? inmate.missing : "")
                    row.push(inmate.completed ? inmate.completed : "")
                    row.push(inmate.incomplete ? inmate.incomplete : "")
                    row.push(inmate.contEd ? inmate.contEd : "")
                    row.push(inmate.dropped ? inmate.dropped : "")
                    row.push(inmate.transferred ? inmate.transferred : "")
                    row.push(inmate.paroled ? inmate.paroled : "")
                    row.push(inmate.released ? inmate.released : "")
                    row.push(inmate.noShow ? inmate.noShow : "")
                    data.push(row)
                  }
                })
              )
            )
          )
          break;
        }

        var csv = papa.unparse({
          fields: fields,
          data: data
        })
        let blob:Blob = new Blob([csv]);
        let url = (window as any).URL.createObjectURL(blob, {type: "text/csv"})
        this.props.setURL('quarterly', url)
      }
    }

    public genCSV(data: any[]) {
      return data.map((row:any) =>
        row
          .map(String)
          .map((v:string) => v.replace(/"/g, '""'))
          .map((v:string) => `"${v}"`)
          .join(',')
      ).join('\r\n')
    }

    public totalEnrollment(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalEnrollmentUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(program.inmates, (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(course.inmates, (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(klass.inmates, (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalCompleted(showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(program.inmates, (a2:number, inmate:any) => a2 + inmate.completed, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.completed, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.completed, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalCompletedUnique(showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalEthnicityEnrollment(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a2:number, inmate:any) => a2 + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a3:number, inmate:any) => a3 + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a4:number, inmate:any) => a4 + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalEthnicityEnrollmentUnique(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalEthnicityCompleted(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a2:number, inmate:any) => a2 + inmate.completed, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a3:number, inmate:any) => a3 + inmate.completed, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (a4:number, inmate:any) => a4 + inmate.completed, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalEthnicityCompletedUnique(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalNonEthnicityEnrollment(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a2:number, inmate:any) => a2 + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a3:number, inmate:any) => a3 + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a4:number, inmate:any) => a4 + inmate.grades.length + inmate.missing + inmate.active + inmate.suspended + (inmate.quarantined ? 1 : 0), 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalNonEthnicityEnrollmentUnique(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalNonEthnicityCompleted(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        if (!showCourses) {
          return a1 + _.reduce(_.filter(program.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a2:number, inmate:any) => a2 + inmate.completed, 0)
        } else {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
            if (showCourses && !showClasses) {
              return a2 + _.reduce(_.filter(course.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a3:number, inmate:any) => a3 + inmate.completed, 0)
            } else {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.reduce(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (a4:number, inmate:any) => a4 + inmate.completed, 0)
              }, 0)
            }
          }, 0)
        }
      }, 0)
    }

    public totalNonEthnicityCompletedUnique(ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(this.props.report.generated.programs, (program:any) => {
        if (!showCourses) {
          _.each(_.filter(program.inmates, (inmate:any) => inmate.ethnicity != ethnicity && inmate.completed > 0), (inmate:any) => {
            if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
          })
        } else {
          _.each(program.courses, (course:any) => {
            if (showCourses && !showClasses) {
              _.each(_.filter(course.inmates, (inmate:any) => inmate.ethnicity != ethnicity && inmate.completed > 0), (inmate:any) => {
                if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
              })
            } else {
              _.each(course.classes, (klass:any) => {
                _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != ethnicity && inmate.completed > 0), (inmate:any) => {
                  if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
                })
              })
            }
          })
        }
      })
      return inmates
    }

    public totalProgramEnrollmentUnique(data:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(klass.inmates, (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalProgramCompletedUnique(data:any, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalProgramEthnicityEnrollmentUnique(data:any, ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalProgramEthnicityCompletedUnique(data:any, ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity == ethnicity && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalProgramNonEthnicityEnrollmentUnique(data:any, ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != ethnicity), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
    }

    public totalProgramNonEthnicityCompletedUnique(data:any, ethnicity:string, showCourses:boolean = true, showClasses:boolean = true) {
      let inmates = 0
      let mem:any = {}
      _.each(_.filter(this.props.report.generated.programs, (program:any) => program.programID == data.programID), (program:any) => {
        _.each(program.courses, (course:any) => {
          _.each(course.classes, (klass:any) => {
            _.each(_.filter(klass.inmates, (inmate:any) => inmate.ethnicity != ethnicity && inmate.completed > 0), (inmate:any) => {
              if (!mem[inmate.recordID]) { mem[inmate.recordID] = true; inmates += 1; }
            })
          })
        })
      })
      return inmates
  }

    public totalActive() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.filter(klass.inmates, (inmate:any) => inmate.active).length
              }, 0)
          }, 0)
      }, 0)
    }

    public totalSuspended() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.filter(klass.inmates, (inmate:any) => inmate.suspended).length
              }, 0)
          }, 0)
      }, 0)
    }

    public totalQuarantined() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.filter(klass.inmates, (inmate:any) => inmate.quarantined).length
              }, 0)
          }, 0)
      }, 0)
    }

    public totalMissing() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.missing, 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalMissingUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                return a3 + _.filter(klass.inmates, (inmate:any) => inmate.missing > 0).length
              }, 0)
          }, 0)
      }, 0)
    }

    public totalNotCompleted() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.schedule + inmate.discipline + inmate.covid + inmate.noShow + inmate.reclassified, 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalNotCompletedUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.filter(klass.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)).length
              }, 0)
          }, 0)
      }, 0)
    }

    public totalContEd() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.reduce(klass.inmates, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
              }, 0)
          }, 0)
      }, 0)
    }

    public totalContEdUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
          return a1 + _.reduce(program.courses, (a2:number, course:any) => {
              return a2 + _.reduce(course.classes, (a3:number, klass:any) => {
                  return a3 + _.filter(klass.inmates, (inmate:any) => inmate.contEd > 0).length
              }, 0)
          }, 0)
      }, 0)
    }

    public totalCoursesEnrollment() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
         return a1 + _.reduce(program.courses, (a2:number, course:any) => {
             return a2 + _.reduce(course.inmates, (a3:number, inmate:any) => a3 + inmate.grades.length + inmate.missing + (inmate.active ? 1 : 0) + (inmate.suspended ? 1 : 0) + (inmate.quarantined ? 1 : 0), 0)
         }, 0)
      }, 0)
    }

    public totalCoursesEnrollmentHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(course.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesEnrollmentUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + course.inmates.length
        }, 0)
      }, 0)
    }

    public totalCoursesActive() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => inmate.active).length
        }, 0)
      }, 0)
    }

    public totalCoursesActiveHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesSuspended() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => inmate.suspended).length
        }, 0)
      }, 0)
    }

    public totalCoursesSuspendedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesQuarantined() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => inmate.quarantined).length
        }, 0)
      }, 0)
    }

    public totalCoursesQuarantinedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
         _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesMissing() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.missing, 0)
        }, 0)
      }, 0)
    }

    public totalCoursesMissingHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesMissingUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => inmate.missing > 0).length
        }, 0)
      }, 0)
    }

    public totalCoursesCompleted() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.completed, 0)
        }, 0)
      }, 0)
    }

    public totalCoursesCompletedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesCompletedUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => inmate.completed > 0).length
        }, 0)
      }, 0)
    }

    public totalCoursesNotCompleted() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.schedule + inmate.discipline + inmate.covid + inmate.noShow + inmate.reclassified, 0)
        }, 0)
      }, 0)
    }

    public totalCoursesNotCompletedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesNotCompletedUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)).length
        }, 0)
      }, 0)
    }

    public totalCoursesContEd() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.reduce(course.inmates, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
        }, 0)
      }, 0)
    }

    public totalCoursesContEdHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        _.each(program.courses, (course:any) => {
          if (_.any(_.filter(course.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
            highlight = true
          }
        })
      })
      return highlight
    }

    public totalCoursesContEdUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.courses, (a2:number, course:any) => {
          return a2 + _.filter(course.inmates, (inmate:any) => inmate.contEd > 0).length
        }, 0)
      }, 0)
    }

    public totalProgramsEnrollment() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a3:number, inmate:any) => a3 + inmate.grades.length + inmate.missing + (inmate.active ? 1 : 0) + (inmate.suspended ? 1 : 0) + (inmate.quarantined ? 1 : 0), 0)
      }, 0)
    }

    public totalProgramsEnrollmentHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(program.inmates, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsEnrollmentUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + program.inmates.length
      }, 0)
    }

    public totalProgramsActive() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => inmate.active).length
      }, 0)
    }

    public totalProgramsActiveHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsSuspended() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => inmate.suspended).length
      }, 0)
    }

    public totalProgramsSuspendedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsQuarantined() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => inmate.quarantined).length
      }, 0)
    }

    public totalProgramsQuarantinedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsMissing() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.missing, 0)
      }, 0)
    }

    public totalProgramsMissingHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsMissingUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => inmate.missing > 0).length
      }, 0)
    }

    public totalProgramsCompleted() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.completed, 0)
      }, 0)
    }

    public totalProgramsCompletedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsCompletedUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => inmate.completed > 0).length
      }, 0)
    }

    public totalProgramsNotCompleted() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.schedule + inmate.discipline + inmate.covid + inmate.noShow + inmate.reclassified, 0)
      }, 0)
    }

    public totalProgramsNotCompletedHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsNotCompletedUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)).length
      }, 0)
    }

    public totalProgramsContEd() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.reduce(program.inmates, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
      }, 0)
    }

    public totalProgramsContEdHighlight() {
      var highlight = false
      _.each(this.props.report.generated.programs, (program:any) => {
        if (_.any(_.filter(program.inmates, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
          highlight = true
        }
      })
      return highlight
    }

    public totalProgramsContEdUnique() {
      return _.reduce(this.props.report.generated.programs, (a1:number, program:any) => {
        return a1 + _.filter(program.inmates, (inmate:any) => inmate.contEd > 0).length
      }, 0)
    }

    public totalCompletionsEnrollment() {
      return _.reduce(this.props.report.generated.completions, (a:number, inmate:any) => a + inmate.grades.length + inmate.missing + (inmate.active ? 1 : 0) + (inmate.suspended ? 1 : 0) + (inmate.quarantined ? 1 : 0), 0)
    }

    public totalCompletionsEnrollmentHighlight() {
      var highlight = false
      if (_.any(this.props.report.generated.completions, (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsEnrollmentUnique() {
      return this.props.report.generated.completions.length
    }

    public totalCompletionsActive() {
      return _.filter(this.props.report.generated.completions, (inmate:any) => inmate.active).length
    }

    public totalCompletionsActiveHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.active), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsSuspended() {
      return _.filter(this.props.report.generated.completions, (inmate:any) => inmate.suspended).length
    }

    public totalCompletionsSuspendedHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.suspended), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsQuarantined() {
      return _.filter(this.props.report.generated.completions, (inmate:any) => inmate.quarantined).length
    }

    public totalCompletionsQuarantinedHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.quarantined), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsMissing() {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.missing, 0)
    }

    public totalCompletionsMissingHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.missing), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsMissingUnique() {
      return _.filter(this.props.report.generated.completions, (inmate:any) => inmate.missing > 0).length
    }

    public totalCompletionsCompleted() {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.completed, 0)
    }

    public totalCompletionsCompletedHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.completed), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsCompletedUnique() {
      return _.filter(this.props.report.generated.completions, (inmate:any) => inmate.completed > 0).length
    }

    public totalCompletionsNotCompleted() {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.missing + inmate.incomplete + inmate.dropped + inmate.transferred + inmate.paroled + inmate.released + inmate.schedule + inmate.discipline + inmate.covid + inmate.noShow + inmate.reclassified, 0)
    }

    public totalCompletionsNotCompletedHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsNotCompletedUnique() {
      return _.filter(this.props.report.generated.completions, (inmate:any) => (inmate.missing > 0) || (inmate.incomplete > 0) || (inmate.dropped > 0) || (inmate.transferred > 0) || (inmate.paroled > 0) || (inmate.released > 0) || (inmate.schedule > 0) || (inmate.discipline > 0) || (inmate.covid > 0) || (inmate.noShow > 0) || (inmate.reclassified > 0)).length
    }

    public totalCompletionsContEd() {
      return _.reduce(this.props.report.generated.completions, (a4:number, inmate:any) => a4 + inmate.contEd, 0)
    }

    public totalCompletionsContEdHighlight() {
      var highlight = false
      if (_.any(_.filter(this.props.report.generated.completions, (inmate:any) => inmate.contEd), (inmate:any) => inmate.highlight || inmate.inmateHighlight)) {
        highlight = true
      }
      return highlight
    }

    public totalCompletionsContEdUnique() {
      return _.filter(this.props.report.generated.completions, (inmate:any) => inmate.contEd > 0).length
    }

    public render() {
        return (
          <Fragment>
            {this.props.report.loading || (this.props.report.ready && this.props.report.url.length == 0) ? <div className='pdf generating'>
              <Icon.Group>
                <Icon name='circle notch' color='grey' size='huge' loading  />
                <Icon name='file pdf outline' color='grey' size='big' />
              </Icon.Group>
              <div className='name'><b>Generating CSV...</b></div>
            </div> : null}
            {this.props.report.ready && this.props.report.url.length > 0 ? <a target='_window' href={this.props.report.url}>
              <div className='pdf ready'>
                <Icon name='file pdf outline' size='huge' />
                <div className='name'><b>Quarterly CSV</b></div>
              </div>
            </a> : null}
          </Fragment>
        )
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.quarterly, options: state.reports.options } },
  { ...StaffStore.actionCreators, ...ReportsStore.actionCreators }
)(QuarterlyCSV as any)
