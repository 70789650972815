import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as PanesStore from '../../store/reducers/panes'
import { format } from 'date-fns'
import DatePicker from 'react-datepicker'
import {
  Container,
  Form,
  Grid,
  Input,
  Select,
  Radio,
  Checkbox,
  TextArea,
  Button,
  Header,
  Divider,
  Icon,
  Table,
  Modal
} from 'semantic-ui-react'
const _ = require('underscore')

type InfoProps =
    { inmate: PanesStore.Inmate, num: 1 | 2, print: boolean } &
    PanesStore.PanesState &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class InmateCommunity extends React.PureComponent<InfoProps> {
    public componentDidMount() {
        this.props.fetchEducation(this.props.num, this.props.inmate.info.recordID)
    }

    public render() {
      return (
        <Container fluid>
          <div style={{display:'inline-block',marginTop:10,marginBottom:10}}>
            <Header as='h3'>Free Time Activities</Header>
          </div>
          <Form>
            <Grid>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column width={16}>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label='Free time activities'
                      placeholder='Free time activities'
                      options={[{ key: 0, text: "No free time", value: "noFreeTime" },{ key: 1, text: "Surf", value: "surf" },{ key: 2, text: "Fish/hunt", value: "fishHunt" },{ key: 3, text: "Hobby", value: "hobby" },{ key: 4, text: "Watch TV", value: "watchTV" },{ key: 5, text: "Sports/Workout", value: "sportsWorkout" },{ key: 6, text: "Read", value: "books" },{ key: 7, text: "Computers", value: "computers" },{ key: 8, text: "Other", value: "otherFTA" }]}
                      value={this.props.inmate.education.data.activities || []}
                      onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'activities', data.value as string[])}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                      multiple
                    />
                    <Form.Field
                      control={Input}
                      label='Other free time activity'
                      placeholder='Other free time activity'
                      name='otherFTAWhat'
                      value={this.props.inmate.education.data.otherFTAWhat || ''}
                      onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'otherFTAWhat', data.value.validate())}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                    />
                  </Form.Group>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Comment</Table.HeaderCell>
                        <Table.HeaderCell>Staff</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.education.data.freeNotes ? this.props.inmate.education.data.freeNotes.map((note:any, i:number) => (
                        <Table.Row key={i} onClick={() => this.props.openEditFreeNote(this.props.num, note)}>
                          <Table.Cell><div style={{whiteSpace:'normal'}}>{note.content}</div></Table.Cell>
                          <Table.Cell>{note.staffName}</Table.Cell>
                          <Table.Cell>{note.date}</Table.Cell>
                        </Table.Row>
                      )) : null}
                      <Table.Row>
                        <Table.Cell colSpan={2}><Input style={{width:'100%'}} value={this.props.inmate.education.data.freeNote||''} onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'freeNote', data.value.validate())} /></Table.Cell>
                        <Table.Cell style={{textAlign:'center'}}><Button positive size='mini' content='Add' style={{width:'100%'}} onClick={_.debounce(() => this.props.addActivitiesNote(this.props.num), 10000, true)} /></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          {this.props.print ? null : <Button positive content='Save' style={{marginTop:25}} onClick={_.debounce(() => this.props.saveCommunity(this.props.num), 10000, true)} />}
          <Divider />
          <div style={{display:'inline-block',marginTop:10,marginBottom:10}}>
            <Header as='h3'>Community Involvement</Header>
          </div>
          <Form>
            <Grid>
              <Grid.Row style={{paddingBottom:0}}>
                <Grid.Column width={16}>
                  <Form.Group>
                    <Form.Field
                      control={Select}
                      label='Licenses / registrations'
                      placeholder='Licenses / registrations'
                      options={[{ key: 0, text: "Library card", value: "libraryCard" },{ key: 1, text: "Registered to vote", value: "registeredToVote" },{ key: 2, text: "Current drivers license", value: "currentDriversLic" },{ key: 3, text: "License ever revoked", value: "licRevoked" }]}
                      value={this.props.inmate.education.data.registrations || []}
                      onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'registrations', data.value as string[])}
                      width={8}
                      className={this.props.print ? 'transparent' : ''}
                      multiple
                    />
                    <Form.Field
                      control={Input}
                      label='Year license expires'
                      placeholder='Year license expires'
                      name='currentDriversLicExp'
                      value={this.props.inmate.education.data.currentDriversLicExp || ''}
                      onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'currentDriversLicExp', data.value.validate())}
                      width={3}
                      className={this.props.print ? 'transparent' : ''}
                    />
                    <Form.Field
                      control={Select}
                      label='Alimony payment / child support'
                      placeholder='Alimony payment'
                      name='alimony'
                      options={[{ key: "U", text: "Unknown", value: "U" },{ key: "N", text: "No", value: "N" }, { key: "Y", text: "Yes", value: "Y" }]}
                      value={this.props.inmate.education.data.alimony}
                      onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'alimony', data.value as string)}
                      width={5}
                      className={this.props.print ? 'transparent' : ''}
                    />
                  </Form.Group>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Table compact celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Comment</Table.HeaderCell>
                        <Table.HeaderCell>Staff</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {this.props.inmate.education.data.communityNotes ? this.props.inmate.education.data.communityNotes.map((note:any, i:number) => (
                        <Table.Row key={i} onClick={() => this.props.openEditCommunityNote(this.props.num, note)}>
                          <Table.Cell><div style={{whiteSpace:'normal'}}>{note.content}</div></Table.Cell>
                          <Table.Cell>{note.staffName}</Table.Cell>
                          <Table.Cell>{note.date}</Table.Cell>
                        </Table.Row>
                      )) : null}
                      <Table.Row>
                        <Table.Cell colSpan={2}><Input style={{width:'100%'}} value={this.props.inmate.education.data.communityNote||''} onChange={(e:any, data:any) => this.props.editEducationField(this.props.num, 'communityNote', data.value.validate())} /></Table.Cell>
                        <Table.Cell style={{textAlign:'center'}}><Button positive size='mini' content='Add' style={{width:'100%'}} onClick={_.debounce(() => this.props.addCommunityNote(this.props.num), 10000, true)} /></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
          {this.props.print ? null : <Button positive content='Save' style={{marginTop:25}} onClick={_.debounce(() => this.props.saveCommunity(this.props.num), 10000, true)} />}
          <Modal
            open={this.props.inmate.modals.editFreeNote.open}
            onClose={() => this.props.toggleEditFreeNote(this.props.num)}
            size='tiny'
          >
            <Modal.Header content='Edit Comment' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    name='content'
                    value={this.props.inmate.modals.editFreeNote.data.content || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editFreeNote', 'content', data.value)}
                    rows={3}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditFreeNote(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeFreeNote(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.editFreeNote(this.props.num), 10000, true)}>Edit</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.inmate.modals.editCommunityNote.open}
            onClose={() => this.props.toggleEditCommunityNote(this.props.num)}
            size='tiny'
          >
            <Modal.Header content='Edit Comment' />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    name='content'
                    value={this.props.inmate.modals.editCommunityNote.data.content || ''}
                    onChange={(e:any, data:any) => this.props.modalDataChange(this.props.num, 'editCommunityNote', 'content', data.value)}
                    rows={3}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={() => this.props.toggleEditCommunityNote(this.props.num)}>Close</Button>
              <Button color='red' onClick={_.debounce(() => this.props.removeCommunityNote(this.props.num), 10000, true)}>Remove</Button>
              <Button color='green' onClick={_.debounce(() => this.props.editCommunityNote(this.props.num), 10000, true)}>Edit</Button>
            </Modal.Actions>
          </Modal>
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { inmate: ownProps.inmate, num: ownProps.num, print: ownProps.print } },
  PanesStore.actionCreators
)(InmateCommunity as any)