import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import * as DrawerStore from '../../store/reducers/drawer'
import * as PanesStore from '../../store/reducers/panes'
import AccessLevels from './AccessLevels'
import AccessPresets from './AccessPresets'
import {
  Container,
  Table,
  Modal,
  Form,
  Button,
  Checkbox,
  Input,
  Select,
  Radio,
  Popup,
  Label,
  Tab
} from 'semantic-ui-react'
const _ = require('underscore')

type AccessProps =
    { access: any, tab: string } &
    typeof AdminStore.actionCreators &
    typeof DrawerStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class AccessManagement extends React.PureComponent<AccessProps> {
    public componentDidMount() {
        this.props.fetchAccess()
    }

    public render() {
      var panes = [
        { menuItem: 'Access Levels', render: () => <Tab.Pane><AccessLevels /></Tab.Pane> },
        { menuItem: 'Presets', render: () => <Tab.Pane><AccessPresets /></Tab.Pane> }
      ]

      return (
        <Tab menu={{ secondary: true }} panes={panes} activeIndex={this.props.access.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchAccessTab(activeIndex) }} />
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { access: state.admin.access, tab: state.drawer.tab } },
  { ...DrawerStore.actionCreators, ...PanesStore.actionCreators, ...AdminStore.actionCreators }
)(AccessManagement as any)