import React, { Fragment } from 'react'
import { format } from 'date-fns'
import {
  Image,
  Container,
  Table
} from 'semantic-ui-react'
const _ = require('underscore')

type ReportProps =
    { report: any, options: any, settings: any }

class ClassSchedule extends React.PureComponent<ReportProps> {
    public render() {
        return (
          <html lang="en">
            <head>
              <script type='text/javascript' dangerouslySetInnerHTML={{__html: `function ready() {
                return (document.fonts.check('1em Roboto') || document.fonts.check('bold 1em Roboto'))
              }`}} />
            </head>
            <body style={{fontSize:'1.2em',lineHeight:'1.2em',fontFamily:'Roboto',backgroundColor:'white !important'}}>
              {this.report()}
            </body>
          </html>
        )
    }

    public report() {
        let data = this.props.report.data

        const dayrow = (schedules:any, day:number, row:number) => {
          var schedules = _.filter(schedules, (schedule:any) => { return schedule.dayOfWeek == day })
          return schedules[row]
        }

        const weekday = (schedules:any, day:number) => {
          return _.filter(schedules, (schedule:any) => { return schedule.dayOfWeek == day })
        }

        const daymax = (schedules:any) => {
          var mon = weekday(schedules, 1).length
          var tue = weekday(schedules, 2).length
          var wed = weekday(schedules, 3).length
          var thu = weekday(schedules, 4).length
          var fri = weekday(schedules, 5).length
          return _.max([mon,tue,wed,thu,fri])
        }

        return (
            <Container fluid>
              <div style={{flexDirection:'column'}}>
                <div>
                  <div style={{flexDirection:'row'}}>
                    <Image src="https://sms.psd-hi.com/assets/HawaiiSeal.png" style={{float:'left',width:70}} />
                    <Image src="https://sms.psd-hi.com/assets/RightGraphic.jpg" style={{float:'right',width:70}} />
                    <div style={{flexDirection:'column',textAlign:'center',fontWeight:700}}>
                      <div>State of Hawai‘i</div>
                      <div>{this.props.settings.deptName}</div>
                      <div>Education Services</div>
                      <div>Notification of Enrollment</div>
                      <div>{format(new Date(), "MM/dd/yyyy")}</div>
                    </div>
                  </div>
                  <div style={{marginTop:40,flexDirection:'column'}}>
                    <div>
                      <span style={{marginRight:5,fontWeight:700}}>To:</span>
                      <span>{data.inmate.lastName + ", " + data.inmate.firstName}{data.inmate.housing ? " - Housing: " + data.inmate.housing : " - Housing: _____"}</span>
                    </div>
                    <div style={{marginTop:10}}>
                      <span style={{marginRight:5,fontWeight:700}}>From:</span>
                      <span>Education Unit</span>
                    </div>
                    <div style={{marginTop:20}}>
                      <span>You are scheduled to attend the following classes which will be held in the Education Unit on the dates shown.</span>
                    </div>
                    <div style={{marginTop:20}}>
                      {data.inmate.times.length == 0 ? <div style={{fontWeight:700}}>No classes scheduled.</div> : <Fragment>
                        <div style={{fontWeight:700}}>Here is your schedule:</div>
                        <Table compact celled striped definition unstackable style={{fontSize:'0.9em'}}>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell width={1}></Table.HeaderCell>
                              <Table.HeaderCell width={3}>Monday</Table.HeaderCell>
                              <Table.HeaderCell width={3}>Tuesday</Table.HeaderCell>
                              <Table.HeaderCell width={3}>Wednesday</Table.HeaderCell>
                              <Table.HeaderCell width={3}>Thursday</Table.HeaderCell>
                              <Table.HeaderCell width={3}>Friday</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {data.inmate.times.map((time:any) => Array.from(Array(daymax(time.schedules))).map((_, i) => (
                              <Table.Row key={i}>
                                <Table.Cell><span style={{whiteSpace:'nowrap'}}>{time.time}</span></Table.Cell>
                                <Table.Cell style={{fontSize:'0.9em'}}>{dayrow(time.schedules, 1, i) ? dayrow(time.schedules, 1, i).className : null}</Table.Cell>
                                <Table.Cell style={{fontSize:'0.9em'}}>{dayrow(time.schedules, 2, i) ? dayrow(time.schedules, 2, i).className : null}</Table.Cell>
                                <Table.Cell style={{fontSize:'0.9em'}}>{dayrow(time.schedules, 3, i) ? dayrow(time.schedules, 3, i).className : null}</Table.Cell>
                                <Table.Cell style={{fontSize:'0.9em'}}>{dayrow(time.schedules, 4, i) ? dayrow(time.schedules, 4, i).className : null}</Table.Cell>
                                <Table.Cell style={{fontSize:'0.9em'}}>{dayrow(time.schedules, 5, i) ? dayrow(time.schedules, 5, i).className : null}</Table.Cell>
                              </Table.Row>
                            )))}
                          </Table.Body>
                        </Table>
                      </Fragment>}
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          )
    }
}

export default ClassSchedule