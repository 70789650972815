import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as ClassesStore from '../../store/reducers/classes'
import * as PanesStore from '../../store/reducers/panes'
import {
  Table,
  Popup,
  Label,
  Icon,
  Dropdown
} from 'semantic-ui-react'
const _ = require('underscore')

type ClassProps =
    { cert: any, filters: ClassesStore.ClassFilters, dates: boolean, tab: string, mode: string } &
    typeof DrawerStore.actionCreators &
    typeof ClassesStore.actionCreators &
    typeof PanesStore.actionCreators &
    WithRouterProps<{}>

class CertificateRow extends React.PureComponent<ClassProps> {
    public render() {
        return (
          <Table.Row id={`cert${this.props.cert.recordID}`}>
            <Table.Cell>{this.props.cert.facilityAbbr}</Table.Cell>
            <Table.Cell>
              {this.props.mode == 'dual' ? <Popup
                hideOnScroll
                on='click'
                trigger={<div className='link' style={{whiteSpace:'normal'}}>{this.props.cert.className}</div>}
              >
                <Popup.Header>Open in pane</Popup.Header>
                <Popup.Content>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchClassTranscripts(1, this.props.cert.classID); this.props.toggleDrawer(this.props.tab) }}>1</Label>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchClassTranscripts(2, this.props.cert.classID); this.props.toggleDrawer(this.props.tab) }}>2</Label>
                </Popup.Content>
              </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.fetchClassTranscripts(this.props.mode == 'left' ? 1 : 2, this.props.cert.classID); this.props.toggleDrawer(this.props.tab) }}>{this.props.cert.className}</div>}
            </Table.Cell>
            <Table.Cell>
              {this.props.mode == 'dual' ? <Popup
                hideOnScroll
                on='click'
                trigger={<div className='link' style={{whiteSpace:'normal'}}>{this.props.cert.inmateName}</div>}
              >
                <Popup.Header>Open in pane</Popup.Header>
                <Popup.Content>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(1, this.props.cert.inmateID); this.props.toggleDrawer(this.props.tab) }}>1</Label>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmate(2, this.props.cert.inmateID); this.props.toggleDrawer(this.props.tab) }}>2</Label>
                </Popup.Content>
              </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.fetchInmate(this.props.mode == 'left' ? 1 : 2, this.props.cert.inmateID); this.props.toggleDrawer(this.props.tab) }}>{this.props.cert.inmateName}</div>}
            </Table.Cell>
            <Table.Cell>{this.props.cert.certDate}</Table.Cell>
            <Table.Cell>
              {this.props.mode == 'dual' ? <Popup
                hideOnScroll
                on='click'
                trigger={<div className='link' style={{whiteSpace:'normal'}}>{this.props.cert.certName}</div>}
              >
                <Popup.Header>Open in pane</Popup.Header>
                <Popup.Content>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmateAndEditTranscript(1, this.props.cert.inmateID, this.props.cert.recordID); this.props.toggleDrawer(this.props.tab) }}>1</Label>
                  <Label circular onClick={() => { window.dispatchEvent(new CustomEvent('scroll')); this.props.fetchInmateAndEditTranscript(2, this.props.cert.inmateID, this.props.cert.recordID); this.props.toggleDrawer(this.props.tab) }}>2</Label>
                </Popup.Content>
              </Popup> : <div className='link' style={{whiteSpace:'normal'}} onClick={() => { this.props.fetchInmateAndEditTranscript(this.props.mode == 'left' ? 1 : 2, this.props.cert.inmateID, this.props.cert.recordID); this.props.toggleDrawer(this.props.tab) }}>{this.props.cert.certName}</div>}
            </Table.Cell>
          </Table.Row>
        )
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { cert: ownProps.cert, filters: state.classes.filters, dates: state.classes.dates, tab: state.drawer.tab, mode: state.panes.mode } },
  { ...DrawerStore.actionCreators, ...ClassesStore.actionCreators, ...PanesStore.actionCreators }
)(CertificateRow as any)