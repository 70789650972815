import { Action, Reducer } from 'redux'
import { AppThunkAction } from '../'
import { ToggleModalAction, SetModalTabAction } from './landing'
import { ToggleDimmerAction, ToggleDrawerAction } from './drawer'
import { connect, disconnect, send } from '@giantmachines/redux-websocket'
import agent from '../../agent'
import { history } from '../../store/configureStore'
import { toast } from 'react-toastify'
const _ = require('underscore')

type Staff = {
    recordID: number,
    themeID: number,
    firstName: string,
    lastName: string,
    displayName: string,
    username: string,
    email: string,
    token: string,
    admin: boolean,
    supervisor: boolean,
    programAdmin: boolean,
    facilities: string[],
    searches: any[],
    theme: any
}

interface LogOutAction { type: 'LOG_OUT' }
interface StaffActivityAction { type: 'STAFF_ACTIVITY', timestamp: number }
interface FetchThemesAction { type: 'FETCH_PROFILE_THEMES', themes: any[] }
interface SetStaffAction { type: 'SET_STAFF', staff: Staff }
interface SetSettingsAction { type: 'SET_SETTINGS', settings: any }
interface FetchSettingsAction { type: 'FETCH_SETTINGS', settings: any }
interface FetchStaffAction { type: 'FETCH_DASHBOARD_STAFF' }
interface FetchFacilitiesAction { type: 'FETCH_DASHBOARD_STAFF_FACILITIES', facilities: { [key:string]: string | number }[] }
export interface FetchStaffFavoritesAction { type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: { [key:string]: string | number }[] }
interface AuthenticateAction { type: 'AUTHENTICATE' }
interface UpdateLatestAction { type: 'UPDATE_LATEST', latest: number }
interface UpdateProfileAction { type: 'UPDATE_PROFILE', field: string, value: any }
interface UpdatePasswordAction { type: 'UPDATE_RESET_PASSWORD', password: string }
interface ValidateResetAction { type: 'VALIDATE_RESET_CODE' }
interface UpdatesMessageAction { type: 'UPDATES::MESSAGE', payload: any }

type KnownAction = UpdatesMessageAction | FetchThemesAction | StaffActivityAction | ValidateResetAction | UpdateLatestAction | UpdatePasswordAction | UpdateProfileAction | ToggleDimmerAction | ToggleDrawerAction | ToggleModalAction | SetModalTabAction | LogOutAction | SetStaffAction | SetSettingsAction | FetchStaffAction | FetchSettingsAction | FetchFacilitiesAction | FetchStaffFavoritesAction | AuthenticateAction

export interface StaffState {
    loggedIn: boolean | null,
    staff: Staff | null,
    profile: any,
    settings: any,
    latest: number,
    lastActivity: number,
    facilities: { [key:string]: string | number }[],
    favorites: { [key:string]: string | number }[],
    options: {
        themes: any[]
    },
    reset: {
        auth: boolean,
        password: string
    }
}

export const actionCreators = {
    socketConnect: () => (connect(`${window.location.protocol.startsWith('https') ? 'wss' : 'ws'}://${window.location.host}/staff/updates`, 'UPDATES') as any),
    socketDisconnect: () => (disconnect('UPDATES') as any),
    activitys: () => ({ type: 'STAFF_ACTIVITY', timestamp: Date.now() } as StaffActivityAction),
    updateLatest: (latest:number) => ({ type: 'UPDATE_LATEST', latest: latest } as UpdateLatestAction),
    updateProfile: (field:string, value:any) => ({ type: 'UPDATE_PROFILE', field: field, value: value} as UpdateProfileAction),
    setReset: (password: string) => ({ type: 'UPDATE_RESET_PASSWORD', password: password } as UpdatePasswordAction),
    logOut: (): LogOutAction => {
        localStorage.clear()
        if (window.location.pathname != '/' && window.location.pathname != '/auth/google' && window.location.pathname.substring(0,7) != '/reset/')
            history.push('/')
        return { type: 'LOG_OUT' }
    },
    update: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let staff = getState().staff.staff
        if (staff) dispatch(send({ authorization: staff.token }, 'UPDATES') as any)
    },
    activity: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().staff
        let now = ((Date.now() + 500) / 1000) | 0
        let seconds = now - state.lastActivity
        if (seconds >= 300) {
            dispatch({ type: 'STAFF_ACTIVITY', timestamp: now } as StaffActivityAction)
        } else if (seconds >= 60) {
            dispatch({ type: 'STAFF_ACTIVITY', timestamp: now } as StaffActivityAction)
            await agent.Auth.staffActivity(seconds)
        }
    },
    setStaff: (staff: Staff): AppThunkAction<KnownAction> => async (dispatch) => {
        localStorage.setItem('jwt', staff.token)
        dispatch({ type: 'SET_STAFF', staff: staff })
        const { facilityData } = await agent.Auth.fetchFacilities()
        dispatch({ type: 'FETCH_DASHBOARD_STAFF_FACILITIES', facilities: facilityData })
        const { favoriteData } = await agent.Auth.fetchFavorites()
        dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
    },
    fetchSettings: (): AppThunkAction<KnownAction> => async (dispatch) => {
        const { settings } = await agent.Data.fetchAppSettings()
        dispatch({ type: 'FETCH_SETTINGS', settings: settings } as FetchSettingsAction)
    },
    fetchThemes: (): AppThunkAction<KnownAction> => async (dispatch) => {
        const { themeData } = await agent.Data.fetchThemes()
        dispatch({ type: 'FETCH_PROFILE_THEMES', themes: themeData } as FetchThemesAction)
    },
    fetchStaff: (): AppThunkAction<KnownAction> => async (dispatch) => {
        const { staff, latest } = await agent.Auth.fetchStaff()
        if (staff) {
            localStorage.setItem('jwt', staff.token)
            dispatch(send({ authorization: staff.token }, 'UPDATES') as any)
            dispatch({ type: 'SET_STAFF', staff: staff })
            dispatch({ type: 'UPDATE_LATEST', latest: latest } as UpdateLatestAction)
            const { facilityData } = await agent.Auth.fetchFacilities()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FACILITIES', facilities: facilityData })
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
            if ((window as any)._chatlio && (window.location.pathname == "/" || window.location.pathname.startsWith("/panes"))) {
                var data:any = { name: staff.displayName, email: staff.email }
                if (facilityData.length > 0) {
                    data.facilities = _.map(facilityData, (facility:any) => { return `${facility.abbreviation}: ${facility.name}` }).join(', ')
                }
                (window as any)._chatlio.identify(staff.username, data);
                (window as any)._chatlio.show();
            }
            if (window.location.pathname == "/" || window.location.pathname.startsWith("/panes")) {
                (window as any)._mfq.push(["identify", staff.username]);
                (window as any)._mfq.push(["setVariable", "user", staff.username]);
            }
            if (staff.wallpaper) {
                document.body.style.backgroundImage = `url(https://sms.psd-hi.com/assets/wallpaper/${staff.wallpaper}.png)`
            } else {
                document.body.style.backgroundImage = ''
            }
        } else {
            dispatch(actionCreators.logOut())
        }
    },
    authenticate: (username: string, password: string): AppThunkAction<KnownAction> => async (dispatch) => {
        const { error } = await agent.Auth.authenticate(username, password)
        if (error == null) {
            toast.info('An email with your 2FA code has been sent')
            dispatch({ type: 'SET_LOGIN_TAB', tab: 'code' } as SetModalTabAction)
        } else {
            toast.error(error, { autoClose: false })
        }
    },
    authenticate2: (username: string, password: string, code: string): AppThunkAction<KnownAction> => async (dispatch) => {
        const { error, staff } = await agent.Auth.authenticate2(username, password, code)
        if (error == null) {
            let params = new URLSearchParams()
            params.append('drawer', 'dashboard')
            localStorage.setItem('jwt', staff.token)
            dispatch({ type: 'TOGGLE_MODAL', open: false } as ToggleModalAction)
            dispatch({ type: 'SET_STAFF', staff: staff })
            const { facilityData } = await agent.Auth.fetchFacilities()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FACILITIES', facilities: facilityData })
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
            setTimeout(() => {
                dispatch({ type: 'TOGGLE_DIMMER' } as ToggleDimmerAction)
                dispatch({ type: 'TOGGLE_DRAWER', tab: 'dashboard' } as ToggleDrawerAction)
                history.replace({ pathname: history.location.pathname, search: params.toString() })
            }, 1000)
            if ((window as any)._chatlio && (window.location.pathname == "/" || window.location.pathname.startsWith("/panes"))) {
                var data:any = { name: staff.displayName, email: staff.email }
                if (facilityData.length > 0) {
                    data.facilities = _.map(facilityData, (facility:any) => { return `${facility.abbreviation}: ${facility.name}` }).join(', ')
                }
                (window as any)._chatlio.identify(staff.username, data);
                (window as any)._chatlio.show();
            }
            if (window.location.pathname == "/" || window.location.pathname.startsWith("/panes")) {
                (window as any)._mfq.push(["identify", staff.username]);
                (window as any)._mfq.push(["setVariable", "user", staff.username]);
            }
            if (staff.wallpaper) {
                document.body.style.backgroundImage = `url(https://sms.psd-hi.com/assets/wallpaper/${staff.wallpaper}.png)`
            } else {
                document.body.style.backgroundImage = ''
            }
        } else {
            toast.error(error, { autoClose: false })
        }
    },
    validateReset: (code: string): AppThunkAction<KnownAction> => async (dispatch) => {
        const { error } = await agent.Auth.validateReset(code)
        if (error == null) {
            dispatch({ type: 'VALIDATE_RESET_CODE' } as ValidateResetAction)
        } else {
            toast.error(error, { autoClose: false })
        }
    },
    resetPassword: (code: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().staff.reset
        if (state.password.length > 5) {
            const { error } = await agent.Auth.resetPassword(code, state.password)
            if (error == null) {
                toast.success('Password reset successfully')
                setTimeout(() => {
                    window.location.assign('/')
                }, 4000)
            } else {
                toast.error(error, { autoClose: false })
            }
        }
    },
    forgotPassword: (username: string): AppThunkAction<KnownAction> => async (dispatch) => {
        const { error } = await agent.Auth.forgotPassword(username)
        if (error == null) {
            toast.success('A reset link has been sent to your email')
            dispatch({ type: 'SET_LOGIN_TAB', tab: 'login' } as SetModalTabAction)
        } else {
            toast.error(error, { autoClose: false })
        }
    },
    saveProfile: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        let state = getState().staff
        const { error } = await agent.Auth.saveProfile(state.profile)
        if (error == null) {
            toast.success('Profile saved')
            const { staff } = await agent.Auth.fetchStaff()
            dispatch({ type: 'SET_STAFF', staff: staff })
        } else {
            toast.error(error, { autoClose: false })
        }
    },
    authGoogle: (code: string): AppThunkAction<KnownAction> => async (dispatch) => {
        const { error, staff } = await agent.Auth.authGoogle(code)
        if (error == null) {
            localStorage.setItem('jwt', staff.token)
            dispatch({ type: 'SET_STAFF', staff: staff })
            const { facilityData } = await agent.Auth.fetchFacilities()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FACILITIES', facilities: facilityData })
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
            if ((window as any)._chatlio && (window.location.pathname == "/" || window.location.pathname.startsWith("/panes"))) {
                var data:any = { name: staff.displayName, email: staff.email }
                if (facilityData.length > 0) {
                    data.facilities = _.map(facilityData, (facility:any) => { return `${facility.abbreviation}: ${facility.name}` }).join(', ')
                }
                (window as any)._chatlio.identify(staff.username, data);
                (window as any)._chatlio.show();
            }
            if (window.location.pathname == "/" || window.location.pathname.startsWith("/panes")) {
                (window as any)._mfq.push(["identify", staff.username]);
                (window as any)._mfq.push(["setVariable", "user", staff.username]);
            }
        } else {
            toast.error(error, { autoClose: false })
        }
        history.push('/')
    },
    loginAs: (recordID: number): AppThunkAction<KnownAction> => async (dispatch) => {
        const { error, staff } = await agent.Auth.loginAs(recordID)
        if (error == null) {
            localStorage.setItem('jwt', staff.token)
            dispatch({ type: 'SET_STAFF', staff: staff })
            const { facilityData } = await agent.Auth.fetchFacilities()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FACILITIES', facilities: facilityData })
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
            window.location.assign('/')
        } else {
            toast.error(error, { autoClose: false })
        }
    },
    unfavorite: (recordID: number, type: string): AppThunkAction<KnownAction> => async (dispatch) => {
        const { error } = await agent.Admin.unfavorite(recordID)
        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success(`${type.charAt(0).toUpperCase() + type.slice(1)} unfavorited`)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    },
    favorite: (recordID: number, type: string): AppThunkAction<KnownAction> => async (dispatch) => {
        switch (type) {
        case 'inmate':
          var { error } = await agent.Inmates.favorite(recordID)
          break;
        case 'class':
          var { error } = await agent.Classes.favorite(recordID)
          break;
        case 'template':
          var { error } = await agent.Admin.favoriteTemplate(recordID)
          break;
        }

        if (error != null) {
            toast.error(error, { autoClose: false })
        } else {
            toast.success(`${type.charAt(0).toUpperCase() + type.slice(1)} favorited`)
            const { favoriteData } = await agent.Auth.fetchFavorites()
            dispatch({ type: 'FETCH_DASHBOARD_STAFF_FAVORITES', favorites: favoriteData } as FetchStaffFavoritesAction)
        }
    }
}

export const reducer: Reducer<StaffState> = (state: StaffState | undefined, incomingAction: Action): StaffState => {
    if (state === undefined) {
        return { loggedIn: null, staff: null, profile: { themeID: null }, settings: { deptName: '' }, facilities: [], favorites: [], options: { themes: [] }, latest: 0, lastActivity: ((Date.now() + 500) / 1000) | 0, reset: { auth: false, password: '' } }
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'UPDATES::MESSAGE':
            const payload = action.payload.message
            return {
                ...state,
                latest: payload.latest
            }
        case 'LOG_OUT':
            return { ...state, loggedIn: false, staff: null, facilities: [] }
        case 'SET_STAFF':
            return { ...state, loggedIn: true, staff: action.staff, profile: { themeID: action.staff.themeID } }
        case 'STAFF_ACTIVITY':
            return { ...state, lastActivity: action.timestamp }
        case 'FETCH_SETTINGS':
            return { ...state, settings: action.settings }
            case 'FETCH_PROFILE_THEMES':
            return { ...state, options: { ...state.options, themes: _.map(action.themes, (theme:any) => { return { key: theme.recordID, value: theme.recordID, text: theme.name } }) } }
        case 'FETCH_DASHBOARD_STAFF_FACILITIES':
            return { ...state, facilities: action.facilities }
        case 'FETCH_DASHBOARD_STAFF_FAVORITES':
            return { ...state, favorites: action.favorites }
        case 'UPDATE_LATEST':
            return { ...state, latest: action.latest }
        case 'UPDATE_PROFILE':
            return { ...state, profile: { ...state.profile, [action.field]: action.value } }
        case 'UPDATE_RESET_PASSWORD':
            return { ...state, reset: { ...state.reset, password: action.password } }
        case 'VALIDATE_RESET_CODE':
            return { ...state, reset: { ...state.reset, auth: true } }
        default:
            return state
    }
}
