import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import SurveyList from './SurveyList'
import SurveyQuestions from './SurveyQuestions'
import SurveyOptions from './SurveyOptions'
import SurveyOptionGroups from './SurveyOptionGroups'
import {
  Container,
  Tab,
  Form,
  Input,
  Select
} from 'semantic-ui-react'
const _ = require('underscore')

type SurveyProps =
    { surveys: any } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class Surveys extends React.PureComponent<SurveyProps> {
    public componentDidMount() {
        this.props.fetchSurveys()
    }

    public render() {
      var panes = [
        { menuItem: 'Surveys', render: () => <Tab.Pane><SurveyList /></Tab.Pane> }
      ]

      if (this.props.surveys.data.length > 0) {
        panes.push({ menuItem: 'Questions', render: () => <Tab.Pane><SurveyQuestions /></Tab.Pane> })
      }

      panes.push({ menuItem: 'Option Groups', render: () => <Tab.Pane><SurveyOptionGroups /></Tab.Pane> })

      if (this.props.surveys.groups.data.length > 0) {
        panes.push({ menuItem: 'Options', render: () => <Tab.Pane><SurveyOptions /></Tab.Pane> })
      }

      return (
        <Container fluid>
          <Tab menu={{ secondary: true }} panes={panes} activeIndex={this.props.surveys.index} onTabChange={(e, { activeIndex }) => { if (typeof activeIndex == 'number') this.props.switchSurveysTab(activeIndex) }} />
        </Container>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { surveys: state.admin.surveys } },
  AdminStore.actionCreators
)(Surveys as any)