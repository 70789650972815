import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { renderToString } from 'react-dom/server'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import { pdfshift } from '../../../agent'
import * as ReportsStore from '../../../store/reducers/reports'
import Utilization from '../shift/Utilization'
import Footer from '../shift/ReportFooter'
import {
  Icon
} from 'semantic-ui-react'

const css = require("!!css-loader!../../../custom.css");

type ReportProps =
    { report: any, options: any, settings: any, connected: boolean } &
    typeof ReportsStore.actionCreators &
    WithRouterProps<{}>

class UtilizationPDF extends React.PureComponent<ReportProps> {
    public componentDidUpdate(prevProps:any) {
      if (!prevProps.report.ready && this.props.report.ready) {
        const pdf = renderToString(<Utilization report={this.props.report} options={this.props.options} settings={this.props.settings} />);
        const footer = renderToString(<Footer report={this.props.report} options={this.props.options} settings={this.props.settings} />);

        pdfshift({
            source: pdf,
            footer: {
              source: footer,
              height: 70
            },
            margin: {
              top: 30,
              bottom: 0,
              left: 30,
              right: 30
            },
            landscape: true,
            css: css.default.toString(),
            format: 'Letter',
            wait_for: 'ready',
            filename: 'utilization.pdf'
        }).then((response:any) => {
          this.props.setURL('utilization', response.data.url)
        })
      }
    }

    public render() {
        return (
          <Fragment>
            {!this.props.connected ? <div className='pdf'>
              <Icon.Group>
                <Icon name='warning sign' color='red' size='huge'  />
              </Icon.Group>
              <div className='name' style={{textAlign:'center'}}><b>Error</b></div>
            </div> : null}
            {this.props.connected && (this.props.report.loading || (this.props.report.ready && this.props.report.url.length == 0)) ? <div className='pdf generating'>
              <Icon.Group>
                <Icon name='circle notch' color='grey' size='huge' loading  />
                <Icon name='file pdf outline' color='grey' size='big' />
              </Icon.Group>
              <div className='name'><b>Generating PDF...</b></div>
            </div> : null}
            {this.props.connected && (this.props.report.ready && this.props.report.url.length > 0) ? <a target='_window' href={this.props.report.url}>
              <div className='pdf ready'>
                <Icon name='file pdf outline' size='huge' />
                <div className='name'><b>Classroom Utilization PDF</b></div>
              </div>
            </a> : null}
          </Fragment>
        )
    }
}

export default connect(
  (state: ApplicationState) => { return { report: state.reports.reports.utilization, options: state.reports.options, settings: state.staff.settings, connected: state.reports.connected } },
  ReportsStore.actionCreators
)(UtilizationPDF as any)