import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../../withRouter'
import { ApplicationState } from '../../../store'
import * as StaffStore from '../../../store/reducers/staff'
import * as ReportsStore from '../../../store/reducers/reports'
import {
  Dimmer,
  Loader,
  Table,
  Container
} from 'semantic-ui-react'
const qs = require('qs')
const _ = require('underscore')

type ReportProps = { report: any }

class RecidivismGlobalFacilities extends React.PureComponent<ReportProps> {
    public render() {
      let params = this.props.report.data
      let data = this.props.report.generated

      return (
        <Fragment>
          {_.map(data.facilities, (facility:any) => <div key={facility.abbreviation}>
            <div style={{textAlign:'center',marginTop:25,marginBottom:25}}>
              <div style={{fontWeight:700,fontSize:16}}>Global - {facility.abbreviation}</div>
            </div>
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              <div style={{flexBasis:`40%`}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Length of Stay and Gender</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraGenders.male.stayLengths.lengthUnder3M.recidivism / facility.extraGenders.male.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.stayLengths.lengthUnder3M.recidivism / facility.extraGenders.male.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraGenders.male.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraGenders.female.stayLengths.lengthUnder3M.recidivism / facility.extraGenders.female.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.stayLengths.lengthUnder3M.recidivism / facility.extraGenders.female.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraGenders.female.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraGenders.male.stayLengths.length3To6M.recidivism / facility.extraGenders.male.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.stayLengths.length3To6M.recidivism / facility.extraGenders.male.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraGenders.male.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraGenders.female.stayLengths.length3To6M.recidivism / facility.extraGenders.female.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.stayLengths.length3To6M.recidivism / facility.extraGenders.female.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraGenders.female.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraGenders.male.stayLengths.length6To12M.recidivism / facility.extraGenders.male.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.stayLengths.length6To12M.recidivism / facility.extraGenders.male.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraGenders.male.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraGenders.female.stayLengths.length6To12M.recidivism / facility.extraGenders.female.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.stayLengths.length6To12M.recidivism / facility.extraGenders.female.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraGenders.female.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraGenders.male.stayLengths.length1To2Y.recidivism / facility.extraGenders.male.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.stayLengths.length1To2Y.recidivism / facility.extraGenders.male.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraGenders.male.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraGenders.female.stayLengths.length1To2Y.recidivism / facility.extraGenders.female.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.stayLengths.length1To2Y.recidivism / facility.extraGenders.female.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraGenders.female.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraGenders.male.stayLengths.length2To5Y.recidivism / facility.extraGenders.male.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.stayLengths.length2To5Y.recidivism / facility.extraGenders.male.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraGenders.male.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraGenders.female.stayLengths.length2To5Y.recidivism / facility.extraGenders.female.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.stayLengths.length2To5Y.recidivism / facility.extraGenders.female.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraGenders.female.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraGenders.male.stayLengths.length5To10Y.recidivism / facility.extraGenders.male.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.stayLengths.length5To10Y.recidivism / facility.extraGenders.male.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraGenders.male.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraGenders.female.stayLengths.length5To10Y.recidivism / facility.extraGenders.female.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.stayLengths.length5To10Y.recidivism / facility.extraGenders.female.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraGenders.female.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
              <div style={{flexBasis:`40%`}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Length Between Returns and Gender</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Under 3 Months</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraGenders.male.outLengths.lengthUnder3M.recidivism / facility.extraGenders.male.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.outLengths.lengthUnder3M.recidivism / facility.extraGenders.male.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraGenders.male.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraGenders.female.outLengths.lengthUnder3M.recidivism / facility.extraGenders.female.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.outLengths.lengthUnder3M.recidivism / facility.extraGenders.female.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraGenders.female.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>3 - 6 Months</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraGenders.male.outLengths.length3To6M.recidivism / facility.extraGenders.male.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.outLengths.length3To6M.recidivism / facility.extraGenders.male.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraGenders.male.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraGenders.female.outLengths.length3To6M.recidivism / facility.extraGenders.female.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.outLengths.length3To6M.recidivism / facility.extraGenders.female.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraGenders.female.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>6 - 12 Months</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraGenders.male.outLengths.length6To12M.recidivism / facility.extraGenders.male.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.outLengths.length6To12M.recidivism / facility.extraGenders.male.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraGenders.male.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraGenders.female.outLengths.length6To12M.recidivism / facility.extraGenders.female.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.outLengths.length6To12M.recidivism / facility.extraGenders.female.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraGenders.female.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>1 - 2 Years</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraGenders.male.outLengths.length1To2Y.recidivism / facility.extraGenders.male.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.outLengths.length1To2Y.recidivism / facility.extraGenders.male.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraGenders.male.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraGenders.female.outLengths.length1To2Y.recidivism / facility.extraGenders.female.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.outLengths.length1To2Y.recidivism / facility.extraGenders.female.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraGenders.female.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>2 - 5 Years</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraGenders.male.outLengths.length2To5Y.recidivism / facility.extraGenders.male.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.outLengths.length2To5Y.recidivism / facility.extraGenders.male.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraGenders.male.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraGenders.female.outLengths.length2To5Y.recidivism / facility.extraGenders.female.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.outLengths.length2To5Y.recidivism / facility.extraGenders.female.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraGenders.female.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>5 - 10 Years</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraGenders.male.outLengths.length5To10Y.recidivism / facility.extraGenders.male.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.outLengths.length5To10Y.recidivism / facility.extraGenders.male.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraGenders.male.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraGenders.female.outLengths.length5To10Y.recidivism / facility.extraGenders.female.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.outLengths.length5To10Y.recidivism / facility.extraGenders.female.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraGenders.female.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              <div style={{flexBasis:`40%`}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Age and Gender</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.genders.male.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.genders.male.recidivism / facility.extraAges.ageUnder22.genders.male.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.genders.male.recidivism / facility.extraAges.ageUnder22.genders.male.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.genders.male.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.genders.male.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.genders.female.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.genders.female.recidivism / facility.extraAges.ageUnder22.genders.female.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.genders.female.recidivism / facility.extraAges.ageUnder22.genders.female.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.genders.female.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.genders.female.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.genders.male.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.genders.male.recidivism / facility.extraAges.age22To30.genders.male.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.genders.male.recidivism / facility.extraAges.age22To30.genders.male.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.genders.male.recidivism.toFixed(0)} / {facility.extraAges.age22To30.genders.male.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.genders.female.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.genders.female.recidivism / facility.extraAges.age22To30.genders.female.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.genders.female.recidivism / facility.extraAges.age22To30.genders.female.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.genders.female.recidivism.toFixed(0)} / {facility.extraAges.age22To30.genders.female.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.genders.male.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.genders.male.recidivism / facility.extraAges.age30To40.genders.male.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.genders.male.recidivism / facility.extraAges.age30To40.genders.male.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.genders.male.recidivism.toFixed(0)} / {facility.extraAges.age30To40.genders.male.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.genders.female.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.genders.female.recidivism / facility.extraAges.age30To40.genders.female.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.genders.female.recidivism / facility.extraAges.age30To40.genders.female.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.genders.female.recidivism.toFixed(0)} / {facility.extraAges.age30To40.genders.female.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.genders.male.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.genders.male.recidivism / facility.extraAges.age40To50.genders.male.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.genders.male.recidivism / facility.extraAges.age40To50.genders.male.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.genders.male.recidivism.toFixed(0)} / {facility.extraAges.age40To50.genders.male.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.genders.female.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.genders.female.recidivism / facility.extraAges.age40To50.genders.female.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.genders.female.recidivism / facility.extraAges.age40To50.genders.female.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.genders.female.recidivism.toFixed(0)} / {facility.extraAges.age40To50.genders.female.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.genders.male.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.genders.male.recidivism / facility.extraAges.age50To60.genders.male.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.genders.male.recidivism / facility.extraAges.age50To60.genders.male.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.genders.male.recidivism.toFixed(0)} / {facility.extraAges.age50To60.genders.male.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.genders.female.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.genders.female.recidivism / facility.extraAges.age50To60.genders.female.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.genders.female.recidivism / facility.extraAges.age50To60.genders.female.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.genders.female.recidivism.toFixed(0)} / {facility.extraAges.age50To60.genders.female.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.genders.male.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.genders.male.recidivism / facility.extraAges.age60To70.genders.male.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.genders.male.recidivism / facility.extraAges.age60To70.genders.male.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.genders.male.recidivism.toFixed(0)} / {facility.extraAges.age60To70.genders.male.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.genders.female.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.genders.female.recidivism / facility.extraAges.age60To70.genders.female.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.genders.female.recidivism / facility.extraAges.age60To70.genders.female.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.genders.female.recidivism.toFixed(0)} / {facility.extraAges.age60To70.genders.female.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.genders.male.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.genders.male.recidivism / facility.extraAges.age70To80.genders.male.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.genders.male.recidivism / facility.extraAges.age70To80.genders.male.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.genders.male.recidivism.toFixed(0)} / {facility.extraAges.age70To80.genders.male.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.genders.female.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.genders.female.recidivism / facility.extraAges.age70To80.genders.female.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.genders.female.recidivism / facility.extraAges.age70To80.genders.female.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.genders.female.recidivism.toFixed(0)} / {facility.extraAges.age70To80.genders.female.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.genders.male.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.genders.male.recidivism / facility.extraAges.age80To90.genders.male.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.genders.male.recidivism / facility.extraAges.age80To90.genders.male.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.genders.male.recidivism.toFixed(0)} / {facility.extraAges.age80To90.genders.male.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.genders.female.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.genders.female.recidivism / facility.extraAges.age80To90.genders.female.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.genders.female.recidivism / facility.extraAges.age80To90.genders.female.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.genders.female.recidivism.toFixed(0)} / {facility.extraAges.age80To90.genders.female.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
              <div style={{flexBasis:`40%`}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={4} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Number of Returns and Gender</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Male</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>Female</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.one.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.one.recidivism / facility.extraGenders.male.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.one.recidivism / facility.extraGenders.male.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.one.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.one.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.one.recidivism / facility.extraGenders.female.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.one.recidivism / facility.extraGenders.female.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.one.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.one.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.two.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.two.recidivism / facility.extraGenders.male.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.two.recidivism / facility.extraGenders.male.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.two.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.two.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.two.recidivism / facility.extraGenders.female.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.two.recidivism / facility.extraGenders.female.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.two.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.two.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.three.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.three.recidivism / facility.extraGenders.male.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.three.recidivism / facility.extraGenders.male.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.three.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.three.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.three.recidivism / facility.extraGenders.female.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.three.recidivism / facility.extraGenders.female.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.three.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.three.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.four.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.four.recidivism / facility.extraGenders.male.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.four.recidivism / facility.extraGenders.male.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.four.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.four.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.four.recidivism / facility.extraGenders.female.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.four.recidivism / facility.extraGenders.female.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.four.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.four.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.five.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.five.recidivism / facility.extraGenders.male.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.five.recidivism / facility.extraGenders.male.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.five.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.five.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.five.recidivism / facility.extraGenders.female.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.five.recidivism / facility.extraGenders.female.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.five.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.five.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.six.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.six.recidivism / facility.extraGenders.male.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.six.recidivism / facility.extraGenders.male.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.six.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.six.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.six.recidivism / facility.extraGenders.female.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.six.recidivism / facility.extraGenders.female.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.six.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.six.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.seven.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.seven.recidivism / facility.extraGenders.male.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.seven.recidivism / facility.extraGenders.male.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.seven.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.seven.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.seven.recidivism / facility.extraGenders.female.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.seven.recidivism / facility.extraGenders.female.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.seven.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.eight.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.eight.recidivism / facility.extraGenders.male.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.eight.recidivism / facility.extraGenders.male.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.eight.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.eight.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.eight.recidivism / facility.extraGenders.female.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.eight.recidivism / facility.extraGenders.female.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.eight.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.nine.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.nine.recidivism / facility.extraGenders.male.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.nine.recidivism / facility.extraGenders.male.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.nine.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.nine.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.nine.recidivism / facility.extraGenders.female.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.nine.recidivism / facility.extraGenders.female.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.nine.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.ten.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.ten.recidivism / facility.extraGenders.male.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.ten.recidivism / facility.extraGenders.male.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.ten.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.ten.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.ten.recidivism / facility.extraGenders.female.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.ten.recidivism / facility.extraGenders.female.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.ten.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.eleven.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.eleven.recidivism / facility.extraGenders.male.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.eleven.recidivism / facility.extraGenders.male.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.eleven.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.eleven.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.eleven.recidivism / facility.extraGenders.female.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.eleven.recidivism / facility.extraGenders.female.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.eleven.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.twelve.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.twelve.recidivism / facility.extraGenders.male.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.twelve.recidivism / facility.extraGenders.male.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.twelve.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.twelve.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.twelve.recidivism / facility.extraGenders.female.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.twelve.recidivism / facility.extraGenders.female.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.twelve.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.thirteen.recidivism / facility.extraGenders.male.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.thirteen.recidivism / facility.extraGenders.male.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.thirteen.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.thirteen.recidivism / facility.extraGenders.female.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.thirteen.recidivism / facility.extraGenders.female.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.thirteen.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.fourteen.recidivism / facility.extraGenders.male.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.fourteen.recidivism / facility.extraGenders.male.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.fourteen.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.fourteen.recidivism / facility.extraGenders.female.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.fourteen.recidivism / facility.extraGenders.female.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.fourteen.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraGenders.male.returns.fifteenPlus.recidivism / facility.extraGenders.male.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.male.returns.fifteenPlus.recidivism / facility.extraGenders.male.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.male.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraGenders.male.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraGenders.female.returns.fifteenPlus.recidivism / facility.extraGenders.female.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraGenders.female.returns.fifteenPlus.recidivism / facility.extraGenders.female.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraGenders.female.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraGenders.female.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              <div style={{flexBasis:'95%'}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Age and Length of Stay</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.stayLengths.lengthUnder3M.recidivism / facility.extraAges.ageUnder22.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.stayLengths.lengthUnder3M.recidivism / facility.extraAges.ageUnder22.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.stayLengths.length3To6M.recidivism / facility.extraAges.ageUnder22.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.stayLengths.length3To6M.recidivism / facility.extraAges.ageUnder22.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.stayLengths.length6To12M.recidivism / facility.extraAges.ageUnder22.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.stayLengths.length6To12M.recidivism / facility.extraAges.ageUnder22.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.stayLengths.length1To2Y.recidivism / facility.extraAges.ageUnder22.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.stayLengths.length1To2Y.recidivism / facility.extraAges.ageUnder22.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.stayLengths.length2To5Y.recidivism / facility.extraAges.ageUnder22.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.stayLengths.length2To5Y.recidivism / facility.extraAges.ageUnder22.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.stayLengths.length5To10Y.recidivism / facility.extraAges.ageUnder22.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.stayLengths.length5To10Y.recidivism / facility.extraAges.ageUnder22.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age22To30.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age22To30.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age22To30.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.stayLengths.length3To6M.recidivism / facility.extraAges.age22To30.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.stayLengths.length3To6M.recidivism / facility.extraAges.age22To30.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age22To30.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.stayLengths.length6To12M.recidivism / facility.extraAges.age22To30.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.stayLengths.length6To12M.recidivism / facility.extraAges.age22To30.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age22To30.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.stayLengths.length1To2Y.recidivism / facility.extraAges.age22To30.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.stayLengths.length1To2Y.recidivism / facility.extraAges.age22To30.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age22To30.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.stayLengths.length2To5Y.recidivism / facility.extraAges.age22To30.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.stayLengths.length2To5Y.recidivism / facility.extraAges.age22To30.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age22To30.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.stayLengths.length5To10Y.recidivism / facility.extraAges.age22To30.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.stayLengths.length5To10Y.recidivism / facility.extraAges.age22To30.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age22To30.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age30To40.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age30To40.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age30To40.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.stayLengths.length3To6M.recidivism / facility.extraAges.age30To40.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.stayLengths.length3To6M.recidivism / facility.extraAges.age30To40.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age30To40.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.stayLengths.length6To12M.recidivism / facility.extraAges.age30To40.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.stayLengths.length6To12M.recidivism / facility.extraAges.age30To40.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age30To40.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.stayLengths.length1To2Y.recidivism / facility.extraAges.age30To40.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.stayLengths.length1To2Y.recidivism / facility.extraAges.age30To40.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age30To40.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.stayLengths.length2To5Y.recidivism / facility.extraAges.age30To40.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.stayLengths.length2To5Y.recidivism / facility.extraAges.age30To40.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age30To40.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.stayLengths.length5To10Y.recidivism / facility.extraAges.age30To40.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.stayLengths.length5To10Y.recidivism / facility.extraAges.age30To40.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age30To40.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age40To50.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age40To50.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age40To50.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.stayLengths.length3To6M.recidivism / facility.extraAges.age40To50.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.stayLengths.length3To6M.recidivism / facility.extraAges.age40To50.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age40To50.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.stayLengths.length6To12M.recidivism / facility.extraAges.age40To50.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.stayLengths.length6To12M.recidivism / facility.extraAges.age40To50.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age40To50.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.stayLengths.length1To2Y.recidivism / facility.extraAges.age40To50.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.stayLengths.length1To2Y.recidivism / facility.extraAges.age40To50.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age40To50.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.stayLengths.length2To5Y.recidivism / facility.extraAges.age40To50.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.stayLengths.length2To5Y.recidivism / facility.extraAges.age40To50.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age40To50.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.stayLengths.length5To10Y.recidivism / facility.extraAges.age40To50.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.stayLengths.length5To10Y.recidivism / facility.extraAges.age40To50.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age40To50.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age50To60.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age50To60.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age50To60.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.stayLengths.length3To6M.recidivism / facility.extraAges.age50To60.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.stayLengths.length3To6M.recidivism / facility.extraAges.age50To60.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age50To60.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.stayLengths.length6To12M.recidivism / facility.extraAges.age50To60.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.stayLengths.length6To12M.recidivism / facility.extraAges.age50To60.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age50To60.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.stayLengths.length1To2Y.recidivism / facility.extraAges.age50To60.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.stayLengths.length1To2Y.recidivism / facility.extraAges.age50To60.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age50To60.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.stayLengths.length2To5Y.recidivism / facility.extraAges.age50To60.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.stayLengths.length2To5Y.recidivism / facility.extraAges.age50To60.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age50To60.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.stayLengths.length5To10Y.recidivism / facility.extraAges.age50To60.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.stayLengths.length5To10Y.recidivism / facility.extraAges.age50To60.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age50To60.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age60To70.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age60To70.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age60To70.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.stayLengths.length3To6M.recidivism / facility.extraAges.age60To70.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.stayLengths.length3To6M.recidivism / facility.extraAges.age60To70.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age60To70.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.stayLengths.length6To12M.recidivism / facility.extraAges.age60To70.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.stayLengths.length6To12M.recidivism / facility.extraAges.age60To70.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age60To70.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.stayLengths.length1To2Y.recidivism / facility.extraAges.age60To70.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.stayLengths.length1To2Y.recidivism / facility.extraAges.age60To70.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age60To70.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.stayLengths.length2To5Y.recidivism / facility.extraAges.age60To70.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.stayLengths.length2To5Y.recidivism / facility.extraAges.age60To70.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age60To70.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.stayLengths.length5To10Y.recidivism / facility.extraAges.age60To70.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.stayLengths.length5To10Y.recidivism / facility.extraAges.age60To70.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age60To70.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age70To80.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age70To80.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age70To80.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.stayLengths.length3To6M.recidivism / facility.extraAges.age70To80.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.stayLengths.length3To6M.recidivism / facility.extraAges.age70To80.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age70To80.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.stayLengths.length6To12M.recidivism / facility.extraAges.age70To80.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.stayLengths.length6To12M.recidivism / facility.extraAges.age70To80.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age70To80.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.stayLengths.length1To2Y.recidivism / facility.extraAges.age70To80.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.stayLengths.length1To2Y.recidivism / facility.extraAges.age70To80.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age70To80.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.stayLengths.length2To5Y.recidivism / facility.extraAges.age70To80.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.stayLengths.length2To5Y.recidivism / facility.extraAges.age70To80.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age70To80.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.stayLengths.length5To10Y.recidivism / facility.extraAges.age70To80.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.stayLengths.length5To10Y.recidivism / facility.extraAges.age70To80.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age70To80.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age80To90.stayLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.stayLengths.lengthUnder3M.recidivism / facility.extraAges.age80To90.stayLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age80To90.stayLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.stayLengths.length3To6M.recidivism / facility.extraAges.age80To90.stayLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.stayLengths.length3To6M.recidivism / facility.extraAges.age80To90.stayLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age80To90.stayLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.stayLengths.length6To12M.recidivism / facility.extraAges.age80To90.stayLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.stayLengths.length6To12M.recidivism / facility.extraAges.age80To90.stayLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age80To90.stayLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.stayLengths.length1To2Y.recidivism / facility.extraAges.age80To90.stayLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.stayLengths.length1To2Y.recidivism / facility.extraAges.age80To90.stayLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age80To90.stayLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.stayLengths.length2To5Y.recidivism / facility.extraAges.age80To90.stayLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.stayLengths.length2To5Y.recidivism / facility.extraAges.age80To90.stayLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age80To90.stayLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.stayLengths.length5To10Y.recidivism / facility.extraAges.age80To90.stayLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.stayLengths.length5To10Y.recidivism / facility.extraAges.age80To90.stayLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.stayLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age80To90.stayLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={12} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Age and Length Between Returns</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>Under 3 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>3 - 6 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>6 - 12 Months</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>1 - 2 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>2 - 5 Years</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>5 - 10 Years</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>21 and Under</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.outLengths.lengthUnder3M.recidivism / facility.extraAges.ageUnder22.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.outLengths.lengthUnder3M.recidivism / facility.extraAges.ageUnder22.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.outLengths.length3To6M.recidivism / facility.extraAges.ageUnder22.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.outLengths.length3To6M.recidivism / facility.extraAges.ageUnder22.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.outLengths.length6To12M.recidivism / facility.extraAges.ageUnder22.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.outLengths.length6To12M.recidivism / facility.extraAges.ageUnder22.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.outLengths.length1To2Y.recidivism / facility.extraAges.ageUnder22.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.outLengths.length1To2Y.recidivism / facility.extraAges.ageUnder22.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.outLengths.length2To5Y.recidivism / facility.extraAges.ageUnder22.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.outLengths.length2To5Y.recidivism / facility.extraAges.ageUnder22.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.outLengths.length5To10Y.recidivism / facility.extraAges.ageUnder22.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.outLengths.length5To10Y.recidivism / facility.extraAges.ageUnder22.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>22 - 29</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.outLengths.lengthUnder3M.recidivism / facility.extraAges.age22To30.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.outLengths.lengthUnder3M.recidivism / facility.extraAges.age22To30.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age22To30.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.outLengths.length3To6M.recidivism / facility.extraAges.age22To30.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.outLengths.length3To6M.recidivism / facility.extraAges.age22To30.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age22To30.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.outLengths.length6To12M.recidivism / facility.extraAges.age22To30.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.outLengths.length6To12M.recidivism / facility.extraAges.age22To30.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age22To30.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.outLengths.length1To2Y.recidivism / facility.extraAges.age22To30.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.outLengths.length1To2Y.recidivism / facility.extraAges.age22To30.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age22To30.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.outLengths.length2To5Y.recidivism / facility.extraAges.age22To30.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.outLengths.length2To5Y.recidivism / facility.extraAges.age22To30.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age22To30.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.outLengths.length5To10Y.recidivism / facility.extraAges.age22To30.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.outLengths.length5To10Y.recidivism / facility.extraAges.age22To30.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age22To30.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>30 - 39</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.outLengths.lengthUnder3M.recidivism / facility.extraAges.age30To40.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.outLengths.lengthUnder3M.recidivism / facility.extraAges.age30To40.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age30To40.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.outLengths.length3To6M.recidivism / facility.extraAges.age30To40.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.outLengths.length3To6M.recidivism / facility.extraAges.age30To40.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age30To40.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.outLengths.length6To12M.recidivism / facility.extraAges.age30To40.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.outLengths.length6To12M.recidivism / facility.extraAges.age30To40.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age30To40.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.outLengths.length1To2Y.recidivism / facility.extraAges.age30To40.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.outLengths.length1To2Y.recidivism / facility.extraAges.age30To40.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age30To40.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.outLengths.length2To5Y.recidivism / facility.extraAges.age30To40.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.outLengths.length2To5Y.recidivism / facility.extraAges.age30To40.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age30To40.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.outLengths.length5To10Y.recidivism / facility.extraAges.age30To40.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.outLengths.length5To10Y.recidivism / facility.extraAges.age30To40.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age30To40.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>40 - 49</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.outLengths.lengthUnder3M.recidivism / facility.extraAges.age40To50.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.outLengths.lengthUnder3M.recidivism / facility.extraAges.age40To50.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age40To50.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.outLengths.length3To6M.recidivism / facility.extraAges.age40To50.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.outLengths.length3To6M.recidivism / facility.extraAges.age40To50.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age40To50.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.outLengths.length6To12M.recidivism / facility.extraAges.age40To50.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.outLengths.length6To12M.recidivism / facility.extraAges.age40To50.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age40To50.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.outLengths.length1To2Y.recidivism / facility.extraAges.age40To50.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.outLengths.length1To2Y.recidivism / facility.extraAges.age40To50.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age40To50.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.outLengths.length2To5Y.recidivism / facility.extraAges.age40To50.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.outLengths.length2To5Y.recidivism / facility.extraAges.age40To50.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age40To50.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.outLengths.length5To10Y.recidivism / facility.extraAges.age40To50.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.outLengths.length5To10Y.recidivism / facility.extraAges.age40To50.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age40To50.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>50 - 59</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.outLengths.lengthUnder3M.recidivism / facility.extraAges.age50To60.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.outLengths.lengthUnder3M.recidivism / facility.extraAges.age50To60.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age50To60.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.outLengths.length3To6M.recidivism / facility.extraAges.age50To60.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.outLengths.length3To6M.recidivism / facility.extraAges.age50To60.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age50To60.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.outLengths.length6To12M.recidivism / facility.extraAges.age50To60.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.outLengths.length6To12M.recidivism / facility.extraAges.age50To60.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age50To60.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.outLengths.length1To2Y.recidivism / facility.extraAges.age50To60.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.outLengths.length1To2Y.recidivism / facility.extraAges.age50To60.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age50To60.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.outLengths.length2To5Y.recidivism / facility.extraAges.age50To60.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.outLengths.length2To5Y.recidivism / facility.extraAges.age50To60.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age50To60.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.outLengths.length5To10Y.recidivism / facility.extraAges.age50To60.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.outLengths.length5To10Y.recidivism / facility.extraAges.age50To60.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age50To60.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>60 - 69</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.outLengths.lengthUnder3M.recidivism / facility.extraAges.age60To70.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.outLengths.lengthUnder3M.recidivism / facility.extraAges.age60To70.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age60To70.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.outLengths.length3To6M.recidivism / facility.extraAges.age60To70.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.outLengths.length3To6M.recidivism / facility.extraAges.age60To70.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age60To70.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.outLengths.length6To12M.recidivism / facility.extraAges.age60To70.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.outLengths.length6To12M.recidivism / facility.extraAges.age60To70.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age60To70.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.outLengths.length1To2Y.recidivism / facility.extraAges.age60To70.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.outLengths.length1To2Y.recidivism / facility.extraAges.age60To70.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age60To70.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.outLengths.length2To5Y.recidivism / facility.extraAges.age60To70.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.outLengths.length2To5Y.recidivism / facility.extraAges.age60To70.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age60To70.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.outLengths.length5To10Y.recidivism / facility.extraAges.age60To70.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.outLengths.length5To10Y.recidivism / facility.extraAges.age60To70.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age60To70.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>70 - 79</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.outLengths.lengthUnder3M.recidivism / facility.extraAges.age70To80.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.outLengths.lengthUnder3M.recidivism / facility.extraAges.age70To80.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age70To80.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.outLengths.length3To6M.recidivism / facility.extraAges.age70To80.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.outLengths.length3To6M.recidivism / facility.extraAges.age70To80.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age70To80.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.outLengths.length6To12M.recidivism / facility.extraAges.age70To80.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.outLengths.length6To12M.recidivism / facility.extraAges.age70To80.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age70To80.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.outLengths.length1To2Y.recidivism / facility.extraAges.age70To80.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.outLengths.length1To2Y.recidivism / facility.extraAges.age70To80.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age70To80.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.outLengths.length2To5Y.recidivism / facility.extraAges.age70To80.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.outLengths.length2To5Y.recidivism / facility.extraAges.age70To80.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age70To80.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.outLengths.length5To10Y.recidivism / facility.extraAges.age70To80.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.outLengths.length5To10Y.recidivism / facility.extraAges.age70To80.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age70To80.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>80 - 89</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.lengthUnder3M.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.outLengths.lengthUnder3M.recidivism / facility.extraAges.age80To90.outLengths.lengthUnder3M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.outLengths.lengthUnder3M.recidivism / facility.extraAges.age80To90.outLengths.lengthUnder3M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.lengthUnder3M.recidivism.toFixed(0)} / {facility.extraAges.age80To90.outLengths.lengthUnder3M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.length3To6M.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.outLengths.length3To6M.recidivism / facility.extraAges.age80To90.outLengths.length3To6M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.outLengths.length3To6M.recidivism / facility.extraAges.age80To90.outLengths.length3To6M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.length3To6M.recidivism.toFixed(0)} / {facility.extraAges.age80To90.outLengths.length3To6M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.length6To12M.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.outLengths.length6To12M.recidivism / facility.extraAges.age80To90.outLengths.length6To12M.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.outLengths.length6To12M.recidivism / facility.extraAges.age80To90.outLengths.length6To12M.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.length6To12M.recidivism.toFixed(0)} / {facility.extraAges.age80To90.outLengths.length6To12M.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.length1To2Y.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.outLengths.length1To2Y.recidivism / facility.extraAges.age80To90.outLengths.length1To2Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.outLengths.length1To2Y.recidivism / facility.extraAges.age80To90.outLengths.length1To2Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.length1To2Y.recidivism.toFixed(0)} / {facility.extraAges.age80To90.outLengths.length1To2Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.length2To5Y.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.outLengths.length2To5Y.recidivism / facility.extraAges.age80To90.outLengths.length2To5Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.outLengths.length2To5Y.recidivism / facility.extraAges.age80To90.outLengths.length2To5Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.length2To5Y.recidivism.toFixed(0)} / {facility.extraAges.age80To90.outLengths.length2To5Y.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.length5To10Y.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.outLengths.length5To10Y.recidivism / facility.extraAges.age80To90.outLengths.length5To10Y.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.outLengths.length5To10Y.recidivism / facility.extraAges.age80To90.outLengths.length5To10Y.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.outLengths.length5To10Y.recidivism.toFixed(0)} / {facility.extraAges.age80To90.outLengths.length5To10Y.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap',justifyContent:'space-around'}}>
              <div style={{flexBasis:'95%'}}>
                <Table compact celled striped unstackable definition style={{margin:'2em 0',height:'0%',border:0,backgroundColor:'hsl(207, 100%, 98%)'}}>
                  <Table.Header>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{borderBottom:0,boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={30} style={{borderBottom:0,borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>{facility.abbreviation} Recidivism by Age and Number of Returns</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row style={{textAlign:'center'}}>
                      <Table.HeaderCell style={{boxShadow:'none'}}></Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>21 and Under</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>22 - 29</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>30 - 39</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>40 - 49</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>50 - 59</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>60 - 69</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)'}}>70 - 79</Table.HeaderCell>
                      <Table.HeaderCell colSpan={2} style={{borderTop:'1px solid rgba(34,36,38,.1)',borderRight:'1px solid rgba(34,36,38,.1)'}}>80 - 89</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>One</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.one.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.one.recidivism / facility.extraAges.ageUnder22.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.one.recidivism / facility.extraAges.ageUnder22.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.one.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.one.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.one.recidivism / facility.extraAges.age22To30.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.one.recidivism / facility.extraAges.age22To30.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.one.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.one.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.one.recidivism / facility.extraAges.age30To40.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.one.recidivism / facility.extraAges.age30To40.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.one.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.one.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.one.recidivism / facility.extraAges.age40To50.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.one.recidivism / facility.extraAges.age40To50.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.one.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.one.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.one.recidivism / facility.extraAges.age50To60.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.one.recidivism / facility.extraAges.age50To60.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.one.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.one.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.one.recidivism / facility.extraAges.age60To70.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.one.recidivism / facility.extraAges.age60To70.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.one.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.one.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.one.recidivism / facility.extraAges.age70To80.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.one.recidivism / facility.extraAges.age70To80.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.one.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.one.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.one.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.one.recidivism / facility.extraAges.age80To90.returns.one.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.one.recidivism / facility.extraAges.age80To90.returns.one.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.one.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.one.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Two</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.two.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.two.recidivism / facility.extraAges.ageUnder22.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.two.recidivism / facility.extraAges.ageUnder22.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.two.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.two.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.two.recidivism / facility.extraAges.age22To30.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.two.recidivism / facility.extraAges.age22To30.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.two.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.two.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.two.recidivism / facility.extraAges.age30To40.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.two.recidivism / facility.extraAges.age30To40.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.two.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.two.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.two.recidivism / facility.extraAges.age40To50.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.two.recidivism / facility.extraAges.age40To50.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.two.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.two.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.two.recidivism / facility.extraAges.age50To60.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.two.recidivism / facility.extraAges.age50To60.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.two.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.two.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.two.recidivism / facility.extraAges.age60To70.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.two.recidivism / facility.extraAges.age60To70.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.two.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.two.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.two.recidivism / facility.extraAges.age70To80.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.two.recidivism / facility.extraAges.age70To80.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.two.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.two.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.two.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.two.recidivism / facility.extraAges.age80To90.returns.two.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.two.recidivism / facility.extraAges.age80To90.returns.two.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.two.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.two.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Three</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.three.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.three.recidivism / facility.extraAges.ageUnder22.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.three.recidivism / facility.extraAges.ageUnder22.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.three.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.three.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.three.recidivism / facility.extraAges.age22To30.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.three.recidivism / facility.extraAges.age22To30.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.three.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.three.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.three.recidivism / facility.extraAges.age30To40.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.three.recidivism / facility.extraAges.age30To40.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.three.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.three.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.three.recidivism / facility.extraAges.age40To50.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.three.recidivism / facility.extraAges.age40To50.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.three.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.three.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.three.recidivism / facility.extraAges.age50To60.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.three.recidivism / facility.extraAges.age50To60.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.three.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.three.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.three.recidivism / facility.extraAges.age60To70.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.three.recidivism / facility.extraAges.age60To70.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.three.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.three.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.three.recidivism / facility.extraAges.age70To80.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.three.recidivism / facility.extraAges.age70To80.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.three.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.three.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.three.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.three.recidivism / facility.extraAges.age80To90.returns.three.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.three.recidivism / facility.extraAges.age80To90.returns.three.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.three.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.three.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Four</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.four.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.four.recidivism / facility.extraAges.ageUnder22.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.four.recidivism / facility.extraAges.ageUnder22.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.four.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.four.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.four.recidivism / facility.extraAges.age22To30.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.four.recidivism / facility.extraAges.age22To30.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.four.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.four.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.four.recidivism / facility.extraAges.age30To40.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.four.recidivism / facility.extraAges.age30To40.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.four.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.four.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.four.recidivism / facility.extraAges.age40To50.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.four.recidivism / facility.extraAges.age40To50.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.four.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.four.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.four.recidivism / facility.extraAges.age50To60.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.four.recidivism / facility.extraAges.age50To60.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.four.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.four.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.four.recidivism / facility.extraAges.age60To70.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.four.recidivism / facility.extraAges.age60To70.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.four.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.four.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.four.recidivism / facility.extraAges.age70To80.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.four.recidivism / facility.extraAges.age70To80.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.four.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.four.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.four.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.four.recidivism / facility.extraAges.age80To90.returns.four.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.four.recidivism / facility.extraAges.age80To90.returns.four.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.four.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.four.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Five</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.five.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.five.recidivism / facility.extraAges.ageUnder22.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.five.recidivism / facility.extraAges.ageUnder22.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.five.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.five.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.five.recidivism / facility.extraAges.age22To30.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.five.recidivism / facility.extraAges.age22To30.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.five.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.five.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.five.recidivism / facility.extraAges.age30To40.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.five.recidivism / facility.extraAges.age30To40.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.five.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.five.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.five.recidivism / facility.extraAges.age40To50.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.five.recidivism / facility.extraAges.age40To50.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.five.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.five.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.five.recidivism / facility.extraAges.age50To60.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.five.recidivism / facility.extraAges.age50To60.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.five.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.five.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.five.recidivism / facility.extraAges.age60To70.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.five.recidivism / facility.extraAges.age60To70.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.five.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.five.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.five.recidivism / facility.extraAges.age70To80.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.five.recidivism / facility.extraAges.age70To80.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.five.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.five.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.five.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.five.recidivism / facility.extraAges.age80To90.returns.five.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.five.recidivism / facility.extraAges.age80To90.returns.five.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.five.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.five.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Six</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.six.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.six.recidivism / facility.extraAges.ageUnder22.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.six.recidivism / facility.extraAges.ageUnder22.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.six.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.six.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.six.recidivism / facility.extraAges.age22To30.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.six.recidivism / facility.extraAges.age22To30.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.six.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.six.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.six.recidivism / facility.extraAges.age30To40.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.six.recidivism / facility.extraAges.age30To40.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.six.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.six.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.six.recidivism / facility.extraAges.age40To50.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.six.recidivism / facility.extraAges.age40To50.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.six.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.six.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.six.recidivism / facility.extraAges.age50To60.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.six.recidivism / facility.extraAges.age50To60.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.six.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.six.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.six.recidivism / facility.extraAges.age60To70.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.six.recidivism / facility.extraAges.age60To70.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.six.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.six.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.six.recidivism / facility.extraAges.age70To80.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.six.recidivism / facility.extraAges.age70To80.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.six.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.six.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.six.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.six.recidivism / facility.extraAges.age80To90.returns.six.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.six.recidivism / facility.extraAges.age80To90.returns.six.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.six.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.six.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Seven</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.seven.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.seven.recidivism / facility.extraAges.ageUnder22.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.seven.recidivism / facility.extraAges.ageUnder22.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.seven.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.seven.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.seven.recidivism / facility.extraAges.age22To30.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.seven.recidivism / facility.extraAges.age22To30.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.seven.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.seven.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.seven.recidivism / facility.extraAges.age30To40.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.seven.recidivism / facility.extraAges.age30To40.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.seven.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.seven.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.seven.recidivism / facility.extraAges.age40To50.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.seven.recidivism / facility.extraAges.age40To50.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.seven.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.seven.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.seven.recidivism / facility.extraAges.age50To60.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.seven.recidivism / facility.extraAges.age50To60.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.seven.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.seven.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.seven.recidivism / facility.extraAges.age60To70.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.seven.recidivism / facility.extraAges.age60To70.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.seven.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.seven.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.seven.recidivism / facility.extraAges.age70To80.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.seven.recidivism / facility.extraAges.age70To80.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.seven.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.seven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.seven.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.seven.recidivism / facility.extraAges.age80To90.returns.seven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.seven.recidivism / facility.extraAges.age80To90.returns.seven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.seven.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.seven.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eight</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.eight.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.eight.recidivism / facility.extraAges.ageUnder22.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.eight.recidivism / facility.extraAges.ageUnder22.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.eight.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.eight.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.eight.recidivism / facility.extraAges.age22To30.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.eight.recidivism / facility.extraAges.age22To30.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.eight.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.eight.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.eight.recidivism / facility.extraAges.age30To40.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.eight.recidivism / facility.extraAges.age30To40.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.eight.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.eight.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.eight.recidivism / facility.extraAges.age40To50.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.eight.recidivism / facility.extraAges.age40To50.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.eight.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.eight.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.eight.recidivism / facility.extraAges.age50To60.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.eight.recidivism / facility.extraAges.age50To60.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.eight.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.eight.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.eight.recidivism / facility.extraAges.age60To70.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.eight.recidivism / facility.extraAges.age60To70.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.eight.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.eight.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.eight.recidivism / facility.extraAges.age70To80.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.eight.recidivism / facility.extraAges.age70To80.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.eight.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.eight.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.eight.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.eight.recidivism / facility.extraAges.age80To90.returns.eight.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.eight.recidivism / facility.extraAges.age80To90.returns.eight.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.eight.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.eight.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Nine</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.nine.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.nine.recidivism / facility.extraAges.ageUnder22.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.nine.recidivism / facility.extraAges.ageUnder22.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.nine.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.nine.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.nine.recidivism / facility.extraAges.age22To30.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.nine.recidivism / facility.extraAges.age22To30.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.nine.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.nine.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.nine.recidivism / facility.extraAges.age30To40.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.nine.recidivism / facility.extraAges.age30To40.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.nine.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.nine.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.nine.recidivism / facility.extraAges.age40To50.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.nine.recidivism / facility.extraAges.age40To50.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.nine.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.nine.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.nine.recidivism / facility.extraAges.age50To60.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.nine.recidivism / facility.extraAges.age50To60.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.nine.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.nine.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.nine.recidivism / facility.extraAges.age60To70.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.nine.recidivism / facility.extraAges.age60To70.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.nine.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.nine.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.nine.recidivism / facility.extraAges.age70To80.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.nine.recidivism / facility.extraAges.age70To80.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.nine.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.nine.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.nine.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.nine.recidivism / facility.extraAges.age80To90.returns.nine.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.nine.recidivism / facility.extraAges.age80To90.returns.nine.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.nine.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.nine.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Ten</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.ten.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.ten.recidivism / facility.extraAges.ageUnder22.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.ten.recidivism / facility.extraAges.ageUnder22.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.ten.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.ten.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.ten.recidivism / facility.extraAges.age22To30.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.ten.recidivism / facility.extraAges.age22To30.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.ten.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.ten.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.ten.recidivism / facility.extraAges.age30To40.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.ten.recidivism / facility.extraAges.age30To40.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.ten.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.ten.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.ten.recidivism / facility.extraAges.age40To50.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.ten.recidivism / facility.extraAges.age40To50.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.ten.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.ten.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.ten.recidivism / facility.extraAges.age50To60.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.ten.recidivism / facility.extraAges.age50To60.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.ten.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.ten.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.ten.recidivism / facility.extraAges.age60To70.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.ten.recidivism / facility.extraAges.age60To70.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.ten.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.ten.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.ten.recidivism / facility.extraAges.age70To80.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.ten.recidivism / facility.extraAges.age70To80.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.ten.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.ten.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.ten.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.ten.recidivism / facility.extraAges.age80To90.returns.ten.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.ten.recidivism / facility.extraAges.age80To90.returns.ten.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.ten.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.ten.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Eleven</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.eleven.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.eleven.recidivism / facility.extraAges.ageUnder22.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.eleven.recidivism / facility.extraAges.ageUnder22.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.eleven.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.eleven.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.eleven.recidivism / facility.extraAges.age22To30.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.eleven.recidivism / facility.extraAges.age22To30.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.eleven.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.eleven.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.eleven.recidivism / facility.extraAges.age30To40.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.eleven.recidivism / facility.extraAges.age30To40.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.eleven.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.eleven.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.eleven.recidivism / facility.extraAges.age40To50.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.eleven.recidivism / facility.extraAges.age40To50.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.eleven.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.eleven.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.eleven.recidivism / facility.extraAges.age50To60.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.eleven.recidivism / facility.extraAges.age50To60.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.eleven.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.eleven.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.eleven.recidivism / facility.extraAges.age60To70.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.eleven.recidivism / facility.extraAges.age60To70.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.eleven.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.eleven.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.eleven.recidivism / facility.extraAges.age70To80.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.eleven.recidivism / facility.extraAges.age70To80.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.eleven.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.eleven.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.eleven.recidivism / facility.extraAges.age80To90.returns.eleven.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.eleven.recidivism / facility.extraAges.age80To90.returns.eleven.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.eleven.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.eleven.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Twelve</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.twelve.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.twelve.recidivism / facility.extraAges.ageUnder22.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.twelve.recidivism / facility.extraAges.ageUnder22.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.twelve.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.twelve.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.twelve.recidivism / facility.extraAges.age22To30.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.twelve.recidivism / facility.extraAges.age22To30.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.twelve.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.twelve.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.twelve.recidivism / facility.extraAges.age30To40.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.twelve.recidivism / facility.extraAges.age30To40.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.twelve.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.twelve.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.twelve.recidivism / facility.extraAges.age40To50.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.twelve.recidivism / facility.extraAges.age40To50.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.twelve.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.twelve.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.twelve.recidivism / facility.extraAges.age50To60.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.twelve.recidivism / facility.extraAges.age50To60.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.twelve.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.twelve.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.twelve.recidivism / facility.extraAges.age60To70.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.twelve.recidivism / facility.extraAges.age60To70.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.twelve.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.twelve.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.twelve.recidivism / facility.extraAges.age70To80.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.twelve.recidivism / facility.extraAges.age70To80.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.twelve.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.twelve.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.twelve.recidivism / facility.extraAges.age80To90.returns.twelve.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.twelve.recidivism / facility.extraAges.age80To90.returns.twelve.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.twelve.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.twelve.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Thirteen</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.thirteen.recidivism / facility.extraAges.ageUnder22.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.thirteen.recidivism / facility.extraAges.ageUnder22.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.thirteen.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.thirteen.recidivism / facility.extraAges.age22To30.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.thirteen.recidivism / facility.extraAges.age22To30.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.thirteen.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.thirteen.recidivism / facility.extraAges.age30To40.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.thirteen.recidivism / facility.extraAges.age30To40.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.thirteen.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.thirteen.recidivism / facility.extraAges.age40To50.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.thirteen.recidivism / facility.extraAges.age40To50.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.thirteen.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.thirteen.recidivism / facility.extraAges.age50To60.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.thirteen.recidivism / facility.extraAges.age50To60.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.thirteen.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.thirteen.recidivism / facility.extraAges.age60To70.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.thirteen.recidivism / facility.extraAges.age60To70.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.thirteen.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.thirteen.recidivism / facility.extraAges.age70To80.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.thirteen.recidivism / facility.extraAges.age70To80.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.thirteen.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.thirteen.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.thirteen.recidivism / facility.extraAges.age80To90.returns.thirteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.thirteen.recidivism / facility.extraAges.age80To90.returns.thirteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.thirteen.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.thirteen.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fourteen</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.fourteen.recidivism / facility.extraAges.ageUnder22.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.fourteen.recidivism / facility.extraAges.ageUnder22.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.fourteen.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.fourteen.recidivism / facility.extraAges.age22To30.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.fourteen.recidivism / facility.extraAges.age22To30.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.fourteen.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.fourteen.recidivism / facility.extraAges.age30To40.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.fourteen.recidivism / facility.extraAges.age30To40.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.fourteen.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.fourteen.recidivism / facility.extraAges.age40To50.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.fourteen.recidivism / facility.extraAges.age40To50.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.fourteen.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.fourteen.recidivism / facility.extraAges.age50To60.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.fourteen.recidivism / facility.extraAges.age50To60.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.fourteen.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.fourteen.recidivism / facility.extraAges.age60To70.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.fourteen.recidivism / facility.extraAges.age60To70.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.fourteen.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.fourteen.recidivism / facility.extraAges.age70To80.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.fourteen.recidivism / facility.extraAges.age70To80.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.fourteen.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.fourteen.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.fourteen.recidivism / facility.extraAges.age80To90.returns.fourteen.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.fourteen.recidivism / facility.extraAges.age80To90.returns.fourteen.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.fourteen.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.fourteen.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                    <Table.Row style={{backgroundColor: 'white'}}>
                      <Table.Cell style={{borderLeft:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap'}}>Fifteen and Over</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraAges.ageUnder22.returns.fifteenPlus.recidivism / facility.extraAges.ageUnder22.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraAges.ageUnder22.returns.fifteenPlus.recidivism / facility.extraAges.ageUnder22.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.ageUnder22.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraAges.ageUnder22.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraAges.age22To30.returns.fifteenPlus.recidivism / facility.extraAges.age22To30.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age22To30.returns.fifteenPlus.recidivism / facility.extraAges.age22To30.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age22To30.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraAges.age22To30.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraAges.age30To40.returns.fifteenPlus.recidivism / facility.extraAges.age30To40.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age30To40.returns.fifteenPlus.recidivism / facility.extraAges.age30To40.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age30To40.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraAges.age30To40.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraAges.age40To50.returns.fifteenPlus.recidivism / facility.extraAges.age40To50.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age40To50.returns.fifteenPlus.recidivism / facility.extraAges.age40To50.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age40To50.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraAges.age40To50.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraAges.age50To60.returns.fifteenPlus.recidivism / facility.extraAges.age50To60.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age50To60.returns.fifteenPlus.recidivism / facility.extraAges.age50To60.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age50To60.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraAges.age50To60.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraAges.age60To70.returns.fifteenPlus.recidivism / facility.extraAges.age60To70.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age60To70.returns.fifteenPlus.recidivism / facility.extraAges.age60To70.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age60To70.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraAges.age60To70.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraAges.age70To80.returns.fifteenPlus.recidivism / facility.extraAges.age70To80.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age70To80.returns.fifteenPlus.recidivism / facility.extraAges.age70To80.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age70To80.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraAges.age70To80.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                      <Table.Cell style={{borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.fifteenPlus.total == 0 ? '0' : Math.round(facility.extraAges.age80To90.returns.fifteenPlus.recidivism / facility.extraAges.age80To90.returns.fifteenPlus.total * 100) > 100 ? '100' : Math.round(facility.extraAges.age80To90.returns.fifteenPlus.recidivism / facility.extraAges.age80To90.returns.fifteenPlus.total * 100)}%</div></Table.Cell>
                      <Table.Cell style={{borderRight:'1px solid rgba(34,36,38,.1)',borderBottom:'1px solid rgba(34,36,38,.1)'}}><div style={{whiteSpace:'nowrap',textAlign:'right'}}>{facility.extraAges.age80To90.returns.fifteenPlus.recidivism.toFixed(0)} / {facility.extraAges.age80To90.returns.fifteenPlus.total.toFixed(0)}</div></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </div>
            </div>
          </div>)}
        </Fragment>
      )
    }
}

export default RecidivismGlobalFacilities