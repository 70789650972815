import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as AdminStore from '../../store/reducers/admin'
import {
  Accordion,
  Button,
  Popup,
  Label,
  List,
  Icon,
  Modal,
  Form,
  Input,
  Select,
  Search,
  TextArea
} from 'semantic-ui-react'
const _ = require('underscore')

type StaffProps =
    { certificates: any } &
    typeof AdminStore.actionCreators &
    WithRouterProps<{}>

class Certificates extends React.PureComponent<StaffProps> {
    public componentDidMount() {
      this.props.fetchInstitutions()
    }

    public render() {
      const institutionPanels = this.props.certificates.list.map((institution:any) => {
        return { key: institution.recordID, title: { content:
          <Fragment>
            {institution.name}
          </Fragment>
        }, content: { content:
          <Fragment>
            <List link style={{marginBottom:0,marginLeft:12}}>
              {institution.certificates.map((certificate:any) => (
                <List.Item key={certificate.recordID} onClick={() => this.props.openEditCertificateModal(certificate)}>{certificate.name}</List.Item>
              ))}
            </List>
            {institution.recordID == null ? null : <Button positive content={`Add Certificate`} size="mini" onClick={() => this.props.openAddCertificateModal(institution.recordID)} style={{marginTop:12}} />}
            {institution.recordID == null ? null : <Button color='blue' content={`Edit ${institution.name}`} size="mini" onClick={() => this.props.openEditInstitutionModal(institution)} style={{marginTop:12}} />}
          </Fragment>
        }}
      })

      return (
        <div className='admin-certificates' style={{overflow:'auto'}}>
          <Form style={{width:1145}}>
            <Form.Group>
              <Form.Field
                control={Input}
                placeholder='Certificate name'
                value={this.props.certificates.filters.name}
                onChange={(e:any, data:any) => this.props.updateCertificatesFilter('name', data.value.validate())}
                width={8}
              />
            </Form.Group>
          </Form>
          <Accordion styled panels={institutionPanels} style={{marginTop:1,marginLeft:1,marginBottom:1}} />
          <Button positive content={`Add Certificate Institution`} size="mini" onClick={() => this.props.openAddInstitutionModal()} style={{marginTop:12,marginBottom:12}} />
          <Modal
            open={this.props.certificates.modals.addI.open}
            onClose={this.props.toggleAddInstitutionModal}
            size='small'
          >
            <Modal.Header content={`Add Institution`} />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Institution name'
                    placeholder='Institution name'
                    value={this.props.certificates.modals.addI.data.name}
                    onChange={(e:any, data:any) => this.props.updateAddInstitutionData('name', data.value.validate())}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleAddInstitutionModal}>Close</Button>
              <Button color='green' onClick={_.debounce(this.props.addInstitution, 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.certificates.modals.editI.open}
            onClose={this.props.toggleEditInstitutionModal}
            size='small'
          >
            <Modal.Header content={`Edit Institution`} />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Institution name'
                    placeholder='Institution name'
                    value={this.props.certificates.modals.editI.data.name}
                    onChange={(e:any, data:any) => this.props.updateEditInstitutionData('name', data.value.validate())}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleEditInstitutionModal}>Close</Button>
              <Button color='green' onClick={_.debounce(this.props.saveInstitution, 10000, true)}>Save</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.certificates.modals.edit.open}
            onClose={this.props.toggleEditCertificateModal}
            size='small'
          >
            <Modal.Header content={`Edit Certificate`} />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Certificate name'
                    placeholder='Certificate name'
                    value={this.props.certificates.modals.edit.data.name}
                    onChange={(e:any, data:any) => this.props.updateEditCertificateData('name', data.value.validate())}
                    width={16}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Institution'
                    placeholder='Institution'
                    options={[{key:0,value:null,text:''}].concat(_.map(this.props.certificates.list, (institution:any) => ({key:institution.recordID, text:institution.name, value:institution.recordID})))}
                    value={this.props.certificates.modals.edit.data.institutionID}
                    onChange={(e:any, data:any) => this.props.updateEditCertificateData('institutionID', data.value)}
                    width={8}
                  />
                  <div className='eight wide field'>
                    <label>Class template</label>
                    <Search
                      placeholder='Class template'
                      loading={this.props.certificates.template.loading}
                      onResultSelect={(e:any, data:any) => this.props.selectTemplate(data.result)}
                      onSearchChange={(e:any, data:any) => this.props.searchTemplates(data.value as string)}
                      results={this.props.certificates.options.templates}
                      value={this.props.certificates.template.value}
                      onFocus={() => this.props.clearTemplates()}
                      style={{display:'inline-block',width:'100%'}}
                      category
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Prerequisites'
                    placeholder='Prerequisites'
                    value={this.props.certificates.modals.edit.data.prerequisites||''}
                    onChange={(e:any, data:any) => this.props.updateEditCertificateData('prerequisites', data.value.validate())}
                    width={8}
                  />
                  <Form.Field
                    control={Input}
                    label='Satisfies'
                    placeholder='Satisfies'
                    value={this.props.certificates.modals.edit.data.satisfies||''}
                    onChange={(e:any, data:any) => this.props.updateEditCertificateData('satisfies', data.value.validate())}
                    width={8}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    label='Details'
                    placeholder='Details'
                    value={this.props.certificates.modals.edit.data.details||''}
                    onChange={(e:any, data:any) => this.props.updateEditCertificateData('details', data.value)}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleEditCertificateModal}>Close</Button>
              <Button color='green' onClick={_.debounce(this.props.saveCertificate, 10000, true)}>Save</Button>
            </Modal.Actions>
          </Modal>
          <Modal
            open={this.props.certificates.modals.add.open}
            onClose={this.props.toggleAddCertificateModal}
            size='small'
          >
            <Modal.Header content={`Add Certificate`} />
            <Modal.Content>
              <Form>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Certificate name'
                    placeholder='Certificate name'
                    value={this.props.certificates.modals.add.data.name}
                    onChange={(e:any, data:any) => this.props.updateAddCertificateData('name', data.value.validate())}
                    width={16}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Select}
                    label='Institution'
                    placeholder='Institution'
                    options={[{key:0,value:null,text:''}].concat(_.map(this.props.certificates.list, (institution:any) => ({key:institution.recordID, text:institution.name, value:institution.recordID})))}
                    value={this.props.certificates.modals.add.data.institutionID}
                    onChange={(e:any, data:any) => this.props.updateAddCertificateData('institutionID', data.value)}
                    width={8}
                  />
                  <div className='eight wide field'>
                    <label>Class template</label>
                    <Search
                      placeholder='Class template'
                      loading={this.props.certificates.template.loading}
                      onResultSelect={(e:any, data:any) => this.props.selectTemplate(data.result)}
                      onSearchChange={(e:any, data:any) => this.props.searchTemplates(data.value as string)}
                      results={this.props.certificates.options.templates}
                      value={this.props.certificates.template.value}
                      onFocus={() => this.props.clearTemplates()}
                      style={{display:'inline-block',width:'100%'}}
                      category
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Input}
                    label='Prerequisites'
                    placeholder='Prerequisites'
                    value={this.props.certificates.modals.add.data.prerequisites||''}
                    onChange={(e:any, data:any) => this.props.updateAddCertificateData('prerequisites', data.value.validate())}
                    width={8}
                  />
                  <Form.Field
                    control={Input}
                    label='Satisfies'
                    placeholder='Satisfies'
                    value={this.props.certificates.modals.add.data.satisfies||''}
                    onChange={(e:any, data:any) => this.props.updateAddCertificateData('satisfies', data.value.validate())}
                    width={8}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={TextArea}
                    label='Details'
                    placeholder='Details'
                    value={this.props.certificates.modals.add.data.details||''}
                    onChange={(e:any, data:any) => this.props.updateAddCertificateData('details', data.value)}
                    width={16}
                  />
                </Form.Group>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button color='black' onClick={this.props.toggleAddCertificateModal}>Close</Button>
              <Button color='green' onClick={_.debounce(this.props.addCertificate, 10000, true)}>Add</Button>
            </Modal.Actions>
          </Modal>
        </div>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { certificates: state.admin.certificates } },
  AdminStore.actionCreators
)(Certificates as any)
