import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as DrawerStore from '../../store/reducers/drawer'
import * as SchedulesStore from '../../store/reducers/schedules'
import SchedulesDrawer from '../schedules/Schedules'
import {
  Icon,
  Header,
  Button
} from 'semantic-ui-react'

type DrawerProps =
    { favorited: boolean } &
    DrawerStore.DrawerState &
    typeof DrawerStore.actionCreators &
    typeof SchedulesStore.actionCreators &
    WithRouterProps<{}>

class Schedules extends React.PureComponent<DrawerProps> {
    public componentWillMount() {
      this.props.fetchFavorites()
    }

    public render() {
      return (
        <Fragment>
          <Button circular icon='arrow left' onClick={() => this.props.toggleDrawer(this.props.tab)} style={{float:'right',marginTop:20,marginRight:20}} />
          {this.props.favorited ? <Icon className='favorite' name='star' color='blue' onClick={() => this.props.unfavoriteSchedules()} style={{float:'right',fontSize:'2em',marginTop:30,marginRight:20}} /> : <Icon className='favorite' name='star outline' color='blue' onClick={() => this.props.favoriteSchedules()} style={{float:'right',fontSize:'2em',marginTop:30,marginRight:20}} />}
          <Header as='h2' style={{display:'inline-block'}}>
            <Icon name='calendar alternate' /> Schedules
          </Header>
          <SchedulesDrawer />
        </Fragment>
      )
    }
}

export default connect(
  (state: ApplicationState) => { return { ...state.drawer, favorited: state.schedules.favorited } },
  { ...DrawerStore.actionCreators, ...SchedulesStore.actionCreators }
)(Schedules as any)