import React from 'react'
import { connect } from 'react-redux'
import { withRouter, WithRouterProps } from '../../withRouter'
import { ApplicationState } from '../../store'
import * as InmatesStore from '../../store/reducers/inmates'
import {
  Popup,
  Form
} from 'semantic-ui-react'

type TableProps =
    { field: number, hasKey: boolean, name: string, label: string, placeholder: string, popup: string, fluid: boolean, search: boolean, multiple: boolean, width: 1|2|3|4|5|6|7|8, options: any } &
    InmatesStore.InmateFilters &
    typeof InmatesStore.actionCreators &
    WithRouterProps<{}>

class FormSelect extends React.PureComponent<TableProps> {
    public render() {
      if (this.props.popup) {
        return (
          <Popup content={this.props.popup} position='top center' trigger={<Form.Select
            fluid={this.props.fluid}
            search={this.props.search}
            multiple={this.props.multiple}
            name={this.props.name}
            options={this.props.options}
            label={this.props.label}
            placeholder={this.props.placeholder}
            value={this.props.hasKey ? this.props.field : (this.props.multiple ? [] : 0)}
            onChange={(e, { value }) => this.props.setFilter(this.props.name, value as number)}
            width={this.props.width}
          />} />
        )
      } else {
        return (<Form.Select
          fluid={this.props.fluid}
          search={this.props.search}
          multiple={this.props.multiple}
          name={this.props.name}
          options={this.props.options}
          label={this.props.label}
          placeholder={this.props.placeholder}
          value={this.props.hasKey ? this.props.field : (this.props.multiple ? [] : 0)}
          onChange={(e, { value }) => this.props.setFilter(this.props.name, value as number)}
          width={this.props.width}
        />)
      }
    }
}

export default connect(
  (state: ApplicationState, ownProps: any) => { return { field: state.inmates.filters[ownProps.name], hasKey: ownProps.name in state.inmates.filters, ...ownProps } },
  InmatesStore.actionCreators
)(FormSelect as any)